import React from 'react';
import { InvOnboardSuccessWrap, InvOnboardSuccessMessage } from './styled';

const InvOnboardSuccess = () => (
    <InvOnboardSuccessWrap>
        <InvOnboardSuccessMessage>
            <span>
                <i className="icon nucleoinvest-n-check" />
            </span>
            <h1>Thanks for Submitting Request</h1>
            <p>
                We have received your request to access Yubi.
                <br />
                Our team member wil get back to you soon
            </p>
        </InvOnboardSuccessMessage>
    </InvOnboardSuccessWrap>
);
export default InvOnboardSuccess;
