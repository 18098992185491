import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { getJSON, patchJSON, handleErrorV2 } from 'app/utils/FetchUtils';

const initialState = {};

const createActionName = (name: string) => `app/tools/${name}`;

const MCLRUrl = `${process.env.REACT_APP_MP_API_HOST}/marginal_cost_lending_rates`;
const tlBaseUrl = `${process.env.REACT_APP_MP_API_HOST}/term_loans`;

export const GET_MCLR_ID_REQUEST = createActionName('GET_MCLR_ID_REQUEST');
export const GET_MCLR_ID_SUCCESS = createActionName('GET_MCLR_ID_SUCCESS');
export const GET_MCLR_ID_FAILURE = createActionName('GET_MCLR_ID_FAILURE');

export const GET_MCLR_RATES_REQUEST = createActionName('GET_MCLR_RATES_REQUEST');
export const GET_MCLR_RATES_SUCCESS = createActionName('GET_MCLR_RATES_SUCCESS');
export const GET_MCLR_RATES_FAILURE = createActionName('GET_MCLR_RATES_FAILURE');

export const UPDATE_MCLR_RATES_REQUEST = createActionName('UPDATE_MCLR_RATES_REQUEST');
export const UPDATE_MCLR_RATES_SUCCESS = createActionName('UPDATE_MCLR_RATES_SUCCESS');
export const UPDATE_MCLR_RATES_FAILURE = createActionName('UPDATE_MCLR_RATES_FAILURE');

export const SKIP_IR_REQUEST = createActionName('SKIP_IR_REQUEST');
export const SKIP_IR_SUCCESS = createActionName('SKIP_IR_SUCCESS');
export const SKIP_IR_FAILURE = createActionName('SKIP_IR_FAILURE');

export const GET_TRANCHE_RS_REQUEST = createActionName('GET_TRANCHE_RS_REQUEST');
export const GET_TRANCHE_RS_SUCCESS = createActionName('GET_TRANCHE_RS_SUCCESS');
export const GET_TRANCHE_RS_FAILURE = createActionName('GET_TRANCHE_RS_FAILURE');

export const SUBMIT_IR_REQUEST = createActionName('SUBMIT_IR_REQUEST');
export const SUBMIT_IR_SUCCESS = createActionName('SUBMIT_IR_SUCCESS');
export const SUBMIT_IR_FAILURE = createActionName('SUBMIT_IR_FAILURE');

export default function demandCollection(state = initialState, action: any) {
    switch (action.type) {
        case GET_MCLR_ID_SUCCESS: {
            return {
                ...state,
                id: action.mclrId,
            };
        }
        case GET_MCLR_RATES_SUCCESS: {
            return {
                ...state,
                rateIndexes: action.payload,
            };
        }
        default:
            return state;
    }
}

export function getMCLRRates(id: any, page = 1) {
    return (dispatch: any) => {
        dispatch({ type: GET_MCLR_RATES_REQUEST });
        return getJSON(`${MCLRUrl}/${id}?page=${page}`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: GET_MCLR_RATES_SUCCESS, payload: _get(d, 'rateIndexes') });
            })
            .catch((ex: any) => {
                dispatch({ type: GET_MCLR_RATES_FAILURE });
                handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Unable to fetch marginal cost lending rate', m);
                });
            });
    };
}

export function getMCLRId() {
    return (dispatch: any) => {
        dispatch({ type: GET_MCLR_ID_REQUEST });
        return getJSON(MCLRUrl)
            .then((d: any) => {
                dispatch({ type: GET_MCLR_ID_SUCCESS, mclrId: _get(d, 'mclrs[0].id', '') });
                dispatch(getMCLRRates(_get(d, 'mclrs[0].id', '')));
            })
            .catch((ex: any) => {
                dispatch({ type: GET_MCLR_ID_FAILURE });
                handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Unable to fetch marginal cost lending rate', m);
                });
            });
    };
}

export function updateMCLRRates(id: any, values: any, setSubmitting: any) {
    return (dispatch: any) => {
        const payload = {
            marginalCostLendingRate: {
                rateIndexes: values,
            },
        };
        dispatch({ type: UPDATE_MCLR_RATES_REQUEST });
        return patchJSON(`${MCLRUrl}/${id}`, humps.decamelizeKeys(payload))
            .then((d: any) => {
                setSubmitting(false);
                dispatch({ type: UPDATE_MCLR_RATES_SUCCESS });
                dispatch(getMCLRRates(id));
            })
            .catch((ex: any) => {
                dispatch({ type: UPDATE_MCLR_RATES_FAILURE });
                handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Unable to update marginal cost lending rate', m);
                });
            });
    };
}

export function skipInterestRates(id: any, trancheId: any) {
    const payload = {
        termLoanTranche: {
            resetInterestRateFlag: false,
        },
    };
    return (dispatch: any) => {
        dispatch({ type: SKIP_IR_REQUEST });
        return patchJSON(`${tlBaseUrl}/${id}/tranches/${trancheId}`, humps.decamelizeKeys(payload))
            .then((d: any) => {
                return d;
            })
            .catch((ex: any) => {
                dispatch({ type: SKIP_IR_FAILURE });
                handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Unable to fetch marginal cost lending rate', m);
                });
            });
    };
}

export function generateRS(id: any, trancheId: any, interestRate: any) {
    const payload = {
        termLoanTranche: {
            effectiveInterestRate: interestRate,
        },
    };
    return patchJSON(`${tlBaseUrl}/${id}/tranches/${trancheId}/generate_float_repayment_schedule`, humps.decamelizeKeys(payload))
        .then((d: any) => humps.camelizeKeys(d))
        .then((d: any) => {
            if (_has(d, 'error')) {
                toastr.error('Error', d.error);
                return null;
            }
            return d;
        })
        .catch((ex: any) => {
            handleErrorV2(null, ex).then((m: any) => {
                toastr.error('Error', m);
            });
        });
}

export function submitIR(id: any, trancheId: any, interestRate: any) {
    const payload = {
        termLoanTranche: {
            effectiveInterestRate: interestRate,
            resetInterestRateFlag: true,
        },
    };
    return (dispatch: any) => {
        dispatch({ type: SUBMIT_IR_REQUEST });
        return patchJSON(`${tlBaseUrl}/${id}/tranches/${trancheId}`, humps.decamelizeKeys(payload))
            .then((d: any) => {
                dispatch({ type: SUBMIT_IR_SUCCESS });
            })
            .catch((ex: any) => {
                dispatch({ type: SUBMIT_IR_FAILURE });
                handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Unable to fetch marginal cost lending rate', m);
                });
            });
    };
}
