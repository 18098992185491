import humps from 'humps';

import { toastr } from 'react-redux-toastr';

import * as fetchUtils from 'app/utils/FetchUtils';

import { ACTION_PREFIX } from './constants';

const initialState = null;

// actions
const createActionName = (name) => `${ACTION_PREFIX}/customer/${name}`;

export const CUSTOMER_GET = createActionName('CUSTOMER_GET');

export default function customer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_GET:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
}

export function getCustomer(entityId) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_CS_OB_API_HOST}/entities/${entityId}/customer`)
            .then((d) => humps.camelizeKeys(d))
            .then((data) => {
                dispatch({ type: CUSTOMER_GET, data, entityId });
                return data;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving customer data', m);
                }),
            );
}
