import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { createLoadingSelector } from 'app/reducers/loading';
import { createActionName, baseURL } from './bid';
import { TRANSACTION_GET as TRANSACTION_GET_ADMIN } from 'app/actors/admin/ducks/ptcv1/transaction';

const initialState = null;
export const TRANSACTION_GET = createActionName('TRANSACTION_GET');
export const GET_EXEC_DOCS_SUCCESS = createActionName('GET_EXEC_DOCS_SUCCESS');
export const FUNDING_STATUS_REQUEST = createActionName('FUNDING_STATUS_REQUEST');
export const FUNDING_STATUS_SUCCESS = createActionName('FUNDING_STATUS_SUCCESS');
export const FUNDING_STATUS_FAILURE = createActionName('FUNDING_STATUS_FAILURE');

export const ADD_ATTACHMENT_REQUEST = createActionName('ADD_ATTACHMENT_REQUEST');
export const ADD_ATTACHMENT_SUCCESS = createActionName('ADD_ATTACHMENT_SUCCESS');
export const ADD_ATTACHMENT_FAILURE = createActionName('ADD_ATTACHMENT_FAILURE');

export const DELETE_ATTACHMENT_REQUEST = createActionName('DELETE_ATTACHMENT_REQUEST');
export const DELETE_ATTACHMENT_SUCCESS = createActionName('DELETE_ATTACHMENT_SUCCESS');
export const DELETE_ATTACHMENT_FAILURE = createActionName('DELETE_ATTACHMENT_FAILURE');

export default function details(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_GET:
            return {
                ...state,
                ...action.data,
            };
        case GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        case ADD_ATTACHMENT_SUCCESS: {
            const { data: newAttachment } = action;
            let cloneExtraDocs = _get(state, 'extraDocuments', []);
            const attachmentIndex = _findIndex(cloneExtraDocs, ['files[0].fileId', _get(newAttachment, 'fileId')]);

            if (attachmentIndex >= 0) {
                cloneExtraDocs[attachmentIndex] = { files: [{ ...newAttachment }] };
            } else {
                cloneExtraDocs = [{ files: [{ ...newAttachment }] }, ...cloneExtraDocs];
            }

            return {
                ...state,
                extraDocuments: cloneExtraDocs,
            };
        }
        case DELETE_ATTACHMENT_SUCCESS: {
            const { attachmentId } = action;
            const cloneExtraDocs = _get(state, 'extraDocuments', []);
            const deleteIndex = cloneExtraDocs.findIndex((item) => _get(item, 'files[0].fileId') === attachmentId);
            if (deleteIndex >= 0) cloneExtraDocs.splice(deleteIndex, 1);
            return {
                ...state,
                extraDocuments: cloneExtraDocs,
            };
        }
        default:
            return state;
    }
}

const unMapDetails = (data) => humps.camelizeKeys(data);

export function getDetails(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${baseURL(id)}`)
            .then((d) => unMapDetails(d))
            .then((data) => {
                dispatch({ type: TRANSACTION_GET, data, id });
                return data;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Transaction details', m);
                }),
            );
}

export function getPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${baseURL(id)}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (isFailed || !docs.length) {
                    return null;
                } else if (isGenerated) {
                    dispatch({ type: GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}

export function addAttachment(transId, data, attachmentId, callBack) {
    return (dispatch) => {
        dispatch({ type: ADD_ATTACHMENT_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}/extra_documents/${attachmentId}`, humps.decamelizeKeys(data))
            .then((attachment) => {
                dispatch({ type: ADD_ATTACHMENT_SUCCESS, data: humps.camelizeKeys(attachment), id: transId });
                dispatch(getDetails(transId));
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                dispatch({ type: ADD_ATTACHMENT_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding attachment', m);
                    if (typeof callBack === 'function') callBack();
                });
            });
    };
}

export function deleteAttachment(transId, attachmentId, callBack) {
    return (dispatch) => {
        dispatch({ type: DELETE_ATTACHMENT_REQUEST });
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}/extra_documents/${attachmentId}`)
            .then(() => {
                dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId, id: transId });
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                dispatch({ type: DELETE_ATTACHMENT_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing attachment', m);
                    if (typeof callBack === 'function') callBack();
                });
            });
    };
}

const params = (data) => {
    return humps.decamelizeKeys({
        ptc_transaction: {
            ...data,
        },
    });
};

export const fundingStatusLoader = createLoadingSelector([createActionName('FUNDING_STATUS')]);

export function updatePtc(id, values, callBack) {
    return (dispatch) => {
        dispatch({ type: FUNDING_STATUS_REQUEST });
        return fetchUtils
            .patchJSON(`${baseURL(id)}`, params(values))
            .then((d) => unMapDetails(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: d, id });
                if (typeof callBack === 'function') {
                    dispatch({ type: TRANSACTION_GET_ADMIN, data: d, id });
                    callBack();
                }
            })
            .then(() => {
                if (values.direct_settlement) {
                    dispatch({ type: FUNDING_STATUS_SUCCESS });
                    toastr.success('Success!', 'Transaction status updated.');
                } else {
                    toastr.success('Success!', 'Transaction updated.');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FUNDING_STATUS_FAILURE });
                    if (typeof callBack === 'function') callBack();
                    toastr.error('Error updating', m);
                }),
            );
    };
}

export function updateMcd(transId, mcdFiles, action, closePopup) {
    return (dispatch) => {
        const params = {
            ptc_transaction: {
                mcd_data: mcdFiles.mcds.filter((f) => f.selected === true).map((m) => ({ id: m.id })),
            },
        };
        return fetchUtils
            .patchJSON(baseURL(transId), humps.decamelizeKeys(params))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                action.setSubmitting(false);
                closePopup(false);
                dispatch({ type: TRANSACTION_GET, data: unMapDetails(d), id: transId });
            })
            .catch((ex) => {
                action.setSubmitting(false);
                closePopup(false);
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating mcd', m);
                });
            });
    };
}

export function editPtcDealName(transId, values) {
    return fetchUtils
        .putJSON(`${baseURL(transId)}`, { ptc_transaction: { ...humps.decamelizeKeys(values), id: transId } })
        .then(() => toastr.success('Success!', 'Deal Name updated successfully'))
        .catch((ex) =>
            fetchUtils.handleError(ex).then((m) => {
                toastr.error('Error updating deal name', m);
            }),
        );
}
