import React from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, Cell, ResponsiveContainer } from 'recharts';
import _get from 'lodash/get';

interface StepsBarChartProps {
    data: {
        name: string;
        uv: number;
        pv: number;
    }[];
    width?: number;
    height?: number;
    xName?: string;
    yName?: string;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    yAxisLabel?: string;
    tickLine?: boolean;
    tick?: boolean;
}

const fillColors: string[] = ['#ffde48', '#39d582', '#0c70fe', '#26cfe4'];

const renderLabel: any = (props: any) => {
    const labelValue = _get(props, 'value', '').split('|');
    const rating = `${Number(_get(labelValue, '[1]', 0)).toFixed(2)} %`;
    const month = _get(labelValue, '[0]', '');
    return (
        <g>
            <text
                x={props.x + props.width / 2}
                y={props.y}
                dy={-18}
                fontSize="10"
                fontFamily="sans-serif"
                textAnchor="middle"
                dominantBaseline="middle"
                color="#999"
            >
                {month}
            </text>
            <text
                x={props.x + props.width / 2}
                y={props.y}
                dy={-4}
                fontSize="10"
                fontFamily="sans-serif"
                textAnchor="middle"
                color="#111"
                fontWeight="bold"
            >
                {rating}
            </text>
        </g>
    );
};

const StepsBarChart: React.FC<StepsBarChartProps> = ({
    data = [],
    margin,
    width = 500,
    height = 300,
    xName = '',
    yName = '',
    yAxisLabel = '',
    tickLine = true,
    tick = true,
}) => {
    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart width={width} height={height} data={data} margin={margin} barGap={0} barCategoryGap={0}>
                <XAxis dataKey="name" />
                <YAxis tickLine={tickLine} tick={tick} label={{ value: yAxisLabel, angle: -90, position: 'insideBottomLeft', offset: 40 }} />
                <Bar dataKey="pv" stackId="a" fill="#fff" name={xName} />
                <Bar dataKey="uv" stackId="a" fill="#36bbc4" name={yName}>
                    <LabelList dataKey="label" position="top" content={renderLabel} />
                    {data.map((entry, index) => (
                        <Cell key={index} fill={fillColors[index]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default StepsBarChart;
