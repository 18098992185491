import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from '../../utils/FetchUtils';

const initialState = {
    signupStatus: false,
};

export const INVOICE_SIGNUP_REQUEST = 'INVOICE_SIGNUP_REQUEST';
export const INVOICE_SIGNUP_SUCCESS = 'INVOICE_SIGNUP_SUCCESS';
export const INVOICE_SIGNUP_FAILURE = 'INVOICE_SIGNUP_FAILURE';

export default function investor_signup_reducer(state = initialState, action) {
    switch (action.type) {
        case INVOICE_SIGNUP_SUCCESS:
            return {
                ...state,
                signupStatus: true,
            };
        default:
            return state;
    }
}

export function postInvestorSignup(values, setSubmitting) {
    return (dispatch) => {
        dispatch({ type: INVOICE_SIGNUP_REQUEST });
        return fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/investor_signup`, humps.decamelizeKeys(values))
            .then(() => {
                setSubmitting(false);
                dispatch({ type: INVOICE_SIGNUP_SUCCESS });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error while signing up', m);
                    dispatch({ type: INVOICE_SIGNUP_FAILURE });
                    setSubmitting(false);
                }),
            );
    };
}
