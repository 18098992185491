import tap from 'redux-tap';
import _get from 'lodash/get';
import Auth, { getProfile } from 'app/utils/Auth';
import { ENVIRONMENTS } from 'app/constants/Constants';
import AmplitudeService from 'app/components/Amplitude/AmplitudeService';

export const EventTypes = {
    track: 'amplitude.eventTypes.track',
    identify: 'amplitude.eventTypes.identify',
};

const userGroup = Auth.getGroup();

export const logEvent = (eventName, params) => {
    if (typeof window !== 'undefined' && typeof window.amplitude === 'object') {
        window.amplitude.getInstance().logEvent(eventName, {
            ...params,
            resourceType: 'Transaction',
            userGroup: _get(userGroup, '[0]', ''),
            ...(getProfile ? getProfile() : {}),
        });
        AmplitudeService.logEvent({ eventName, type: EventTypes.track }, params);
    }
};

const filterAmplitude = ({ meta }) => meta && meta.amplitude;

const warn = (msg) => typeof console === 'object' && console.warn(msg);

export default () =>
    tap(filterAmplitude, (amplitude, action, store) => {
        const eventData = Array.isArray(amplitude) ? amplitude : [amplitude];
        const state = store.getState();
        if (typeof window !== 'undefined' && typeof window.amplitude === 'object' && ENVIRONMENTS.PRODUCTION === process.env.REACT_APP_ENV) {
            eventData.forEach((e) => {
                const { eventType, eventPayload } = e;
                if (eventType) {
                    const { type: actionType, meta: actionMeta, ...actionPayload } = action || {
                        type: 'unknown',
                    };
                    const { eventName, userId, path, ...rest } = eventPayload || {
                        name: actionType,
                        ...actionPayload,
                    };
                    const name = eventName || actionType;
                    if (eventType === EventTypes.track && name) {
                        if (path) {
                            window.amplitude.getInstance().setUserProperties(path);
                        }
                        window.amplitude.getInstance().logEvent(name, {
                            ...rest,
                            ..._get(state, 'authed.user'),
                            resourceType: 'Transaction',
                            userGroup: _get(userGroup, '[0]', ''),
                            entityName: _get(state, 'authed.user.entity'),
                        });
                        AmplitudeService.logEvent({ eventName, type: EventTypes.track }, rest);
                    } else if (eventType === EventTypes.identify) {
                        if (userId) {
                            window.amplitude.getInstance().setUserId(userId);
                        }
                        if (rest) {
                            window.amplitude.getInstance().setUserProperties(rest);
                        }
                        if (path) {
                            window.amplitude.getInstance().setUserProperties(path);
                        }
                    }
                } else {
                    warn(`eventType not found in ${eventData}`);
                }
            });
        } else {
            warn('redux-amplitude has been executed but it seems like Amplitude snippet has not been loaded.');
        }
    });
