import React, { useReducer } from 'react';
import PusherProvider from './Pusher/PusherProvider';
import { reducer, notificationInitialState } from './notificationReducer';

export const NotificationContext = React.createContext({});

const pusherConfig = {
    appKey: `${process.env.REACT_APP_PUSHER_APP_KEY}`,
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    authEndpoint: `${process.env.REACT_APP_PLATFORMS_URI}/notification_service/web/authenticate`,
};

const NotificationProvider = (props: any) => {
    const [notificationState, dispatch] = useReducer<any>(reducer, notificationInitialState);
    return (
        <NotificationContext.Provider
            value={{
                notificationState,
                dispatch,
            }}
        >
            <PusherProvider {...pusherConfig}>{props.children}</PusherProvider>
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
