import React from 'react';
import styled from 'styled-components';
import { Color, Shade, TypographyVariants, TextAlign, TextTransform, Spacing, Gutter, Align } from '../Types/CommonTypes';
import { getColor } from '../Utils/colorSets';

const typographyMap = new Map([
    ['h1', 'h1'],
    ['h2', 'h2'],
    ['h3', 'h3'],
    ['h4', 'h4'],
    ['mediumitalics', 'p'],
    ['mediumsemibold', 'h6'],
    ['mediumregular', 'p'],
    ['bodyitalics', 'p'],
    ['bodysemibold', 'p'],
    ['bodyregular', 'p'],
    ['smallitalics', 'p'],
    ['smallsemibold', 'p'],
    ['smallregular', 'p'],
    ['tiny', 'p'],
]);

export interface TypographyProps extends React.HTMLAttributes<HTMLHeadingElement> {
    typeStyle: TypographyVariants;
    tag?: string;
    lineHt?: number;
    size?: number;
    color?: Color;
    shade?: Shade;
    weight?: number;
    fontStyle?: string;
    textAlign?: TextAlign;
    textTransform?: TextTransform;
    spacing?: Spacing;
    gutter?: Gutter;
    align?: Align;
    opacity?: number;
}

const StyledTag = ({ tag, typeStyle, children, textTransform, ...rest }: TypographyProps) => {
    const Component: any = tag || typographyMap.get(typeStyle);
    return <Component {...rest}>{children}</Component>;
};

const StyledH1 = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '48px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '64px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const StyledH2 = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '36px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '50px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const StyledH3 = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '28px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '40px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const StyledH4 = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '20px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '28px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const MediumItalics = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '16px')};
    font-family: 'Sofia Pro', sans-serif;
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '24px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    font-style: ${({ fontStyle }) => (fontStyle ? `${fontStyle}` : 'italic')};
    color: ${({ color, shade }) => getColor(color, shade)};
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const MediumSemibold = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '16px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '24px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const MediumRegular = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '16px')};
    font-family: 'Sofia Pro', sans-serif;
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '24px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const BodyItalics = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '14px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '20px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    font-style: ${({ fontStyle }) => (fontStyle ? `${fontStyle}` : 'italic')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const BodySemibold = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '14px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '20px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const BodyRegular = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '14px')};
    font-family: 'Sofia Pro', sans-serif;
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '20px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const SmallItalics = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '12px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '16px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    font-style: ${({ fontStyle }) => (fontStyle ? `${fontStyle}` : 'italic')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const SmallSemibold = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '12px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '16px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '600')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const SmallRegular = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '12px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '16px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;
const Tiny = styled(StyledTag)<TypographyProps>`
    font-size: ${({ size }) => (size ? `${size}px` : '10px')};
    line-height: ${({ lineHt }) => (lineHt ? `${lineHt}px` : '12px')};
    font-style: ${({ fontStyle }) => (fontStyle ? `${fontStyle}` : 'normal')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : 'normal')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    color: ${({ color, shade }) => getColor(color, shade)};
    font-family: 'Sofia Pro', sans-serif;
    padding: ${({ spacing }) => `${spacing}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    opacity: ${({ opacity }) => opacity && `${opacity}`};
    ${({ textTransform }) =>
        textTransform === 'capitalize' &&
        `
        text-transform: capitalize;
    `}
    ${({ textTransform }) =>
        textTransform === 'uppercase' &&
        `
        text-transform: uppercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'lowercase' &&
        `
        text-transform: lowercase;
    `}
    ${({ textTransform }) =>
        textTransform === 'inherit' &&
        `
        text-transform: inherit;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;

const Typography: React.FC<TypographyProps> = ({
    children,
    textTransform = 'inherit',
    spacing = '0',
    gutter = '0',
    color,
    shade,
    opacity,
    ...props
}): JSX.Element => {
    const { typeStyle } = props;
    switch (typeStyle) {
        case 'h1':
            return (
                <StyledH1 {...props} color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity}>
                    {children}
                </StyledH1>
            );
        case 'h2':
            return (
                <StyledH2 color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </StyledH2>
            );
        case 'h3':
            return (
                <StyledH3 color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </StyledH3>
            );
        case 'h4':
            return (
                <StyledH4 color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </StyledH4>
            );
        case 'mediumitalics':
            return (
                <MediumItalics
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </MediumItalics>
            );
        case 'mediumsemibold':
            return (
                <MediumSemibold
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </MediumSemibold>
            );
        case 'mediumregular':
            return (
                <MediumRegular
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </MediumRegular>
            );
        case 'bodyitalics':
            return (
                <BodyItalics color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </BodyItalics>
            );
        case 'bodysemibold':
            return (
                <BodySemibold
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </BodySemibold>
            );
        case 'bodyregular':
            return (
                <BodyRegular color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </BodyRegular>
            );
        case 'smallitalics':
            return (
                <SmallItalics
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </SmallItalics>
            );
        case 'smallsemibold':
            return (
                <SmallSemibold
                    color={color}
                    shade={shade}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </SmallSemibold>
            );
        case 'smallregular':
            return (
                <SmallRegular
                    shade={shade}
                    color={color}
                    textTransform={textTransform}
                    spacing={spacing}
                    gutter={gutter}
                    opacity={opacity}
                    {...props}
                >
                    {children}
                </SmallRegular>
            );
        case 'tiny':
            return (
                <Tiny color={color} shade={shade} textTransform={textTransform} spacing={spacing} gutter={gutter} opacity={opacity} {...props}>
                    {children}
                </Tiny>
            );
        default:
            return <p>{children}</p>;
    }
};

export default Typography;
