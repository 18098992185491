import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import _get from 'lodash/get';
import * as fetchUtils from 'app/utils/FetchUtils';
import { createLoadingSelector } from 'app/reducers/loading';
import { createActionName, daBaseUrl } from './bid';

const initialState = {};

export const ORDERS_FETCH_SUCCESS = createActionName('ORDERS_FETCH_SUCCESS');
export const ORDERS_FETCH_REQUEST = createActionName('ORDERS_FETCH_REQUEST');
export const ORDERS_SUBMIT_SUCCESS = createActionName('ORDERS_SUBMIT_SUCCESS');

export const selectorTasksLoading = createLoadingSelector([createActionName('TASK_FETCH')]);

export default function orderManagement(state = initialState, action: any) {
    switch (action.type) {
        case ORDERS_FETCH_SUCCESS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case ORDERS_SUBMIT_SUCCESS: {
            return {
                ...state,
                ...action.data,
            };
        }
        default:
            return state;
    }
}

export function getOrderManagement(id: string) {
    return (dispatch: any) => {
        dispatch({
            type: ORDERS_FETCH_REQUEST,
        });
        return fetchUtils
            .getJSON(`${daBaseUrl(id)}/order_management`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) =>
                dispatch({
                    type: ORDERS_FETCH_SUCCESS,
                    data: _get(d, 'statusInfo'),
                    id,
                }),
            )
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error retrieving deal status', m);
                });
            });
    };
}

export function submitOrders(id: string, data: any) {
    return (dispatch: any) =>
        fetchUtils
            .patchJSON(
                `${daBaseUrl(id)}/order_management`,
                humps.decamelizeKeys({
                    assignment_transaction: {
                        status_info: { ...data },
                    },
                }),
            )
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                toastr.success('Success!', `Deal Status submitted successfully.`);
                return dispatch({ type: ORDERS_SUBMIT_SUCCESS, data: _get(d, 'statusInfo'), id });
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error submitting deal status', m);
                });
                return Promise.reject(ex);
            });
}
