import React from 'react';
import styled from 'styled-components';

export const ButtonWrapper = styled.div``;
export const ButtonCancel = styled.button`
    background: #fff;
    border: 1px solid #d0d3d5;
    color: #2f3136;
    min-width: 100px;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
    &.txt-caps {
        text-transform: uppercase;
    }
    &.sm-txt-variant {
        font-size: 11px;
        min-width: inherit;
        padding: 5px 20px;
        &:hover {
            transform: translateY(-1px);
        }
    }
`;
export const ButtonDelete = styled.button`
    background: #d74e4e;
    color: #fff;
    min-width: 100px;
    border: 1px solid #d74e4e;
    border-radius: 3px;
    padding: 5px;
    font-size: 14px;
`;
export const ButtonSave = styled.button`
    text-transform: capitalize;
    background: #0070d2;
    min-width: 100px;
    font-size: 14px;
    padding: 8px;
    border-radius: 3px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.5s ease;
`;
export const ButtonBase = styled.button`
    border: none;
    font-size: 14px;
    outline: none;
    background-color: transparent;
    padding: 0px;
    box-shadow: none;
`;
export const AddRoleButton = styled.button`
    background: #0070d2;
    border: none;
    color: #fff;
    padding: 7px;
    min-width: 150px;
    border-radius: 3px;
    i {
        display: inline-block;
        vertical-align: middle;
    }
`;

export const AddLinkBtn = styled.a`
    display: inline-block;
    line-height: 15px;
    border-bottom: 1px solid;
    color: #0070d2;
    i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
    }
`;
export const ViewDetailBtn = styled.button`
    color: #0070d2;
    border: 1px solid #0070d2;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    text-transform: capitalize;
    min-width: 100px;
    text-align: center;
    padding: 2px 5px;
    background: transparent;
    &:hover,
    &:focus {
        color: #fff;
        background: #0070d2;
    }
`;
export const BtnBlock = styled.button`
    width: 100%;
    background: #1e76d0;
    border-radius: 3px;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &.red-colored-btn {
        height: 52px;
        background: #f46058;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 18px;
    }
`;
export const BackTop = styled.button`
    position: fixed;
    top: 10px;
    left: 50%;
    right: 0;
    font-weight: 500;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
    cursor: pointer;
    border: 1px solid #e0e0e0;
    z-index: 99;
    min-width: 120px;
    margin: 0 auto 0 -60px;
    padding: 4px 10px;
    i {
        vertical-align: middle;
        font-size: 14px;
        margin-right: 2px;
    }
`;
export const UploadButton = styled.button`
    background: #0070d2;
    border: none;
    color: #fff;
    border-radius: 3px;
    padding: 7px 20px;
`;
export const LinkLikeBtn = styled.button`
    background: none;
    border: none;
    padding: 0;
    color: #0070d2;
    text-decoration: underline;
`;
export const ActionBtn = styled.button`
    background: #f46058;
    color: #fff;
    min-width: 189px;
    border: 1px solid #d74e4e;
    padding: 5px;
    font-size: 13px;
    font-weight: 600;
    min-height: 49px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    &:hover,
    &:focus {
        background: #fff;
        color: #f46058;
    }
`;
export const YesBtn = styled.button`
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    min-width: 78px;
    min-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    transition: all 0.5s ease;
    i {
        color: #63af46;
        font-weight: 600;
        font-size: 20px;
        margin-right: 10px;
    }
    &.red-colored-btn {
        background: #f46058;
        text-transform: capitalize;
        color: #fff;
        i {
            color: #fff;
        }
    }
`;
export const NoBtn = styled.button`
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    min-width: 78px;
    min-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    i {
        color: #d80e07;
        font-weight: 600;
        font-size: 20px;
        margin-right: 10px;
    }
`;
export const RevokeBtn = styled.button`
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 3px;
    color: #238fd8;
    border: 1px solid #238fd8;
    min-width: 78px;
    min-height: 30px;
    padding: 0 7px;
    font-size: 12px;
    &:hover,
    &:focus {
        background: #238fd8;
        color: #fff;
        i {
            color: #fff;
        }
    }
    i {
        color: #238fd8;
        font-weight: 600;
        font-size: 20px;
        margin-right: 5px;
    }
`;
export const BtnDefault = styled.button`
    color: rgb(255, 255, 255);
    background: rgb(0, 112, 211);
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 3px;
    padding: 6px 20px;
    text-transform: uppercase;
    font-size: 11px;
    transition: all 0.5s ease;
    &:hover {
        transform: translateY(-1px);
    }
`;
export const CloseBtn = styled.button`
    box-shadow: 0 0px 5px #ddd;
    background: #fff;
    border: none;
    padding: 5px 6px;
    transition: all 0.5s ease;
    &:hover {
        box-shadow: 0 0px 8px #ddd;
        i {
            &.icon-close {
                color: #333;
            }
        }
    }
    i {
        &.icon-close {
            color: #999;
            font-size: 14px;
            padding: 3px;
            display: inline-block;
            vertical-align: middle;
        }
    }
`;
export const AgreeBtn = styled.button`
    border: none;
    background: #1f238a;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    min-width: 110px;
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    &:hover {
        transform: translateY(-1px);
    }
`;
export const DisAgreeBtn = styled.button`
    border: none;
    background: #1f238a;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    min-width: 110px;
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    &:hover {
        transform: translateY(-1px);
    }
`;

export const BorderOnlyBtn = styled.button`
    background: none;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    padding: 6px 15px;
    color: #333;
    &.active {
        border-color: #0070d2;
        color: #0070d2;
    }
`;
export const SkipBtn = styled.a`
    text-decoration: none;
`;
export const UpdateBtn = styled.button`
    background: none;
    border: none;
    border-bottom: 1px solid #0070d2;
    color: #0070d2;
`;
const Buttons = () => (
    <ButtonWrapper>
        <ButtonCancel>Cancel</ButtonCancel>
        <ButtonDelete className="txt-caps sm-txt-variant">Delete</ButtonDelete>
        <ButtonSave>Save</ButtonSave>
        <AddRoleButton>
            <i className="icon-add" />
            Add Role
        </AddRoleButton>
        <AddLinkBtn>
            <i className="icon-add" />
            Add Link Btn
        </AddLinkBtn>
        <ViewDetailBtn>View Details</ViewDetailBtn>
        <BtnBlock className="red-colored-btn">Proceed</BtnBlock>
        <BackTop>
            <i className="icon-cheveron-up" />
            Back to top
        </BackTop>
        <UploadButton>Upload</UploadButton>
        <LinkLikeBtn>MapManually</LinkLikeBtn>
        <ActionBtn />
        <YesBtn>
            <i className="icon-success" /> YES
        </YesBtn>
        <NoBtn>
            <i className="icon-error" /> NO
        </NoBtn>
        <RevokeBtn>
            <i className="icon-revoke" /> REVOKE
        </RevokeBtn>
        <BtnDefault>DefaultBtn</BtnDefault>
        <CloseBtn>
            <i className="icon-close" />
        </CloseBtn>
        <AgreeBtn>I Agree</AgreeBtn>
        <AgreeBtn>I DisAgree</AgreeBtn>
        <SkipBtn>Skip</SkipBtn>
        <UpdateBtn>Update Btn</UpdateBtn>
    </ButtonWrapper>
);
export default Buttons;
