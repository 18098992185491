import React, { useState } from 'react';
import _get from 'lodash/get';
import { manageEmailNotifications } from 'app/reducers/entities';
import { SwitchField } from 'app/utils/FormikUtils';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { NOTIFICATION_CHANNELS } from 'app/constants/Constants';
import { getActiveActorHomeUrl, getActiveEntityId, getActiveUser, getActiveUserId } from 'app/actions/AuthedActions';
import styled from 'styled-components';
import ModalPopup from 'app/components/ModalPopup';
import { useEffect } from 'react';
import { getColor } from '../UIComponents/Utils/colorSets';
import withRouter from 'app/components/WithRouter';
import queryString from 'qs';

const Wrapper = styled.div`
    background: #fff;
    padding: 0 20px;
    .modal-action {
        border-top: 0;
        padding-right: 0;
        button {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
        }
        button.submit {
            background: ${getColor('primary', 'primary')};
            box-shadow: inset 0px -4px 0px rgba(24, 52, 132, 0.5);
            border-radius: 8px;
            color: #ffffff;
        }
        button.cancel {
            color: #677489;
            background: #fff;
        }
    }
    .switch-field {
        justify-content: space-between;
    }
`;

const Message = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #384150;
    margin-bottom: 20px;
`;

interface IManageEmails {
    notificationChannels?: Array<string>;
    manageNotification: (eId: string, uId: string, userEntity: any, callBack: () => void) => void;
    permissions: any;
    history: any;
}

const { isProduct } = getActiveUser();

const ManageEmails = ({ notificationChannels = [], manageNotification, permissions, history }: IManageEmails): JSX.Element => {
    const [openManageEmail, setOpenManageEmail] = useState(false);

    useEffect(() => {
        if (!isProduct && notificationChannels.includes(NOTIFICATION_CHANNELS.EMAIL)) {
            setOpenManageEmail(true);
        }
    }, []);

    const handleBack = () => {
        const qs = queryString.parse(history?.location?.search || '', { ignoreQueryPrefix: true });
        if (qs?.from === 'pools') {
            history.goBack();
        } else {
            const homeUrl = getActiveActorHomeUrl(permissions);
            window.location.href = homeUrl ? homeUrl : '/';
        }
    };

    const handleSubmit = (values: any) => {
        const userId = getActiveUserId();
        const enitityId = getActiveEntityId();
        const data: any = { id: userId };
        if (values.transactionRelated) {
            data.notificationChannels = notificationChannels.includes(NOTIFICATION_CHANNELS.TRANSACTION_RELATED)
                ? [...notificationChannels]
                : [...notificationChannels, NOTIFICATION_CHANNELS.TRANSACTION_RELATED];
        } else {
            data.notificationChannels = notificationChannels.filter((n: string) => n !== NOTIFICATION_CHANNELS.TRANSACTION_RELATED);
        }

        manageNotification(enitityId, userId, { user: { ...data } }, handleBack);
    };

    const handleClose = () => {
        setOpenManageEmail(false);
        handleBack();
    };

    return (
        <ModalPopup open={openManageEmail} modalClass="modal-xs email-config" title={'Manage email notifications'} onRequestClose={handleClose}>
            <Wrapper>
                <Message>Choose which emails you want to receive by turning the toggle on or off.</Message>
                <Formik
                    initialValues={{ transactionRelated: notificationChannels.includes(NOTIFICATION_CHANNELS.TRANSACTION_RELATED) }}
                    onSubmit={handleSubmit}
                    render={({ values, isSubmitting }: any): JSX.Element => (
                        <Form>
                            <Field
                                name="transactionRelated"
                                containerClass="form-group"
                                id="transactionRelated"
                                checked={values.transactionRelated === true}
                                inputClass="styled-checkbox"
                                component={SwitchField}
                                defValue={values.transactionRelated}
                                label="PTC and DA related transaction updates"
                            />
                            <div className="modal-action">
                                <button disabled={isSubmitting} onClick={handleClose} type="button" className="btn cancel">
                                    Cancel
                                </button>
                                <button disabled={isSubmitting} type="submit" className="btn submit">
                                    {isSubmitting ? 'Submitting' : 'Submit'}
                                </button>
                            </div>
                        </Form>
                    )}
                />
            </Wrapper>
        </ModalPopup>
    );
};

const mapStateToProps = (state: any) => ({
    notificationChannels: _get(state, 'authed.user.notificationChannels', []),
    permissions: _get(state, 'authed.user.permissions', {}),
});

const mapDispatchToProps = (dispatch: any) => ({
    manageNotification: (eId: string, uId: string, userEntity: any, callBack: () => void) =>
        dispatch(manageEmailNotifications(eId, uId, userEntity, callBack)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageEmails));
