import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';
import { createActionName, baseURL } from './transaction';
import { createLoadingSelector } from 'app/reducers/loading';

export const GET_COMMODITY_REQUEST = createActionName('GET_COMMODITY_REQUEST');
export const GET_COMMODITY_SUCCESS = createActionName('GET_COMMODITY_SUCCESS');
export const GET_COMMODITY_FAILURE = createActionName('GET_COMMODITY_FAILURE');

export const POST_COMMODITY_REQUEST = createActionName('POST_COMMODITY_REQUEST');
export const POST_COMMODITY_SUCCESS = createActionName('POST_COMMODITY_SUCCESS');
export const POST_COMMODITY_FAILURE = createActionName('POST_COMMODITY_FAILURE');

export const UPDATE_COMMODITY_REQUEST = createActionName('UPDATE_COMMODITY_REQUEST');
export const UPDATE_COMMODITY_SUCCESS = createActionName('UPDATE_COMMODITY_SUCCESS');
export const UPDATE_COMMODITY_FAILURE = createActionName('UPDATE_COMMODITY_FAILURE');

export const commodityLoading = createLoadingSelector([createActionName('GET_COMMODITY')]);

export const commodityFormValues = { name: '', location: '', volume: '', volumeUnit: '', procurementPrice: '', collateralManagerInfo: '' };

const initialState: any = { commodities: [] };

export const normalizeColumn = (value: any) => {
    const processedData = {
        ptcCommodity: {
            ...value,
            volume: {
                unit: value.volumeUnit,
                value: value.volume,
            },
            collateralManagerIds: [value.collateralManagerInfo.value],
        },
    };
    return humps.decamelizeKeys(processedData);
};

export const unMapCommodities = (val: any) => {
    return {
        ...val,
        volume: val.volume.value,
        volumeUnit: val.volume.unit,
        collateralManagerInfo: { label: val.collateralManagerInfo.name, value: val.collateralManagerInfo.id },
    };
};

export default function commodity(state: any = initialState, action: any) {
    switch (action.type) {
        case GET_COMMODITY_SUCCESS: {
            return { ...state, commodities: action.data };
        }
        default: {
            return state;
        }
    }
}

export const getCommodity = (id: string) => {
    return (dispatch: any) => {
        dispatch({ type: GET_COMMODITY_REQUEST });
        fetchUtils
            .getJSON(`${baseURL(id)}/commodities`)
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: GET_COMMODITY_SUCCESS, data: d, id });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: GET_COMMODITY_FAILURE });
                    toastr.error('Error fetching Commodities information', m);
                }),
            );
    };
};

export const uploadCommodity = (id: string, body: object) => {
    return (dispatch: any) => {
        dispatch({ type: POST_COMMODITY_REQUEST });
        fetchUtils
            .postJSON(`${baseURL(id)}/commodities`, humps.decamelizeKeys(body))
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: POST_COMMODITY_SUCCESS, id });
                toastr.success('Success', ' Commodities added Successfully');
                return dispatch(getCommodity(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: POST_COMMODITY_FAILURE });
                    toastr.error('Error Uploading Commodities information', m);
                }),
            );
    };
};

export const updateCommodity = (id: string, commodityId: string, values: object) => {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_COMMODITY_REQUEST });
        fetchUtils
            .putJSON(`${baseURL(id)}/commodities/${commodityId}`, values)
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: UPDATE_COMMODITY_SUCCESS, id });
                toastr.success('Success', ' Commodities Updated Successfully');
                return dispatch(getCommodity(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: UPDATE_COMMODITY_FAILURE });
                    toastr.error('Error Updating Commodities information', m);
                }),
            );
    };
};
