export const HOME = '/home';
export const SETUP_PRODUCT = '/product-setup/products';
export const SETUP_LOAN_FILE_VERSION = `${SETUP_PRODUCT}/loan-file/:asset`;
export const SETUP_LOAN_FILE_VIEW = `${SETUP_PRODUCT}/:fileType/:id/view`;
export const SETUP_LOAN_FILE_EDIT = `${SETUP_PRODUCT}/:fileType/:id/edit`;
export const SETUP_LOAN_VIEW_TRANSACTION = `${SETUP_PRODUCT}/loan-file/:id/transactions`;
export const SETUP_LOAN_FILE = `${SETUP_PRODUCT}/loan-file`;
export const PRODUCT_SETUP = '/product-setup/initiate';
export const PRODUCT_SETUP_FORM = '/product-setup/initiate/pool/:fileType/:id';
export const ASSET_CLASS_SELECTION = '/product-setup/initiate/asset-class';
export const MULTI_ASSET_CLASS_SELECTION = '/product-setup/initiate/multi-asset-class';
export const ASSET_CLASS_TYPE_SELECTION = '/product-setup/initiate/asset-class-type';
export const FIELD_CONFIGURATIONS = '/product-setup/initiate/field-config/:fileType/:id';
export const MCD_LISTING_PAGE = '/pool/mcd';
export const MCD_MAPPING = '/product-setup/initiate/mapping/:fileType/:id';
export const MCD_TEMPLATE_VALIDATE = '/product-setup/initiate/:fileType/:id/validate';

//MCD Upload and Transaction creation

export const MCD_UPLOAD = '/product-setup/upload-mcd/:templateId';
export const DIRECT_MCD_UPLOAD = '/product-setup/upload-mcd';
export const MCD_DETAILS = '/product-setup/self-mcd/:mcdId/';

// Standard reports
export const STANDARD_REPORTS = `${SETUP_PRODUCT}/standard-reports`;
export const STANDARD_REPORTS_SETUP = '/product-setup/standard-reports';
export const STANDARD_REPORTS_SELECT_REPORTS = `${STANDARD_REPORTS_SETUP}/select-reports`;
export const STANDARD_REPORTS_CONFIGURATION = `${STANDARD_REPORTS_SELECT_REPORTS}/:type/configuration`; // config for specific report Eg: npa/configuration

// daily dpd reports setupd
export const DAILY_DPD_REPORTS_HOME = `${SETUP_PRODUCT}/daily-dpd-reports`;
export const DAILY_DPD_REPORT = '/product-setup/daily-dpd-reports';
export const DAILY_DPD_REPORT_SETUP = `${DAILY_DPD_REPORT}/template`;
export const DAILY_DPD_REPORT_SETUP_INITIATE = `${DAILY_DPD_REPORT}/template/initiate`;
export const DAILY_DPD_REPORT_SETUP_CONFIGURATION = `${DAILY_DPD_REPORT}/template/:templateId/config`;

export const REPORT = '/report';

export const PAYOUT_REPORTS = `${REPORT}/payout-report`;

//Payout report setup
export const PAYOUT_REPORT_SETUP_HOME = `${SETUP_PRODUCT}/payout-setup`;
export const PAYOUT_SETUP = '/product-setup/payout-setup';
export const PAYOUT_REPORT_SETUP = `${PAYOUT_SETUP}/template`;
export const PAYOUT_REPORT_SETUP_INITIATE = `${PAYOUT_SETUP}/template/initiate`;
export const PAYOUT_REPORT_SETUP_CONFIGURATION = `${PAYOUT_SETUP}/template/:templateId/config`;
export const PAYOUT_REPORT_SETUP_EDIT = `${PAYOUT_SETUP}/template/:templateId/edit-config`;

//Product setup other operations
export const OTHER_OPERATIONS_HOME = `${SETUP_PRODUCT}/other-operations`;
export const OTHER_OPERATIONS = '/product-setup/other-operations';
export const ADD_ACCOUNT_DETAILS = `${OTHER_OPERATIONS}/add-account`;
export const ADD_ORIGINATOR_ACCOUNT_DETAILS = `${OTHER_OPERATIONS}/add-originator-account`;
export const VIEW_ORIGINATOR_ACCOUNT_DETAILS = `${OTHER_OPERATIONS}/view-originator-account/:accountId/customer-name/:customerName`;
export const VIEW_ORIGINATOR_ACCOUNTS_LIST = `${SETUP_PRODUCT}/other-operations/view-originator-list`;
export const VIEW_ACCOUNT = `${OTHER_OPERATIONS}/view-account`;
export const VIEW_ACCOUNT_DETAILS = `${VIEW_ACCOUNT}/:accountId`;
export const ADD_CUSTOM_FIELDS = `${OTHER_OPERATIONS}/custom-fields`;
export const MAKER_CHECKER_CONFIG = `${OTHER_OPERATIONS_HOME}/maker-checker`;

//Payout reports
export const TRANSACTION_LEVEL_PAYOUT_REPORTS = `${PAYOUT_REPORTS}/:transId`;
export const PAYOUT_REPORTS_UPLOAD = `${PAYOUT_REPORTS}/:id/upload`;
export const PAYOUT_REPORTS_FORM = `${PAYOUT_REPORTS}/:id/form/`;
export const PAYOUT_REPORTS_SUMMARY = `${PAYOUT_REPORTS}/:id/summary`;
export const PAYOUT_REPORTS_TRANS_LIST = `${PAYOUT_REPORTS}/:id/payout-list`;

//Change the below routes as it is confusing
export const PAYOUT_REPORTS_SAMPLE_UPLOAD = `${PAYOUT_SETUP}/:templateId/sample-upload`;
export const PAYOUT_REPORTS_SAMPLE_MAPPING = `${PAYOUT_SETUP}/:templateId/mapping`;
export const PAYOUT_REPORTS_SAMPLE_VALIDATE = `${PAYOUT_SETUP}/:templateId/validate`;
// Repayment Schedule Routes

export const RS_UPLOAD_FORM = '/product-setup/self-rs';
export const RS_UPLOAD_VALIDATION = '/product-setup/self-rs/:rsId';

//daily-dpd-reports
// export const TRANSACTION_LEVEL_PAYOUT_REPORTS = `${PAYOUT_REPORTS}/:transId`;
// export const PAYOUT_REPORTS_UPLOAD = `${PAYOUT_REPORTS}/:id/upload`;
// export const PAYOUT_REPORTS_FORM = `${PAYOUT_REPORTS}/:id/form/`;
// export const PAYOUT_REPORTS_SUMMARY = `${PAYOUT_REPORTS}/:id/summary`;
export const DAILY_DPD_REPORTS_TRANS_LIST = `${DAILY_DPD_REPORT}/:id/payout-list`;

//Repayment Schedule
export const REPAYMENT_SCHEDULE = `${SETUP_PRODUCT}/rsv1`;

export const RSV1_BASE = `${REPAYMENT_SCHEDULE}/:id`;

export const RSV1_MAPPING = `${REPAYMENT_SCHEDULE}/customer/:customerId/mapping`;
export const RSV1_MAPPING_FIELDS = `${RSV1_MAPPING}/:id`;

export const DPD_CONFIGURATION = `${SETUP_PRODUCT}/dpd-configuration`;
export const NPA_CONFIGURATION = `${SETUP_PRODUCT}/npa-configuration`;

/* * */

export const COLLECTION_HOME = `${SETUP_PRODUCT}/collection-file`;
export const COLLECTION_SETUP = `/product-setup/collection-file/setup`;
export const COLLECTION_CONFIG = `/product-setup/collection-file/:templateId/setup`;
