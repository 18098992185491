import React, { ReactNode } from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

interface Itable {
    columns: Array<object>;
    data: Array<object>;
    title?: string;
    containerClass?: string;
    isLoading?: boolean;
    customCell?: ReactNode;
}

const Table: React.FC<Itable> = ({ columns, data, title = '', containerClass = 'table-container', isLoading = false, customCell }) => (
    <div className={containerClass}>
        {title && <h4>{title}</h4>}
        <div className="custom-scroll-h">
            <table>
                <TableHeader columns={columns} />
                <TableBody body={data} isLoading={isLoading} columns={columns} customCell={customCell} />
            </table>
        </div>
    </div>
);

export default Table;
