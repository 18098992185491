
const loadHotjar = () => {
    const link = 'https://static.hotjar.com/c/hotjar-';
    const extension = '.js?sv=';
    window.hj =
        window.hj ||
        function() {
            (window.hj.q = window.hj.q || []).push(arguments);
        };
    window._hjSettings = { hjid: 1919130, hjsv: 6 };
    const element = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.async = 1;
    script.rel = 'preconnect';
    script.src = link + window._hjSettings.hjid + extension + window._hjSettings.hjsv;
    element.appendChild(script);
};

export default loadHotjar;
