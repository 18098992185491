import { AuthService } from '@yubi/yb-module-auth';
import { MFAService } from '@yubi/yb-module-auth';
export const setProfile = (profile) => {
    // Saves profile data to localStorage
    try {
        localStorage.setItem('profile', JSON.stringify(profile));
    } catch (e) {}
};
export const getProfile = () => {
    // Retrieves the profile data from localStorage
    try {
        const profile = localStorage?.getItem('profile');
        return profile ? JSON.parse(localStorage?.profile) : {};
    } catch (e) {}
};

export const setTokensToCookies = () => {
    const authResult = {
        accessToken: AuthService.getAccessToken(),
        idToken: AuthService.getIdToken(),
    };
    setToken(authResult);
};

export const setToken = (authResult) => {
    AuthService.setToCookie('access_token', authResult.accessToken, true);
    AuthService.setToCookie('id_token', authResult.idToken, true);
    AuthService.setToCookie('mfa_token', MFAService.getMFAToken());
};
export const getNextUrl = () => localStorage?.getItem('next_url');

export default AuthService;
