import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import createSentryMiddleware from 'redux-sentry-middleware';
import amplitudeMiddleware from '../utils/reduxAmplitude';
import * as Sentry from '@sentry/react';

import rootReducer from '../reducers/index';
import history from '../utils/myHistory';

const middlewareHistory = routerMiddleware(history);

const configureStore = (browserHistory, initialState) => {
    const middlewares = [middlewareHistory, thunk];
    let composeEnhancers = compose;
    if (process.env.NODE_ENV === 'development') {
        /* eslint-disable no-underscore-dangle */
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        /* eslint-enable */
    }
    middlewares.push(routerMiddleware(history));

    if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production') {
        if (typeof window !== 'undefined') {
            middlewares.push(createSentryMiddleware(Sentry, {}));
        }
    }

    if (typeof window !== 'undefined') {
        if (typeof window.amplitude === 'object') {
            middlewares.push(amplitudeMiddleware());
        }
    }

    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

export default configureStore;
