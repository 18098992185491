import _get from 'lodash/get';
import _size from 'lodash/size';
import _uniq from 'lodash/uniq';
import { PTCV1_KEYS, ENTERPRISE_FINANCE } from 'app/constants/TransactionConstants';
import { TAB_OPTIONS } from 'app/constants/TransactionList';
import {
    TRANSACTION_STATE,
    PRODUCT_KEYS,
    PRODUCTS,
    LONG_TERM_RATING,
    SHORT_TERM_RATING,
    PRODUCT_API_ROUTES,
    USER_TYPES,
    DOCUMENTS_DOWNLOAD_ACCESS,
    INTERNAL_ACTORS,
    DOCUMENTS_COLLAB_ACCESS,
    PLI_DOCUMENTS_COLLAB_ACCESS,
    TL_COUNTERPARTIES,
} from 'app/constants/Constants';
import { EXTERNAL_ACTORS } from 'app/actors/admin/constants/Constants';
import { TRANSACTION_LIST_FIELDS } from 'app/constants/TransactionList';

import { humanize, replaceSpecialChar, amountInCr, capitalize } from './StringUtils';
import { findAttribValue, getFileExtension } from './CommonUtils';
import { getActiveEntityId, getActiveUser } from '../actions/AuthedActions';

export const INTEREST_TYPE = {
    FIXED: 'fixed',
    FLOATING: 'floating',
};

const {
    direct_assignment,
    securitization,
    assignment,
    working_capital_demand_loan,
    commercial_paper,
    ncd,
    ncdv2,
    term_loan,
    pref_shares,
    ptc,
    pooled_loan_issuance,
    //new
    bank_guarantee,
    cash_credit,
    receivable_assignment,
    factoring,
    pack_credit_foreign_currency,
    export_packing_credit,
    letter_of_credit,
    short_term_loan,
    overdraft,
    discounting,
} = PRODUCT_KEYS;

/**
 * FIXME: Rewrite this method based on input type for ex:
 * tenor as object or string and normalize it instead of checking on products
 */
export const getTenor = (tenor: any, type: any) => {
    if (tenor) {
        if ([commercial_paper, working_capital_demand_loan].includes(type)) {
            return `${tenor} days`;
        } else if (
            [
                ncd,
                ncdv2,
                pref_shares,
                term_loan,
                ptc,
                assignment,
                pooled_loan_issuance,
                bank_guarantee,
                cash_credit,
                receivable_assignment,
                factoring,
                pack_credit_foreign_currency,
                export_packing_credit,
                letter_of_credit,
                short_term_loan,
                overdraft,
                discounting,
            ].includes(type)
        ) {
            const year = _get(tenor, 'years');
            const month = _get(tenor, 'months');
            const day = _get(tenor, 'days');

            return year || month || day
                ? `${(_get(tenor, 'years') > 0 && _get(tenor, 'years') + 'y') || ''}
            ${(_get(tenor, 'months') > 0 && _get(tenor, 'months') + 'm') || ''}
            ${(_get(tenor, 'days') > 0 && _get(tenor, 'days') + 'd') || ''}`
                : '-';
        } else if ([securitization, direct_assignment].includes(type)) {
            return `${tenor} months`;
        }
        return tenor;
    }
    return '-';
};

export const getYieldPercent = (yieldPercent: any, type: string, showMultiple = false) => {
    if (type === 'tl' || type === 'wcdl') {
        switch (_get(yieldPercent, 'type')) {
            case INTEREST_TYPE.FIXED: {
                return yieldPercent ? `${yieldPercent.value}% ${yieldPercent.type}` : '-';
            }
            case INTEREST_TYPE.FLOATING: {
                return yieldPercent ? `${yieldPercent.effectiveInterestRate}% ${yieldPercent.type}` : '-';
            }
            default: {
                return '-';
            }
        }
    } else if (
        [
            bank_guarantee,
            cash_credit,
            receivable_assignment,
            factoring,
            pack_credit_foreign_currency,
            export_packing_credit,
            letter_of_credit,
            short_term_loan,
            overdraft,
            discounting,
        ].includes(type)
    ) {
        return yieldPercent ? (yieldPercent.value ? `${yieldPercent.value}% ${yieldPercent.type}` : '-') : '-';
    } else if (type === 'ps' || type === 'cp' || type === 'pli') return yieldPercent ? `${yieldPercent}%` : '-';
    else if (type === 'da' || type === 'dav2') {
        if (!yieldPercent) return '-';
        if (yieldPercent.assigneeYield) {
            return `${_get(yieldPercent, 'assigneeYield', '-')}% ${_get(yieldPercent, 'interestRateMethod', '')} ${_get(
                yieldPercent,
                'interestRateType',
                '',
            )}`;
        }
    } else if (type === 'ptc' || type === 'ptcv1') {
        if (!yieldPercent) return '-';
        let str = '';
        Object.keys(yieldPercent).forEach((m: any) => {
            str += `${str && str.length > 0 && ', '}${yieldPercent[m]}`;
        });
        return str;
    } else if (type === 'ncdv2') {
        if (!yieldPercent) return '-';
        if (typeof yieldPercent === 'string') {
            return yieldPercent;
        }
    } else if (type === 'ncd') {
        switch (_get(yieldPercent, 'type')) {
            case INTEREST_TYPE.FIXED: {
                return yieldPercent ? `${yieldPercent.value}% ${yieldPercent.type}` : '-';
            }
            case INTEREST_TYPE.FLOATING: {
                return yieldPercent ? `${yieldPercent.floatingInterest.benchmark}% ${yieldPercent.type}` : '-';
            }
            default: {
                return '-';
            }
        }
    }
    return '-';
};

export const getAssetClass = (assetClass: any) => {
    let assetCls = assetClass;
    if (Array.isArray(assetClass)) {
        assetCls = assetClass[0];
    }
    return humanize(assetCls) ? humanize(assetCls) : '';
};

export const getAssetClassV2 = (assetClass: any) => {
    if (!assetClass) return [];
    if (Array.isArray(assetClass)) {
        const arr = assetClass.map((a: any) => {
            return humanize(a) ? humanize(a) : ' ';
        });
        return arr.join(', ');
    }
    return humanize(assetClass) ? [humanize(assetClass)] : ' ';
};

export const getProductType = (type: keyof typeof PRODUCT_KEYS): string => _get(PRODUCT_KEYS, `[${type}]`, ' ');

export const getProductLabel = (value: any) => {
    const prod: any = PRODUCTS.find((d: any) => d.value === value);
    return prod && prod.capitalize;
};

interface AssetClass {
    key: string;
    label: string;
}

export function assetClassStructure(assetClasses: Array<AssetClass>) {
    return assetClasses.map((asset: AssetClass) => ({ label: asset.label, value: asset.key }));
}

export function assetClassArr(entity: any, assetClasses: Array<AssetClass>, entityId?: string) {
    let entityType = [];
    if (Object.keys(entity).length > 0) {
        const assetClass = entity[entityId || getActiveEntityId()].customer;
        entityType = assetClass.assetClass.reduce((type: any, asset: any) => {
            const tempAsset: any = {};
            const poolLabel: any = findAttribValue(assetClasses, 'value', asset, 'label');
            tempAsset.value = asset;
            tempAsset.label = poolLabel || humanize(asset);
            type.push(tempAsset);
            return type;
        }, []);
    }
    return entityType;
}

export const getRatingRange = (rIndex: number, top: number, bottom: number) => {
    const arrIndex = rIndex + 1;
    const RATINGS_LENGTH = LONG_TERM_RATING.length;
    let begin: number = arrIndex - (top + 1) > 0 ? arrIndex - (top + 1) : 0;
    const end = begin === 0 ? top + bottom + 1 : arrIndex + bottom;
    if (end > RATINGS_LENGTH) begin = begin + RATINGS_LENGTH - end;
    const data = LONG_TERM_RATING.slice(begin, end);
    return data.map((item: any) => item.value).join(',');
};

export const addDownloadDetails = (data: any = []) =>
    _get(data, 'documents', []).map((doc: any) => ({
        ...doc,
        downloadParams: {
            fileName: doc.displayName,
            transId: _get(data, 'id'),
            clientId: _get(data, 'clientId'),
            clientName: _get(data, 'clientName'),
            transName: _get(data, 'dealName'),
        },
    }));

export const addDownloadDetailsForTask = (tasks: any = [], transId: string): any =>
    tasks.map((task: any) => ({
        ...task,
        taskFields: _get(task, 'taskFields', []).map((taskField: any) => {
            if (taskField.type === 'FILE') {
                return {
                    ...taskField,
                    value: _get(taskField, 'value', []).map((val: any) => ({
                        ...val,
                        downloadParams: { fileName: val.filename, transId, taskId: task.id, taskName: task.actionLabel },
                    })),
                };
            }
            return taskField;
        }),
    }));

export const removeNull = (obj: any, replaceValue: any = {}) => {
    Object.keys(obj).forEach((val: any) => {
        obj[val] = _get(obj, val) || replaceValue;
    });
    return obj;
};

export const mapColumns = (
    stdLabels: Array<string>,
    mappedColumns: Array<{ standardColumnName: string; fileColumnName: string }>,
    formValues: any,
): any => {
    const formValuesCopy = formValues;
    stdLabels.forEach((label: string) => {
        formValuesCopy[label] =
            _get(
                mappedColumns.find((data: any) => data.standardColumnName === label),
                'fileColumnName',
            ) || null;
    });
    return formValuesCopy;
};

export const ratingToString = (rating: object | string): string => {
    if (!rating) {
        return '-';
    }
    if (typeof rating === 'object') {
        return Object.values(rating).toString();
    }
    if (typeof rating === 'string') {
        return rating.toUpperCase();
    }
    return rating;
};

export const getProductName = (key: any) => {
    if (getProductType(key) === 'ps') {
        return 'pref shares';
    } else if (getProductType(key) === 'ptcv1') {
        return 'ptc';
    } else if (getProductType(key) === 'dav2') {
        return 'da';
    } else if (getProductType(key) === 'ncdv2') {
        return 'ncd';
    }
    return getProductType(key);
};

export const getProductURL = (type: string) => {
    switch (type) {
        case 'wcdl':
            return 'wc';
        case 'pref_shares':
            return 'ps';
        default:
            return type;
    }
};

export const getDealNameForAPI = (type: string) => {
    switch (type) {
        case 'tl':
            return 'term_loans';
        case 'bg':
            return 'bank_guarantees';
        case 'cc':
            return 'cash_credits';
        case 'ra':
            return 'receivable_assignments';
        case 'factoring':
            return 'factoring';
        case 'pcfc':
            return 'pack_credit_foreign_currencies';
        case 'epc':
            return 'export_packing_credits';
        case 'lc':
            return 'letter_of_credits';
        case 'stl':
            return 'short_term_loans';
        case 'od':
            return 'overdrafts';
        case 'discounting':
            return 'discounts';
        default:
            return type;
    }
};

export const getShortLabel = (type: string) => {
    if (type === 'ps') {
        return 'pref shares';
    }
    if (type === 'ptcv1') {
        return 'ptc';
    }
    if (type === 'dav2') {
        return 'da';
    }
    if (type === 'ncdv2') {
        return 'ncd';
    }
    return type;
};

/**
 * @returns {Boolean} True or false based on if we can show the Client Overview view
 */
export const canShowClientOverview = () => {
    const { isInvestor, isInternal, isClient, isRatingagency } = getActiveUser();
    return isInvestor || isInternal || isClient || isRatingagency;
};

export const ptcv1KeyMapping = (transObj: object) => {
    const cloneObj: any = { ...transObj };
    const result: any = {};
    Object.keys(cloneObj).forEach((key) => {
        if (PTCV1_KEYS[key]) {
            result[PTCV1_KEYS[key]] = cloneObj[key];
        } else {
            result[key] = cloneObj[key];
        }
    });
    return result;
};

/**
 * Get rating index based on the rating value. It will look into LONG_TERM_RATING and SHORT_TERM_RATING
 * @param {string} rating pass the rating value
 * @returns {number} returns rating index
 */
export const getLongTermRating = (rating: string) => {
    if (!rating) throw new Error('You must pass the rating value');
    const ratingIndex = LONG_TERM_RATING.findIndex((d: { value: string }) => d.value === rating);
    if (ratingIndex === -1) {
        const shortRating: any = SHORT_TERM_RATING.find((d: { value: string }) => d.value === rating);
        const longRatingIndex =
            _size(shortRating) > 0 ? LONG_TERM_RATING.findIndex((d: { ref: number }) => d.ref === Math.floor(shortRating.ref)) : 0;
        return longRatingIndex;
    }
    return ratingIndex;
};

/**
 * When we pass the required rating array. (e.g) ['AA+', 'AA', 'AA-', 'A+']. It will look into the actual rating source and return the rating array.
 * @param {Array<string>} rating pass the rating arr
 * @returns {Array<object>} returns new rating arr
 */
export const pickRating = (rating: Array<string>): Array<object> => {
    if (!Array.isArray(rating) || rating.length < 1) return [];
    return LONG_TERM_RATING.filter((d: { value: string }) => {
        return rating.includes(d.value);
    });
};

export const getEntitiesOptions = (input: any, actor: any, fetchEntities: any, page = 1, otherParams = {}) => {
    return fetchEntities(page, input, actor, otherParams).then((d: any) => ({ options: d }));
};

export const addCPAssigned = (tasks: any) => {
    if (!tasks) return [];
    return tasks.map((d: any) => {
        return {
            ...d,
            counterPartyAssigned: `${humanize(d.counterPartyType)} - ${d.assignedTo}`,
        };
    });
};

export const getFilteredEntities = (input: any, actor: any, fetchEntities: any, page = 1, id: any) =>
    fetchEntities(page, input, actor).then((d: any) => ({ options: d.filter((d: any) => d.value !== id) }));

export const mapAssignCounterparties = (cp: any) => {
    return Object.keys(cp).reduce((processData, val) => {
        const cloneData: any = { ...processData };
        if (Array.isArray(cp[val]) && cp[val].length > 0) {
            cloneData[val] = cp[val].map((d: any) => d.value);
        } else if (_size(cp[val]) > 0) {
            cloneData[val] = _get(cp, `${val}.value`, '') !== 'N/A' ? [cp[val].value] : [''];
        } else {
            cloneData[val] = [''];
        }
        return cloneData;
    }, {});
};

export const isDocumentPresent = (files: any, documentName: any) => {
    const file = files.find((d: any) => d.type === documentName);
    return _size(file ? file.files : []) > 0;
};

export const canClientViewTL = (state: string, isHlf: boolean) => {
    const { isClient } = getActiveUser();
    if (isClient && !isHlf) {
        return [TRANSACTION_STATE.DRAFT, TRANSACTION_STATE.RELEASED].includes(state);
    }
    return false;
};

export const isEnterpriseFinanceClient = (companyType: string): boolean => {
    return companyType === ENTERPRISE_FINANCE;
};

/**
 * Get external external counterparties invloved with respect to each product type.
 * @param {string} productType Product Type
 * @returns {{ value: string; label: string }[]} returns Array of label and value of counterparties
 */
export const getCounterparties = (productType: string, isHlfTransaction = false, isEFClient = false): { value: string; label: string }[] => {
    let counterParties: any = [];
    switch (productType) {
        case PRODUCT_API_ROUTES.dav2:
        case PRODUCT_API_ROUTES.da:
            counterParties = [...EXTERNAL_ACTORS.filter((actor) => actor.value !== USER_TYPES.COLLATERAL_MANAGER)];
            break;
        case PRODUCT_API_ROUTES.ptc:
        case PRODUCT_API_ROUTES.ptcv1:
            if (isEFClient) counterParties = EXTERNAL_ACTORS;
            else counterParties = [...EXTERNAL_ACTORS.filter((actor) => actor.value !== USER_TYPES.COLLATERAL_MANAGER)];
            break;
        default:
            counterParties = EXTERNAL_ACTORS;
    }
    return counterParties;
};

export const mapDashboardData = (data = []) => {
    return data.map((d: any) => {
        const dCopy = { ...d };
        if (Object.keys(d).includes('aggregatedFacilityLimit')) {
            dCopy.humanizedAmount = `${dCopy.aggregatedFacilityLimit} Cr(s)`;
        }
        return dCopy;
    });
};

export const canCashflowView = (state = ''): boolean => {
    const { isCSG, isInvestor, isClient, isRatingagency, isTrustee, isOperation, isOperationAssociate, isInternalAuditor } = getActiveUser();
    return (
        (isOperation || isOperationAssociate || isCSG || isClient || isTrustee || isRatingagency || isInvestor || isInternalAuditor) &&
        state === TRANSACTION_STATE.SETTLED
    );
};

export const canCashflowEdit = (): boolean => {
    const { isTrustee, isOperation } = getActiveUser();
    return isTrustee || isOperation;
};

const getInternalActors = () => {
    const { isOperationAssociate } = getActiveUser();
    return INTERNAL_ACTORS.reduce((actors: any, ia: any) => {
        const copyActors = [...actors];
        if (ia.value !== USER_TYPES.OPERATIONS || (ia.value === USER_TYPES.OPERATIONS && !isOperationAssociate)) {
            copyActors.push(ia.value);
        }
        return copyActors;
    }, []);
};

export const canTransDocDownload = (f: { fileName: string }, cp: string, transactionState = ''): boolean => {
    const internalActors =
        !['docx', 'doc'].includes(getFileExtension(f.fileName)) &&
        ![TRANSACTION_STATE.DRAFT, TRANSACTION_STATE.SUMMARY_ACCEPTED].includes(transactionState)
            ? getInternalActors()
            : [];
    return _uniq([...DOCUMENTS_DOWNLOAD_ACCESS, ...internalActors]).includes(cp);
};

export const canTransDocCollab = (cp: string, productType = ''): boolean => {
    return _uniq([
        ...DOCUMENTS_COLLAB_ACCESS,
        ...getInternalActors(),
        ...(productType === PRODUCT_API_ROUTES.pli ? PLI_DOCUMENTS_COLLAB_ACCESS : []),
    ]).includes(cp);
};

export const checkCollabAccess = (
    f: { fileId: string; fileName: string; editable: boolean },
    cp: string,
    transactionState: string,
    productType = '',
): any => {
    return (
        f.fileId &&
        f.fileName &&
        (f.editable || productType === PRODUCT_API_ROUTES.pli) &&
        ['docx', 'doc'].includes(getFileExtension(f.fileName)) &&
        [TRANSACTION_STATE.RELEASED, TRANSACTION_STATE.FINALIZED].includes(transactionState) &&
        canTransDocCollab(cp, productType)
    );
};

// Task Utils

export const selectTaskGroups = (tasksData: any) => {
    const group: Array<any> = [];
    if (tasksData) {
        tasksData.forEach((tsk: any) => {
            const tg = group.find((g) => g.name === tsk.group);
            if (!tg && tsk.group) {
                const openTask = tasksData.find((g: any) => g.group === tsk.group && g.status === 'open');
                const avlTask = tasksData.find((g: any) => g.group === tsk.group && g.isAvailable === true);
                const status = openTask ? 'open' : 'completed';
                const isAvailable = !!avlTask;
                const isEnabled = tasksData.filter((g: any) => g.group === tsk.group).every((t: any) => Array.isArray(t.taskFields));

                group.push({
                    name: tsk.group,
                    alt: replaceSpecialChar(tsk.group),
                    status,
                    isAvailable,
                    isEnabled,
                });
            }
        });
    }
    return group;
};

export const selectTasks = (tasksData: any) => {
    if (!tasksData) return [];
    const entityId = getActiveEntityId();
    return tasksData.filter((t: any) => t.ownerId === entityId);
};

export const selectDependantTasks = (tasksData: any) => {
    if (!tasksData) return [];
    const entityId = getActiveEntityId();
    return tasksData.filter((t: any) => t.ownerId !== entityId);
};

export const getStatePath = (state: any, product: any, transId: any): any => {
    const { isProduct } = getActiveUser();
    if (isProduct) {
        return state.admin[product][transId];
    }
    return state[product].transaction[transId];
};

export const isCustomWaterfall = (values: any) => {
    const checkJuniourSeries = (tranches: any) => {
        let isJuniourTranche = false;
        tranches.forEach((t: any) => {
            if (t.investors.length > 1) {
                isJuniourTranche = true;
            }
        });
        return isJuniourTranche;
    };
    return (
        _get(values, 'eisTrappingPercentage') !== 0 &&
        (_size(_get(values, 'tranches', [])) > 2 || (_size(_get(values, 'tranches', [])) < 3 && checkJuniourSeries(_get(values, 'tranches', []))))
    );
};

const asigneeAssignorShare = (poolPrincipal: number, percentage: any, amount: any): any => {
    // if amount given, find percentage
    let percentage1;
    let percentage2;
    let amount1;
    let amount2;
    if (amount) {
        amount1 = parseFloat(amount);
        amount2 = poolPrincipal - amount;
        percentage1 = (amount / poolPrincipal) * 100;
        percentage2 = 100 - percentage1;
    }
    // if percentage given, find amount
    if (percentage) {
        percentage1 = parseFloat(percentage);
        percentage2 = 100 - percentage1;
        amount1 = (poolPrincipal * percentage1) / 100;
        amount2 = poolPrincipal - amount1;
    }

    return {
        amount1: amount1 && !Number.isInteger(amount1) ? amount1.toFixed(2) : amount1,
        amount2: amount2 && !Number.isInteger(amount2) ? amount2.toFixed(2) : amount2,
        percentage1: percentage1 && !Number.isInteger(percentage1) ? Number(percentage1.toFixed(2)) : percentage1,
        percentage2: percentage2 && !Number.isInteger(percentage2) ? Number(percentage2.toFixed(2)) : percentage2,
    };
};

export const computeShare = (target: any, setFieldValue: any, poolPrincipal: number): void => {
    switch (target.name) {
        case 'sharing.assigneeShare':
            {
                const shares = asigneeAssignorShare(poolPrincipal, target.value, null);
                setFieldValue('sharing.assigneeAmount', shares.amount1);
                setFieldValue('sharing.assignorShare', shares.percentage2);
                setFieldValue('sharing.assignorAmount', shares.amount2);
            }
            break;
        case 'sharing.assigneeAmount':
            {
                const shares = asigneeAssignorShare(poolPrincipal, null, target.value);
                setFieldValue('sharing.assigneeShare', shares.percentage1);
                setFieldValue('sharing.assignorShare', shares.percentage2);
                setFieldValue('sharing.assignorAmount', shares.amount2);
            }
            break;
        case 'sharing.assignorShare':
            {
                const shares = asigneeAssignorShare(poolPrincipal, target.value, null);
                setFieldValue('sharing.assignorAmount', shares.amount1);
                setFieldValue('sharing.assigneeShare', shares.percentage2);
                setFieldValue('sharing.assigneeAmount', shares.amount2);
            }
            break;
        case 'sharing.assignorAmount':
            {
                const shares = asigneeAssignorShare(poolPrincipal, null, target.value);
                setFieldValue('sharing.assignorShare', shares.percentage1);
                setFieldValue('sharing.assigneeShare', shares.percentage2);
                setFieldValue('sharing.assigneeAmount', shares.amount2);
            }
            break;
        default:
            return target;
    }
    return target;
};

export const getAmplitudeParams = (details: any, productType: string) => {
    const fieldKey = TRANSACTION_LIST_FIELDS[productType];

    const dealAmount = _get(details, 'leanTransaction', false)
        ? `${amountInCr(_get(details, 'poolPreference.poolPrincipal'))}CR`
        : productType === pooled_loan_issuance && _get(details, `${fieldKey.amount}`)
        ? `${details[`${fieldKey.amount}`]} CRORES`
        : productType === assignment || productType === ptc
        ? `${amountInCr(_get(details, 'loansSummary.poolPrincipal'))} CR`
        : _get(details, `${fieldKey.amount}`);
    const clientName =
        productType === PRODUCT_KEYS.pooled_loan_issuance
            ? _get(details, 'customers', [])
                  .map((d: any) => d.name)
                  .join(',')
            : _get(details, `${fieldKey.clientName}`);
    const tenor = _get(details, 'leanTransaction', false)
        ? getTenor(_get(details, 'poolPreference.poolTenor'), productType)
        : productType === assignment || productType === ptc
        ? _get(details, 'loansSummary.poolTenor')
        : getTenor(_get(details, `${fieldKey.tenor}`), productType);
    const interestRate =
        productType === assignment
            ? _get(details, `${fieldKey.pricing}`)
            : productType === ptc
            ? _get(details, 'tranchesSummary', [])
                  .map((d: any) => `${_get(d, 'investors[0].yield') + (_get(d, 'investors[0].interestMethod') === 'Coupon' ? 'PAPM' : 'XIRR')}`)
                  .join(',')
            : getYieldPercent(_get(details, `${fieldKey.pricing}`, ''), productType);
    return {
        clientName,
        dealName: _get(details, `${fieldKey.dealName}`),
        dealAmount,
        tenor,
        interestRate,
        rating: ptc
            ? _get(details, 'tranchesSummary', [])
                  .map((d: any) => d.expectedRating)
                  .join(',')
            : ratingToString(_get(details, `${fieldKey.rating}`)),
        transactionState: _get(details, 'transactionState'),
        leanTransaction: _get(details, 'leanTransaction', false),
        transactionType: productType,
        transactionId: _get(details, 'id'),
    };
};
export const getCommonSideMenus = (type: string) => [
    {
        title: `MY DEALS`,
        items: TAB_OPTIONS,
    },
];

export const mapOptions = (options: Array<any> = [], humaizeLabel = true): Array<any> =>
    _size(options) ? options.map((opt) => ({ label: humaizeLabel ? humanize(opt) : opt, value: opt })) : [];

export const checkEmptyActors = (actors: any, actorNames: any) => {
    let data = false;
    actorNames.map((c: any, i: number) => {
        const cp = actors[c][0];
        if (_get(cp, 'name', _get(cp, 'companyName'))) data = true;
    });
    return data;
};

export const getYieldValue = (data) => {
    if (_get(data, 'structuring.tranches', []).some((t: any) => _get(t, 'investors[0].yield')))
        return `${Number(_get(data, 'structuring.tranches[0].investors[0].yield', 0))?.toFixed(2)} %`;
    else if (_get(data, 'pricing')) return capitalize(_get(data, 'pricing', '-'));
    else if (_get(data, 'poolPreference.poolYield'))
        return `${_get(data, 'poolPreference.poolYield.from') + ' - ' + _get(data, 'poolPreference.poolYield.to')}%`;

    return '';
};

export const getTenorValue = (data) => {
    if (_get(data, 'loansSummary.poolTenor')) return _get(data, 'loansSummary.poolTenor', '-');
    else if (data?.poolPreference?.poolTenor) return getTenor(data?.poolPreference?.poolTenor, data?.transactionType);

    return '';
};

export const checkDocsGenerated = (docs = []) => {
    return docs.every((item) => item?.files?.[0]?.state === 'generated');
};
