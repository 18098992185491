import { combineReducers } from 'redux';
import details, { getDetails, getPTCExecDocs } from './details';
import summary, { getSummary } from './summary';
import documents, { getDocuments } from './documents';
import poolSummary, { getPoolSummary } from './poolSummary';
import actors, { getActors } from './actors';
import files, { getFiles } from './files';
import bid, { getBid, placeBid, fetchBid, makeBid } from './bid';
import tasks, { getTasks, actionableTasks, submitTask } from './tasks';
import client, { getClient } from './clients';
import costing, { getFixedFees, updateFixedFees, updateCashCollateral, fixedCostLoading, cashCollateralLoading, cashFlowLoading } from './costing';
import lossModel, { getLossModel, generateLossModel } from './lossModel.ts';
import dealPerformance, { getDealPerformance, DEAL_PERFORMANCE_LOADING } from './dealPerformance';
import invoice, {
    getInvoice,
    addInvoice,
    updateInvoice,
    addBank,
    getBankDetails,
    deleteInvoice,
    deleteSignedInvoice,
    deleteBankDetails,
    signedInvoiceUpload,
    getInvoiceDetail,
    GET_INVOICE_LOADING,
    SIGNED_INVOICE_UPLOAD_LOADING,
    SIGNED_INVOICE_DELETE_LOADING,
    GET_INVOICE_DETAIL_LOADING,
} from './invoice.ts';
import pricing, { sameEntity, sameSector, otherSector, clientInvestor, sameEntityLoading, sameSectorLoading, otherSectorLoading } from './pricing.ts';

const rootReducer = (state = {}, action) => {
    if (action.id === undefined) return state;

    return {
        [action.id]: combineReducers({
            details,
            summary,
            poolSummary,
            documents,
            files,
            actors,
            bid,
            tasks,
            costing,
            client,
            lossModel,
            invoice,
            pricing,
            dealPerformance,
        })(state[action.id], action),
    };
};

export default rootReducer;

export const actions = {
    getDetails,
    getSummary,
    getDocuments,
    getFiles,
    getPoolSummary,
    getActors,
    getBid,
    placeBid,
    fetchBid,
    makeBid,
    getTasks,
    getFixedFees,
    updateFixedFees,
    updateCashCollateral,
    fixedCostLoading,
    cashCollateralLoading,
    cashFlowLoading,
    getClient,
    actionableTasks,
    getLossModel,
    generateLossModel,
    getInvoice,
    addInvoice,
    updateInvoice,
    getBankDetails,
    deleteInvoice,
    deleteSignedInvoice,
    addBank,
    deleteBankDetails,
    signedInvoiceUpload,
    getInvoiceDetail,
    GET_INVOICE_LOADING,
    SIGNED_INVOICE_UPLOAD_LOADING,
    SIGNED_INVOICE_DELETE_LOADING,
    GET_INVOICE_DETAIL_LOADING,
    sameEntity,
    sameSector,
    otherSector,
    clientInvestor,
    sameEntityLoading,
    sameSectorLoading,
    otherSectorLoading,
    getPTCExecDocs,
    submitTask,
    getDealPerformance,
    DEAL_PERFORMANCE_LOADING,
};
