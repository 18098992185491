import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';
import { PRODUCT_API_ROUTES, PRODUCT_KEYS } from 'app/constants/Constants';
import * as RouterUtils from 'app/utils/RouterUtils';
import { humanize } from 'app/utils/StringUtils';
import { CLIENT_INIT_TRANSACTION_LEAN_SUCCESS } from 'app/actors/client/constants/Routes';
import { CREATE_LEAN_TRANSACTION } from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';

const createActionName = (name: any) => `app/client/lean/${name}`;

export const CREATE_PTC_TRANSACTION_SUCCESS = createActionName('CREATE_PTC_TRANSACTION_SUCCESS');
export const CREATE_DA_TRANSACTION_SUCCESS = createActionName('CREATE_DA_TRANSACTION_SUCCESS');

export default function transaction(state = {}, action: any) {
    switch (action.type) {
        case CREATE_PTC_TRANSACTION_SUCCESS:
            return {
                ...state,
                initializedLean: action.data,
            };
        case CREATE_DA_TRANSACTION_SUCCESS:
            return {
                ...state,
                initializedLean: action.data,
            };
        default:
            return state;
    }
}

const normalizeTransactionData = (data: any) => {
    const { psl, instrumentType, poolPrincipal, indicativeTermSheet, expectedSettlementDate, ...rest } = data;
    const key = instrumentType === PRODUCT_API_ROUTES.ptcv1 ? 'ptcTransaction' : 'assignmentTransaction';
    const formData: any = new FormData();
    const jsonData = humps.decamelizeKeys({
        poolPreference: {
            ...rest,
            poolPrincipal: poolPrincipal * 10000000,
        },
        expectedSettlementDate: expectedSettlementDate,
    });
    if (indicativeTermSheet) {
        const termSheetValue = instrumentType === PRODUCT_API_ROUTES.ptcv1 ? 'termsheet' : 'term_sheet';
        formData.append('document', indicativeTermSheet);
        formData.append('document_type', termSheetValue);
    }
    formData.append(humps.decamelize(key), JSON.stringify(jsonData));
    formData.append('lean_transaction', true);
    return formData;
};

export const createLean = (transType: any, data: any) => {
    return (dispatch: any) =>
        fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/${transType}`, normalizeTransactionData(data))
            .then((d: any) => {
                dispatch({
                    type: transType === PRODUCT_API_ROUTES.ptcv1 ? CREATE_PTC_TRANSACTION_SUCCESS : CREATE_DA_TRANSACTION_SUCCESS,
                    data: humps.camelizeKeys(d),
                });
                return d;
            })
            .then((d: any) =>
                dispatch(
                    RouterUtils.pushRoute({
                        path: CLIENT_INIT_TRANSACTION_LEAN_SUCCESS,
                        keys: { transId: d.id, productType: transType === PRODUCT_API_ROUTES.ptcv1 ? PRODUCT_KEYS.ptc : PRODUCT_KEYS.assignment },
                    }),
                ),
            )
            .then(() => {
                dispatch({
                    type: CREATE_LEAN_TRANSACTION,
                    meta: {
                        amplitude: [
                            {
                                eventType: EventTypes.track,
                                eventPayload: {
                                    eventName: CREATE_LEAN_TRANSACTION,
                                    transactionType: transType,
                                    ...data,
                                },
                            },
                        ],
                    },
                });
            })
            .catch((err: any) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                    toastr.error(`Error Initiating ${humanize(transType)} Transaction`, m);
                });
            });
};
