import decode from 'jwt-decode';
import Logger from './Logger';

export function getTokenExpirationDate(token) {
    try {
        if (!token) return null;
        const decoded = decode(token);
        if (!decoded.exp) {
            return null;
        }
        const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);
        return date;
    } catch (e) {
        return null;
    }
}

export function getUser(token) {
    try {
        if (!token) return null;
        const user = decode(token);
        return user;
    } catch (e) {
        return null;
    }
}

export function isTokenExpired(token, cutOffSec = 0) {
    try {
        if (!token) {
            return true;
        }
        const date = getTokenExpirationDate(token);
        if (date === null) {
            return false;
        }
        return !(date.valueOf() - cutOffSec * 1000 > new Date().valueOf());
    } catch (e) {
        console.warn(`Invalid token specified: token::${token},message::${e.message}`);
    }
    return true;
}

export function userIdFromMfaToken(token) {
    if (!token) return null;
    const decoded = decode(token);
    if (!decoded.user_id) {
        return null;
    }
    return decoded.user_id;
}

export function getItemFromToken(token, field) {
    if (token && decode(token).exp < new Date().getTime() && decode(token)[`${field}`]) {
        return decode(token)[`${field}`];
    }
    return false;
}

export function decodeJWT(token) {
    if (!token) return null;
    try {
        const jwt = decode(token);
        return jwt;
    } catch (e) {
        Logger.logException(e);
    }
    return null;
}
