import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import * as fetchUtils from 'app/utils/FetchUtils';
import { createLoadingSelector } from 'app/reducers/loading';
import { unMapPTC, TRANSACTION_GET, baseURL, getTranchePrincipal } from './transaction';
import { IformValues, ImodifyFormValues } from 'app/actors/admin/containers/PTCV1/Costing/costing.types';

const MINIMUM_RATING_FEE = 35000;
const MINIMUM_STAMP_DUTY_FEE = 100500;

export const createActionName = (name: any) => `app/admin/ptcv1/${name}`;

export const intialCostingVal = {
    ratingFee: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    legalFee: {
        value: '',
    },
    trusteeFee: {
        value: '',
    },
    auditFee: {
        value: '',
    },
    stampDuty: {
        value: '',
    },
    rAndTDepository: {
        value: '',
    },
    arrangerFee: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    nsdlCustodyCharge: {
        value: '',
    },
    leiCharge: {
        value: '',
    },
    upfrontFeeSeriesA1: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    upfrontFeeSeriesA2: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    upfrontFeeSeriesA3: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    upfrontFeeSeriesA4: {
        value: '',
        percent: '',
        unit: 'percent',
    },
    upfrontFeePoolPos: {
        value: '',
        percent: '',
        unit: 'percent',
    },
};

export const POST_COSTING_REQUEST = createActionName('POST_COSTING_REQUEST');
export const POST_COSTING_SUCCESS = createActionName('POST_COSTING_SUCCESS');
export const POST_COSTING_FAILURE = createActionName('POST_COSTING_REQUEST');

export const postCostingLoader = createLoadingSelector([createActionName('POST_COSTING')]);

export const costingPercentageCalc = (percent: number, value: any, d = ''): number | string => {
    const percentageCal = (p: number, v: number): number => {
        return (p / 100) * v;
    };
    let pVal: any = '';
    switch (humps.camelize(d)) {
        case 'ratingFee': {
            const calculateVal: any = percentageCal(percent, value.principal);
            pVal = Math.max(MINIMUM_RATING_FEE, calculateVal.toFixed(2));
            break;
        }
        case 'stampDuty': {
            const calculateVal: number = percentageCal(percent, value.principal);
            pVal = (Math.min(MINIMUM_STAMP_DUTY_FEE, calculateVal) + 5000).toFixed(2);
            break;
        }
        case 'arrangerFee': {
            const calculateVal: number = percentageCal(percent, value.totalFunding);
            pVal = calculateVal.toFixed(2);
            break;
        }
        case 'upfrontFeeSeriesA1': {
            const calculateVal: number = percentageCal(percent, _get(value, 'tranches[0]', 0));
            pVal = calculateVal.toFixed(2);
            break;
        }

        case 'upfrontFeeSeriesA2': {
            const calculateVal: number = percentageCal(percent, _get(value, 'tranches[1]', 0));
            pVal = calculateVal.toFixed(2);
            break;
        }
        case 'upfrontFeeSeriesA3': {
            const calculateVal: number = percentageCal(percent, _get(value, 'tranches[2]', 0));
            pVal = calculateVal.toFixed(2);
            break;
        }

        case 'upfrontFeeSeriesA4': {
            const calculateVal: number = percentageCal(percent, _get(value, 'tranches[3]', 0));
            pVal = calculateVal.toFixed(2);
            break;
        }
        case 'upfrontFeePoolPos': {
            const calculateVal: number = percentageCal(percent, value.principal);
            pVal = calculateVal.toFixed(2);
            break;
        }
        default:
            pVal = '';
            break;
    }
    return pVal;
};
const costingPercentageList = [
    'rating_fee',
    'arranger_fee',
    'upfront_fee_series_a1',
    'upfront_fee_series_a2',
    'upfront_fee_series_a3',
    'upfront_fee_series_a4',
    'upfront_fee_pool_pos',
];

export const normalizeCosting = (data: any, principal: any) => {
    let costingValues;
    const allFields = { ..._get(data, 'fixed_fee', {}) };
    costingValues = Object.keys(allFields).reduce((field: any, d: any) => {
        const cloneFields: any = { ...field };
        cloneFields[d] = {
            value: allFields[d],
            ...((_has(data, `fixed_fee_percent.${d}`) || costingPercentageList.includes(d)) &&
                d !== 'stamp_duty' && {
                    percent: _has(data, `fixed_fee_percent.${d}`) ? data.fixed_fee_percent[d] : '',
                    unit: _has(data, `fixed_fee_percent.${d}`) ? 'percent' : 'value',
                    value: _has(data, `fixed_fee_percent.${d}`) ? costingPercentageCalc(data.fixed_fee_percent[d], principal, d) : allFields[d],
                }),
        };
        return cloneFields;
    }, {});
    if (_isEmpty(costingValues)) {
        costingValues = intialCostingVal;
    }
    return { ...data, data: costingValues };
};

const denormalizeCosting = (data: any) => {
    const formData: any = {
        fixed_fee: {},
        fixed_fee_percent: {},
    };
    Object.keys(data).forEach((d: any) => {
        formData.fixed_fee[d] = Number(data[d].value);
        if (_get(data, `${d}.unit`) === 'percent') {
            formData.fixed_fee_percent[d] = Number(data[d].percent);
        }
    });
    return formData;
};

function mapPTCCosting(data: object) {
    const postCostingData = denormalizeCosting(data);
    const processedData = {
        ptcTransaction: {
            ...postCostingData,
        },
    };
    return humps.decamelizeKeys(processedData);
}

function handleCostingPoll(transId: string) {
    return (dispatch: any) =>
        fetchUtils
            .getJSON(baseURL(transId))
            .then((d: any) => {
                if (_get(d, 'costing.state', '') === 'generated') {
                    return d;
                }
                return null;
            })
            .catch((ex: any) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error retrieving costing files', m);
                }),
            );
}

function pollCosting(transId: string) {
    return (dispatch: any) =>
        fetchUtils
            .poll(() => dispatch(handleCostingPoll(transId)), 180000, 1000)
            .then((d: any) => {
                if (d) {
                    dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), transId });
                }
            })
            .then(() => {
                toastr.success('Success!', 'Costing files are available.');
            })
            .catch((e: any) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving costing files', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving costing files', errMsg);
                }
            });
}

export function updateCosting(transId: string, data: object, setSubmitting: any) {
    return (dispatch: any) => {
        dispatch({ type: POST_COSTING_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}`, mapPTCCosting(data))
            .then((d: any) => {
                setSubmitting(false);
                if (_get(d, 'costing.state', '') === 'generating') {
                    dispatch(pollCosting(transId));
                    dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id: transId });
                }
                dispatch({ type: POST_COSTING_SUCCESS, id: transId });
            })
            .catch((ex: any) => {
                setSubmitting(false);
                dispatch({ type: POST_COSTING_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error updating costing', m);
                });
            });
    };
}

export const updateFormValues = (
    values: IformValues,
    { field, value, poolPrincipal, setFieldValue, waterfallSummary, structuring }: ImodifyFormValues,
): number => {
    const filteredFields = Object.keys(values).reduce((arr: any, d: string) => {
        const copyArr = { ...arr };
        copyArr[d] = { ...values[d] };
        if (field === `${d}.percent`) {
            const percentageVal = costingPercentageCalc(
                value,
                {
                    principal: poolPrincipal,
                    totalFunding: _get(waterfallSummary, 'totalFunding', 0),
                    tranches: getTranchePrincipal(_get(structuring, 'tranches', []), poolPrincipal),
                },
                d,
            );
            copyArr[d].value = percentageVal;
            copyArr[d].percent = value;
            setFieldValue(d, {
                ...values[d],
                value: percentageVal,
                percent: value,
            });
        } else if (field === `${d}.value`) {
            copyArr[d].value = value;
            setFieldValue(d, {
                ...values[d],
                value,
            });
        } else {
            copyArr[d].value = values[d]!.value;
            setFieldValue(d, {
                ...values[d],
                value: values[d]!.value,
            });
        }
        return copyArr;
    }, {});
    return Object.keys(filteredFields).reduce((total: number, f: string) => {
        const val = filteredFields[f].value ? Number(filteredFields[f].value) : 0;
        const sumField = total + val;
        return sumField;
    }, 0);
};

export default intialCostingVal;
