import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getColor } from 'app/products/UIComponents/Utils/colorSets';

interface LineBarChartProps {
    data: {}[];
    width?: number;
    height?: number;
    leftYAxisLabel?: string;
    rightYAxisLabel?: string;
    leftYAxisUnit?: string;
    rightYAxisUnit?: string;
    xName?: string;
    yName?: string;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    fill?: string;
    stroke?: string;
    tickLine?: boolean;
    axisLine?: boolean;
    offset?: number;
    barSize?: number;
    radius?: Array<number>;
}
const LineBarChart: React.FC<LineBarChartProps> = ({
    data = [],
    width = 500,
    height = 340,
    leftYAxisLabel = '',
    rightYAxisLabel = '',
    leftYAxisUnit = '',
    rightYAxisUnit = '',
    xName = '',
    yName = '',
    margin = {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0,
    },
    fill = getColor('primary', 'primary'),
    stroke = '#b837c4',
    axisLine = true,
    tickLine = true,
    offset = 20,
    barSize = 50,
    radius = [0, 0, 0, 0],
}) => {
    return (
        <ResponsiveContainer width="100%" height={height}>
            <ComposedChart data={data} margin={margin}>
                <XAxis axisLine={axisLine} tickLine={tickLine} dataKey="name" />
                <YAxis
                    axisLine={axisLine}
                    tickLine={tickLine}
                    yAxisId="left"
                    label={{
                        value: `${leftYAxisLabel} ${leftYAxisUnit && `(${leftYAxisUnit})`}`,
                        angle: -90,
                        position: 'insideBottomLeft',
                        offset: offset,
                    }}
                />
                <YAxis
                    axisLine={axisLine}
                    tickLine={tickLine}
                    yAxisId="right"
                    orientation="right"
                    label={{ value: `${rightYAxisLabel} ${rightYAxisUnit && `(${rightYAxisUnit})`}`, angle: -90, position: 'insideRight' }}
                />
                <Tooltip />
                <Legend />
                {xName && <Bar dataKey="pv" yAxisId="left" barSize={barSize} fill={fill} name={xName} radius={radius} />}
                {yName && <Line dataKey="uv" yAxisId="right" stroke={stroke} name={yName} />}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LineBarChart;
