export const LOGIN = `${process.env.REACT_APP_POOL_HOST}`;
export const OTP = '/otp';
export const LOGOUT = `https://${process.env.REACT_APP_AUTH_DOMAIN_ADDRESS}/v2/logout?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&returnTo=${process.env.REACT_APP_POOL_HOST}`;

export const LOGOUT_ALL_DEVICES = '/logout-all-devices';
export const CHANGE_PASSWORD = '/change-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const SET_PASSWORD = '/set-password';
export const HOME = '/home';
export const FIREWALL = '/firewall';
export const OVERALL_SERVICER_REPORTS = '/servicer-report';
export const FORBIDDEN = '/forbidden';
export const HELP_COOKIES = '/auth/help-cookies';

export const CLIENT_SIGNUP = `${process.env.REACT_APP_CS_HOST}/sign-up`;
export const CLIENT_DOCUMENT = `${process.env.REACT_APP_CS_HOST}/client/:entityId/client-snapshot`;
export const CLIENT_INFO_REPORT = `${process.env.REACT_APP_CS_HOST}/client/:entityId/client-snapshot`;
export const CLIENT_SNAPSHOT = `${process.env.REACT_APP_CS_HOST}/client/:entityId/client-snapshot`;
export const CLIENT_GLOBAL_HOME = `${process.env.REACT_APP_CS_HOST}/client/:entityId/home`;
export const CLIENT_INFO_REPORT_V1 = `${process.env.REACT_APP_CS_HOST}/client/:entityId/info-report`;
export const CLIENT_INSIGHTS = `${process.env.REACT_APP_CS_HOST}/insight`;
export const CLIENT_LEADS = `${process.env.REACT_APP_CS_HOST}/leads`;
export const RETAIL_LEADS = `${process.env.REACT_APP_CS_HOST}/retail-leads`;
export const CLIENT_TASKS = `${process.env.REACT_APP_CS_HOST}/tasks`;
export const CLIENT_PEOPLE = `${process.env.REACT_APP_CS_HOST}/people`;
export const CLIENT_COMPANIES = `${process.env.REACT_APP_CS_HOST}/companies`;
export const CLIENT_AUDITORS = `${process.env.REACT_APP_CS_HOST}/auditors`;
export const CLIENT_PENDING_DOCUMENTS = `${process.env.REACT_APP_CS_HOST}/client/:entityId/document/pending`;
export const CLIENT_FINANCIAL_RATIOS = `${process.env.REACT_APP_CS_HOST}/client/:clientId/info-report/financials/financial_ratios`;
export const CLIENT_COMPANY_SNAPSHOT = `${process.env.REACT_APP_CS_HOST}/client/:clientId/info-report/company_snapshot/company_snapshot`;
export const CAG_SETTINGS = `${process.env.REACT_APP_CS_HOST}/settings`;
export const EXPRESS_INTEREST = `${process.env.REACT_APP_CS_HOST}/express-interest`;
export const CLIENT_EXPRESS_INTEREST = `${process.env.REACT_APP_CS_HOST}/client/:entityId/express-interest`;
export const CLIENT_EXPRESS_INTEREST_PRODUCT = `${process.env.REACT_APP_CS_HOST}/express-interest`;
export const LIQUIDITY_ANALYSIS = `${process.env.REACT_APP_CS_HOST}/liquidity-analysis`;
export const FEEDBACK = `${process.env.REACT_APP_CS_HOST}/feedback`;
export const PEER_COMPARISON = `${process.env.REACT_APP_CS_HOST}/peer-comparison`;
export const INVESTOR_EXPLORE = `${process.env.REACT_APP_CS_HOST}/explore`;
export const INVESTOR_DEFAULT_HOME = `${process.env.REACT_APP_CS_HOST}/home`;

export const CLIENT_DEFAULT_HOME = '/client';
export const CAG_DEFAULT_HOME = `${process.env.REACT_APP_CS_HOST}/leads`;
export const ACTOR_DEFAULT_HOME = '/:type/transactions';
export const ANCHOR_DEFAULT_HOME = `${process.env.REACT_APP_SCF_HOST}/`;
export const CREDIT_DEFAULT_HOME = `${process.env.REACT_APP_CS_HOST}/`;
export const CONNECTOR_DEFAULT_HOME = `${process.env.REACT_APP_CS_HOST}/connector`;
export const CSG_HOME = `${process.env.REACT_APP_CS_HOST}/home`;

export const DOCUMENT_COLLABORATOR_DA = '/da/:transId/documents/:docId/editor';
export const DOCUMENT_COLLABORATOR_DA_V2 = '/dav2/:transId/documents/:docId/editor';
export const DOCUMENT_COLLABORATOR_PTC = '/ptc/:transId/documents/:docId/editor';
export const DOCUMENT_COLLABORATOR_PTC_V1 = '/ptcv1/:transId/documents/:docId/editor';
export const DOCUMENT_COLLABORATOR = '/:type/:transId/documents/:docId/editor';

export const ONBOARDED_CLIENTS = `${process.env.REACT_APP_CS_HOST}/clients`;
export const INVESTOR_SETTINGS = `${process.env.REACT_APP_CS_HOST}/settings`;
export const INVESTOR_PREFERENCES_SETTINGS = `${process.env.REACT_APP_CS_HOST}/settings#preference-settings`;
export const CLIENT_SETTINGS = `${process.env.REACT_APP_CS_HOST}/settings`;

export const ENTITY_LIST = `${process.env.REACT_APP_CS_HOST}/entities`;
export const ENTITY = '/entities/:entityId';
export const ENTITY_ADD = '/entities/add';
export const ENTITY_USER_ADD = '/entities/:entityId/user';
export const USER_ADD = '/user';
export const SURVEY = '/surveys';
export const SURVEY_SUCCESS = '/survey-success';
export const TRANSACTION_DETAIL = '/:product/:transId';
export const DEMAND_COLLECTION = '/demand-collection';
export const MANAGE_ROLES = '/manage-roles';
export const MANAGE_NOTIFICATIONS = '/manage-notifications';
export const INTERNAL_MANAGE_ROLE = '/entities/:entityId/:actor/manage-roles';

export const INIT_POOL = '/initiate/pool';
export const MCD_MAPPING = '/initiate/pool/:poolId/mcd-mapping';
export const RS_MAPPING = '/initiate/pool/:poolId/rs-mcd';
export const PC_MAPPING = '/initiate/pool/:poolId/pc-mcd';
export const POOL_PREF = '/initiate/pool/:poolId/pref';
export const POOL_UPLOAD_SUCCESS = '/initiate/pool/:fileType/success/:poolId';

// Routes Transistion
export const SERVICER_REPORT_OLD = '/:cp/securitization/:transId/servicer-report';
export const REPORTS_OLD = '/:cp/reports';
export const REPORTS = '/reports';
export const SERVICER_REPORT = '/ptc/:transId/servicer-report';
export const TRANSACTION_DETAIL_OLD = '/:cp/:product(securitization|ptc|ncd|cp|ps|tl|da)/:transId';
export const RESOURCES = '/resources';
export const ACTIONS_CENTER = '/actions-center';

export const INVESTOR_SIGNUP = '/investor_signup';

// Common Dashboard
export const DASHBOARD_HOME = '/dashboard';
export const DASHBOARD_POOL_PORTFOLIO = '/dashboard/pool-purchase-portfolio/:type';
export const NEW_DASHBOARD = '/new-dashboard';
export const NEW_DASHBOARD_POOL_PORTFOLIO = '/dashboard/pool-purchase-portfolio/all';
export const ABS_POOL_PORTFOLIO = '/pool-purchase-portfolio/all';
export const DASHBOARD_PORTFOLIO = '/dashboard/portfolio';
export const DASHBOARD_PORTFOLIO_PRODUCTS = '/dashboard/portfolio/products';
export const DASHBOARD_PORTFOLIO_CLIENT = '/dashboard/portfolio/client';
export const DASHBOARD_PORTFOLIO_SECTOR = '/dashboard/portfolio/sector';
export const DASHBOARD_PORTFOLIO_TRANSACTION = '/dashboard/portfolio/transaction';

export const VENDOR_ONBOARDING = `${process.env.REACT_APP_SCF_HOST}/onboarding/vendor`;

export const TOOLS = `${process.env.REACT_APP_LOANS_HOST}/tools`;
export const REGULATORY_REPORTS = '/regulatory-reports';
export const CONSOLIDATED_REPORTS = '/consolidated-reports';
export const TOOLS_MCLR = '/tools/mclr';

export const NOTIFICATIONS = '/notifications';
export const ORDER_MANAGEMENT = `${process.env.REACT_APP_CS_HOST}/order-management`;
export const QUICKACCESS_RESOURCES = '/quick-access/resources';
export const QUICKACCESS_SECTORNOTES = '/quick-access/sector-notes';

export const TRANS_LIST_TYPE = '/:type/transactions';

//Common routes
export const POOLS_LIST_PAGE = '/pools/transactions';
export const LOANS_LIST_PAGE = '/loans/transactions';
export const POOL_FILES = '/pool-files/:fileType';
export const PTCV1_TRANSACTION = '/ptcv1/:transId';
export const DAV2_TRANSACTION = '/dav2/:transId';

// Other platforms
export const COLENDING_EXPLORE = `${process.env.REACT_APP_COLEND_HOST}/explore/all`;
export const PLUTUS_EXPLORE = `${process.env.REACT_APP_PLUTUS_HOST}/explore`;
export const SCF_EXPLORE = `${process.env.REACT_APP_SCF_HOST}/explore-programs/all`;

export const LOANS_TRANS_LIST = `${process.env.REACT_APP_LOANS_HOST}/loans/transactions`;
export const BONDS_TRANS_LIST = `${process.env.REACT_APP_BONDS_HOST}/bonds/transactions`;
export const MARKET_DATA = `${process.env.REACT_APP_CS_HOST}/market-data`;

export const PAYOUT_REPORTS = `/payout-report`;

export const PAYOUT_REPORT_CONFIGURATIONS = `${PAYOUT_REPORTS}/configuration`;
export const REPORT_CONFIGURATION = '/:transType/:transId/report-config';
export const PRODUCT_SETUP = '/product-setup';
export const RS_V1 = '/rsv1';
export const RS_V1_MAPPING = `${RS_V1}/:rsId/mapping`;
export const INTEGRATION_REPORTS = '/integration-reports';

export const PUBLIC_ROUTES = [INVESTOR_SIGNUP, SET_PASSWORD, FORGOT_PASSWORD, SURVEY, SURVEY_SUCCESS, HELP_COOKIES];
