import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = [];

// actions
const createActionName = name => `app/ptc/transaction/docs/${name}`;

export const DOCUMENTS_FETCH_SUCCESS = createActionName('DOCUMENTS_FETCH_SUCCESS');
export const DOCUMENTS_FETCH_FAILED = createActionName('DOCUMENTS_FETCH_FAILED');

export default function documents(state = initialState, action) {
    switch (action.type) {
    case DOCUMENTS_FETCH_SUCCESS:
        return [...action.data.legal_documents];
    case DOCUMENTS_FETCH_FAILED:
        return [...initialState];
    default:
        return state;
    }
}

const ptcBaseUrl = id => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

export function getDocuments(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/legal_documents`)
            .then((data) => {
                dispatch({ type: DOCUMENTS_FETCH_SUCCESS, data, id });
                return data;
            })
            .catch(() => dispatch({ type: DOCUMENTS_FETCH_FAILED }));
}
