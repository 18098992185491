import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';
import { formatterType } from 'app/components/ColumnMapper/constants';
import { DATE_PATTERN } from 'app/components/ColumnMapper/Date/constants';
import _get from 'lodash/get';

const initialState = {
    transId: '',
    rsId: '',
    meta: {},
    isFetching: false,
    currentStep: 1,
    frequency: '',
    rows: [],
    formatters: [],
    columnNames: [],
};

// action types
const createActionName = (name) => `app/rsMapping/${name}`;
export const FETCHING_DATA = createActionName('FETCHING_DATA');

export const RS_HANDLE_ACTIVE_STEP = createActionName('RS_HANDLE_ACTIVE_STEP');

export const RS_UPDATE_FORMATTERS = createActionName('RS_UPDATE_FORMATTERS');

export const RS_FETCH_IDS = createActionName('RS_FETCH_IDS');
export const RS_FETCH_META = createActionName('RS_FETCH_META');
export const RS_FETCH_ROWS = createActionName('RS_FETCH_ROWS');
export const RS_FETCH_FORMATTERS = createActionName('RS_FETCH_FORMATTERS');

// reducer
export default function transactions(state = initialState, action) {
    switch (action.type) {
        case RS_FETCH_IDS:
            return Object.assign({}, state, {
                transId: action.transId,
                rsId: action.rsId,
            });
        case RS_FETCH_META:
            return Object.assign({}, state, {
                meta: action.data,
                transId: action.transId,
                rsId: action.rsId,
                frequency: action.data.repaymentFrequency,
            });
        case RS_FETCH_ROWS:
            return Object.assign({}, state, {
                rows: action.data.rows,
            });
        case RS_FETCH_FORMATTERS:
            return Object.assign({}, state, {
                formatters: action.data.formatters,
                rows: action.data.rows,
                columnNames: action.data.columnNames,
            });
        case RS_UPDATE_FORMATTERS:
            return Object.assign({}, state, {
                formatters: action.formatters,
            });
        case RS_HANDLE_ACTIVE_STEP:
            return Object.assign({}, state, {
                currentStep: action.step,
            });
        case FETCHING_DATA:
            return Object.assign({}, state, {
                isFetching: action.status,
            });
        default:
            return state;
    }
}

// actions
export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function handleCurrentStep(step) {
    return {
        type: RS_HANDLE_ACTIVE_STEP,
        step,
    };
}

export function fetchFormatterData(formatter) {
    return {
        type: RS_UPDATE_FORMATTERS,
        formatter,
    };
}

export function fetchRSId(transId, rsId) {
    return {
        type: RS_FETCH_IDS,
        transId,
        rsId,
    };
}

export function fetchRSMeta(transId, rsId, data) {
    return {
        type: RS_FETCH_META,
        data,
        transId,
        rsId,
    };
}

export function fetchRSRows(data) {
    return {
        type: RS_FETCH_ROWS,
        data,
    };
}

export function fetchRSFormatters(data) {
    return {
        type: RS_FETCH_FORMATTERS,
        data,
    };
}

const mapFormatters = (data) => {
    const updatedData = data.map((d) => {
        let result = d;
        if (d.type === formatterType.date) {
            if (_get(d.mappers[0], 'recurringPattern.metaData')) {
                result = { ...result, datePattern: DATE_PATTERN.common };
            } else result = { ...result, datePattern: DATE_PATTERN.loanSpecific };
        }
        if (!_get(d, 'mappers[0]')) {
            result = { ...result, mappers: null };
        }
        return result;
    });
    return { formatters: updatedData };
};

// action creators
export function getRSMeta(rsId) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(fetchRSMeta('', rsId, d));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Repayment Schedules', m);
                });
            });
    };
}

export function getPolledRSMeta(rsId) {
    try {
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                if (_get(d, 'noOfRepayments') > 0) {
                    getRSMeta(rsId);
                    return d;
                }
                return null;
            });
    } catch (e) {
        return e;
    }
}

export function pollForRSMeta(rsId) {
    return fetchUtils.poll(() => getPolledRSMeta(rsId), 180000, 3000);
}

export function getRSRows(rsId, sheetName) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}/rows?sheet=${encodeURIComponent(sheetName)}`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(fetchRSRows(d));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving RS Rows', m);
                });
            });
    };
}

export function getFormatters(rsId) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}/formatters`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => mapFormatters(d))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(fetchRSFormatters(d));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving RS Formatters', m);
                });
            });
    };
}

export function postFormatter(transId, rsId, formatterData, nextPage) {
    const formData = humps.decamelizeKeys(formatterData);
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}/formatters`, formData)
            .then((d) => humps.camelizeKeys(d))
            .then(() => {
                dispatch(fetchingData(false));
                toastr.success('Success!', 'Formatter Data submitted successfully.');
                dispatch(handleCurrentStep(nextPage));
                return dispatch(getFormatters(rsId));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error saving formatter', m);
                });
            });
    };
}

export function updateFormatter(transId, rsId, formatterId, formatterData, nextPage) {
    const formData = humps.decamelizeKeys(formatterData);
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}/formatters/${formatterId}`, formData)
            .then((d) => humps.camelizeKeys(d))
            .then(() => {
                dispatch(fetchingData(false));
                toastr.success('Success!', 'Formatter Data updated successfully.');
                dispatch(handleCurrentStep(nextPage));
                return dispatch(getFormatters(rsId));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error saving formatter', m);
                });
            });
    };
}

const getFormattersData = (rsId, currentStep, signal) => {
    return (dispatch) => {
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${rsId}/formatters`, signal)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                if (!d?.[currentStep].processing) {
                    return dispatch(fetchRSFormatters(mapFormatters(d)));
                }
                return null;
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving RS Formatters', m);
                });
            });
    };
};

export const pollFormatter = (rsId, currentStep, signal) => (dispatch) =>
    fetchUtils.poll(() => dispatch(getFormattersData(rsId, currentStep, signal)), 3000);
