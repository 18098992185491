import { combineReducers } from 'redux';
import details, { getDetails, updateDA, getDAExecDocs } from './details';
import bid, { getBid, placeBid, fetchBid, makeBid } from './bid';
import tasks, { getTasks, actionableTasks, submitTask } from './tasks';
import client, { getClient } from './clients';
import dealPerformance, { getDealPerformance, DEAL_PERFORMANCE_LOADING } from './dealPerformance';
import lossModel, { getLossModel } from './lossModel.ts';

const rootReducer = (state = {}, action) => {
    if (action.id === undefined) return state;

    return {
        [action.id]: combineReducers({
            details,
            bid,
            tasks,
            client,
            lossModel,
            dealPerformance,
        })(state[action.id], action),
    };
};

export default rootReducer;

export const actions = {
    getDetails,
    updateDA,
    getBid,
    placeBid,
    fetchBid,
    makeBid,
    getTasks,
    actionableTasks,
    submitTask,
    getClient,
    getDAExecDocs,
    getDealPerformance,
    DEAL_PERFORMANCE_LOADING,
    getLossModel,
};
