import * as ampTypes from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';
import { PRODUCT_CATEGORY } from 'app/constants/Constants';

const initialState = {
    isMobile: false,
    height: null,
    width: null,
    alert: null,
    platform: PRODUCT_CATEGORY.pools
};

// actions
const createActionName = (name) => `app/env/${name}`;

export const CHANGE_IS_MOBILE = createActionName('CHANGE_IS_MOBILE');
export const CHANGE_WIDTH_AND_HEIGHT = createActionName('CHANGE_WIDTH_AND_HEIGHT');
export const SHOW_ENV_ALERT = createActionName('SHOW_ENV_ALERT');
export const HIDE_ENV_ALERT = createActionName('HIDE_ENV_ALERT');
export const URL_BLOCK_ALERT = createActionName('URL_BLOCK_ALERT');
export const CHANGE_PLATFORM = createActionName('CHANGE_PLATFORM');

export default function environment(state = initialState, action) {
    switch (action.type) {
        case CHANGE_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile,
            };
        case CHANGE_WIDTH_AND_HEIGHT:
            return {
                ...state,
                height: action.height,
                width: action.width,
            };
        case SHOW_ENV_ALERT:
            return {
                ...state,
                alert: { title: action.title, desc: action.desc },
            };
        case HIDE_ENV_ALERT:
            return {
                ...state,
                alert: null,
            };
        case URL_BLOCK_ALERT:
            return {
                ...state,
                alertUser: action.alertUser,
            };
        case CHANGE_PLATFORM: {
            return {
                ...state,
                platform: action.platform,
            }
        }
        default:
            return state;
    }
}

export function showAlert(title, desc) {
    return {
        type: SHOW_ENV_ALERT,
        title,
        desc,
    };
}

export function hideAlert() {
    return {
        type: HIDE_ENV_ALERT,
    };
}

function changeIsMobile(isMobile) {
    return {
        type: CHANGE_IS_MOBILE,
        isMobile,
    };
}

export function urlBlockAlert(alertUser = false) {
    return (dispatch) =>
        new Promise((res, rej) => {
            return res(
                dispatch({
                    type: URL_BLOCK_ALERT,
                    alertUser,
                }),
            );
        }).then(dispatch(urlBlockNotify()));
}

export function urlBlockNotify() {
    return (dispatch) =>
        dispatch({
            type: ampTypes.CREDAVENUE_URL_BLOCKED,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: ampTypes.CREDAVENUE_URL_BLOCKED,
                        },
                    },
                ],
            },
        });
}

export function changeWidthAndHeight(height, width) {
    return {
        type: CHANGE_WIDTH_AND_HEIGHT,
        height,
        width,
    };
}

export function initEnvironment() {
    return (dispatch) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        dispatch(changeIsMobile(isMobile));
        dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));

        window.onresize = () => {
            dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));
        };
    };
}

export function changePlatform(platform) {
    return {
        type: CHANGE_PLATFORM,
        platform,
    }
}
