import React from 'react';

const Alert = ({ type, title, msg, dismiss, children, isHTML = false, ...rest }) => (
    <div className={`alert ${type}`} {...rest}>
        {title && <strong>{title}</strong>}
        {msg && (isHTML ? <div dangerouslySetInnerHTML={{ __html: msg }} /> : <div>{msg}</div>)}
        {dismiss && (
            <button onClick={dismiss} className="close">
                x
            </button>
        )}
        {children}
    </div>
);

export default Alert;
