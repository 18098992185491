import { combineReducers } from 'redux';
import details, { getDetails, getPTCExecDocs, updatePtc, updateMcd, addAttachment, deleteAttachment } from './details';
import bid, { getBid, placeBid, fetchBid, makeBid } from './bid';
import tasks, { getTasks, actionableTasks, submitTask } from './tasks';
import client, { getClient } from './clients';
import costing, { getFixedFees, updateFixedFees, updateCashCollateral, fixedCostLoading, cashCollateralLoading, cashFlowLoading } from './costing';
import lossModel, { getLossModel, pollLossModel } from './lossModel';
import invoice, {
    getInvoice,
    addInvoice,
    updateInvoice,
    addBank,
    getBankDetails,
    deleteInvoice,
    deleteSignedInvoice,
    deleteBankDetails,
    signedInvoiceUpload,
    getInvoiceDetail,
    GET_INVOICE_LOADING,
    SIGNED_INVOICE_UPLOAD_LOADING,
    SIGNED_INVOICE_DELETE_LOADING,
    GET_INVOICE_DETAIL_LOADING,
} from './invoice.ts';
import pricing, { sameEntity, sameSector, otherSector, clientInvestor, sameEntityLoading, sameSectorLoading, otherSectorLoading } from './pricing.ts';
import commodity, { getCommodity, updateBulkCommodities, filterCommodities } from './commodities';

const rootReducer = (state = {}, action) => {
    if (action.id === undefined) return state;

    return {
        [action.id]: combineReducers({
            details,
            bid,
            tasks,
            client,
            costing,
            lossModel,
            pricing,
            commodity,
        })(state[action.id], action),
    };
};

export default rootReducer;

export const actions = {
    getDetails,
    updatePtc,
    updateMcd,
    getBid,
    placeBid,
    fetchBid,
    makeBid,
    getTasks,
    actionableTasks,
    getClient,
    getFixedFees,
    updateFixedFees,
    updateCashCollateral,
    fixedCostLoading,
    cashCollateralLoading,
    cashFlowLoading,
    getLossModel,
    pollLossModel,
    invoice,
    getInvoice,
    addInvoice,
    updateInvoice,
    addBank,
    getBankDetails,
    deleteInvoice,
    deleteSignedInvoice,
    deleteBankDetails,
    signedInvoiceUpload,
    getInvoiceDetail,
    GET_INVOICE_LOADING,
    SIGNED_INVOICE_UPLOAD_LOADING,
    SIGNED_INVOICE_DELETE_LOADING,
    GET_INVOICE_DETAIL_LOADING,
    getPTCExecDocs,
    sameEntity,
    sameSector,
    otherSector,
    clientInvestor,
    sameEntityLoading,
    sameSectorLoading,
    otherSectorLoading,
    submitTask,
    addAttachment,
    deleteAttachment,
    getCommodity,
    updateBulkCommodities,
    filterCommodities,
};
