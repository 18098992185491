import { combineReducers } from 'redux';
import ptc from './ptc';
import client from './client';
import dashboard from './dashboard';
import portfolio from './portfolio.tsx';
import task from '../../../reducers/task';

const rootReducer = combineReducers({
    ptc,
    task,
    client,
    dashboard,
    portfolio,
});

export default rootReducer;
