import { combineReducers } from 'redux';
import customer, { getCustomer } from './customer';

import { ACTION_PREFIX } from './constants';

const rootReducer = (state = {}, action) => {
    if (action.entityId === undefined || !action.type.startsWith(ACTION_PREFIX)) return state;

    return {
        [action.entityId]: combineReducers({
            customer,
        })(state[action.entityId], action),
    };
};

export default rootReducer;

export const entityActions = {
    getCustomer,
};
