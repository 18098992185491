import React, { useState } from 'react';
import Select, { Async } from 'react-select';
import { Element } from 'react-scroll';
import 'react-select/dist/react-select.css';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { connect, Field } from 'formik';
import moment from 'moment';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import _snakeCase from 'lodash/snakeCase';
import { CheckBoxContainer } from 'app/components/TransactionDetail/Common/Attachments/styled';
import styled from 'styled-components';
import { formatDate, parseDate } from '../utils/DateUtils';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/datepicker.css';
import Editor from '../components/RichTextEditor/index';
import { toastr } from 'react-redux-toastr';
import { EditContainer, ViewHistory } from 'app/products/DA/Tools/styled';
import Grid from 'app/products/UIComponentsV1/Grid';

export const months = [
    { label: 'Jan', value: 1 },
    { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 },
    { label: 'Apr', value: 4 },
    { label: 'May', value: 5 },
    { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 },
    { label: 'Aug', value: 8 },
    { label: 'Sep', value: 9 },
    { label: 'Oct', value: 10 },
    { label: 'Nov', value: 11 },
    { label: 'Dec', value: 12 },
];

const AvatarCircle = styled.span`
    position: absolute;
    top: -4px;
    left: 30px;
    background: #eee;
    width: 32px;
    height: 32px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
    &.dark-blue {
        color: #212121;
    }
    &.saffron {
        color: #e4521b;
    }
    &.brown {
        color: #b51f23;
    }
    &.olive-green {
        color: #827622;
    }
    &.violet {
        color: #704da1;
    }
`;

const SearchIcon = styled.span`
    position: absolute;
    z-index: 99;
    top: 12px;
    left: 0px;
    > i {
        font-size: 18px;
        color: #666;
    }
`;

export const preventFormOnEnter = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
};

export const InlineError = ({ message, name = '' }: any) => (
    <span data-name={name} className="error-text">
        {message}
    </span>
);

interface SelectFieldProps {
    field: any;
    label: string;
    options: Array<object>;
    className: string;
    onChangeAction: (v: any) => void;
    containerStyle: string;
    containerClassName: string;
    labelStyle: string;
    error: any;
    labelClassName: string;
    selectOnlyValue: boolean;
    multiSelect: boolean;
    clearable: boolean;
    loadOptions: any;
    disabled: boolean;
    defaultValue: null | string;
    placeholder: string;
    cacheOptions: boolean;
    defaultOptions: boolean;
    valueKey: string;
    labelKey: string;
    multi: boolean;
    value: any;
    name: any;
    form: {
        touched: any;
        errors: any;
        setFieldValue: any;
        setFieldTouched: any;
    };
}

export const SelectField = ({
    field: { name },
    altName,
    field,
    label,
    options,
    labelClass,
    className,
    onChangeAction,
    mandatory,
    containerStyle,
    containerClassName,
    selectOnlyValue,
    multi = false,
    clearable = true,
    loadOptions,
    disabled = false,
    defaultValue = null,
    placeholder = 'Select...',
    form: { touched, setFieldTouched },
    form,
    labelKey = 'label',
    valueKey = 'value',
}: any) => {
    let isError;
    let errorMsg;
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const onChangeHandler = (option: any) => {
        const v = (option && selectOnlyValue ? option[valueKey] : option) || '';
        form.setFieldValue(field.name, v);
        if (typeof onChangeAction === 'function') {
            onChangeAction(v);
        }
    };
    if (altName) {
        isError = _get(form, `errors.${altName}`) && _get(touched, `${altName}`);
        errorMsg = _get(form, `errors.${altName}`);
    } else {
        isError = _get(form, `errors[${field.name}]`) && _get(touched, name);
        errorMsg = _get(form, `errors[${field.name}]`);
    }
    return (
        <div className={isError ? `${containerClass} error` : containerClass}>
            <Element name={name} />
            {label && (
                <label className={`${containerClass} ${labelClass}`} htmlFor={field.name}>
                    {label} {mandatory && <sup className="mandatory">*</sup>}
                </label>
            )}
            {loadOptions ? (
                <Async
                    {...field}
                    name={field.name}
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    value={field.value ? field.value : defaultValue}
                    multi={multi}
                    clearable={clearable}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    loadOptions={loadOptions}
                    onBlur={() => {
                        setFieldTouched(name, true);
                        field.onBlur(field.name, true);
                    }}
                    onChange={onChangeHandler}
                />
            ) : (
                <Select
                    {...field}
                    name={field.name}
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    value={field.value ? field.value : defaultValue}
                    multi={multi}
                    clearable={clearable}
                    options={options}
                    onBlur={() => {
                        setFieldTouched(name, true);
                        field.onBlur(field.name, true);
                    }}
                    onChange={onChangeHandler}
                />
            )}
            {isError && <InlineError name={name} message={errorMsg} />}
        </div>
    );
};

interface RadioProps {
    field: any;
    form: any;
    id: string;
    containerClass: string;
    labelClass: string;
    defValue: number;
    label?: string;
    checked: any;
    inputClass: string;
    onChangeAction?: any;
    customContents?: any;
    disabled?: boolean;
}

export const RadioButton = ({
    field: { name, onChange, onBlur },
    id,
    containerClass,
    defValue,
    label,
    checked,
    inputClass,
    onChangeAction,
    disabled = false,
}: RadioProps) => (
    <div className={containerClass}>
        <Element name={name} />
        <input
            name={name}
            className={inputClass}
            id={id}
            type="radio"
            value={defValue}
            onChange={(e) => {
                onChange(e);
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                }
            }}
            onBlur={onBlur}
            checked={checked}
            disabled={disabled}
        />
        <label htmlFor={id}>{label && label}</label>
    </div>
);

export const RadioButtonBox = ({
    field: { name, onChange, onBlur },
    id,
    containerClass,
    defValue,
    label,
    checked,
    inputClass,
    onChangeAction,
    customContents,
}: RadioProps) => (
    <div className={containerClass}>
        <Element name={name} />
        <input
            name={name}
            className={inputClass}
            id={id}
            type="radio"
            value={defValue}
            onChange={(e) => {
                onChange(e);
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                }
            }}
            onBlur={onBlur}
            checked={checked}
        />
        <div className="box-radio">
            <label htmlFor={id}>{label}</label>
            {customContents}
        </div>
    </div>
);

export const FileUpload = ({ field: { name, onChange }, id, onChangeAction, acceptedTypes, label, inputClass, ...props }: any) => (
    <input
        name={name}
        type="file"
        className={inputClass}
        onChange={(e) => {
            if (acceptedTypes?.split(',').includes(e?.target?.files?.[0]?.type) || !acceptedTypes) {
                onChange(e);
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                }
            } else toastr.error('Error', 'Please upload only XLSX file format');
        }}
        accept={acceptedTypes}
        ref={props.innerRef}
    />
);

export const customRadioField = ({
    field,
    label,
    id,
    containerClass,
    iconClass,
    inputClass,
    onChangeAction,
    innerClass,
    svgIcon: SVG = '',
    active = false,
    disabled = false,
}: any): JSX.Element => (
    <div className={containerClass}>
        <div className={classNames(innerClass || 'fade', { active }, { disabled })}>
            <label htmlFor={label}>
                <input
                    {...field}
                    disabled={disabled}
                    className={inputClass}
                    type="radio"
                    id={label}
                    value={id}
                    onChange={(e) => {
                        field.onChange(e);
                        if (typeof onChangeAction === 'function') {
                            onChangeAction(e);
                        }
                    }}
                />
                {label}
            </label>
            <figure>{SVG ? <SVG /> : <i className={iconClass} />}</figure>
        </div>
    </div>
);

export const customRadioFieldV1 = ({
    field: { name },
    label,
    value,
    containerClass,
    inputClass,
    onChangeAction,
    labelClass,
    children,
    checked,
    disabled,
    form,
    field,
    id,
}: any) => {
    const customLabel = id || _snakeCase(label);
    return (
        <div className={containerClass}>
            <Element name={name} />
            <input
                {...field}
                name={name}
                className={inputClass}
                type="radio"
                id={customLabel}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={(e) => {
                    field.onChange(e);
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e);
                    }
                }}
            />
            <label htmlFor={customLabel} className={labelClass}>
                {label}
            </label>
            {children}
            {_get(form, `errors[${name}]`) && <InlineError name={name} message={_get(form, `errors[${name}]`)} />}
        </div>
    );
};

export const RenderDateFieldV2 = ({
    field: { name, value, ...field }, // { name, value, onChange, onBlur }
    form: { touched, errors, setFieldValue, setFieldTouched, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    fieldClassName,
    labelClassName,
    label,
    mandatory = false,
    handleOnChange,
    dateFormat = 'DD-MMM-YYYY',
    apiFormat = 'DD-MMM-YYYY',
    disabled = false,
    autoComplete = 'off',
    onChange,
    popperModifiers = {},
    ...props
}: any) => {
    const error = _get(errors, name);
    const fieldClass = fieldClassName === undefined ? 'form-group' : fieldClassName;
    const parsedDate = parseDate(value, apiFormat);
    const [selectedDate, setSelectedDate] = useState<any>(parsedDate);
    return (
        <div
            className={classNames(fieldClass, {
                error: error && _get(touched, name),
            })}
        >
            <Element name={name} />
            {label && <label htmlFor={name}>{label}</label>}
            <DatePicker
                name={name}
                value={formatDate(value, dateFormat)}
                selected={_isNull(selectedDate) ? parsedDate : value ? selectedDate : null}
                autoComplete={autoComplete}
                className="form-control"
                showMonthDropdown
                showYearDropdown
                onBlur={() => {
                    setFieldTouched(name, true);
                }}
                onChange={(val) => {
                    if (val) {
                        if (moment.isMoment(val)) {
                            setSelectedDate(val);
                        } else {
                            setSelectedDate(moment(new Date(val)));
                        }
                    }
                    if (typeof onChange === 'function') {
                        onChange(name, formatDate(val, dateFormat));
                    }
                }}
                dateFormat={dateFormat}
                disabled={disabled}
                popperModifiers={popperModifiers}
                {...props}
            />
            {error && <InlineError name={name} message={error} />}
            <i className="icon-calendar" style={error && _get(touched, name) ? { marginBottom: '15px' } : {}} />
        </div>
    );
};

export const CheckboxField = ({ field: { name, onChange }, id, label, inputClass, defValue, onChangeAction, disableLabel = false, checked }: any) => (
    <>
        <Element name={name} />
        <input
            name={name}
            id={id}
            type="checkbox"
            className={inputClass}
            value={defValue}
            checked={checked}
            onClick={(e) => {
                console.log('changed');
            }}
            onChange={(e) => {
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                } else {
                    onChange(e);
                }
            }}
        />
        {!disableLabel && <label htmlFor={id}>{label}</label>}
    </>
);

export const CustomCheckboxField = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    defValue,
    onChangeAction,
    disableLabel = false,
    checked,
    iconClass,
    svgIcon: SVG = '',
    containerClass,
    active = false,
    innerClass,
    singleSelect = false,
    disabled = false,
}: any): JSX.Element => (
    <>
        <div className={containerClass}>
            <div className={classNames(innerClass || 'fade', { active }, { disabled })}>
                <input
                    name={name}
                    id={id}
                    type={!!singleSelect ? 'radio' : 'checkbox'}
                    className={inputClass}
                    value={defValue}
                    checked={checked}
                    onClick={(e) => {}}
                    onChange={(e) => {
                        if (typeof onChangeAction === 'function') {
                            onChangeAction(e);
                        } else {
                            onChange(e);
                        }
                    }}
                    disabled={disabled}
                />
                <figure>{SVG ? <SVG /> : <i className={iconClass} />}</figure>
                {!disableLabel && <label htmlFor={id}>{label}</label>}
            </div>
        </div>
    </>
);

export const renderStyleCheckbox = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    inputClass,
    fieldClass,
    labelClass,
    disableLabel = false,
    containerClass,
    handleChange,
}: any) => (
    <div className={containerClass}>
        <Element name={name} />
        <input
            type="checkbox"
            value={value}
            checked={value}
            onChange={(e: any) => {
                onChange(e);
                if (typeof handleChange === 'function') {
                    handleChange(e);
                }
            }}
            onBlur={onBlur}
            name={name}
            id={id}
            className={inputClass}
        />
        {!disableLabel && (
            <label className={labelClass} htmlFor={id}>
                {label}
            </label>
        )}
    </div>
);

export const renderTextField = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    disableLabel = false,
    form,
    field,
    placeholder,
    onChangeAction,
    disabled = false,
    defValue = '',
}: any) => (
    <>
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <input
            type="text"
            name={name}
            id={id}
            className={inputClass}
            value={value}
            onChange={(e) => {
                onChange(e);
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e.target.value);
                }
            }}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defValue}
        />
    </>
);

export const CheckboxFieldStyled = ({
    field: { name, onChange },
    id = 'ipa-yes',
    label,
    inputClass,
    defValue,
    onChangeAction,
    disableLabel = false,
    checked,
    disabled,
    active,
    containerClass,
    innerClass,
    isAvatarEnable,
}: any) => (
    <div className={containerClass || 'form-group m-b-0'}>
        <div className={classNames(innerClass || 'zero-coupon fade ipa-yes box-radio box-check', { active })}>
            <label htmlFor={id}>
                <input
                    name={name}
                    id={id}
                    type="checkbox"
                    className={inputClass}
                    value={defValue}
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) => {
                        if (typeof onChangeAction === 'function') {
                            onChangeAction(e);
                        } else {
                            onChange(e);
                        }
                    }}
                />
                {isAvatarEnable && <AvatarCircle className="darkblue">{label && label.charAt(0)}</AvatarCircle>}
                {!disableLabel && label}
            </label>
        </div>
    </div>
);

export const buttonCheckboxField = ({ field: { name, onChange }, id, label, inputClass, defValue, onChangeAction, checked, disabled }: any) => (
    <label htmlFor={id} className={checked ? 'selected' : ''}>
        <input
            name={name}
            id={id}
            type="checkbox"
            className={inputClass}
            value={defValue}
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                } else {
                    onChange(e);
                }
            }}
        />
        {label}
    </label>
);

export const renderTextFieldV1 = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    inputClass,
    disableLabel = false,
    form,
    form: { touched, setFieldValue },
    field,
    placeholder,
    fieldClass,
    iconClass,
    autoComplete = 'on',
    onChangeAction,
    icon = '',
    fieldRef,
    disabled = false,
}: any) => (
    <div className={_get(form, `errors[${field.name}]`) && _get(touched, field.name) ? `${fieldClass} error` : fieldClass}>
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <input
            {...field}
            type="text"
            name={name}
            id={id}
            ref={fieldRef}
            className={inputClass}
            value={value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={(e: any) => {
                if (typeof onChangeAction === 'function') {
                    setFieldValue(name, e.target.value);
                    onChangeAction(e.target.value); // custom onChange
                } else {
                    onChange(e);
                }
            }}
        />
        {icon && (
            <SearchIcon>
                <i className={icon} />
            </SearchIcon>
        )}
        {iconClass && <i className={iconClass} />}
        {_get(form, `errors[${field.name}]`) && _get(touched, field.name) && (
            <InlineError name={field.name} message={_get(form, `errors[${field.name}]`)} />
        )}
    </div>
);

export const renderNumberFieldV1 = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    disableLabel = false,
    handleOnChange,
    form: { touched },
    form,
    field,
    placeholder = '',
    noDecimal = false,
    fieldClass,
    readOnly = false,
    disabled,
    allowOnlyNumbers,
    autoComplete = 'on',
    defValue,
}: NumberFieldProps) => (
    <div className={_get(form, `errors[${field.name}]`) && _get(touched, name) ? `${fieldClass} error` : fieldClass}>
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <input
            {...field}
            type="number"
            name={name}
            id={id}
            className={inputClass}
            value={value ?? defValue ?? ''}
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            data-allow-numbers={allowOnlyNumbers}
            onChange={(e: any) => {
                if (typeof handleOnChange === 'function') {
                    handleOnChange(e); // custom onChange
                } else {
                    onChange(e);
                }
            }}
            onKeyPress={
                noDecimal
                    ? (event) => {
                          if (!(event.charCode >= 48 && event.charCode <= 57)) event.preventDefault();
                      }
                    : () => {}
            }
            autoComplete={autoComplete}
        />
        {_get(form, `errors[${field.name}]`) && _get(touched, name) && <InlineError name={name} message={_get(form, `errors[${field.name}]`)} />}
    </div>
);

export const renderTextAreaField = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    disableLabel = false,
    form: { touched },
    form,
    field,
    placeholder,
    fieldClass,
    rows = 3,
    fieldWrapper = '',
    disabled = false,
}: any) => {
    const error = _get(form, `errors[${field.name}]`);
    const fieldClassName = fieldClass || 'form-group';
    return (
        <div
            className={classNames(fieldClassName, {
                error: error && _get(touched, 'name'),
            })}
        >
            <Element name={name} />
            {!disableLabel && <label htmlFor={id}>{label}</label>}
            <div className={fieldWrapper}>
                <textarea
                    {...field}
                    name={name}
                    id={id}
                    className={inputClass}
                    placeholder={placeholder}
                    value={value || ''}
                    rows={rows}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
            {error && _get(touched, 'name') && <InlineError name={name} message={error} />}
        </div>
    );
};

export const renderFieldWithUnit = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    disableLabel = false,
    form: { touched },
    form,
    field,
    units,
    unitsClass,
    type = 'text',
    defaultValue = null,
    fieldClass,
    disabled = false,
    inputProps = {},
    placeholder = '',
    normalize = '',
    ...props
}: any) => (
    <div className={_get(form, `errors[${field.name}]`) && _get(touched, name) ? `${fieldClass} error` : fieldClass}>
        <Element name={name} />
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <div className="input-group">
            <input
                {...inputProps}
                {...props}
                name={name}
                type={type}
                id={id}
                className={inputClass}
                value={value || defaultValue || ''}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
            />
            <div className={unitsClass}>{units}</div>
        </div>
        {_get(form, `errors[${field.name}]`) && _get(touched, name) && <InlineError name={name} message={_get(form, `errors[${field.name}]`)} />}
    </div>
);

interface NumberFieldProps {
    labelClass?: string;
    id: string;
    label: string;
    inputClass: string;
    disableLabel: boolean;
    form: {
        touched: any;
        errors: any;
        setFieldValue: any;
    };
    field: Field;
    noDecimal: boolean;
    unitsClass: string;
    units: string;
    step: string;
    handleOnChange: (e: any) => void;
    fieldClass?: string;
    readOnly?: boolean;
    disabled?: boolean;
    allowOnlyNumbers?: boolean;
    autoComplete?: string;
    placeholder?: string;
    allowZero?: boolean;
    defValue?: number;
    canEdit?: boolean;
    viewHistory?: boolean;
    handleEditClick: () => void;
    handleViewHistoryClick: () => void;
}

interface Field {
    name: string;
    value: number;
    onChange: (arg: any) => void;
}

export const renderNumberField = ({
    field: { name, value, onChange },
    field,
    id,
    label,
    inputClass,
    disableLabel = false,
    handleOnChange,
    form,
    noDecimal = false,
}: NumberFieldProps) => (
    <>
        <Element name={name} />
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <input
            {...field}
            type="number"
            name={name}
            id={id}
            className={inputClass}
            value={value}
            onChange={(e: any) => {
                if (typeof handleOnChange === 'function') {
                    handleOnChange(e); // custom onChange
                } else {
                    onChange(e);
                }
            }}
            onKeyPress={
                noDecimal
                    ? (event) => {
                          if (!(event.charCode >= 48 && event.charCode <= 57)) event.preventDefault();
                      }
                    : () => {}
            }
        />
        {_get(form, `errors[${name}]`) && <InlineError name={name} message={_get(form, `errors[${name}]`)} />}
    </>
);

export const renderNumberFieldWithUnit: React.FC<NumberFieldProps> = ({
    field: { name, value, onChange },
    field,
    id,
    label,
    inputClass,
    labelClass,
    fieldClass,
    disableLabel = false,
    form,
    form: { touched },
    unitsClass,
    units,
    handleOnChange,
    step = 'any',
    noDecimal = false,
    disabled = false,
    allowZero = false,
    canEdit = false,
    viewHistory = false,
    handleEditClick,
    handleViewHistoryClick,
}) => (
    <div className={_get(form, `errors[${field.name}]`) && _get(touched, name) ? `${fieldClass} error` : fieldClass}>
        <Element name={name} />
        <Grid type="flex" container alignItems="center" justify="space-between">
            {!disableLabel && (
                <label htmlFor={id} className={labelClass}>
                    {label}
                </label>
            )}
            {viewHistory && <ViewHistory onClick={handleViewHistoryClick}>View history</ViewHistory>}
        </Grid>
        <div className="input-group">
            <input
                {...field}
                type="number"
                step={step}
                name={name}
                id={id}
                className={inputClass}
                disabled={disabled}
                value={value ? value : value === 0 ? (allowZero ? value : '') : ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): any => {
                    if (typeof handleOnChange === 'function') {
                        handleOnChange(e); // custom onChange
                    }
                    return onChange(e);
                }}
                onKeyPress={
                    noDecimal
                        ? (event) => {
                              if (!(event.charCode >= 48 && event.charCode <= 57)) event.preventDefault();
                          }
                        : () => {}
                }
            />
            {canEdit && (
                <EditContainer className="edit-class" onClick={handleEditClick}>
                    Edit
                </EditContainer>
            )}
            <div className={unitsClass}>{units}</div>
        </div>
        {_get(form, `errors[${name}]`) && _get(touched, name) && <InlineError name={name} message={_get(form, `errors[${name}]`)} />}
    </div>
);

export const renderPasswordField = ({ field: { name, value, onChange }, id, label, inputClass, disableLabel = false }: any) => (
    <>
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <input type="password" name={name} id={id} className={inputClass} value={value} onChange={onChange} />
    </>
);

interface ICheckboxGroup {
    options: {
        value: string;
        label: string;
    }[];
    field: {
        name: string;
        value: string[];
        onChange: (e: any) => void;
    };
    onChangeAction: any;
    disabled: boolean;
    form: any;
}

export const CheckboxGroup: React.FC<ICheckboxGroup> = ({
    options,
    onChangeAction,
    disabled,
    field: { name, value = [], onChange },
    form: { setFieldValue, setFieldTouched },
}) => {
    const handleCheckBox = (event: any, val: string) => {
        const arr = [...value];
        if (event.target.checked) {
            arr.push(val);
        } else {
            arr.splice(arr.indexOf(val), 1);
        }
        setFieldTouched(name);
        if (typeof onChangeAction === 'function') onChangeAction(arr);
        return setFieldValue(name, arr);
    };

    return (
        <div className="form-group checkbox-form-group">
            <div className="checkbox-control-group">
                <ul className="list-inline counterparties-list">
                    {options.map((item: any, index) => (
                        <li key={index}>
                            <CheckBoxContainer>
                                <input
                                    type="checkbox"
                                    name={item.value}
                                    value={item.value}
                                    checked={value.includes(item.value)}
                                    onChange={(e: any) => handleCheckBox(e, item.value)}
                                    disabled={disabled}
                                    className="styled-checkbox"
                                />
                                <label htmlFor={item.value}>{item.label}</label>
                            </CheckBoxContainer>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
/** FIXME */
/* tslint:disable */
const ClassBasedEditor: any = Editor; // Delete me after refactoring <Editor /> to TS
/* tslint:enable */

export const renderRichTextField = ({
    field: { name, value, onChange },
    id,
    label,
    inputClass,
    disableLabel = false,
    form,
    editorRef,
    onChangeAction,
}: any) => (
    <>
        <Element name={name} />
        {!disableLabel && <label htmlFor={id}>{label}</label>}
        <div className={classNames('editor', inputClass)} style={{ width: '100%', maxWidth: 600 }}>
            <ClassBasedEditor ref={editorRef} onChange={(v: any) => onChangeAction(v)} />
        </div>
        {_get(form, `errors[${name}]`) && <InlineError name={name} message={_get(form, `errors[${name}]`)} />}
    </>
);

export const SwitchField = ({
    field: { name, onChange },
    id,
    label,
    inputClass,
    defValue,
    onChangeAction,
    disableLabel = false,
    checked,
    disabled,
    className,
}: any) => (
    <span className={classNames('switch-field', { checked }, { disabled }, className)}>
        {!disableLabel && <span>{label}</span>}
        <label className="switch" htmlFor={id}>
            <input
                name={name}
                id={id}
                type="checkbox"
                className={inputClass}
                value={defValue}
                checked={checked}
                disabled={disabled}
                onClick={(e) => {}}
                onChange={(e) => {
                    e.stopPropagation();
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e);
                    } else {
                        onChange(e);
                    }
                }}
            />
            <span className={classNames('slider-input-field', { disabled })} />
        </label>
    </span>
);

const ErrorFocus = ({ formik: { isSubmitting, isValidating, errors } }: any) => {
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
        document.querySelector<any>(`[name="${keys[0]}"]`).focus();
    }

    return null;
};

export const ConnectErrorFocus = connect(ErrorFocus);
