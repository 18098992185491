import TagManager from 'react-gtm-module';

const loadGtm = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-P8L3BBB',
    };
    TagManager.initialize(tagManagerArgs);
};

export default loadGtm;
