import { StyleValue } from '../../UIComponents/Types/CommonTypes';
import { getStyleValue } from '../../UIComponents/Utils/StyleUtils';
import React, { MutableRefObject, BaseHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export type GridType = 'grid' | 'flex';

export type AlignItems =
    | 'baseline'
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'inherit'
    | 'initial'
    | 'left'
    | 'normal'
    | 'right'
    | 'safe'
    | 'self-end'
    | 'self-start'
    | 'start'
    | 'stretch';

export type JustifyContent =
    | 'baseline'
    | 'center'
    | 'end'
    | 'flex-end'
    | 'flex-start'
    | 'inherit'
    | 'initial'
    | 'left'
    | 'normal'
    | 'right'
    | 'safe'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'start'
    | 'stretch';

export type DirectionType = 'row' | 'column';
interface GridProps extends React.BaseHTMLAttributes<HTMLDivElement> {
    container?: boolean;
    item?: boolean;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    grow?: boolean;
    shrink?: boolean;
    flex?: number | string;
    spacing?: number;
    gap?: number;
    gutter?: number;
    wrap?: 'wrap' | 'nowrap';
    direction?: DirectionType;
    justify?: JustifyContent;
    alignItems?: AlignItems;
    breakpoint?: string;
    basis?: string;
    type?: GridType;
    grid?: boolean;
    ref?: MutableRefObject<any>;

    columns?: string | number;
    rows?: string | number;
    rowGap?: StyleValue;
    columnGap?: StyleValue;
    gridGap?: StyleValue;
    columnWidth?: string;
    rowWidth?: string;
    templateColumns?: string;
    templateRows?: string;
    templateAreas?: string;
    template?: string;
    placeItems?: AlignItems;

    columnStart?: number | string;
    columnEnd?: number | string;
    rowStart?: number | string;
    rowEnd?: number | string;
    gridRow?: string;
    gridColumn?: number | string;
    gridArea?: number | string;
    justifySelf?: JustifyContent;
    alignSelf?: AlignItems;
    placeSelf?: AlignItems;
}

interface StyledGridProps extends GridProps {
    breakPoint: number;
}

const StyledGrid = styled.div<StyledGridProps>`
    height: inherit;
    ${({ justify }) =>
        justify &&
        `
        justify-content: ${justify};
    `}
    ${({ alignItems }) =>
        alignItems &&
        `
        align-items: ${alignItems};
    `}

    ${({ item }) =>
        item &&
        css<any>`
            ${({ columnStart }) =>
                columnStart &&
                `
            grid-column-start: ${columnStart};
        `}
            ${({ columnEnd }) =>
                columnEnd &&
                `
            grid-column-end: ${columnEnd};
        `}
        ${({ rowStart }) =>
            rowStart &&
            `
            grid-row-start: ${rowStart};
        `}
        ${({ rowEnd }) =>
            rowEnd &&
            `
            grid-row-end: ${rowEnd};
        `}
        ${({ gridColumn }) =>
            gridColumn &&
            `
            grid-column: ${gridColumn};
        `}
        ${({ gridRow }) =>
            gridRow &&
            `
            grid-row: ${gridRow};
        `}
        ${({ gridArea }) =>
            gridArea &&
            `
            grid-area: ${gridArea};
        `}
        ${({ justifySelf }) =>
            justifySelf &&
            `
            justify-self: ${justifySelf};
        `}
        ${({ alignSelf }) =>
            alignSelf &&
            `
            align-self: ${alignSelf};
        `}
        ${({ placeSelf }) =>
            placeSelf &&
            `
            place-self: ${placeSelf};
        `}
        `}

    ${({ type }) =>
        type &&
        type === 'grid' &&
        css<any>`
            & {
                display: grid;
                ${({ columns, columnWidth }) =>
                    columns &&
                    columns !== 'unset' &&
                    `
                grid-template-columns: repeat(${columns}, ${columnWidth});
            `}
                ${({ rows, rowWidth }) =>
                    rows &&
                    `
                grid-template-rows: repeat(${rows}, ${rowWidth});
            `}
            ${({ rowGap }) =>
                rowGap &&
                `
                grid-row-gap: ${getStyleValue(rowGap)};
            `}
            ${({ columnGap }) =>
                columnGap &&
                `
                grid-column-gap:  ${getStyleValue(columnGap)};
            `}
            ${({ gridGap }) =>
                gridGap &&
                `
                grid-gap: ${getStyleValue(gridGap)};
            `}
            ${({ templateColumns }) =>
                templateColumns &&
                `
                grid-template-columns: ${templateColumns};
            `}
            ${({ templateRows }) =>
                templateRows &&
                `
                grid-template-rows: ${templateRows};
            `}
            ${({ templateAreas }) =>
                templateAreas &&
                `
                grid-template-areas: ${templateAreas};
            `}
            ${({ template }) =>
                template &&
                `
                grid-template: ${template};
            `}
            ${({ placeItems }) =>
                placeItems &&
                `
                place-items: ${placeItems};
            `}
            }
        `}

    ${({ type }) =>
        type === 'flex' &&
        css<any>`
            & {
                flex-grow: ${({ grow }) => (grow ? 1 : 'unset')};
                flex-shrink: ${({ shrink }) => (shrink ? 1 : 'unset')};
                flex-basis: ${({ basis }) => basis};
                ${({ item, gutter, spacing }) =>
                    item &&
                    `
                margin: ${gutter * 4}px;
                padding: ${spacing * 4}px;
            `}
                ${({ flex }) =>
                    flex &&
                    `
                flex: ${flex};
            `}
            ${({ container, breakPoint, direction, justify, alignItems, spacing, gutter, gap, wrap }) =>
                container &&
                `
                display: flex;
                flex-wrap: ${wrap};
                flex-direction: ${direction};
                >.vc-grid-item {
                    flex-basis: ${
                        breakPoint > 0 ? (gap ? `calc(${(breakPoint / 12) * 100}% - ${gap * 4 * 2}px)` : `${(breakPoint / 12) * 100}%`) : 'unset'
                    };
                    height: ${breakPoint > 0 ? 'min-content' : 'unset'};
                }
                >.vc-grid-item {
                    margin: ${gap * 4}px;
                    padding: ${spacing * 4}px;
                    &.options{
                        position:relative;
                        margin-left:auto;
                        max-width:255px;
                        >.input-group{
                            width:100%;
                            input{
                                border-top-left-radius:3px!important;
                                border-bottom-left-radius:3px!important;
                                height:38px;
                                &:focus{
                                    border-color:#0070D2;
                                    border-right:none;
                                    + .input-group-addon{
                                        border-color:#0070D2;
                                    }
                                }
                                + .input-group-addon{
                                    font-size: 15px;
                                }
                            }
                        }
                        > p{
                            color: #66686f;
                            font-weight: normal;
                            text-transform: capitalize;
                            margin-right:5px;
                        }
                        > label{
                            position: absolute;
                            color: #66686f;
                            font-weight: normal;
                            text-transform: capitalize;
                            top: -9px;
                            left: 6px;
                            background: #fff;
                            padding: 0px 10px;
                        }
                    }
                }
            `}
            ${({ container, gap }) =>
                container &&
                gap &&
                `
                & {
                    margin: 0 -${gap * 4}px;
                }
            `}
            }
        `}
`;

const Grid = React.forwardRef<BaseHTMLAttributes<HTMLDivElement>, GridProps>(
    (
        {
            container,
            item,
            children,
            className,
            xs,
            sm,
            md,
            lg,
            xl,
            spacing = 0,
            gutter = 0,
            gap = 0,
            direction = 'row',
            wrap,
            breakpoint,
            basis,
            type = 'flex',
            grid = false,
            columnWidth = '1fr',
            rowWidth = '1fr',
            ...props
        },
        ref: any,
    ): JSX.Element => {
        className = className ? `${className} vc-grid` : 'vc-grid';
        className = item ? `${className} vc-grid-item` : className;
        className = container ? `${className} vc-grid-container` : className;

        const getDeviceInfo = () => {
            const { innerWidth } = window;
            if (innerWidth >= 320 && innerWidth <= 480) return 'xs';
            if (innerWidth >= 481 && innerWidth <= 767) return 'sm';
            if (innerWidth >= 768 && innerWidth <= 1024) return 'md';
            if (innerWidth >= 1025 && innerWidth <= 1280) return 'lg';
            if (innerWidth >= 1281) return 'xl';
            return 'xl';
        };

        const device = getDeviceInfo();
        const breakPoint = (() => {
            switch (device) {
                case 'xs':
                    return xs || -1;
                case 'sm':
                    return sm || xs || -1;
                case 'md':
                    return md || sm || -1;
                case 'lg':
                    return lg || md || sm || xs || -1;
                case 'xl':
                    return xl || lg || md || sm || xs || -1;
                default:
                    return -1;
            }
        })();

        return (
            <StyledGrid
                className={`${className} dvc-${breakPoint}`}
                item={item}
                container={container}
                direction={direction}
                spacing={spacing}
                gutter={gutter}
                gap={gap}
                breakPoint={breakPoint}
                basis={basis}
                wrap={wrap || (breakPoint === -1 ? 'nowrap' : 'wrap')}
                type={type}
                columnWidth={columnWidth}
                rowWidth={rowWidth}
                ref={ref}
                {...props}
            >
                {children}
            </StyledGrid>
        );
    },
);

export default Grid;
