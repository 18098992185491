import React, { Fragment } from 'react';
import Select, { Async } from 'react-select';
import 'react-select/dist/react-select.css';
import classNames from 'classnames';
import { scroller, Element } from 'react-scroll';
import _get from 'lodash/get';
// DatePicker
import DatePicker from 'react-datepicker';
import DropZone from 'react-dropzone';
import moment from 'moment';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/datepicker.css';

export const Info = styled.span`
    color: #de8500 !important;
    text-transform: none;
    font-size: 12px !important;
    vertical-align: middle;
    line-height: 16px;
    i {
        color: #de8500 !important;
        margin-right: 6px;
        margin-left: 10px;
        font-size: 15px !important;
        margin-bottom: 2px;
        display: inline-block;
    }
`;

export const ContentWrap = styled.div`
    position: absolute;
    ${({ variant }) => `top: ${variant === 'large' ? 33 : 27}px`};
    ${({ variant }) => `left: ${variant === 'large' ? 8 : -4}px`};
    button {
        height: 34px;
        box-shadow: none;
        margin-top: -4px;
        margin-left: 18px;
    }
`;

export const renderField = ({
    input,
    label,
    type,
    className,
    placeholder,
    onChangeAction,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    disabled = false,
    meta: { touched, error, warning, active },
    inputProps = {},
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div
            className={classNames(`${containerClass}`, { error: error && touched }, { active: active || (input.value && input.value.length > 0) })}
            style={containerStyle}
        >
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <input
                {...input}
                {...inputProps}
                disabled={disabled}
                className={className}
                placeholder={placeholder}
                type={type}
                step="any"
                onChange={(e) => {
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e.target.value);
                    }
                    input.onChange(e.target.value);
                }}
            />
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderNumberField = ({
    input,
    label,
    type = 'text',
    className,
    placeholder,
    onChangeAction,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    disabled = false,
    meta,
    meta: { touched, error, warning, active },
    inputProps = {},
    noDecimal = false,
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div
            className={classNames(`${containerClass}`, { error: error && touched }, { active: active || (input.value && input.value.length > 0) })}
            style={containerStyle}
        >
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <input
                {...input}
                {...inputProps}
                disabled={disabled}
                className={className}
                placeholder={placeholder}
                type={type}
                step="any"
                onChange={(e) => {
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e.target.value);
                    }
                    input.onChange(e.target.value);
                }}
                onKeyPress={
                    noDecimal
                        ? (event) => {
                              if (!(event.charCode >= 48 && event.charCode <= 57)) event.preventDefault();
                          }
                        : (event) => {
                              if (!(event.charCode >= 46 && event.charCode <= 57)) event.preventDefault();
                          }
                }
            />
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderTextAreaField = ({
    input,
    label,
    rows,
    className,
    placeholder,
    onChangeAction,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    disabled = false,
    meta: { touched, error, warning },
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div className={error && touched ? `${containerClass} error` : containerClass} style={containerStyle}>
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <textarea
                {...input}
                disabled={disabled}
                className={className}
                placeholder={placeholder}
                rows={rows || 3}
                onChange={(e) => {
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e.target.value);
                    }
                    input.onChange(e.target.value);
                }}
            />
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderCheckField = ({
    input,
    label,
    className,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    disabled = false,
    meta: { touched, error },
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div className={error && touched ? `${containerClass} checkbox error` : `${containerClass} checkbox`} style={containerStyle}>
            {input.name && <Element name={`position-${input.name}`} />}
            <label className={labelClass} style={labelStyle}>
                <input {...input} checked={input.value} disabled={disabled} className={className} type="checkbox" />
                {label}
            </label>
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const CheckboxField = ({ input: { name, onChange }, id, label, inputClass, defValue, onChangeAction, disableLabel = false, checked }) => (
    <React.Fragment>
        <input
            name={name}
            id={id}
            type="checkbox"
            className={inputClass}
            value={defValue}
            checked={checked}
            onChange={(e) => {
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e);
                } else {
                    onChange(e);
                }
            }}
        />
        {!disableLabel && <label htmlFor={id}>{label}</label>}
    </React.Fragment>
);

export const styledCheckField = ({
    input,
    label,
    className,
    active = input.value ? input.value : false,
    disabled = false,
    onChangeAction,
    showTick = true,
}) => (
    <div className="form-group m-b-0">
        <div className={classNames('zero-coupon fade ipa-yes box-radio box-check', { active })}>
            <label htmlFor={input.name}>
                <input {...input} checked={input.value} disabled={disabled} className={className} type="checkbox" onClick={onChangeAction} />
                {label}
            </label>
            {showTick && <i className="icon-tick2 txt-green" />}
        </div>
    </div>
);

export const renderRadioField = ({ input, label, customName, isDefaultChecked, inputClass, onChangeAction, disabled = false }) => (
    <div>
        {_get(input, 'name') && <Element name={`position-${_get(input, 'name')}`} />}
        <input
            id={label || customName}
            disabled={disabled}
            className={inputClass}
            type="radio"
            value={customName || ''}
            checked={isDefaultChecked || false}
            onChange={onChangeAction}
        />
        <label htmlFor={label || customName}>{label}</label>
    </div>
);

export const renderRadioFieldV1 = ({ input, label, inputValue, isDefaultChecked, onChangeAction, disabled = false, id }) => (
    <Fragment>
        {input.name && <Element name={`position-${input.name}`} />}
        <input id={id} {...input} disabled={disabled} type="radio" value={inputValue} defaultChecked={isDefaultChecked} />
        <label htmlFor={id}>{label}</label>
    </Fragment>
);

export const renderSelectField = ({
    input,
    label,
    children,
    className,
    placeholder,
    onChangeAction,
    containerStyle,
    containerClassName,
    labelStyle,
    labelClassName,
    disabled = false,
    meta: { touched, error, warning },
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;

    return (
        <div className={error && touched ? `${containerClass} error` : containerClass} style={containerStyle}>
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <select
                {...input}
                placeholder={placeholder}
                className={className}
                disabled={disabled}
                onChange={(e) => {
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e.target.value);
                    }
                    input.onChange(e.target.value);
                }}
            >
                {children}
            </select>
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderOnlySelectField = ({ input, children, className, placeholder, onChangeAction, disabled = false, meta: { error } }) => (
    <Fragment>
        {input.name && <Element name={`position-${input.name}`} />}
        <select
            {...input}
            placeholder={placeholder}
            className={className}
            disabled={disabled}
            onChange={(e) => {
                if (typeof onChangeAction === 'function') {
                    onChangeAction(e.target.value);
                }
                input.onChange(e.target.value);
            }}
        >
            {children}
        </select>
        {error && <span className="error-text">{error}</span>}
    </Fragment>
);

export const renderCustomSelectField = ({
    input,
    label,
    options,
    className,
    onChangeAction,
    containerStyle,
    containerClassName,
    labelStyle,
    labelClassName,
    selectOnlyValue,
    labelKey = 'label',
    valueKey = 'value',
    multiSelect = false,
    clearable = true,
    loadOptions,
    disabled = false,
    defaultValue = null,
    placeholder = 'Select...',
    meta: { touched, error },
    input: { value },
    infoIcon,
    infoMsg = '',
}) => {
    const onChangeHandler = (s) => {
        if (!multiSelect) {
            // FIXME: select only value does'nt work for async option. Needs to be fixed
            let v = '';
            if (typeof s !== 'undefined' && s !== null && selectOnlyValue) {
                v = s[valueKey];
            } else {
                v = s;
            }
            input.onChange(v);
            if (typeof onChangeAction === 'function') {
                onChangeAction(v);
            }
        } else {
            input.onChange(s);
        }
    };
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div className={error && typeof error !== 'object' && touched ? `${containerClass} error` : containerClass} style={containerStyle}>
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                    {infoIcon && (
                        <Info>
                            <i className="icon-information" />
                            {infoMsg}
                        </Info>
                    )}
                </label>
            )}
            {loadOptions ? (
                <Async
                    {...input}
                    disabled={disabled}
                    className={className}
                    options={options}
                    clearable={clearable}
                    onBlur={() => {}}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    loadOptions={loadOptions}
                    onChange={onChangeHandler}
                    multi={multiSelect}
                    value={value || defaultValue}
                    placeholder={placeholder}
                />
            ) : (
                <Select
                    {...input}
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    value={value || defaultValue}
                    multi={multiSelect}
                    clearable={clearable}
                    options={options}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    onBlur={() => {}}
                    onChange={onChangeHandler}
                />
            )}
            {error && typeof error !== 'object' && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderDateField = ({
    input,
    label,
    className,
    onChangeAction,
    containerStyle,
    containerClassName,
    labelStyle,
    labelClassName,
    disabled = false,
    dateFormat,
    valueFormat,
    minDate,
    maxDate,
    meta: { touched, error },
    selectOnlyEndOfMonth = false,
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    const format = dateFormat || 'DD-MMM-YYYY';
    const vFormat = valueFormat || 'YYYY-MM-DD';
    const selectedValue = input.value ? (moment.isMoment(input.value) ? input.value : moment(input.value)) : null;
    return (
        <div className={error && touched ? `${containerClass} error` : containerClass} style={containerStyle}>
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}

            <DatePicker
                disabled={disabled}
                className={className}
                selected={selectedValue}
                minDate={minDate}
                maxDate={maxDate}
                showMonthDropdown
                showYearDropdown
                onChange={(v) => {
                    input.onChange(moment.isMoment(v) ? v.format(vFormat) : v);
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(v);
                    }
                }}
                dateFormat={format}
            />
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderFieldWithUnit = ({
    input,
    label,
    type,
    className,
    placeholder,
    onChangeAction,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    defaultValue = '',
    units,
    unitsClass,
    disabled = false,
    meta: { touched, error, active },
    inputProps = {},
    input: { value },
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div
            className={classNames(`${containerClass}`, { error: error && touched }, { active: active || input.value.length > 0 })}
            style={containerStyle}
        >
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <div className="input-group">
                <input
                    {...input}
                    {...inputProps}
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    type={type}
                    value={value || defaultValue}
                    onChange={(e) => {
                        if (typeof onChangeAction === 'function') {
                            onChangeAction(e.target.value);
                        }
                        input.onChange(e.target.value);
                    }}
                />
                <div className={unitsClass}>{units}</div>
            </div>
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const renderImageUploadField = ({
    onDrop,
    onDropAccepted,
    onDropRejected,
    onChangeAction,
    input,
    imageFile,
    acceptedFormats,
    label,
    maxSize,
    containerClassName,
    containerStyle,
    containerLabel,
    dropzoneClassName,
    dropzoneStyle,
    activeClassName,
    meta: { error, touched },
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const dropzoneClass = dropzoneClassName === undefined ? 'upload-btn-wrapper' : dropzoneClassName;
    const containerLabelContent = containerLabel || 'Drag &amp; drop or <button>Browse</button> files to attach';
    const activeClass = activeClassName || {
        padding: 5,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: '#d2d1cf',
        backgroundColor: '#f5f5f5',
    };
    const accept = acceptedFormats || 'image/jpeg, image/png, image/jpg';
    const renderImage = (src) => (
        <figure>
            <img src={src} alt="Entity Logo" />
        </figure>
    );
    const renderImageUpload = (placeholder) => (
        <React.Fragment>
            <i className="icon-cloud-storage-uploading-option" />
            <p>{placeholder}</p>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className={classNames(`${containerClass}`, { error: error && touched })} style={containerStyle}>
                {input.name && <Element name={`position-${input.name}`} />}
                {label && <label htmlFor={input.name}>{label}</label>}
                <DropZone
                    accept={accept}
                    multiple={false}
                    onDrop={onDrop}
                    maxSize={maxSize || 1048576} // defaults to 1MB, can be configured
                    onDropAccepted={(files) => {
                        const [file] = files;
                        input.onChange(file);
                        onDropAccepted(file);
                    }}
                    onDropRejected={onDropRejected}
                    onChange={(e) => {
                        e.stopPropagation();
                        if (typeof onChangeAction === typeof Function) {
                            onChangeAction(e);
                        }
                        input.onChange(e.target.value);
                    }}
                    className={classNames(dropzoneClass, { hover: imageFile })}
                    activeStyle={activeClass}
                    style={dropzoneStyle}
                >
                    <React.Fragment>{imageFile ? renderImage(imageFile.preview) : renderImageUpload(containerLabelContent)}</React.Fragment>
                </DropZone>
            </div>
        </React.Fragment>
    );
};

export const styledRadioField = ({
    input,
    label,
    inputVal,
    meta: { error },
    active = false,
    innerClassName,
    containerClassName,
    inputClassName,
    iconClassName,
    onChangeAction,
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const innerClass = innerClassName === undefined ? 'zero-coupon fade rating-no box-radio' : innerClassName;
    const inputClass = inputClassName === undefined ? 'styled-radio' : inputClassName;
    return (
        <div className={containerClass}>
            <div className={classNames(innerClass, { active })}>
                {input.name && <Element name={`position-${input.name}`} />}
                <label>
                    <input {...input} className={inputClass} type="radio" value={inputVal} onClick={onChangeAction} />
                    {label}
                </label>
                <i className={iconClassName} />
            </div>
        </div>
    );
};

export const customRadioField = ({ input, label, inputVal, className, disabled = false, onChangeAction, meta: { error } }) => (
    <React.Fragment>
        {input.name && <Element name={`position-${input.name}`} />}
        <input id={label} {...input} disabled={disabled} className={className} type="radio" value={inputVal} onClick={onChangeAction} />
        <label htmlFor={label}>{label}</label>
        {error && <span className="error-text">{error}</span>}
    </React.Fragment>
);

export const renderFieldWithCountryCode = ({
    input,
    label,
    type,
    className,
    placeholder,
    onChangeAction,
    containerClassName,
    containerStyle,
    labelClassName,
    labelStyle,
    disabled = false,
    meta: { touched, error, warning, active },
    inputProps = {},
    content,
    variant,
}) => {
    const containerClass = containerClassName === undefined ? 'form-group' : containerClassName;
    const labelClass = labelClassName === undefined ? 'control-label' : labelClassName;
    return (
        <div
            className={classNames(`${containerClass}`, { error: error && touched }, { active: active || (input.value && input.value.length > 0) })}
            style={containerStyle}
        >
            {input.name && <Element name={`position-${input.name}`} />}
            {label && (
                <label className={labelClass} htmlFor={input.name} style={labelStyle}>
                    {label}
                </label>
            )}
            <input
                {...input}
                {...inputProps}
                disabled={disabled}
                className={className}
                placeholder={placeholder}
                type={type}
                step="any"
                style={{ paddingLeft: 115 }}
                onChange={(e) => {
                    if (typeof onChangeAction === 'function') {
                        onChangeAction(e.target.value);
                    }
                    input.onChange(e.target.value);
                }}
            />
            {content && (
                <ContentWrap variant={variant} className="input-inner-content-wrap">
                    {content}
                </ContentWrap>
            )}
            {error && <span className="error-text">{error}</span>}
        </div>
    );
};

export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value;
    }
    return value.replace(/[^\d]/g, '');
};

export const onlyDecimal = (v) => {
    const strValue = v.toString();
    const value = strValue.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .

    // Create an array with sections split by .
    const sections = value.split('.');

    // Remove any leading 0s apart from single 0
    if (sections[0] !== '0' && sections[0] !== '00') {
        sections[0] = sections[0].replace(/^0+/, '');
    } else {
        sections[0] = '0';
    }

    // If numbers exist after first .
    if (sections[1]) {
        // Join first two sections and truncate end section to length 2
        return `${sections[0]}.${sections[1].slice(0, 2)}`;
        // If original value had a decimal place at the end, add it back
    } else if (value.indexOf('.') !== -1) {
        return `${sections[0]}.`;
        // Otherwise, return only section
    }
    return sections[0];
};

// Helper functions for scroll and focusing error fields

const flattenFields = (arr) => arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flattenFields(toFlatten) : toFlatten), []);

const getErrorFieldNames = (obj, name = '') => {
    const errorArr = [];
    errorArr.push(
        Object.keys(obj)
            .map((key) => {
                const next = obj[key];
                if (next) {
                    if (typeof next === 'string') {
                        return name + key;
                    }
                    if (next.map) {
                        errorArr.push(next.map((item, index) => getErrorFieldNames(item, `${name}${key}[${index}].`)).filter((o) => o));
                    }
                }
                return null;
            })
            .filter((o) => o),
    );
    return flattenFields(errorArr);
};

export const scrollToFirstError = (errors) => {
    const errorFields = getErrorFieldNames(errors);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < errorFields.length; i++) {
        const fieldName = `position-${errorFields[i]}`;
        // Checking if the marker exists in DOM
        if (document.querySelectorAll(`[name="${fieldName}"]`).length) {
            scroller.scrollTo(fieldName, { offset: -200, smooth: true });
            break;
        }
    }
};
