import React from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import { getEnv } from 'app/utils/FetchUtils';

const Redirect: React.FC<{ to: string } & NavigateProps> = ({ to, ...rest }) => {
    if (to && to.startsWith('/')) {
        return <Navigate to={to} {...rest} />;
    }
    window.location = getEnv(to);
    return null;
};

export default Redirect;
