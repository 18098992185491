import { push } from 'react-router-redux';
import { matchPath } from 'react-router';
import pathToRegexp from 'path-to-regexp';
import { lazy } from 'react';
import Logger from './Logger';

export const parsedRoute = ({ path, keys, to }) => {
    try {
        const toPath = path && pathToRegexp.compile(path);
        const toParsed = to || (path && keys && toPath(keys));
        return toParsed;
    } catch (err) {
        Logger.logException(err, { type: `Error in ${path} with ${keys} to ${to}` });
    }
};

export const pushRoute = (props) => {
    const url = parsedRoute(props);
    if (url.startsWith('/')) {
        return push(url);
    }
    window.location = url;
    return { type: 'LOCATION_CHANGE' };
};

export const locationToUrl = (location, defaultUrl = '/') => {
    if (!location.pathname) return defaultUrl;
    let url = location.pathname;
    if (location.search) {
        url = `${url}?${location.search}`;
    }
    if (location.hash) {
        url = `${url}?${location.hash}`;
    }
    return url;
};

export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageRefreshed = JSON.parse(localStorage.getItem('page_refreshed') || false);
        try {
            const component = await componentImport();
            localStorage.setItem('page_refreshed', 'false');

            return component;
        } catch (error) {
            if (!pageRefreshed) {
                localStorage.setItem('page_refreshed', 'true');

                return window.location.reload();
            }
            Logger.logException(error, { type: `Lazy load failed in ${componentImport}` });
        }
    });
export const getParamsFromRoute = (route, pathConstant) => {
    return matchPath(route, { path: pathConstant });
};
