export const RECEIVE_ACCESS_TOKEN = 'RECEIVE_ACCESS_TOKEN';
export const RECEIVE_AUTHED_USER = 'RECEIVE_AUTHED_USER';
export const RESET_AUTHED = 'RESET_AUTHED';
export const RESET_MFA = 'RESET_MFA';
export const CHECKED_AUTHED = 'CHECKED_AUTHED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_CANCELLED = 'AUTH_CANCELLED';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const MFA_REQUEST = 'MFA_REQUEST';
export const MFA_SUCCESS = 'MFA_SUCCESS';
export const MFA_FAILURE = 'MFA_FAILURE';
export const MFA_OTP_FAILURE = 'MFA_OTP_FAILURE';
export const MFA_OTP_REQUEST = 'MFA_OTP_REQUEST';
export const MFA_OTP_SUCCESS = 'MFA_OTP_SUCCESS';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_RESPONSE = 'FORGOT_PASSWORD_RESPONSE';
export const FORGOT_PASSWORD_RESET_VIEW = 'FORGOT_PASSWORD_RESET_VIEW';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_EXISTING_USER = 'SET_PASSWORD_EXISTING_USER';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const SEARCH_COMPANY_RESULT = 'SEARCH_COMPANY_RESULT';
export const SEARCH_COMPANY = 'SEARCH_COMPANY';
export const SELECT_COMPANY = 'SELECT_COMPANY';

export const SEC_POOL_UPLOAD_RESPONSE = 'SEC_POOL_UPLOAD_RESPONSE';
export const SEC_MAP_COLUMN_RESPONSE = 'SEC_MAP_COLUMN_RESPONSE';
export const SEC_CASH_FLOW_RESPONSE = 'SEC_CASH_FLOW_RESPONSE';
export const SEC_WATERFALL_RESPONSE = 'SEC_WATERFALL_RESPONSE';

export const LOGOUT_ALL_REQUEST = 'LOGOUT_ALL_REQUEST';
export const LOGOUT_ALL_SUCCESS = 'LOGOUT_ALL_SUCCESS';
export const LOGOUT_ALL_FAILURE = 'LOGOUT_ALL_FAILURE';

export const LOGIN_PRECHECK = 'LOGIN_PRECHECK';
export const LOGIN_PRECHECK_FAILURE = 'LOGIN_PRECHECK_FAILURE';
export const MICROSOFT_LOGIN = 'MICROSOFT_LOGIN';
export const MICROSOFT_AUTH_SUCCESS = 'MICROSOFT_TOKEN_SUCCESS';
export const MICROSOFT_AUTH_FAILURE = 'MICROSOFT_TOKEN_FAILURE';
export const USER_TEMP_PASSWORD_REQUEST = 'USER_TEMP_PASSWORD_REQUEST';
export const USER_TEMP_PASSWORD_SUCCESS = 'USER_TEMP_PASSWORD_SUCCESS';
export const USER_TEMP_PASSWORD_FAILURE = 'USER_TEMP_PASSWORD_FAILURE';
export const PASSWORD_INVALIDATED = 'PASSWORD_INVALIDATED';
export const AUTH_FAILED_VIA_MICROSOFT = 'AUTH_FAILED_VIA_MICROSOFT';
export const UPDATE_USER = 'UPDATE_USER';