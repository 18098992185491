import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = null;

// actions
const createActionName = name => `app/ptc/transaction/poolSummary/${name}`;

export const POOL_SUMMARY_SUCCESS = createActionName('POOL_SUMMARY_SUCCESS');

export default function details(state = initialState, action) {
    switch (action.type) {
    case POOL_SUMMARY_SUCCESS:
        return {
            ...action.data,
        };
    default:
        return state;
    }
}

const ptcBaseUrl = id => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

export function getPoolSummary(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/pool_summary`)
            .then(d => (d && d.pool_summary && humps.camelizeKeys(d.pool_summary)) || {})
            .then(data => dispatch({ type: POOL_SUMMARY_SUCCESS, data, id }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }));
}
