import { combineReducers } from 'redux';
import tasks, { getTasks } from 'app/reducers/da/transaction/tasks';
import details, {
    getDetails,
    updateDA,
    updateDAPool,
    updateDAActors,
    updateDAState,
    generatePoolFiles,
    generateLoanFiles,
    uploadDADocuments,
    pollLoanFiles,
    destroyFile,
    getFileHeaders,
    destroyPTCPool,
    uploadPTCPoolV2,
    createDATrans,
    updateDAStructuring,
    editDealName,
    generateDocs,
    generateExecDocs,
    pollDAExecDocs,
    getDAExecDocs,
    adhocDocLoading,
    adhocDocUpdating,
    addAdhocDocument,
    updateAdhocDocument,
    removeDocPlaceholder,
    revokeExecDocs,
} from './details';
import bids, { getBidsInfo, updateBid, finalizeBids } from './bids';

const rootReducer = (state = {}, action) => {
    if (action.id === undefined) return state;
    return {
        [action.id]: combineReducers({
            details,
            bids,
            tasks,
        })(state[action.id], action),
    };
};

export default rootReducer;

export const actions = {
    getDetails,
    updateDA,
    updateDAPool,
    updateDAActors,
    updateDAState,
    generatePoolFiles,
    generateLoanFiles,
    getBidsInfo,
    updateBid,
    finalizeBids,
    uploadDADocuments,
    destroyFile,
    getTasks,
    getFileHeaders,
    destroyPTCPool,
    uploadPTCPoolV2,
    createDATrans,
    updateDAStructuring,
    pollLoanFiles,
    editDealName,
    generateDocs,
    generateExecDocs,
    pollDAExecDocs,
    getDAExecDocs,
    adhocDocLoading,
    adhocDocUpdating,
    addAdhocDocument,
    updateAdhocDocument,
    removeDocPlaceholder,
    revokeExecDocs,
};
