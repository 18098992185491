import { combineReducers } from 'redux';
import tasks, { getTasks } from 'app/reducers/ptcv1/transaction/tasks';
import details, {
    getPTCInfo,
    editPTC,
    updateDetails,
    updateStructuring,
    pollForPreview,
    pollForCashFlows,
    uploadWaterfall,
    reUploadWaterfall,
    waterfallReuploadLoader,
    generateDocuments,
    uploadPTCDocs,
    addAdhocDocument,
    updateAdhocDocument,
    removeDocPlaceholder,
    adhocDocLoading,
    adhocDocUpdating,
    destroyFile,
    scrubbEdit,
    releaseTrans,
    finalizeTrans,
    updateActors,
    releasePTC,
    generateExecDocs,
    pollPTCExecDocs,
    getPTCExecDocs,
    revokeExecDocs,
    updateMcd,
    addAttachment,
    deleteAttachment,
    updatePTC,
    isStrUpdating,
    releaseLoading,
    transLoading,
} from './transaction';
import bids, { getBidsInfo, updateBid, finalizeBids } from './bids';
import pricing, {
    sameEntity,
    sameSector,
    otherSector,
    clientInvestor,
    sameEntityLoading,
    sameSectorLoading,
    otherSectorLoading,
} from '../pricing.ts';
import commodity, { updateCommodity, getCommodity, uploadCommodity } from './commodities';
import orderManagement, { getOrderManagement, submitOrders } from './orderManagement';

const ptcv1 = (state = {}, action) => {
    if (action.id === undefined) return state;
    return {
        [action.id]: combineReducers({
            details,
            tasks,
            bids,
            pricing,
            orderManagement,
            commodity,
        })(state[action.id], action),
    };
};

export default ptcv1;

export const action = {
    getPTCInfo,
    updateDetails,
    updateStructuring,
    updatePTC,
    getTasks,
    pollForPreview,
    pollForCashFlows,
    uploadWaterfall,
    reUploadWaterfall,
    waterfallReuploadLoader,
    generateDocuments,
    uploadPTCDocs,
    addAdhocDocument,
    updateAdhocDocument,
    removeDocPlaceholder,
    adhocDocLoading,
    adhocDocUpdating,
    destroyFile,
    scrubbEdit,
    getBidsInfo,
    updateBid,
    finalizeBids,
    releaseTrans,
    finalizeTrans,
    updateActors,
    releasePTC,
    editPTC,
    generateExecDocs,
    pollPTCExecDocs,
    getPTCExecDocs,
    revokeExecDocs,
    updateMcd,
    sameEntity,
    sameSector,
    otherSector,
    clientInvestor,
    sameEntityLoading,
    sameSectorLoading,
    otherSectorLoading,
    addAttachment,
    deleteAttachment,
    isStrUpdating,
    releaseLoading,
    transLoading,
    uploadCommodity,
    getCommodity,
    updateCommodity,
    getOrderManagement,
    submitOrders,
};
