import humps from 'humps';
import _get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    loading: false,
    errorMessage: '',
};

// actions
const createActionName = (name) => `app/ptc/transaction/lossModel/${name}`;

export const LOSS_MODEL_GENERATE_REQUEST = createActionName('LOSS_MODEL_GENERATE_REQUEST');
export const LOSS_MODEL_GENERATE_SUCCESS = createActionName('LOSS_MODEL_GENERATE_SUCCESS');
export const LOSS_MODEL_GENERATE_FAILURE = createActionName('LOSS_MODEL_GENERATE_FAILURE');

export const LOSS_MODEL_REGENERATE_REQUEST = createActionName('LOSS_MODEL_REGENERATE_REQUEST');
export const LOSS_MODEL_REGENERATE_SUCCESS = createActionName('LOSS_MODEL_REGENERATE_SUCCESS');
export const LOSS_MODEL_REGENERATE_FAILURE = createActionName('LOSS_MODEL_REGENERATE_FAILURE');

export const LOSS_MODEL_GET_REQUEST = createActionName('LOSS_MODEL_GET_REQUEST');
export const LOSS_MODEL_GET_SUCCESS = createActionName('LOSS_MODEL_GET_SUCCESS');
export const LOSS_MODEL_GET_FAILURE = createActionName('LOSS_MODEL_GET_FAILURE');

export default function lossModel(state = initialState, action) {
    switch (action.type) {
        case LOSS_MODEL_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOSS_MODEL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        case LOSS_MODEL_GET_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
            };
        default:
            return state;
    }
}

// const ptcBaseUrl = id => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;
// const processValue = {
//     securitizationTransactionInvestmentNote: {
//         status: 'processing',
//     },
// };

const ptcBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/ptcs/${id}`;

const mapLossModel = (lossModelData) => ({
    ...lossModelData,
    data: {
        ...lossModelData.data,
        ratingCeWithStress: _get(lossModelData, 'data.ratingCeWithStress', []).map((item) => ({
            ...item,
            cePercentElPlusUl: Number((item.cePercentElPlusUl * 100).toFixed(2)),
        })),
        betaGhhi: _get(lossModelData, 'data.betaGhhi', []).map((item) => ({
            ...item,
            betaEntity: Number(item.betaEntity).toFixed(2),
            betaPool: Number(item.betaPool).toFixed(2),
            ghhiEntityR: Number(item.ghhiEntityR).toFixed(2),
            ghhiPoolR: Number(item.ghhiPoolR).toFixed(2),
        })),
        ceBuildUp: _get(lossModelData, 'data.ceBuildUp', []).map((item) => ({
            ...item,
            cashCollatPer: Number(item.cashCollatPer.slice(0, -2)),
            eisPer: Number(item.eisPer.slice(0, -2)),
            overCollatPer: Number(item.overCollatPer.slice(0, -2)),
            payOutDate: item.payOutDate,
            total: Number(item.total.slice(0, -2)),
        })),
    },
});

export function getLossModel(id) {
    return (dispatch) => {
        dispatch({ type: LOSS_MODEL_GET_REQUEST, id });
        return fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/investment_notes`)
            .then((d) => humps.camelizeKeys(d))

            .then((d) => {
                const payload = mapLossModel(d);
                dispatch({
                    type: LOSS_MODEL_GET_SUCCESS,
                    payload,
                    id,
                });

                if (['processing', 'inprogress'].includes(_get(payload, 'status'))) return null;
                return payload;
            })
            .catch((err) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m) => {
                    toastr.error('Error Initiating Loss Modelling', m);
                });
                return dispatch({ type: LOSS_MODEL_GET_FAILURE, errorMessage: err.message, id });
            });
    };
}

export function pollLossModel(id) {
    return (dispatch) => {
        return fetchUtils
            .poll(() => dispatch(getLossModel(id)), 180000, 5000)
            .then(() => {})
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    const msg = 'Timeout retrieving investment note';
                    toastr.error(msg, 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving investment note', errMsg);
                }
            });
    };
}
