import _get from 'lodash/get';
import _size from 'lodash/size';
import { scaleQuantile } from 'd3-scale';
import { valuesToCrores } from 'app/utils/CommonUtils';
import { capitalize } from 'app/utils/StringUtils';
import { CHART_COLORS, REVERSE_GEO_MAP_COLOR } from 'app/products/UIComponents/Chart/Constants';
import { STATE_CODES_ARRAY } from 'app/products/UIComponents/Maps/constants';
import { MAX_ITEM, SHORT_MONTH_NAMES } from 'app/products/Dashboards/Constants';

export interface IfilterReturn {
    label: string;
}

export const getSortOrder = (prop: any) => {
    return function(a: any, b: any) {
        if (b[prop] > a[prop]) {
            return 1;
        } else if (b[prop] < a[prop]) {
            return -1;
        }
        return 0;
    };
};

export const humanizeDate = (date: any, delimiter = '-') => {
    const dateArr = date.split(delimiter);
    return `${SHORT_MONTH_NAMES[Number(dateArr[1] - 1)]}-${dateArr[0].slice(2)}`;
};

export const standardiseArray = (data = [], key: any, value: any, keyFn: any, valueFn: any) => {
    if (data.length < 1) return [];
    return data.map((d: any) => {
        return {
            value: typeof valueFn === 'function' ? valueFn(d[value]) : d[value],
            name: typeof keyFn === 'function' ? keyFn(d[key]) : d[key],
        };
    });
};

const convertEmptyStringToOthers = (data: any) => {
    const modifiedData: any = {};
    Object.keys(data).forEach((d: any) => {
        if (d) {
            if (d !== 'others') {
                modifiedData[d] = data[d];
            } else {
                modifiedData.others = modifiedData.others ? modifiedData.others + data[d] : data[d];
            }
        } else {
            modifiedData.others = modifiedData.others ? modifiedData.others + data[d] : data[d];
        }
    });
    return modifiedData;
};

const objToArray = (data: any, format = false) => {
    return Object.keys(data).map((d: any) => {
        return { name: d, value: format ? Number(valuesToCrores(data[d])) : data[d] };
    });
};

const moveToOthers = (posOrYieldData: any, length: any, posData: any) => {
    if (_get(posData, 'type') === 'yield') {
        if (posOrYieldData.length > length) {
            const convertPosArr = objToArray(posData.data);
            const yieldOthers = convertPosArr.find((o: any) => o.name === 'Others' || o.name === 'others');
            let yieldModifiedData;
            if (yieldOthers) {
                yieldModifiedData = convertPosArr.filter((f: any) => {
                    return f.name !== 'others';
                });
            } else {
                yieldModifiedData = [...convertPosArr];
            }
            const sortedPosArr = yieldModifiedData.sort(getSortOrder('value'));
            const slicedData = sortedPosArr.slice(0, length);
            const remainingOthersData = [...sortedPosArr.slice(length), ...(yieldOthers ? [yieldOthers] : [])];
            const totalPos = remainingOthersData.reduce((acc: any, item: any) => {
                return acc + item.value;
            }, 0);
            const totalYieldAmt = remainingOthersData.reduce((acc: any, pos: any) => {
                const yieldVal: any = posOrYieldData.find((p: any) => p.name === pos.name);
                if (yieldVal) {
                    const yieldAmt = pos.value * yieldVal.value;
                    return acc + yieldAmt;
                }
                return acc;
            }, 0);
            const slicedYieldData = slicedData.map((s: any) => {
                const yieldVal: any = posOrYieldData.find((p: any) => p.name === s.name);
                return yieldVal;
            });
            return [...slicedYieldData, { name: 'Others', value: totalYieldAmt / totalPos }];
        }
        return posOrYieldData;
    }
    const finalArr: any = [];
    const posOthers = posOrYieldData.find((o: any) => o.name === 'Others' || o.name === 'others');
    let posWithoutOthers;
    if (posOthers) {
        posWithoutOthers = posOrYieldData.filter((f: any) => {
            return f.name !== 'others' || f.name !== 'Others';
        });
    } else {
        posWithoutOthers = [...posOrYieldData];
    }
    posWithoutOthers.reduce((acc: any, pos: any, index: any) => {
        const posItem = { ...pos };
        if (index + 1 > length) {
            const findIndex = finalArr.findIndex((n: any) => n.name === 'Others');
            if (findIndex !== -1) {
                finalArr[findIndex].name = 'Others';
                finalArr[findIndex].value = Number((acc + pos.value).toFixed(2));
            } else {
                finalArr.push({ name: 'Others', value: posOthers ? pos.value + posOthers.value : pos.value });
            }
            return acc + pos.value;
        }
        finalArr.push(posItem);
        return 0;
    }, 0);
    return finalArr;
};

export const normalizeChartData: any = (data: any, format: boolean, posData: any): any => {
    const payload = convertEmptyStringToOthers(data);
    const sortedPayloadArr = objToArray(payload, format).sort(getSortOrder('value'));
    return moveToOthers(sortedPayloadArr, MAX_ITEM, posData).map((c: any, i: any) => ({ ...c, colors: CHART_COLORS[i] }));
};

export const convertHumanReadableSector = (data: any, assetClasses: any) => {
    return data.map((d: any) => {
        const sector = _size(assetClasses) > 0 && assetClasses.find((a: any) => a.value === d.name);
        return { ...d, name: sector ? sector.label : d.name };
    });
};

export const normalizeSectorSplit = (data: any, assetClasses: any, format = false, type: any): any => {
    const normalizedData = normalizeChartData(data, format, type);
    return convertHumanReadableSector(normalizedData, assetClasses);
};

export const normalizeProductSplit = (data: any, format = false, type: any): any => {
    return normalizeChartData(data, format, type);
};

export const mergePos = (originalArr: any, arr: any) => {
    return originalArr.map((o: any) => {
        const getPos = arr.find((a: any) => a.period === o.period);
        return { ...o, pos: getPos ? getPos.pos : 0 };
    });
};

export const pushYearWise = (data = []) => {
    if (data.length < 1) return {};
    let years: any = {};
    data.forEach((year: any) => {
        const splitYear = year.period.split('-')[0];
        if (years[splitYear]) {
            years[splitYear].push(year);
        } else {
            years = { ...years, [splitYear]: [year] };
        }
    });
    return years;
};

export const normalizeStateData = (mapData: any) => {
    return STATE_CODES_ARRAY.map((d: any) => {
        const stateIndex: any = mapData.findIndex((da: any) => {
            return da.state === capitalize(d.name.toLowerCase().replace(/\s/g, ''));
        });
        return {
            name: d.name,
            value: stateIndex !== -1 ? Number(valuesToCrores(mapData[stateIndex].pos)) : 0,
        };
    });
};

export const normalizeMaps: any = (mapData: any) => {
    const stateData: any = normalizeStateData(mapData).sort(getSortOrder('value'));
    const TOP_COLORS = REVERSE_GEO_MAP_COLOR.slice(0, 5);
    const OTHER_COLORS = REVERSE_GEO_MAP_COLOR.slice(5).reverse();
    const colorScale: any = scaleQuantile()
        .domain(stateData.slice(5).map((d: any) => d.value))
        .range(OTHER_COLORS);
    return stateData.map((d: any, index: any) => {
        return {
            ...d,
            color: index < 5 ? TOP_COLORS[index] : colorScale(Number(_get(d, 'value', ''))),
        };
    });
};

export const chunkObj = (obj: any, size: any) => {
    const values = Object.values(obj);
    const final = [];
    let counter = 0;
    let portion: any = {};
    Object.keys(obj).forEach((key: any) => {
        if (counter !== 0 && counter % size === 0) {
            final.push(portion);
            portion = {};
        }
        portion[key] = values[counter];
        counter += 1;
    });
    final.push(portion);
    return final;
};

export const splitYears = (obj: any) => {
    const newObj: any = {};
    obj.forEach((element: any) => {
        let yearName = '';
        const yearArr = Object.keys(element);
        if (yearArr.length === 2) {
            yearName = `Jan ${yearArr[0]} - Dec ${yearArr[1]}`;
        } else {
            yearName = `Jan ${yearArr[0]} - Dec ${yearArr[0]}`;
        }
        newObj[yearName] = [];
        yearArr.forEach((d: any) => {
            newObj[yearName] = [...newObj[yearName], ...element[d]];
        });
    });
    return newObj;
};
