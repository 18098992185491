import React from 'react';

const SideBar = () => (
    <div className="content">
        <h1>
            <span>/</span> Expand your horizons with us
        </h1>
        <p>
            Yubi is a one stop solution for all debt market participants in India and the first port of call for offshore investors. Get access to
            wider opportunities across different debt instruments enabled by hassle-free online execution and post settlement support!
        </p>
    </div>
);

export default SideBar;
