import React, { useState, useEffect } from 'react';

const Tabs = ({ children, defaultIndex = 0, onTabClicked }: any) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    useEffect(() => {
        setActiveIndex(defaultIndex);
    }, [defaultIndex]);
    const filterChild = Array.isArray(children) ? children.filter((e: any) => !!e) : children;
    const tabs = React.Children.map(filterChild, (child, index) => {
        const {
            props: { label, disabled = false },
        } = child;
        const active = activeIndex === index ? 'active' : '';
        const onTabChange = (index: any, label: any) => {
            setActiveIndex(index);
            onTabClicked && onTabClicked({ index, label });
        };
        return (
            <li key={index} className={active}>
                <button disabled={disabled} onClick={() => onTabChange(index, label)}>
                    {label}
                </button>
            </li>
        );
    });
    return (
        <React.Fragment>
            <ul className="tab-head">{tabs}</ul>
            <div className="tab-body">{Array.isArray(children) ? filterChild[activeIndex] : children}</div>
        </React.Fragment>
    );
};

export default Tabs;
