import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import EnvironmentAlert from './EnvironmentAlert';

const CommonLayoutWoHeadFooter = ({ component: Content, environment: { alert }, ...rest }) => (
    <div>
        {alert && alert.title && (
            <CSSTransition classNames="fade" timeout={{ exit: 300, enter: 500 }}>
                <EnvironmentAlert title={alert.title} desc={alert.desc} />
            </CSSTransition>
        )}
        <Content {...rest} />
    </div>
);

const mapStateToProps = (state) => ({
    environment: state.environment,
});

const reduxConnector = connect(mapStateToProps);

export default reduxConnector(CommonLayoutWoHeadFooter);
