import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../../reducers/loading';
import { createActionName, baseURL } from './bid';

export const FIXED_COST_REQUEST = createActionName('FIXED_COST_REQUEST');
export const FIXED_COST_SUCCESS = createActionName('FIXED_COST_SUCCESS');

export const CASH_COLLATERAL_REQUEST = createActionName('CASH_COLLATERAL_REQUEST');
export const CASH_COLLATERAL_SUCCESS = createActionName('CASH_COLLATERAL_SUCCESS');

export const CASH_FLOW_REQUEST = createActionName('CASH_FLOW_REQUEST');
export const CASH_FLOW_SUCCESS = createActionName('CASH_FLOW_SUCCESS');

const initialState = {
    fixedFees: null,
    cashCollateral: null,
    cashFlow: null,
};

export default function costing(state = initialState, action) {
    switch (action.type) {
    case FIXED_COST_SUCCESS:
        return {
            ...state,
            fixedFees: action.payload.fixed_fees,
            fixedFeesTotal: action.payload.total,
        };
    case CASH_COLLATERAL_SUCCESS:
        return {
            ...state,
            cashCollateral: action.payload,
        };
    case CASH_FLOW_SUCCESS:
        return {
            ...state,
            cashFlow: action.payload,
        };
    default:
        return state;
    }
}

export const fixedCostLoading = createLoadingSelector([createActionName('FIXED_COST')]);
export const cashCollateralLoading = createLoadingSelector([createActionName('CASH_COLLATERAL')]);
export const cashFlowLoading = createLoadingSelector([createActionName('CASH_FLOW')]);

export function getFixedFees(id) {
    return (dispatch) => {
        dispatch({ type: FIXED_COST_REQUEST });
        return fetchUtils
            .getJSON(`${baseURL(id)}/fixed_fees/`)
            .then((res) => {
                if (res.fixed_fees) {
                    dispatch({ type: FIXED_COST_SUCCESS, payload: res, id });
                    dispatch(getCashCollateral(id));
                }
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching fixed cost data', m);
                });
            });
    };
}

export function updateFixedFees(id, feeId, feeData) {
    return dispatch =>
        fetchUtils
            .putJSON(`${baseURL(id)}/fixed_fees/${feeId}`, { fixed_fee: feeData })
            .then(() => {
                toastr.success('Fixed Cost', 'Fixed cost update successful');
                dispatch(getFixedFees(id));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating fixed fee data', m);
                });
            });
}

export function getCashCollateral(id) {
    return (dispatch) => {
        dispatch({ type: CASH_COLLATERAL_REQUEST });
        return fetchUtils
            .getJSON(`${baseURL(id)}/cash_collateral/`)
            .then((res) => {
                if (res.cash_collateral) {
                    dispatch({ type: CASH_COLLATERAL_SUCCESS, payload: res.cash_collateral, id });
                    dispatch(getCashFlow(id));
                }
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching Cash collateral data', m);
                });
            });
    };
}

export function updateCashCollateral(id, cashCollateralData) {
    return dispatch =>
        fetchUtils
            .putJSON(`${baseURL(id)}/cash_collateral`, {
                cash_collateral: cashCollateralData,
            })
            .then(() => {
                toastr.success('Cash Collateral', 'Cash Collateral update successful');
                dispatch(getFixedFees(id));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating fixed fee data', m);
                });
            });
}

export function getCashFlow(id) {
    return (dispatch) => {
        dispatch({ type: CASH_FLOW_REQUEST });
        return fetchUtils
            .getJSON(`${baseURL(id)}/costing/`)
            .then((res) => {
                if (res.costing) {
                    dispatch({ type: CASH_FLOW_SUCCESS, payload: res.costing, id });
                }
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching cash flow data', m);
                });
            });
    };
}
