import React from 'react';
import styled, { css } from 'styled-components';
import { getColor } from '../Utils/colorSets';
import { StyleValue, SizeType, IconType, Position, Color, Shade } from '../Types/CommonTypes';
import { getStyleValue, StyleOperators } from '../Utils/StyleUtils';
import IconButton from './IconButton';

interface BackgroundPosition {
    x: StyleValue;
    y: StyleValue;
}

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    icon?: string;
    color?: Color;
    shade?: Shade;
    size?: SizeType;
    type?: IconType;
    backgroundSize?: StyleValue;
    backgroundPosition?: BackgroundPosition;
    spacing?: number;
    spacingPosition?: Position;
    fontWeight?: number;
}

const StyledIcon = styled.i<IconProps>`
    padding: 6px 6px 6px 2px;
    vertical-align: middle;
    color:${({ color, shade }) => getColor(color, shade)};
    ${({ type, icon }) =>
        type === 'sprite' &&
        css<any>`
        display: flex;
        background: url(${icon}) no-repeat;

        ${({ size, backgroundPosition }) =>
            size === 'small' &&
            `
            width: 12px;
            height: 12px;
            background-size: 250px;
            ${backgroundPosition &&
                `background-position: ${getStyleValue(backgroundPosition.x, StyleOperators.DIVIDE, 2)} ${getStyleValue(
                    backgroundPosition.y,
                    StyleOperators.DIVIDE,
                    2,
                )};`}
        `}
        ${({ size }) =>
            size === 'inherit' &&
            `
            width: inherit;
            height: inherit;
        `}
        ${({ size, backgroundPosition }) =>
            size === 'normal' &&
            `
            width: 24px;
            height: 24px;
            background-size: 500px;
            ${backgroundPosition && `background-position: ${getStyleValue(backgroundPosition.x)} ${getStyleValue(backgroundPosition.y)};`}
        `}
        ${({ size, backgroundPosition }) =>
            size === 'large' &&
            `
            width: 48px;
            height: 48px;
            background-size: 1000px;
            ${backgroundPosition &&
                `background-position: ${getStyleValue(backgroundPosition.x, StyleOperators.DIVIDE, 2)} ${getStyleValue(
                    backgroundPosition.y,
                    StyleOperators.DIVIDE,
                    2,
                )};`}
        `}
        ${({ size, backgroundPosition }) =>
            size === 'xlarge' &&
            `
            width: 72px;
            height: 72px;
            background-size: 1500px;
            ${backgroundPosition &&
                `background-position: ${getStyleValue(backgroundPosition.x, StyleOperators.DIVIDE, 2)} ${getStyleValue(
                    backgroundPosition.y,
                    StyleOperators.DIVIDE,
                    2,
                )};`}
        `}
    `}

    ${({ backgroundSize }) =>
        backgroundSize &&
        `
        background-size: ${getStyleValue(backgroundSize)};
    `}


    ${({ type }) =>
        type === 'font' &&
        css<any>`
        ${({ size }) =>
            size === 'small' &&
            `
            font-size: 12px;
        `}
        ${({ size }) =>
            size === 'inherit' &&
            `
            font-size: inherit;
        `}
        ${({ size }) =>
            size === 'medium' &&
            `
            font-size: 16px !important;
        `}
        ${({ size }) =>
            size === 'normal' &&
            `
            font-size: 20px !important;
        `}
        ${({ size }) =>
            size === 'large' &&
            `
            font-size: 24px;
        `}
        ${({ size }) =>
            size === 'xlarge' &&
            `
            font-size: 30px;
        `}
    `}
`;

const Icon: React.FC<IconProps> = ({ color, shade, icon = '', size = 'small', type = 'font', backgroundSize = '100%', className = '', ...props }) => {
    return (
        <StyledIcon
            icon={icon}
            className={`icon ${icon}`}
            backgroundSize={backgroundSize}
            size={size}
            color={color}
            shade={shade}
            type={type}
            {...props}
        />
    );
};

export const IconWrapper = styled.div<{ borderRadius: string; height?: number; width?: number; color?: Color; shade?: Shade; iconPadding?: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ borderRadius, height, width, color, shade }) => `
    border-radius: ${borderRadius};
    width: ${width ? `${width}px` : '24px'}
    height: ${height ? `${height}px` : '24px'}
    ${color && shade && `background:${getColor(color, shade)}`}
    `}
`;

export default Icon;
export { IconButton };
