export const formatterInitialValues = {
    mappers: [
        {
            recurringPattern: {
                type: '',
                value: [
                    {
                        range: '',
                        repeatCount: '',
                    },
                ],
            },
        },
    ],
};

export const formatterDatesInitialValues = {
    mappers: [
        {
            recurringPattern: {
                type: 'sequential',
                value: [
                    {
                        range: '',
                        repeatCount: '',
                    },
                ],
            },
            datePattern: 'loan_specific_schedule',
        },
    ],
};

export const formatterType = {
    principal: 'PoolFile::Rs::PrincipalFormatter',
    interest: 'PoolFile::Rs::InterestFormatter',
    date: 'PoolFile::Rs::DateFormatter',
};

export const cellRangePattern = new RegExp(/^[a-zA-Z]{1,3}[/:][a-zA-Z]{1,3}/);
