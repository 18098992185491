import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { getProductType, getProductName } from '../../../utils/TransactionUtils';
import * as fetchUtils from '../../../utils/FetchUtils';
import { HEADER_WITHOUT_CCE, HEADER_WITH_CCE, CLIENT_HEADER, TRANSACTION_HEADER, SECTOR_HEADER } from '../constants/TableHeader';
import { PRODUCTS } from '../../../constants/Constants';
import { createLoadingSelector } from '../../../reducers/loading';
import { humanize } from '../../../utils/StringUtils';
import { humanizeNumber } from '../../../utils/CommonUtils';
import { getSummaryData } from './dashboard';
import { TRANS_CONST } from '../../../constants/TransactionConstants';

const initialState = {
    product: {},
    sector: {},
    transaction: {
        body: [],
        header: [],
        sector: [],
    },
    client: [],
};

// actions
const createActionName = (name: any) => `app/investor/portfolio/${name}`;
export const CLIENT_LOADING = createLoadingSelector([createActionName('CLIENT_DATA')]);
export const SECTOR_LOADING = createLoadingSelector([createActionName('SECTOR_DATA')]);

export const PRODUCT_DATA_REQUEST = createActionName('PRODUCT_DATA_REQUEST');
export const PRODUCT_DATA_SUCCESS = createActionName('PRODUCT_DATA_SUCCESS');
export const PRODUCT_DATA_FAILURE = createActionName('PRODUCT_DATA_FAILURE');

export const TRANSACTION_DATA_REQUEST = createActionName('TRANSACTION_DATA_REQUEST');
export const TRANSACTION_DATA_SUCCESS = createActionName('TRANSACTION_DATA_SUCCESS');
export const TRANSACTION_DATA_FAILURE = createActionName('TRANSACTION_DATA_FAILURE');

export const TRANSACTION_RESET = createActionName('TRANSACTION_RESET');
export const TOGGLE_CLIENT_ACCORDION = createActionName('TOGGLE_CLIENT_ACCORDION');

export const CLIENT_DATA_REQUEST = createActionName('CLIENT_DATA_REQUEST');
export const CLIENT_DATA_SUCCESS = createActionName('CLIENT_DATA_SUCCESS');
export const CLIENT_DATA_FAILURE = createActionName('CLIENT_DATA_FAILURE');

export const CLIENT_TRANSACTION_DATA_REQUEST = createActionName('CLIENT_TRANSACTION_DATA_REQUEST');
export const CLIENT_TRANSACTION_DATA_SUCCESS = createActionName('CLIENT_TRANSACTION_DATA_SUCCESS');

export const CLIENT_FI_REQUEST = createActionName('CLIENT_FI_REQUEST');
export const CLIENT_FI_SUCCESS = createActionName('CLIENT_FI_SUCCESS');

export const SECTOR_TRANSACTION_REQUEST = createActionName('SECTOR_TRANSACTION_REQUEST');
export const SECTOR_TRANSACTION_SUCCESS = createActionName('SECTOR_TRANSACTION_SUCCESS');
export const SECTOR_TRANSACTION_FAILURE = createActionName('SECTOR_TRANSACTION_FAILURE');

// reducer
export default function dashboard(state = initialState, action: any) {
    switch (action.type) {
        case SECTOR_TRANSACTION_REQUEST:
            let sec: any = { ...state.sector };
            if (sec[action.sectorType]) {
                sec[action.sectorType] = { ...sec[action.sectorType], isLoading: true, error: false };
            } else {
                sec = { ...state.sector, ...action.payload };
            }
            return {
                ...state,
                sector: sec,
            };
        case SECTOR_TRANSACTION_SUCCESS:
            let sectors: any = {};
            if (_get(state, `sector[${action.sectorType}].body`)) {
                sectors = { ...state.sector };
                const updatedRecord: any = [...sectors[action.sectorType].body, ...action.payload[action.sectorType].body];
                sectors[action.sectorType].body = updatedRecord;
                sectors[action.sectorType].isLoading = false;
            } else {
                sectors = { ...state.sector, ...action.payload };
            }
            return {
                ...state,
                sector: sectors,
            };
        case SECTOR_TRANSACTION_FAILURE: {
            let secFailure: any = { ...state.sector };
            if (secFailure[action.sectorType]) {
                secFailure[action.sectorType] = { ...secFailure[action.sectorType], isLoading: false, error: true };
            } else {
                secFailure = { ...state.sector, ...action.payload };
            }
            return {
                ...state,
                sector: secFailure,
            };
        }
        case PRODUCT_DATA_REQUEST:
            let prod: any = { ...state.product };
            if (prod[action.productType]) {
                prod[action.productType] = { ...prod[action.productType], isLoading: true, error: false };
            } else {
                prod = { ...state.product, ...action.payload };
            }
            return {
                ...state,
                product: prod,
            };
        case PRODUCT_DATA_SUCCESS:
            let products: any = {};
            if (_get(state, `product[${action.productType}].body`)) {
                products = { ...state.product };
                const updatedRecord: any = [...products[action.productType].body, ...action.payload[action.productType].body];
                products[action.productType].body = updatedRecord;
                products[action.productType].isLoading = false;
            } else {
                products = { ...state.product, ...action.payload };
            }
            return {
                ...state,
                product: products,
            };
        case PRODUCT_DATA_FAILURE: {
            let prodFailure: any = { ...state.product };
            if (prodFailure[action.productType]) {
                prodFailure[action.productType] = { ...prodFailure[action.productType], isLoading: false, error: true };
            } else {
                prodFailure = { ...state.product, ...action.payload };
            }
            return {
                ...state,
                product: prodFailure,
            };
        }
        case TRANSACTION_DATA_REQUEST:
            return {
                ...state,
                transaction: { ...state.transaction, isLoading: true, error: false },
            };
        case TRANSACTION_DATA_SUCCESS: {
            let transactionsData: any = {};
            if (_has(action, 'payload.body')) {
                transactionsData.body = [...state.transaction.body, ...action.payload.body];
                transactionsData.totalPage = action.payload.totalPage;
                transactionsData.header = action.payload.header;
                transactionsData.isLoading = false;
            } else {
                transactionsData = { ...state.transaction, ...action.payload };
            }
            return {
                ...state,
                transaction: transactionsData,
            };
        }
        case TRANSACTION_DATA_FAILURE:
            return {
                ...state,
                transaction: { ...state.transaction, isLoading: false, error: true },
            };
        case TRANSACTION_RESET:
            return {
                ...state,
                ...initialState,
            };
        case CLIENT_DATA_SUCCESS:
            return {
                ...state,
                client: action.payload,
            };
        case CLIENT_TRANSACTION_DATA_REQUEST:
            const clientDataLoad: any = [...state.client];
            const clientIndexLoad = clientDataLoad.findIndex((d: any) => d.clientId === action.clientId);
            clientDataLoad[clientIndexLoad].transactions = { ...clientDataLoad[clientIndexLoad].transactions, isLoading: true };
            return { ...state, client: clientDataLoad };
        case CLIENT_TRANSACTION_DATA_SUCCESS:
            const clientData: any = [...state.client];
            const clientIndex = clientData.findIndex((d: any) => d.clientId === action.clientId);
            if (_get(clientData, `[${clientIndex}].transactions.body`)) {
                clientData[clientIndex].transactions = {
                    body: [...clientData[clientIndex].transactions.body, ...action.payload.body],
                    isLoading: false,
                    header: action.payload.header,
                    totalPage: action.payload.totalPage,
                };
            } else if (_has(clientData, [clientIndex])) {
                clientData[clientIndex].transactions = action.payload;
            }

            return {
                ...state,
                client: clientData,
            };
        case CLIENT_FI_SUCCESS:
            const clientFI: any = [...state.client];
            const clientFIIndex = clientFI.findIndex((d: any) => d.clientId === action.clientId);
            clientFI[clientFIIndex] = { ...clientFI[clientFIIndex], ...action.payload };
            return {
                ...state,
                client: clientFI,
            };
        case TOGGLE_CLIENT_ACCORDION:
            const clients: any = [...state.client];
            const index = clients.findIndex((d: any) => d.clientId === action.clientId);
            clients[index].isOpen = action.status;
            return { ...state, client: clients };
        default:
            return state;
    }
}

export function transactionReset() {
    return {
        type: TRANSACTION_RESET,
    };
}

export function toggleClientAccordion(status: boolean, clientId: string) {
    return {
        type: TOGGLE_CLIENT_ACCORDION,
        status: !status,
        clientId,
    };
}

export function getProductData(pageNumber = 1, type: string) {
    return (dispatch: any) => {
        if (!type) {
            PRODUCTS.forEach((product: any) => {
                dispatch(getProductTransaction(product.value, product.label, pageNumber));
            });
        } else {
            const product = PRODUCTS.find((product: any) => product.label === type);
            if (product) {
                dispatch(getProductTransaction(product.value, product.label, pageNumber));
            }
        }
    };
}

export function getProductTransaction(product: any, label: any, pageNumber = 1) {
    return (dispatch: any) => {
        dispatch({
            type: PRODUCT_DATA_REQUEST,
            productType: label,
            payload: {
                [label]: {
                    isLoading: true,
                },
            },
        });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${pageNumber} &items_per_page=20&actor=investor&tab=settled&filter_params%5B%5D%5Bfield%5D=_type&filter_params%5B%5D%5Bvalue%5D%5B%5D=${product}&filter_params%5B%5D%5Boperator%5D=in&is_self_serve=${false}`,
            )
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                let header;
                if (product === 'Securitization' || product === 'PrefShares::Transaction') {
                    header = HEADER_WITH_CCE;
                } else {
                    header = HEADER_WITHOUT_CCE;
                }
                dispatch({
                    type: PRODUCT_DATA_SUCCESS,
                    productType: label,
                    payload: {
                        [label]: {
                            body: transformData(d.transactions, humps.camelizeKeys(header)),
                            header: humps.camelizeKeys(header),
                            totalPage: d.totalPages,
                        },
                    },
                });
            })
            .catch((ex: any) => {
                console.error(ex);
                fetchUtils.handleError(ex).then((m: any) => {
                    dispatch({
                        type: PRODUCT_DATA_FAILURE,
                        productType: label,
                        payload: {
                            [label]: {
                                isLoading: false,
                            },
                        },
                    });
                    toastr.error('Error retrieving Dashboard data', m);
                });
            });
    };
}

export function getTransactionData(pageNumber: number) {
    return (dispatch: any) => {
        dispatch({
            type: TRANSACTION_DATA_REQUEST,
            payload: {
                body: [],
                header: [],
                totalPage: 0,
                isLoading: true,
            },
        });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${pageNumber} &items_per_page=20&actor=investor&tab=settled&limit=25&is_self_serve=${false}`,
            )
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({
                    type: TRANSACTION_DATA_SUCCESS,
                    payload: {
                        body: transformData(d.transactions, humps.camelizeKeys(TRANSACTION_HEADER)),
                        header: humps.camelizeKeys(TRANSACTION_HEADER),
                        totalPage: d.totalPages,
                        isLoading: false,
                    },
                });
            })
            .catch((ex: any) => {
                console.error(ex);
                fetchUtils.handleError(ex).then((m: any) => {
                    dispatch({
                        type: TRANSACTION_DATA_FAILURE,
                        payload: {
                            body: [],
                            header: [],
                            totalPage: 0,
                            isLoading: false,
                            error: true,
                        },
                    });
                    toastr.error('Error retrieving transaction data', m);
                });
            });
    };
}

export function getClientData(clientId: string, pageNumber: number) {
    return (dispatch: any) => {
        if (clientId) {
            dispatch(getClientTransactionData(clientId, pageNumber));
        } else {
            dispatch({
                type: CLIENT_DATA_REQUEST,
            });
            fetchUtils
                .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/client_exposure`)
                .then((d: any) => humps.camelizeKeys(d))
                .then((d: any) => {
                    const clientExposure = d.clientExposure.map((trans: any) => {
                        const transObj = { ...trans };
                        transObj.isOpen = false;
                        transObj.assetClass = _has(trans, 'assetClass[0]') && humanize(trans.assetClass[0]);
                        return transObj;
                    });
                    dispatch({
                        type: CLIENT_DATA_SUCCESS,
                        payload: clientExposure,
                    });
                    d.clientExposure.forEach((d: any) => {
                        dispatch(getClientTransactionData(d.clientId));
                    });
                })
                .catch((ex: any) => {
                    console.error(ex);
                    fetchUtils.handleError(ex).then((m: any) => {
                        dispatch({
                            type: CLIENT_DATA_FAILURE,
                        });
                        toastr.error('Error retrieving Dashboard data', m);
                    });
                });
        }
    };
}

export function getClientTransactionData(clientId: any, pageNumber = 1) {
    return (dispatch: any) => {
        dispatch({
            type: CLIENT_TRANSACTION_DATA_REQUEST,
            clientId,
            payload: {
                isLoading: true,
            },
        });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${pageNumber}&items_per_page=20&actor=investor&tab=settled&filter_params[][field]=customer_id&filter_params[][operator]=in&filter_params[][value][]=${clientId}&is_self_serve=${false}`,
            )
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({
                    type: CLIENT_TRANSACTION_DATA_SUCCESS,
                    clientId,
                    payload: {
                        body: transformData(d.transactions, humps.camelizeKeys(CLIENT_HEADER)),
                        header: humps.camelizeKeys(CLIENT_HEADER),
                        totalPage: d.totalPages,
                        isLoading: false,
                    },
                });
            })
            .catch((ex: any) => {
                fetchUtils.handleError(ex).then((m: any) => {
                    toastr.error('Error retrieving Dashboard data', m);
                });
            });
    };
}

export function getSectorData(sectors: any, pageNumber: number) {
    return (dispatch: any) => {
        if (sectors.length > 0) {
            sectors.forEach((sector: any) => {
                dispatch(getSectorTransaction(sector.name, pageNumber));
            });
        } else {
            dispatch(getSummaryData(true));
        }
    };
}

export function getSectorTransaction(sector: string, pageNumber = 1) {
    return (dispatch: any) => {
        dispatch({
            type: SECTOR_TRANSACTION_REQUEST,
            sectorType: sector,
            payload: {
                [sector]: {
                    isLoading: true,
                },
            },
        });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${pageNumber}&items_per_page=20&actor=investor&tab=settled&filter_params[][field]=sector&filter_params[][operator]=in&filter_params[][value][]=${sector}&limit=2&is_self_serve=${false}`,
            )
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({
                    type: SECTOR_TRANSACTION_SUCCESS,
                    sectorType: sector,
                    payload: {
                        [sector]: {
                            body: transformData(d.transactions, humps.camelizeKeys(SECTOR_HEADER)),
                            header: humps.camelizeKeys(SECTOR_HEADER),
                            totalPage: d.totalPages,
                        },
                    },
                });
            })
            .catch((ex: any) => {
                console.error(ex);
                fetchUtils.handleError(ex).then((m: any) => {
                    dispatch({
                        type: SECTOR_TRANSACTION_FAILURE,
                        sectorType: sector,
                        payload: {
                            [sector]: {
                                isLoading: false,
                            },
                        },
                    });
                    toastr.error('Error retrieving Dashboard data', m);
                });
            });
    };
}

function transformData(transactions: any, header: any) {
    const modifiedArr: any = [];
    transactions.forEach((transaction: any) => {
        const tempArr: any = {
            className: '',
            data: [],
            id: transaction.id,
            product: getProductType(transaction.transactionType),
        };
        header.forEach((payload: any) => {
            const localObj: any = {};
            localObj.isProduct = false;
            localObj.isClient = false;
            localObj.isCCE = false;
            if (payload.isCCE) {
                localObj.isCCE = true;
                localObj.group = [];
                payload.group.map((d: any) => {
                    const tempObj: any = {};
                    tempObj.key = d.key;
                    tempObj.label = d.label;
                    tempObj.value = _get(transaction, `portfolioInfo[${d.key}]`);
                    if (d.key === TRANS_CONST.other) {
                        tempObj.value = _has(transaction, `portfolioInfo[${TRANS_CONST.other}]`)
                            ? humanizeNumber(transaction.portfolioInfo[d.key])
                            : '-';
                    }
                    localObj.group.push(tempObj);
                });
            } else {
                payload.key.forEach((key: any) => {
                    if (_has(transaction, key) || _has(transaction, `portfolioInfo[${key}]`)) {
                        localObj.key = key;
                        localObj.value = transaction[key] || transaction.portfolioInfo[key];
                        if (key === TRANS_CONST.sector) {
                            localObj.value = humanize(transaction.portfolioInfo[key]);
                        }
                        if (key === TRANS_CONST.assetClass) {
                            localObj.value = humanize(transaction[key]);
                        }
                        if (key === TRANS_CONST.transactionType) {
                            localObj.value = getProductName(transaction[key]).toUpperCase();
                            localObj.isProduct = true;
                        }
                        if (key === TRANS_CONST.residualTenor) {
                            localObj.value = getTenor(transaction.portfolioInfo[key]);
                        }
                        if (key === TRANS_CONST.yield) {
                            localObj.value = getYield(transaction.portfolioInfo[key]);
                        }
                        if (key === TRANS_CONST.quantum) {
                            localObj.value = getQuantum(transaction.portfolioInfo[key], getProductType(transaction.transactionType));
                        }
                        if (key === TRANS_CONST.principalOutstanding) {
                            localObj.value = humanizeNumber(transaction[key]);
                        }
                        if (key === TRANS_CONST.collectionEfficiency) {
                            localObj.value = getYield(transaction[key]);
                        }
                        localObj.isClient = key === TRANS_CONST.clientName;
                        return false;
                    }
                });
            }
            tempArr.data.push(localObj);
        });
        modifiedArr.push(tempArr);
    });
    return modifiedArr;
}

function getQuantum(quantum: any, type: string) {
    return humanizeNumber(quantum);
}

function getTenor(tenor: any) {
    return `${tenor.years}yrs ${tenor.months}m ${tenor.days}d`;
}

function getYield(yieldVal: any) {
    return yieldVal ? parseFloat(yieldVal).toFixed(2) : '-';
}
