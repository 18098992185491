import { useCallback, useContext } from 'react';
import { AmplitudeEvent } from './AmplitudeTypes';
import { AmplitudeContext, AmplitudeContextInterface } from './AmplitudeProvider';
import AmplitudeService from './AmplitudeService';
import { useWebEngage } from '@yubi/yb-module-webengage-provider';

interface UserProperties {
    [key: string]: string;
}

type UseAmplitudeProps = {
    logEvent: (event: AmplitudeEvent, eventProperties?: Record<string, any>, callback?: Function, userProperties?: UserProperties) => void;
    setUserProperties?: (props: UserProperties) => any;
    initializeNewUser?: () => any;
};

const useAmplitude = (): UseAmplitudeProps => {
    const { amplitude, userProps }: any = useContext<AmplitudeContextInterface>(AmplitudeContext);
    const webEngage = useWebEngage();
    const setUserProperties = useCallback(
        (userProperties) => {
            return amplitude.setUserProperties(userProperties);
        },
        [amplitude],
    );

    const logEvent = useCallback(
        (event, eventProperties, callback, userProperties = userProps) => {
            AmplitudeService.logEvent(event, eventProperties, callback);
            webEngage?.track(event?.eventName, {
                category: event?.category ?? '',
                ...eventProperties,
                module: process.env.REACT_APP_POD_NAME,
            });
        },
        [amplitude, userProps],
    );

    return {
        logEvent,
        setUserProperties,
    };
};

export default useAmplitude;
