import React from 'react';
import styled from 'styled-components';
import firewall from 'app/assets/images/firewall.png';

const StyledWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    .firewall-container {
        display: flex;
        align-items: center;
        max-width: 1100px;
        .firewall-avatar {
        }
        .firewall-content {
        }
    }
    .firewall-heading {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 800;
        font-style: italic;
        color: #000;
        span {
            font-weight: 400;
        }
    }
    .firewall-caption {
        line-height: 22px;
        margin-bottom: 20px;
        font-size: 14px;
        color: #000;
        font-weight: 400;
        span {
            font-weight: 600;
        }
    }
    .firewall-table {
        border: 1px solid #dcd9d9;
        border-radius: 4px;
        padding: 9px;
        margin-bottom: 30px;
        table {
            margin: 0;
            thead {
                background: #def1ff;
                border-radius: 4px;
                th {
                    border: none;
                    padding: 16px;
                    font-size: 14px;
                    font-weight: 700;
                    color: #000;
                    text-align: left;
                }
            }
            tbody {
                tr {
                    td {
                        border: none;
                        padding: 12px 16px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #000;
                    }
                }
            }
        }
    }
`;

const FirewallSettings = () => {
    return (
        <StyledWrapper>
            <div className="firewall-container">
                <div className="firewall-avatar">
                    <figure>
                        <img src={firewall} alt="Firewall" />
                    </figure>
                </div>
                <div className="firewall-content">
                    <h1 className="firewall-heading">
                        Dear
                        <span role="img" aria-label="raise">
                            &nbsp;User,&nbsp;👋
                        </span>
                    </h1>
                    <h1 className="firewall-caption">
                        We have moved to a new domain{' '}
                        <a href={process.env.REACT_APP_POOL_HOST} target="_blank" rel="noopener noreferrer">
                            go-yubi.com
                        </a>
                        . Please allow the following outbound connections from your firewall setting to connect our
                        <span>&nbsp;Yubi Marketplace&nbsp;</span>
                        Platform.
                    </h1>
                    <div className="firewall-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Protocol</th>
                                    <th>Port</th>
                                    <th>Host Name</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>HTTPS</td>
                                    <td>443</td>
                                    <td>*.credavenue.com</td>
                                    <td>Yubi</td>
                                </tr>
                                <tr>
                                    <td>HTTPS</td>
                                    <td>443</td>
                                    <td>*.vivriticapital.com</td>
                                    <td>Vivriti Web Marketplace</td>
                                </tr>
                                <tr>
                                    <td>HTTPS</td>
                                    <td>443</td>
                                    <td>*.amazonaws.com</td>
                                    <td>Amazon Cloud services</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h1 className="firewall-caption">
                        Please reach out to&nbsp;
                        <a
                            href="mailto:support@go-yubi.com"
                            title="support@go-yubi.com"
                            style={{
                                color: '#000',
                                fontWeight: 600,
                                textDecoration: 'underline',
                            }}
                        >
                            support@go-yubi.com
                        </a>
                        &nbsp;for questions/assistance/feedback.
                    </h1>
                </div>
            </div>
        </StyledWrapper>
    );
};

export default FirewallSettings;
