import * as types from '../constants/ActionTypes';
import { decodeJWT, getItemFromToken } from '../utils/jwtHelper';
import Auth from 'app/utils/Auth';
import { TOKEN_LOCAL_USER_ID } from 'app/constants/Constants';

const initialState = {
    accessToken: null,
    authJWT: null,
    user: {
        liveDeals: null,
        settledDeals: null,
        newOffers: null,
    },
    isAuthChecked: false,
    authErrMsg: '',
    mfaErrMsg: '',
    microsoftAuthErr: '',
    resetPassword: false,
    resetError: null,
    resetPasswordReq: false,
    mfaToken: null,
    userEmail: null,
    passwordInvalidated: false,
    passwordResetMessage: '',
};

export default function authed(state = initialState, action) {
    switch (action.type) {
        case types.RECEIVE_ACCESS_TOKEN: {
            return {
                ...state,
                accessToken: action.token.accessToken,
                mfaToken: action.token.mfaToken,
                authJWT: decodeJWT(action.token.idToken),
            };
        }
        case types.RESET_MFA: {
            return {
                ...state,
                accessToken: action.token.accessToken,
                mfaToken: action.token.mfaToken,
                authJWT: decodeJWT(action.token.idToken),
            };
        }
        case types.RECEIVE_AUTHED_USER:
            return {
                ...state,
                user: { ...state.user, ...action.user },
                isAuthChecked: true,
            };
        case types.CHECKED_AUTHED: {
            return {
                ...state,
                isAuthChecked: action.isAuthChecked,
            };
        }
        case types.AUTH_FAILED:
            return {
                ...state,
                authErrMsg: action.errorMsg,
            };
        case types.AUTH_FAILED_VIA_MICROSOFT:
            return {
                ...state,
                isAuthChecked: true,
                microsoftAuthErr: action.errorMsg,
            };
        case types.MFA_FAILURE:
            return {
                ...state,
                mfaErrMsg: action.errorMsg,
            };
        case types.MFA_OTP_SUCCESS:
            return {
                ...state,
                mfaErrMsg: action.errorMsg,
            };
        case types.MFA_OTP_FAILURE:
            return {
                ...state,
                mfaErrMsg: action.errorMsg,
            };
        case types.AUTH_REQUEST:
            return {
                ...state,
                authErrMsg: '',
            };
        case types.FORGOT_PASSWORD_REQUEST: {
            return {
                ...state,
                resetError: null,
                resetPasswordReq: true,
            };
        }
        case types.FORGOT_PASSWORD_RESPONSE: {
            return {
                ...state,
                resetPasswordReq: false,
                resetPassword: action.response.resetPassword,
                resetError: action.response.err,
                passwordResetMessage: action.response.passwordResetMessage,
            };
        }
        case types.FORGOT_PASSWORD_RESET_VIEW: {
            return {
                ...state,
                userEmail: null,
                resetError: null,
                resetPassword: false,
                passwordInvalidated: false,
            };
        }
        case types.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                passwordChanged: true,
            };
        }
        case types.SET_PASSWORD_SUCCESS: {
            return {
                ...state,
                done: true,
            };
        }
        case types.SET_PASSWORD_EXISTING_USER: {
            return {
                ...state,
                userEmail: action.data,
            };
        }
        case types.PASSWORD_INVALIDATED: {
            return {
                ...state,
                passwordInvalidated: true,
            };
        }
        case types.AUTH_CANCELLED: {
            return {
                ...state,
                authCancelled: true,
                authErrMsg: action.errorMsg,
            };
        }
        case types.UPDATE_USER: {
            return {
                ...state,
                user: { ...state.user, ...action.user },
            };
        }
        default:
            return state;
    }
}

export function getActiveUserId() {
    const idToken = Auth.getIdToken();
    return idToken ? getItemFromToken(Auth.getAccessToken(), `${process.env.REACT_APP_AUTH_AUDIENCE}${TOKEN_LOCAL_USER_ID}`) : '';
}
