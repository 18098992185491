import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import { addDownloadDetails } from 'app/utils/TransactionUtils.tsx';

import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../../reducers/loading';
import { PRODUCT_KEYS, TRANSACTION_STATE } from 'app/constants/Constants';
import { PRODUCT_RELEASE_CLICK } from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';

const initialState = {
    transactionId: '',
    transaction: {},
    poolSelection: {},
    clientPref: {},
    structure: {
        trancheCount: 2,
        trancheUnit: 'percent',
        payinOffsetDays: 15,
        payoutOffsetDays: 2,
        payoutDelay: 0,
        servicerFeeUnit: 'percent',
        servicerFee: 0.0,
        tranches: [],
    },
    scrubbing: {
        loading: true,
        edit: true,
        selectedPoolState: [],
    },
    poolSummary: { isDirty: true, summary: {} },
    isFetching: false,
    isCreating: false,
};
// actions
const createActionName = (name) => `app/admin/ptc/${name}`;
export const TRANSACTION_GET = createActionName('TRANSACTION_GET');
export const TRANSACTION_EDIT = createActionName('TRANSACTION_EDIT');
export const POOL_SELECTION_SUCCESS = createActionName('POOL_SELECTION_SUCCESS');
export const FETCH_POOL_SELECTION = createActionName('FETCH_POOL_SELECTION');

export const POOL_SUMMARY_SUCCESS = createActionName('POOL_SUMMARY_SUCCESS');
export const POOL_SUMMARY_PROCESSING = createActionName('POOL_SUMMARY_PROCESSING');
export const POOL_SUMMARY_REQUEST = createActionName('POOL_SUMMARY_REQUEST');
export const POOL_SUMMARY_ERROR = createActionName('POOL_SUMMARY_ERROR');
export const POOL_SUMMARY_TIMEOUT = createActionName('POOL_SUMMARY_TIMEOUT');

export const GEN_LOAN_FILES_REQUEST = createActionName('GEN_LOAN_FILES_REQUEST');
export const GEN_LOAN_FILES_SUCCESS = createActionName('GEN_LOAN_FILES_SUCCESS');

export const WATERFALL_SUMMARY_REQUEST = createActionName('WATERFALL_SUMMARY_REQUEST');
export const WATERFALL_SUMMARY_PROCESSING = createActionName('WATERFALL_SUMMARY_PROCESSING');
export const WATERFALL_SUMMARY_SUCCESS = createActionName('WATERFALL_SUMMARY_SUCCESS');
export const WATERFALL_SUMMARY_ERROR = createActionName('WATERFALL_SUMMARY_ERROR');
export const WATERFALL_SUMMARY_TIMEOUT = createActionName('WATERFALL_SUMMARY_TIMEOUT');

export const CLIENT_PREF_SUCCESS = createActionName('CLIENT_PREF_SUCCESS');
export const PTC_FILES_SUCCESS = createActionName('PTC_FILES_SUCCESS');

export const STRUCTURING_UPDATE_SUCCESS = createActionName('STRUCTURING_UPDATE_SUCCESS');
export const STRUCTURING_GET_SUCCESS = createActionName('STRUCTURING_GET_SUCCESS');

export const ACTORS_GET_SUCCESS = createActionName('ACTORS_GET_SUCCESS');
export const ACTORS_UPDATE_SUCCESS = createActionName('ACTORS_UPDATE_SUCCESS');

export const DOCUMENTS_FETCH_REQUEST = createActionName('DOCUMENTS_FETCH_REQUEST');
export const DOCUMENTS_FETCH_SUCCESS = createActionName('DOCUMENTS_FETCH_SUCCESS');
export const PTC_GET_EXEC_DOCS_SUCCESS = createActionName('PTC_GET_EXEC_DOCS_SUCCESS');

export const ENTITIES_FETCH_SUCCESS = createActionName('ENTITIES_FETCH_SUCCESS');

export const TRANS_RELEASE_REQUEST = createActionName('TRANS_FINALIZE_REQUEST');
export const TRANS_RELEASE_SUCCESS = createActionName('TRANS_FINALIZE_SUCCESS');

export const BIDS_FETCH_REQUEST = createActionName('BIDS_FETCH_REQUEST');
export const BIDS_FETCH_SUCCESS = createActionName('BIDS_FETCH_SUCCESS');

export const BIDS_UPDATE_REQUEST = createActionName('BIDS_UPDATE_REQUEST');
export const BIDS_UPDATE_SUCCESS = createActionName('BIDS_UPDATE_SUCCESS');

export const BIDS_FINALIZE_REQUEST = createActionName('BIDS_FINALIZE_REQUEST');
export const BIDS_FINALIZE_SUCCESS = createActionName('BIDS_FINALIZE_SUCCESS');

export const TRANS_FINALIZE_REQUEST = createActionName('TRANS_FINALIZE_REQUEST');
export const TRANS_FINALIZE_SUCCESS = createActionName('TRANS_FINALIZE_SUCCESS');

export const TRANCHE_INVESTOR_GET_REQUEST = createActionName('TRANCHE_INVESTOR_GET_REQUEST');
export const TRANCHE_INVESTOR_GET_SUCCESS = createActionName('TRANCHE_INVESTOR_GET_SUCCESS');

export const EXECUTED_DOCS_FETCH_REQUEST = createActionName('EXECUTED_DOCS_FETCH_REQUEST');
export const EXECUTED_DOCS_FETCH_SUCCESS = createActionName('EXECUTED_DOCS_FETCH_SUCCESS');
export const EXECUTED_DOCS_FETCH_FAILURE = createActionName('EXECUTED_DOCS_FETCH_FAILURE');

export const DOCUMENT_UPLOAD_REQUEST = createActionName('DOCUMENT_UPLOAD_REQUEST');
export const DOCUMENT_UPLOAD_SUCCESS = createActionName('DOCUMENT_UPLOAD_SUCCESS');
export const DOCUMENT_UPLOAD_FAILURE = createActionName('DOCUMENT_UPLOAD_FAILURE');

export const FILE_UPLOAD_SUCCESS = createActionName('FILE_UPLOAD_SUCCESS');
export const FILE_DESTROY_SUCCESS = createActionName('FILE_DESTROY_SUCCESS');

export const ADHOC_CREATE_REQUEST = createActionName('ADHOC_CREATE_REQUEST');
export const ADHOC_CREATE_SUCCESS = createActionName('ADHOC_CREATE_SUCCESS');
export const ADHOC_CREATE_FAILURE = createActionName('ADHOC_CREATE_FAILURE');

export const ADHOC_UPDATE_REQUEST = createActionName('ADHOC_UPDATE_REQUEST');
export const ADHOC_UPDATE_SUCCESS = createActionName('ADHOC_UPDATE_SUCCESS');
export const ADHOC_UPDATE_FAILURE = createActionName('ADHOC_UPDATE_FAILURE');

export const PLACEHOLDER_REMOVE_REQUEST = createActionName('PLACEHOLDER_REMOVE_REQUEST');
export const PLACEHOLDER_REMOVE_SUCCESS = createActionName('PLACEHOLDER_REMOVE_SUCCESS');
export const PLACEHOLDER_REMOVE_FAILURE = createActionName('PLACEHOLDER_REMOVE_FAILURE');

export const FETCHING_DATA = createActionName('FETCHING_DATA');
export const RESET_DATA = createActionName('RESET_DATA');

export const FETCH_SCRUBB_REQUEST = createActionName('FETCH_SCRUBB_REQUEST');
export const FETCH_SCRUBB_SUCCESS = createActionName('FETCH_SCRUBB_SUCCESS');
export const FETCH_SCRUBB_FAILURE = createActionName('FETCH_SCRUBB_FAILURE');
export const LOAN_SCRUBB_REQUEST = createActionName('LOAN_SCRUBB_REQUEST');
export const LOAN_SCRUBB_SUCCESS = createActionName('LOAN_SCRUBB_SUCCESS');
export const LOAN_SCRUBB_FAILURE = createActionName('LOAN_SCRUBB_FAILURE');
export const LOAN_SCRUBB_EDIT = createActionName('LOAN_SCRUBB_EDIT');

export const FUNDING_STATUS_REQUEST = createActionName('FUNDING_STATUS_REQUEST');
export const FUNDING_STATUS_SUCCESS = createActionName('FUNDING_STATUS_SUCCESS');
export const FUNDING_STATUS_FAILURE = createActionName('FUNDING_STATUS_FAILURE');

export default function ptc(state = initialState, action) {
    switch (action.type) {
        case POOL_SELECTION_SUCCESS: {
            return {
                ...state,
                poolSelection: action.data,
                transactionId: action.transId,
                isFetching: false,
            };
        }
        case FETCH_POOL_SELECTION:
            return {
                ...state,
                poolSelection: action.data,
                transactionId: action.transId,
            };
        case POOL_SUMMARY_PROCESSING:
        case POOL_SUMMARY_REQUEST:
            return {
                ...state,
                transactionId: action.transId,
                poolSummary: {
                    ...state.poolSummary,
                    errorMsg: '',
                    isDirty: true,
                },
            };
        case POOL_SUMMARY_ERROR:
        case POOL_SUMMARY_TIMEOUT:
            return {
                ...state,
                transactionId: action.transId,
                poolSummary: { errorMsg: action.errorMsg, isDirty: false },
            };
        case POOL_SUMMARY_SUCCESS:
            return {
                ...state,
                transactionId: action.transId,
                poolSummary: {
                    summary: action.data.pool_summary,
                    errorMsg: '',
                    isDirty: false,
                },
            };
        case TRANSACTION_GET:
            return {
                ...state,
                transaction: {
                    ...action.data,
                    documents: addDownloadDetails(_get(action, 'data')),
                },
                transactionId: action.transId,
            };
        case TRANSACTION_EDIT:
            return {
                ...state,
                transaction: {
                    ...action.data,
                    documents: addDownloadDetails(_get(action, 'data')),
                },
                transactionId: action.transId,
            };
        case CLIENT_PREF_SUCCESS:
            return {
                ...state,
                clientPref: action.data,
                transactionId: action.transId,
            };
        case PTC_FILES_SUCCESS:
            return {
                ...state,
                ptcFiles: action.data,
                transactionId: action.transId,
            };
        case STRUCTURING_UPDATE_SUCCESS:
            return {
                ...state,
                structure: action.data,
                transactionId: action.transId,
            };
        case STRUCTURING_GET_SUCCESS:
            return {
                ...state,
                structure: action.data,
                transactionId: action.transId,
            };
        case ACTORS_UPDATE_SUCCESS:
            return {
                ...state,
                actors: action.data,
                transactionId: action.transId,
            };
        case ACTORS_GET_SUCCESS:
            return {
                ...state,
                actors: action.data,
                transactionId: action.transId,
            };
        case ENTITIES_FETCH_SUCCESS:
            return {
                ...state,
                entities: action.data,
            };
        case WATERFALL_SUMMARY_PROCESSING:
        case WATERFALL_SUMMARY_REQUEST:
            return {
                ...state,
                transactionId: action.transId,
                waterSummary: {
                    ...state.waterSummary,
                    errorMsg: '',
                    isDirty: true,
                },
            };
        case WATERFALL_SUMMARY_ERROR:
        case WATERFALL_SUMMARY_TIMEOUT:
            return {
                ...state,
                transactionId: action.transId,
                waterSummary: { errorMsg: action.errorMsg, isDirty: false },
            };
        case WATERFALL_SUMMARY_SUCCESS:
            return {
                ...state,
                transactionId: action.transId,
                waterSummary: {
                    summary: action.data.waterfall_summary,
                    errorMsg: '',
                    isDirty: false,
                },
            };
        case DOCUMENTS_FETCH_REQUEST:
            return {
                ...state,
                documents: [],
                transactionId: action.transId,
            };
        case DOCUMENTS_FETCH_SUCCESS:
        case DOCUMENT_UPLOAD_SUCCESS:
            return {
                ...state,
                documents: action.data,
                transactionId: action.transId,
            };
        case EXECUTED_DOCS_FETCH_SUCCESS:
            return {
                ...state,
                executedDocs: action.data,
            };
        case TRANS_RELEASE_SUCCESS: {
            return {
                ...state,
                details: action.data,
            };
        }
        case PTC_GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        case BIDS_FETCH_REQUEST:
            return {
                ...state,
                bids: null,
            };
        case BIDS_FETCH_SUCCESS:
            return {
                ...state,
                bids: action.data,
            };
        case BIDS_UPDATE_SUCCESS:
            return {
                ...state,
                bids: uniqBy([action.data, ...state.bids], 'id'),
            };
        case BIDS_FINALIZE_SUCCESS: {
            return {
                ...state,
                details: action.data,
            };
        }
        case TRANCHE_INVESTOR_GET_REQUEST: {
            return {
                ...state,
                trancheInvestors: null,
            };
        }
        case TRANCHE_INVESTOR_GET_SUCCESS:
            return {
                ...state,
                trancheInvestors: action.data,
            };
        case TRANS_FINALIZE_SUCCESS: {
            return {
                ...state,
                details: action.data,
            };
        }
        case FETCHING_DATA:
            return {
                ...state,
                isFetching: action.status,
            };
        case RESET_DATA:
            return {
                ...initialState,
            };
        case FETCH_SCRUBB_SUCCESS: {
            const poolState = action.data.poolStates ? action.data.poolStates : [];
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    loading: false,
                    selectedPoolState: poolState,
                    edit: poolState.length > 0,
                    status: action.data.status,
                },
            };
        }
        case FETCH_SCRUBB_FAILURE: {
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    selectedPoolState: [],
                    edit: false,
                    loading: false,
                },
            };
        }
        case LOAN_SCRUBB_REQUEST:
            return {
                ...state,
                scrubbing: {
                    edit: true,
                    selectedPoolState: [],
                    status: null,
                },
            };
        case LOAN_SCRUBB_SUCCESS:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, selectedPoolState: action.poolStates },
            };
        case LOAN_SCRUBB_EDIT:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, success: true },
            };
        default:
            return state;
    }
}

// selectors
export const selectorGenLoanFilesLoading = createLoadingSelector([createActionName('GEN_LOAN_FILES')]);
export const adhocDocLoading = createLoadingSelector([createActionName('ADHOC_CREATE')]);
export const adhocDocUpdating = createLoadingSelector([createActionName('ADHOC_UPDATE')]);
export const placeholderRemoving = createLoadingSelector([createActionName('PLACEHOLDER_REMOVE')]);
export const scrubbingLoader = createLoadingSelector([createActionName('FETCH_SCRUBB')]);

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function resetData() {
    return {
        type: RESET_DATA,
    };
}

export function poolSelection(transId, data) {
    return {
        type: POOL_SELECTION_SUCCESS,
        data,
        transId,
    };
}

export function fetchPoolSelection(transId, data) {
    return {
        type: FETCH_POOL_SELECTION,
        data,
        transId,
    };
}

export function structuringUpdate(transId, data) {
    return {
        type: STRUCTURING_UPDATE_SUCCESS,
        data,
        transId,
    };
}

export function actorsUpdate(transId, data) {
    return {
        type: ACTORS_UPDATE_SUCCESS,
        data,
        transId,
    };
}

export function fetchEntities(data) {
    return {
        type: ENTITIES_FETCH_SUCCESS,
        data,
    };
}

export function scrubbEdit() {
    return {
        type: LOAN_SCRUBB_EDIT,
        status: null,
    };
}
export function generateLoanFiles(transId) {
    return (dispatch) => {
        dispatch({ type: GEN_LOAN_FILES_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/generate_loan_files`, {})
            .then(() => {
                dispatch({ type: GEN_LOAN_FILES_SUCCESS });
                toastr.success('Success!', 'Loan files generation request has been successful.');
            })
            .then(() => {
                dispatch(pollPoolSummary(transId));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

function mapPoolSelection(data) {
    const mapData = {
        ...data,
        cutoffDate: moment(data.cutoffDate).format('YYYY-MM-DD'),
    };
    return { pool_selection_criteria: humps.decamelizeKeys(mapData) };
}

function unMapPoolSelection(data) {
    const { pool_selection_criteria: criteria } = data;
    return humps.camelizeKeys(criteria);
}

export function getPoolSelection(transId) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/pool_selection_criteria`)
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(fetchPoolSelection(transId, unMapPoolSelection(d)));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function updatePoolSelection(transId, criteria) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/pool_selection_criteria`, mapPoolSelection(criteria))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(poolSelection(transId, unMapPoolSelection(d)));
            })
            .then(() => {
                toastr.success('Success!', 'Pool selection criteria updated successfully.');
            })
            .then(() => {
                dispatch(pollPoolSummary(transId)).then(() => {
                    dispatch(getPTC(transId));
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function loadPoolSummary(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/pool_summary`)
            .then((d) => {
                if (d.error) {
                    dispatch({
                        type: POOL_SUMMARY_PROCESSING,
                        transId: id,
                    });
                    return null;
                }
                dispatch({
                    type: POOL_SUMMARY_SUCCESS,
                    data: d,
                    transId: id,
                });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({
                        type: POOL_SUMMARY_ERROR,
                        transId: id,
                        errorMsg: m,
                    });
                    return new Error(m);
                }),
            );
}

export function pollPoolSummary(id) {
    return (dispatch) => {
        dispatch({ type: POOL_SUMMARY_REQUEST, transId: id });

        return fetchUtils
            .poll(() => dispatch(loadPoolSummary(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'PoolSummary updated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    const msg = 'Timeout retrieving pool summary';
                    toastr.error(msg, 'Please try again after sometime.');
                    dispatch({
                        type: POOL_SUMMARY_TIMEOUT,
                        errorMsg: msg,
                        transId: id,
                    });
                } else {
                    toastr.error('Error retrieving pool summary', errMsg);
                    dispatch({
                        type: POOL_SUMMARY_ERROR,
                        transId: id,
                        errorMsg: errMsg,
                    });
                }
            });
    };
}

function unMapClientPref(pref) {
    return {
        pricing: pref.pricing,
        poolCutoffDate: moment(pref.pool_cutoff_date).format('DD/MM/YYYY'),
        settlementDate: moment(pref.settlement_date).format('DD/MM/YYYY'),
    };
}

const unMapPTC = (data) => {
    const m = {
        ...data,
        client_preferences: data.client_preferences ? unMapClientPref(data.client_preferences) : {},
    };
    const d = humps.camelizeKeys(m);
    return {
        ...d,
        clientId: d.customerId || (Array.isArray(d.customer) && d.customer.length > 0 && d.customer[0].id) || '',
        poolSelectionCriteria: {
            ...d.poolSelectionCriteria,
            cutoffDate: moment(d.poolSelectionCriteria.cutoffDate).format('DD/MM/YYYY'),
        },
    };
};

export function getPTC(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then((d) => unMapPTC(d))
            .then((d) => dispatch({ type: TRANSACTION_GET, data: d, transId: id }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }),
            );
}

export function editPTC(transId, values) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}`, { ...humps.decamelizeKeys(values), id: transId })
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_EDIT, data: d, id: transId });
                toastr.success('Success!', 'Deal Name updated successfully');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating deal name', m);
                }),
            );
}

export function getPTCFiles(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/securitization_files`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) =>
                dispatch({
                    type: PTC_FILES_SUCCESS,
                    data: d.securitizationFiles,
                    transId: id,
                }),
            )
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitization files', m);
                }),
            );
}

/* <<<<<< Structuring */
function unMapStructuring(data) {
    const { waterfall_gen_criteria: criteria } = data;
    const s = humps.camelizeKeys(criteria);
    return {
        ...s,
        trancheCount: s.tranches.length,
    };
}
function mapStructuring(data) {
    const mapData = {
        ...data,
        settlementDate: moment(data.settlementDate).format('YYYY-MM-DD'),
    };
    return { waterfall_gen_criteria: humps.decamelizeKeys(mapData) };
}

export function updateStructuring(transId, structure) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/waterfall_gen_criteria`, mapStructuring(structure))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(structuringUpdate(transId, unMapStructuring(d)));
            })
            .then(() => {
                toastr.success('Success!', 'Initiated deal structuring. Please wait a few seconds.');
            })
            .then(() => dispatch(pollWaterfall(transId)))
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function getStructuring(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/waterfall_gen_criteria`)
            .then((d) => {
                dispatch({
                    type: STRUCTURING_GET_SUCCESS,
                    data: unMapStructuring(d),
                    transId: id,
                });
                return d;
            })
            .catch(() => '');
}

export function loadWaterfall(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/waterfall_summary`)
            .then((d) => {
                if (d.error) {
                    dispatch({
                        type: WATERFALL_SUMMARY_PROCESSING,
                        transId: id,
                    });
                    return null;
                }
                dispatch({
                    type: WATERFALL_SUMMARY_SUCCESS,
                    data: d,
                    transId: id,
                });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({
                        type: WATERFALL_SUMMARY_ERROR,
                        transId: id,
                        errorMsg: m,
                    });
                    return new Error(m);
                }),
            );
}

export function pollWaterfall(id) {
    return (dispatch) => {
        dispatch({ type: WATERFALL_SUMMARY_REQUEST, transId: id });

        return fetchUtils
            .poll(() => dispatch(loadWaterfall(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'Waterfall updated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving waterfall summary', 'Please try again after sometime.');
                    dispatch({ type: WATERFALL_SUMMARY_TIMEOUT, transId: id });
                } else {
                    toastr.error('Error retrieving waterfall summary', errMsg);
                }
            });
    };
}
/* Structuring >>>>>> */

/* <<<<<< Actors */
function unMapActors(data) {
    const { actors } = data;
    const unMapData = humps.camelizeKeys(actors);
    return {
        slceProvider:
            (unMapData.slceProvider && {
                value: unMapData.slceProvider.id,
                label: unMapData.slceProvider.name || 'N/A',
            }) ||
            {},
        servicer:
            (unMapData.servicer && {
                value: unMapData.servicer.id,
                label: unMapData.servicer.name || '',
            }) ||
            {},
        auditor:
            (unMapData.auditor && {
                value: unMapData.auditor.id,
                label: unMapData.auditor.name || '',
            }) ||
            {},
        trustee:
            (unMapData.trustee && {
                value: unMapData.trustee.id,
                label: unMapData.trustee.name || '',
            }) ||
            {},
        trusteeBranch:
            (unMapData.trustee.branch && {
                value: unMapData.trustee.branch,
                label: unMapData.trustee.branch || '',
            }) ||
            {},
        ratingAgency:
            (unMapData.ratingAgency && {
                value: unMapData.ratingAgency.id,
                label: unMapData.ratingAgency.name || '',
            }) ||
            {},
        lawFirm:
            (unMapData.lawFirm && {
                value: unMapData.lawFirm.id,
                label: unMapData.lawFirm.name || '',
            }) ||
            {},
        lawFirmBranch:
            (unMapData.lawFirm.branch && {
                value: unMapData.lawFirm.branch,
                label: unMapData.lawFirm.branch || '',
            }) ||
            {},
    };
}

function mapActors(data) {
    const mapData = {
        slce_provider: {
            id: data.slceProvider && data.slceProvider.value,
            name: data.slceProvider && data.slceProvider.label,
        },
        servicer: {
            id: data.servicer && data.servicer.value,
            name: data.servicer && data.servicer.label,
        },
        auditor: {
            id: data.auditor && data.auditor.value,
            name: data.auditor && data.auditor.label,
        },
        trustee: {
            id: data.trustee && data.trustee.value,
            name: data.trustee && data.trustee.label,
            branch: (data.trusteeBranch && data.trusteeBranch.value) || '',
        },
        ratingAgency: {
            id: data.ratingAgency && data.ratingAgency.value,
            name: data.ratingAgency && data.ratingAgency.label,
        },
        lawFirm: {
            id: data.lawFirm && data.lawFirm.value,
            name: data.lawFirm && data.lawFirm.label,
        },
    };
    return { actors: humps.decamelizeKeys(mapData) };
}

export function updateActorsInfo(transId, actors) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/actors`, mapActors(actors))
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(actorsUpdate(transId, unMapActors(d)));
            })
            .then(() => {
                toastr.success('Success!', 'Counterparties changes updated successfully.');
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving actors info', m);
                });
            });
    };
}

export function getActorsInfo(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/actors`)
            .then((d) => {
                dispatch({
                    type: ACTORS_GET_SUCCESS,
                    data: unMapActors(d),
                    transId: id,
                });
                return d;
            })
            .catch(() => '');
}

/* Actors >>>>>> */

/* <<<<<< Documents */
export function generateDocuments(transId) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/generate_documents `, {})
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: d, id: transId });
            })
            .then(() => {
                toastr.success('Success!', 'Documents generation initiated successfully.');
            })
            .then(() => dispatch(pollingPTCDocs(transId)))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                });
            });
}

export function pollingPTCDocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getPTCDocs(id)), 180000, 1000)
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: d, id });
                toastr.success('Success!', 'Documents generated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Generating Documents', errMsg);
                }
            });
}

function getPTCDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then((d) => unMapPTC(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated' || item.files[0].state === 'uploaded');
                dispatch({ type: TRANSACTION_GET, data: d, transId: id });
                if (isGenerated && docs.length > 0) return d;
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching Documents', m);
                }),
            );
}

export function loadDocuments(id, type = null) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/legal_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                if (type === 'poll') {
                    if (d && d.legalDocuments && d.legalDocuments.length > 0 && d.legalDocuments.filter((f) => f.filename).length > 0) {
                        dispatch({
                            type: DOCUMENTS_FETCH_SUCCESS,
                            data: d.legalDocuments,
                            transId: id,
                        });
                        return d;
                    }
                } else if (d && d.legalDocuments && d.legalDocuments.length > 0) {
                    dispatch({
                        type: DOCUMENTS_FETCH_SUCCESS,
                        data: d.legalDocuments,
                        transId: id,
                    });
                    return d;
                }
                return null;
            })
            .catch(() => '');
}

export function pollDocuments(id) {
    return (dispatch) => {
        dispatch({ type: DOCUMENTS_FETCH_REQUEST, transId: id });

        return fetchUtils
            .poll(() => dispatch(getPTCDocs(id)), 20000, 1000)
            .catch((e) => {
                fetchUtils.handleError(e).then((m) => {
                    toastr.error('Error retrieving documents', m);
                });
            });
    };
}

export function uploadLegalDocument(id, data) {
    return (dispatch) => {
        dispatch({ type: DOCUMENT_UPLOAD_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`, data)
            .then((d) => humps.camelizeKeys(d))
            .then(() => dispatch(getPTCDocs(id)))
            .catch((ex) => {
                dispatch({ type: DOCUMENT_UPLOAD_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating legal docs', m);
                });
            });
    };
}

export function destroyLegalDocument(id, fileId) {
    return (dispatch) =>
        dispatch(destroyFile(fileId)).then(() => {
            dispatch(getPTCDocs(id));
        });
}

export function getPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const others = d.otherFiles;
                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (_get(d, 'errorMessage')) {
                    return _get(d, 'errorMessage');
                }
                if (!docs.length && others.length) {
                    dispatch({ type: PTC_GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return null;
                }
                if (isFailed) {
                    return docs;
                } else if (isGenerated) {
                    dispatch({ type: PTC_GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }

                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}

export function pollPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getPTCExecDocs(id)), 180000, 3000)
            .then((d) => {
                if (!Array.isArray(d)) {
                    toastr.error('Error Finalizing Documents', d);
                } else if (!d.length) {
                    toastr.error('Error Finalizing Documents', 'Failed to generate executed documents');
                } else if (d.some((item) => item.files[0].state === 'failed')) {
                    toastr.error(
                        'Error Finalizing Documents',
                        `Please check the format of ${humps.pascalize(d.find((item) => item.files[0].state === 'failed').files[0].type)}`,
                    );
                } else {
                    toastr.success('Success!', 'Documents generated successfully.');
                }
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Polling Documents', errMsg);
                }
            });
}

export function generateExecDocs(transId, setSubmitting) {
    toastr.success('Success!', 'Documents generation Request initiated');
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/executable_documents`)
            .then(() => dispatch(pollPTCExecDocs(transId)))
            .then(() => setSubmitting(false))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                }),
            );
}

export function revokeExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/executable_documents/revoke`)
            .then((d) => {
                if (_get(d, 'success')) {
                    dispatch(getPTCExecDocs(id));
                } else {
                    toastr.error('Failed to revoke executable documents');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error revoking executable documents', m);
                }),
            );
}

/* Documents >>>>>> */

export function releaseTrans(transId, values) {
    return (dispatch) => {
        dispatch({ type: TRANS_RELEASE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/release`, values)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: TRANS_RELEASE_SUCCESS, data: d }))
            .then(() => {
                toastr.success('Success!', 'Transaction released successfully.');
                dispatch({
                    type: PRODUCT_RELEASE_CLICK,
                    meta: {
                        amplitude: [
                            {
                                eventType: EventTypes.track,
                                eventPayload: {
                                    eventName: PRODUCT_RELEASE_CLICK,
                                    transactionId: transId,
                                    transaction_type: PRODUCT_KEYS.securitization,
                                    ...values,
                                },
                            },
                        ],
                    },
                });
            })
            .then(() => {
                dispatch(getPTC(transId));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error releasing', m);
                }),
            );
    };
}

export function getPTCBids(id) {
    return (dispatch) => {
        dispatch({ type: BIDS_FETCH_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/bids`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_FETCH_SUCCESS, data: d }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

export function updateBid(transId, bId, action, body) {
    return (dispatch) => {
        dispatch({ type: BIDS_UPDATE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/bids/${bId}/${action}`, body)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_UPDATE_SUCCESS, data: d }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

export function finalizeBids(transId, body) {
    return (dispatch) => {
        dispatch({ type: BIDS_FINALIZE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/finalize_bids`, body)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_FINALIZE_SUCCESS, data: d }))
            .then(() => {
                dispatch(getPTC(transId));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

const mapFinalize = (data) => {
    const { tranches, ...rest } = data;
    const mData = {
        finalize: {
            tranches: tranches.map((t) => ({
                series: t.series,
                investors: t.investors.map((i) => ({
                    series: i.series,
                    investorId: i.investorId,
                })),
            })),
            ...rest,
        },
    };
    return humps.decamelizeKeys(mData);
};

export function finalizeTrans(transId, values) {
    return (dispatch) => {
        dispatch({ type: TRANS_FINALIZE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/finalize`, mapFinalize(values))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: TRANS_FINALIZE_SUCCESS, data: d }))
            .then(() => {
                toastr.success('Success!', 'Transaction finalized successfully.');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error finalizing', m);
                }),
            );
    };
}

export function getTranchInvestors(transId) {
    return (dispatch) => {
        dispatch({ type: TRANCHE_INVESTOR_GET_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/tranche_investors_data`)
            .then((d) => humps.camelizeKeys(d.tranche_investors))
            .then((d) => dispatch({ type: TRANCHE_INVESTOR_GET_SUCCESS, data: d }))
            .catch((ex) => {
                if (ex.response) {
                    const { status } = ex.response;
                    if (status === 404) {
                        return;
                    }
                }
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error finalizing', m);
                });
            });
    };
}

export function getExecutedDocuments(id) {
    return (dispatch) => {
        dispatch({ type: EXECUTED_DOCS_FETCH_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/executed_documents`)
            .then((d) => humps.camelizeKeys(d.executed_documents))
            .then((d) => dispatch({ type: EXECUTED_DOCS_FETCH_SUCCESS, data: d }))
            .catch(() => '');
    };
}

export function uploadFile(file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('transaction_file[document]', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch({ type: FILE_UPLOAD_SUCCESS, data: d });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

export function destroyFile(transId, fileId) {
    return (dispatch) =>
        fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/deal_documents/${fileId}`)
            .then(() => {
                toastr.success('File Destroyed');
                dispatch(getPTCDocs(transId));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error destroying transaction file', m);
                });
            });
}

export function uploadPTCDocs(id, file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('document', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/deal_documents`, body)
            .then(() => {
                dispatch(getPTCDocs(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

const formatAdHocData = (values) => {
    const data = {
        document: values,
    };
    return humps.decamelizeKeys(data);
};

export function addAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_CREATE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/add_document`, formatAdHocData(data))
            .then(() => {
                dispatch({ type: ADHOC_CREATE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Adhoc document has been created successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_CREATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding document', m);
                });
            });
    };
}

export function updateAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_UPDATE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/modify_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: ADHOC_UPDATE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Document settings has been updated successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating settings', m);
                });
            });
    };
}

export function removeDocPlaceholder(transId, data) {
    return (dispatch) => {
        dispatch({ type: PLACEHOLDER_REMOVE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/remove_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: PLACEHOLDER_REMOVE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Document placeholder has been removed successfully');
            })
            .catch((ex) => {
                dispatch({ type: PLACEHOLDER_REMOVE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing placeholder', m);
                });
            });
    };
}

export function getLoanScrubb(id, type) {
    return (dispatch) => {
        dispatch({ type: FETCH_SCRUBB_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/${type}/${id}/loan_scrubb`)
            .then((d) => humps.camelizeKeys(d))
            .then((data) => {
                dispatch({ type: FETCH_SCRUBB_SUCCESS, data });
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FETCH_SCRUBB_FAILURE });
                    toastr.error('Error fetching scrub data', m);
                });
            });
    };
}

export function requestLoanScrubb(values, setSubmitting, formData) {
    const { poolStates = [], transactionId, paramKey, type: transactionType } = formData;
    const formValues = { ...values };
    const { scrubType, selectedPoolState = [] } = formValues;
    formValues.poolStates = scrubType === 'full_pool' ? poolStates : selectedPoolState;
    delete formValues.selectedPoolState;
    delete formValues.type;
    const formVal = { [paramKey]: { ...formValues } };
    return (dispatch) => {
        dispatch({ type: LOAN_SCRUBB_REQUEST });
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/${transactionType}/${transactionId}/loan_scrubb`, humps.decamelizeKeys(formVal))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                setSubmitting(false);
                dispatch({ type: LOAN_SCRUBB_SUCCESS, status: d.status, poolStates: d.poolStates });
            })
            .catch((ex) => {
                setSubmitting(false);
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error initiating loan scrub', m);
                });
            });
    };
}

export function getPTCState(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then((d) => unMapPTC(d))
            .then((d) => {
                if ([TRANSACTION_STATE.SETTLED, TRANSACTION_STATE.MATURED].includes(d.transactionState)) {
                    dispatch({ type: TRANSACTION_GET, data: d, id });
                    return d;
                }

                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    return new Error(m);
                }),
            );
}

export function pollPTCState(id) {
    return (dispatch) => {
        return fetchUtils
            .poll(() => dispatch(getPTCState(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'Transaction state updated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving transaction state', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving transaction state', errMsg);
                }
            });
    };
}

export function updatePTC(id, values) {
    return (dispatch) => {
        dispatch({ type: FUNDING_STATUS_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`, humps.decamelizeKeys({ securitization: { ...values } }))
            .then((d) => unMapPTC(d))
            .then(() => dispatch(pollPTCState(id)))
            .then(() => {
                if (values.direct_settlement) {
                    dispatch({ type: FUNDING_STATUS_SUCCESS });
                    toastr.success('Success!', 'Transaction status updated.');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FUNDING_STATUS_FAILURE });
                    toastr.error('Error while updating', m);
                }),
            );
    };
}
