import { CAEvents } from 'app/components/Amplitude/AmplitudeEvents';
import { CREDAVENUE_DOMAIN, YUBI_DOMAIN } from 'app/constants/Constants';
import { getCurrentDomain } from 'app/utils/CommonUtils';
import React, { memo } from 'react';
import AmplitudeService from './Amplitude/AmplitudeService';
import Spinner from './Spinner';
import useYubiDomainResolver from 'app/hooks/useYubiDomainResolver';
import useTPCookieCheck from 'app/hooks/useTPCookieCheck';

const Events = CAEvents.YUBI_LAUNCH;
export const YUBI_DOMAIN_NOT_RESOLVED = 'yubi_domain_not_resolved';

export const YubiDomainResolverContext = React.createContext<any>({});

const YubiDomainResolver = ({ children }: any) => {
    const domain = getCurrentDomain();
    const { status } = useYubiDomainResolver({
        canFetch: domain === CREDAVENUE_DOMAIN,
    });
    const { supported } = useTPCookieCheck({
        canFetch: true,
    });

    if (status === null || supported === null) return <Spinner />;

    if (supported && status === 200 && domain === CREDAVENUE_DOMAIN) {
        window.location.href = window.location.href.replace(domain, YUBI_DOMAIN);
        return <></>;
    }

    if (status !== 200 && domain === CREDAVENUE_DOMAIN) {
        localStorage.setItem(YUBI_DOMAIN_NOT_RESOLVED, 'true');
        AmplitudeService.logEvent(Events.YUBI_DOMAIN_RESOLVED_STATUS, {
            domainResolved: false,
            domain,
            domainStatus: status,
        });
    }

    if (domain === YUBI_DOMAIN) {
        AmplitudeService.logEvent(Events.YUBI_DOMAIN_RESOLVED_STATUS, {
            domainResolved: true,
            domain,
            domainStatus: status,
        });
    }

    if (supported === false || supported === 'failed') {
        AmplitudeService.logEvent(Events.YUBI_DOMAIN_TP_COOKIE_NOT_SUPPORTED, {
            supportStatus: supported,
            domain,
            domainStatus: status,
        });
    }

    return <YubiDomainResolverContext.Provider value={{ supported, domain }}>{children}</YubiDomainResolverContext.Provider>;
};

const isPropsEqual = () => {
    return true;
};

export default memo(YubiDomainResolver, isPropsEqual);
