import { DEFAULT_TEXT_COLOR } from 'app/products/UIComponents/Constants';
import { getColor } from 'app/products/UIComponents/Utils/colorSets';
import styled from 'styled-components';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    mode?: 'light' | 'dark';
    textButton?: boolean;
    buttonType?: 'primary' | 'secondary' | 'tertiary' | 'destructive-perimary' | 'destructive-secondary' | 'success-primary' | 'success-secondary';
    icon?: 'string';
    img?: any;
    iconPosition?: 'right' | 'left';
    size?: 'large' | 'medium' | 'small';
    noBorderBottom?: boolean;
}

const getBorderButtonTypes = (type = 'primary') => {
    switch (type) {
        case 'secondary':
            return `
            color: ${getColor('primary', 'primary')} !important;
            background:  ${getColor('grey', '0')} !important;
            border: 1px solid ${getColor('primary', 'primary')} !important;
            box-sizing: border-box;
            a {
                color: ${getColor('primary', 'primary')} !important;
            }
            &:hover {
                background: ${getColor('primary', '100')} !important;
            }
            &:disabled{
                background:  ${getColor('grey', '0')} !important;
                color: ${getColor('grey', '400')} !important;
                border: 1px solid #D1D8E2 !important;
            }`;
        case 'tertiary':
            return `
                background: ${getColor('grey', '0')};
                border: 1px solid #9AA5B8;
                box-sizing: border-box;
                
                color: ${getColor('grey', '500')};
                &:hover{
                    border: 1px solid ${getColor('primary', DEFAULT_TEXT_COLOR)};
                    color: ${getColor('grey', '900')};
                }
                &:disabled{
                    background:  ${getColor('grey', '0')};
                    color: ${getColor('grey', '400')};
                    box-shadow: 0px 0px 0px;
                    border: 1px solid #D1D8E2;
                }`;
        case 'destructive-primary':
            return `
                background: ${getColor('error', '500')};
                box-shadow: inset 0px -4px 0px rgba(24, 52, 132, 0.2);
                color:  ${getColor('grey', '0')};
                &:hover{
                    background: ${getColor('error', '700')};
                }
                &:disabled{
                    background: ${getColor('grey', '200')};
                    color: ${getColor('grey', '400')};
                    box-shadow: 0px 0px 0px;
                }`;
        case 'destructive-secondary':
            return `
                background:  ${getColor('grey', '0')};
                border: 1px solid #EE4444;
                box-sizing: border-box;
                box-shadow: inset 0px -4px 0px rgba(238, 68, 68, 0.4);
                color: ${getColor('error', '500')};
                &:hover{
                    background: ${getColor('error', '700')};
                }
                &:disabled{
                    background:  ${getColor('grey', '0')};
                    color: ${getColor('grey', '400')};
                    box-shadow: 0px 0px 0px;
                    border: 1px solid #D1D8E2;
                }`;
        case 'success-primary':
            return `
                background: ${getColor('success', '600')};
                
                color:  ${getColor('grey', '0')};
                &:hover{
                    background: ${getColor('success', '800')};
                }
                &:disabled{
                    background: ${getColor('grey', '200')};
                    color: ${getColor('grey', '400')};
                    box-shadow: 0px 0px 0px;
                }`;
        case 'success-secondary':
            return `
                background:  ${getColor('grey', '0')};
                border: 1px solid #157F3D;
                box-sizing: border-box;
                box-shadow: inset 0px -4px 0px rgba(21, 127, 61, 0.2);
                a {
                    color: ${getColor('success', '700')};
                }
                color: ${getColor('success', '700')};
                &:hover{
                    background: #D6F3E2;
                }
                &:disabled{
                    background: ${getColor('grey', '0')};
                    color: ${getColor('grey', '400')};
                    box-shadow: 0px 0px 0px;
                    border: 1px solid #D1D8E2;
                }`;
        case 'text':
            return `
            border: none;
            color: ${getColor('primary', 'primary')};`;
        default:
            return `
                background: ${getColor('primary', 'primary')};
                
                color: ${getColor('grey', '0')};
                &:hover {
                    background-color: ${getColor('primary', '600')};
                    color: rgb(255, 255, 255);
                }
                &:disabled{
                    background: ${getColor('grey', '200')};
                    color: ${getColor('grey', '400')};
                    box-shadow: none;
                    border: none;
                }`;
    }
};

const getTextButtonProps = (type = 'primary', noBorderBottom = false) => {
    switch (type) {
        case 'secondary':
            return `
            border: none;
            background: inherit;
            border-radius: unset;
            color: ${getColor('grey', '500')};
                &:hover {
                    color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
                    ${!noBorderBottom && `border-bottom:  1px solid ${getColor('grey')}`}
                }
                &.disabled {
                    color: ${getColor('grey', '400')};
                }`;

        case 'tertiary':
            return `
                background: #FFFFFF;
                border: 1px solid #9AA5B8;
                box-sizing: border-box;
                box-shadow: inset 0px -4px 0px rgba(24, 52, 132, 0.2);`;
        case 'destructive-primary':
            return `
                background: #EE4444;
                box-shadow: inset 0px -4px 0px rgba(24, 52, 132, 0.2);`;
        case 'destructive-secondary':
            return `
                background: #FFFFFF;
                border: 1px solid #EE4444;
                box-sizing: border-box;
                box-shadow: inset 0px -4px 0px rgba(238, 68, 68, 0.4);`;
        case 'success-primary':
            return `
                background: #17A34A;
                box-shadow: inset 0px -4px 0px rgba(24, 52, 132, 0.2);`;
        case 'success-secondary':
            return `
                background: #FFFFFF;
                border: 1px solid #157F3D;
                box-sizing: border-box;`;
        case 'text':
            return `
            border: none;
            color: ${getColor('primary', 'primary')};`;
        default:
            return `
            color: ${getColor('primary', 'primary')} !important;
            border: none;
            background: inherit;
            border-radius: unset;
                &:hover {
                    ${!noBorderBottom && `border-bottom:  1px solid ${getColor('primary', 'primary')}`}
                }
                &:disabled {
                    color: ${getColor('grey', '400')};
                }`;
    }
};

const getSizeStyle = (size = 'medium', textButton = false) => {
    switch (size) {
        case 'large':
            return `
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                padding: 16px 40px;
                border-radius: ${textButton ? 'unset' : '10px'};
            `;
        case 'small':
            return `
                border-radius: ${textButton ? 'unset' : '6px'};
                padding: 8px 16px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
            `;
        default:
            return `
                padding: ${textButton ? 'unset' : '10px 24px'};
                border-radius: ${textButton ? 'unset' : '8px'};
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
            `;
    }
};

const Button = styled.button<ButtonProps>`
    transition: all 1s ease;
    border: none;
    font-family: Sofia Pro;
    ${({ size, textButton }) => {
        return getSizeStyle(size, textButton);
    }}
    ${({ buttonType, textButton, noBorderBottom }) => {
        return textButton
            ? `background: transparent !important;
        border-radius: 0 !important;
        padding: 0 !important;
        border: none !important;
        padding: 0 !important;
        ${getTextButtonProps(buttonType, noBorderBottom)}`
            : getBorderButtonTypes(buttonType);
    }}
    ${({ disabled }) =>
        disabled &&
        `
        opacity: 0.5 !important;
        pointer-events: none !important;
        cursor: disabled !important;
        `}
        `;

export default Button;
