import React from 'react';
import styled from 'styled-components';
import { SizeType, BorderType, Spacing, Gutter, Align, Direction, Color, Shade, HorizontalDimension } from '../Types/CommonTypes';
import { getColor } from '../Utils/colorSets';
import { DEFAULT } from '../Constants';
import CardHeader from './CardHeader';
import CardContent from './CardContent';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    children: any;
    size?: SizeType;
    borderType?: BorderType;
    borderRadius?: string | number;
    color?: Color;
    shade?: Shade;
    textColor?: Color;
    textShade?: Shade;
    borderColor?: Color;
    borderShade?: Shade;
    borderWidth?: number;
    borderStyle?: string;
    spacing?: Spacing;
    align?: Align;
    direction?: Direction;
    width?: HorizontalDimension;
    gutter?: Gutter;
}
const CardContainer = styled.div<CardProps>`
    &.blue-card{
        background: #041659;
    }
    width: ${({ width }) => `${width}px`};
    margin: ${({ gutter }) => `${gutter}px`};
    ${({ size }) =>
        size === 'medium' &&
        `
        width:100%;
        height:100%;
    `}
    ${({ size }) =>
        size === 'small' &&
        `
        width:250px;
        height:100%;
    `}
    ${({ spacing }) =>
        spacing === 'x-small' &&
        `padding:10px;
    `}
    ${({ spacing }) =>
        spacing === 'small' &&
        `padding:15px;
    `}
    ${({ spacing }) =>
        spacing === 'medium' &&
        `padding:20px 30px;
    `}
    ${({ spacing }) =>
        spacing === 'large' &&
        `padding:35px;
    `}
    ${({ color, shade, textColor, textShade, borderStyle, borderColor, borderShade }) =>
        `
            background:${getColor(color, shade)}
            color:${getColor(textColor, textShade)}
            border:1px ${borderStyle} ${getColor(borderColor, borderShade)};
            
            .sub-heading{
                color:${getColor(color, '500')}
            }
        `}
    ${({ borderType }) => borderType === 'rounded' && `border-radius:12px`}
    ${({ borderType }) => borderType === 'square' && `border-radius:0px`}
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    ${({ direction }) =>
        direction === 'horizontal' &&
        `
            flex-direction:row;
    `}${({ direction }) =>
    direction === 'vertical' &&
    `
        flex-direction:column;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:flex;
            justify-content:center;
            align-items:center;
    `}
`;
const Card: React.FC<CardProps> = ({
    children,
    size = 'medium',
    borderType = 'rounded',
    borderStyle = 'solid',
    color = 'grey',
    shade = DEFAULT,
    spacing = 'medium',
    align = 'leftTop',
    textColor = 'grey',
    textShade = '600',
    borderColor = 'grey',
    borderShade = '300',
    direction = 'vertical',
    borderRadius,
    width,
    gutter,
    ...rest
}): JSX.Element => {
    return (
        <CardContainer
            size={size}
            borderType={borderType}
            borderRadius={borderRadius}
            color={color}
            shade={shade}
            borderStyle={borderStyle}
            spacing={spacing}
            align={align}
            textColor={textColor}
            textShade={textShade}
            borderColor={borderColor}
            borderShade={borderShade}
            direction={direction}
            width={width}
            gutter={gutter}
            {...rest}
        >
            {children}
        </CardContainer>
    );
};

export default Card;
export { CardContent, CardHeader };
