import * as fetchUtils from 'app/utils/FetchUtils';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { toastr } from 'react-redux-toastr';
import { createLoadingSelector } from 'app/reducers/loading';
import { TRANSACTION_GET, unMapPTC, baseURL } from './transaction';

export const createActionName = (name) => `app/admin/ptcv1/${name}`;

export const GET_RATING_MODEL_REQUEST = createActionName('GET_RATING_MODEL_REQUEST');
export const GET_RATING_MODEL_SUCCESS = createActionName('GET_RATING_MODEL_SUCCESS');
export const GET_RATING_MODEL_FAILURE = createActionName('GET_RATING_MODEL_FAILURE');

export const POLL_RATING_MODEL_REQUEST = createActionName('POLL_RATING_MODEL_REQUEST');
export const POLL_RATING_MODEL_SUCCESS = createActionName('POLL_RATING_MODEL_SUCCESS');
export const POLL_RATING_MODEL_FAILURE = createActionName('POLL_RATING_MODEL_FAILURE');

export const PATCH_RATING_MODEL_REQUEST = createActionName('PATCH_RATING_MODEL_REQUEST');
export const PATCH_RATING_MODEL_SUCCESS = createActionName('PATCH_RATING_MODEL_SUCCESS');
export const PATCH_RATING_MODEL_FAILURE = createActionName('PATCH_RATING_MODEL_FAILURE');

export const isPollRModel = createLoadingSelector([createActionName('POLL_RATING_MODEL')]);
export const isGetRModel = createLoadingSelector([createActionName('GET_RATING_MODEL')]);

export function getLossModel(id) {
    return (dispatch) => {
        dispatch({ type: GET_RATING_MODEL_REQUEST });
        return fetchUtils
            .getJSON(`${baseURL(id)}/loss_estimation`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: GET_RATING_MODEL_SUCCESS, data: d, id });
            })
            .catch((ex) => {
                return fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: GET_RATING_MODEL_FAILURE });
                    toastr.error('Error retrieving Loss estimation Suggestion', m);
                });
            });
    };
}

export function checkLossEstimationSuggestion(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${baseURL(id)}/loss_estimation`)
            .then((d) => {
                if (!d.loss_estimation_generation_flag) {
                    return d;
                }
                return null;
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Loss estimation Suggestion', m);
                });
                return { error: true };
            });
}

export function pollLossEstimationSuggestion(id) {
    return (dispatch) => {
        dispatch({ type: POLL_RATING_MODEL_REQUEST });
        return fetchUtils
            .poll(() => dispatch(checkLossEstimationSuggestion(id)), 180000, 1000)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                if (!_has(d, 'error')) {
                    const cloneData = { ...d };
                    if (_get(d, 'loss_estimation_suggestions[0].label', '') === 'error') {
                        cloneData.loss_estimation_suggestions = [];
                        toastr.error('Error', _get(d, 'loss_estimation_suggestions[0].type'));
                    } else {
                        toastr.success('Success!', 'Loss estimation suggestion generated.');
                    }
                    dispatch({ type: GET_RATING_MODEL_SUCCESS, data: d, id });
                }
                dispatch({ type: POLL_RATING_MODEL_SUCCESS });
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                dispatch({ type: POLL_RATING_MODEL_FAILURE });
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving Loss estimation Suggestion', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving Loss estimation Suggestion', errMsg);
                }
            });
    };
}

export function generateLossEstimation(id, data, setSubmitting) {
    const processedData = {
        ptcTransaction: {
            lossEstimationPreferences: {
                ...data,
            },
        },
    };
    return (dispatch) => {
        fetchUtils
            .patchJSON(baseURL(id), humps.decamelizeKeys(processedData))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id });
                setSubmitting(false);
                toastr.success('Success!', 'Initiated Loss modelling. Please wait a few seconds.');
            })
            .then(() => dispatch(pollLossEstimationSuggestion(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    setSubmitting(false);
                    toastr.error('Error applying Loss modelling', m);
                }),
            );
    };
}

export default generateLossEstimation;
