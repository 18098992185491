import { StyleValue } from '../Types/CommonTypes';

export enum StyleOperators {
    ADD = '+',
    SUBTRACT = '-',
    MULTIPLY = '*',
    DIVIDE = '/',
}

const getQualifiedValue = (operator: StyleOperators, value: number, quantity: number): number => {
    switch (operator) {
        case StyleOperators.ADD:
            return value + quantity;
        case StyleOperators.MULTIPLY:
            return value * quantity;
        case StyleOperators.SUBTRACT:
            return value - quantity;
        case StyleOperators.DIVIDE:
            return value / quantity;
        default:
            return value;
    }
};

export const getStyleValue = (value: StyleValue, operator?: StyleOperators, quantity?: number): string => {
    if (operator && quantity) {
        const v = parseFloat(value.toString());
        return `${getQualifiedValue(operator, v, quantity)}px`;
    }
    return typeof value === 'string' ? value : `${value}px`;
};
