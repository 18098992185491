import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../utils/FetchUtils';

const initialState = {
    recentDeals: [],
};

// actions
const createActionName = name => `app/bi/ptc/${name}`;

export const RECENT_DEALS_FETCH_SUCCESS = createActionName('RECENT_DEALS_FETCH_SUCCESS');

export default function task(state = initialState, action) {
    switch (action.type) {
    case RECENT_DEALS_FETCH_SUCCESS:
        return {
            ...state,
            recentDeals: {
                ...state.recentDeals,
                [action.cacheName]: action.data,
            },
        };
    default:
        return state;
    }
}

// selectors
export function selectRecentDeals(state, name) {
    return state.bi.ptc.recentDeals[name];
}

export function getRecentDeals(cacheName, filterOptions, scopeOptions) {
    return (dispatch) => {
        const filters = Object.keys(filterOptions).map(o => Object.keys(filterOptions[o]).map(f => filterOptions[o][f].map(v => `${o}[${f}]=${v}`)));
        const scopes = (scopeOptions && `&scope=${scopeOptions.join(',')}`) || '';
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/market_securitizations?page=1&${filters.join('&')}${scopes}`)
            .then(d => humps.camelizeKeys(d.market_securitizations))
            .then(d => dispatch({ type: RECENT_DEALS_FETCH_SUCCESS, data: d, cacheName }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving recent deals', m);
                });
            });
    };
}
