import React, { useState } from 'react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styled from 'styled-components';
import fire from 'app/assets/images/fire.svg';
import flash from 'app/assets/images/flash.svg';
import { amountInCr, capitalize } from 'app/utils/StringUtils';
import { formatDate, formatDateV1 } from 'app/utils/DateUtils';
import ReactTooltip from 'react-tooltip';
import { PRODUCT_KEYS } from 'app/constants/Constants';
import { getTenor } from 'app/utils/TransactionUtils';
import IconButton from 'app/products/UIComponents/Icon/IconButton';
import { getColor } from '../Utils/colorSets';
import { DEFAULT_TEXT_COLOR } from '../Constants';

export const PDCWrapper = styled.div`
    position: relative;
    padding: 20px 30px;
    background: linear-gradient(180deg, #f2f5f9 0%, #ffffff 100%);
    border: 1px solid #d1d8e2;
    box-shadow: inset 0px 3px 0px #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-top: 24px;
    font-family: Sofia Pro;
    color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
    font-weight: 400;
    &.results {
        padding: 20px;
    }
`;

export const DataWrapper = styled(PDCWrapper)`
    background: #ffffff;
    border-radius: 16px;
`;

export const PrimaryContainer = styled.div`
    width: 68%;
`;

export const DataPrimaryContainer = styled.div`
    width: 94%;
`;

export const SecondaryContainer = styled.div`
    width: 68%;
    margin-top: 20px;
    margin-bottom: 12px;
    line-height: 20px;
    h3 {
        color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    &.show {
        transition: all 2s linear;
        display: block;
    }
    &.hide {
        display: none;
        opacity: 0;
    }
`;

export const LabelValueContainer = styled.p`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    span {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
        i,
        img {
        }
        .icon-tick2 {
            width: 9.5px;
            height: 11px;
            padding: 3px;
            background: #23c55e;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
                font-size: 5px;
                color: #fff;
                font-weight: 900;
            }
        }
        &.yield {
            position: relative;
        }
    }
    label {
        color: #4a5567;
    }
    .array-input-data {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const ImageWrapper = styled.span`
    padding: 5px;
    border-radius: 40%;
    margin-right: 5px;
    border-radius: 6px;
    &.tick {
        background: #bbf7d1;
    }
    &.fire {
        background: ${getColor('primary', '100')};
    }
    &.flash {
        background: ${getColor('secondary', '200')};
    }
`;

export const ShowToggle = styled.button`
    border: none;
    background: none;
    font-size: 12px;
    span,
    i {
        color: ${getColor('primary', 'primary')};
    }
    i {
        margin-left: 8px;
    }
`;

export const PoolText = styled.span`
    display: flex;
    width: 50%;
    text-align: left;
`;
export const TransactionInfoHeading = styled.div`
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
    margin-top: 20px;
`;
const InterestRateModal = styled.div`
    table {
        position: absolute;
        margin: 0;
        border: 0;
        transition: all 0.5s ease;
        width: 400px;
        background: #fff;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        z-index: 1;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        thead {
            background: #e9e9e9;
            th {
                padding: 10px;
                font-size: 12px;
            }
        }
        tbody {
            tr {
                td {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
    }
    i {
        font-size: 14px;
    }
`;

const newFormat = (date: any) => {
    let newDate = '';
    let first = true;
    for (const x in date) {
        if (date[x] === '-') {
            if (first) {
                newDate += ' ';
                first = false;
            } else newDate += ', ';
        } else newDate += date[x];
    }
    return newDate;
};
const PoolCard = ({ data, transactionType }: any): JSX.Element => {
    const [showMore, setShowMore] = useState<boolean>(false);
    return (
        <>
            <TransactionInfoHeading>
                <h3>Transaction Information</h3>
            </TransactionInfoHeading>
            <PDCWrapper>
                <PrimaryContainer>
                    <LabelValueContainer>
                        <span>
                            <ImageWrapper className="tick">
                                <i className="icon-tick2" />
                            </ImageWrapper>
                            {!!_get(data, 'loansSummary.poolPrincipal')
                                ? _get(data, 'loansSummary.poolPrincipal') == 0
                                    ? 'N/A'
                                    : `₹${amountInCr(_get(data, 'loansSummary.poolPrincipal'))} CR`
                                : _get(data, 'leanTransaction') && _get(data, 'poolPreference.poolPrincipal')
                                ? _get(data, 'poolPreference.poolPrincipal') == 0
                                    ? 'N/A'
                                    : `₹${amountInCr(_get(data, 'poolPreference.poolPrincipal'))} CR`
                                : 'N/A'}
                        </span>
                        <PoolText>
                            <label>Pool Quantum</label>
                        </PoolText>
                    </LabelValueContainer>
                    <LabelValueContainer>
                        <span>
                            <ImageWrapper className="fire">
                                <img src={fire} alt="tenor" />
                            </ImageWrapper>
                            {_get(data, 'leanTransaction') && _get(data, 'poolPreference.poolTenor')
                                ? _get(data, 'poolPreference.poolTenor') == 0
                                    ? 'N/A'
                                    : getTenor(_get(data, 'poolPreference.poolTenor'), transactionType)
                                : _get(data, 'loansSummary.poolTenor', 'N/A')}
                        </span>
                        <PoolText>
                            {' '}
                            <label>Tenor</label>
                        </PoolText>
                    </LabelValueContainer>
                    <LabelValueContainer>
                        <span>
                            <ImageWrapper className="flash">
                                <img src={flash} alt="indicative yield" />
                            </ImageWrapper>
                            {_get(data, 'leanTransaction') && _get(data, 'poolPreference.indicativeCoupon')
                                ? _get(data, 'poolPreference.indicativeCoupon') == 0
                                    ? 'N/A'
                                    : `${_get(data, 'poolPreference.indicativeCoupon')}%`
                                : _get(data, 'structuring.tranches', []).some((t: any) => _get(t, 'investors[0].yield'))
                                ? _get(data, 'structuring.tranches', [])
                                      .map((tranche: any) =>
                                          tranche.investors
                                              .map((inv: any) => `${inv.yield || 0}% ${inv.interestMethod === 'Coupon' ? '' : 'XIRR'}`)
                                              .join(', '),
                                      )
                                      .join(', ')
                                : capitalize(_get(data, 'pricing', '-'))}
                            {transactionType === PRODUCT_KEYS.assignment && <HistoricalRateChanges data={_get(data, 'historicalIndexRates', [])} />}
                        </span>
                        <PoolText>
                            {' '}
                            <label>Yield</label>
                        </PoolText>
                    </LabelValueContainer>
                    {_get(data, 'creditEnhancement', 0) > 25 && (
                        <LabelValueContainer>
                            <span>Upto {_get(data, 'creditEnhancement', 0).toFixed(2)}%</span>
                            <PoolText>
                                {' '}
                                <label
                                    data-for="creditEnh"
                                    data-tip={`<ul>
                                    <li>Over Collateral: ${_get(data, 'overCollateral', 0)}</li>
                                    <li>Cash Collateral: ${_get(data, 'cashCollateral', 0)}</li>
                                </ul>`}
                                    data-html={true}
                                >
                                    Credit Enhancement
                                </label>
                            </PoolText>
                            <ReactTooltip id="creditEnh" place="right" type="dark" effect="solid" html={true} />
                        </LabelValueContainer>
                    )}
                </PrimaryContainer>
                <SecondaryContainer className={showMore ? 'show' : 'hide'}>
                    <h3>Pool Level Information</h3>
                    <LabelValueContainer>
                        <label>Pool cut-off date</label>
                        <PoolText>{newFormat(formatDate(_get(data, 'poolSelectionCriteria.cutOffDate'))) || 'N/A'}</PoolText>
                    </LabelValueContainer>
                    <LabelValueContainer>
                        <label>First payout date</label>
                        <PoolText>
                            {newFormat(formatDate(_get(data, 'firstPayoutDate', _get(data, 'waterfallSummary.firstPayoutDate')))) || 'N/A'}
                        </PoolText>
                    </LabelValueContainer>
                    <LabelValueContainer>
                        <label>Maturity date</label>
                        <PoolText>{newFormat(formatDate(_get(data, 'loansSummary.poolMaturityDate'))) || 'N/A'}</PoolText>
                    </LabelValueContainer>
                    <LabelValueContainer>
                        <label>No. of payments</label>
                        <PoolText>{_get(data, 'numberOfPayments', _get(data, 'waterfallSummary.numberOfPayments')) || 'N/A'}</PoolText>
                    </LabelValueContainer>
                    {transactionType === PRODUCT_KEYS.ptc && (
                        <>
                            <LabelValueContainer>
                                <label>EIS Trapping%</label>
                                <PoolText>
                                    {!!_get(data, 'structuring.eisTrappingPercentage') &&
                                    typeof _get(data, 'structuring.eisTrappingPercentage') === 'number'
                                        ? `${_get(data, 'structuring.eisTrappingPercentage')}%`
                                        : 'N/A'}
                                </PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>EIS Type</label>
                                <PoolText>{_get(data, 'structuring.waterfallType') || 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Calculated MRR</label>
                                <PoolText>{_get(data, 'mrr') || 'N/A'}</PoolText>
                            </LabelValueContainer>
                        </>
                    )}
                    {transactionType === PRODUCT_KEYS.assignment && (
                        <>
                            <LabelValueContainer>
                                <label>Payout Date</label>
                                <PoolText>
                                    {_get(data, 'waterfallSummary.firstPayoutDate')
                                        ? formatDateV1(_get(data, 'waterfallSummary.firstPayoutDate'), 'DD')
                                        : 'N/A'}
                                </PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Current POS(Assignee Share)</label>
                                <PoolText>
                                    {data?.revisedPoolSummary?.currentPosAssigneeShare
                                        ? `${amountInCr(data?.revisedPoolSummary?.currentPosAssigneeShare)} CR`
                                        : 'N/A'}
                                </PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Assignor Share</label>
                                <PoolText>
                                    {data?.structuring?.sharing?.assignorShare ? `${data?.structuring?.sharing?.assignorShare}%` : 'N/A'}
                                </PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Minimum Holding Period</label>
                                <PoolText>{data?.revisedPoolSummary?.minimumHoldingPeriod ?? 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Holding Period</label>
                                <PoolText>{data?.revisedPoolSummary?.holdingPeriod ?? 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Remaining Tenure</label>
                                <PoolText>{data?.revisedPoolSummary?.remainingTenor ?? 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Remaining Instalments</label>
                                <PoolText>{data?.revisedPoolSummary?.revisedNoOfRepayments ?? 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Seasoning Completed</label>
                                <PoolText>{data?.revisedPoolSummary?.seasoningCompleted ?? 'N/A'}</PoolText>
                            </LabelValueContainer>
                            <LabelValueContainer>
                                <label>Revised Maturity Date</label>
                                <PoolText>
                                    {data?.revisedPoolSummary?.maturityDate
                                        ? formatDateV1(data?.revisedPoolSummary?.maturityDate, 'DD MMM, YYYY')
                                        : 'N/A'}
                                </PoolText>
                            </LabelValueContainer>
                        </>
                    )}
                </SecondaryContainer>
                <ShowToggle onClick={() => setShowMore(!showMore)}>
                    <span>{showMore ? 'Show less' : 'Show more'}</span>
                    <>{!showMore && ' deal info'}</> <i className={showMore ? 'icon-cheveron-up' : 'icon-chevron-down'} />
                </ShowToggle>
            </PDCWrapper>
        </>
    );
};

export const HistoricalRateChanges = ({ data = [] }: any) => {
    const [showHistoricalRate, setShowHistoricalRate] = useState<boolean>(false);

    React.useEffect(() => {
        if (showHistoricalRate) {
            document.addEventListener('click', closeModal);
        } else {
            document.removeEventListener('click', closeModal);
        }
        return () => {
            document.removeEventListener('click', closeModal);
        };
    }, [showHistoricalRate]);

    const closeModal = () => setShowHistoricalRate(false);

    return (
        <InterestRateModal>
            {_size(data) > 0 && (
                <>
                    <IconButton
                        onClick={() => setShowHistoricalRate(!showHistoricalRate)}
                        iconClass="icon-history"
                        color="primaryV1"
                        iconShade="500"
                        data-for="floatingRate"
                        data-tip="Historical Yield"
                    />
                    <ReactTooltip place="top" id="floatingRate" />
                </>
            )}
            {showHistoricalRate && (
                <table>
                    <thead>
                        <tr>
                            <th>Effective Date</th>
                            <th>Effective Interest Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d: any, i: number) => (
                            <tr key={`${d.effectiveDate}.${i}`}>
                                <td>{formatDateV1(d.effectiveFrom)}</td>
                                <td>{d.effectiveInterestRate}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </InterestRateModal>
    );
};
export default PoolCard;
