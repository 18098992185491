import React, { ReactNode, cloneElement, isValidElement } from 'react';
import styled from 'styled-components';
import _size from 'lodash/size';
import Spinner from 'app/components/Spinner';

interface ItableBody {
    body: Array<object>;
    isLoading: boolean;
    columns: Array<object>;
    customCell?: ReactNode;
    onScroll?: any;
    shouldFetch?: any;
    isFetching?: any;
}

interface CellProps {
    data: any;
    content: ReactNode;
}

const Cell: React.FC<CellProps> = (props: CellProps) => {
    const { content, data } = props;
    if (isValidElement(content)) {
        return cloneElement(content, data);
    }
    return (
        <td className={data.className} key={data.key}>
            <p>{data.label}</p>
        </td>
    );
};

const TableBody: React.FC<ItableBody> = ({ body, isLoading, columns, customCell, onScroll, shouldFetch, isFetching }) => (
    <tbody>
        {isLoading ? (
            <tr>
                <td colSpan={columns.length}>
                    <Loader>
                        <Spinner />
                    </Loader>
                </td>
            </tr>
        ) : (
            <>
                {_size(body) === 0 ? (
                    <tr>
                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                            No data available
                        </td>
                    </tr>
                ) : (
                    <>
                        {body.map((d: any, i: number) => (
                            <tr key={`${d.index}.${i}.table`} className={d.className}>
                                {d.data.map((data: any, j: number) => (
                                    <Cell key={`${data.key}.${j}.cell`} data={data} content={customCell} />
                                ))}
                            </tr>
                        ))}
                    </>
                )}
            </>
        )}
    </tbody>
);

export const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
`;

export default TableBody;
