import React from 'react';

interface ItableHeader {
    columns: Array<object>;
}

const TableHeader: React.FC<ItableHeader> = ({ columns = [] }) => (
    <thead>
        <tr>
            {columns.map((data: any, i: number) => (
                <th className={data.className} key={`${data.key}-table-${i}`}>
                    <p>{data.label}</p>
                </th>
            ))}
        </tr>
    </thead>
);

export default TableHeader;
