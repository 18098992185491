import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import Header from 'app/components/Header/CommonHeader';
import EnvironmentAlert from './EnvironmentAlert';

const AuthHeader = React.lazy(() => import('app/components/Header'));

const CACommonLayout = ({ component: Content, environment: { alert }, showAuthenticatedHeader = false, containerClass, ...rest }: any) => (
    <div>
        {alert && alert.title && (
            <CSSTransition classNames="fade" timeout={{ exit: 300, enter: 500 }}>
                <EnvironmentAlert title={alert.title} desc={alert.desc} />
            </CSSTransition>
        )}
        {showAuthenticatedHeader ? <AuthHeader /> : <Header />}
        <div className={classNames({ container: showAuthenticatedHeader && !containerClass, [`${containerClass}`]: containerClass })}>
            <Content {...rest} />
        </div>
    </div>
);

const mapStateToProps = (state: any) => ({
    environment: state.environment,
});

const reduxConnector = connect(mapStateToProps);

export default reduxConnector(CACommonLayout);
