import {
    HOME,
    RESOURCES,
    REGULATORY_REPORTS,
    QUICKACCESS_RESOURCES,
    QUICKACCESS_SECTORNOTES,
    POOLS_LIST_PAGE,
    LOANS_LIST_PAGE,
    POOL_FILES,
    OVERALL_SERVICER_REPORTS,
    REPORTS,
    INIT_POOL,
    PTCV1_TRANSACTION,
    DAV2_TRANSACTION,
    DASHBOARD_HOME,
} from 'app/constants/Routes';
import { TABS } from 'app/constants/TransactionList';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ROUTE_CHANGE = 'ROUTE CHANGE';
export const DOWNLOAD_FILE = 'DOWNLOAD FILE';
export const TRANS_DETAILS_CLICK = 'TRANS DETAILS CLICK';
export const SERVICER_REPORT_UPLOAD_INIT = 'SERVICER REPORT UPLOAD INIT';
export const SERVICER_REPORT_UPLOAD_COMPLETED = 'SERVICER REPORT UPLOAD COMPLETED';
export const ACTIONABLE_TASK_POPUP = 'ACTIONABLE TASK POPUP';
export const ACTIONABLE_TASK_COMPLETED = 'ACTIONABLE TASK COMPLETED';
export const ACTIONABLE_TASK_OPEN = 'ACTIONABLE TASK OPEN';
export const CREDAVENUE_URL_BLOCKED = 'CREDAVENUE_URL_BLOCKED';

export const FILTER_DA = 'FILTER DA';
export const FILTER_PTC = 'FILTER PTC';
export const FILTER_ALL = 'FILTER ALL';
export const TRANSACTION_HEADER_TAB = 'TRANSACTION HEADER TAB';
export const INVESTOR_DOWNLOADS = 'INVESTOR DOWNLOAD';
export const TABLEAU_REPORTS_CLICK = 'TABLEAU_REPORTS_CLICK';
export const DOWNLOAD_REGULATORY_REPORTS = 'DOWNLOAD_REGULATORY_REPORTS';

// Customer and Product
export const SAMPLE_MCD = 'SAMPLE_MCD';
export const UPLOAD_MCD = 'UPLOAD MCD';
export const UPLOAD_MCD_SUBMIT = 'UPLOAD MCD SUBMIT';
export const UPLOAD_RS = 'UPLOAD RS';
export const UPLOAD_RS_SUBMIT = 'UPLOAD RS SUBMIT';

// Customer
export const CUSTOMER_INITIATE_TRANSACTION = 'CUSTOMER INITIATE TRANSACTION';
export const CUSTOMER_CLIENTS = 'CUSTOMER CLIENTS';
export const CUSTOMER_TOOLS = 'CUSTOMER TOOLS';
export const CUSTOMER_PROFILE = 'CUSTOMER PROFILE';
export const CUSTOMER_SETTLED_DEALS = 'CUSTOMER SETTLED DEALS';
export const CUSTOMER_LIVE_DEALS = 'CUSTOMER LIVE DEALS';
export const CUSTOMER_MATURED_DEALS = 'CUSTOMER MATURED DEALS';
export const CUSTOMER_SERVICER_REPORTS_DOCS_ATTACHED = 'CUSTOMER SERVICER REPORTS DOCS ATTACHED';
export const CUSTOMER_SERVICER_REPORTS_SUBMIT = 'CUSTOMER SERVICER REPORTS SUBMIT';
export const UPLOAD_POOL_LEAN = 'UPLOAD POOL LEAN';

export const CUSTOMER_UPLOADS = 'CUSTOMER UPLOAD';
export const CUSTOMER_INITIATE_CLICK = 'CUSTOMER INITIATE CLICK';
export const PROCEED_WIHTOUT_FILE_CLICK = 'PROCEED WIHTOUT FILE_CLICK';
export const CREATE_LEAN_TRANSACTION = 'CREATE LEAN TRANSACTION';
export const UPLOAD_INDICATIVE_TERM_SHEET = 'UPLOAD INDICATIVE TERM SHEET';
export const INITATE_TRANSACTION_PRODUCT_CLICK = 'INITATE TRANSACTION PRODUCT CLICK';

// PRODUCT
export const PRODUCT_RELEASE_CLICK = 'PRODUCT RELEASE CLICK';
export const PRODUCT_MOVED_TO_SETTLED = 'PRODUCT MOVED TO SETTLED';
export const PRODUCT_MOVED_TO_SETTLED_CONFIRM = 'PRODUCT MOVED TO SETTLED CONFIRM';
export const PRODUCT_MOVED_TO_SETTLED_CANCEL = 'PRODUCT MOVED TO SETTLED CANCEL';
export const PRODUCT_UPLOAD_MULTI_MCD_SUBMIT = 'PRODUCT_UPLOAD_MULTI_MCD_SUBMIT';
export const PRODUCT_BULK_VALIDATE_CLICK = 'PRODUCT_BULK_VALIDATE_CLICK';
export const PRODUCT_BULK_PROCESS_CLICK = 'PRODUCT_BULK_PROCESS_CLICK';
export const PRODUCT_BULK_TRANSACTION_CLICK = 'PRODUCT_BULK_TRANSACTION_CLICK';

// INVESTOR
export const INVESTOR_EXPRESS_INTEREST = 'INVESTOR EXPRESS INTEREST';
export const INVESTOR_EXPRESS_INTEREST_SUBMIT = 'INVESTOR EXPRESS INTEREST SUBMIT';
export const INVESTOR_EXPRESS_INTEREST_LEAN = 'INVESTOR EXPRESS INTEREST LEAN';
export const INVESTOR_EXPRESS_INTEREST_SUBMIT_LEAN = 'INVESTOR EXPRESS INTEREST SUBMIT LEAN';
export const INVESTOR_MAKE_A_BID = 'INVESTOR MAKE A BID';
export const INVESTOR_MAKE_A_BID_SUBMIT = 'INVESTOR MAKE A BID SUBMIT';
export const INVESTOR_UPLOAD_FUNDING_DOC = 'INVESTOR UPLOAD FUNDING DOC';
export const INVESTOR_UPLOAD_FUNDING_DOC_SUBMIT = 'INVESTOR UPLOAD FUNDING DOC SUBMIT';
export const INVESTOR_APPLICATION_FORM = 'INVESTOR APPLICATION FORM';
export const INVESTOR_APPLICATION_FORM_SUBMIT = 'INVESTOR APPLICATION FORM SUBMIT';
export const LEFTNAVIGATION_SUMMARY = 'LEFTNAVIGATION_SUMMARY';
export const LEFTNAVIGATION_TRANSACTIONINFORMATION = 'LEFTNAVIGATION_TRANSACTIONINFORMATION';
export const LEFTNAVIGATION_TRANCHE = 'LEFTNAVIGATION_TRANCHE';
export const LEFTNAVIGATION_POOLOVERVIEW = 'LEFTNAVIGATION_POOLOVERVIEW ';
export const LEFTNAVIGATION_LAST5TRANSACTIONS = 'LEFTNAVIGATION_LAST5TRANSACTIONS';
export const LEFTNAVIGATION_COLLECTIONEFFICIENCY = 'LEFTNAVIGATION_COLLECTIONEFFICIENCY ';
export const LEFTNAVIGATION_PREVIOUSDEALPERFORMANCE = 'LEFTNAVIGATION_PREVIOUSDEALPERFORMANCE ';
export const LEFTNAVIGATION_SIMILARDEALS = 'LEFTNAVIGATION_SIMILARDEALS';
export const LEFTNAVIGATION_DISBURSEMENT_DETAILS = 'LEFTNAVIGATION_DISBURSEMENT_DETAILS';
export const LEFTNAVIGATION_POOLFILES = 'LEFTNAVIGATION_POOLFILES';
export const LEFTNAVIGATION_TRANSACTIONFILES = 'LEFTNAVIGATION_TRANSACTIONFILES';
export const LEFTNAVIGATION_COUNTERPARTIES = 'LEFTNAVIGATION_COUNTERPARTIES';
export const SIMILARDEALSCLICKS = 'SIMILARDEALSCLICKS';
export const DETAILSPAGEFILESDOWNLOADED = 'DETAILSPAGEFILESDOWNLOADED ';
export const LAST5TRANSACTIONSCLICKS = 'LAST5TRANSACTIONSCLICKS';
export const EI_TELL_US_MORE = 'EXPRESS INTEREST TELL US MORE';
export const EI_DETAILS_SUBMIT = 'EXPRESS INTEREST DETAILS SUBMIT';
export const HOMEPAGE_POOLS_CARD_CLICK = 'HOMEPAGE POOLS CARD CLICK';
export const POOL_SELECTION_SELF_SERVE_APPLY = 'POOL SELECTION SELF SERVE APPLY';
export const POOL_STRUCTURING_SELF_SERVE_APPLY = 'POOL STRUCTURING SELF SERVE APPLY';

// INVESTOR ROUTE CHANGES
export const INVESTOR_EXPLORE = 'INVESTOR EXPLORE';
export const INVESTOR_CLIENTS = 'INVESTOR CLIENTS';
export const INVESTOR_TOOLS = 'INVESTOR TOOLS';
export const INVESTOR_MISSED_DEALS = 'INVESTOR MISSED DEALS';
export const INVESTOR_MATURED_DEALS = 'INVESTOR MATURED DEALS';
export const INVESTOR_LIVE_DEALS = 'INVESTOR LIVE DEALS';
export const INVESTOR_MY_PORTFOLIO = 'INVESTOR MY PORTFOLIO';
export const INVESTOR_TOP_PICK = 'INVESTOR TOP PICK';
export const INVESTOR_NEW_OFFERS = 'INVESTOR NEW OFFERS';

//Transaction tab events
export const DETAILS_TAB_CLICK = 'DETAILS TAB CLICK';
export const FILES_TAB_CLICK = 'FILES TAB CLICK';
export const DEAL_PERFORMANCE_TAB_CLICK = 'DEAL PERFORMANCE TAB CLICK';
export const MARKET_RESEARCH_TAB_CLICK = 'MARKET RESEARCH TAB CLICK';
export const SERVICER_REPORT_TAB_CLICK = 'SERVICER REPORT TAB CLICK';
export const INVESTMENT_NOTE_TAB_CLICK = 'INVESTMENT NOTE TAB CLICK';
export const REPORTS_TAB_CLICK = 'REPORTS TAB CLICK';
export const CASHFLOW_STATEMENT_TAB_CLICK = 'CASHFLOW STATEMENT TAB CLICK';

//Common routes
export const ROUTE_CHANGE_EVENTS = new Map([
    [HOME, { eventName: 'HOMEPAGE' }],
    [DASHBOARD_HOME, { eventName: 'DASHBOARD PAGE' }],
    [RESOURCES, { eventName: 'RESOURCES PAGE' }],
    [REGULATORY_REPORTS, { eventName: 'REGULATORY REPORTS PAGE' }],
    [QUICKACCESS_RESOURCES, { eventName: 'QUICK ACCESS RESOURCES PAGE' }],
    [QUICKACCESS_SECTORNOTES, { eventName: 'QUICK ACCESS SECTOR NOTES PAGE' }],
    [POOLS_LIST_PAGE, { eventName: 'POOLS LIST PAGE' }],
    [LOANS_LIST_PAGE, { eventName: 'LOANS LIST PAGE' }],
    [POOL_FILES, { eventName: 'POOL FILES PAGE' }],
    [OVERALL_SERVICER_REPORTS, { eventName: 'SERVICER REPORT PAGE' }],
    [REPORTS, { eventName: 'TABLEAU REPORTS PAGE' }],
    [INIT_POOL, { eventName: 'INITIATE POOL PAGE' }],
    [PTCV1_TRANSACTION, { eventName: 'PTCV1 TRANSACTION PAGE' }],
    [DAV2_TRANSACTION, { eventName: 'DAV2 TRANSACTION PAGE' }],
]);

export const PRODUCT_USER_EVENTS = {
    TRANSACTION_FINALISE_CLICK: 'TRANSACTION FINALISE CLICK',
};

export const TRANSACTION_VIEW_TAB_EVENTS = {
    GRID_VIEW_ICON_CLICK: 'GRID VIEW ICON CLICK',
    LIST_VIEW_ICON_CLICK: 'LIST VIEW ICON CLICK',
};

export const POOLS_LIST_PAGE_FILTERS = {
    [TABS.NEW]: 'FILTERS ALL POOLS',
    [TABS.RECOMMENDED]: 'FILTERS RECOMMENDED',
    [TABS.CLIENT_PORTFOLIO]: 'FILTERS CLIENT PORTFOLIO',
    [TABS.RECENTLY_ADDED]: 'FILTER RECENTLY ADDED',
    [TABS.HIGH_VOLUME]: 'FILTERS HIGH VOLUME',
    [TABS.LIVE]: 'FILTERS LIVE DEALS',
    [TABS.SETTLED]: 'FILTERS SETTLED DEALS',
    [TABS.MATURED]: 'FILTERS MATURED DEALS',
    [TABS.TRENDING]: 'FILTERS TRENDING DEALS',
};

export const REQUEST_POOLS = {
    REQUEST_POOLS_EXPLORE: 'REQUEST_POOLS_EXPLORE',
    REQUEST_POOLS_DETAILSPAGE: 'REQUEST_POOLS_DETAILSPAGE',
    REQUEST_POOLS_CREATEREQUEST: 'REQUEST_POOLS_CREATEREQUEST',
};

export const PAYOUT_REPORTS_ACTIONS = {
    UPLOAD_REPORTS: 'UPLOAD PAYOUT',
    LIST_ITEM_FILTERS: 'PAYOUT FILTERS',
    CUSTOM_VALIDATIONS_CLICK: 'PAYOUT CUSTOM VALIDATIONS CLICK',
    REPORTS_MAPPING_CLICK: 'PAYOUT REPORTS MAPPING_CLICK',
    SERVICER_REPORT_SAVE_CUSTOM_VALIDATIONS: 'SERVICER REPORT SAVE CUSTOM VALIDATIONS',
    SERVICER_REPORT_CLOSE_CUSTOM_VALIDATIONS: 'SERVICER REPORT CLOSE CUSTOM VALIDATIONS',
    DOWNLOAD_PAYOUT_REPORTS: 'DOWNLOAD PAYOUT REPORT',
    CHANGE_PAYOUT_REPORTS_TAB: 'CHANGE PAYOUT REPORTS TAB',
    DOWNLOAD_ERROR_FILE: 'DOWNLOAD ERROR FILE',
};

export const REORIGINATE_DEAL = { REORIGINATE_DEAL_CLICK: 'REORIGINATE DEAL CLICK', REORIGINATE_DEAL_PROCEED: 'REORIGINATE DEAL PROCEED' };

export const STRESS_TESTING = { STRESS_TESTING_BEGIN: 'STRESS TESTING BEGIN' };
