import React from 'react';
import styled from 'styled-components';
import getBrowserName from 'app/utils/browserDetection';
import NotifyBar from 'app/components/StyledComponents/NotifyBar';

const browserName = getBrowserName();
export const checkUnsupportedBrowser = (browserList: Array<string>) => {
    return browserList.some((d: string) => {
        return d === browserName;
    });
};
export const BrowserAlertStyle = styled.div`
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 9999;
    background: #e7e9ee;
    &.custom-alert {
        position: relative;
        background: none;
        z-index: initial;
    }
    .notify-wrap {
        margin: 10px 10px 10px;
        min-height: 40px;
        font-weight: 500;
        padding: 10px;
        border-left: none;
        background: #fffbf5;
        .notify-settlement {
            padding-left: 55px;
            .settlement-details {
                font-size: 13px;
            }
            .flag-icon {
                display: flex !important;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }
`;
const BrowserAlert = ({ unsupportedBrowsers }: any): JSX.Element => {
    return (
        <BrowserAlertStyle>
            {checkUnsupportedBrowser(unsupportedBrowsers) && (
                <NotifyBar
                    message="Your current browser will not support all the features that we offer. We recommend you to use Google Chrome or Mozilla Firefox to improve your browsing experience."
                    type="warning"
                    containerClass="notify-wrap"
                    disableIcon
                    disableAction
                    successIcon="icon-error"
                />
            )}
        </BrowserAlertStyle>
    );
};
export default BrowserAlert;
