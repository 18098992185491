export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID}`,
        authority: 'https://login.microsoftonline.com/common',
        redirectURI: `${window.location.origin}`,
    },
};

export const requestObj = {
    scopes: ['user.read'],
};
