import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import * as ampTypes from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';
import * as fetchUtils from '../../../utils/FetchUtils';
import { PRODUCT_KEYS } from 'app/constants/Constants';

const initialState = null;

// actions
export const createActionName = (name) => `app/da/transaction/bids/${name}`;

export const BID_POST_SUCCESS = createActionName('BID_POST_SUCCESS');
export const BID_EDIT_SUCCESS = createActionName('BID_EDIT_SUCCESS');

export const BID_GET_SUCCESS = createActionName('BID_GET_SUCCESS');
export const BID_GET_FAILED = createActionName('BID_GET_FAILED');

export default function bids(state = initialState, action) {
    switch (action.type) {
        case BID_POST_SUCCESS:
            return {
                ...action.data,
            };
        case BID_GET_SUCCESS:
            return {
                ...action.data,
            };
        case BID_GET_FAILED:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export const daBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/assignments/${id}`;

const mapBid = (bid) => {
    const mbid = {
        ...bid,
        // expirationDate: moment(bid.expirationDate).format('YYYY-MM-DD'),
        preferredSettlementDate: moment(bid.preferredSettlementDate).format('YYYY-MM-DD'),
    };
    return { assignment_bid: humps.decamelizeKeys(mbid) };
};

const unmapBid = (bid) => {
    const umap = humps.camelizeKeys(bid);
    return {
        ...umap,
        // expirationDate: moment(umap.expirationDate).format('DD MMM, YYYY'),
        preferredSettlementDate: moment(umap.preferredSettlementDate).format('DD MMM, YYYY'),
    };
};

function postBid(id, bid) {
    const { id: bId } = bid;
    if (bId) {
        return fetchUtils.putJSON(`${daBaseUrl(id)}/bids/${bId}`, mapBid(bid));
    }
    return fetchUtils.postJSON(`${daBaseUrl(id)}/bids`, mapBid(bid));
}

export function placeBid(id, bid) {
    return (dispatch) =>
        postBid(id, bid)
            .then((d) => unmapBid(d))
            .then((d) => dispatch({ type: BID_POST_SUCCESS, data: d, id }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error posting bid', m);
                }),
            );
}

export function fetchBid(id, actor) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${daBaseUrl(id)}/bids?actor=${actor || ''}`)
            .then((d) => {
                if (d.length > 0) {
                    return unmapBid(d[0]);
                }
                throw new Error("Couldn't able to load bid");
            })
            .then((d) => dispatch({ type: BID_GET_SUCCESS, data: d, id }))
            .catch(() => dispatch({ type: BID_GET_FAILED }));
}

export function getBid(id, bId, actor) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${daBaseUrl(id)}/bids/${bId}?actor=${actor || ''}`)
            .then((d) => dispatch({ type: BID_GET_SUCCESS, data: unmapBid(d), id }))
            .catch(() => dispatch({ type: BID_GET_FAILED }));
}
export function makeBid(id, mode, payload = {}) {
    return (dispatch) =>
        dispatch({
            type: mode === 'init' ? ampTypes.INVESTOR_MAKE_A_BID : ampTypes.INVESTOR_MAKE_A_BID_SUBMIT,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: mode === 'init' ? ampTypes.INVESTOR_MAKE_A_BID : ampTypes.INVESTOR_MAKE_A_BID_SUBMIT,
                            transId: id,
                            transaction_type: PRODUCT_KEYS.direct_assignment,
                            ...payload,
                        },
                    },
                ],
            },
        });
}
