import React from 'react';
import { useRouterHelper } from 'app/hooks/useRouterHelper';

// Todo: Make strong typing here post react update
function withRouter<ComponentProps>(Component: React.FunctionComponent<ComponentProps>): any {
    function ComponentWithRouterProp(props: ComponentProps): any {
        const routeHelper = useRouterHelper();
        return <Component {...props} location={routeHelper.location} params={routeHelper.params} navigate={routeHelper.push} />;
    }

    return ComponentWithRouterProp;
}

export default withRouter;
