import { combineReducers } from 'redux';

import ptc from './ptc';
import task from '../../../reducers/task';
import transaction from './transaction';
import client from './client';

const rootReducer = combineReducers({
    ptc,
    task,
    client,
    transaction,
});

export default rootReducer;
