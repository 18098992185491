import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ParsedLink from 'app/components/ParsedLink';
import Logo from '../../assets/images/yubi-logos/yubi-normal.svg';

const primaryColor = '#293577';
const secondaryColor = '#00c7cc';
const tertiaryColor = '#f46058';

export const StyledHeader = styled.header`
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;
    box-shadow: 0 1px 3px #dadada;
    z-index: 99;
    width: 100%;
    background: #fff;
    font-family: 'Sofia Pro', sans-serif;
    .clearfix {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pull-left {
        flex-basis: 20%;
        a {
            display: block;
            figure {
                max-width: 250px;
                img {
                    backface-visibility: initial;
                }
            }
        }
    }
    .pull-right {
        flex-basis: 80%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .hamburger {
            display: none;
        }
        .list-inline {
            > li {
                padding: 0 30px;
                position: relative;
                &:not(:last-child):after {
                    position: absolute;
                    content: '/';
                    right: -2px;
                    top: 2px;
                    color: ${primaryColor};
                    font-weight: 600;
                }
                &.about {
                    &:not(:last-child):after {
                        content: '';
                    }
                }
                &:nth-child(5) {
                    padding-right: 0;
                    &:after {
                        content: '';
                    }
                }
                /* &:nth-child(3) {
          padding-right: 0;
          &:after {
            content: "";
          }
        } */
                > a {
                    color: ${primaryColor};
                    font-size: 13px;
                    line-height: 18px;
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    i {
                        transition: all 0.5s ease;
                        font-size: 14px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    &:hover {
                        color: ${secondaryColor};
                    }
                }
                &:not(:last-child):before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 28px;
                    top: 16px;
                    left: 0;
                    background: #fff;
                }
                &:hover {
                    a {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                    .sub_menus {
                        display: block;
                    }
                }
                &.menus {
                    > a {
                        pointer-events: none;
                    }
                }
                .sub_menus {
                    position: absolute;
                    background: #fff;
                    min-width: 180px;
                    top: 45px;
                    display: none;
                    left: 0;
                    box-shadow: 0 0 3px #dadada;
                    z-index: 999;
                    li {
                        a {
                            display: block;
                            color: ${primaryColor};
                            font-size: 13px;
                            font-weight: 600;
                            padding: 15px 20px;
                            border-bottom: 1px solid #eaeaef;
                            &:hover {
                                color: ${secondaryColor};
                                background: #eaeaef;
                            }
                        }
                    }
                }
                .get_started {
                    background: ${tertiaryColor};
                    border: 1px solid ${tertiaryColor};
                    color: #fff;
                    text-transform: uppercase;
                    padding: 8px 30px;
                    font-size: 13px;
                    font-weight: 600;
                    &:hover {
                        background: #fff;
                        color: ${tertiaryColor};
                    }
                }
                &:last-child {
                    padding-right: 0;
                    /* a {
                        border: 1px solid #060606;
                        border-radius: 2px;
                        padding: 5px 22px 8px;
                        display: inline-block;
                        &:hover {
                            background: #060606;
                            color: #fff;
                        }
                    } */
                }
            }
        }
    }
    @media (max-width: 991px) {
        display: block;
        padding: 20px 0;
        position: fixed;
        top: 0;
        background: #fff;
        width: 100%;
        box-shadow: 0px 3px 5px #ccc;
        z-index: 99;
        .clearfix {
            display: block;
        }
        .pull-left {
            a {
                figure {
                    max-width: 160px;
                }
            }
        }
        .pull-right {
            .hamburger {
                display: block;
                font-size: 26px;
                line-height: 14px;
                color: ${primaryColor};
                padding: 0;
                background: none;
                border: none;
                i {
                    line-height: 20px;
                }
            }
            ul.list-inline {
                position: fixed;
                top: 60px;
                left: 0px;
                width: 100%;
                height: 100vh;
                background: #fff;
                border-top: 1px solid #eaeaef;
                > li {
                    padding: 0px;
                    display: block;
                    position: relative;
                    &:not(:last-child):after {
                        left: 25px;
                        right: auto;
                        top: 21px;
                    }
                    /* &:nth-child(4) {
            padding-right: 0;
            &:after {
              content: "/";
            }
          } */
                    &:not(:last-child):before {
                        height: 0;
                    }
                    a {
                        padding: 20px 20px 20px 40px;
                        display: block;
                        border-bottom: 1px solid #eaeaef;
                    }
                    .get_started {
                        margin: 20px;
                        display: inline-block;
                    }
                    &.menus {
                        > a {
                            position: relative;
                            i {
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                font-size: 20px;
                                margin-top: -10px;
                                transform: rotate(270deg);
                                transition: all 0.5s ease;
                            }
                            &:active,
                            &:focus {
                                color: ${secondaryColor};
                                i {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                        .sub_menus {
                            position: static;
                            li {
                                a {
                                    font-weight: normal;
                                    padding-left: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface DropDownMenuProps {
    linkName: string;
    children: any;
    className?: string;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ linkName, children, className = '' }) => {
    const [menu, setMenu] = useState(false);
    const toggleMenu = (e: any) => {
        e.stopPropagation();
        setMenu(!menu);
    };
    return (
        <div className={classNames(`header-dropdown ${className}`, { 'is-open': menu })}>
            <a href="#!" onClick={(e) => toggleMenu(e)}>
                {linkName} <i className="icon-chevron-down" />
            </a>
            {children}
        </div>
    );
};

const Header = () => {
    const [hamburgerToggle, setHamburgerToggle] = useState(false);
    return (
        <StyledHeader data-src="Yubi">
            <div className="container">
                <div className="clearfix">
                    <div className="pull-left">
                        <ParsedLink to={process.env.REACT_APP_NEW_HOST} title="Yubi">
                            <figure>
                                <img src={Logo} alt={Logo} />
                            </figure>
                        </ParsedLink>
                    </div>
                    <div className="pull-right">
                        <button className="hamburger" onClick={() => setHamburgerToggle(!hamburgerToggle)}>
                            {hamburgerToggle ? <i className="icon-close-bold" /> : <i className="icon-toggle" />}
                        </button>
                        {hamburgerToggle && (
                            <ul className="list-inline visible-xs">
                                <li>
                                    <ParsedLink to={process.env.REACT_APP_NEW_HOST}>Home</ParsedLink>
                                </li>
                                <li className="menus">
                                    <DropDownMenu linkName="About" className="menus-inner">
                                        <ul className="list-unstyled sub_menus">
                                            <li>
                                                <ParsedLink to={`${process.env.REACT_APP_NEW_HOST}/news-and-media`}>News and Videos</ParsedLink>
                                            </li>
                                        </ul>
                                    </DropDownMenu>
                                </li>
                            </ul>
                        )}
                        <ul className="list-inline hidden-xs">
                            <li>
                                <ParsedLink to={process.env.REACT_APP_NEW_HOST}>Home</ParsedLink>
                            </li>
                            <li className="about">
                                <ParsedLink to="#">
                                    About <i className="icon-chevron-down" />
                                </ParsedLink>
                                <ul className="list-unstyled sub_menus">
                                    <li>
                                        <ParsedLink to={`${process.env.REACT_APP_NEW_HOST}/news-and-media`}>News and Videos</ParsedLink>
                                    </li>
                                </ul>
                            </li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </StyledHeader>
    );
};

export default Header;
