import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import size from 'lodash/size';
import { getJSON, handleErrorV2 } from 'app/utils/FetchUtils';

export const GET_INT_INVESTORS_SUCCESS = 'GET_INT_INVESTORS_SUCCESS';

export const intialIntInvestor = {
    interestedInvestorsCount: 0,
    investorInterests: [],
};

const getCRAIntInvestor = (transId: string) =>
    getJSON(
        `${process.env.REACT_APP_CS_MP_API_HOST}/customer_interest_relations?deal_id=${transId}&product_category=pools&relation_name=profile_interest`,
    );
const getPoolsIntInvestor = (transId: string) => getJSON(`${process.env.REACT_APP_MP_API_HOST}/assignments/${transId}/investor_interests`);

export function getIntInvestor(transId: string, dispatch: React.Dispatch<{ type: string; data?: any }>, setLoading: any): Promise<any> {
    setLoading(true);
    return Promise.all([getCRAIntInvestor(transId), getPoolsIntInvestor(transId)])
        .then((data) => {
            return humps.camelizeKeys({
                investorInterests:
                    get(data, '[0].customer_interest_relation').map((d: any) => {
                        const respectivePoolsData = get(data, '[1].investor_interests', []).find(
                            (p: any) => get(p, 'investor_id') === get(d, 'source_entity_id'),
                        );
                        return {
                            ...d,
                            inv_structuring: get(respectivePoolsData, 'inv_structuring'),
                            inv_pool_selection_criteria: get(respectivePoolsData, 'inv_pool_selection_criteria'),
                        };
                    }) || [],
                interestedInvestorsCount: size(get(data, '[0].customer_interest_relation') || []),
            });
        })
        .then((data) => {
            setLoading(false);
            dispatch({ type: GET_INT_INVESTORS_SUCCESS, data });
        })
        .catch((ex) => {
            setLoading(false);
            handleErrorV2(dispatch, ex).then((m: string) => {
                toastr.error('Error retrieving DA data', m);
            });
        });
}
