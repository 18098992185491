import { HOVER_BG_COLOR, HOVER_TEXT_COLOR, DEFAULT, DEFAULT_TEXT_COLOR } from '../Constants';
import { Color, Shade } from '../Types/CommonTypes';

const PRIMARY_COLORS = new Map([
    ['50', '#EFF6FF'],
    ['100', '#DBEAFF'],
    ['200', '#BFDCFE'],
    ['300', '#92C5FC'],
    ['400', '#61A5FA'],
    ['500', '#3B82F6'],
    ['600', '#2563EA'],
    ['700', '#1D4ED7'],
    ['800', '#1E40AE'],
    ['900', '#1E3B8B'],
    [DEFAULT, '#1D4ED7'],
    [DEFAULT_TEXT_COLOR, '#1B202D'],
    [HOVER_BG_COLOR, '#DBEAFF'],
    [HOVER_TEXT_COLOR, '#1B202D'],
    ['primary', '#3232FF'],
]);

const PRIMARY_COLORS_YUBI = new Map([
    ['50', '#FFF9F8'],
    ['100', '#FFF1EE'],
    ['200', '#FEE4DE'],
    ['300', '#F4D3CC'],
    ['400', '#F6CAC1'],
    ['500', '#EE654C'],
    ['600', '#BE4D38'],
    ['700', '#933827'],
    ['800', '#48150C'],
    ['900', '#02020A'],
    ['1000', '#1D3159'],
    [DEFAULT, '#933827'],
    [DEFAULT_TEXT_COLOR, '#1B202D'],
    [HOVER_BG_COLOR, '#FFF1EE'],
    [HOVER_TEXT_COLOR, '#1B202D'],
    ['primary', '#EE654C'],
]);

const PRIMARY_COLORS_V1 = new Map([
    ['50', '#F5F5FF'],
    ['100', '#EAEAFF'],
    ['200', '#D6D6FF'],
    ['300', '#ADADFF'],
    ['400', '#8484FF'],
    ['500', '#3232FF'],
    ['600', '#1E1E99'],
    ['700', '#141466'],
    ['800', '#0A0A33'],
    ['900', '#02020A'],
    [DEFAULT, '#141466'],
    [DEFAULT_TEXT_COLOR, '#1B202D'],
    [HOVER_BG_COLOR, '#DBEAFF'],
    [HOVER_TEXT_COLOR, '#1B202D'],
    ['primary', '#3232FF'],
]);

const SECONDARY_COLORS = new Map([
    ['50', '#ECFEFF'],
    ['100', '#CFFBFE'],
    ['200', '#A5F3FD'],
    ['300', '#66E8F8'],
    ['400', '#21D3ED'],
    ['500', '#07B6D5'],
    ['600', '#0891B3'],
    ['700', '#0F7490'],
    ['800', '#165E76'],
    ['900', '#164F63'],
]);

const SECONDARY_COLORS_YUBI = new Map([
    ['50', '#F2F7FF'],
    ['100', '#EBF3FF'],
    ['200', '#D3E5FF'],
    ['300', '#A7CBFF'],
    ['400', '#8CB9FC'],
    ['500', '#4D6D9B'],
    ['600', '#375480'],
    ['700', '#2E4B77'],
    ['800', '#243F69'],
    ['900', '#142A4A'],
]);

const SUCCESS_COLORS = new Map([
    ['50', '#F0FDF4'],
    ['100', '#DCFCE7'],
    ['200', '#BBF7D1'],
    ['300', '#86EFAC'],
    ['400', '#4ADE80'],
    ['500', '#23C55E'],
    ['600', '#17A34A'],
    ['700', '#157F3D'],
    ['800', '#166535'],
    ['900', '#15532E'],
    ['1000', '#14BC51'],
]);

const WARNING_COLORS = new Map([
    ['50', '#FEFBEA'],
    ['100', '#FEF3C6'],
    ['200', '#FDE689'],
    ['300', '#FBD34D'],
    ['400', '#FBBF23'],
    ['500', '#F49E0B'],
    ['600', '#D87706'],
    ['700', '#B5530A'],
    ['800', '#92400E'],
    ['900', '#793510'],
    [DEFAULT, '#793510'],
]);

const ERROR_COLORS = new Map([
    ['50', '#FDF1F1'],
    ['100', '#FEE2E1'],
    ['200', '#FECBCA'],
    ['300', '#FBA5A4'],
    ['400', '#F77170'],
    ['500', '#EE4444'],
    ['600', '#DC2625'],
    ['700', '#B81C1D'],
    ['800', '#991B1C'],
    ['900', '#7F1D1C'],
]);

const SPECIAL_COLORS = new Map([
    ['50', '#FDF2F8'],
    ['100', '#FDE7F3'],
    ['200', '#FBCFE8'],
    ['300', '#F9A8D3'],
    ['400', '#F473B6'],
    ['500', '#EB4899'],
    ['600', '#DA2778'],
    ['700', '#BD185C'],
    ['800', '#9D174C'],
    ['900', '#811942'],
]);

const GREY_COLORS = new Map([
    ['0', '#FFFFFF'],
    ['50', '#F8FAFC'],
    ['100', '#F2F5F9'],
    ['200', '#E3E8EF'],
    ['300', '#D1D8E2'],
    ['400', '#9AA5B8'],
    ['500', '#677489'],
    ['600', '#4A5567'],
    ['700', '#384150'],
    ['800', '#29303E'],
    ['900', '#1B202D'],
    ['1000', '#F4F5FF'],
    ['1100', '#464C62'],
    ['1200', '#E5EBF4'],
    ['1300', '#667085'],
    [DEFAULT, '#FFFFFF'],
    ['1400', '#667085'],
]);

const INFO_COLORS = new Map([
    ['100', '#EE6047'],
    ['200', '#1D3159'],
    ['300', '#677489'],
    ['400', '#9AA5B8'],
    ['500', '#E3E8EF'],
]);

const NO_COLORS = new Map([['0', 'none']]);

const CRED_COLORS: any = new Map([
    ['primary', PRIMARY_COLORS],
    ['secondary', SECONDARY_COLORS],
    ['success', SUCCESS_COLORS],
    ['warning', WARNING_COLORS],
    ['error', ERROR_COLORS],
    ['special', SPECIAL_COLORS],
    ['grey', GREY_COLORS],
    ['none', NO_COLORS],
    ['primaryV1', PRIMARY_COLORS_V1],
]);

const AVATAR_COLORS: any = new Map([
    ['secondary', SECONDARY_COLORS_YUBI],
    ['success', SUCCESS_COLORS],
    ['warning', WARNING_COLORS],
    ['error', ERROR_COLORS],
    ['special', SPECIAL_COLORS],
    ['grey', GREY_COLORS],
    ['none', NO_COLORS],
]);

const PHOENIX_COLORS = new Map([['blue', '#1D3159']]);

const YUBI_COLORS: any = new Map([
    ['primary', PRIMARY_COLORS_YUBI],
    ['secondary', SECONDARY_COLORS_YUBI],
    ['success', SUCCESS_COLORS],
    ['warning', WARNING_COLORS],
    ['error', ERROR_COLORS],
    ['special', SPECIAL_COLORS],
    ['grey', GREY_COLORS],
    ['none', NO_COLORS],
    ['primaryV1', PRIMARY_COLORS_YUBI],
    ['info', INFO_COLORS],
]);

const THEMES: any = {
    defaultTheme: 'grey',
    defaultShade: '900',
};

export const getColor = (theme: Color = THEMES.defaultTheme, shade: Shade = THEMES.defaultShade) => YUBI_COLORS.get(theme).get(shade) || 'none';

export const getColorValues = (variant: Color, start: number, end: number, sort = 'asc') => {
    const array = [...YUBI_COLORS.get(variant).values()];
    const result = array.filter((el: string, indx: number) => {
        return indx + 1 >= start && indx < end;
    });
    return sort === 'desc' ? result.reverse() : result;
};

export const getSequentialArrVal = (source: any, index: any, reverse = false) => {
    const arr = reverse ? source.reverse() : source;
    if (arr[index]) {
        return arr[index];
    }
    const indexVal = index % arr.length;
    return arr[indexVal];
};

export const getAvatarColor = (index = Math.floor(Math.random() * 10) + 1) => {
    const defaultBackgroundShade = '400';
    const defaultTextShade = '100';
    const avatarArr: any = [];
    AVATAR_COLORS.forEach((value: any, key: any) => {
        const obj = { background: '', text: '', type: key, bgColor: defaultBackgroundShade, textColor: defaultTextShade };
        if (key !== 'none' && key !== 'grey') {
            obj.background = value.get(defaultBackgroundShade);
            obj.text = value.get(defaultTextShade);
            avatarArr.push(obj);
        }
    });
    return getSequentialArrVal(avatarArr, index);
};
