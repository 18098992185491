/* eslint-disable no-case-declarations */
import { toastr } from 'react-redux-toastr';
import { putJSON } from 'app/utils/FetchUtils';
import humps from 'humps';
import { uniqBy } from 'lodash';
import { NotificationInfo } from './useNotifications';

export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_GET_NEXT_PAGE_SUCCESS = 'NOTIFICATION_GET_NEXT_PAGE_SUCCESS';

export const UPDATE_LIVE_NOTIFICATION = 'UPDATE_LIVE_NOTIFICATION';
export const MERGE_LIVE_NOTIFICATION = 'MERGE_LIVE_NOTIFICATION';
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
interface NotificationState {
    notifications: NotificationInfo[];
    liveNotifications: NotificationInfo[];
    unreadCount: number;
    totalItems: number;
    totalPages: number;
    currentPage: number;
}
interface NotificationAction {
    type: string;
    payload: any;
}
export const notificationInitialState = {
    notifications: [],
    liveNotifications: [],
    unreadCount: 0,
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
};

export const reducer = (state: NotificationState, action: NotificationAction) => {
    switch (action.type) {
        case NOTIFICATION_GET_SUCCESS:
            return {
                ...state,
                notifications: action.payload.notifications,
                liveNotifications: [],
                unreadCount: action.payload.unreadCount,
                totalItems: action.payload.totalItems,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case NOTIFICATION_GET_NEXT_PAGE_SUCCESS:
            return {
                ...state,
                notifications: uniqBy([...state.notifications, ...action.payload.notifications], 'id'),
                unreadCount: action.payload.unreadCount,
                totalItems: action.payload.totalItems,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case UPDATE_LIVE_NOTIFICATION:
            return {
                ...state,
                liveNotifications: [...state.liveNotifications, action.payload],
                unreadCount: state.unreadCount + 1,
            };
        case MERGE_LIVE_NOTIFICATION:
            let newNotes = [];
            if (state.notifications.length < action.payload.itemsPerPage) {
                newNotes = [...state.liveNotifications, ...state.notifications].slice(0, action.payload.itemsPerPage);
            } else {
                newNotes = [...state.liveNotifications, ...state.notifications].slice(0, state.notifications.length);
            }
            return {
                ...state,
                liveNotifications: [],
                notifications: newNotes,
            };
        case UPDATE_NOTIFICATION_STATUS:
            const note = state.notifications.find((n) => n.id === action.payload.id);
            if (note) note.readAt = action.payload.status === 'read' ? 'read' : '';
            return {
                ...state,
                unreadCount: state.unreadCount - 1,
            };
        default:
            return null;
    }
};

export const updateNotificationStatus = (id: number, status: string) => {
    return putJSON(`${process.env.REACT_APP_PLATFORMS_URI}/notification_service/web/notifications`, { notification: { ids: [id], status } })
        .then((d) => humps.camelizeKeys(d))
        .then(({ data }: any) => data)
        .catch((ex) => {
            if (ex !== 'cancelled') {
                console.warn(ex);
                toastr.error('Error while updating notification', ex);
            }
        });
};

export default reducer;
