import styled from 'styled-components';
import { Row } from 'app/styled/CommonStyles';

export const Container = styled.div`
    padding: 10px 0 0 0;
    ${Row} {
        margin-bottom: 30px;
        .recharts-responsive-container {
            svg {
                &.recharts-surface {
                    g {
                        &.recharts-cartesian-axis {
                            text {
                                font-weight: 600;
                            }
                            .recharts-cartesian-axis-ticks {
                                .recharts-cartesian-axis-tick {
                                    line {
                                        stroke: #999;
                                    }
                                    text {
                                        font-weight: 400;
                                        fill: #000;
                                        font-size: 12px;
                                        font-family: Sofia Pro, sans-serif;
                                    }
                                }
                            }
                        }
                    }
                    .recharts-layer {
                        &.recharts-bar {
                            .recharts-label-list {
                                text {
                                    &:first-child {
                                        font-size: 12px;
                                        transform: translateY(-7px);
                                        font-family: Sofia Pro, sans-serif;
                                    }
                                    &:nth-child(2) {
                                        font-size: 11px;
                                        font-weight: 800;
                                        transform: translateY(-4px);
                                        font-family: Sofia Pro, sans-serif;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .recharts-legend-wrapper {
                .recharts-default-legend {
                    li {
                        svg {
                            path {
                                transform: translateY(-4px);
                            }
                        }
                    }
                }
            }
        }
        &.delinquency-loss-inner {
            margin-bottom: 0;
        }
        .chart-pos-mover {
            .recharts-wrapper {
                > svg {
                    transform: translateX(-50px);
                }
            }
            &.rating-upgrade-chart {
                .recharts-wrapper {
                    > svg {
                        transform: translateX(-30px);
                    }
                }
            }
            &.del-los-graph-mover {
                .recharts-wrapper {
                    > svg {
                        transform: translateX(-25px);
                    }
                }
            }
        }
        .table-container {
            > .custom-scroll-h {
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
            }
        }
    }
`;

export const DefaultMessage = styled.div`
    text-align: center;
    padding: 64px;
    color: #666;
    h1 {
        color: #999;
    }
    .sprocess {
        margin-bottom: 24px !important;
    }
`;

export const Summary = styled.div`
    background: #f8f8f8;
    padding: 12px 15px;
    border: 1px solid #eaeaea;
    box-shadow: none;
    border-radius: 3px;
    & > div {
        max-height: 75px;
        overflow: hidden;
        transition: all 0.5s ease;
    }
    ul {
        margin: 0;
        padding-left: 0px;
        display: flex;
        justify-content: space-between;
        li {
            text-align: left;
            margin: 0;
            margin-right: auto;
            padding: 10px 0;
            flex-basis: 130px;
            span {
                font-size: 16px;
                font-weight: 600;
                color: #040404;
                margin-bottom: 8px;
                display: block;
            }
            p {
                font-size: 12px;
                padding: 0;
                line-height: 18px;
                color: #454545;
            }
        }
    }
`;
const Table = styled.div`
    border: 1px solid #eaeaea;
    border-radius: 5px;
    /* margin-bottom: 12px; */
    .trans-matrix-table {
        table {
            tr {
                th,
                td {
                    min-width: 60px;
                    max-width: 60px;
                    &:first-child {
                        width: 60px;
                    }
                }
            }
        }
    }
    .ce-table {
        table {
            tr {
                th,
                td {
                    &:first-child {
                        width: 120px;
                    }
                }
            }
        }
    }
    table {
        margin: 0;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        tr {
            th,
            td {
                border: none;
            }
        }
        thead {
            background: #000483;
            color: #fff;
        }
        tr {
            &:not(:last-child) {
                td {
                    border-bottom: 1px solid #eaeaea;
                }
            }
            th {
                padding: 6px 10px;
                p {
                    color: #fff;
                    text-transform: uppercase;
                    padding-bottom: 0;
                    text-align: left;
                    font-size: 12px;
                }
                &:first-child {
                    border-top-left-radius: 5px;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    max-width: 200px;
                    text-align: left;
                    background: #000483;
                }
                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
            td {
                padding: 10px;
                position: relative;
                p {
                    position: relative;
                    padding-bottom: 0;
                    text-align: left;
                    font-size: 12px;
                    color: #000;
                    span {
                        min-width: 42px;
                        display: inline-block;
                    }
                }
                &:first-child {
                    background: #f7f7f7;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    max-width: 200px;
                    text-align: left;
                    p {
                        font-weight: 600;
                        font-size: 13px;
                        color: #000;
                    }
                }
                i {
                    position: absolute;
                    right: 5px;
                    font-size: 7px;
                    vertical-align: middle;
                    line-height: 20px;
                    margin-left: 4px;
                    &.icon-caret-arrow-up {
                        color: #6cd479;
                    }
                    &.icon-sort-down {
                        color: #ff2323;
                    }
                }
            }
            &:last-child {
                border-bottom: none;
                td {
                    &:first-child {
                        border-bottom-left-radius: 5px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
    }
`;
const Title = styled.h4`
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    &.ce-title {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;
const ChartWrapper = styled.div`
    /* min-height: 350px; */
    ${Title} {
        margin-top: 16px;
    }
    .tab-head {
        font-size: 0;
        border: 1px solid #c5e7c2;
        border-radius: 3px;
        display: flex;
        width: 80px;
        position: absolute;
        top: 12px;
        right: 10px;
        li {
            display: inline-block;
            text-align: center;
            position: relative;
            margin: 0;
            padding: 0;
            button {
                color: #333;
                opacity: 0.5;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                padding: 7px 12px;
                /* width: 40px; */
                position: relative;
                background: #fff;
                border: none;
                height: 30px;
                i {
                    font-size: 15px;
                    vertical-align: middle;
                }
            }
            &:first-child {
                button {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }
            &:last-child {
                button {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
            &.active {
                button {
                    background: #d7f4d6;
                    opacity: 1;
                }
            }
        }
    }
    .tab-body {
        width: 100%;
        display: block !important;
        border-right: none;
        padding: 0;
        margin-top: 24px;
        .chart-wrap-box {
            padding: 20px;
            border: 1px solid #eaeaea;
            border-radius: 3px;
            &.coll-eff-graph {
                .recharts-responsive-container {
                    svg {
                        &.recharts-surface {
                            g {
                                &.recharts-cartesian-axis {
                                    text {
                                        /* transform: rotate(-90, 20, 270) translateX(-10px); */
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    select {
        width: 95px;
        position: absolute;
        padding: 0px 5px;
        top: 0px;
        right: 18px;
        height: 28px;
        border-radius: 4px;
        appearance: menulist;
        z-index: 2;
        border-radius: 3px !important;
        border-color: #eaeaea;
    }
    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin-top: 35px;
        border: 1px solid #eaeaea;
        border-radius: 5px;
    }
`;
const PayoutTable = styled.div``;
export const MatrixTable = styled.div``;

export const Styled = {
    Container,
    DefaultMessage,
    Table,
    ChartWrapper,
    Title,
    Summary,
    PayoutTable,
    MatrixTable,
};
