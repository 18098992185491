import React from 'react';
import moment from 'moment';
import { Foot } from './styled';

const Footer = () => (
    <Foot>
        <div className="container">
            <div className="clearfix">
                <ul className="list-inline">
                    <li>
                        <a href="https://www.go-yubi.com/tnc" rel="noopener noreferrer" target="_blank">
                            Terms &amp; conditions
                        </a>
                    </li>
                </ul>
                <span>Yubi™ (Trademark of CredAvenue Private Limited)</span>
                <ul className="list-inline social-links">
                    <li>
                        <a href="mailto:support@go-yubi.com" title="support@go-yubi.com">
                            support@go-yubi.com
                        </a>
                    </li>
                    <li>Follow us on:</li>
                    <li className="facebook">
                        <a href="https://www.facebook.com/credavenue/" title="Yubi Facebook page" rel="noopener noreferrer" target="_blank">
                            <i className="icon-facebook" />
                        </a>
                    </li>
                    <li className="twitter">
                        <a href="https://twitter.com/Yubi_fin" title="Yubi Twitter page" rel="noopener noreferrer" target="_blank">
                            <i className="icon-twitter" />
                        </a>
                    </li>
                    <li className="linkedin">
                        <a href="https://www.linkedin.com/company/go-yubi/" title="Yubi Linkedin page" rel="noopener noreferrer" target="_blank">
                            <i className="icon-linkedin" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </Foot>
);

export default Footer;
