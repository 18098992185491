import React from 'react';
import { getColor } from '../../UIComponents/Utils/colorSets';
import { DEFAULT } from '../../UIComponents/Constants';
import styled, { css } from 'styled-components';
import { Shade, TextTransform, Color } from '../../UIComponents/Types/CommonTypes';

export type TypographyVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'subheading' | 'body' | 'small' | 'tiny' | 'span';

export type FontStyleVariants = 'inherit' | 'italic' | 'initial' | 'normal' | 'oblique' | 'unset';

export type FontWeightVariants = 'semibold' | 'medium' | 'regular' | string;

const DEFAULT_COLOR = 'primary';
const DEFAULT_SHADE = '500';
const DEFAULT_VARIANT = 'body';

const fontWeightMap = new Map([
    ['semibold', '600'],
    ['medium', '500'],
    ['regular', 'normal'],
    [DEFAULT, 'normal'],
]);

export interface TypographyProps extends React.HTMLAttributes<HTMLHeadingElement> {
    variant?: TypographyVariants;
    fontStyle?: FontStyleVariants;
    fontWeight?: FontWeightVariants;
    color?: Color;
    shade?: Shade;
    textTransform?: TextTransform;
    align?: 'left' | 'center' | 'right';
    spacing?: number | string;
    size?: number;
}

const typographyMap = new Map([
    ['h1', 'h1'],
    ['h2', 'h2'],
    ['h3', 'h3'],
    ['h4', 'h4'],
    ['subheading', 'h6'],
    ['body', 'p'],
    ['small', 'p'],
    ['tiny', 'p'],
    ['span', 'span']
]);

const StyledTag: React.FC<TypographyProps> = ({ variant = DEFAULT_VARIANT, children, ...props }) => {
    const Component: any = typographyMap.get(variant);
    return <Component {...props}>{children}</Component>;
};

const StyledH1 = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 48px;
    line-height: 64px;
    font-weight: 600;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
`;

const StyledH2 = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const StyledH3 = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const StyledH4 = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const Subheading = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    font-weight: ${({ fontWeight }) => fontWeight}
        ${({ fontStyle }) =>
            fontStyle &&
            css`
                font-style: ${fontStyle};
            `};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const BodyText = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    font-weight: ${({ fontWeight }) => fontWeight}
        ${({ fontStyle }) =>
            fontStyle &&
            css`
                font-style: ${fontStyle};
            `};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const SmallText = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    font-weight: ${({ fontWeight }) => fontWeight}
        ${({ fontStyle }) =>
            fontStyle &&
            css`
                font-style: ${fontStyle};
            `};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const SpanText = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    font-weight: ${({ fontWeight }) => fontWeight}
        ${({ fontStyle }) =>
            fontStyle &&
            css`
                font-style: ${fontStyle};
            `};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

const TinyText = styled(StyledTag)<TypographyProps>`
    font-family: 'Sofia Pro';
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    color: ${({ color }) => color || 'inherit'};
    text-align: ${({ align }) => align || 'inherit'};
    text-transform: ${({ textTransform }) => textTransform || 'inherit'};
    padding: ${({ spacing }) => `${spacing || 0}px`};
    ${({ size }) =>
        size &&
        css`
            font-size: ${size}px;
        `};
`;

export const Typography: React.FC<TypographyProps> = ({ children, ...rest }) => {
    const { variant = DEFAULT_VARIANT, color = DEFAULT_COLOR, shade = DEFAULT_SHADE, fontWeight: weight } = rest;
    const textColor = getColor(color, shade);
    const fontWeight = fontWeightMap.get(weight || DEFAULT);
    const props = { ...rest, color: textColor, fontWeight, variant };
    switch (variant) {
        case 'h1':
            return <StyledH1 {...props}>{children}</StyledH1>;
        case 'h2':
            return <StyledH2 {...props}>{children}</StyledH2>;
        case 'h3':
            return <StyledH3 {...props}>{children}</StyledH3>;
        case 'h4':
            return <StyledH4 {...props}>{children}</StyledH4>;
        case 'subheading':
            return <Subheading {...props}>{children}</Subheading>;
        case 'body':
            return <BodyText {...props}>{children}</BodyText>;
        case 'small':
            return <SmallText {...props}>{children}</SmallText>;
        case 'span':
            return <SpanText {...props}>{children}</SpanText>;
        case 'tiny':
            return <TinyText {...props}>{children}</TinyText>;
    }
};

export default Typography;
