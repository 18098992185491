import React from 'react';
import IPBlocked from 'app/assets/images/ip-blocked.svg';
import { Wrapper, TextWrapper } from './styled';
import LogoImg from 'app/assets/images/yubi-logos/yubi-big.svg';
import Typography from 'app/products/UIComponentsV1/Typography';

const IPWhitelisting = () => {
    return (
        <Wrapper>
            <img src={LogoImg} alt="CA" className="ca-logo" style={{ height: '42px' }} />
            <img src={IPBlocked} alt="ip-blocked" className="ip-img" />
            <TextWrapper>
                <Typography color="grey" shade="900" variant="h4" fontWeight="semibold" style={{ marginTop: '41.6px' }}>
                    IP address blocked
                </Typography>
                <Typography color="grey" shade="600" variant="body" fontWeight="regular" style={{ marginTop: '8px', textAlign: 'center' }}>
                    You can’t access the portal from current IP. Contact your admin to know more about the access{' '}
                </Typography>
            </TextWrapper>
        </Wrapper>
    );
};

export default IPWhitelisting;
