import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from '../../../utils/FetchUtils';

import { createLoadingSelector } from '../../../reducers/loading';

const initialState = {
    isFetching: false,
};

// actions
const createActionName = name => `app/client/portfolio/${name}`;
export const FETCHING_DATA = createActionName('FETCHING_DATA');

export const FILE_UPLOAD_SUCCESS = createActionName('FILE_UPLOAD_SUCCESS');

export const OVERVIEW_GET_SUCCESS = createActionName('OVERVIEW_UPDATE_SUCCESS');

export const OVERVIEW_UPDATE_REQUEST = createActionName('OVERVIEW_UPDATE_REQUEST');
export const OVERVIEW_UPDATE_SUCCESS = createActionName('OVERVIEW_UPDATE_SUCCESS');
export const OVERVIEW_UPDATE_FAILURE = createActionName('OVERVIEW_UPDATE_FAILURE');

export const KYC_DOC_GET_SUCCESS = createActionName('KYC_DOC_GET_SUCCESS');

export const KYC_DOC_UPDATE_REQUEST = createActionName('KYC_DOC_UPDATE_REQUEST');
export const KYC_DOC_UPDATE_SUCCESS = createActionName('KYC_DOC_UPDATE_SUCCESS');
export const KYC_DOC_UPDATE_FAILURE = createActionName('KYC_DOC_UPDATE_FAILURE');

export const FINANCIAL_RESULT_GET_REQUEST = createActionName('FINANCIAL_RESULT_GET_REQUEST');
export const FINANCIAL_RESULT_GET_SUCCESS = createActionName('FINANCIAL_RESULT_GET_SUCCESS');
export const FINANCIAL_RESULT_GET_FAILURE = createActionName('FINANCIAL_RESULT_GET_FAILURE');

export const FINANCIAL_RESULT_UPDATE_REQUEST = createActionName('FINANCIAL_RESULT_UPDATE_REQUEST');
export const FINANCIAL_RESULT_UPDATE_SUCCESS = createActionName('FINANCIAL_RESULT_UPDATE_SUCCESS');
export const FINANCIAL_RESULT_UPDATE_FAILURE = createActionName('FINANCIAL_RESULT_UPDATE_FAILURE');

export const FINANCIAL_STATEMENT_UPDATE_REQUEST = createActionName('FINANCIAL_STATEMENT_UPDATE_REQUEST');
export const FINANCIAL_STATEMENT_UPDATE_SUCCESS = createActionName('FINANCIAL_STATEMENT_UPDATE_SUCCESS');
export const FINANCIAL_STATEMENT_UPDATE_FAILURE = createActionName('FINANCIAL_STATEMENT_UPDATE_FAILURE');

export const PORTFOLIO_CUT_GET_SUCCESS = createActionName('PORTFOLIO_CUT_GET_SUCCESS');

export const PORTFOLIO_CUT_UPDATE_REQUEST = createActionName('PORTFOLIO_CUT_UPDATE_REQUEST');
export const PORTFOLIO_CUT_UPDATE_SUCCESS = createActionName('PORTFOLIO_CUT_UPDATE_SUCCESS');
export const PORTFOLIO_CUT_UPDATE_FAILURE = createActionName('PORTFOLIO_CUT_UPDATE_FAILURE');

// reducer
export default function customer(state = initialState, action) {
    switch (action.type) {
    case FETCHING_DATA:
        return {
            ...state,
            isFetching: action.status,
        };
    case OVERVIEW_GET_SUCCESS:
    case OVERVIEW_UPDATE_SUCCESS:
        return {
            ...state,
            overview: action.data,
        };
    case KYC_DOC_UPDATE_SUCCESS:
    case KYC_DOC_GET_SUCCESS:
        return {
            ...state,
            kycDocuments: action.data,
        };
    case FINANCIAL_RESULT_UPDATE_SUCCESS:
    case FINANCIAL_RESULT_GET_SUCCESS:
        return {
            ...state,
            financialResults: action.data,
        };
    case PORTFOLIO_CUT_UPDATE_SUCCESS:
    case PORTFOLIO_CUT_GET_SUCCESS:
        return {
            ...state,
            portfolioCuts: action.data,
        };
    default:
        return state;
    }
}

// selectors
export const selectorFinancialResultLoading = createLoadingSelector([createActionName('FINANCIAL_RESULT_GET')]);

// action creators
export function uploadFile(file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('transaction_file[document]', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch({ type: FILE_UPLOAD_SUCCESS, data: d });
                return d;
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }));
    };
}

export function getOverview(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/overview`)
            .then(d => (d && d.overview && humps.camelizeKeys(d.overview)) || {})
            .then(d => dispatch({ type: OVERVIEW_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving overview', m);
                });
            });
}

export function updateOverview(customerId, data) {
    return (dispatch) => {
        dispatch({ type: OVERVIEW_UPDATE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/overview`, data)
            .then(d => (d && d.overview && humps.camelizeKeys(d.overview)) || {})
            .then(d => dispatch({ type: OVERVIEW_UPDATE_SUCCESS, data: d }))
            .catch((ex) => {
                dispatch({ type: OVERVIEW_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating overview', m);
                });
            });
    };
}

export function getKycDocuments(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/kyc_document`)
            .then(d => (d && d.kyc_documents && humps.camelizeKeys(d.kyc_documents)) || {})
            .then(d => dispatch({ type: KYC_DOC_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving KYC Document', m);
                });
            });
}

export function updateKycDocument(customerId, data) {
    return (dispatch) => {
        dispatch({ type: KYC_DOC_UPDATE_REQUEST });
        const kycDoc = {
            kyc_document: humps.decamelizeKeys(data),
        };
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/kyc_document`, kycDoc)
            .then(d => (d && d.kyc_documents && humps.camelizeKeys(d.kyc_documents)) || {})
            .then(d => dispatch({ type: KYC_DOC_UPDATE_SUCCESS, data: d }))
            .catch((ex) => {
                dispatch({ type: KYC_DOC_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating KYC document', m);
                });
            });
    };
}

export function getFinancialResult(customerId, yr) {
    return (dispatch) => {
        dispatch({ type: FINANCIAL_RESULT_GET_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/financial_result?year=${yr}`)
            .then(d => (d && d.financial_results && humps.camelizeKeys(d.financial_results)) || {})
            .then(d => dispatch({ type: FINANCIAL_RESULT_GET_SUCCESS, data: d }))
            .catch((ex) => {
                dispatch({ type: FINANCIAL_RESULT_GET_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving KYC Document', m);
                });
            });
    };
}

export function updateFinancialResult(customerId, data) {
    return (dispatch) => {
        dispatch({ type: FINANCIAL_RESULT_UPDATE_REQUEST });
        const dt = {
            financial_result: humps.decamelizeKeys(data),
        };

        const frUpdatePromise = fetchUtils.putJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/financial_result`, dt);

        const fsData = {};
        fsData.type = dt.financial_result.type.toUpperCase();
        fsData.sub_type = dt.financial_result.sub_type.toUpperCase();
        fsData.document_id = dt.financial_result.result_document_id;
        fsData.year = dt.financial_result.financial_year;

        const fsCreatePromise = fetchUtils.postJSON(`${process.env.REACT_APP_CS_API_HOST}/financial-statement`, fsData);

        return Promise.all([frUpdatePromise, fsCreatePromise]) // Dont change the order
            .then(dArr => dArr[0]) // return first promise data
            .then(d => (d && d.financial_results && humps.camelizeKeys(d.financial_results)) || {})
            .then((d) => {
                dispatch({ type: FINANCIAL_RESULT_UPDATE_SUCCESS, data: d });
                toastr.success('Success!', 'File uploaded successfully!!');
            })
            .catch((err) => {
                dispatch({ type: FINANCIAL_RESULT_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, err).then((m) => {
                    toastr.error('Error updating KYC document in MP', m);
                });
            });
    };
}

export function getPortfolioCuts(customerId, yr) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/portfolio_cut?year=${yr}`)
            .then(d => (d && d.portfolio_cuts && humps.camelizeKeys(d.portfolio_cuts)) || {})
            .then(d => dispatch({ type: PORTFOLIO_CUT_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving portfolio cut', m);
                });
            });
}

export function updatePortfolioCut(customerId, data) {
    return (dispatch) => {
        dispatch({ type: PORTFOLIO_CUT_UPDATE_REQUEST });
        const dt = {
            portfolio_cut: humps.decamelizeKeys(data),
        };
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/portfolio_cut`, dt)
            .then(d => (d && d.portfolio_cuts && humps.camelizeKeys(d.portfolio_cuts)) || {})
            .then(d => dispatch({ type: PORTFOLIO_CUT_UPDATE_SUCCESS, data: d }))
            .catch((ex) => {
                dispatch({ type: PORTFOLIO_CUT_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating portfolio cut', m);
                });
            });
    };
}
