import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface BarChartProps {
    data: {
        name: string;
        uv: number;
        pv: number;
    }[];
    width?: number;
    height?: number;
    xName?: string;
    yName?: string;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    tickLine?: boolean;
    tick?: boolean;
    yAxisUnit?: string;
    axisLine?: boolean;
    barSize?: number;
    radius?: Array<number>;
    fill1?: string;
    fill2?: string;
    leftYAxisLabel?: string;
    offset?: number;
}

const CustomBarChart: React.FC<BarChartProps> = ({
    data = [],
    margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    width = 500,
    height = 300,
    xName = '',
    yName = '',
    tickLine = true,
    tick = true,
    yAxisUnit = '',
    axisLine = true,
    barSize = 50,
    radius = [0, 0, 0, 0],
    fill1 = '#36bbc4',
    fill2 = '#b837c4',
    leftYAxisLabel = '',
    offset = 0,
}) => {
    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart data={data} margin={margin} barGap={0}>
                <XAxis dataKey="name" axisLine={axisLine} tickLine={tickLine} tick={tick} />
                <YAxis
                    label={{ value: leftYAxisLabel, angle: -90, position: 'insideBottomLeft', offset: offset }}
                    tickLine={tickLine}
                    tick={tick}
                    unit={yAxisUnit}
                    axisLine={axisLine}
                />
                <Tooltip cursor={false} />
                <Legend />
                <Bar dataKey="pv" name={xName} fill={fill1} barSize={barSize} radius={radius} />
                <Bar dataKey="uv" name={yName} fill={fill2} barSize={barSize} radius={radius} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;
