import { combineReducers } from 'redux';
import da from 'app/actors/client/ducks/da/index';
import transaction from './transaction';
import task from '../../../reducers/task';
import ptc from './ptc';
import portfolio from './portfolio';
import lean from './lean';

const rootReducer = combineReducers({
    transaction,
    task,
    ptc,
    portfolio,
    da,
    lean,
});

export default rootReducer;
