import React, { useEffect, createContext, Fragment } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import withRouter from 'app/components/WithRouter';
import history from 'app/utils/myHistory';
import * as Sentry from '@sentry/react';

import _get from 'lodash/get';
import classNames from 'classnames';

import 'whatwg-fetch';

import 'driver.js/dist/driver.min.css';
import 'app/assets/css/App.css';
import 'app/assets/css/main.css';
import 'app/assets/css/survey.css';
import 'app/assets/css/drawdown-request.css';
import 'app/assets/css/dialog.css';
import 'app/assets/css/invoice.css';
import 'app/assets/css/nucleo-icons.css';
import 'app/assets/fonts/sofia-pro/stylesheet.css';

// Necessary to load Common Types, Dont Remove
import 'app/commonTypes';

import { CPProvider } from 'app/constants/CPContext';
import { initEnvironment, showAlert, hideAlert, urlBlockAlert } from 'app/reducers/environment';
import { getAssetClasses } from 'app/reducers/assetClasses';
import {
    initAuth,
    setPassword,
    changePassword,
    checkInternalUser,
    getActiveActorHomeUrl,
    logoutUser,
    getActiveUser,
    getActiveUserId,
} from 'app/actions/AuthedActions';

// Constants
import {
    LOGOUT,
    CHANGE_PASSWORD,
    SET_PASSWORD,
    SURVEY,
    SURVEY_SUCCESS,
    SERVICER_REPORT_OLD,
    SERVICER_REPORT,
    TRANSACTION_DETAIL_OLD,
    TRANSACTION_DETAIL,
    REPORTS_OLD,
    REPORTS,
    LOGOUT_ALL_DEVICES,
    INVESTOR_SIGNUP,
    FIREWALL,
    NOTIFICATIONS,
    MANAGE_NOTIFICATIONS,
    PUBLIC_ROUTES,
} from 'app/constants/Routes';

import { ADMIN_TRANS_DEFAULT, ADMIN_TRANS_OLD } from 'app/actors/admin/constants/Routes';
import { ENVIRONMENTS, IDLE_TIMEOUT_MSG } from 'app/constants/Constants';
import { BROWSER_LIST } from 'app/utils/browserDetection';
// Hocs
import CommonLayoutWoHeadFooter from 'app/components/CommonLayoutWoHeadFooter';
import CACommonLayout from 'app/components/CACommonLayout';
import YubiLaunch from 'app/components/YubiLaunch';
import AutoReload from 'app/components/AutoReload';
import NoConnection from 'app/components/NoConnection';
import Spinner from 'app/components/Spinner';
import Redirect from 'app/components/Redirect';
import Logout from 'app/components/Logout';
import LogoutFromAllDevices from 'app/components/LogoutFromAllDevices.tsx';
import NotFound from 'app/components/NotFound';
import Forbidden from 'app/components/Forbidden';

import ChangePassword from 'app/components/ChangePassword/index';
import SetPassword from 'app/components/SetPassword/index';

import Footer from 'app/components/Footer';
import HelpCookies from 'app/components/HelpCookies';
import ModalPopup from 'app/components/ModalPopup';
import InvestorSignup from 'app/components/InvestorSignup';
import Firewall from 'app/components/Firewall';
import MainTenance from 'app/components/Maintenance';

import * as RouterUtils from 'app/utils/RouterUtils';
import BrowserAlert, { checkUnsupportedBrowser } from 'app/components/BrowserAlert';
import Alerts from 'app/components/Extras/Alerts';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { routeChange } from 'app/reducers/amplitude';
import ManageEmails from 'app/products/Shared/ManageEmails';

import { useAuth } from '@yubi/yb-module-auth';
import { getAllUserEntities } from 'app/reducers/entities';
import IPWhitelisting from 'app/components/IPWhitelisting';
import { useStyles } from '@yubi/yb-style-provider';
import { useRouterHelper } from 'app/hooks/useRouterHelper';
import useAmplitude from 'app/components/Amplitude/useAmplitude';
import { CAEvents } from 'app/components/Amplitude/AmplitudeEvents';

const { lazyWithRetry } = RouterUtils;
const Admin = lazyWithRetry(() => import('app/actors/admin/containers/Admin'));
const Client = lazyWithRetry(() => import('app/actors/client/containers/Client'));
const Internals = lazyWithRetry(() => import('app/actors/internal/components/Internals'));
const Investor = lazyWithRetry(() => import('app/actors/investor/components/Investor'));
const Auditor = lazyWithRetry(() => import('app/actors/cps/components/Auditor'));
const Trustee = lazyWithRetry(() => import('app/actors/cps/components/Trustee'));
const RatingAgency = lazyWithRetry(() => import('app/actors/cps/components/RatingAgency'));
const LawFirm = lazyWithRetry(() => import('app/actors/cps/components/LawFirm'));
const Guarantor = lazyWithRetry(() => import('app/actors/cps/components/Guarantor'));
const CollateralManager = lazyWithRetry(() => import('app/actors/cps/components/CollateralManager'));
const Notifications = lazyWithRetry(() => import('app/components/Notifications/NotificationsCenter'));

const unsupportedBrowsers = [BROWSER_LIST.IE];

export const ExpressInterestModalContext = createContext();
/* eslint-disable consistent-return */
const App = ({ ...props }) => {
    const [state, setState] = React.useState({
        idlePopup: false,
        isModalVisible: false,
    });
    const { styleConnector } = useStyles();

    const { logout } = useAuth();
    const { location } = props;
    const { pathname = '' } = location;
    const { params } = useRouterHelper();
    const { logEvent } = useAmplitude();
    const Events = CAEvents.WHITELISTED_ALERT;

    useEffect(() => {
        const blocked = localStorage?.getItem('credavenueBlocked');
        const { initEnvironmentDispatch, initAuthDispatch, routeChangeDispatch, getAssetClassList, getAllRoles, urlBlockALertDispatch } = props;
        initEnvironmentDispatch();
        if (!PUBLIC_ROUTES.includes(pathname)) {
            initAuthDispatch();
        }
        if (process.env.REACT_APP_ENV !== 'development') {
            getAssetClassList();
            if (!PUBLIC_ROUTES.includes(pathname)) {
                getAllRoles();
            }
        }
        history.listen((loc) => {
            routeChangeDispatch(loc.pathname);
        });
        if (blocked) {
            urlBlockALertDispatch(blocked);
        }
    }, []);

    useEffect(() => {
        styleConnector.fetch('base');
    }, []);

    useEffect(() => {
        const isAccountUrlWhitelisted = (url) => {
            fetch(url, { mode: 'no-cors' })
                .catch((e) => {
                    logEvent(Events.ACCOUNT_URL_BLOCKED, {
                        date: new Date(),
                        url: process.env.REACT_APP_ACCOUNT_HOST
                    });
                });
        };
        isAccountUrlWhitelisted(process.env.REACT_APP_ACCOUNT_HOST);
    }, [])

    const handleChangePassword = (values) => {
        const userId = getActiveUserId();
        const { changePasswordDispatch } = props;
        return changePasswordDispatch(userId, values, () => logout({ returnTo: window.origin }));
    };

    const handleSetPassword = (args, values) => {
        const { setPasswordDispatch, authed } = props;
        const mergedValues = { ...values, email: authed.userEmail };
        if (_get(args, 'entityId') && _get(args, 'token') && _get(args, 'userId')) {
            return setPasswordDispatch(args, mergedValues);
        }
    };

    const handleOnline = () => {
        const { hideAlertDispatch } = props;
        hideAlertDispatch();
    };

    const handleOffline = () => {
        const { showAlertDispatch } = props;
        showAlertDispatch('No Internet Connection', 'Your computer is currently offline. Please check your network settings.');
    };

    const doLogout = () => {
        setState({ ...state, idlePopup: false });
        const { logoutDispatch } = props;
        logoutDispatch();
    };

    const toggleExpressInterestModal = () => {
        setState({ ...state, isModalVisible: !state.isModalVisible });
    };

    const { isAuthChecked, authed, alertUser, urlBlockALertDispatch, permissions } = props;
    const { idlePopup, isModalVisible } = state;
    if (!isAuthChecked && !PUBLIC_ROUTES.includes(pathname)) {
        return <Spinner />;
    }
    if (process.env.REACT_APP_MAINTENANCE === 'true') {
        return <MainTenance />;
    }

    if (alertUser) {
        return (
            <Routes>
                <Route path={FIREWALL} element={<Firewall />} />
                <Redirect to={FIREWALL} />
            </Routes>
        );
    }
    const homeUrl = getActiveActorHomeUrl(permissions);
    const redirectUrl = homeUrl && homeUrl !== '/' ? homeUrl : '/forbidden';
    return (
        <ExpressInterestModalContext.Provider
            value={{
                isModalVisible,
                toggleExpressInterestModal,
            }}
        >
            <Fragment>
                <div className={classNames('stick-hei', { 'ie-page-wrap': checkUnsupportedBrowser(unsupportedBrowsers) })}>
                    {alertUser && (
                        <Alerts
                            alertType="info"
                            position="fixed"
                            className="align-center m-b-0"
                            content="CredAvenue is moving to new URL! Please contact your IT support for whitelisting *.go-yubi.com in your firewall settings to continue accessing our platform"
                            onClose={() => urlBlockALertDispatch(false)}
                        />
                    )}
                    <AutoReload url="/index.html" />
                    <NoConnection onOnline={handleOnline} onOffline={handleOffline} />
                    <BrowserAlert unsupportedBrowsers={unsupportedBrowsers} />
                    {idlePopup && (
                        <ModalPopup open title="Session Timeout" modalClass="relogin">
                            <p>{IDLE_TIMEOUT_MSG}</p>
                            <ul className="list-inline">
                                <li>
                                    <button type="button" onClick={doLogout}>
                                        Login
                                    </button>
                                </li>
                            </ul>
                        </ModalPopup>
                    )}
                    <ErrorBoundary from={history.location.pathname}>
                        <Routes>
                            <Route path={INVESTOR_SIGNUP} element={<CommonLayoutWoHeadFooter component={InvestorSignup} {...props} />} />
                            <Route
                                path={CHANGE_PASSWORD}
                                element={<CACommonLayout component={ChangePassword} {...props} onSubmit={handleChangePassword} />}
                            />
                            <Route path={SET_PASSWORD} element={<CACommonLayout component={SetPassword} {...props} onSubmit={handleSetPassword} />} />
                            <Route path="/forbidden" element={<Forbidden {...props} />} />

                            <Route path="/ip-address-blocked" element={<IPWhitelisting {...props} />} />
                            <Route
                                path={NOTIFICATIONS}
                                element={
                                    <React.Suspense fallback={<Spinner />}>
                                        <CACommonLayout component={Notifications} {...props} showAuthenticatedHeader containerClass="nc-container" />
                                    </React.Suspense>
                                }
                            />
                            <Route path={'/logout'} element={<Logout />} />

                            <Route path={REPORTS_OLD} element={<Redirect to={REPORTS} />} />

                            <Route path="/:cp/entities" element={<Redirect to="/entities" />} />
                            <Route path={MANAGE_NOTIFICATIONS} element={<CommonLayoutWoHeadFooter component={ManageEmails} {...props} />} />
                            {_get(getActiveUser(), 'isInvestor') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <Investor {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isClient') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <Client {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isProduct') && (
                                <Route
                                    path="*"
                                    element={
                                        <CPProvider value="admin">
                                            <React.Suspense fallback={<Spinner />}>
                                                <Admin {...props} />
                                            </React.Suspense>
                                        </CPProvider>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isAuditor') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <Auditor {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isTrustee') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <Trustee {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isGuarantor') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <Guarantor {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isLawfirm') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <LawFirm {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isRatingagency') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <RatingAgency {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {_get(getActiveUser(), 'isCollateralManger') && (
                                <Route
                                    path="*"
                                    element={
                                        <React.Suspense fallback={<Spinner />}>
                                            <CollateralManager {...props} />
                                        </React.Suspense>
                                    }
                                />
                            )}
                            {checkInternalUser() && (
                                <Route
                                    path="*"
                                    element={
                                        <CPProvider value={params?.actor}>
                                            <React.Suspense fallback={<Spinner />}>
                                                <Internals {...props} />
                                            </React.Suspense>
                                        </CPProvider>
                                    }
                                />
                            )}
                            <Route path="/*" element={<Redirect to={redirectUrl} replace />} />
                            <Route path="/auth/help-cookies" element={<HelpCookies {...props} />} />
                            <Route element={<NotFound {...props} />} />
                        </Routes>
                        {process.env.REACT_APP_ENV !== ENVIRONMENTS.STAGING && process.env.REACT_APP_ENV !== ENVIRONMENTS.PRODUCTION && (
                            <YubiLaunch />
                        )}
                    </ErrorBoundary>
                    {!pathname.includes('loan-details') && <div className="push" />}
                </div>
                {!pathname.includes('collection-file/setup') && <Footer />}
            </Fragment>
        </ExpressInterestModalContext.Provider>
    );
};

const mapStateToProps = (state) => {
    const { authed, environment } = state;
    const { isAuthChecked } = authed;
    const { isMobile, alertUser } = environment;

    return {
        isMobile,
        isAuthChecked,
        authed,
        alertUser,
        permissions: _get(authed, 'user.permissions', {}),
    };
};

const mapDispatchToProps = (dispatch) => ({
    initEnvironmentDispatch: () => dispatch(initEnvironment()),
    initAuthDispatch: () => dispatch(initAuth()),
    logoutDispatch: () => dispatch(logoutUser()),
    setPasswordDispatch: (a, d) => dispatch(setPassword(a, d)),
    changePasswordDispatch: (i, v) => dispatch(changePassword(i, v)),
    showAlertDispatch: (t, d) => dispatch(showAlert(t, d)),
    hideAlertDispatch: () => dispatch(hideAlert()),
    routeChangeDispatch: (p) => dispatch(routeChange(p)),
    getAssetClassList: () => dispatch(getAssetClasses()),
    urlBlockALertDispatch: (i) => dispatch(urlBlockAlert(i)),
    getAllRoles: () => dispatch(getAllUserEntities()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App)));
