import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import { Align } from 'app/products/UIComponents/Types/CommonTypes';

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    heading?: React.ReactNode;
    subHeading?: React.ReactNode;
    action?: React.ReactNode;
    className?: string;
    children?: any;
    align?: Align;
}

const StyledCardHeader = styled.div<CardHeaderProps>`
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:flex;
            justify-content:center;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'space-between' &&
        `
            display:flex;
            justify-content:space-between;
            align-items:center;
    `}
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    .left-side {
        position: relative;
    }
`;

const CardHeader: React.FC<CardHeaderProps> = ({
    align = 'space-between',
    children,
    heading,
    subHeading = null,
    action = null,
    className = '',
    ...rest
}): JSX.Element => {
    return (
        <StyledCardHeader align={align} {...rest}>
            <div className="left-side">
                {heading && <Typography typeStyle="bodysemibold">{heading}</Typography>}
                {subHeading && (
                    <Typography typeStyle="smallregular" className="sub-heading">
                        {subHeading}
                    </Typography>
                )}
            </div>
            <div className="right-side">{action && <div>{action}</div>}</div>
            {children}
        </StyledCardHeader>
    );
};

export default CardHeader;
