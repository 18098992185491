/* eslint-disable */
export default function loadAmplitude() {
    function debug(type, i) {
        type.prototype[i] = function() {
            return this._q.push([i].concat(Array.prototype.slice.call(arguments, 0))), this;
        };
    }

    function resolve(branch) {
        function loadImplementation(call) {
            branch[call] = function() {
                branch._q.push([call].concat(Array.prototype.slice.call(arguments, 0)));
            };
        }
        for (let i = 0; i < libs.length; i++) {
            loadImplementation(libs[i]);
        }
    }
    const me = window.amplitude || {
        _q: [],
        _iq: {},
    };
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.src = `https://amplitude-cdn.credavenue.com/libs/amplitude-4.1.0-min.gz.js`;
    el.rel = 'preconnect';

    el.onload = function() {
        if (window.amplitude.runQueuedFunctions) {
            window.amplitude.runQueuedFunctions();
        } else {
            console.log('[Amplitude] Error: could not load SDK');
        }
    };
    const wafCss = document.getElementsByTagName('script')[0];
    wafCss.parentNode.insertBefore(el, wafCss);

    var end = function() {
        return (this._q = []), this;
    };
    var methods = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset'];
    for (let i = 0; i < methods.length; i++) {
        debug(end, methods[i]);
    }
    me.Identify = end;

    var destroy = function() {
        return (this._q = []), this;
    };
    var args = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties'];
    for (let index = 0; index < args.length; index++) {
        debug(destroy, args[index]);
    }
    me.Revenue = destroy;
    const libs = [
        'init',
        'logEvent',
        'logRevenue',
        'setUserId',
        'setUserProperties',
        'setOptOut',
        'setVersionName',
        'setDomain',
        'setDeviceId',
        'setGlobalUserProperties',
        'identify',
        'clearUserProperties',
        'setGroup',
        'logRevenueV2',
        'regenerateDeviceId',
        'logEventWithTimestamp',
        'logEventWithGroups',
        'setSessionId',
    ];
    resolve(me);

    me.getInstance = function(type) {
        return (
            (type = (type && type.length !== 0 ? type : '$default_instance').toLowerCase()),
            me._iq.hasOwnProperty(type) ||
                ((me._iq[type] = {
                    _q: [],
                }),
                resolve(me._iq[type])),
            me._iq[type]
        );
    };
    window.amplitude = me;
    configureAmplitude();
}
function configureAmplitude() {
    if (typeof window !== 'undefined') {
        if (typeof window.amplitude === 'object') {
            window.amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, undefined, {
                apiEndpoint: `${process.env.REACT_APP_AMPLITUDE_ENDPOINT}`,
                includeUtm: true,
            });
        }
    }
}
