import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface StackedBarChartProps {
    data: {}[];
    width?: number;
    height?: number;
    xName?: string;
    yName?: string;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    axisLine?: boolean;
    tickLine?: boolean;
    barSize?: number;
    radius?: Array<number>;
    fill1?: string;
    fill2?: string;
    fill3?: string
    leftYAxisLabel?: string;
    offset?: number;
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data, axisLine=true,
    barSize=50,
    radius=[0,0,0,0],
    fill1="#36bbc4",
    fill2="#4099ff",
    fill3="#b837c4",
    leftYAxisLabel= '',
    height=300,tickLine}) => (
    <ResponsiveContainer width="100%" height={height}>
        <BarChart
            data={data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <XAxis dataKey="payOutDate" axisLine={axisLine} tickLine={tickLine}/>
            <YAxis label={{value:leftYAxisLabel, angle:-90, position: 'insideBottomLeft'}}unit="%" axisLine={axisLine} tickLine={tickLine}/>
            <Tooltip />
            <Legend />
            <Bar dataKey="cashCollatPer" name="CC %" stackId="a" fill={fill1} barSize={barSize} radius={radius}/>
            <Bar dataKey="eisPer" name="EIS %" stackId="a" fill={fill2} barSize={barSize} radius={radius}/>
            <Bar dataKey="overCollatPer" name="OC %" stackId="a" fill={fill3} barSize={barSize} radius={radius}/>
        </BarChart>
    </ResponsiveContainer>
);

export default StackedBarChart;
