import { getActiveUser } from 'app/actions/AuthedActions';
import { PRODUCT_CATEGORY } from 'app/constants/Constants';
export const TRANSACTION_LIST_FIELDS: any = {
    ptc: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'trancheRating',
        amount: 'humanizedAmount',
        pricing: 'trancheCoupon',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'settlementDate',
        recommendedScore: 'recommendedScore',
        bidsCount: 'bidsCount',
        isPslDeal: 'isPslDeal',
        creditEnhancement: 'creditEnhancement',
        collectionEfficiencyMetrics: 'collectionEfficiencyMetrics',
        investorInterestsCount: 'investorInterestsCount',
        trendingScore: 'trendingScore',
    },
    da: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedAmount',
        pricing: 'pricing',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'settlementDate',
        recommendedScore: 'recommendedScore',
        bidsCount: 'bidsCount',
        creditEnhancement: 'creditEnhancement',
        isPslDeal: 'isPslDeal',
        collectionEfficiencyMetrics: 'collectionEfficiencyMetrics',
        investorInterestsCount: 'investorInterestsCount',
    },
    dav2: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClasses',
        rating: 'rating',
        amount: 'humanizedAmount',
        pricing: 'pricing',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'settlementDate',
        recommendedScore: 'recommendedScore',
        bidsCount: 'bidsCount',
        creditEnhancement: 'creditEnhancement',
        isPslDeal: 'isPslDeal',
        collectionEfficiencyMetrics: 'collectionEfficiencyMetrics',
        investorInterestsCount: 'investorInterestsCount',
        isNotLean: 'hasProcessedMcds',
        trendingScore: 'trendingScore',
        stcCompliant: 'stcCompliant',
        isSelfCreated: 'isSelfCreated',
    },
    tl: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    wcdl: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
    },
    cc: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    od: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    bg: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    stl: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    lc: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    ra: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    discounting: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    factoring: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    pcfc: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    epc: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedFacilityLimit',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'disbursementDate',
        isHlfTransaction: 'isHlfTransaction',
    },
    cp: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedIssuanceSize',
        pricing: 'discount',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'issueDate',
    },
    ps: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedIssuanceSize',
        pricing: 'dividendRate',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'issueDate',
    },
    ncd: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedIssuanceSize',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'issueDate',
    },
    ncdv2: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'humanizedIssuanceSize',
        pricing: 'interest',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'issueDate',
    },

    ptcv1: {
        transId: 'id',
        status: 'transactionState',
        clientName: 'clientName',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClasses',
        rating: 'rating',
        amount: 'humanizedAmount',
        pricing: 'coupon',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'settlementDate',
        recommendedScore: 'recommendedScore',
        bidsCount: 'bidsCount',
        creditEnhancement: 'creditEnhancement',
        isPslDeal: 'isPslDeal',
        collectionEfficiencyMetrics: 'collectionEfficiencyMetrics',
        investorInterestsCount: 'investorInterestsCount',
        isNotLean: 'hasProcessedMcds',
        trendingScore: 'trendingScore',
        stcCompliant: 'stcCompliant',
        isSelfCreated: 'isSelfCreated',
    },
    pli: {
        transId: 'id',
        status: 'transactionState',
        dealName: 'dealName',
        product: 'transactionType',
        assetClass: 'assetClass',
        rating: 'rating',
        amount: 'aggregatedFacilityLimit',
        pricing: 'pricing',
        tenor: 'tenor',
        createdOn: 'createdAt',
        executionDate: 'settlementDate',
        customers: 'customerDetails',
    },
};

export const SORTING_DATES = [
    { label: 'Created On', value: 'created_at' },
    { label: 'Execution date', value: 'issue_date' },
];
export const SORTING_DATES_TRENDING = [
    { label: 'Created On', value: 'created_at' },
    { label: 'Execution date', value: 'issue_date' },
    { label: 'Trending Score', value: 'trending_score' },
];

export const TRANSACTION_LIST_HEADERS: {
    label: string;
    value: string;
}[] = [
    {
        label: 'Name',
        value: 'name',
    },
    {
        label: 'Product',
        value: 'product',
    },
    {
        label: 'Asset Class',
        value: 'assetClass',
    },
    {
        label: 'Rating',
        value: 'rating',
    },
    {
        label: 'Amount',
        value: 'amount',
    },
    {
        label: 'Pricing',
        value: 'pricing',
    },
    {
        label: 'Tenor',
        value: 'tenor',
    },
    {
        label: 'Execution Date',
        value: 'executionDate',
    },
];

export const TABS = {
    NEW: 'new',
    LIVE: 'live',
    RECOMMENDED: 'recommended',
    MISSED: 'missed',
    SETTLED: 'settled',
    MATURED: 'matured',
    CLIENT_PORTFOLIO: 'client_portfolio',
    RECENTLY_ADDED: 'recently_added',
    HIGH_VOLUME: 'high_volume',
    TRENDING: 'trending',
    STC: 'stc',
    FAILED: 'failed',
    ACTIONS: 'actions',
};

export const TAB_OPTIONS: {
    name: string;
    value: string;
    icon: string;
}[] = [
    { name: 'Live Deals', value: TABS.LIVE, icon: 'icon-feed' },
    { name: 'Settled Deals', value: TABS.SETTLED, icon: 'icon-deal' },
    { name: 'Matured Deals', value: TABS.MATURED, icon: 'icon-matured' },
];

export const INTERNAL_AUDITOR_TAB_OPTIONS: {
    name: string;
    value: string;
    icon: string;
}[] = [
    { name: 'Settled Deals', value: TABS.SETTLED, icon: 'icon-deal' },
    { name: 'Matured Deals', value: TABS.MATURED, icon: 'icon-matured' },
];

export const PRODUCT_VALUES = {
    all: '',
    ptc: 'Securitization',
    da: 'DirectAssignment::Transaction',
    dav2: 'Assignment::Transaction',
};

export const PRODUCT_TYPE_TABS: {
    label: string;
    value: string;
    id: string;
    description: string;
    type?: string;
}[] = [
    { value: PRODUCT_VALUES.all, label: 'ALL', id: 'all', description: 'All Products', type: 'all' },
    { value: PRODUCT_VALUES.ptc, label: 'PTC', id: 'ptc', description: 'Pass Through Certificate', type: PRODUCT_CATEGORY.pools },
    { value: PRODUCT_VALUES.da, label: 'DA', id: 'da', description: 'Direct Assignment', type: PRODUCT_CATEGORY.pools },
];

export const PRODUCT_TYPE_TABS_FSClient: {
    label: string;
    value: string;
    id: string;
    description: string;
    type?: string;
}[] = [
    { value: PRODUCT_VALUES.all, label: 'ALL', id: 'all', description: 'All Products', type: 'all' },
    { value: PRODUCT_VALUES.ptc, label: 'PTC', id: 'ptc', description: 'Pass Through Certificate', type: PRODUCT_CATEGORY.pools },
    { value: PRODUCT_VALUES.da, label: 'DA', id: 'da', description: 'Direct Assignment', type: PRODUCT_CATEGORY.pools },
];

export const TRANSACTION_LIST_PAGE_SIZE = 10;

// const { isInvestorChecker, isInvestorMaker } = getActiveUser();

export const INVESTOR_POOLS_TABS = [
    {
        title: 'EXPLORE POOLS',
        items: [
            {
                name: 'All Pools',
                icon: 'iconnew-summary',
                value: TABS.NEW,
            },
            {
                name: 'Recommended',
                icon: 'icon-star',
                value: TABS.RECOMMENDED,
            },
            {
                name: 'Portfolio Client',
                icon: 'iconnew-summary',
                value: TABS.CLIENT_PORTFOLIO,
            },
            {
                name: 'Recently Added',
                icon: 'icon-shopping-label',
                value: TABS.RECENTLY_ADDED,
            },
            {
                name: 'High Volume',
                icon: 'iconnew-summary',
                value: TABS.HIGH_VOLUME,
            },
            {
                name: 'Trending',
                icon: 'iconnew-summary',
                value: TABS.TRENDING,
            },
            {
                name: 'STC Eligible Pools',
                icon: 'icon-shopping-label',
                value: TABS.STC,
            },
        ],
    },
    {
        title: 'MY DEALS',
        items: [
            {
                name: 'Live Deals',
                icon: 'icon-feed',
                value: TABS.LIVE,
            },
            {
                name: 'Matured Deals',
                icon: 'icon-matured',
                value: TABS.MATURED,
            },
            { name: 'My Portfolio', value: TABS.SETTLED, icon: 'icon-deal' },
        ],
    },
    ...[
        {
            title: 'Others',
            items: [
                {
                    name: 'Action items',
                    icon: 'icon-flash',
                    value: TABS.ACTIONS,
                },
            ],
        },
    ],
];

export const INVESTOR_SIDEMENUS = {
    [PRODUCT_CATEGORY.pools]: INVESTOR_POOLS_TABS,
};
