import React, { isValidElement, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import parse from 'html-react-parser';
import settledIcon from 'app/assets/images/cra-assets.svg';
import notifyLoudIcon from 'app/assets/images/moratorium-icons.svg';
import { YesBtn, NoBtn, BtnDefault } from './Buttons';

export const NotifyBarWrap = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 30px 0 10px;
    background: #fff;
    box-shadow: 0 0 8px #ccc;
    justify-content: space-between;
    margin: 0px 20px 40px;
    position: relative;
    top: 30px;
    &.notify-wrap {
        top: 0px;
        &.danger {
            border-color: #c80029;
        }
        .notify-settlement {
            .info-icon {
                display: none;
            }
        }
    }
    &.blue-theme {
        border-left: none;
        border: 1px solid #2a72df;
        background: #f4f8fd;
        padding: 0px !important;
        min-height: 50px;
        margin: 15px 0px 25px;
        .notify-settlement {
            .info-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 50px;
                max-width: 50px;
                min-height: 52px;
                max-height: 52px;
                background: #2a72df;
                > i {
                    color: #fff;
                    font-size: 16px;
                }
            }
            .settlement-details {
                padding: 10px;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }
    .notify-settlement {
        display: flex;
        align-items: center;
        .settlement-icon {
            position: relative;
            min-width: 50px;
            max-width: 50px;
            height: 60px;
            overflow: hidden;
            margin-right: 13px;
            .settlement-icon-image {
                width: 100px;
                height: 72px;
                background-image: url(${settledIcon});
                background-position: 3px -37px;
                position: absolute;
                right: 5px;
                background-size: 640px;
                top: -19px;
                transition: all 0.5s ease;
            }
            .notify-loud-image {
                width: 115px;
                height: 88px;
                background-image: url(${notifyLoudIcon});
                background-position: 57px -225px;
                position: absolute;
                right: -9px;
                background-size: 110%;
                top: 0px;
                transition: all 0.5s ease;
            }
        }
        .flag-icon {
            display: none;
            position: relative;
            min-width: 50px;
            max-width: 50px;
            height: 30px;
            overflow: hidden;
            margin-right: 13px;
            background: #f5b23d;
            i {
                font-size: 22px;
                color: #fff;
            }
        }
        .info-icon {
            display: none;
        }
        .settlement-details {
            span {
                font-weight: 600;
                display: inline-block;
                margin: 0 3px;
                color: #000;
            }
        }
    }
    .confirmatory-actions {
        display: flex;
        align-items: center;
        .no-btn {
            margin-right: 20px;
        }
        .revoke-btn {
            display: none;
        }
        &.after-confirm-actions {
            .no-btn,
            .yes-btn {
                display: none;
            }
            .revoke-btn {
                display: flex;
            }
            + .close-notify {
                display: block;
            }
        }
        .close-notify-simple {
            display: none;
            background: none;
            border: none;
            i {
                &.icon-close {
                    font-weight: 800;
                    font-size: 12px;
                    vertical-align: middle;
                    display: inline-block;
                    color: #bfbfbe;
                }
            }
        }
        &.text-style {
            .no-btn,
            .yes-btn {
                background: none;
                border: none;
                color: #919191;
                min-height: inherit;
                min-width: inherit;
                text-transform: capitalize;
                font-weight: normal;
                &:hover {
                    transform: translateY(-1px);
                }
                i {
                    display: none;
                }
            }
            .no-btn {
                margin-right: 5px;
            }
            .yes-btn {
                color: #0070d2;
                text-decoration: underline;
            }
        }
    }
    > .close-notify {
        display: none;
        background: none;
        border: none;
        opacity: 1;
        position: absolute;
        right: -6px;
        padding: 0;
        top: -6px;
        i {
            &.icon-error {
                color: #d9100a;
                font-weight: 800;
                font-size: 14px;
            }
        }
    }

    ${(props) =>
        props.theme === 'blue'
            ? css`
                  border: 1px solid #2a72df;
                  background: #f4f8fd;
                  box-shadow: none;
                  margin: 5px 0px 15px;
                  min-height: 48px;
                  font-weight: 500;
                  padding: 0px;
                  .flag-icon {
                      display: none;
                  }
                  .info-icon {
                      display: flex !important;
                      position: relative;
                      min-width: 50px;
                      max-width: 50px;
                      height: 48px;
                      overflow: hidden;
                      margin-right: 13px;
                      background: #2a72df;
                      justify-content: center;
                      align-items: center;
                      i {
                          font-size: 17px;
                          color: #fff;
                      }
                  }
                  .notify-settlement {
                      .settlement-details {
                          font-size: 13px;
                          color: #000;
                          line-height: 18px;
                      }
                  }
              `
            : css`
                  border-left: none;
                  border: 1px solid #f5b23d;
                  background: #fff;
                  padding: 0 20px;
                  min-height: 69px;
                  border-left: 6px solid #ffbf5e;
                  .notify-settlement {
                      padding-left: 0px;
                      .settlement-details {
                          color: #333;
                      }
                      .flag-icon {
                          display: none;
                          align-items: center;
                          justify-content: center;
                          position: absolute;
                          left: 0px;
                          top: 0px;
                          height: 100%;
                      }
                  }
                  .confirmatory-actions {
                      .close-notify-simple {
                          display: flex;
                          min-width: inherit;
                          padding: 0;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          margin-right: 5px;
                          min-height: 24px;
                          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                              position: relative;
                              width: 15px;
                          }
                          i {
                              &.icon-close {
                                  margin-right: 0px;
                                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                      position: absolute;
                                      transform: translate(-50%, -50%);
                                      left: 50%;
                                      top: 50%;
                                  }
                              }
                          }
                      }
                  }
              `}
`;

interface NotifyBarProps {
    message: string;
    iconClassName?: string;
    type?: string;
    yes?: () => void;
    no?: () => void;
    defaultAction?: () => void;
    disableAction?: boolean;
    disableIcon?: boolean;
    loading?: boolean;
    labelPositive?: string;
    labelNegative?: string;
    defaultLabel?: string;
    successIcon?: string;
    yesContent?: any;
    containerClass?: string;
    theme?: string;
    buttonStyle?: string;
}

const NotifyBar = ({
    message,
    iconClassName = 'settlement-icon-image',
    type = 'info',
    yes,
    no,
    disableAction = false,
    disableIcon = false,
    loading,
    labelPositive = 'Yes',
    labelNegative = 'No',
    defaultLabel,
    defaultAction,
    successIcon = 'icon-success',
    yesContent,
    containerClass = 'notify-wrap',
    theme = 'default',
    buttonStyle = 'buttons',
}: NotifyBarProps): JSX.Element => {
    let renderText;
    if (isValidElement(message)) {
        renderText = cloneElement(message);
    } else {
        renderText = parse(message);
    }
    return (
        <NotifyBarWrap
            className={classnames(containerClass, {
                [type]: true,
            })}
            theme={theme}
        >
            <div className="notify-settlement">
                {!disableIcon && (
                    <div className="settlement-icon">
                        <span className={iconClassName} />
                    </div>
                )}
                <div className="flag-icon">
                    <i className="icon nucleoinvest-flag-points-32" />
                </div>
                <div className="info-icon">
                    <i className="icon nucleoinvest-c-info" />
                </div>
                <div className="settlement-details">{renderText}</div>
            </div>
            {!disableAction && (
                <div
                    className={classnames('confirmatory-actions', {
                        'text-style': buttonStyle === 'text',
                        'button-style': buttonStyle === 'buttons',
                    })}
                >
                    {no && (
                        <NoBtn className="no-btn" disabled={loading} onClick={no}>
                            <i className="icon-error" /> {labelNegative}
                        </NoBtn>
                    )}
                    {yes && (
                        <>
                            {yesContent ? (
                                <div role="button" tabIndex={0} onClick={yes} onKeyDown={(e) => e.keyCode === 13 && yes}>
                                    {yesContent}
                                </div>
                            ) : (
                                <YesBtn className="yes-btn" disabled={loading} onClick={yes}>
                                    <i className={successIcon} /> {labelPositive}
                                </YesBtn>
                            )}
                        </>
                    )}
                </div>
            )}
            {defaultLabel && (
                <BtnDefault disabled={loading} onClick={defaultAction}>
                    {defaultLabel}
                </BtnDefault>
            )}

            <button className="close-notify">
                <i className="icon-error" />
            </button>
        </NotifyBarWrap>
    );
};

export default NotifyBar;
