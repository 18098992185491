/* eslint arrow-body-style: ["error", "always"] */
import React from 'react';

const Spinner = ({ classname = 'sprocess', radius = '20' }) => {
    return (
        <div className={classname} style={{ margin: '10px auto auto' }}>
            <svg className="circular" viewBox="25 25 50 50">
                <circle className="path" cx="50" cy="50" r={radius} fill="none" />
            </svg>
        </div>
    );
};

export default Spinner;
