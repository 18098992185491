import styled from 'styled-components';
import AttachIconOuterPanel from 'app/assets/images/screen-enlarge.png';
import imageFile from 'app/assets/images/cra-assets-01.svg';

export const Container = styled.div`
    min-height: calc(100vh - 200px);
    margin-bottom: -20px;
`;

export const CustomAdjacentWrap = styled.div`
    margin: 10px;
    .text-right {
        &.actions {
            margin: 0 -20px;
        }
    }
    .view-access {
        > p {
            color: #333;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
            padding-bottom: 5px;
        }
        .counterparties-selection {
            display: flex;
            > button {
                margin-right: 10px;
                &:first-child {
                    /* margin-right: 12px; */
                }
                &:hover {
                    border-color: #0070d2;
                    color: #0070d2;
                }
            }
        }
        .counterparties-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            > li {
                width: auto;
                margin-bottom: 5px;
                margin-right: 25px;
            }
        }
    }
    .submit-btn {
        padding: 5px 20px;
        text-transform: uppercase;
        font-size: 11px;
    }
`;

export const AttachFileButton = styled.button`
    position: relative;
    width: 28px;
    background-color: transparent;
    border: none;
    background-image: url(${AttachIconOuterPanel});
    height: 28px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    i {
        &.icon-add {
            font-size: 21px;
            position: absolute;
            top: 3px;
            left: 4px;
        }
    }
`;

export const DataPlaceHoldInitiateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 0;
    .attach-doc-img {
        min-width: 92px;
        max-width: 92px;
        position: absolute;
        top: 54px;
        z-index: 1;
        > input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 9;
        }
    }
    .add-attachement-btn {
        position: absolute;
        bottom: 50px;
        font-size: 12px;
        min-width: 130px;
        > input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 9;
        }
    }
`;

export const CircleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f7fb;
    width: 260px;
    height: 260px;
    background-position: 0px 10px;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100%;
    border-radius: 50%;
`;

export const PrimaryBtn = styled.button`
    background: #0070d3;
    border: none;
    color: #fff;
    border-radius: 3px;
    padding: 7px 20px;
`;

export const FileListContainer = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 20px 20px 0;
    .attachement-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        width: 100%;
        > li {
            &.attachement-item {
                .icon-type {
                    i {
                        display: inline-block;
                        font-size: 32px;
                        vertical-align: middle;
                        &.icon-xlsx-file-format {
                            color: #207245;
                        }
                        &.icon-docx-file-format {
                            color: #295598;
                        }
                        &.icon-pdf-file-format-symbol {
                            color: #e2574c;
                        }
                        &.icon-image-icon {
                            color: #d16461;
                            font-size: 36px;
                        }
                    }
                }
            }
        }
    }
`;

export const UploadFileBox = styled.form`
    display: flex;
    align-items: center;
    background: #f7f7f7;
    position: relative;
    min-height: 55px;
    box-shadow: 0px 0px 3px 2px #f1f1f1;
    transition: all 0.5s ease;
    > p {
        padding: 0 8px;
        color: #333;
    }
    input {
        position: absolute;
        opacity: 0;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
`;
export const UploadFileIcon = styled.div`
    min-height: 55px;
    display: flex;
    align-items: center;
    min-width: 55px;
    justify-content: center;
`;

export const ImageFileContain = styled.figure`
    img {
        width: 50px;
        height: 50px;
        background-image: url(${imageFile});
        background-position: 13px -181px;
        position: absolute;
        right: 5px;
        background-size: 80px;
        background-repeat: no-repeat;
        top: 0px;
        transition: all 0.5s ease;
    }
`;

export const CheckBoxContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    .styled-checkbox {
        position: absolute;
        opacity: 0;
        z-index: 9;
        height: 100%;
        width: 100%;
        &:disabled {
            + label {
                opacity: 0.5;
            }
        }
        + label {
            position: relative;
            cursor: pointer;
            color: #333;
            font-weight: 600;
            font-size: 11px;
            display: flex;
            align-items: center;
        }
        &:checked {
            + label {
                &:before {
                    background: #0070d2;
                    border-color: #0070d2;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 4px;
                    top: 9px;
                    background: #fff;
                    width: 2px;
                    height: 2px;
                    box-shadow: 3px 0px 0 #fff, 2px 0px 0 #fff, 3px -2px 0 #fff, 3px -4px 0 #fff, 3px -6px 0 #fff, 3px -8px 0 #fff;
                    transform: rotate(45deg) rotateX(0);
                }
            }
        }
        + label {
            &:before {
                content: '';
                margin-right: 8px;
                display: inline-block;
                vertical-align: text-top;
                width: 17px;
                height: 17px;
                background: transparent;
                border: 1px solid #ccc;
                margin-bottom: 2px;
            }
        }
    }
`;

export const BorderOnlyBtn = styled.button`
    background: none;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    padding: 6px 15px;
    color: #333;
    &.active {
        border-color: #0070d2;
        color: #0070d2;
    }
`;

export const FileBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    min-height: 55px;
    box-shadow: 0px 0px 3px 2px #f1f1f1;
    transition: all 0.5s ease;
    &:hover {
        &:before {
            position: absolute;
            content: '';
            width: 99%;
            height: 96%;
            top: 1px;
            left: 1px;
            z-index: 9;
            right: 1px;
            bottom: 1px;
            background: rgba(255, 255, 255, 0.8);
        }
        .file-actions {
            display: flex;
        }
    }
`;
export const FileTypeBox = styled.div`
    background: #f7f7f7;
    min-height: 55px;
    display: flex;
    align-items: center;
    min-width: 50px;
    justify-content: center;
    position: relative;
    .icon-type {
        i {
            display: inline-block;
            font-size: 32px;
            vertical-align: middle;
            &.icon-xlsx-file-format {
                color: #207245;
            }
            &.icon-docx-file-format {
                color: #295598;
            }
            &.icon-pdf-file-format-symbol {
                color: #e2574c;
            }
        }
    }
`;
export const FileType = styled.div`
    i {
        display: inline-block;
        font-size: 32px;
        vertical-align: middle;
        &.icon-xlsx-file-format {
            color: #207245;
        }
        &.icon-pdf-file-format-symbol {
            color: #e2574c;
        }
    }
`;
export const FileContent = styled.div`
    padding: 0 13px;
    width: 100%;
`;
export const FileName = styled.p`
    padding-bottom: 0px;
    font-weight: 600;
    color: #333;
    max-width: 190px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
export const FileDate = styled.span`
    font-size: 11px;
    font-weight: 100;
    color: #a8a8a8;
    margin-bottom: 4px;
    line-height: 16px;
    display: block;
`;
export const FileActions = styled.ul`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 99;
`;
export const FileActionsItem = styled.li`
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    > a,
    button {
        font-size: 14px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #4473c5;
        color: #fff;
        border-radius: 50%;
        &:hover {
            color: #fff;
        }
    }
    > button {
        border: none;
    }
`;

export const ProgressIndicator = styled.div`
    width: 100%;
    height: 3px;
    box-shadow: none;
    background: rgb(230, 230, 230);
    border-radius: 5px;
    margin: 0px;
`;
export const ProgressTrack = styled.div`
    height: 3px;
    border-radius: 5px;
    background: rgb(115, 196, 116);
`;

export const EmptyStatesCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    &.attachment-empty-states {
        min-height: 50vh;
        i {
            &.nucleoinvest-attach {
                font-size: 60px;
                color: #b9c0d1;
            }
        }

        h4 {
            color: #565860;
            letter-spacing: 0.2px;
            margin-top: 0px;
            margin-bottom: 4px;
        }
    }
    p {
        text-align: center;
        color: #96979c;
        line-height: 18px;
        letter-spacing: 0.2px;
    }
`;

export const EmptyStateIcon = styled.div``;
