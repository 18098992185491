import React from 'react';

import { PUBLIC_ROUTES } from 'app/constants/Routes';
import { AuthWrapper } from '@yubi/yb-module-auth';
import { useNavigate } from 'react-router-dom';
const PublicRoutesWrapper = ({ children, history }) => {
    const navigate = useNavigate();
    const { location = {} } = history || {};
    const { pathname = '' } = location;
    
    if (PUBLIC_ROUTES.includes(pathname)) {
        return <>{children}</>;
    }

    return <AuthWrapper navigator={navigate}>{children}</AuthWrapper>;
};

export default PublicRoutesWrapper;
