import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import { authEnabledLink } from 'app/utils/CommonUtils';
import * as fetchUtils from 'app/utils/FetchUtils';
import * as StringUtils from 'app/utils/StringUtils';
import * as ampTypes from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';
import { createLoadingSelector } from 'app/reducers/loading';
import { getActiveEntityId, getActiveUser } from 'app/actions/AuthedActions';
import { addDownloadDetailsForTask } from 'app/utils/TransactionUtils';
import { PRODUCT_KEYS } from 'app/constants/Constants';

const initialState = null;

const { isInvestor } = getActiveUser();

// actions
const createActionName = (name) => `app/da/transaction/tasks/${name}`;

export const FILE_UPLOAD_SUCCESS = createActionName('FILE_UPLOAD_SUCCESS');
export const FILE_DESTROY = createActionName('FILE_DESTROY');

export const TASK_FETCH_REQUEST = createActionName('TASK_FETCH_REQUEST');
export const TASK_FETCH_SUCCESS = createActionName('TASK_FETCH_SUCCESS');
export const TASK_SUBMIT_SUCCESS = createActionName('TASK_SUBMIT_SUCCESS');

const selectTaskGroups = (tasksData) => {
    const group = [];
    if (tasksData) {
        tasksData.forEach((tsk) => {
            const tg = group.find((g) => g.name === tsk.group);
            if (!tg && tsk.group) {
                const openTask = tasksData.find((g) => g.group === tsk.group && g.status === 'open');
                const avlTask = tasksData.find((g) => g.group === tsk.group && g.isAvailable === true);
                const status = openTask ? 'open' : 'completed';
                const isAvailable = !!avlTask;
                const isEnabled = tasksData.filter((g) => g.group === tsk.group).every((t) => Array.isArray(t.taskFields));

                group.push({
                    name: tsk.group,
                    tempName: tsk.label,
                    alt: StringUtils.replaceSpecialChar(tsk.group),
                    status,
                    isAvailable,
                    isEnabled,
                });
            }
        });
    }
    return group;
};

const selectTasks = (tasksData) => {
    if (!tasksData) return [];
    const entityId = getActiveEntityId();
    return tasksData.filter((t) => t.ownerId === entityId);
};

const selectDependantTasks = (tasksData) => {
    if (!tasksData) return [];
    const entityId = getActiveEntityId();
    return tasksData.filter((t) => t.ownerId !== entityId);
};

export const selectorTasksLoading = createLoadingSelector([createActionName('TASK_FETCH')]);

export default function tasks(state = initialState, action) {
    switch (action.type) {
        case TASK_FETCH_SUCCESS: {
            const tsks = addDownloadDetailsForTask(selectTasks(action.data), action.id);
            return {
                ...state,
                data: tsks,
                groups: selectTaskGroups(tsks),
                dependentData: selectDependantTasks(addDownloadDetailsForTask(action.data, action.id)),
                all: action.data,
            };
        }
        case TASK_SUBMIT_SUCCESS: {
            const tsks = addDownloadDetailsForTask(
                (state.data && state.data.map((obj) => [action.data].find((o) => o.id === obj.id) || obj)) || [],
                action.id,
            );
            const alltsks = (state.all && state.all.map((obj) => [action.data].find((o) => o.id === obj.id) || obj)) || [];
            return {
                ...state,
                data: tsks,
                groups: selectTaskGroups(tsks),
                all: alltsks,
            };
        }
        default:
            return state;
    }
}

const ncdBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}`;

export function uploadTaskFile(file) {
    return (dispatch) => {
        const body = new FormData();
        body.append('transaction_task_document[document]', file);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_task_documents`, body)
            .then((d) => {
                dispatch({ type: FILE_UPLOAD_SUCCESS, data: d });
                return d;
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                });
            });
    };
}

const unMapTask = (data) => {
    const cdata = humps.camelizeKeys(data);
    return {
        ...cdata,
        status: cdata.completed ? 'completed' : 'open',
        actionLabel: cdata.label || cdata.group,
        label: cdata.label || cdata.group,
        resources:
            (cdata.resources &&
                cdata.resources.map((r) => ({
                    ...r,
                    url: r.url && authEnabledLink(r.url),
                }))) ||
            [],
        taskFields:
            cdata.taskFields &&
            cdata.taskFields.map((f) =>
                f.type === 'FILE'
                    ? {
                          ...f,
                          value:
                              (f.value &&
                                  f.value.map((v) => ({
                                      ...v,
                                      url: authEnabledLink(`/transaction_task_documents/${v.id}/download`),
                                  }))) ||
                              f.value,
                      }
                    : f,
            ),
    };
};

const unMapTasks = (data) => {
    const cdata = humps.camelizeKeys(data);
    return cdata.map((d) => unMapTask(d));
};

const mapTask = (data) => {
    const fieldValues = [];
    Object.keys(data).forEach((f) => {
        // FIXME: hack to support file type task field value between set and get
        const val = Array.isArray(data[f]) ? data[f].map((v) => v.id) : data[f];
        //
        fieldValues.push({
            name: f,
            value: val,
        });
    });
    const payload = {
        transaction_task: {
            task_fields: fieldValues,
        },
    };
    return humps.decamelizeKeys(payload);
};

export function getTasks(id) {
    return (dispatch) => {
        dispatch({
            type: TASK_FETCH_REQUEST,
        });
        fetchUtils
            .getJSON(`${ncdBaseUrl(id)}/transaction_tasks`)
            .then((d) => unMapTasks(d))
            .then((d) =>
                dispatch({
                    type: TASK_FETCH_SUCCESS,
                    data: d,
                    id,
                }),
            )
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving task information', m);
                });
            });
    };
}
export function submitTask(id, tid, data) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${ncdBaseUrl(id)}/transaction_tasks/${tid}`, mapTask(data))
            .then((d) => unMapTask(d))
            .then((d) => {
                toastr.success('Success!', `${d.label} submitted successfully.`);
                dispatch(actionableTasks('complete', d.label));
                return dispatch({ type: TASK_SUBMIT_SUCCESS, data: d, id });
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error submitting task', m);
                });
                return Promise.reject(ex);
            });
}

export function actionableTasks(mode, taskName) {
    let event;
    if (mode === 'init') {
        if (isInvestor && taskName === 'funding') {
            event = ampTypes.INVESTOR_UPLOAD_FUNDING_DOC;
        } else {
            event = ampTypes.ACTIONABLE_TASK_POPUP;
        }
    } else if (mode === 'complete') {
        if (isInvestor && taskName === 'funding') {
            event = ampTypes.INVESTOR_UPLOAD_FUNDING_DOC_SUBMIT;
        } else {
            event = ampTypes.ACTIONABLE_TASK_COMPLETED;
        }
    } else {
        event = ampTypes.ACTIONABLE_TASK_OPEN;
    }
    return (dispatch) =>
        dispatch({
            type: event,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: event,
                            task: taskName,
                            transaction_type: PRODUCT_KEYS.direct_assignment,
                        },
                    },
                ],
            },
        });
}
