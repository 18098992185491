import { TRANS_CONST } from '../../../constants/TransactionConstants';

export const HEADER_WITH_CCE = [
    {
        label: 'TRANSACTION',
        key: [TRANS_CONST.dealName],
        className: '',
    },
    {
        label: 'CLIENT',
        key: [TRANS_CONST.clientName],
        className: 'name',
    },
    {
        label: 'SECTOR',
        key: [TRANS_CONST.assetClass, TRANS_CONST.sector],
        className: '',
    },
    {
        label: 'QUANTUM',
        key: [TRANS_CONST.quantum],
        className: '',
    },
    {
        label: 'POS',
        key: [TRANS_CONST.principalOutstanding],
        className: '',
    },
    {
        label: 'YIELD(%)',
        key: [TRANS_CONST.yield],
        className: '',
    },
    {
        label: 'RESIDUAL TENOR',
        key: [TRANS_CONST.residualTenor],
        className: '',
    },
    {
        label: 'CURRENT CREDIT ENHANCEMENT',
        key: [],
        isCCE: true,
        className: 'current-credit-enhcmnt',
        group: [
            {
                label: 'FLCE',
                key: TRANS_CONST.flceFixedDeposit,
            },
            {
                label: 'SLCE',
                key: TRANS_CONST.maxSlceBg,
            },
            {
                label: 'OTHER',
                key: TRANS_CONST.other,
            },
        ],
    },
];

export const HEADER_WITHOUT_CCE = [
    {
        label: 'TRANSACTION',
        key: [TRANS_CONST.dealName],
        className: '',
    },
    {
        label: 'CLIENT',
        key: [TRANS_CONST.clientName],
        className: 'name',
    },
    {
        label: 'SECTOR',
        key: [TRANS_CONST.assetClass, TRANS_CONST.sector],
        className: '',
    },
    {
        label: 'QUANTUM',
        key: [TRANS_CONST.quantum],
        className: '',
    },
    {
        label: 'POS',
        key: [TRANS_CONST.principalOutstanding],
        className: '',
    },
    {
        label: 'YIELD(%)',
        key: [TRANS_CONST.yield],
        className: '',
    },
    {
        label: 'RESIDUAL TENOR',
        key: [TRANS_CONST.residualTenor],
        className: '',
    },
];

export const TRANSACTION_HEADER = [
    {
        label: 'TRANSACTION',
        key: [TRANS_CONST.dealName],
        className: '',
    },
    {
        label: 'DISBURSED DATE',
        key: [TRANS_CONST.disbursementDate],
        className: '',
    },
    {
        label: 'DISBURSED AMOUNT',
        key: [TRANS_CONST.disbursedAmount],
        className: '',
    },
    {
        label: 'POS',
        key: [TRANS_CONST.principalOutstanding],
        className: '',
    },
    {
        label: 'YIELD(%)',
        key: [TRANS_CONST.yield],
        className: '',
    },
    {
        label: 'MATURITY DATE',
        key: [TRANS_CONST.maturityDate],
        className: '',
    },
];

export const CLIENT_HEADER = [
    {
        label: 'TRANSACTION',
        key: [TRANS_CONST.dealName],
        className: '',
    },
    {
        label: 'PRODUCT',
        key: [TRANS_CONST.transactionType],
        className: 'name',
    },
    {
        label: 'SECTOR',
        key: [TRANS_CONST.assetClass, TRANS_CONST.sector],
        className: '',
    },
    {
        label: 'QUANTUM',
        key: [TRANS_CONST.quantum],
        className: '',
    },
    {
        label: 'POS',
        key: [TRANS_CONST.principalOutstanding],
        className: '',
    },
    {
        label: 'YIELD(%)',
        key: [TRANS_CONST.yield],
        className: '',
    },
    {
        label: 'RESIDUAL TENOR',
        key: [TRANS_CONST.residualTenor],
        className: '',
    },
    {
        label: 'CURRENT CREDIT ENHANCEMENT',
        key: [],
        isCCE: true,
        className: 'current-credit-enhcmnt',
        group: [
            {
                label: 'FLCE',
                key: TRANS_CONST.flceFixedDeposit,
            },
            {
                label: 'SLCE',
                key: TRANS_CONST.maxSlceBg,
            },
            {
                label: 'OTHER',
                key: TRANS_CONST.other,
            },
        ],
    },
];

export const SECTOR_HEADER = [
    {
        label: 'TRANSACTION',
        key: [TRANS_CONST.dealName],
        className: '',
    },
    {
        label: 'DISBURSED DATE',
        key: [TRANS_CONST.disbursementDate],
        className: '',
    },
    {
        label: 'DISBURSED AMOUNT',
        key: [TRANS_CONST.disbursedAmount],
        className: '',
    },
    {
        label: 'POS',
        key: [TRANS_CONST.principalOutstanding],
        className: '',
    },
    {
        label: 'YIELD(%)',
        key: [TRANS_CONST.yield],
        className: '',
    },
    {
        label: 'MATURITY DATE',
        key: [TRANS_CONST.maturityDate],
        className: '',
    },
];
