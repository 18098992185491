export const TRANS_PTC_POOL_FILE_UPLOAD = 'TRANS_PTC_POOL_FILE_UPLOAD';
export const TRANS_PTC_POOL_FILE_DESTROY = 'TRANS_PTC_POOL_FILE_DESTROY';
export const TRANS_PTC_POOL_FILE_422_ERROR = 'TRANS_PTC_POOL_FILE_422_ERROR';

export const TRANS_PTC_POOL_FILE_HEADERS_REQUEST = 'TRANS_PTC_POOL_FILE_HEADERS_REQUEST';
export const TRANS_PTC_POOL_FILE_HEADERS_ERROR = 'TRANS_PTC_POOL_FILE_HEADERS_ERROR';
export const TRANS_PTC_POOL_FILE_HEADERS_SUCCESS = 'TRANS_PTC_POOL_FILE_HEADERS_SUCCESS';

export const TRANS_PTC_CREATE_REQUEST = 'TRANS_PTC_CREATE_REQUEST';
export const TRANS_PTC_CREATE_SUCCESS = 'TRANS_PTC_CREATE_SUCCESS';
export const TRANS_PTC_CREATE_ERROR = 'TRANS_PTC_CREATE_ERROR';

export const TRANS_PTC_SUMMARY_REQUEST = 'TRANS_PTC_SUMMARY_REQUEST';
export const TRANS_PTC_SUMMARY_SUCCESS = 'TRANS_PTC_SUMMARY_SUCCESS';
export const TRANS_PTC_SUMMARY_ERROR = 'TRANS_PTC_SUMMARY_ERROR';
export const TRANS_PTC_SUMMARY_SKIP = 'TRANS_PTC_SUMMARY_SKIP';

export const TRANS_PTC_CLIENT_PREF_SUCCESS = 'TRANS_PTC_CLIENT_PREF_SUCCESS';

export const TRANS_PTC_INIT = 'TRANS_PTC_INIT';
export const TRANS_PTC_GET = 'TRANS_PTC_GET';

export const RESET_DATA = 'RESET_DATA';
