import styled, { css } from 'styled-components';
import { ALERT_TYPE } from './index';

interface AlertWrapperI {
    type: string;
    noBottomMarigin: boolean;
    positionFixed?: string;
}

export const AlertWrapper = styled.div<AlertWrapperI>`
    position: relative;
    padding: 15px 35px 15px 20px;
    background-color: #fff;
    font-size: 14px;
    color: #00539f;
    border-radius: 3px;
    word-break: break-word;
    margin-bottom: 20px;
    strong {
        margin-right: 10px;
        text-transform: capitalize;
    }
    ${({ positionFixed }) =>
        positionFixed === 'fixed' &&
        `
        position:fixed;
        top:0px;
        left:0px;
        right:0px;
    `}
    ${(props: AlertWrapperI): any => {
        switch (props.type) {
            case ALERT_TYPE.warning: {
                return css`
                    color: #856404;
                    background: #fff3cd;
                    margin-bottom:${props.noBottomMarigin ? '0px' : '20px'}
                    button {
                        i {
                            color: #856404;
                        }
                    } 
                `;
            }
            case ALERT_TYPE.danger: {
                return css`
                    color: #a94442;
                    background-color: #f2dede;
                    border-color: #ebccd1;
                    button {
                        i {
                            color: #a94442;
                        }
                    }
                `;
            }
            case ALERT_TYPE.success: {
                return css`
                    color: #3c763d;
                    background-color: #dff0d8;
                    border-color: #d6e9c6;
                    button {
                        i {
                            color: #3c763d;
                        }
                    }
                `;
            }
            case ALERT_TYPE.info: {
                return css`
                    background-color: #c5e6ff;
                    color: #727272;
                    margin-bottom: 0px;
                    strong {
                        font-size: 15px;
                        color: #3e4041;
                        .icon-info-button {
                            font-size: 18px;
                            vertical-align: middle;
                            color: #097ad7;
                            position: relative;
                            bottom: 1px;
                        }
                    }
                    button {
                        i {
                            color: #3e4041;
                            opacity: 0.3;
                        }
                    }
                `;
            }
            default: {
                return css``;
            }
        }
    }}
`;

export const CloseBtn = styled.button`
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    padding: 0px;
    i {
        font-size: 24px;
        color: #00539f;
    }
`;
export default AlertWrapper;
