import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { createLoadingSelector } from '../../../reducers/loading';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    isFetching: false,
};

// actions
const createActionName = name => `app/intp/ptc/${name}`;

export const FETCHING_DATA = createActionName('FETCHING_DATA');
export const RESET_DATA = createActionName('RESET_DATA');
export const TRANSACTION_GET = createActionName('TRANSACTION_GET');
export const SUMMARY_FETCH_SUCCESS = createActionName('SUMMARY_FETCH_SUCCESS');
export const DOCUMENTS_FETCH_SUCCESS = createActionName('DOCUMENTS_FETCH_SUCCESS');
export const PTC_FILES_SUCCESS = createActionName('PTC_FILES_SUCCESS');
export const EXECUTED_DOCS_FETCH_SUCCESS = createActionName('EXECUTED_DOCS_FETCH_SUCCESS');

export default function ptc(state = initialState, action) {
    switch (action.type) {
    case FETCHING_DATA:
        return {
            ...state,
            isFetching: action.status,
        };
    case TRANSACTION_GET:
        return {
            ...state,
            details: action.data,
        };
    case SUMMARY_FETCH_SUCCESS:
        return {
            ...state,
            summary: action.data,
        };
    case DOCUMENTS_FETCH_SUCCESS:
        return {
            ...state,
            documents: action.data.legal_documents,
        };
    case PTC_FILES_SUCCESS:
        return {
            ...state,
            ptcFiles: action.data,
        };
    case RESET_DATA:
        return {
            ...initialState,
        };
    case EXECUTED_DOCS_FETCH_SUCCESS:
        return {
            ...state,
            executedDocs: action.data,
        };
    default:
        return state;
    }
}

export const selectorSummryFetchLoading = createLoadingSelector([
    createActionName('SUMMARY_FETCH'),
]);

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function transactionGet(data) {
    return {
        type: TRANSACTION_GET,
        data,
    };
}

export function resetData() {
    return {
        type: RESET_DATA,
    };
}

const unMapPTC = (data) => {
    const cdata = humps.camelizeKeys(data);
    return {
        ...cdata,
        poolSelectionCriteria: {
            ...cdata.poolSelectionCriteria,
            cutoffDate: moment(cdata.poolSelectionCriteria.cutoffDate).format('DD MMM, YYYY'),
        },
    };
};

export function getPTC(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then(d => unMapPTC(d))
            .then(d => dispatch(transactionGet(d)))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }));
}

export function getPTCSummary(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/transaction_summary`)
            .then(d =>
                (d && d.transaction_summary && humps.camelizeKeys(d.transaction_summary)) || {})
            .then(d => dispatch({ type: SUMMARY_FETCH_SUCCESS, data: d }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }));
}

export function loadDocuments(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/legal_documents`)
            .then((d) => {
                dispatch({ type: DOCUMENTS_FETCH_SUCCESS, data: d, transId: id });
                return d;
            })
            .catch(() => '');
}

export function getPTCFiles(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/securitization_files`)
            .then(d => humps.camelizeKeys(d))
            .then(d =>
                dispatch({
                    type: PTC_FILES_SUCCESS,
                    data: d.securitizationFiles,
                    transId: id,
                }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitization files', m);
                }));
}

export function getExecutedDocuments(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/executed_documents`)
            .then(d => humps.camelizeKeys(d.executed_documents))
            .then(d => dispatch({ type: EXECUTED_DOCS_FETCH_SUCCESS, data: d }))
            .catch(() => '');
}
