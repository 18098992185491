export const RS = 'repayment_schedules';
export const MCD = 'mcds';
export const PC = 'pc';
export const MULTI_FILE_MCD = 'multi_mcds';
export const MCD_TEMPLATE = 'mcd_templates';
export const PAYOUT = 'payout';
export const STANDARDISATION_FILE_NAME = 'Standardisation Error File';

export const REPAYMENT_FREQUENCY = 'repayment_frequency';
export const PSL = 'psl';
export const KYC_TYPE = 'kyc_type';
export const ASSET_CLASS = 'asset_class';

export const MCD_VALIDATION_MSG = {
    ERROR_DOC: 'Please rectify the errors and process the file again',
    VALIDATION_SUCCESS: 'Validation completed. File is ready for processing',
    PROCESS_SUCCESS: 'File processing completed successfully',
    BULK_RS_SUCCESS: 'Bulk RS generated successfully',
};

export const DOC_TYPE = {
    ORIGINAL: 'original',
    STANDARD: 'standard',
    ERROR: 'error',
    WARNING: 'warnings',
    STANDARDISATION: 'standardisation',
    STANDARDISED_DATA: 'standardised_data',
    STANDARDISATION_ERROR: 'standardisation_error',
};

export const MCD_PROGRESS_STEPS = ['Copying original file', 'Preparing system', 'Extracting', 'Completed'];

export const VALIDATION = 'Validating';

export const PROCESSING = 'Processing';

export const COMPLETED = 'completed';

export const COLUMN_MAPPING_FIELDS = {
    ORIGINAL_LTV_AS_FRACTION_OF: 'original_ltv_as_fraction_of',
    ORIGINAL_LTV: 'original_ltv',
};

export const COLUMN_MAPPING_VALUES = {
    NOT_AVAILABLE: 'Not Available',
};
