import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Icon from './index';
import Typography from '../Typography';
import { SizeType, StyleValue, Direction, Color, Shade } from '../Types/CommonTypes';
import { getColor } from '../Utils/colorSets';

interface IconButtonProps extends HTMLAttributes<HTMLElement> {
    text?: number;
    iconClass?: string;
    notification?: string;
    size?: SizeType;
    gutter?: StyleValue;
    spacing?: StyleValue;
    color?: Color;
    bgColor?: Color;
    borderWidth?: number;
    borderStyle?: string;
    borderColor?: Color;
    borderRadius?: number;
    bgShade?: Shade;
}
interface IconTextProps extends IconButtonProps {
    textColor?: Color;
    textShade?: Shade;
    textBgColor?: Color;
    textBgShade?: Shade;
    textWidth?: StyleValue;
    textHeight?: StyleValue;
    textBorderWidth?: number | string;
    textBorderStyle?: string;
    textBorderColor?: Color;
    badgeBorderRadius?: number | string;
    direction?: Direction;
    iconShade?: Shade;
}

const StyledNotification = styled.button<IconButtonProps>`
    position: relative;
    background: ${({ bgColor }) => (bgColor ? `${bgColor}` : 'none')}
        border-width: ${({ borderWidth }) => (borderWidth ? `${borderWidth}px` : '0px')}
    `;

const Badge = styled.div<IconTextProps>`
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    outline: 2px solid #fff;
    background: ${({ textBgColor, textBgShade }) => getColor(textBgColor, textBgShade)};
    color: ${({ textColor, textShade }) => getColor(textColor, textShade)};
    width: ${({ textWidth }) => `${textWidth}px`};
    height: ${({ textHeight }) => `${textHeight}px`};
    border-radius: ${({ badgeBorderRadius }) => `${badgeBorderRadius}px`};
    border-width: ${({ textBorderWidth }) => (textBorderWidth ? `${textBorderWidth}px` : '0px')}
    border-style: ${({ textBorderStyle }) => (textBorderStyle ? `${textBorderStyle}` : 'solid')}
    ${({ direction }) =>
        direction === 'horizontal' &&
        `
        display:flex;
        align-items:center;
        justify-content:center;
    `};
`;

const IconButton: React.FC<IconTextProps> = ({
    notification = false,
    iconClass,
    spacing,
    gutter,
    size,
    color,
    bgColor,
    badgeBorderRadius = '3',
    textColor,
    textShade,
    iconShade,
    ...props
}): JSX.Element => {
    return (
        <StyledNotification spacing={spacing} gutter={gutter} size={size} bgColor={bgColor} type="button" {...props}>
            {notification && (
                <Badge badgeBorderRadius={badgeBorderRadius} {...props}>
                    <Typography typeStyle="tiny" color={textColor} shade={textShade}>
                        {notification}
                    </Typography>
                </Badge>
            )}
            <Icon icon={iconClass} color={color} shade={iconShade} />
        </StyledNotification>
    );
};

export default IconButton;
