import uniqBy from 'lodash/uniqBy';
import { toastr } from 'react-redux-toastr';
import filter from 'lodash/filter';

import * as fetchUtils from '../../../utils/FetchUtils';
import * as routerUtils from '../../../utils/RouterUtils';

import { ADMIN_TRANS_PTC } from '../constants/Routes';

const initialState = {
    poolFiles: [],
    transaction: {},
    poolSummary: {},
    clientPref: {},
    isFetching: false,
    errMessage: '',
    warnMessage: '',
    skipExtractHeaders: false,
    shouldSkipPoolSummary: false,
};

// actions
const createActionName = (name) => `app/client/ptc/initiate/${name}`;

export const TRANS_PTC_POOL_FILE_UPLOAD = createActionName('TRANS_PTC_POOL_FILE_UPLOAD');
export const TRANS_PTC_POOL_FILE_DESTROY = createActionName('TRANS_PTC_POOL_FILE_DESTROY');
export const TRANS_PTC_POOL_FILE_422_ERROR = createActionName('TRANS_PTC_POOL_FILE_422_ERROR');

export const TRANS_PTC_POOL_FILE_HEADERS_REQUEST = createActionName('TRANS_PTC_POOL_FILE_HEADERS_REQUEST');
export const TRANS_PTC_POOL_FILE_HEADERS_ERROR = createActionName('TRANS_PTC_POOL_FILE_HEADERS_ERROR');
export const TRANS_PTC_POOL_FILE_HEADERS_SUCCESS = createActionName('TRANS_PTC_POOL_FILE_HEADERS_SUCCESS');

export const TRANS_PTC_CREATE_REQUEST = createActionName('TRANS_PTC_CREATE_REQUEST');
export const TRANS_PTC_CREATE_SUCCESS = createActionName('TRANS_PTC_CREATE_SUCCESS');
export const TRANS_PTC_CREATE_ERROR = createActionName('TRANS_PTC_CREATE_ERROR');

export const TRANS_PTC_SUMMARY_REQUEST = createActionName('TRANS_PTC_SUMMARY_REQUEST');
export const TRANS_PTC_SUMMARY_SUCCESS = createActionName('TRANS_PTC_SUMMARY_SUCCESS');
export const TRANS_PTC_SUMMARY_ERROR = createActionName('TRANS_PTC_SUMMARY_ERROR');
export const TRANS_PTC_SUMMARY_SKIP = createActionName('TRANS_PTC_SUMMARY_SKIP');

export const TRANS_PTC_CLIENT_PREF_SUCCESS = createActionName('TRANS_PTC_CLIENT_PREF_SUCCESS');

export const TRANS_PTC_INIT = createActionName('TRANS_PTC_INIT');
export const TRANS_PTC_GET = createActionName('TRANS_PTC_GET');

export const RESET_DATA = createActionName('RESET_DATA');

export default function ptcInitiate(state = initialState, action) {
    switch (action.type) {
        case TRANS_PTC_POOL_FILE_DESTROY:
            return {
                ...state,
                poolFiles: filter(state.poolFiles, (f) => f.id !== action.id),
            };
        case TRANS_PTC_POOL_FILE_UPLOAD:
            return {
                ...state,
                poolFiles: uniqBy([action.data, ...state.poolFiles], 'document_type'),
            };
        case TRANS_PTC_POOL_FILE_422_ERROR:
            return {
                ...state,
                skipExtractHeaders: true,
            };
        case TRANS_PTC_POOL_FILE_HEADERS_REQUEST:
            return {
                ...state,
                errMessage: '',
                warnMessage: '',
                isFileHeadersLoading: true,
            };
        case TRANS_PTC_POOL_FILE_HEADERS_SUCCESS:
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: '',
                warnMessage: '',
                poolFiles: state.poolFiles.map((f) => (action.data && action.fileId && f.id === action.fileId ? { ...f, ...action.data } : { ...f })),
            };
        case TRANS_PTC_POOL_FILE_HEADERS_ERROR:
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: action.errorMsg,
                warnMessage: action.warnMessage,
            };
        case TRANS_PTC_CREATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                errMessage: '',
                warnMessage: '',
            };
        case TRANS_PTC_CREATE_SUCCESS:
            return {
                ...state,
                transaction: action.data,
                isFetching: false,
            };
        case TRANS_PTC_CREATE_ERROR:
            return {
                ...state,
                errMessage: action.message,
                isFetching: false,
            };
        case TRANS_PTC_SUMMARY_SKIP:
            return {
                ...state,
                errMessage: '',
                warnMessage: '',
                shouldSkipPoolSummary: true,
            };
        case TRANS_PTC_SUMMARY_REQUEST:
            return {
                ...state,
                isFetching: true,
                shouldSkipPoolSummary: false,
                errMessage: '',
                warnMessage: '',
            };
        case TRANS_PTC_SUMMARY_SUCCESS:
            return {
                ...state,
                poolSummary: action.summary.pool_summary,
                isFetching: false,
            };
        case TRANS_PTC_SUMMARY_ERROR:
            return {
                ...state,
                errMessage: action.message,
                errMessageAction: action.messageAction,
                isFetching: false,
            };
        case TRANS_PTC_CLIENT_PREF_SUCCESS:
            return {
                ...state,
                clientPref: action.data,
            };
        case TRANS_PTC_INIT:
            return {
                ...initialState,
            };
        case TRANS_PTC_GET: {
            const files = [];
            if (action.data.rs_file_id) {
                files.push({
                    id: action.data.rs_file_id,
                    document_type: 'rs_file',
                    file_name: 'RS file',
                });
            }
            if (action.data.mcd_file_id) {
                files.push({
                    id: action.data.mcd_file_id,
                    document_type: 'mcd_file',
                    file_name: 'MCD File',
                });
            }

            return {
                ...state,
                poolFiles: files,
                transaction: action.data,
            };
        }
        default:
            return state;
    }
}

export function initPTCTrans() {
    return (dispatch) => dispatch({ type: TRANS_PTC_INIT });
}

export function getPTCTrans(transId) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}`)
            .then((d) => {
                dispatch({ type: TRANS_PTC_GET, data: d });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                }),
            );
}

export function destroyPTCPool(fileId, transId) {
    return (dispatch) => {
        if (transId) {
            return Promise.resolve(dispatch({ type: TRANS_PTC_POOL_FILE_DESTROY, id: fileId }));
        }
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}`)
            .then(() => {
                dispatch({ type: TRANS_PTC_POOL_FILE_DESTROY, id: fileId });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing pool file', m);
                }),
            );
    };
}
export function uploadPTCPoolV2(fileId, files, fileType, poolType, transId = '') {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}`, {
                [fileType]: {
                    document_file_name: files[0].name,
                    document_content_type: files[0].type,
                    document_file_size: files[0].size,
                    transaction_id: transId,
                },
                transaction_id: transId,
            })
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(pollFileHeaders(d.id, poolType));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_PTC_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
}
export function uploadPTCPool(file, fileType, poolType, skipExtractHeaders = false) {
    return (dispatch) => {
        const body = new FormData();
        Object.keys(file).forEach((k) => {
            body.append('transaction_file[document]', file[k]);
        });
        if (fileType === 'mcd_file') {
            body.append('transaction_file[pool_type]', poolType);
            body.append('transaction_file[skip_extract_headers]', skipExtractHeaders);
        }
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(pollFileHeaders(d.id, poolType));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_PTC_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

// Upload for RS Column Mapping
export function uploadPTCPoolV3(fileId, files, fileType, poolType, h, transId) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/repayment_schedules/${fileId}`, {
                [fileType]: {
                    document_file_name: files[0].name,
                    document_content_type: files[0].type,
                    document_file_size: files[0].size,
                },
            })
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(pollFileHeaders(d.id, poolType));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_PTC_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
}

export function getFileHeaders(fileId, poolType) {
    return (dispatch) => {
        dispatch({ type: TRANS_PTC_POOL_FILE_HEADERS_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}/file_headers?pool_type=${poolType}`)
            .then((d) =>
                dispatch({
                    type: TRANS_PTC_POOL_FILE_HEADERS_SUCCESS,
                    fileId,
                    data: d,
                }),
            )
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) =>
                    dispatch({
                        type: TRANS_PTC_POOL_FILE_HEADERS_ERROR,
                        errorMsg: m,
                    }),
                ),
            );
    };
}

export function pollFileHeaders(fileId, poolType) {
    return (dispatch) => {
        dispatch({ type: TRANS_PTC_POOL_FILE_HEADERS_REQUEST });
        return fetchUtils
            .poll(
                () =>
                    fetchUtils
                        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}/file_headers?pool_type=${poolType}`)
                        .catch(() => null),
                1000 * 60 * 3,
                5000,
            )
            .then((d) =>
                dispatch({
                    type: TRANS_PTC_POOL_FILE_HEADERS_SUCCESS,
                    fileId,
                    data: d,
                }),
            )
            .catch((ex) =>
                fetchUtils
                    .handleErrorV2(dispatch, ex)
                    .then(() => dispatch({ type: TRANS_PTC_POOL_FILE_HEADERS_ERROR, warnMessage: 'Timed out! please try again.' })),
            );
    };
}

function updatePoolFile(d) {
    return { type: TRANS_PTC_POOL_FILE_UPLOAD, data: d };
}

function mapTransfileColumns(poolType, fileType, columnMapping) {
    const obj = {};
    const colMappingObj = {};
    Object.keys(columnMapping).forEach((c) => {
        if (columnMapping[c]) {
            colMappingObj[c] = columnMapping[c];
        }
    });
    obj[fileType] = {
        column_mapping: colMappingObj,
        pool_type: poolType,
    };
    return obj;
}

function postTransFileColMap(poolType, fileId, fileType, columnMapping) {
    return (dispatch) => {
        if (fileId && fileType && columnMapping) {
            return fetchUtils
                .putJSON(
                    `${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}/map_columns`,
                    mapTransfileColumns(poolType, fileType, columnMapping),
                )
                .then((d) => dispatch(updatePoolFile(d)));
        }
        return Promise.resolve();
    };
}

function mapPTCInitData(data) {
    const { poolFiles, poolType } = data;

    const mcdFile = poolFiles.filter((el) => el.document_type === 'mcd_file');
    const rsFile = poolFiles.filter((el) => el.document_type === 'rs_file');

    return {
        securitization: {
            rs_file_id: rsFile && rsFile.length > 0 ? rsFile[0].id : '',
            mcd_file_id: mcdFile && mcdFile.length > 0 ? mcdFile[0].id : '',
            pool_type: poolType,
        },
    };
}

function postPTCTrans(transId, data) {
    if (transId) {
        return fetchUtils.putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}`, mapPTCInitData(data));
    }
    return fetchUtils.postJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations`, mapPTCInitData(data));
}

export function createPTCTrans(transId, data, mode = 'create') {
    return (dispatch) => {
        const { poolFiles, columnMappingFileId, columnMappingFileType, columnMapping, poolType } = data;
        dispatch({ type: TRANS_PTC_CREATE_REQUEST });
        return dispatch(postTransFileColMap(poolType, columnMappingFileId, columnMappingFileType, columnMapping))
            .then(() => postPTCTrans(transId, { poolFiles, poolType }))
            .then((d) => {
                dispatch({ type: TRANS_PTC_CREATE_SUCCESS, data: d });
                return d;
            })
            .then((d) => {
                if (mode === 'edit') {
                    return dispatch(
                        routerUtils.pushRoute({
                            path: ADMIN_TRANS_PTC,
                            keys: { transId: d.id },
                        }),
                    );
                }
                return dispatch(
                    routerUtils.pushRoute({
                        path: ADMIN_TRANS_PTC,
                        keys: { transId: d.id },
                    }),
                );
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANS_PTC_CREATE_ERROR, message: m });
                    toastr.error('Error while creating PTC transaction', m);
                }),
            );
    };
}
