import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = [];

// actions
const createActionName = name => `app/ptc/transaction/files/${name}`;

export const FILES_SUCCESS = createActionName('FILES_SUCCESS');

export default function files(state = initialState, action) {
    switch (action.type) {
    case FILES_SUCCESS:
        return [...action.data];
    default:
        return state;
    }
}

const ptcBaseUrl = id => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

export function getFiles(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/securitization_files`)
            .then(d => humps.camelizeKeys(d.securitization_files))
            .then(data =>
                dispatch({
                    type: FILES_SUCCESS,
                    data,
                    id,
                }))
            .catch(ex =>
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving securitization files', m);
                }));
}
