export const CHART_TYPE = {
    BAR: 'BAR',
    AREA: 'AREA',
    PIE: 'PIE',
    LINE: 'LINE',
};
export const DEFAULT_COLOR = '#EEE';
export const BAR_COLOR: any = '#8884d8';
export const GEO_MAP_COLOR: any = ['#F0F7F9', '#E1EEF4', '#D2E6EE', '#97C5D8', '#79B4CD', '#6AACC8', '#4C9BBD', '#3B82A1', '#2C6177', '#11242D'];
export const GEO_MAP_BORDER_COLOR: any = '#4C9BBD';
export const REVERSE_GEO_MAP_COLOR = GEO_MAP_COLOR.reverse();
export const CHART_COLORS: any = ['#3B82A1', '#9FDBD0', '#E16A51', '#9D6CBA', '#EFC970', '#A9D575', '#4DADDF', '#F2A364'];
