import humps from 'humps';
import history from 'app/utils/myHistory';
import _get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import * as ampTypes from 'app/constants/AmplitudeActions';
import { createLoadingSelector } from 'app/reducers/loading';
import { userIdFromMfaToken, decodeJWT } from 'app/utils/jwtHelper';
import { msalConfig, requestObj } from 'app/constants/MsalConstant';
import * as Msal from 'msal';
import AmplitudeService from 'app/components/Amplitude/AmplitudeService';

import { USER_TYPES, GROUPS, ROLE, PRODUCT_ID, INTERNAL_USER_EMAIL_DOMAIN, INTERNAL_ACTORS_VALUES, ENVIRONMENTS } from 'app/constants/Constants';
import { isValidRedirect, nexturlValidation } from 'app/utils/CommonUtils';
import * as types from '../constants/ActionTypes';
import * as RouterUtils from '../utils/RouterUtils';
import * as fetchUtils from '../utils/FetchUtils';
import Logger from '../utils/Logger';
import Auth from '../utils/Auth';
import { EventTypes } from '../utils/reduxAmplitude';
import { fetchUserWithpermissions } from '../reducers/entities';
import { CLIENT_DEFAULT } from '../actors/client/constants/Routes';
import {
    CAG_DEFAULT_HOME,
    FORGOT_PASSWORD,
    VENDOR_ONBOARDING,
    HOME,
    ANCHOR_DEFAULT_HOME,
    CREDIT_DEFAULT_HOME,
    POOLS_LIST_PAGE,
    CONNECTOR_DEFAULT_HOME,
} from '../constants/Routes';
import { CAEvents } from 'app/components/Amplitude/AmplitudeEvents';
import { AuthType, NetworkStatusCode } from 'app/components/Amplitude/AmplitudeTypes';
import { MFAService } from '@yubi/yb-module-auth';
import { HOME as PRODUCT_SETUP_HOME } from 'app/products/Shared/ProductSetup/Routes';
const EXCLUDE_URL = ['/logout-all-devices'];

function checkMfaToken(token) {
    let updateToken = token;
    if (MFAService.isMfaTokenValid() && !MFAService.isMfaTokenExpiring() && getActiveUserId() === userIdFromMfaToken(MFAService.getMFAToken())) {
        updateToken = {
            ...token,
            mfaToken: MFAService.getMFAToken(),
        };
    } else {
        MFAService.removeMFAToken();
        updateToken = {
            ...token,
            mfaToken: null,
        };
    }
    return updateToken;
}

/* eslint-disable consistent-return */

export function initAuth() {
    return (dispatch) => {
        const token = Auth.getToken();
        const mfaValidatedToken = checkMfaToken(token);
        return dispatch(authUser(mfaValidatedToken));
    };
}

export function getHomeUrl(group, permissions = {}) {
    try {
        const grp = humps.decamelize(group);
        const subGroup = Auth.getSubGroupFromCookie();
        const { role, credpoolRole } = Auth.getActiveUserGroup();
        const admin = role === ROLE.ADMIN ? true : false;
        if (group) {
            switch (grp) {
                case GROUPS.CLIENT:
                    return fetchUtils.getEnv(subGroup ? getSubGroupHomeUrl(subGroup) : CLIENT_DEFAULT);
                case GROUPS.INVESTOR:
                    return fetchUtils.getEnv(credpoolRole === USER_TYPES.OPERATIONS ? PRODUCT_SETUP_HOME : CREDIT_DEFAULT_HOME);
                case GROUPS.PRODUCT:
                    return fetchUtils.getEnv(POOLS_LIST_PAGE);
                case GROUPS.CAG:
                    return fetchUtils.getEnv(CAG_DEFAULT_HOME);
                case GROUPS.VENDOR:
                    return fetchUtils.getEnv(VENDOR_ONBOARDING);
                case GROUPS.ANCHOR:
                    return fetchUtils.getEnv(ANCHOR_DEFAULT_HOME);
                case GROUPS.RETAIL_USER:
                    return fetchUtils.getEnv(CREDIT_DEFAULT_HOME);
                case GROUPS.CONNECTOR:
                    return fetchUtils.getEnv(admin ? CONNECTOR_DEFAULT_HOME : CREDIT_DEFAULT_HOME);
                default:
                    return fetchUtils.getEnv(HOME);
            }
        }
    } catch (e) {
        Logger.logException(e);
    }
}

function getSubGroupHomeUrl(group) {
    switch (group) {
        case GROUPS.ANCHOR:
            return fetchUtils.getEnv(CREDIT_DEFAULT_HOME);
        default:
            return fetchUtils.getEnv(CLIENT_DEFAULT);
    }
}

export function getPermissions() {
    const activeUser = Auth.getActiveUserGroup();
    return {
        isAdmin: ROLE.ADMIN === activeUser?.role,
        isProduct: GROUPS.PRODUCT === activeUser?.group,
        isInvestor: GROUPS.INVESTOR === activeUser?.group,
        isClient: GROUPS.INVESTOR === activeUser?.group,
    };
}

export function getEntityDetails() {
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${getActiveEntityId()}`)
        .then((d) => humps.camelizeKeys(d))
        .catch((e) => fetchUtils.handleError('', e));
}

export function getActiveEntityId() {
    return Auth.getEntityId();
}

export const checkInternalUser = (actors = Auth.getActiveActor()) => {
    if (Array.isArray(actors)) {
        let access = false;
        actors.map((actor) => {
            if (INTERNAL_ACTORS_VALUES.includes(actor)) {
                access = true;
            }
        });
        return access;
    }
    return INTERNAL_ACTORS_VALUES.includes(actors);
};

export function getActiveUser() {
    const group = Auth.getGroup();
    const roles = Auth.getRoles() ? Auth.getRoles() : '';
    const { credpoolRole } = Auth.getActiveUserGroup() || {};

    if (!group) return {};
    return {
        isProduct: group.includes(USER_TYPES.PRODUCT),
        isClient: group.includes(USER_TYPES.CUSTOMER),
        isInvestor: group.includes(USER_TYPES.INVESTOR),
        isTreasury: group.includes(USER_TYPES.TREASURY),
        isAuditor: group.includes(USER_TYPES.AUDITOR),
        isLawfirm: group.includes(USER_TYPES.LAW_FIRM),
        isTrustee: group.includes(USER_TYPES.TRUSTEE),
        isGuarantor: group.includes(USER_TYPES.GUARANTOR),
        isRatingagency: group.includes(USER_TYPES.RATING_AGENCY),
        isOperation: group.includes(USER_TYPES.OPERATIONS) && roles === ROLE.ADMIN,
        isOperationAssociate: group.includes(USER_TYPES.OPERATIONS) && roles === ROLE.ASSOCIATE,
        isRisk: group.includes(USER_TYPES.RISK),
        isCAG: group.includes(USER_TYPES.CLIENT_ACQUISITION_GROUP),
        isInternal: checkInternalUser(),
        isCapitalMarkets: group.includes(USER_TYPES.CAPITAL_MARKETS),
        isCredit: group.includes(USER_TYPES.CREDIT),
        isCSG: group.includes(USER_TYPES.CLIENT_SERVICE_GROUP),
        isCollateralManger: group.includes(USER_TYPES.COLLATERAL_MANAGER),
        isInternalAuditor: group.includes(USER_TYPES.INTERNAL_AUDITOR),
        isInvestorOperations: group.includes(USER_TYPES.INVESTOR) && credpoolRole === USER_TYPES.OPERATIONS,
        isInvestorAdmin: group.includes(USER_TYPES.INVESTOR) && credpoolRole === USER_TYPES.ADMIN,
        isInvestorMaker: (group.includes(USER_TYPES.INVESTOR) && credpoolRole === USER_TYPES.MAKER) || credpoolRole === USER_TYPES.MAKER_NEW,
        isInvestorChecker: (group.includes(USER_TYPES.INVESTOR) && credpoolRole === USER_TYPES.CHECKER) || credpoolRole === USER_TYPES.CHECKER_NEW,
        isRelationshipManager: group.includes(USER_TYPES.INVESTOR) && credpoolRole === USER_TYPES.RELATIONSHIP_MANAGER,
    };
}

export function getUserId(idToken) {
    if (!idToken) return '';
    const jwt = decodeJWT(idToken);
    if (!jwt) return '';
    return jwt[`${process.env.REACT_APP_AUTH_AUDIENCE}/local_user_id`];
}

export function getActiveUserId() {
    const idToken = Auth.getIdToken();
    return idToken ? Auth.getUserId(idToken) : '';
}

export function getActiveActorHomeUrl(permissions) {
    const activeGroup = Auth.getActiveUserGroup();
    return activeGroup?.group ? getHomeUrl(activeGroup?.group, permissions) : '/';
}

export function preLoginCheck(formdata, nextUrl) {
    AmplitudeService.logEvent(CAEvents.Authentication.LoginInitiated, {
        referrer: AmplitudeService.getModuleFromURL(),
        status_msg: 'Login Initated',
        login_mode: AuthType.Web,
    });

    return (dispatch) => {
        if (!nexturlValidation(nextUrl.pathname)) {
            AmplitudeService.logEvent(CAEvents.Authentication.LoginCompleted, {
                referrer: AmplitudeService.getModuleFromURL(),
                status_code: NetworkStatusCode.UnAuthenticated,
                status_msg: 'Wrong email or password',
                login_mode: AuthType.Web,
            });
            return dispatch({ type: types.AUTH_FAILED, errorMsg: 'Wrong email or password' });
        }
        dispatch({ type: types.LOGIN_PRECHECK });
        const { email } = formdata;
        if (typeof Auth?.validateUserPassword === 'function') {
            return Auth?.validateUserPassword(email)
                .then((response) => {
                    const { invalidPasswordCheck } = response;
                    if (invalidPasswordCheck) {
                        dispatch({ type: types.PASSWORD_INVALIDATED, passwordInvalidated: invalidPasswordCheck });
                        history.push(FORGOT_PASSWORD);
                    } else {
                        return dispatch(login(formdata, nextUrl));
                    }
                })
                .catch((err) => {
                    AmplitudeService.logEvent(CAEvents.Authentication.LoginCompleted, {
                        referrer: AmplitudeService.getModuleFromURL(),
                        status_code: NetworkStatusCode.UnAuthenticated,
                        status_msg: 'Wrong email or password',
                        login_mode: AuthType.Web,
                    });
                    fetchUtils.handleErrorV2(dispatch, err).then((m) => {
                        dispatch({ type: types.AUTH_FAILED, errorMsg: m === 'User not found' ? 'Wrong email or password.' : m });
                    });
                });
        }
    };
}

export function login(formData, nextUrl, fromMicrosoft = false) {
    return (dispatch) => {
        dispatch({ type: types.AUTH_REQUEST });
        const { email, password } = formData;
        Auth.setNextUrl(RouterUtils.locationToUrl(nextUrl, '/'), EXCLUDE_URL);
        return Auth.login(email, password)
            .then((token) => {
                dispatch(authUser(token));
            })
            .then(() => {
                if (nextUrl) {
                    return dispatch(push(nextUrl));
                }
                return dispatch(push('/'));
            })
            .catch((err) => {
                const { name, message } = err;
                if (name !== 'access_denied') {
                    Logger.logException(err);
                }
                const parsedMessage = isJson(message)
                    ? 'No internet connection or contact your IT support to whitelist *.go-yubi.com & *.amazonaws.com in your firewall settings to login'
                    : message;

                AmplitudeService.logEvent(CAEvents.Authentication.LoginCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: NetworkStatusCode.UnAuthenticated,
                    status_msg: parsedMessage,
                    login_mode: fromMicrosoft ? AuthType.Microsoft : AuthType.Web,
                });
                return dispatch({
                    type: fromMicrosoft ? types.AUTH_FAILED_VIA_MICROSOFT : types.AUTH_FAILED,
                    errorMsg: parsedMessage,
                });
            });
    };
}

export function loginWithMicrosoft(nextUrl) {
    sessionStorage.clear();
    const msalInstance = new Msal.UserAgentApplication(msalConfig);

    AmplitudeService.logEvent(CAEvents.Authentication.LoginInitiated, {
        referrer: AmplitudeService.getModuleFromURL(),
        status_msg: 'Login Initiated',
        login_mode: AuthType.Microsoft,
    });
    return (dispatch) => {
        if (!nexturlValidation(nextUrl.pathname)) {
            return dispatch({ type: types.AUTH_FAILED, errorMsg: 'Microsoft Single Sign on failed' });
        }
        dispatch({ type: types.MICROSOFT_LOGIN });
        msalInstance
            .loginPopup(requestObj)
            .then((loginResponse) => {
                dispatch(getUserTempPassword(loginResponse, nextUrl));
                dispatch({ type: types.MICROSOFT_AUTH_SUCCESS });
                dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: false });
            })
            .catch((error) => {
                AmplitudeService.logEvent(CAEvents.Authentication.LoginCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: NetworkStatusCode.UnAuthenticated,
                    login_mode: AuthType.Microsoft,
                    status_msg: error?.message ?? 'MS Authentication Failed',
                });
                dispatch({ type: types.MICROSOFT_AUTH_FAILURE, errorMsg: error });
                Logger.logException(error);
            });
    };
}

function getUserTempPassword(result, nextUrl = {}) {
    return (dispatch) => {
        dispatch({ type: types.USER_TEMP_PASSWORD_REQUEST });
        const {
            idToken: { rawIdToken: id_token, nonce },
        } = result;
        return Auth.getUserPassword({ id_token, nonce })
            .then((data) => {
                const { mfaToken, userEmail: email, tempPassword: password } = data;
                MFAService.setMfa(mfaToken);
                dispatch(login({ email, password }, nextUrl, true));
                dispatch({ type: types.USER_TEMP_PASSWORD_SUCCESS });
            })
            .catch((err) =>
                fetchUtils.handleErrorV2(dispatch, err).then((m) => {
                    dispatch({ type: types.AUTH_FAILED_VIA_MICROSOFT, errorMsg: 'User Not Found' });
                    dispatch({ type: types.USER_TEMP_PASSWORD_FAILURE });
                }),
            );
    };
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function forgotPasswordRes(response) {
    return {
        type: types.FORGOT_PASSWORD_RESPONSE,
        response,
    };
}

export const mfaAuthLoader = createLoadingSelector(['MFA']);
export const mfaOtpLoader = createLoadingSelector(['MFA_OTP']);
export const LOGOUT_ALL_LOADING = createLoadingSelector(['LOGOUT_ALL']);

export function resetForgotPasswordView() {
    return (dispatch) => dispatch({ type: types.FORGOT_PASSWORD_RESET_VIEW });
}

export function forgotPassword(formData) {
    return (dispatch) => {
        dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/forgot_password?product_id=${PRODUCT_ID}&email=${formData.email}`)
            .then((d) => {
                AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: 200,
                    email: formData.email,
                    message: 'Email Sent',
                });
                dispatch(forgotPasswordRes({ resetPassword: true, err: null, passwordResetMessage: d.error_message ?? '' }));
            })
            .catch((err) => {
                AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: 403,
                    email: formData.email,
                    message: err?.message ?? err,
                });
                fetchUtils.handleErrorV2(dispatch, err).then((m) => dispatch(forgotPasswordRes({ resetPassword: false, err: m })));
            });
    };
}

export function isActivated(userId) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/can_update_password?product_id=${PRODUCT_ID}&user_id=${userId}`)
            .then((d) => {
                dispatch({ type: types.SET_PASSWORD_EXISTING_USER, data: d.user_email });
                if (!d.can_update) {
                    dispatch(push(FORGOT_PASSWORD));
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) =>
                    dispatch({
                        type: types.AUTH_FAILED,
                        errorMsg: m,
                    }),
                ),
            );
}

export function setPassword(args, data) {
    return (dispatch) => {
        MFAService.removeMfa();
        dispatch({ type: types.SET_PASSWORD_REQUEST });
        const { userId, token } = args;
        const url = `${process.env.REACT_APP_AUTH_API_HOST}/users/${userId}/activate`;
        return fetchUtils
            .putJSON(url, {
                password: _get(data, 'password'),
                token,
                terms_and_conditions_accepted: _get(data, 'termsAndConditionsAccepted'),
            })
            .then((d) => {
                if (d.mfa_token) {
                    MFAService.setMfa(d.mfa_token);
                    return;
                }
                dispatch({
                    type: types.MFA_FAILURE,
                    errorMsg: 'Token not found',
                });
            })
            .then(() => {
                AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: 200,
                    email: data?.email,
                    message: 'New Password set',
                });
                toastr.success('New Password!', 'Your new password has been updated successfully');
                Auth.logout();
                if (isValidRedirect(window.location.origin)) {
                    window.location.href = window.location.origin;
                }
            })
            .catch((ex) => {
                AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, {
                    referrer: AmplitudeService.getModuleFromURL(),
                    status_code: 403,
                    email: data?.email,
                    message: 'New password setup failed',
                });
                return fetchUtils.handleErrorV2(dispatch, ex).then((m) =>
                    dispatch({
                        type: types.AUTH_FAILED,
                        errorMsg: m,
                    }),
                );
            });
    };
}

export function changePassword(userId, data, callback) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/${userId}/change_password?product_id=${PRODUCT_ID}`, {
                password: data && data.password,
                old_password: _get(data, 'oldPassword'),
            })
            .then((d) => {
                toastr.success('New Password!', 'Your new password has been updated successfully');
                AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, {
                    email: d?.email,
                    status_code: 200,
                    message: 'Password updated',
                });
                MFAService.removeMfa();
                if (typeof callBack === 'function') callback();
                Auth.logout();
                if (isValidRedirect(window.location.origin)) {
                    window.location.href = window.location.origin;
                }
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    AmplitudeService.logEvent(CAEvents.Authentication.PasswordSetupCompleted, { status_code: 403, message: m });
                    return dispatch({
                        type: types.AUTH_FAILED,
                        errorMsg: m,
                    });
                });
            });
    };
}

export function logoutUser() {
    return (dispatch) => {
        Auth.logout();
        dispatch(resetAuthed());
        dispatch(initAuth());
    };
}

export function logoutFromAllDevices() {
    return (dispatch) => {
        dispatch({ type: types.LOGOUT_ALL_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/${getActiveUserId()}/logout_from_all_devices?product_id=${PRODUCT_ID}`)
            .then(() => {
                dispatch(logoutUser());
                dispatch({ type: types.LOGOUT_ALL_SUCCESS });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: types.LOGOUT_ALL_FAILURE });
                    toastr.error('Error while trying to logout', m);
                }),
            );
    };
}

function authUser(token) {
    return (dispatch) => dispatch(fetchAuthedUser(token));
}

function fetchAuthedUser(token) {
    return (dispatch) => {
        dispatch(receiveAuthedUserPre(token, null));
        if (!MFAService.isMfaTokenValid()) {
            return dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: true });
        }
        if (!getActiveEntityId()) return dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: true });
        return fetchUserWithpermissions(getActiveEntityId(), getActiveUserId())
            .then((r) => dispatch(receiveAuthedUserPre(token, r)))
            .catch(() => {
                dispatch({ type: types.CHECKED_AUTHED, isAuthChecked: true });
                // throw err;
            });
    };
}

function receiveAuthedUserPre(token, user) {
    return (dispatch) => {
        dispatch(receiveAccessToken(token));
        if (user) {
            getEntityDetails().then((d) => {
                const owner = _get(d, 'owner', false);
                dispatch(
                    receiveAuthedUser({
                        ...user,
                        entity: d.companyName,
                        liveDeals: _get(d, 'liveDeals', false),
                        settledDeals: _get(d, 'settledDeals', false),
                        newOffers: _get(d, 'newOffers', false),
                        investorDashboardEnabled: _get(d, 'investorDashboardEnabled', false),
                        newInvestorDashboardEnabled: _get(d, 'newInvestorDashboardEnabled', false),
                        moratoriumStatus: _get(d, 'moratoriumStatus', null),
                        isMoratoriumEligible: _get(d, 'eligibleForMoratoriumRequest', false),
                        owner,
                        showABSDashboard: _get(d, 'absDashboardEnabled', false),
                        showPortfolioDashboard: _get(d, 'clientPortfolioEnabled', false),
                        clientDashboardV2Enabled: _get(d, 'newClientDashboardEnabled', false),
                        standardReportsPreferences: d?.reportPreferenceSet,
                        dailyCollectionEnabled: d?.dailyCollectionEnabled,
                    }),
                );
            });
        }
    };
}

function resetAuthed() {
    return {
        type: types.RESET_AUTHED,
        meta: {
            amplitude: {
                eventType: EventTypes.track,
                eventPayload: {
                    eventName: ampTypes.LOGOUT,
                },
            },
        },
    };
}

function receiveAccessToken(token) {
    return {
        type: types.RECEIVE_ACCESS_TOKEN,
        token,
    };
}

function receiveAuthedUser(user) {
    const { sub, email, name, entity, ...rest } = user;
    const idToken = Auth.getIdToken();
    const jwt = decodeJWT(idToken);
    const userName = (jwt && jwt.name) || 'unknown';

    const { allEntities, ...ampParams } = rest;
    if (window.FreshWidget) {
        window.FreshWidget.update({
            queryString: `&widgetType=popup&searchArea=no&helpdesk_ticket[requester]=${email}&helpdesk_ticket[name]=${name}`,
        });
    }
    return {
        type: types.RECEIVE_AUTHED_USER,
        user,
        meta: {
            amplitude: [
                {
                    eventType: EventTypes.identify,
                    eventPayload: {
                        userId: sub,
                        name,
                        ...rest,
                        userName,
                        entityName: entity,
                    },
                },
                {
                    eventType: EventTypes.track,
                    eventPayload: {
                        eventName: ampTypes.LOGIN,
                        sub,
                        name,
                        userName,
                        entityName: entity,
                        ...ampParams,
                    },
                },
            ],
        },
    };
}

export const checkInternalUserEmail = (email) => {
    if (email) {
        const regex = new RegExp(INTERNAL_USER_EMAIL_DOMAIN);
        return regex.test(email);
    }
};
export const DBS_IDS = [process.env.REACT_APP_DBS_INVESTOR_ENTITY_ID, process.env.REACT_APP_DBS_INVESTOR_ENTITY_ID_2];

export const BOI_IDS = [process.env.REACT_APP_BOI_BANK_INVESTOR_ENTITY_ID];

export const isDBSEntity = (id = '') => {
    if (id) return DBS_IDS.includes(id);
    return DBS_IDS.includes(getActiveEntityId());
};

export const isBOIEntity = (id = '') => {
    if (id) return BOI_IDS.includes(id);
    return BOI_IDS.includes(getActiveEntityId());
};

export function getIntegrationReports() {
    const URL = `${process.env.REACT_APP_INTEGRATION_REPORTS_API_HOST}/v1/documents?entity_id=${getActiveEntityId()}`;
    const additionalHeaders = { 'x-product-id': 'CRDPL' };
    return (dispatch) => {
        fetchUtils
            .getJSON(URL, null, null, additionalHeaders)
            .then((d) => {
                dispatch({ type: types.RECEIVE_AUTHED_USER, user: { canShowIntegrationReports: d?.length > 0 } });
            })
            .catch(() => toastr.error('Error', 'Something went wrong'));
    };
}
