import { getActiveUser } from 'app/actions/AuthedActions';
import { matchPath } from 'react-router-dom';
import { RS, MULTI_FILE_MCD } from 'app/components/Securitization/Constants/constants';
import * as ampTypes from 'app/constants/AmplitudeActions';
import { MODAL_STATE } from 'app/constants/Constants';
import { TABS, PRODUCT_VALUES } from 'app/constants/TransactionList';
import { EventTypes, logEvent } from 'app/utils/reduxAmplitude';
import { TRANSACTION_NAVIGATION } from 'app/constants/TransactionConstants';

const { ROUTE_CHANGE_EVENTS } = ampTypes;

export default function amplitude(state = {}, action: any) {
    switch (action.type) {
        default:
            return state;
    }
}

export function routeChange(path: string) {
    const event = getRouteChangeEvent(path);
    return (dispatch: React.Dispatch<any>) => {
        if (event)
            return dispatch({
                type: event,
                meta: {
                    amplitude: [
                        {
                            eventType: EventTypes.track,
                            eventPayload: {
                                eventName: event,
                                redirectedPath: path,
                            },
                        },
                    ],
                },
            });
    };
}

export function downloadFile(params: any) {
    return (dispatch: any) =>
        dispatch({
            type: ampTypes.DOWNLOAD_FILE,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: ampTypes.DOWNLOAD_FILE,
                            ...params,
                        },
                    },
                ],
            },
        });
}

export function handleMovetoSettle(data: any = {}, type: string) {
    return (dispatch: any) => {
        let event;
        switch (type) {
            case MODAL_STATE.OPEN: {
                event = ampTypes.PRODUCT_MOVED_TO_SETTLED;
                break;
            }
            case MODAL_STATE.CONFIRM: {
                event = ampTypes.PRODUCT_MOVED_TO_SETTLED_CONFIRM;
                break;
            }
            case MODAL_STATE.CANCEL: {
                event = ampTypes.PRODUCT_MOVED_TO_SETTLED_CANCEL;
                break;
            }
            default:
                event = '';
        }
        return dispatch({
            type: event,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: event,
                            ...data,
                        },
                    },
                ],
            },
        });
    };
}

export function handleFileUpload(fileType: string, params: any) {
    return (dispatch: any) => {
        return dispatch({
            type:
                fileType === RS
                    ? ampTypes.UPLOAD_RS_SUBMIT
                    : fileType === MULTI_FILE_MCD
                    ? ampTypes.PRODUCT_UPLOAD_MULTI_MCD_SUBMIT
                    : ampTypes.UPLOAD_MCD_SUBMIT,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName:
                                fileType === RS
                                    ? ampTypes.UPLOAD_RS_SUBMIT
                                    : fileType === MULTI_FILE_MCD
                                    ? ampTypes.PRODUCT_UPLOAD_MULTI_MCD_SUBMIT
                                    : ampTypes.UPLOAD_MCD_SUBMIT,
                            ...params,
                        },
                    },
                ],
            },
        });
    };
}

export function transactionFilter(tab: any) {
    let event: string;

    switch (tab) {
        case PRODUCT_VALUES.da: {
            event = ampTypes.FILTER_DA;
            break;
        }
        case PRODUCT_VALUES.ptc: {
            event = ampTypes.FILTER_PTC;
            break;
        }
        default:
            event = ampTypes.FILTER_ALL;
    }
    return (dispatch: React.Dispatch<any>) =>
        dispatch({
            type: event,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: event,
                        },
                    },
                ],
            },
        });
}

export function logExternalLinks(name: string, path: string) {
    const { isInvestor, isClient } = getActiveUser();
    const nameLowerCase = name.toLowerCase();
    if (path && isInvestor) {
        if (nameLowerCase === 'client list') {
            logEvent(ampTypes.INVESTOR_CLIENTS);
        } else if (nameLowerCase === 'peer comparison') {
            logEvent(ampTypes.INVESTOR_TOOLS);
        }
    }
    if (isClient && nameLowerCase === 'profile') {
        logEvent(ampTypes.CUSTOMER_PROFILE);
    }
}

export const logTransactionLinks = (type: string, transactionType: string) => {
    let event: string;
    switch (type) {
        case TRANSACTION_NAVIGATION.DETAILS:
            event = ampTypes.DETAILS_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.FILES:
            event = ampTypes.FILES_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.INVESTMENT_NOTE:
            event = ampTypes.INVESTMENT_NOTE_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.DEAL_PERFORMANCE:
            event = ampTypes.DEAL_PERFORMANCE_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.MARKET_RESEARCH:
            event = ampTypes.MARKET_RESEARCH_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.SERVICER_REPORT:
            event = ampTypes.SERVICER_REPORT_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.CASHFLOW_STATEMENT:
            event = ampTypes.CASHFLOW_STATEMENT_TAB_CLICK;
            break;
        case TRANSACTION_NAVIGATION.REPORTS:
            event = ampTypes.REPORTS_TAB_CLICK;
            break;
        default:
            event = '';
    }

    logEvent(event, { transactionType });
};

export const getRouteChangeEvent = (path: string) => {
    const eventType = ROUTE_CHANGE_EVENTS.has(path) && ROUTE_CHANGE_EVENTS.get(path)?.eventName;
    if (eventType) return eventType;

    const event: any = Array.from(ROUTE_CHANGE_EVENTS.keys()).find((eventPath: any) => {
        // const match = matchPath(
        //     {
        //         path: eventPath,
        //         exact: eventPath?.exact ?? true,
        //     },
        //     path,
        // );
        //if (match) return true;
    });

    return ROUTE_CHANGE_EVENTS.get(event)?.eventName;
};
