import React, { useEffect, useState } from 'react';
import Auth, { getProfile } from 'app/utils/Auth';
import AmplitudeService from './AmplitudeService';
import { checkInternalUserEmail } from 'app/actions/AuthedActions';
import useWebEngage from 'app/thirdpartyservices/useWebEngage';
type AmplitudeStorage = {
    DEFAULT_INSTANCE: '$default_instance';
    API_VERSION: 2;
    MAX_STRING_LENGTH: 4096;
    MAX_PROPERTY_KEYS: 1000;
    IDENTIFY_EVENT: '$identify';
    GROUP_IDENTIFY_EVENT: '$groupidentify';
    // localStorageKeys
    LAST_EVENT_ID: 'amplitude_lastEventId';
    LAST_EVENT_TIME: 'amplitude_lastEventTime';
    LAST_IDENTIFY_ID: 'amplitude_lastIdentifyId';
    LAST_SEQUENCE_NUMBER: 'amplitude_lastSequenceNumber';
    SESSION_ID: 'amplitude_sessionId';
    // Used in cookie as well
    DEVICE_ID: 'amplitude_deviceId';
    OPT_OUT: 'amplitude_optOut';
    USER_ID: 'amplitude_userId';
    COOKIE_TEST_PREFIX: 'amp_cookie_test';
    COOKIE_PREFIX: 'amp';
    // Storage options
    STORAGE_DEFAULT: '';
    STORAGE_COOKIES: 'cookies';
    STORAGE_NONE: 'none';
    STORAGE_LOCAL: 'localStorage';
    STORAGE_SESSION: 'sessionStorage';
    // revenue keys
    REVENUE_EVENT: 'revenue_amount';
    REVENUE_PRODUCT_ID: '$productId';
    REVENUE_QUANTITY: '$quantity';
    REVENUE_PRICE: '$price';
    REVENUE_REVENUE_TYPE: '$revenueType';
    AMP_DEVICE_ID_PARAM: 'amp_device_id';
    // url param
    REFERRER: 'referrer';
    // UTM Params
    UTM_SOURCE: 'utm_source';
    UTM_MEDIUM: 'utm_medium';
    UTM_CAMPAIGN: 'utm_campaign';
    UTM_TERM: 'utm_term';
    UTM_CONTENT: 'utm_content';
    ATTRIBUTION_EVENT: '[Amplitude] Attribution Captured';
};

type AmplitudeOptions = {
    apiEndpoint?: string;
    batchEvents?: boolean;
    cookieExpiration?: number;
    // 12 months is for GDPR compliance
    cookieName?: string;
    // this is a deprecated option
    sameSiteCookie?: string;
    // cookie privacy policy
    cookieForceUpgrade?: boolean;
    deferInitialization?: boolean;
    disableCookies?: boolean;
    // this is a deprecated option
    deviceIdFromUrlParam?: boolean;
    domain?: string;
    eventUploadPeriodMillis?: number;
    // 30s
    eventUploadThreshold?: number;
    forceHttps?: boolean;
    includeFbclid?: boolean;
    includeGclid?: boolean;
    includeReferrer?: boolean;
    includeUtm?: boolean;
    language?: any;
    logLevel?: any;
    logAttributionCapturedEvent?: boolean;
    optOut?: boolean;
    onError?: Function;
    platform?: `Web` | `iOS` | `Android`;
    savedMaxCount?: number;
    saveEvents?: boolean;
    saveParamsReferrerOncePerSession?: boolean;
    secureCookie?: boolean;
    sessionTimeout?: number;
    storage: AmplitudeStorage;
    trackingOptions: {
        city?: boolean;
        country?: boolean;
        carrier?: boolean;
        device_manufacturer?: boolean;
        device_model?: boolean;
        dma?: boolean;
        ip_address?: boolean;
        language?: boolean;
        os_name?: boolean;
        os_version?: boolean;
        platform?: boolean;
        region?: boolean;
        version_name?: boolean;
    };
    unsetParamsReferrerOnNewSession?: boolean;
    unsentKey?: string;
    unsentIdentifyKey?: string;
    uploadBatchSize?: number;
};

type AmplitudeProviderProps = {
    apiKey: string;
    children: React.ReactNode;
    amplitudeInstance: any;
    userId?: string;
    config?: AmplitudeOptions;
};

interface UserProperties {
    [key: string]: string;
}

export interface AmplitudeProps {
    logEvent?: (eventType: string, eventProperties?: Record<string, any>, callback?: Function) => void;
    setUserProperties?: (props: UserProperties) => any;
    setUserId?: (id: string) => any;
}

export interface AmplitudeContextInterface {
    amplitude?: AmplitudeProps;
    userProps?: any;
}

export const AmplitudeContext = React.createContext<AmplitudeContextInterface>({});
const DEFAULT_AMPLITUDE_CONFIG = {
    includeUtm: true,
};

const AmplitudeProvider = ({ apiKey, amplitudeInstance, userId, children, config }: AmplitudeProviderProps): JSX.Element => {
    console.info('Pool Platform', 'Analytics Enabled!');

    const [userProps, setUserProps] = useState<any>(null);
    const { webEngage } = useWebEngage();
    const userDetails = getProfile();

    useEffect(() => {
        if (amplitudeInstance) {
            amplitudeInstance.init(apiKey, userId || null, { ...DEFAULT_AMPLITUDE_CONFIG, ...config });
            window.amplitudeInstance = amplitudeInstance;

            // Init Amplitude Service
            new AmplitudeService(userProps);

            if (userProps) {
                amplitudeInstance.setUserProperties(userProps);
            }
        }
    }, [amplitudeInstance, userProps, apiKey, userId, config]);

    useEffect(() => {
        if (userId) {
            const idToken = Auth.getIdToken();
            const entityDetails = Auth.getActiveUserGroup();
            const { entity_id: entityId = null, group = null, subGroup = null, role = null, name: entityName = null } = entityDetails || {};
            const { name: userName = '' } = getProfile();
            const userEmail = Auth.getUserEmail(idToken);

            const mUserProps = {
                entityId,
                entityName,
                role,
                group,
                subGroup,
                userEmail,
                userName,
                isInternal: checkInternalUserEmail(userEmail),
            };
            setUserProps(mUserProps);
        }
    }, [userId]);

    useEffect(() => {
        if (userDetails?.caUserId) {
            const entityDetails = userDetails?.allEntities?.find((entityData: any) => entityData?.entityId === userDetails?.entityId);
            webEngage?.user?.login(userDetails?.caUserId);

            webEngage?.user?.setAttribute('entityId', userDetails?.entityId);
            webEngage?.user?.setAttribute('userEmail', userDetails?.email);
            webEngage?.user?.setAttribute('userName', userDetails?.name);
            webEngage?.user?.setAttribute('entityName', entityDetails?.companyName);
        }
    }, [userDetails?.caUserId]);

    return <AmplitudeContext.Provider value={{ amplitude: amplitudeInstance, userProps }}>{children}</AmplitudeContext.Provider>;
};

export default AmplitudeProvider;
