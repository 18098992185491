import { TRANS_LIST_TYPE } from './Routes';

export const PRODUCT = 'admin';
export const CLIENT = 'client';
export const RELEASE = 'release';
export const COUNTERPARTY = 'counterparty';
export const LOGIN_REQUIRED = 'login_required';
export const CONSENT_REQUIRED = 'consent_required';
export const INVALID_GRANT = 'invalid_grant';
export const TIMEOUT = 'timeout';

export const PRODUCT_ID = 'CRDPL';
export const PRODUCT_ID_PLATFORMS = 'USRPTF';
export const PRODUCT_ID_CREDIT = 'CRA';
export const PRODUCT_ID_LOANS = 'LOANS';
export const PRODUCT_ID_COLLECTIONS = 'COLLECTIONS';
export const PRODUCT_CATEGORY = {
    pools: 'pools',
    loans: 'loans',
    bonds: 'bonds',
    primaryBonds: 'primary_bonds',
};

export const POOL_TYPE = {
    micro_finance: 'micro_finance',
    small_finance_bank: 'small_finance_bank',
    commercial_vehicle: 'commercial_vehicle',
    housing_finance: 'housing_finance',
    education_finance: 'education_finance',
    school_finance: 'school_finance',
    agricultural_finance: 'agricultural_finance',
    loan_against_property: 'loan_against_property',
    gold_loan: 'gold_loan',
    small_business_loan: 'small_business_loan',
    consumer_finance: 'consumer_finance',
    two_wheeler: 'two_wheeler',
    three_wheeler: 'three_wheeler',
    four_wheeler: 'four_wheeler',
    vehicle: 'vehicle',
    dairy: 'dairy',
    apparels: 'apparels',
    others: 'others',
    other_nbfc: 'other_nbfc',
    wholesale_lending: 'wholesale_lending',
};

export const LTV_POOL_TYPES = [
    POOL_TYPE.gold_loan,
    POOL_TYPE.commercial_vehicle,
    POOL_TYPE.housing_finance,
    POOL_TYPE.loan_against_property,
    POOL_TYPE.two_wheeler,
    POOL_TYPE.three_wheeler,
    POOL_TYPE.four_wheeler,
];

export const PTC_POOL_TYPE = [
    { value: POOL_TYPE.micro_finance, label: 'Micro Finance (MFI)' },
    { value: POOL_TYPE.small_finance_bank, label: 'Small Finance Bank' },
    { value: POOL_TYPE.commercial_vehicle, label: 'Commercial Vehicle' },
    { value: POOL_TYPE.housing_finance, label: 'Housing Finance' },
    { value: POOL_TYPE.education_finance, label: 'Education Finance' },
    { value: POOL_TYPE.school_finance, label: 'School Finance' },
    { value: POOL_TYPE.agricultural_finance, label: 'Agricultural Finance' },
    { value: POOL_TYPE.loan_against_property, label: 'Loan against Property' },
    { value: POOL_TYPE.gold_loan, label: 'Gold Loan' },
    { value: POOL_TYPE.small_business_loan, label: 'Small Business Loan' },
    { value: POOL_TYPE.consumer_finance, label: 'Consumer Finance' },
    { value: POOL_TYPE.two_wheeler, label: 'Two Wheeler' },
    { value: POOL_TYPE.three_wheeler, label: 'Three Wheeler' },
    { value: POOL_TYPE.four_wheeler, label: 'Four Wheeler' },
    { value: POOL_TYPE.vehicle, label: 'Vehicle' },
    { value: POOL_TYPE.dairy, label: 'Dairy' },
    { value: POOL_TYPE.apparels, label: 'Apparels' },
    { value: POOL_TYPE.others, label: 'Others' },
    { value: POOL_TYPE.wholesale_lending, label: 'Wholesale Lending' },
];

export const PTC_POOL_TYPE_ICON = {
    micro_finance: 'pool-micro',
    small_finance_bank: 'pool-micro',
    commercial_vehicle: 'pool-vehicle',
    housing_finance: 'pool-micro',
    education_finance: 'pool-micro',
    school_finance: 'pool-vehicle',
    agricultural_finance_monthly: 'pool-vehicle',
    loan_against_property: 'pool-vehicle',
    gold_loan: 'pool-vehicle',
    small_business_loan: 'pool-vehicle',
    consumer_finance: 'pool-twowheeler',
    two_wheeler: 'pool-twowheeler',
    three_wheeler: 'pool-vehicle',
    four_wheeler: 'pool-vehicle',
    vehicle: 'pool-vehicle',
    dairy: 'pool-twowheeler',
    apparels: 'pool-twowheeler',
    others: 'pool-twowheeler',
    wholesale_lending: 'pool-micro',
    unsecured_small_business_loan: 'pool-vehicle',
    agri_purpose_loans_half_yearly: 'pool-vehicle',
    agri_purpose_loans_quarterly: 'pool-vehicle',
    agri_purpose_loans_yearly: 'pool-vehicle',
};

export const MONTHS = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
];

export const SERVICER_REPORT_COLUMNS = [
    'advanceInterestCollected',
    'advancePrincipalCollected',
    'closingInterestOutstanding',
    'closingInterestOverdue',
    'closingPrincipalOutstanding',
    'closingPrincipalOverdue',
    'currentInterestDue',
    'currentPrincipalDue',
    'deathPrincipalCollected',
    'interestPrepaymentCollected',
    'loanAccountNo',
    'normalInterestCollected',
    'normalPrincipalCollected',
    'openingInterestOutstanding',
    'openingInterestOverdue',
    'openingPrincipalOutstanding',
    'openingPrincipalOverdue',
    'overdueInterestCollected',
    'overduePrincipalCollected',
    'partPrepaymentInterestCollected',
    'partPrepaymentPrincipalCollected',
    'principalPrepaymentCollected',
    'totalInterestCollected',
    'totalPrincipalCollected',
];

export const PRODUCTS = [
    { value: 'Securitization', label: 'PTC', capitalize: 'PTC' },
    { value: 'Ncd::Transaction', label: 'NCD', capitalize: 'NCD' },
    { value: 'CommercialPaper::Transaction', label: 'CP', capitalize: 'CP' },
    { value: 'TermLoan::Transaction', label: 'TL', capitalize: 'TL' },
    {
        value: 'PrefShares::Transaction',
        label: 'PREF SHARES',
        capitalize: 'Pref Shares',
    },
    { value: 'DirectAssignment::Transaction', label: 'DA', capitalize: 'DA' },
    {
        value: 'WorkingCapitalDemandLoan::Transaction',
        label: 'WCDL',
        capitalize: 'WCDL',
    },
    { value: 'PooledLoanIssuance::Transaction', label: 'PLI', capitalize: 'PLI' },
];

export const SHORT_TERM_RATING = [
    { value: 'A1+', label: 'A1+', ref: 4.1 },
    { value: 'A1', label: 'A1', ref: 4.2 },
    { value: 'A2+', label: 'A2+', ref: 4.3 },
    { value: 'A2', label: 'A2', ref: 4.4 },
    { value: 'A3+', label: 'A3+', ref: 4.5 },
    { value: 'A3', label: 'A3', ref: 4.6 },
    { value: 'A4+', label: 'A4+', ref: 4.7 },
    { value: 'A4', label: 'A4', ref: 4.8 },
];

export const LONG_TERM_RATING = [
    { value: 'AAA', label: 'AAA', ref: 1 },
    { value: 'AA+', label: 'AA+', ref: 2 },
    { value: 'AA', label: 'AA', ref: 3 },
    { value: 'AA-', label: 'AA-', ref: 4 },
    { value: 'A+', label: 'A+', ref: 5 },
    { value: 'A', label: 'A', ref: 6 },
    { value: 'A-', label: 'A-', ref: 7 },
    { value: 'BBB+', label: 'BBB+', ref: 8 },
    { value: 'BBB', label: 'BBB', ref: 9 },
    { value: 'BBB-', label: 'BBB-', ref: 10 },
    { value: 'BB+', label: 'BB+', ref: 11 },
    { value: 'BB', label: 'BB', ref: 12 },
    { value: 'BB-', label: 'BB-', ref: 13 },
    { value: 'B+', label: 'B+', ref: 14 },
    { value: 'B', label: 'B', ref: 15 },
    { value: 'B-', label: 'B-', ref: 16 },
    { value: 'C+', label: 'C+', ref: 17 },
    { value: 'C', label: 'C', ref: 18 },
    { value: 'C-', label: 'C-', ref: 19 },
    { value: 'D', label: 'D', ref: 20 },
];

export const NCD_RATING = [
    { value: 'AAA', label: 'AAA' },
    { value: 'AA+', label: 'AA+' },
    { value: 'AA', label: 'AA' },
    { value: 'AA-', label: 'AA-' },
    { value: 'A1+', label: 'A1+' },
    { value: 'A1', label: 'A1' },
    { value: 'A2+', label: 'A2+' },
    { value: 'A2', label: 'A2' },
    { value: 'A3+', label: 'A3+' },
    { value: 'A3', label: 'A3' },
    { value: 'A4+', label: 'A4+' },
    { value: 'A4', label: 'A4' },
    { value: 'A+', label: 'A+' },
    { value: 'A', label: 'A' },
    { value: 'A-', label: 'A-' },
    { value: 'BBB+', label: 'BBB+' },
    { value: 'BBB', label: 'BBB' },
    { value: 'BBB-', label: 'BBB-' },
    { value: 'BB+', label: 'BB+' },
    { value: 'BB', label: 'BB' },
    { value: 'BB-', label: 'BB-' },
    { value: 'D', label: 'D' },
];

export const LOSS_MODEL_RATING = ['AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-'];

export const RATING = [...LONG_TERM_RATING, ...SHORT_TERM_RATING];

export const SELF_CREATED = [{ value: 'true', label: 'Created by investor' }];

export const LEAN_FILTER = [
    { label: 'Lean', value: 'true' },
    { label: 'Non-Lean', value: 'false' },
];

export const PTC_STRUCTURE_TYPE = [
    { value: 'par', label: 'Par' },
    { value: 'turbopar', label: 'Turbo Par' },
    { value: 'premium', label: 'Premium' },
];

export const PROPORTION_UNITS = [
    { value: 'percent', label: 'Percent' },
    { value: 'amount', label: 'Amount' },
];

export const PTC_INVESTOR_DELAY_TYPE = [
    { value: 'time', label: 'Time' },
    { value: 'investment', label: 'Investment' },
];

export const PTC_CE_PREFERENCE = [
    { value: 'cash_collateral', label: 'Cash Collateral' },
    { value: 'bank_guarantee', label: 'Bank Guarantee' },
    { value: 'corporate_guarantee', label: 'Corporate Guarantee' },
    {
        value: 'second_loss_credit_enhancement',
        label: 'Second Loss Credit Enhancement',
    },
];

export const CLIENT_PTC_PRIORITY = [
    { value: 'pricing', label: 'Pricing' },
    { value: 'settlement_date', label: 'Settlement Date' },
    { value: 'preferred_investor', label: 'Preferred Investor' },
];

export const COURT = [
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Kolkata', label: 'Kolkata' },
];

export const SIGNING_LOCATION = [
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Kolkata', label: 'Kolkata' },
];

export const PAR_OPTIONS = [
    { value: 'par0', label: 'PAR0' },
    { value: 'par30', label: 'PAR30' },
    { value: 'par60', label: 'PAR60' },
    { value: 'par90', label: 'PAR90' },
];

export const PAR_OPTIONS_V1 = [
    { value: 'par_0', label: 'PAR0' },
    { value: 'par_30', label: 'PAR30' },
    { value: 'par_60', label: 'PAR60' },
    { value: 'par_90', label: 'PAR90' },
];

export const COLOR_SELECTOR = ['pink', 'yellow', 'green', 'purple'];

export const ACTORS = [
    { value: 'investor', label: 'Investor', type: 'external' },
    { value: 'customer', label: 'Customer', type: 'external' },
    { value: 'product', label: 'Product', type: 'internal' },
    {
        value: 'institutional_business',
        label: 'Institutional Business',
        type: 'internal',
    },
    {
        value: 'capital_markets',
        label: 'Digital Capital Markets',
        type: 'internal',
    },
    { value: 'auditor', label: 'Auditor', type: 'external' },
    { value: 'law_firm', label: 'Law Firm', type: 'external' },
    { value: 'trustee', label: 'Trustee', type: 'external' },
    { value: 'rating_agency', label: 'Rating Agency', type: 'external' },
    { value: 'operations', label: 'Operations', type: 'internal' },
    { value: 'risk', label: 'Risk', type: 'internal' },
    { value: 'treasury', label: 'Treasury', type: 'internal' },
    { value: 'credit', label: 'Credit', type: 'internal' },
    {
        value: 'client_acquisition_group',
        label: 'Client Aquisition Group',
        type: 'internal',
    },
    {
        value: 'client_service_group',
        label: 'Client Service Group',
        type: 'internal',
    },
    { value: 'collateral_manager', label: 'Collateral Manager', type: 'external' },
    { value: 'guarantor', label: 'Guarantor', type: 'external' },
    { value: 'anchor', label: 'Anchor', type: 'external' },
    { value: 'vendor', label: 'Vendor', type: 'external' },
    { value: 'internal_auditor', label: 'Internal Auditor', type: 'internal' },
];

export const INTERNAL_ACTORS = [
    { value: 'product', label: 'Product' },
    { value: 'institutional_business', label: 'Institutional Business' },
    { value: 'capital_markets', label: 'Digital Capital Markets' },
    { value: 'operations', label: 'Operations' },
    { value: 'risk', label: 'Risk' },
    { value: 'treasury', label: 'Treasury' },
    { value: 'credit', label: 'Credit' },
    { value: 'client_acquisition_group', label: 'Client Aquisition Group' },
    { value: 'client_service_group', label: 'Client Service Group' },
    { value: 'internal_auditor', label: 'Internal Auditor' },
];
export const INTERNAL_ACTORS_VALUES = INTERNAL_ACTORS.map((v) => v.value);

export const EXTERNAL_ACTORS = [
    { value: 'investor', label: 'Investor' },
    { value: 'customer', label: 'Customer' },
    { value: 'auditor', label: 'Auditor' },
    { value: 'law_firm', label: 'Law Firm' },
    { value: 'trustee', label: 'Trustee' },
    { value: 'rating_agency', label: 'Rating Agency' },
    { value: 'guarantor', label: 'Guarantor' },
    { value: 'anchor', label: 'Anchor' },
    { value: 'vendor', label: 'Vendor' },
    { value: 'collateral_manager', label: 'Collateral Manager' },
];

export const ROLES = [
    { value: 'admin', label: 'Admin', for: 'admin' },
    { value: 'analytics', label: 'Analytics', for: 'rating_agency' },
    {
        value: 'biz_development',
        label: 'Business Development',
        for: 'rating_agency',
    },
];

export const ENTITY_TYPES = [
    { value: 'banks', label: 'Banks' },
    { value: 'nbfc', label: 'NBFC' },
    { value: 'small_finance_bank', label: 'Small Finance Bank' },
    { value: 'dfi', label: 'DFI' },
    { value: 'impact_fund_manager', label: 'Impact Fund Manager' },
    { value: 'impact_asset_manager', label: 'Impact Asset Manager' },
    { value: 'hfc', label: 'HFC' },
    { value: 'mutual_fund', label: 'Mutual Fund' },
    { value: 'private_wealth_house', label: 'Private Wealth House' },
    { value: 'hni', label: 'HNI' },
    { value: 'insurance_companies', label: 'Insurance Companies' },
    { value: 'pension_funds', label: 'Pension Funds' },
];

export const ENTITY_TYPES_BANKS = [
    { label: 'Private Banks', value: 'private_banks' },
    { label: 'PSU Banks', value: 'psu_banks' },
];

export const ENTITY_TYPES_DFI = [
    { label: 'Registered FPI', value: 'registered_fpi' },
    { label: 'ECB Lender', value: 'ecb_lender' },
];

export const REPAYMENT_FREQUENCY = [
    { value: 'fortnightly', label: 'Fornightly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'weekly', label: 'Weekly' },
];

export const REPAYMENT_STYLE = [
    { value: 'row', label: 'Row wise' },
    { value: 'column', label: 'Column wise' },
];

export const REPAYMENT_PATTERN = [
    { value: 'match_header', label: 'Match Header' },
    { value: 'column_frequency', label: 'Column Frequency' },
];
export const DATES_REPAYMENT_PATTERN = [
    { value: 'match_header', label: 'Match Header' },
    { value: 'column_frequency', label: 'Column Frequency' },
    { value: 'sequential', label: 'Sequential' },
    { value: 'fetch_from_header', label: 'Fetch from Header' },
];
export const DATE_PATTERN_OPTIONS = [
    { value: 'loan_specific_schedule', label: 'Loan Specific Schedule' },
    { value: 'common_schedule', label: 'Common Schedule' },
];

export const DATE_SELECTION_PATTERN = [
    { value: 'sequence', label: 'Sequence' },
    { value: 'fetch_from_header', label: 'Pick from Header' },
];

export const YEARS = [
    { value: 2014, label: '2014' },
    { value: 2015, label: '2015' },
    { value: 2016, label: '2016' },
    { value: 2017, label: '2017' },
    { value: 2018, label: '2018' },
    { value: 2019, label: '2019' },
    { value: 2020, label: '2020' },
    { value: 2021, label: '2021' },
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
];

export const FD_PERIOD = [
    { value: 'cleanup_call', label: 'Clean up call' },
    { value: 'maturity', label: 'Maturity' },
];

export const DRAWDOWN_STATUS = {
    INITIATED: 'initiated',
    APPROVED: 'approved',
    SIGNED: 'signed',
    DECLINED: 'declined',
    DISBURSED: 'disbursed',
    MATURED: 'matured',
    DEFAULTED: 'defaulted',
    PRECLOSED: 'preclosed',
    REJECTED: 'rejected',
};

export const TRANSACTION_STATE = {
    FINALIZED: 'finalized',
    DRAFT: 'draft',
    SUMMARY_ACCEPTED: 'summary_accepted',
    RELEASED: 'released',
    SETTLED: 'settled',
    SANCTIONED: 'sanctioned',
    MATURED: 'matured',
    DISBURSED: 'disbursed',
    OPEN: 'open',
    ARCHIVED: 'archived',
};

export const PRODUCT_API_ROUTES = {
    ptc: 'securitizations',
    da: 'direct_assignments',
    dav2: 'assignments',
    cp: 'commercial_papers',
    ncd: 'ncds',
    ps: 'pref_shares',
    tl: 'term_loans',
    wc: 'working_capital_demand_loans',
    ptcv1: 'ptcs',
    pli: 'pooled_loan_issuances',
    ncdv2: 'ncdv2',
};

export const PRODUCT_KEYS = {
    securitization: 'ptc',
    ptc: 'ptcv1',
    direct_assignment: 'da',
    assignment: 'dav2',
    commercial_paper: 'cp',
    ncd: 'ncd',
    ncdv2: 'ncdv2',
    pref_shares: 'ps',
    term_loan: 'tl',
    working_capital_demand_loan: 'wcdl',
    pooled_loan_issuance: 'pli',
    //newly added products
    bank_guarantee: 'bg',
    cash_credit: 'cc',
    receivable_assignment: `ra`,
    factoring: 'factoring',
    pack_credit_foreign_currency: 'pcfc',
    export_packing_credit: 'epc',
    letter_of_credit: 'lc',
    short_term_loan: 'stl',
    overdraft: 'od',
    discounting: 'discounting',
};

export const NEW_PRODUCTS = ['bg', 'cc', `ra`, 'factoring', 'pcfc', 'epc', 'lc', 'stl', 'od', 'discounting'];
export const OLD_PRODUCTS = ['term_loans', 'working_capital_demand_loans'];

export const ORIGINATOR_INVESTOR_ACCOUNTS = ['investor_account', 'originator_account'];

export const EF_ONLY_PRODUCTS = [
    PRODUCT_KEYS.bank_guarantee,
    PRODUCT_KEYS.cash_credit,
    PRODUCT_KEYS.receivable_assignment,
    PRODUCT_KEYS.factoring,
    PRODUCT_KEYS.pack_credit_foreign_currency,
    PRODUCT_KEYS.export_packing_credit,
    PRODUCT_KEYS.letter_of_credit,
    PRODUCT_KEYS.short_term_loan,
    PRODUCT_KEYS.overdraft,
    PRODUCT_KEYS.discounting,
];
export const EF_CLIENTS = [
    PRODUCT_KEYS.bank_guarantee,
    PRODUCT_KEYS.cash_credit,
    PRODUCT_KEYS.receivable_assignment,
    PRODUCT_KEYS.factoring,
    PRODUCT_KEYS.pack_credit_foreign_currency,
    PRODUCT_KEYS.export_packing_credit,
    PRODUCT_KEYS.letter_of_credit,
    PRODUCT_KEYS.short_term_loan,
    PRODUCT_KEYS.overdraft,
    PRODUCT_KEYS.discounting,
];

export const IDLE_TIMEOUT_MSG = "You're being timed out due to inactivity. Please login again to continue";

export const DATES = {
    formatOne: 'D-MMM-YYYY',
    formatTwo: 'DD-MMM-YYYY',
    formatThree: 'D MMM, YYYY',
    formatFour: 'MMM D, YYYY',
};

export const DRAWDOWN_WARNING_ERROR_MSG = 'Interest repayment frequency should be smaller than or equal to principal repayment frequency';

export const USER_TYPES = {
    ADMIN: 'admin',
    PRODUCT: 'product',
    OPERATIONS: 'operations',
    CLIENT: 'client',
    CUSTOMER: 'customer',
    INTERNAL: 'internal',
    CPS: 'cps',
    INVESTOR: 'investor',
    AUDITOR: 'auditor',
    TREASURY: 'treasury',
    TRUSTEE: 'trustee',
    LAW_FIRM: 'law_firm',
    RATING_AGENCY: 'rating_agency',
    CLIENT_ACQUISITION_GROUP: 'client_acquisition_group',
    CLIENT_SERVICE_GROUP: 'client_service_group',
    CREDIT: 'credit',
    INSTITUTIONAL_BUSINESS: 'institutional_business',
    CAPITAL_MARKETS: 'capital_markets',
    RISK: 'risk',
    GUARANTOR: 'guarantor',
    COLLATERAL_MANAGER: 'collateral_manager',
    INTERNAL_AUDITOR: 'internal_auditor',
    ARRANGER: 'arranger',
    MAKER: 'psl_operations_–_maker',
    CHECKER: 'psl_operations_–_checker',
    RELATIONSHIP_MANAGER: 'relationship_managers_role',
    LENDER: 'lender',
    COLLECTION_AGENCY: 'collection_agency',
    MAKER_NEW: 'operations_maker',
    CHECKER_NEW: 'operations_checker',
};

export const NACH_MANDATE_FILETYPE = 'signed_nach_mandate_form';
export const SIGNED_DRAWDOWN_FILETYPE = 'executed_drawdown_request_form';
export const DRAWDOWN_REQUEST_FILETYPE = 'drawdown_request_form';
export const TOKEN_ENTITY_ID = '/entity_id';
export const TOKEN_LOCAL_USER_ID = '/local_user_id';

export const DOCUMENTS_COLLAB_ACCESS = [USER_TYPES.CUSTOMER, USER_TYPES.INVESTOR, USER_TYPES.LAW_FIRM, USER_TYPES.CLIENT_SERVICE_GROUP];
export const NCD_DOCUMENTS_COLLAB_ACCESS = [...DOCUMENTS_COLLAB_ACCESS, USER_TYPES.TRUSTEE];
export const PLI_DOCUMENTS_COLLAB_ACCESS = [...DOCUMENTS_COLLAB_ACCESS, USER_TYPES.TRUSTEE, USER_TYPES.GUARANTOR];

export const DOCUMENTS_DOWNLOAD_ACCESS = [
    USER_TYPES.CUSTOMER,
    USER_TYPES.INVESTOR,
    USER_TYPES.LAW_FIRM,
    USER_TYPES.RATING_AGENCY,
    USER_TYPES.TRUSTEE,
    USER_TYPES.AUDITOR,
    USER_TYPES.PRODUCT,
    USER_TYPES.OPERATIONS,
    USER_TYPES.GUARANTOR,
    USER_TYPES.CLIENT_ACQUISITION_GROUP,
    USER_TYPES.COLLATERAL_MANAGER,
];
export const DOCUMENTS_DOWNLOAD_ACCESS_SETTLED = [
    USER_TYPES.CLIENT_ACQUISITION_GROUP,
    USER_TYPES.CLIENT_SERVICE_GROUP,
    USER_TYPES.RISK,
    USER_TYPES.TREASURY,
    USER_TYPES.INSTITUTIONAL_BUSINESS,
    USER_TYPES.CREDIT,
    USER_TYPES.CAPITAL_MARKETS,
];
export const FILE_TYPE_CLASS = {
    'icon-pdf-file-format-symbol': ['pdf'],
    'icon-xlsx-file-format': ['xlsx', 'xls', 'csv'],
    'icon-docx-file-format': ['doc', 'docx'],
    'icon-image-icon': ['png', 'jpg', 'jpeg', 'svg'],
    'icon-zip': ['zip', 'rar'],
    'icon-ppt-file-format': ['ppt', 'pptx'],
};
export const FILE_TYPE_ICONS = {
    'icon-new-xls-file': ['xlsx', 'xls'],
    'icon-new-doc-file': ['doc', 'docx'],
    'icon-csv-file': ['csv'],
    'icon-txt-file': ['txt'],
};

export const GROUPS = {
    PRODUCT: 'product',
    INVESTOR: 'investor',
    CLIENT: 'customer',
    CAG: 'client_acquisition_group',
    RISK: 'risk',
    TREASURY: 'treasury',
    VENDOR: 'vendor',
    COLLATERAL_MANAGER: 'collateral_manager',
    ANCHOR: 'anchor',
    RETAIL_USER: 'retail_user',
    CONNECTOR: 'connector',

    CAPITAL_MARKETS: 'capital_markets',
    OPERATIONS: 'operations',
    CREDIT: 'credit',
    CLIENT_SERVICE_GROUP: 'client_service_group',
    CREDIT_COMMITTEE: 'credit_committee',
    INTERNAL_AUDITOR: 'internal_auditor',
    CUSTOMER: 'customer',
    TRUSTEE: 'trustee',
    REGISTRAR: 'registrar',
};

export const ROLE = {
    ADMIN: 'admin',
    ASSOCIATE: 'associate',
    CONNECTOR: 'connector',
};

export const FILE_STATUS = {
    COMPLETED: 'completed',
    APPROVED: 'approved',
    INPROGRESS: 'inprogress',
    FAILED: 'failed',
    GENERATED: 'generated',
    GENERATING: 'generating',
    SUCCEEDED: 'succeeded',
    PROCESSING: 'processing',
};

export const LOSS_MODELLING_STATUS = {
    PROCESSING: 'processing',
    INPROGRESS: 'inprogress',
    PENDING: 'pending',
    SUCCESS: 'success',
    COMPLETED: 'completed',
    FAILED: 'failed',
    VALIDATION_FAILED: 'validation_failed',
};

export const BANK_TYPE_CONSTANT = ['SB', 'CA', 'CC', 'SB-NRE', 'SB-NRO', 'OTHER'];

export const INVESTOR_TYPES = [
    { label: 'Individual', value: 'INDIVIDUAL', key: 'individual' },
    { label: 'Bank', value: 'BANK', key: 'bank' },
    { label: 'Mutual Fund', value: 'MF', key: 'mf' },
    { label: 'Family Office', value: 'FAMILY-OFFICE', key: 'family_office' },
    { label: 'NBFC', value: 'NBFC', key: 'nbfc' },
];

export const INVESTOR_INVESTMENT_SURPLUS = [
    { label: '25 Lakhs', value: '25 Lakhs' },
    { label: '25 - 50 Lakhs', value: '25 - 50 Lakhs' },
    { label: '50 Lakhs - 1 Crore', value: '50 Lakhs - 1 Crore' },
    { label: 'Above 1 Crore', value: 'Above 1 Crore' },
];

export const BID_INTEREST_METHODS = [
    { label: 'XIRR', value: 'XIRR' },
    { label: 'Coupon', value: 'Coupon' },
];

export const WATERFALL_STATUS = {
    UPLOADED: 'uploaded',
    MAPPED: 'mapped',
    PROCESSED: 'processed',
    FAILED: 'failed',
};

export const RS_STATUS = {
    LOADING: 'loading',
    LOADED: 'loaded',
    PROCESSING: 'processing',
    PROCESSED: 'processed',
    ERROR: 'error',
    FAILED: 'failed',
};

export const DA_WATERFALL_FIELDS = [
    'assignee_principal_paid_basis_ce_column',
    'assignee_principal_paid_column',
    'assignee_interest_paid_basis_ce_column',
    'assignee_interest_paid_column',
    'assignor_principal_paid_basis_ce_column',
    'assignor_principal_paid_column',
    'assignor_interest_paid_basis_ce_column',
    'assignor_interest_paid_column',
];

export const ENVIRONMENTS = {
    QA: 'qa',
    STAGING: 'staging',
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    FEATURE: 'feature',
};

export const BLACK_LISTED_EMAIL_SUBDOMAINS = [
    '.*@gmail.com',
    '.*@yahoo.com',
    '.*@live.com',
    '.*@hotmail.com',
    '.*@outlook.com',
    '.*@popmail.com',
    '.*@protonmail.com',
    '.*@yopmail.com',
];
export const MODAL_STATE = {
    OPEN: 'open',
    CONFIRM: 'confirm',
    CANCEL: 'cancel',
};
export const NETWORK_CALL_ABORTED = 'CANCELLED';

export const RBAC_USERS = [USER_TYPES.CUSTOMER, USER_TYPES.INVESTOR, USER_TYPES.PRODUCT, USER_TYPES.LENDER, USER_TYPES.COLLECTION_AGENCY];

export const CRA_RBAC_USERS = [USER_TYPES.CUSTOMER, USER_TYPES.INVESTOR];

export const USER_MANAGEMENT_ACCESS_DISABLED_ACTORS = [
    USER_TYPES.INVESTOR,
    USER_TYPES.ANCHOR,
    USER_TYPES.GUARANTOR,
    USER_TYPES.AUDITOR,
    USER_TYPES.RATING_AGENCY,
    USER_TYPES.LAW_FIRM,
    USER_TYPES.COLLATERAL_MANAGER,
    USER_TYPES.VENDOR,
    USER_TYPES.INSTITUTIONAL_BUSINESS,
];

export const USER_MANAGEMENT_ACCESS_ENABLED_GROUPS = [
    GROUPS.PRODUCT,
    GROUPS.CAPITAL_MARKETS,
    GROUPS.OPERATIONS,
    GROUPS.RISK,
    GROUPS.TREASURY,
    GROUPS.CREDIT,
    GROUPS.CAG,
    GROUPS.CLIENT_SERVICE_GROUP,
    GROUPS.INTERNAL_AUDITOR,
    GROUPS.CUSTOMER,
    GROUPS.TRUSTEE,
    GROUPS.REGISTRAR,
    GROUPS.CREDIT_COMMITTEE,
];

export const DRAFT_TRANSACTIONS_LIMIT = 2000;

export const DRAFT_TRANSACTIONS_LIMIT_MSG =
    'For maintaining our platform performance  we have decided to restrict the number of draft transactions in the system to 2000. Please delete some of the redundant Draft transactions to proceed';

export const INVESTOR_ENTITY_IDS = {
    CREATION_INVESTMENTS: '5f859ccc8233220f2bcdaa63',
};

export const SIDEBAR_ROUTES = [];

export const ACCEPTED_FILE_TYPES = {
    DOC: '.doc',
    DOCX: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    EXCEL: 'application/vnd.ms-excel, application/excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PDF: 'application/pdf, .pdf',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    CSV: 'text/comma-separated-values, text/csv',
    ZIP: 'application/zip',
};

export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOC_TYPE = 'application/msword';

export const TL_COUNTERPARTIES = [
    { value: USER_TYPES.INVESTOR, label: 'Investor' },
    { value: USER_TYPES.CUSTOMER, label: 'Customer' },
    { value: USER_TYPES.AUDITOR, label: 'Auditor' },
    { value: USER_TYPES.LAW_FIRM, label: 'Law Firm' },
    { value: USER_TYPES.TRUSTEE, label: 'Trustee' },
    { value: USER_TYPES.RATING_AGENCY, label: 'Rating Agency' },
];

export const NOTIFICATION_CHANNELS = {
    EMAIL: 'email',
    TRANSACTION_RELATED: 'transaction_related',
};

export const INTERNAL_USER_EMAIL_DOMAIN = 'go-yubi.com';
export const INTERNAL_SECURITIES_USER_EMAIL_DOMAIN = 'yubisecurities.com';
export const INTERNAL_CORPOSITORY_EMAIL_DOMAIN = 'corpository.com';
export const INTERNAL_SPOCTO_EMAIL_DOMAIN = 'spocto.com';

export const ASSET_VERIFICATION_FILE = 'ekyc_verification_files';

export const STC_HELPER_TEXT = `
<div class="notify-message">
    <div class="text-ctn">
        <p><i class="icon-tick2"></i>Verified.</p>
    </div>
    <div class="sub-text-ctn">
    <ul>
        <li>&bull;&nbsp;All Loans in the pool are from the same asset class</li>
        <li>&bull;&nbsp;No Loans in the pool are Restructured</li>
        <li>&bull;&nbsp;No Loans in the pool are under Moratorium</li>
        <li>&bull;&nbsp;All loans in the Pools contain a Bureau Score</li>
        <li>&bull;&nbsp;Maximum Obligor Concentration capped at 1-2% as per New Securitization Guidelines</li>
    </ul>
</div>`;

export const PRODUCT_API_UPDATE_KEYS = {
    ptcv1: 'ptcTransaction',
    ptc: 'securitization',
    dav2: 'assignmentTransaction',
    da: 'directAssignmentTransaction',
};

export const INVESTOR_CONSTANTS = {
    IOB: 'iob',
};

export const ASSET_CLASSES_WITH_DESC = [
    { value: 'micro_finance', label: 'Micro Finance', desc: 'Micro credits for low-income individuals excluded from traditional banking' },
    { value: 'commercial_vehicle', label: 'Commercial Vehicle Finance', desc: 'Finance Funding for the purchase of commercial vehicles' },
    { value: 'loan_against_property', label: 'Loan Against Property', desc: 'Secured loan against a commerical or residential property' },
    {
        value: 'agricultural_finance_monthly',
        label: 'Agricultural Finance (Monthly)',
        desc: 'Financing of any loan that can be classified as Agriculture Loan as per RBI guidelines',
    },
    {
        value: 'unsecured_small_business_loan',
        label: 'Unsecured Small Business Loans',
        desc: 'Unsecured credit for planned and unplanned business expense',
    },
    { value: 'housing_finance', label: 'Housing Finance', desc: 'Financing the purchase, construction or renovation of a house' },
    {
        value: 'two_wheeler',
        label: 'Two Wheeler Finance',
        desc: "Financing a customer's two-wheeler purchase at low interests on monthly EMIs",
    },
    {
        value: 'school_finance',
        label: 'School Finance',
        desc: 'Funding the operations of a school including wages, maintenance and materials',
    },
    { value: 'consumer_finance', label: 'Consumer Finance', desc: 'Short-term credit for a wide range of commercial or household purposes' },
    { value: 'gold_loan', label: 'Gold Loans', desc: 'Loan against gold ornaments or jewelry' },
    { value: 'small_business_loan', label: 'Small Business Loans', desc: 'Instant credit for planned and unplanned business expenses' },
    {
        value: 'tractors_and_farm_equipment',
        label: 'Tractors And Farm Equipment',
        desc: 'Financing the purchase of tractors and farm equipment',
    },
    {
        value: 'agri_purpose_loans_half_yearly',
        label: 'Agri Purpose Loans (Half yearly)',
        desc: 'Financing the purchase of tractors and farm equipment',
    },
    {
        value: 'agri_purpose_loans_quarterly',
        label: 'Agri Purpose Loans (Quarterly)',
        desc: 'Financing the purchase of tractors and farm equipment',
    },
    {
        value: 'agri_purpose_loans_yearly',
        label: 'Agri Purpose Loans (Yearly)',
        desc: 'Financing the purchase of tractors and farm equipment',
    },
    {
        value: 'personal_loans',
        label: 'Personal Loans',
        desc: 'Unsecured loan for planned or unplanned personal expenses',
    },
    {
        value: 'education_loans',
        label: 'Education Loans',
        desc: 'Loans to students pursuing higher education in India or abroad',
    },
    {
        value: 'home_loan_insurance',
        label: 'Home Loan Insurance',
        desc: 'Insurance associated with home loans',
    },
    {
        value: 'auto_loan',
        label: 'Auto Loan',
        desc: 'Loans for automotives',
    },
    {
        value: 'multi_asset',
        label: 'Multi Asset',
        desc: 'Combination of multiple asset class loans',
    },
];

export const PRODUCT_VALUES = {
    ptc: { apiEndpoint: 'ptcs', apiKey: 'ptc_transaction', label: 'Pass-through certificate' },
    da: { apiEndpoint: 'assignments', apiKey: 'assignment_transaction', label: 'Direct assignment' },
};

export const PRODUCTS_BE_KEYS = {
    Securitization: { label: 'PTC', type: 'ptc', apiKey: 'securitizations' },
    'DirectAssignment::Transaction': { label: 'DA', type: 'da', apiKey: 'direct_assignments' },
    'Ptc::Transaction': { label: 'PTC', type: 'ptcv1', apiKey: 'ptcs' },
    'Assignment::Transaction': { label: 'DA', type: 'dav2', apiKey: 'assignments' },
};

export const NO_FOOTER_ROUTES = ['initiate/lean', 'mcd-mapping'];

export const YUBI_DOMAIN = 'go-yubi';
export const CREDAVENUE_DOMAIN = 'credavenue';
export const YUBI_COOKIE_CHECK_URL = `https://ca-yubi.go-yubi.com/cayubi/checkpage.html`;
export const YUBI_TEST_DOMAIN = `https://ca-yubi.go-yubi.com/cayubi/ping.json`;

export const IFSC_CODE_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export const CIN_REGEX = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

export const PAN_REGEX = /[A-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[A-Z]{1}$/;

export const PAN_REGEX_FOR_CORPORATE = /[A-Z]{3}[PCFT]{1}[a-zA-Z]{1}[0-9]{4}[A-Z]{1}$/;

export const MAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const MOBILE_NUMBER_REGEX = /^\d{10}$/;
