
const createActionName = (name: string) => `app/oms/orders/${name}`;

export const UPDATE_ORDERSCOUNT = createActionName('UPDATE_ORDERSCOUNT');

export interface OrderDataState {
    isFetching: boolean;
    orderData: {
        pendingOrderCount: number;
    };
}