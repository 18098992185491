import _get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';
import { getSectorTransaction } from 'app/reducers/dashboard/portfolio';
import { createLoadingSelector } from 'app/reducers/loading';

const initialState = {
    summary: {},
    portfolio: [],
};

// actions
const createActionName = (name: string) => `app/dashboard/${name}`;

export const SUMMARY_DATA_REQUEST = createActionName('SUMMARY_DATA_REQUEST');
export const SUMMARY_DATA_SUCCESS = createActionName('SUMMARY_DATA_SUCCESS');
export const SUMMARY_DATA_FAILURE = createActionName('SUMMARY_DATA_FAILURE');

export const PORTFOLIO_DATA_REQUEST = createActionName('PORTFOLIO_DATA_REQUEST');
export const PORTFOLIO_DATA_SUCCESS = createActionName('PORTFOLIO_DATA_SUCCESS');
export const PORTFOLIO_DATA_FAILURE = createActionName('PORTFOLIO_DATA_FAILURE');

export const SUMMARY_LOADING = createLoadingSelector([createActionName('SUMMARY_DATA')]);

// reducer
export default function dashboard(state: any = initialState, action: any) {
    switch (action.type) {
        case SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                summary: action.summary,
                portfolio: action.portfolio,
            };
        case PORTFOLIO_DATA_SUCCESS:
            return {
                ...state,
                portfolio: action.portfolio,
            };
        default:
            return state;
    }
}

export function getSummaryData(isPortfolio = false, query: any = {}) {
    return (dispatch: any) => {
        dispatch({ type: SUMMARY_DATA_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/investor_dashboard_stats`)
            .then((d) => humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({
                    type: SUMMARY_DATA_SUCCESS,
                    summary: d.investorDashboardStats.summary,
                    portfolio: d.investorDashboardStats.portfolio,
                });
                if (isPortfolio) {
                    d.investorDashboardStats.portfolio.sector.forEach((sector: any) => {
                        dispatch(
                            getSectorTransaction(sector.name, {
                                ...query,
                                filter_params: [...(_get(query, 'filter_params') ? query.filter_params : {})],
                            }),
                        );
                    });
                }
            })
            .catch((ex) => {
                dispatch({ type: SUMMARY_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving Summary data', m);
                });
            });
    };
}

export function getPortfolioData() {
    return (dispatch: any) => {
        dispatch({ type: PORTFOLIO_DATA_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/statistics`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: PORTFOLIO_DATA_SUCCESS, portfolio: d }))
            .catch((ex) => {
                dispatch({ type: PORTFOLIO_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving Portfolio data', m);
                });
            });
    };
}
