export const CLIENT_DEFAULT = '/client';
export const CLIENT_HOME = '/home';
export const CLIENT_DASHBOARD = '/dashboard';
export const CLIENT_ABS_DASHBOARD = '/abs-dashboard';
export const CLIENT_PORTFOLIO_MONITORING = '/portfolio-monitoring';
export const CLIENT_SECTOR_ANALYSIS = '/sector-analysis';
export const CLIENT_INIT_TRANSACTION_OLD = '/client/initiate';
export const CLIENT_INIT_TRANSACTION = '/initiate';
export const CLIENT_INIT_TRANSACTION_PTC_POOL = '/initiate/ptc';
export const CLIENT_INIT_TRANSACTION_PTC_SUMMARY = '/initiate/ptc/:transId/summary';
export const CLIENT_INIT_TRANSACTION_PTC_PREF = '/initiate/ptc/:transId/pref';
export const CLIENT_INIT_TRANSACTION_PTC_SUCCESS = '/initiate/ptc/:transId/success';

export const CLIENT_POOL_LIST = '/pools';
export const CLIENT_POOL_DETAILS_MCD = '/pool/mcd/:poolId/';
export const CLIENT_POOL_DETAILS_RS = '/pool/rs/:poolId/';

export const CLIENT_INIT_POOL = '/initiate/pool';
export const CLIENT_MCD_MAPPING = '/initiate/pool/:poolId/mcd-mapping';
export const CLIENT_RS_MAPPING = '/initiate/pool/:poolId/rs-mcd';
export const CLIENT_POOL_PREF = '/initiate/pool/:poolId/pref';
export const CLIENT_POOL_UPLOAD_SUCCESS = '/initiate/pool/:fileType/success/:poolId';

export const CLIENT_TRANS_LIST = '/:type/transactions';
export const CLIENT_TRANS_DETAILS = '/:product/:transId';
export const CLIENT_SERVICER_REPORT = '/:product/:transId/servicer-report';
export const CLIENT_OVERALL_SERVICER_REPORT = '/servicer-report';
export const CLIENT_REPORTS_LISTS = '/reports';
export const CLIENT_REPORTS_DETAILS = '/reports/:reportType/details';

export const CLIENT_ENTITY_USER_ADD = '/user';

export const CLIENT_INIT_TRANSACTION_PTC = '/initiate/ptc';
export const CLIENT_PTC_TASKS_STATUS = '/ptc/:transId/task-status';
export const CLIENT_PTC_TRANS_DETAILS = '/ptc/:transId/details';
export const CLIENT_PTC_TRANS_POOLFILES = '/ptc/:transId/poolfile';
export const CLIENT_PTC_SERVICER_REPORT = '/ptc/:transId/servicer-report';
export const CLIENT_PTC_TASKS_REPORTER = '/ptc/:transId/task-report';
export const CLIENT_PTC_TASK_GROUP = '/ptc/:transId/task/:altGroupName';
export const CLIENT_PTC_TRANS_TASKS_REPORTER = '/ptc/:transId/task-report';
export const CLIENT_PTC_TRANS = '/ptc/:transId';
export const CLIENT_PTC_COSTING = '/ptc/:transId/costing';
export const CLIENT_PTC_SIGNING_INSTRUCTION = '/ptc/:transId/signing-instruction';
export const CLIENT_PTC_INVESTMENT_NOTE = '/ptc/:transId/investment-note';

export const CLIENT_PTC_DEAL_PREF = '/ptc/:transId/deal-performance';

export const CLIENT_PTC_CASHFLOW = '/ptc/:transId/waterfall';
// PTC V1 Routes
export const CLIENT_PTC_V1_TRANS = '/ptcv1/:transId';
export const CLIENT_PTC_V1_FILES = '/ptcv1/:transId/documents';
export const CLIENT_PTC_V1_DEAL_PREF = '/ptcv1/:transId/deal-performance';
export const CLIENT_PTC_V1_TASK_GROUP = '/ptcv1/:transId/task/:altGroupName';
export const CLIENT_PTC_V1_TASKS_REPORTER = '/ptcv1/:transId/task-report';
export const CLIENT_PTC_V1_TASKS_STATUS = '/ptcv1/:transId/task-status';
export const CLIENT_PTC_V1_SERVICER_REPORT = '/ptcv1/:transId/servicer-report';
export const CLIENT_PTC_V1_SIGNING_INSTRUCTION = '/ptcv1/:transId/signing-instruction';
export const CLIENT_PTC_V1_INVESTMENT_NOTE = '/ptcv1/:transId/investment-note';
export const CLIENT_PTC_V1_DOCUMENTS = '/ptcv1/:transId/documents-view';
export const CLIENT_PTC_V1_ATTACHMENTS = '/ptcv1/:transId/attachments';
export const CLIENT_PTCV1_CASHFLOW = '/ptcv1/:transId/waterfall';

// DA Routes
export const CLIENT_INIT_TRANSACTION_DA = '/initiate/da';
export const CLIENT_INIT_TRANSACTION_DA_MCDUPLOAD = '/initiate/da/:transId/mcd-upload';
export const CLIENT_INIT_TRANSACTION_DA_RSUPLOAD = '/initiate/da/:transId/rs-upload';
export const CLIENT_EDIT_TRANSACTION_DA_MCDUPLOAD = '/edit/da/:transId/mcd-upload';
export const CLIENT_EDIT_TRANSACTION_DA_RSUPLOAD = '/edit/da/:transId/rs-upload';
export const CLIENT_INIT_TRANSACTION_DA_SUCCESS = '/initiate/da/:transId/success';
export const CLIENT_TRANS_DA_DETAILS = '/da/:transId/details';
export const CLIENT_DA_TRANS = '/da/:transId';
export const CLIENT_DA_TASKS_STATUS = '/da/:transId/task-status';
export const CLIENT_DA_TASKS_REPORTER = '/da/:transId/task-report';
export const CLIENT_DA_TASK_GROUP = '/da/:transId/task/:altGroupName';
export const CLIENT_DA_SERVICER_REPORT = '/da/:transId/servicer-report';
export const CLIENT_DA_DEAL_PREF = '/da/:transId/deal-performance';
export const CLIENT_DA_DOCUMENTS_VIEW = '/da/:transId/documents-view';
export const CLIENT_DA_CASHFLOW = '/da/:transId/waterfall';

export const CLIENT_ONBOARDING = `${process.env.REACT_APP_CS_HOST}/client/:entityId/home`;
export const CLIENT_EXPRESS_INTEREST = `${process.env.REACT_APP_CS_HOST}/client/:entityId/express-interest`;

export const CLIENT_DOCUMENT_COLLABORATOR_DA = '/da/:transId/documents/:docId/editor';
export const CLIENT_DOCUMENT_COLLABORATOR_PTC = '/ptc/:transId/documents/:docId/editor';
export const CLIENT_DOCUMENT_COLLABORATOR_PTC_V1 = '/ptcv1/:transId/documents/:docId/editor';

export const CLIENT_INIT_TRANSACTION_LEAN = '/initiate/lean';
export const CLIENT_INIT_TRANSACTION_LEAN_SUCCESS = '/initiate/:productType/:transId/success';
