import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PasswordErrorStateComponent = ({ errors, onErrPopupClose }) => (
    <div className={classNames('password-info')}>
        <p>Password criteria: </p>
        <ul className="clearfix password-info-detail">
            <li
                className={classNames({
                    success: errors.isPasswordLength === 2,
                    pending: errors.isPasswordLength === 1,
                })}
            >
                Must be at least 8 characters long
            </li>
            <li
                className={classNames({
                    success: errors.isLowercase === 2,
                    pending: errors.isLowercase === 1,
                })}
            >
                Must include at least 1 lowercase letter(a-z)
            </li>
            <li
                className={classNames({
                    success: errors.isUpperCase === 2,
                    pending: errors.isUpperCase === 1,
                })}
            >
                Must include at least 1 uppercase letter(A-Z)
            </li>
            <li
                className={classNames({
                    success: errors.isDigit === 2,
                    pending: errors.isDigit === 1,
                })}
            >
                Must contain letters and numbers
            </li>
            <li
                className={classNames({
                    success: errors.isSpecialChar === 2,
                    pending: errors.isSpecialChar === 1,
                })}
            >
                Must include at least 1 special character (@, $, !, &, etc.,)
            </li>
        </ul>
        <button className="close" onClick={onErrPopupClose}>
            <i className="icon-cross" />
        </button>
    </div>
);

PasswordErrorStateComponent.propTypes = {
    errors: PropTypes.shape({
        isPasswordLength: PropTypes.number,
        isLowercase: PropTypes.number,
        isUpperCase: PropTypes.number,
        isDigit: PropTypes.number,
        isSpecialChar: PropTypes.number,
    }).isRequired,
    onErrPopupClose: PropTypes.func.isRequired,
};

export const PasswordErrorProgressComponent = ({ passwordStrength }) => (
    <div
        className={classNames('pass-prog pass-progress-indicator', {
            weak: passwordStrength === 1 || passwordStrength === 2,
            medium: passwordStrength === 3 || passwordStrength === 4,
            strong: passwordStrength >= 5,
        })}
    >
        <div className="progress">
            <div className="progress-bar" />
        </div>
    </div>
);

PasswordErrorProgressComponent.propTypes = {
    passwordStrength: PropTypes.number.isRequired,
};

export default PasswordErrorStateComponent;
