import { getColor } from 'app/products/UIComponents/Utils/colorSets';
import styled from 'styled-components';
import Grid from 'app/products/UIComponentsV1/Grid';

export const EditContainer = styled.div`
    position: absolute;
    font-size: 12px;
    color: #ee654c;
    font-weight: 700;
    z-index: 2;
    right: 45px;
    top: 10px;
    cursor: pointer;
`;

export const ViewHistory = styled.div`
    font-size: 12px;
    color: ${getColor('primary', '500')};
    font-weight: 700;
    cursor: pointer;
`;

export const SliderWrapper = styled.div`
    padding: 35px 36px 0 4px;
    .history-table {
        border-color: ${getColor('grey', '300')};
        overflow: auto;
        table {
            margin: 0;
            th {
                background: ${getColor('grey', '50')};
            }
            tbody {
                background: ${getColor('grey', '0')};
                tr {
                    td {
                    }
                }
            }
        }
    }
`;

export const EditModalWrapper = styled.div`
    h4 {
        margin-bottom: 32px;
    }
    .input-field-ctn {
        width: 280px;
        input {
            margin-top: 8px;
        }
    }
    .date-selector {
        width: 280px;
        input {
            border-color: ${getColor('grey', '1300')};
        }
    }
    .button-container {
        margin-top: 32px;
        float: right;
        button {
            padding: 6px 26px;
        }
        .cancel {
            margin-right: 32px;
        }
    }
    .error-class {
        background: ${getColor('error', '50')} !important;
        border: 1.5px solid ${getColor('error', '500')} !important;
        box-sizing: border-box;
    }
`;

export const InputFieldContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 25px;
    .child {
        flex: 45%;
    }
`;

export const StyledContainer = styled(Grid)`
    min-height: 200px;
    .icon-checked {
        font-size: 32px;
    }
    h6 {
        margin-bottom: 4px;
    }
`;

export const FinalizeContainer = styled(Grid)`
    background: ${getColor('grey', '50')};
    &.white-bg-cl {
        background: ${getColor('grey', '0')};
    }
    border: 1px solid ${getColor('grey', '300')};
    border-radius: 12px;
    padding: 24px 24px 40px 24px;
    > form {
        .finalise-select {
            width: 40%;
            input {
                border: 1px solid ${getColor('grey', '300')};
            }
        }
        > button {
            margin-top: 32px;
            float: right;
            width: fit-content;
            margin-left: auto;
        }
    }
`;

export const DisbursementContainer = styled(Grid)`
    margin-right: 24px;
    .details {
        margin-bottom: 24px;
        background: ${getColor('grey', '0')};
        border: 1px solid ${getColor('primaryV1', '300')};
        box-shadow: inset 0px 3px 0px ${getColor('grey', '0')};
        border-radius: 12px;
    }
    .refresh-ctn {
        width: 75%;
    }
    .deal-name-ctn {
        gap: 16px;
        padding: 16px 32px;
        border-radius: inherit;
        background: ${getColor('primaryV1', '50')};
    }
    .disbursement-card {
        min-height: 227px;
    }
    .deal-deatails-ctn {
        padding: 16px 32px;
        .item-ctn {
            .label-ctn {
                width: 312px;
                .icon-tick {
                    background: ${getColor('success', '200')};
                }
                .icon-tenor {
                    background: ${getColor('primaryV1', '200')};
                }
                .icon-yield {
                    background: ${getColor('secondary', '200')};
                }
                > div {
                    height: 20px;
                    width: 20px;
                    margin-right: 8px;
                    padding: 5px;
                    border-radius: 6px;
                    .nucleoinvest-c-check-2 {
                        color: ${getColor('success', '500')};
                        padding: 0;
                    }
                }
            }
            margin-bottom: 18px;
        }
    }
    .disburse-loan {
        margin-top: 16px;
        margin-bottom: 16px;
        background: ${getColor('grey', '0')};
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        width: 852px;
        padding: 24px 32px;
        .credit-text {
            margin: 24px 0 16px 0;
        }
        > form {
            display: flex;
            flex-direction: column;
            > button {
                margin-left: auto;
            }
        }
        .seperator {
            border-top: 1px solid #f2f4f7;
            margin-top: 16px;
            margin-bottom: 16px;
            width: 100%;
        }
        .input-field {
            display: grid;
            grid-template-columns: 1fr 1fr;
            > div {
                width: 392px;
                margin: 0 12px 24px 0;
            }
        }
    }
    .pending-actions {
        margin-top: 24px;
        > p {
            margin: 8px 0 16px 0;
        }
        .action-ctn {
            background: ${getColor('grey', '0')};
            border: 1px solid #d0d5dd;
            min-width: 216px;
            padding: 15px;
            border-radius: 8px;
            min-height: 56px;
            box-shadow: 0px 1px 1px rgba(15, 62, 142, 0.25);
            margin-right: 16px;
            .arrow-link {
                font-weight: bold;
            }
        }
    }
`;
export const ErrorModalWrapper = styled.div`
    .upper-container {
        margin-bottom: 8px;
        i {
            border: 2px solid ${getColor('error', '300')};
            padding: 2px;
            border-radius: 50%;
            margin-right: 12px;
            color: ${getColor('error', '500')};
            font-size: 16px;
        }
    }

    .button-container {
        margin-top: 20px;
        float: right;
        button {
            padding: 6px 26px;
        }
        .cancel {
            margin-right: 32px;
        }
    }
`;
