import Pusher, { Options } from 'pusher-js';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import Auth from 'app/utils/Auth';
import { getGroupHeaders } from 'app/utils/FetchUtils';
import { MFAService } from '@yubi/yb-module-auth';

export interface PusherConfig extends Options {
    appKey: string;
}

const PusherContext = React.createContext<any>({});
export const _PusherContext = PusherContext;

export interface PusherProviderProps extends PusherConfig {
    children: ReactNode;
}

const PusherProvider = ({ children, appKey, cluster, authEndpoint }: PusherProviderProps) => {
    const [pusherInstance, setPusherInstance] = useState<any>(null);
    useEffect(() => {
        if (!appKey) console.error('App key is mandatory, please pass the app key');
        if (!cluster) console.error('Cluster is mandatory, please pass the cluster value');
    }, [appKey, cluster]);

    const initializePusher = useCallback(() => {
        const accessToken = Auth.getAccessToken();
        const mfaToken = MFAService.getMFAToken();
        const roleHeaders = getGroupHeaders();
        const pusher = new Pusher(appKey, {
            cluster,
            authEndpoint,
            auth: {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Mfa-Token': mfaToken,
                    ...roleHeaders,
                },
            },
        });
        setPusherInstance(pusher);
    }, [appKey, cluster, authEndpoint]);

    useEffect(() => {
        const accessToken = Auth.getAccessToken();
        const mfaToken = MFAService.getMFAToken();
        if (appKey && cluster && authEndpoint && accessToken && mfaToken) {
            initializePusher();
        }
    }, [appKey, cluster, authEndpoint]);

    const value = useMemo(() => {
        return { pusherInstance, authEndpoint, initializePusher };
    }, [pusherInstance, authEndpoint, initializePusher]);

    return <PusherContext.Provider value={value}>{children}</PusherContext.Provider>;
};

export default PusherProvider;
