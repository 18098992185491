import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';
import { createLoadingSelector } from 'app/reducers/loading';
import { mapData } from 'app/reducers/ptc/transaction/dealPerformance';
import { PRODUCT_KEYS } from 'app/constants/Constants';

const createActionName = (name: string): string => `app/da/transaction/dealPerformance/${name}`;
const daBaseUrl = (id: string): string => `${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}`;

const initialState = {
    error: '',
    data: [],
};

export const DEAL_PERFORMANCE_LOADING = createLoadingSelector([createActionName('DEAL_PERF_GET')]);

export const DEAL_PERF_GET_REQUEST = createActionName('DEAL_PERF_GET_REQUEST');
export const DEAL_PERF_GET_SUCCESS = createActionName('DEAL_PERF_GET_SUCCESS');
export const DEAL_PERF_GET_FAILURE = createActionName('DEAL_PERF_GET_FAILURE');

export default function dealPerformance(state = initialState, action: any): typeof initialState {
    switch (action.type) {
        case DEAL_PERF_GET_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case DEAL_PERF_GET_FAILURE:
            return {
                ...state,
                error: action.message,
            };
        default:
            return state;
    }
}

export function getDealPerformance(id: any) {
    return (dispatch: any) => {
        dispatch({ type: DEAL_PERF_GET_REQUEST, id });
        return fetchUtils
            .getJSON(`${daBaseUrl(id)}/deal_performance`)
            .then((d: any) => mapData(humps.camelizeKeys(d), PRODUCT_KEYS.direct_assignment))
            .then((payload: any) => {
                return dispatch({
                    type: DEAL_PERF_GET_SUCCESS,
                    payload,
                    id,
                });
            })
            .catch((err: any) => {
                return fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                    toastr.error('Error fetching deal performance', m);
                    return dispatch({ type: DEAL_PERF_GET_FAILURE, message: m, id });
                });
            });
    };
}
