import { combineReducers } from 'redux';
import tasks, { getTasks, actionableTasks, submitTask } from './tasks';
import bid, { getBid, placeBid, fetchBid, makeBid } from './bid';
import client, { getClient } from './clients';
import orderManagement, { getOrderManagement, submitOrders } from './orderManagement';

const rootReducer = (state = {}, action) => {
    if (action.id === undefined) return state;

    return {
        [action.id]: combineReducers({
            tasks,
            bid,
            client,
            orderManagement,
        })(state[action.id], action),
    };
};

export default rootReducer;

export const actions = {
    getTasks,
    actionableTasks,
    submitTask,
    getBid,
    placeBid,
    fetchBid,
    makeBid,
    getClient,
    getOrderManagement,
    submitOrders,
};
