const Header = [
    {
        key: ['name'],
        label: 'Deal Name',
        className: '',
    },
    {
        key: ['originatorName'],
        label: 'Originator Name',
        className: '',
    },
    {
        key: ['poolPrincipal'],
        label: 'Principal Amount (Crs)',
        className: '',
    },
    {
        key: ['settlementDate', 'disbursementDate'],
        label: 'Disbursement Date',
        className: '',
    },
    {
        key: ['coupon'],
        label: 'Coupon Rate',
        className: '',
    },
    {
        key: ['tenor'],
        label: 'Tenor (Months)',
        className: '',
    },
    {
        key: ['cashCollateral'],
        label: 'Cash Collateral (%)',
        className: '',
    },
    {
        key: ['structure', 'tranches'],
        label: 'Structure',
        className: '',
    },
    {
        key: ['assetClass'],
        label: 'Asset Class',
        className: '',
    },
    {
        key: ['rating'],
        label: 'Rating',
        className: '',
    },
];

export default Header;
