import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';
import { mapDashboardData } from 'app/utils/TransactionUtils';
import { createLoadingSelector } from '../../../reducers/loading';
import { getSectorTransaction } from './portfolio.tsx';

const ACTOR = 'investor';

const initialState = {
    summary: {},
    portfolio: [],
    newOffers: [],
    liveDeals: [],
    recommendedDeals: [],
    customers: [],
    clientPortfolio: [],
};

// actions
const createActionName = (name) => `app/investor/dashboard/${name}`;

export const SUMMARY_DATA_REQUEST = createActionName('SUMMARY_DATA_REQUEST');
export const SUMMARY_DATA_SUCCESS = createActionName('SUMMARY_DATA_SUCCESS');
export const SUMMARY_DATA_FAILURE = createActionName('SUMMARY_DATA_FAILURE');

export const PORTFOLIO_DATA_REQUEST = createActionName('PORTFOLIO_DATA_REQUEST');
export const PORTFOLIO_DATA_SUCCESS = createActionName('PORTFOLIO_DATA_SUCCESS');
export const PORTFOLIO_DATA_FAILURE = createActionName('PORTFOLIO_DATA_FAILURE');

export const NEW_OFFERS_DATA_REQUEST = createActionName('NEW_OFFERS_DATA_REQUEST');
export const NEW_OFFERS_DATA_SUCCESS = createActionName('NEW_OFFERS_DATA_SUCCESS');
export const NEW_OFFERS_DATA_FAILURE = createActionName('NEW_OFFERS_DATA_FAILURE');

export const LIVE_DEALS_DATA_REQUEST = createActionName('LIVE_DEALS_DATA_REQUEST');
export const LIVE_DEALS_DATA_SUCCESS = createActionName('LIVE_DEALS_DATA_SUCCESS');
export const LIVE_DEALS_DATA_FAILURE = createActionName('LIVE_DEALS_DATA_FAILURE');

export const RECENT_CLIENT_REQUEST = createActionName('RECENT_CLIENT_REQUEST');
export const RECENT_CLIENT_SUCCESS = createActionName('RECENT_CLIENT_SUCCESS');
export const RECENT_CLIENT_FAILURE = createActionName('RECENT_CLIENT_FAILURE');

export const RECOMMENDED_DATA_REQUEST = createActionName('RECOMMENDED_DATA__REQUEST');
export const RECOMMENDED_DATA_SUCCESS = createActionName('RECOMMENDED_DATA_SUCCESS');
export const RECOMMENDED_DATA_FAILURE = createActionName('RECOMMENDED_DATA_FAILURE');

export const PORTFOLIO_CLIENT_REQUEST = createActionName('PORTFOLIO_CLIENT_REQUEST');
export const PORTFOLIO_CLIENT_SUCCESS = createActionName('PORTFOLIO_CLIENT_SUCCESS');
export const PORTFOLIO_CLIENT_FAILURE = createActionName('PORTFOLIO_CLIENT_FAILURE');

export const SUMMARY_LOADING = createLoadingSelector([createActionName('SUMMARY_DATA')]);
export const NEW_OFFERS_LOADING = createLoadingSelector([createActionName('NEW_OFFERS_DATA')]);
export const LIVE_DEALS_LOADING = createLoadingSelector([createActionName('LIVE_DEALS_DATA')]);
export const RECENT_CLIENT_LOADING = createLoadingSelector([createActionName('RECENT_CLIENT')]);
export const RECOMMENDED_DATA_LOADING = createLoadingSelector([createActionName('RECOMMENDED_DATA')]);

// reducer
export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                summary: action.summary,
                portfolio: action.portfolio,
            };
        case PORTFOLIO_DATA_SUCCESS:
            return {
                ...state,
                portfolio: action.portfolio,
            };
        case NEW_OFFERS_DATA_SUCCESS:
            return {
                ...state,
                newOffers: action.newOffers,
            };
        case LIVE_DEALS_DATA_SUCCESS:
            return {
                ...state,
                liveDeals: action.liveDeals,
            };
        case RECOMMENDED_DATA_SUCCESS:
            return {
                ...state,
                recommendedDeals: action.recommendedDeals,
            };
        case RECENT_CLIENT_SUCCESS:
            return {
                ...state,
                customers: action.customers,
            };
        case PORTFOLIO_CLIENT_SUCCESS: {
            return {
                ...state,
                clientPortfolio: action.clientPortfolio,
            };
        }
        default:
            return state;
    }
}

export function getSummaryData(isPortfolio = false) {
    return (dispatch) => {
        dispatch({ type: SUMMARY_DATA_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/investor_dashboard_stats`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({
                    type: SUMMARY_DATA_SUCCESS,
                    summary: d.investorDashboardStats.summary,
                    portfolio: d.investorDashboardStats.portfolio,
                });
                if (isPortfolio) {
                    d.investorDashboardStats.portfolio.sector.forEach((sector) => {
                        dispatch(getSectorTransaction(sector.name));
                    });
                }
            })
            .catch((ex) => {
                dispatch({ type: SUMMARY_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving Summary data', m);
                });
            });
    };
}
export function getPortfolioData() {
    return (dispatch) => {
        dispatch({ type: PORTFOLIO_DATA_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/statistics`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: PORTFOLIO_DATA_SUCCESS, portfolio: d }))
            .catch((ex) => {
                dispatch({ type: PORTFOLIO_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving Portfolio data', m);
                });
            });
    };
}

export function getNewOffersData() {
    return (dispatch) => {
        dispatch({ type: NEW_OFFERS_DATA_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions?page1&items_per_page=5&actor=${ACTOR}&tab=new&limit=5&is_self_serve=${false}`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: NEW_OFFERS_DATA_SUCCESS, newOffers: mapDashboardData(d.transactions) }))
            .catch((ex) => {
                dispatch({ type: NEW_OFFERS_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving New Offers data', m);
                });
            });
    };
}

export function getLiveDeals() {
    return (dispatch) => {
        dispatch({ type: LIVE_DEALS_DATA_REQUEST });
        fetchUtils
            .getJSON(
                `${process.env.REACT_APP_MP_API_HOST}/transactions?page1&items_per_page=5&actor=${ACTOR}&tab=live&limit=5&is_self_serve=${false}`,
            )
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: LIVE_DEALS_DATA_SUCCESS, liveDeals: mapDashboardData(d.transactions) }))
            .catch((ex) => {
                dispatch({ type: LIVE_DEALS_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving Live Deals data', m);
                });
            });
    };
}

export function getRecentClient() {
    return (dispatch) => {
        dispatch({ type: RECENT_CLIENT_REQUEST });
        fetchUtils
            .getJSON(`${process.env.REACT_APP_CS_OB_API_HOST}/customers?items_per_page=4&limit=4`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: RECENT_CLIENT_SUCCESS, customers: d.customers.splice(0, 4) }))
            .catch((ex) => {
                dispatch({ type: RECENT_CLIENT_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving customer data', m);
                });
            });
    };
}

export function getRecommendedDeals() {
    return (dispatch) => {
        dispatch({ type: RECOMMENDED_DATA_REQUEST });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page1&items_per_page=5&actor=${ACTOR}&tab=recommended&limit=5&is_self_serve=${false}`,
            )
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: RECOMMENDED_DATA_SUCCESS, recommendedDeals: d.transactions }))
            .catch((ex) => {
                dispatch({ type: RECOMMENDED_DATA_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving recommended transactions', m);
                });
            });
    };
}

export function getPortfolioClients() {
    return (dispatch) => {
        dispatch({ type: PORTFOLIO_CLIENT_REQUEST });
        fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page1&items_per_page=5&actor=${ACTOR}&tab=client_portfolio&limit=5&is_self_serve=${false}`,
            )
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: PORTFOLIO_CLIENT_SUCCESS, clientPortfolio: d.transactions }))
            .catch((ex) => {
                dispatch({ type: PORTFOLIO_CLIENT_FAILURE });
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving recommended transactions', m);
                });
            });
    };
}
