import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import uniqBy from 'lodash/uniqBy';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    transactionList: { count: 0, list: [] },
    transactionListPage: 1,
    transactionFilter: [{ name: 'tab', value: 'live' }],
    isFetching: false,
    details: null,
    bids: null,
};

// actions
const createActionName = (name) => `app/client/transaction/${name}`;
export const TRANSACTION_LIST = createActionName('TRANSACTION_LIST');
export const TRANSACTION_LIST_VIEW_MORE = createActionName('TRANSACTION_LIST_VIEW_MORE');
export const TRANSACTION_LIST_FILTER = createActionName('TRANSACTION_LIST_FILTER');

export const TRANSACTION_GET = createActionName('TRANSACTION_GET');

export const BIDS_FETCH_REQUEST = createActionName('BIDS_FETCH_REQUEST');
export const BIDS_FETCH_SUCCESS = createActionName('BIDS_FETCH_SUCCESS');

export const BIDS_UPDATE_REQUEST = createActionName('BIDS_UPDATE_REQUEST');
export const BIDS_UPDATE_SUCCESS = createActionName('BIDS_UPDATE_SUCCESS');

export const BIDS_FINALIZE_REQUEST = createActionName('BIDS_FINALIZE_REQUEST');
export const BIDS_FINALIZE_SUCCESS = createActionName('BIDS_FINALIZE_SUCCESS');

export const RESET_DATA = createActionName('RESET_DATA');
export const FETCHING_DATA = createActionName('FETCHING_DATA');

export default function transaction(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_LIST: {
            return {
                ...state,
                transactionList: {
                    count: action.data.total_count,
                    list:
                        parseInt(action.page, 10) > 1
                            ? uniqBy([...state.transactionList.list, ...action.data.transactions], 'id')
                            : action.data.transactions,
                },
                isFetching: false,
            };
        }
        case TRANSACTION_LIST_VIEW_MORE:
            return {
                ...state,
                transactionListPage: state.transactionListPage + 1,
            };
        case TRANSACTION_LIST_FILTER:
            return {
                ...state,
                transactionFilter: uniqBy([action.data, ...state.transactionFilter], (o) => o.name),
                transactionList: { count: 0, list: [] },
                transactionListPage: 1,
            };
        case TRANSACTION_GET:
            return {
                ...state,
                details: action.data,
            };
        case BIDS_FETCH_REQUEST:
            return {
                ...state,
                bids: null,
            };
        case BIDS_FETCH_SUCCESS:
            return {
                ...state,
                bids: action.data,
            };
        case BIDS_UPDATE_SUCCESS:
            return {
                ...state,
                bids: uniqBy([action.data, ...state.bids], 'id'),
            };
        case BIDS_FINALIZE_SUCCESS: {
            return {
                ...state,
                details: action.data,
            };
        }
        case FETCHING_DATA:
            return {
                ...state,
                isFetching: action.status,
            };
        case RESET_DATA:
            return {
                ...state,
                details: null,
                bids: null,
            };
        default:
            return state;
    }
}

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function transactionList(data, page) {
    return {
        type: TRANSACTION_LIST,
        data,
        page,
    };
}

export function transactionGet(data) {
    return {
        type: TRANSACTION_GET,
        data,
    };
}

export function transactionListViewMore() {
    return {
        type: TRANSACTION_LIST_VIEW_MORE,
    };
}

export function transactionFilterUpdate(data) {
    return {
        type: TRANSACTION_LIST_FILTER,
        data,
    };
}

export function resetData() {
    return {
        type: RESET_DATA,
    };
}

export function getTransactionList(page = 1, filter, actor) {
    return (dispatch) => {
        const tab = filter && filter.find((f) => f.name === 'tab');
        const tabValue = (tab && tab.value) || '';
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(
                `${process.env.REACT_APP_MP_API_HOST}/transactions?page=${page}&per_page=20&actor=${actor}&tab=${tabValue}&is_self_serve=${false}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(transactionList(d, page));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function viewMoreTransactionList() {
    return (dispatch) => dispatch(transactionListViewMore());
}

export function updateTransactionFilter(data) {
    return (dispatch) => dispatch(transactionFilterUpdate(data));
}

const unMapPTC = (data) => {
    const cdata = humps.camelizeKeys(data);
    return {
        ...cdata,
        poolSelectionCriteria: {
            ...cdata.poolSelectionCriteria,
            cutoffDate: moment(cdata.poolSelectionCriteria.cutoffDate).format('DD MMM, YYYY'),
        },
    };
};

export function getPTC(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then((d) => unMapPTC(d))
            .then((d) => dispatch(transactionGet(d)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }),
            );
}

export function getPTCBids(id) {
    return (dispatch) => {
        dispatch({ type: BIDS_FETCH_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/bids`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_FETCH_SUCCESS, data: d }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

export function updateBid(transId, bId, action, body) {
    return (dispatch) => {
        dispatch({ type: BIDS_UPDATE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/bids/${bId}/${action}`, body)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_UPDATE_SUCCESS, data: d }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

export function finalizeBids(transId, body) {
    return (dispatch) => {
        dispatch({ type: BIDS_FINALIZE_REQUEST });
        return fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/finalize_bids`, body)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: BIDS_FINALIZE_SUCCESS, data: d }))
            .then(() => {
                toastr.success('Success!', 'Bids has been finalized.');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }),
            );
    };
}

export function searchTransactions(page = 1, filter, actor, searchTerm) {
    return (dispatch) => {
        const tab = filter && filter.find((f) => f.name === 'tab');
        const tabValue = (tab && tab.value) || '';
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${page}&items_per_page=20&actor=${actor}&tab=${tabValue}&search=${searchTerm}&is_self_serve=${false}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(transactionList(d, page));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}
