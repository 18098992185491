export const APP_AMPLITUDE_MODULE = 'pools';

export const MODULE_DOMAIN_MAP = [
    { module: 'credit', env: 'qa', domain: 'credit-qa.credavenue.in' },
    { module: 'credit', env: 'stg', domain: 'credit-stg.credavenue.in' },
    { module: 'credit', env: 'prod', domain: 'credit.credavenue.com' },

    { module: 'pool', env: 'qa', domain: 'pool-qa.credavenue.in' },
    { module: 'pool', env: 'stg', domain: 'pool-stg.credavenue.in' },
    { module: 'pool', env: 'prod', domain: 'pool.credavenue.com' },

    { module: 'loan', env: 'qa', domain: 'loan-qa.credavenue.in' },
    { module: 'loan', env: 'stg', domain: 'loan-stg.credavenue.in' },
    { module: 'loan', env: 'prod', domain: 'loan.credavenue.com' },

    { module: 'bond', env: 'qa', domain: 'bond-qa.credavenue.in' },
    { module: 'bond', env: 'stg', domain: 'bond-stg.credavenue.in' },
    { module: 'bond', env: 'prod', domain: 'loan.credavenue.com' },

    { module: 'colend', env: 'qa', domain: 'colend-qa.credavenue.in' },
    { module: 'colend', env: 'stg', domain: 'colend-stg.credavenue.in' },
    { module: 'colend', env: 'prod', domain: 'colend.credavenue.com' },

    { module: 'plutus', env: 'qa', domain: 'plutus-qa.credavenue.in' },
    { module: 'plutus', env: 'stg', domain: 'plutus-stg.credavenue.in' },
    { module: 'plutus', env: 'prod', domain: 'plutus.credavenue.com' },

    { module: 'tf', env: 'qa', domain: 'tf-qa.credavenue.in' },
    { module: 'tf', env: 'stg', domain: 'tf-stg.credavenue.in' },
    { module: 'tf', env: 'prod', domain: 'tf.credavenue.com' },

    { module: 'auth', env: 'qa', domain: 'auth-qa.credavenue.in' },
    { module: 'auth', env: 'stg', domain: 'auth-stg.credavenue.in' },
    { module: 'auth', env: 'prod', domain: 'auth.credavenue.com' },
];
