import { combineReducers } from 'redux';
import da from 'app/actors/admin/ducks/da/';
import tasks, { getTasks } from 'app/reducers/ptc/transaction/tasks';
import ptc from './ptc';
import ptcv1 from './ptcv1';
import ptcInitiate from './ptcInitiate';
import pricing, { sameEntity, sameSector, otherSector, clientInvestor, sameEntityLoading, sameSectorLoading, otherSectorLoading } from './pricing.ts';

const rootReducer = combineReducers({
    ptc,
    ptcv1,
    ptcInitiate,
    tasks,
    da,
    pricing,
});

export default rootReducer;

export const action = {
    getTasks,
};
export const pricingAction = {
    sameEntity,
    sameSector,
    otherSector,
    clientInvestor,
    sameEntityLoading,
    sameSectorLoading,
    otherSectorLoading,
};
