import { USER_TYPES } from 'app/constants/Constants';

export const TRANS_CONST = {
    dealName: 'dealName',
    clientName: 'clientName',
    transactionType: 'transactionType',
    assetClass: 'assetClass',
    sector: 'sector',
    quantum: 'quantum',
    disbursementDate: 'disbursementDate',
    maturityDate: 'maturityDate',
    disbursedAmount: 'tranchesDisbursedAmount',
    principalOutstanding: 'principalOutstanding',
    yield: 'yield',
    collectionEfficiency: 'collectionEfficiency',
    residualTenor: 'residualTenor',
    flceFixedDeposit: 'flceFixedDeposit',
    maxSlceBg: 'maxSlceBg',
    other: 'other',
};

export const PTCV1_KEYS: any = {
    clientId: 'customerId',
    assetClasses: 'poolType',
};

export const INTEREST_RATE_METHOD = [
    { label: 'Coupon', value: 'Coupon' },
    { label: 'XIRR', value: 'XIRR' },
];

export const DAYS_CONVENTION = [
    { label: 'Actual/365', value: 'Actual/365' },
    { label: 'Actual/360', value: 'Actual/360' },
    { label: '30/365', value: '30/365' },
    { label: '30/360', value: '30/360' },
    { label: 'Actual/Actual', value: 'Actual/Actual' },
];

export const INTEREST_RATE_TYPE = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Floating', value: 'floating' },
];

export const PENAL_INTEREST_APPLIED_ON = [
    { value: 'full_outstanding_amount', label: 'Full Outstanding Amount' },
    { value: 'total_overdues', label: 'Total Overdues' },
    { value: 'principal_overdue', label: 'Principal Overdue' },
    { value: 'interest_overdue', label: 'Interest Overdue' },
];

export const INTEREST_RATE_SHARING_TYPE = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Variable', value: 'floating' },
];

export const PROPORTION_UNITS = [
    { value: 'percent', label: 'Percent' },
    { value: 'amount', label: 'Amount' },
    { value: 'percent_on_collection', label: 'Percent On Collection' },
];

export const PROPORTION_UNITS_DA = [
    { value: 'amount', label: 'Amount' },
    { value: 'percent', label: '% of Investor Opening POS' },
    { value: 'percent_on_collection', label: '% of Principal collection' },
    { value: 'percent_on_average_invetsor_pos', label: '% of Average Investor POS' },
];

export const PROPORTION_UNIT_VALUES = {
    AMOUNT: 'amount',
    PERCENT: 'percent',
    PERCENT_ON_COLLECTION: 'percent_on_collection',
};

export const FREQUENCY = [
    { value: 'annually', label: 'Annually' },
    { value: 'half_yearly', label: 'Half Yearly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'monthly', label: 'Monthly' },
];

export const NPA_RECOGNITION = [
    { label: 'Greater than 90', value: 'greater_than_90' },
    { label: 'All overdues cleared', value: 'all_overdues_cleared' },
];

export const ASSET_CLASSIFICATION = [
    { label: 'From payout report', value: 'from_payout_report' },
    { label: 'As per RBI', value: 'as_per_rbi' },
    { label: 'As per the Payout or RBI guidelines (as applicable)', value: 'as_per_payout_or_as_per_rbi' },
];

export const ASSIGNED_POS = [
    { label: 'POS', value: 'with_overdue' },
    { label: 'POS + Overdue', value: 'without_overdue' },
];

export const PAYOUT_LEVEL_EQUATED_INTEREST_METHOD = 'payout_level_equated_interest_method';

export const ASSIGNEE_INTEREST_SPLIT = [
    { label: 'Method 1', value: 'loan_level_equated_interest_method' },
    { label: 'Method 2', value: 'pool_level_equated_interest_method' },
    { label: 'Method 3', value: PAYOUT_LEVEL_EQUATED_INTEREST_METHOD },
];

export const SERVICER_FEE_RECONCILIATION = [
    { label: 'Servicing fee with GST given', value: 'method_1' },
    { label: 'Servicing fee based on pool opening pos', value: 'method_2' },
    { label: 'Servicing fee based on average of assigned pos', value: 'method_3' },
    { label: 'Servicing fee based on total principal collected', value: 'method_4' },
];

export const PRINCIPAL_REPAYMENT = {
    PROMISED: { label: 'Promised', value: 'promised' },
    EXPECTED: { label: 'Expected', value: 'expected' },
};

export const EIS_TRAP = {
    TURBO_PAR: { label: 'TurboPar', value: 'turboPar' },
    EIS_LEAKING: { label: 'EIS Leaking', value: 'eisLeaking' },
    TRIGGER: { label: 'Trigger', value: 'trigger' },
};

export const CASHFLOW = 'cashflows';
export const WATERFALL = 'waterfall';
export const TERM_SHEET = 'term_sheet';
export const ENTERPRISE_FINANCE = 'enterprise_finance';
export const REPAYMENT_SCHEDULE = 'repayment_schedule';
export const RS_FILE = 'rs_file';
export const RS_FILES = 'rs_files';
export const DEAL_DOCUMENTS = 'deal_documents';
export const FIXED = 'fixed';
export const FLOATING = 'floating';

export const INVESTOR_LIST = {
    eligible: 'eligible',
    excluded: 'excluded',
    released: 'released',
};

export const NachMandateBank = [
    { label: 'RBL BANK', value: 'rbl' },
    { label: 'YES BANK', value: 'yes' },
    { label: 'KOTAK MAHINDRA BANK', value: 'kotak' },
    { label: 'HDFC BANK', value: 'hdfc' },
];
export const ALLOW_TL_BOOK_DEBTS = [USER_TYPES.RISK, USER_TYPES.CREDIT, USER_TYPES.OPERATIONS];

export const TRANSACTION_NAVIGATION = {
    DETAILS: 'details',
    FILES: 'fILES',
    DEAL_PERFORMANCE: 'deal_performane',
    MARKET_RESEARCH: 'market_research',
    SERVICER_REPORT: 'servicer_report',
    INVESTMENT_NOTE: 'investment_note',
    REPORTS: 'reports',
    CASHFLOW_STATEMENT: 'cashflow_statement',
    LOANS_VIEW: 'loans_view',
};

export const POOLS_COUNTERPARTIES = ['auditors', 'trustees', 'ratingAgencies', 'lawFirms'];

export const ENABLE_TIME_TRANCHING = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

export const PERCENTAGE = [
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
];

export const PERCENTAGE_FULL_RANGE = [
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    { value: 55, label: '55%' },
    { value: 60, label: '60%' },
    { value: 65, label: '65%' },
    { value: 70, label: '70%' },
    { value: 75, label: '75%' },
    { value: 80, label: '80%' },
    { value: 85, label: '85%' },
    { value: 90, label: '90%' },
    { value: 95, label: '95%' },
    { value: 100, label: '100%' },
];

export const PRINCIPAL_REPAYMENT_ITEMS = [
    { label: 'Promised', value: 'promised' },
    { label: 'Expected', value: 'expected' },
];

export const REPAYMENT_TYPES = {
    PROMISED: 'promised',
    EXPECTED: 'expected',
};

export const CREDIT_ENHANCEMENT_VALUES = {
    CASH_COLLATERAL: 'cash_collateral',
    BANK_GUARANTEE: 'bank_guarantee',
    CORPORATE_GUARANTEE: 'corporate_guarantee',
    None: 'None',
};
export const ROUND_OFF_CONFIG = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
];

export const INVESTOR_DPD_OPTIONS_STRUCTURING = [
    {
        label: 'Same as Originator DPD',
        value: 'same_as_originator_dpd',
    },
    {
        label: 'Calculate Investor DPD based on payout report only',
        value: 'investor_dpd_based_on_payout_report',
    },
    {
        label: 'NPA date as per Payout report',
        value: 'npa_date_as_per_payout',
    },
];

export const BENCHMARK_TYPE = [
    { label: 'BPLR', value: 'bplr' },
    { label: 'Base Rate', value: 'base_rate' },
    { label: 'MCLR', value: 'mclr' },
    { label: 'EBLR', value: 'eblr' },
    { label: 'RLLR', value: 'rllr' },
    { label: 'Others', value: 'others' },
];
