import { FILE_TYPE_CLASS, FILE_TYPE_ICONS } from 'app/constants/Constants';
import { getFileExtension } from 'app/utils/CommonUtils';
import _mapKeys from 'lodash/mapKeys';
import humps from 'humps';

export const replaceSpecialChar = (str, char = '-') => {
    if (!str) {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9]/g, char).toLowerCase();
};

export const amountInCr = (value) => {
    if (!value) return null;
    const v = parseFloat(value) / 10000000.0;
    return v.toFixed(2);
};

export const intFormatINR = (value) => {
    const x = value.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = `,${lastThree}`;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
};

export const floatFormatINR = (value) => {
    let x = value.toString();
    let afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = `,${lastThree}`;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
};

export const getOptionLabel = (arr, value) => {
    const option = (arr && (value || value === 0) && Array.isArray(arr) && arr.find((p) => p.value === value)) || {
        value,
        label: value,
    };
    return (option && option.label) || value;
};

export const humanize = (value) => {
    if (!value) return null;
    if (typeof value !== 'string') return value;
    const newValue = value.replace(/_/g, ' ');
    return newValue.charAt(0).toUpperCase() + newValue.slice(1);
};

export const capitalize = (value) => {
    if (!value) return null;
    const text = value
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return text;
};

export const normalizeFloat = (value) => (value && parseFloat(value).toFixed(2)) || value;

export const convertToNumberingScheme = (number) => {
    const baseChar = 'A'.charCodeAt(0);

    if (number < 26) {
        return String.fromCharCode(baseChar + number);
    }
    return convertToNumberingScheme(number / 26 - 1) + String.fromCharCode(baseChar + (number % 26));
};

export const getFileIcon = (extension, iconsV1 = false) => {
    if (!extension) return '';
    const icons = iconsV1 ? FILE_TYPE_ICONS : FILE_TYPE_CLASS;
    const className = Object.keys(icons).find((d) => {
        const checkExtension = icons[d].indexOf(extension);
        if (checkExtension > -1) {
            return d;
        }
    });
    return className || '';
};

export const getFileLogo = (filename) => {
    const extension = getFileExtension(filename);
    if (!extension) return '';
    return getFileIcon(extension);
};

export const getFileIconV1 = (filename) => {
    const extension = getFileExtension(filename);
    if (!extension) return '';
    return getFileIcon(extension, true);
};

export const getFundedNotification = (client, settlementDate) => {
    return `Settlement date for <span className='txn-name'>"${client}"</span> is <span className="txn-settlement-due-date">${settlementDate}</span>and has passed. Is this transaction settled?`;
};
export const formatINR = (amount) => {
    if (amount || amount === 0) {
        return `${amount.toLocaleString('en-IN', {
            // style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })}`;
    }
    return;
};

export const capitalizeKeysInObject = (data, undisturbedKeys = []) => {
    return _mapKeys(data, (_, key) => {
        if (!data) {
            return {};
        }
        if (!undisturbedKeys.includes(key)) {
            return capitalize(humps.decamelize(key));
        }
        return humps.decamelize(key);
    });
};
