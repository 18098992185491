import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';

const createActionName = name => `app/admin/da/${name}`;
export const GET_BIDS_SUCCESS = createActionName('GET_BIDS_SUCCESS');
export const UPDATE_BID_SUCCESS = createActionName('UPDATE_BID_SUCCESS');
export const FINALIZE_BIDS_SUCCESS = createActionName('FINALIZE_BIDS_SUCCESS');

const initialState = null;

export default function bids(state = initialState, action) {
    switch (action.type) {
    case GET_BIDS_SUCCESS: {
        return [...action.data];
    }
    case UPDATE_BID_SUCCESS: {
        return [...action.data];
    }
    case FINALIZE_BIDS_SUCCESS: {
        return [...action.data, action.data];
    }
    default:
        return state;
    }
}

export function getBidsInfo(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/bids`)
            .then(d => d && humps.camelizeKeys(d))
            .then(d => dispatch({ type: GET_BIDS_SUCCESS, data: d, id }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching Bids information', m);
                }));
}

export function updateBid(id, bId, action, body) {
    return dispatch =>
        fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/bids/${bId}/${action}`, body)
            .then(d => humps.camelizeKeys(d))
            .then((d) => {
                dispatch(getBidsInfo(id));
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating Bid', m);
                }));
}

export function finalizeBids(id, body) {
    return dispatch =>
        fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/finalize_bid`, body)
            .then(d => humps.camelizeKeys(d))
            .then(d => dispatch({ type: FINALIZE_BIDS_SUCCESS, data: d, id }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving bids', m);
                }));
}
