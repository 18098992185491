/* eslint-disable no-underscore-dangle */
import debounce from 'lodash/debounce';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import { matchPath } from 'react-router';
//import { matchPath } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _flattenDeep from 'lodash/flattenDeep';
import _sample from 'lodash/sample';
import * as fetchUtils from 'app/utils/FetchUtils';
import Auth from 'app/utils/Auth';
import { INTERNAL_ACTORS } from 'app/actors/admin/constants/Constants';
import { ACTORS } from 'app/constants/Constants';
import serializeQuery from 'app/utils/URLQuery';
import humps from 'humps';
import history from 'app/utils/myHistory';
import { CHART_COLORS } from '../constants/Colors.tsx';
import Logger from './Logger';
import { toastr } from 'react-redux-toastr';
import { getEnv } from 'app/utils/FetchUtils';

export const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);
    return (e) => {
        e.persist();
        return debouncedEvent(e);
    };
};

// helper method to check existance of deep nested objects
export const validateObj = (nestedObj, pathArr) => pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);

export const upperCaseFirst = (word) => word.charAt(0).toUpperCase() + word.slice(1);

export const groupBy = (xs, key) => {
    if (!xs || !key) return [];
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const dateCalculator = (date, tenor) => {
    const q = {
        'transaction[date]': date,
        'transaction[no_of_days]': tenor.days,
        'transaction[no_of_months]': tenor.months,
        'transaction[no_of_years]': tenor.years,
    };

    const qParams = Object.keys(q)
        .filter((key) => q[key] !== undefined)
        .map((key) => `${key}=${q[key]}`)
        .join('&');

    return fetchUtils.getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/process_date?${qParams}`).then((d) => d);
};

export const getEntities = (page = 1, search, actor, otherParams = {}) => {
    const params = {
        ...(page ? { page } : {}),
        search,
        actor,
        ...otherParams,
    };
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities?${serializeQuery(params)}`)
        .then(
            (data) =>
                data &&
                data.entities &&
                data.entities.map((d) => ({
                    value: d.id,
                    label: d.company_name,
                })),
        )
        .catch(() => []);
};

export const getEntitiesV1 = (page = 1, search, actor, otherParams = {}) => {
    const params = {
        page,
        search,
        actor,
        ...otherParams,
    };
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities?${serializeQuery(params)}`)
        .then((data) => humps.camelizeKeys(data.entities))
        .catch((e) => console.log(e));
};

export const authEnabledLink = (url) => {
    const hurl = (url && url.startsWith('/') && getEnv(`${process.env.REACT_APP_MP_API_HOST}${url}`)) || getEnv(url);

    return (hurl && hurl) || '/';
};

export const getDownloadLink = (url) => {
    const fetchParams = fetchUtils.requestPayload('GET');
    return fetch(getEnv(url), { ...fetchParams, responseType: 'blob' }).then((res) => {
        const resUrl = URL.createObjectURL(new Blob(res));
        return resUrl;
    });
};

export const urltoFile = (url, filename, mimeType) =>
    fetch(getEnv(url))
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));

export const objectToFormData = (obj, form, namespace) => {
    const fd = form || new FormData();
    let formKey;
    // eslint-disable-next-line no-restricted-syntax
    for (const property in obj) {
        //  if (obj.hasOwnProperty(property) && obj[property]) {
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = `${namespace}[${property}]`; //  namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], fd, formKey);
            } else {
                // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }
    return fd;
};

// Check whether the given user is from internal or product team
export const isInternalUser = (user) =>
    INTERNAL_ACTORS.map((m) => humps.decamelize(humps.camelize(m.value), { separator: '-' }))
        .filter((item) => item !== 'product')
        .includes(user);

export const valuesToCrores = (value, decimal = 2, number = false) => {
    if (value === '') return null;
    if (value === 0) return value;
    return number ? Number((value / 10000000).toFixed(decimal)) : (value / 10000000).toFixed(decimal);
};

export const croresToValues = (value) => {
    if (value === '') return null;
    if (value === 0) return value;
    return value * 10000000;
};

export const humanizeNumber = (value) => {
    let val = Math.abs(value);
    if (val >= 10000000) {
        val = `${(val / 10000000).toFixed(2)} ${(val / 10000000).toFixed(2) > 1 ? ' Cr(s)' : ' Cr'}`;
    } else if (val >= 100000) {
        val = `${(val / 100000).toFixed(2)} ${(val / 100000).toFixed(2) > 1 ? 'Lakhs' : 'Lakh'}`;
    }
    return typeof val === 'number' ? val.toFixed(2) : val;
};

// query constructor: builds filter query parameters for transactions
export const queryConstructor = (qval) => {
    const qvals = qval; // refrencing args to new varaibale as per eslint rule no param reassign
    const params = ['filter_params[][field]', 'filter_params[][value][]', 'filter_params[][operator]'];
    const equalParams = ['filter_params[][field]', 'filter_params[][value]', 'filter_params[][operator]'];
    const operatorReq = ['rating', 'sector', '_type', 'investor', 'lean'];
    const operatorMap = new Map([
        ['rating', 'in'],
        ['sector', 'in'],
        ['_type', 'in'],
        ['investor', 'in'],
        ['lean', 'equals'],
    ]);
    const query = [];
    Object.keys(qvals).map((m) => {
        if (_isEmpty(qvals[m])) {
            delete qvals[m];
        } else if (!operatorReq.includes(m)) {
            query.push(`${m}=${qvals[m]}`);
            delete qvals[m];
        }
        return null;
    });
    Object.entries(qvals).map((m) => {
        m.map((n, i) => {
            query.push(`${(m[0] === 'lean' ? equalParams : params)[i]}=${n}`);
            if (i === 1) query.push(`${params[i + 1]}=${operatorMap.get(m[0])}`);
            return null;
        });
        return null;
    });
    return query.join('&');
};

export const queryBuilder = (queryData) => {
    const processedQuery = [];
    const groupedData = _groupBy(queryData, 'field');
    const params = ['filter_params[][field]', 'filter_params[][value][]', 'filter_params[][operator]'];

    function query(input) {
        const output = input.map((m) => {
            const a = `${params[0]}=${m.field}`;
            const b = `${params[2]}=${m.operator}`;
            const c = `${params[1]}=${m.value}`;
            return [a, b, c];
        });
        return [...new Set(_flattenDeep(output))].join('&');
    }

    if (_get(groupedData, '_type.length') > 0) {
        processedQuery.push(query(groupedData._type));
    }
    if (_get(groupedData, 'rating.length') > 0) {
        processedQuery.push(query(groupedData.rating));
    }
    if (_get(groupedData, 'customer_id.length') > 0) {
        processedQuery.push(query(groupedData.customer_id));
    }
    if (_get(groupedData, 'sector.length') > 0) {
        processedQuery.push(query(groupedData.sector));
    }

    return processedQuery.join('&');
};

export const groupbyConfig = (objArray, config) => {
    if (!objArray) return null;
    const outputArray = [];
    config.forEach((c, i) => {
        const item = {};
        item.label = c.attrName;
        item.data = objArray.map((t) => _get(t, c.attrPath));
        if (c.title) {
            item.title = c.title;
        }
        item.id = i;
        outputArray.push(item);
    });
    return outputArray;
};

export const getActor = () => ACTORS.filter((f) => f.value === Auth.getGroup()[0]).reduce((acc, cur) => cur.alais, null);

export const getDayOptions = (days = 31, includeZero) =>
    [...(includeZero ? [0] : []), ...Array(days)].map((m, i) => {
        const day = includeZero ? i - 1 : i;
        return { label: day + 1, value: day + 1 };
    });

export const getRandomColor = () => _sample(CHART_COLORS);

export const findAttribValue = (source, attribName, attribValue, className) => {
    const src = source.find((data) => data[attribName] === attribValue);
    if (src) {
        return src[className];
    }
    return '-';
};

export const getSequentialArrVal = (source, index, reverse = false) => {
    const arr = reverse ? source.reverse() : source;
    if (arr[index]) {
        return arr[index];
    }
    const indexVal = index % arr.length;
    return arr[indexVal];
};

export const getFileExtension = (filename, toUpperCase = false) => {
    if (!filename) return '';
    return toUpperCase
        ? filename
              .split('.')
              .pop()
              .toUpperCase()
        : filename.split('.').pop();
};

export const checkDoubleExtension = (fileName) => {
    if (!fileName) return true;
    if (fileName.split('.').length > 2) {
        return true;
    }
    return false;
};

export const underscoreToHyphen = (str) => {
    if (!str) return '';
    return str.replace(/_/g, '-');
};

function preventInputScroll(e, allowNumbers = false) {
    e.addEventListener('mousewheel', preventDefault, true);
    e.addEventListener(
        'keydown',
        (evt) => {
            const { keyCode } = evt;
            let disabledKeyCode = [38, 40, 69, 189, 187];
            if (allowNumbers) {
                disabledKeyCode = [...disabledKeyCode, 190];
            }
            if (disabledKeyCode.includes(keyCode)) {
                preventDefault(evt);
            }
        },
        true,
    );
    e.addEventListener('blur', () => {
        e.removeEventListener('mousewheel', preventDefault, false);
        e.removeEventListener('keydown', preventDefault, false);
    });
}

function preventDefault(e) {
    return e.preventDefault();
}

document.querySelector('body').addEventListener('click', (e) => {
    if (e.target.type === 'number') {
        const allowNumbers = !!_get(e, 'target.dataset.allowNumbers');
        preventInputScroll(e.target, allowNumbers);
    }
});

export const findOrdinal = (num) => {
    const digits = [num % 10, num % 100];
    const ordinals = ['st', 'nd', 'rd', 'th'];
    const oPattern = [1, 2, 3];
    const tPattern = [11, 12, 13];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1]) ? num + ordinals[digits[0] - 1] : num + ordinals[3];
};

export function formatFileSize(bytes, decimalPoint) {
    if (bytes === 0) return '0 Bytes';
    const k = 1000;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const calculateGst = (val, rate) => {
    if (!val || !rate) return 0;
    return parseFloat((val * rate) / 100);
};

export const checkEmptyJson = (object) => object.some((d) => !_isEmpty(d));

export const decimalFormatter = (value) => {
    if (value) {
        if (value.split('').pop() === '.') return value;
        return parseFloat(value);
    }
    return value;
};

export const findDeep = (inp, key, getMtachingKeysObject = true) => {
    if (!inp) return [];
    if (Array.isArray(inp)) {
        const result = [];
        inp.forEach((m) => {
            Object.keys(m).forEach((n) => {
                if (Array.isArray(m[n])) {
                    result.push(findDeep(m[n], key, getMtachingKeysObject));
                }
            });
            result.push(findDeep(m, key, getMtachingKeysObject));
        });
        return result.flat(2).filter((f) => f);
    }
    if (inp[key]) {
        return getMtachingKeysObject ? inp : inp[key];
    }
};

export const checkNumberOrString = (value, zeroCheck = false) => {
    return !(
        ((typeof value === 'string' && value.length > 0) || typeof value === 'number') &&
        (!zeroCheck || (zeroCheck && typeof value === 'number' && value > 0) || (typeof value === 'string' && value !== '0'))
    );
};

export const deriveErrorMsg = (errorMsg) => (errorMsg && errorMsg.split('{').length > 1 ? JSON.parse(errorMsg).message.split(':')[1] : errorMsg);

export const nexturlValidation = (v) => {
    const urlTest = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    if (urlTest.test(v)) {
        const uri = new URL(v);
        const host = getDomainName(uri);
        if (_get(host, 'domain') && _get(host, 'domain') === process.env.REACT_APP_MP_DOMAIN_NEW) {
            return true;
        } else {
            return false;
        }
    } else if (v.indexOf('.') !== -1) {
        return false;
    } else {
        return true;
    }
};

export function getDomainName(customHost = null) {
    const hostName = customHost ? customHost.hostname.split('.') : window.location.hostname.split('.');
    const hostLength = hostName.length;
    if (hostLength > 0) {
        const domainName = hostName.slice(hostLength - 2, hostLength).join('.');
        return {
            domain: domainName,
        };
    }
    return {};
}

export const formatMoney = (amount) => `${amount.toLocaleString('en-IN')}`;

export const routeMatch = (url, pathname = '') => {
    return matchPath(url, pathname);
};
export const isRouteMatch = (pathname, sidebarEnabledRoutes) => {
    return sidebarEnabledRoutes.some((r) => {
        return routeMatch(r, pathname);
    });
};

export const valuesToAmount = (value, formatVal, decimal = 2) => {
    if (value === '') return null;
    if (value === 0) return value;
    return Number((value / formatVal).toFixed(decimal));
};

export const triggerDownload = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout((_) => {
        window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
};

const downloadFile = (url, filename) => {
    fetch(getEnv(url), { method: 'GET' })
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            triggerDownload(blob, filename);
        })
        .catch((err) => {
            Logger.logException(new Error(err));
            fetchUtils.handleError(err, 'Error in downloading file').then(({ message, title, toastType = 'error' }) => {
                const { [toastType]: toast } = toastr;
                toast(title, message);
            });
        });
};

export const handleFileLink = (url, name, canDownload = false) => {
    try {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (canDownload) {
            return downloadFile(url, name);
        } else {
            a.download = name;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
        }
        if (document.body) {
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    } catch (e) {
        console.error(e);
    }
};

export const deleteTransaction = (transId, productType, pushRoute, setSubmitting) =>
    fetchUtils
        .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/${productType}/${transId}`)
        .then(() => {
            toastr.success('Success!', 'Transaction deleted successfully');
            return history.push(pushRoute);
        })
        .catch((e) =>
            fetchUtils.handleError(e).then((d) => {
                setSubmitting(false);
                toastr.error('Error', d);
            }),
        );

export const encodeDealName = (dealName) =>
    encodeURI(dealName)
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');

export const parseDomain = () => {
    const [subDomain, domain, type] = window.location.host?.split('.');
    return { subDomain, domain, type };
};

export const getCurrentDomain = () => {
    const { domain } = parseDomain();
    return domain;
};

export const toTwoDecimalPlaces = (value) => {
    return value === null || value === '' || value === undefined ? 'NA' : parseFloat(value).toFixed(2);
};
export const parseToken = (token) => {
    return `${token}px`;
};

export const isValidRedirect = (url) => {
    return url === process.env.REACT_APP_POOL_HOST || url === process.env.REACT_APP_POOL_OLD_HOST;
};
