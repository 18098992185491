import React, { FC, Fragment } from 'react';
import _size from 'lodash/size';
import {
    Pie,
    Cell,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area,
    ResponsiveContainer,
    Label,
    ComposedChart,
    PieChart,
} from 'recharts';
import { CHART_TYPE } from 'app/products/UIComponents/Chart/Constants';
import { Icell } from 'app/products/UIComponents/Chart/chart.types';

export const RenderCell: FC<Icell> = (props): JSX.Element => {
    const { key, fill } = props;
    return <Cell key={key} fill={fill} />;
};

export const Chart: FC<any> = (props): JSX.Element => {
    const {
        data,
        width,
        height,
        config,
        cartisenGrid,
        XAxis: XAxisConfig,
        YAxis: YAxisConfig,
        series = [],
        tooltip,
        legend,
        type,
        YAxisSecondaryConfig,
    } = props;

    return (
        <>
            {type === CHART_TYPE.PIE && (
                <PieChart width={width} height={height}>
                    {tooltip && <Tooltip {...tooltip} />}
                    {legend && <Legend {...legend} />}
                    {series.map((d: any) => (
                        <Pie key={d.id} data={data} innerRadius={d.innerRadius} outerRadius={d.outerRadius} fill={d.fill} dataKey={d.dataKey}>
                            {d.label && <Label value={d.label.value} position={d.label.position} />}
                            {_size(d.cell) > 0 && d.cell.map((c: any, i: number) => <Cell key={`${c.key}.${i}`} fill={c.fill} />)}
                        </Pie>
                    ))}
                </PieChart>
            )}
            {type !== CHART_TYPE.PIE && (
                <ResponsiveContainer height={height}>
                    <ComposedChart data={data} {...config}>
                        {cartisenGrid && <CartesianGrid {...cartisenGrid} />}
                        {XAxisConfig && <XAxis {...XAxisConfig} />}
                        {YAxisConfig && <YAxis {...YAxisConfig} />}
                        {YAxisSecondaryConfig && <YAxis {...YAxisSecondaryConfig} />}
                        {tooltip && <Tooltip {...tooltip} />}
                        {legend && <Legend {...legend} />}
                        {series.map((d: any, index: number) => {
                            const {
                                fill,
                                dataKey = 'value',
                                cell,
                                areaType,
                                stroke,
                                name,
                                shape,
                                radius,
                                stackId = 'z',
                                yAxisId,
                                lineType,
                                type: chartType = CHART_TYPE.BAR,
                            } = d;
                            switch (chartType) {
                                case CHART_TYPE.AREA:
                                    return <Area key={`area-chart.${index}`} type={areaType} dataKey={dataKey} stroke={stroke} fill={fill} />;
                                case CHART_TYPE.BAR:
                                    return (
                                        <Bar
                                            key={`bar-chart.${index}`}
                                            yAxisId={yAxisId}
                                            stackId={stackId}
                                            name={name}
                                            dataKey={dataKey}
                                            fill={fill}
                                            shape={shape}
                                            radius={radius}
                                        >
                                            {_size(cell) > 0 && cell.map((c: any) => <Cell key={c.key} fill={c.fill} />)}
                                        </Bar>
                                    );
                                case CHART_TYPE.LINE:
                                    return (
                                        <Line
                                            key={`line-chart.${index}`}
                                            type={lineType}
                                            dataKey={dataKey}
                                            stroke={fill}
                                            strokeWidth={2}
                                            yAxisId={yAxisId}
                                            name={name}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </>
    );
};

export default Chart;
