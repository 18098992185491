import React from 'react';
import AlertWrapper, { CloseBtn } from './styled';

export const ALERT_TYPE: any = {
    warning: 'warning',
    danger: 'danger',
    info: 'info',
    success: 'success',
};

const renderAlertType = (type: string): string => {
    let content = '';
    switch (type) {
        case ALERT_TYPE.warning: {
            content = '<i class="icon icon-warning"></i> Warning';
            break;
        }
        case ALERT_TYPE.danger: {
            content = 'Alert!';
            break;
        }
        case ALERT_TYPE.info: {
            content = '<i class="icon icon-info-button"></i>  Info';
            break;
        }
        default: {
            content = type;
        }
    }
    return content;
};

interface AlertsProp {
    alertType: any;
    content?: string;
    alertText?: string;
    close?: any;
    onClose?: () => void;
    className?: string;
    noBottomMarigin?: boolean;
    position?: string;
    children?: any;
}

const Alerts = ({
    alertType,
    content,
    onClose,
    close,
    alertText,
    noBottomMarigin = false,
    children,
    position,
    className,
}: AlertsProp): JSX.Element | any => {
    if (close) return null;
    return (
        <AlertWrapper type={alertType} noBottomMarigin={noBottomMarigin} positionFixed={position} className={className}>
            <strong dangerouslySetInnerHTML={{ __html: alertText || renderAlertType(alertType) }} />
            {content || children}
            {onClose && (
                <CloseBtn onClick={onClose}>
                    <i className="icon-close-bold" />
                </CloseBtn>
            )}
        </AlertWrapper>
    );
};

export default Alerts;
