export const JOIN = {
    AND: 'and',
    OR: 'or',
};

export const RHS_TYPES = {
    STRING: 'string',
    STRING_ARRAY: 'string array',
    NULL: null,
    NUMBER: 'number',
    NUMBER_ARRAY: 'number array',
    DATE: 'date',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

export const EMPTY_OPERATORS = {
    IS_NULL: 'IS NULL',
    IS_NOT_NULL: 'IS NOT NULL',
};

export const STRING_OPERATORS = ['=', '!=', 'IN', 'NOT IN', EMPTY_OPERATORS.IS_NULL, EMPTY_OPERATORS.IS_NOT_NULL];

export const DATATYPE_MAPPING: any = {
    string: ['text', 'string'],
    text: ['text', 'string'],
    number: ['integer', 'float', 'number'],
    integer: ['integer', 'float', 'number'],
    float: ['integer', 'float', 'number'],
    date: ['date'],
};

export const STRING_OPERATIONS = new Map([
    ['=', { label: 'Is', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Is not', rhs: RHS_TYPES.SINGLE }],
    ['IN', { label: 'Contains', rhs: RHS_TYPES.MULTIPLE }],
    ['NOT IN', { label: 'Does not contain', rhs: RHS_TYPES.MULTIPLE }],
    [EMPTY_OPERATORS.IS_NULL, { label: 'Is empty', rhs: RHS_TYPES.NULL }],
    [EMPTY_OPERATORS.IS_NOT_NULL, { label: 'Is not empty', rhs: RHS_TYPES.NULL }],
]);

export const NUMBER_OPERATORS = ['>', '<', 'IN', 'NOT IN', '=', '!=', '<=', '>=', EMPTY_OPERATORS.IS_NULL, EMPTY_OPERATORS.IS_NOT_NULL];

export const NUMBER_OPERATIONS = new Map([
    ['>', { label: 'Greater than', rhs: RHS_TYPES.SINGLE }],
    ['<', { label: 'Less than', rhs: RHS_TYPES.SINGLE }],
    ['IN', { label: 'Contains', rhs: RHS_TYPES.MULTIPLE }],
    ['NOT IN', { label: 'Does not contain', rhs: RHS_TYPES.MULTIPLE }],
    ['=', { label: 'Equal to', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Not equal to', rhs: RHS_TYPES.SINGLE }],
    ['<=', { label: 'Less than or equal to', rhs: RHS_TYPES.SINGLE }],
    ['>=', { label: 'Greater than or equal to', rhs: RHS_TYPES.SINGLE }],
    [EMPTY_OPERATORS.IS_NULL, { label: 'Is empty', rhs: RHS_TYPES.NULL }],
    [EMPTY_OPERATORS.IS_NOT_NULL, { label: 'Is not empty', rhs: RHS_TYPES.NULL }],
]);

export const DATE_OPERATORS = ['>', '<', '=', '<=', '>=', '!='];

export const DATE_OPERATIONS = new Map([
    ['<', { label: 'Before', rhs: RHS_TYPES.SINGLE }],
    ['>', { label: 'After', rhs: RHS_TYPES.SINGLE }],
    ['=', { label: 'Is', rhs: RHS_TYPES.SINGLE }],
    ['<=', { label: 'Before or on', rhs: RHS_TYPES.SINGLE }],
    ['>=', { label: 'After or on', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Not equal to', rhs: RHS_TYPES.SINGLE }],
]);

export const OPERATIONS = new Map([
    ['=', { label: 'Is', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Is not', rhs: RHS_TYPES.SINGLE }],
    ['IN', { label: 'Contains', rhs: RHS_TYPES.MULTIPLE }],
    ['NOT IN', { label: 'Does not contain', rhs: RHS_TYPES.MULTIPLE }],
    [EMPTY_OPERATORS.IS_NULL, { label: 'Is empty', rhs: RHS_TYPES.NULL }],
    [EMPTY_OPERATORS.IS_NOT_NULL, { label: 'Is not empty', rhs: RHS_TYPES.NULL }],
    ['>', { label: 'Greater than', rhs: RHS_TYPES.SINGLE }],
    ['<', { label: 'Less than', rhs: RHS_TYPES.SINGLE }],
    ['IN', { label: 'Contains', rhs: RHS_TYPES.MULTIPLE }],
    ['NOT IN', { label: 'Does not contain', rhs: RHS_TYPES.MULTIPLE }],
    ['=', { label: 'Equal to', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Not equal to', rhs: RHS_TYPES.SINGLE }],
    [EMPTY_OPERATORS.IS_NULL, { label: 'Is empty', rhs: RHS_TYPES.NULL }],
    [EMPTY_OPERATORS.IS_NOT_NULL, { label: 'Is not empty', rhs: RHS_TYPES.NULL }],
    ['<', { label: 'Before', rhs: RHS_TYPES.SINGLE }],
    ['>', { label: 'After', rhs: RHS_TYPES.SINGLE }],
    ['=', { label: 'Is', rhs: RHS_TYPES.SINGLE }],
    ['<=', { label: 'Before or on', rhs: RHS_TYPES.SINGLE }],
    ['>=', { label: 'After or on', rhs: RHS_TYPES.SINGLE }],
    ['!=', { label: 'Not equal to', rhs: RHS_TYPES.SINGLE }],
]);

export const LHS_CATEGORY_LIST = [
    { label: 'Loan', value: 'loan' },
    { label: 'Primary Customer', value: 'primary_customer' },
    { label: 'Co Applicant', value: 'co_applicant' },
    { label: 'Related Party', value: 'related_party' },
    { label: 'Guarantor', value: 'guarantor' },
    { label: 'Collateral', value: 'collateral' },
];

export const RHS_CONSTANT = 'constant';
