import React, { Fragment } from 'react';
import ServerDown from 'app/components/ServerDown';
import styled from 'styled-components';
import Auth from 'app/utils/Auth';
import ParsedLink from 'app/components/ParsedLink';

const ENVIRONMENT: any = ['development'];

const Message = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 89vh;
    .title {
        padding-top: 30px;
        font-size: 23px;
    }
    .description {
        padding-top: 20px;
        font-size: 14px;
        text-align: center;
        p {
            &:nth-child(2) {
                max-width: 900px;
            }
        }
    }
`;
const Button = styled.div`
    display: flex;
    padding: 40px 0px;
    button {
        font-size: 13px;
        font-weight: 400;
        margin: 0px 20px;
        background-color: #fbfbfb;
        padding: 6px 15px;
        border-radius: 3px;
        border:1px solid #85919F;
        &:hover {
            border 1px solid #183247;
        }
    }
`;
interface FallbackProps {
    error: any;
    changeErrorState: () => void;
    title?: string;
    description?: string;
    from: string;
    customContent?: string;
}

const FallbackUI: React.FC<FallbackProps> = ({ error, changeErrorState, title, description, from, customContent }): JSX.Element => {
    return (
        <Fragment>
            <Message>
                <ServerDown width={260} height={290} />
                <div className="title">{title || 'Something Went Wrong!'}</div>
                <div className="description">
                    {ENVIRONMENT.includes(process.env.REACT_APP_ENV) ? (
                        <>
                            <p>
                                <strong>Error Message: </strong>
                                {error.message}
                            </p>
                            <p>
                                <strong>Error Path: </strong>
                                {error.file}
                            </p>
                        </>
                    ) : (
                        description ||
                        'Try reloading the page, If the issue persists, try Logging out and login again, If nothing helps, Please report us!'
                    )}
                </div>
                {customContent || (
                    <Button>
                        <ParsedLink to="/">
                            <button>Go To Homepage</button>
                        </ParsedLink>
                        <ParsedLink to={from}>
                            <button onClick={changeErrorState}> Reload Page</button>
                        </ParsedLink>
                        <ParsedLink to={window.location.origin}>
                            <button onClick={() => Auth.logout()}>Logout</button>
                        </ParsedLink>
                    </Button>
                )}
            </Message>
        </Fragment>
    );
};

export default FallbackUI;
