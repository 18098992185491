import { combineReducers } from 'redux';
import transaction from './transaction';
import ptc from './ptc';

const internalUser = combineReducers({
    ptc,
    transaction,
});

export default internalUser;
