import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import { addDownloadDetails } from 'app/utils/TransactionUtils.tsx';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = null;

// actions
const createActionName = (name) => `app/ptc/transaction/details/${name}`;

export const TRANSACTION_GET = createActionName('TRANSACTION_GET');
export const GET_EXEC_DOCS_SUCCESS = createActionName('GET_EXEC_DOCS_SUCCESS');

export default function details(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_GET:
            return {
                ...state,
                ...action.data,
                documents: addDownloadDetails(_get(action, 'data')),
            };
        case GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        default:
            return state;
    }
}

const ptcBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

const unMapPTC = (data) => {
    const cdata = humps.camelizeKeys(data);
    return {
        ...cdata,
        clientId: cdata.customerId || (Array.isArray(cdata.customer) && cdata.customer.length > 0 && cdata.customer[0].id) || '',
        poolSelectionCriteria: {
            ...cdata.poolSelectionCriteria,
            cutoffDate: moment(cdata.poolSelectionCriteria.cutoffDate).format('DD MMM, YYYY'),
        },
    };
};

export function getDetails(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(ptcBaseUrl(id))
            .then((d) => unMapPTC(d))
            .then((data) => dispatch({ type: TRANSACTION_GET, data, id }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }),
            );
}

export function getPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (isFailed || !docs.length) {
                    return null;
                } else if (isGenerated) {
                    dispatch({ type: GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}
