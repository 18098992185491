import React from 'react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import { startCase } from 'lodash';
import humps from 'humps';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Card from 'app/products/UIComponents/Card';
import CardContent from 'app/products/UIComponents/Card/CardContent';
import CardHeader from 'app/products/UIComponents/Card/CardHeader';
import { CHART_TYPE, BAR_COLOR } from 'app/products/UIComponents/Chart/Constants';
import Chart from 'app/products/UIComponents/Chart';
import PoolVsPortfolio from './PoolVsPortfoliocuts';

import { CHART_WIDTH, CHART_HEIGHT } from 'app/products/Dashboards/Constants';
import { valuesToCrores } from 'app/utils/CommonUtils';
import { humanize } from 'app/utils/StringUtils';
import { getSortOrder } from 'app/products/Dashboards/Utils';
import { getColor } from '../Utils/colorSets';
import { DEFAULT_TEXT_COLOR } from '../Constants';

export const CHART_COLORS: Array<string> = ['#2C43AB', '#5669BC', '#808ECD', '#ABB4DD', '#EAECF7'];
export const POWrapper = styled.div`
    position: relative;
    font-family: Sofia Pro;
    margin-top: 30px;
    .recharts-legend-item {
        display: flex !important;
        align-items: center;
    }
    h6 {
        color: #677489;
        font-weight: 400;
        margin-bottom: 15px;
    }
`;

export const ChartsWrapper = styled.div<any>`
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: ${(props: any) => (props.noGrid ? 'none' : '1fr 1fr')};
    margin-bottom: 20px;
    overflow-x:auto;
    flex-direction: ${(props: any) => (props.columnWise ? 'column' : 'row')} 
    row-gap:  ${(props: any) => props.columnWise && '20px'} 
    & > div {
        flex: 1;
    }
    .recharts-legend-item {
        margin-bottom: 10px;
    }
    @media (max-width: 1075px) {
        flex-direction: column;
        row-gap: 20px;
    }
`;

const LegendTitle = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #384150;
`;

const renderToolTip = (districts: any, branches: any, branch_dist: any): string => {
    return (!_size(districts) && !_size(branches)) ||
        ((districts || []).some((d: any) => _get(d, 'name') === null) && (branches || []).some((d: any) => _get(d, 'name') === null))
        ? ''
        : `<p>
            ${
                !!_size(districts) && !(districts || []).some((d: any) => _get(d, 'name') === null)
                    ? `
                    <p>Top Districts</p>
                    <ul>
                        ${districts
                            .sort(getSortOrder('percent'))
                            .map((district: any, index: number) => {
                                if (index < 3)
                                    return `<li>
                                        ${index + 1}: ${humanize(_get(district, 'name').toLowerCase())}
                                    </li>`;
                                return ``;
                            })
                            .join('')}
                    </ul>
                    <br/>
                `
                    : ''
            }
            ${
                !!_size(branches) && !(branches || []).some((d: any) => _get(d, 'name') === null)
                    ? `
                    <div>
                    <p>Top Branches</p>
                    <ul>
                        ${branches
                            .sort(getSortOrder('percent'))
                            .map((district: any, index: number) => {
                                if (index < 3 && _size(district) && _get(district, 'name'))
                                    return `<li>
                                        ${index + 1}: ${humanize(_get(district, 'name', '').toLowerCase())} ${
                                        _size(branch_dist) ? `${'('}${startCase(branch_dist[humps.camelize(_get(district, 'name', ''))])}${')'}` : ''
                                    }
                                    </li>`;
                                return ``;
                            })
                            .join('')}
                    </ul>
                    </div>
                `
                    : ''
            }
        </p>`;
};

export const sortRange = (prop: any) => {
    return (a: any, b: any): number => {
        if (
            Number(
                _get(b, prop, '')
                    .replace('+', '')
                    .split(' - ')[0],
            ) <
            Number(
                _get(a, prop, '')
                    .replace('+', '')
                    .split(' - ')[0],
            )
        ) {
            return 1;
        } else if (
            Number(
                _get(b, prop, '')
                    .replace('+', '')
                    .split(' - ')[0],
            ) >
            Number(
                _get(a, prop, '')
                    .replace('+', '')
                    .split(' - ')[0],
            )
        ) {
            return -1;
        }
        return 0;
    };
};

export const StateChart = ({ data, isOriginal }: { data: any; isOriginal?: boolean }) => {
    const stateChartData: Array<any> = (isOriginal
        ? _get(data, 'consolidatedOriginalPoolInfo.state', [])
        : _get(data, 'loansSummary.consolidatedPoolInfo.state', [])
    )
        ?.sort(getSortOrder('percent'))
        .slice(0, 5)
        .map((st: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: st.name,
            value: st.percent * 100,
        }));

    return (
        <Card style={{ overflow: 'auto' }}>
            <CardHeader align="leftTop" heading="Top State Concentration"></CardHeader>
            <CardContent className="card-content">
                {stateChartData.length > 0 ? (
                    <>
                        <Chart
                            type={CHART_TYPE.PIE}
                            data={stateChartData}
                            width={CHART_WIDTH}
                            height={CHART_HEIGHT}
                            tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                            legend={{
                                layout: 'vertical',
                                align: 'right',
                                verticalAlign: 'middle',
                                content: <StateLegend legendData={stateChartData} data={data} />,
                            }}
                            series={[
                                {
                                    innerRadius: 85,
                                    outerRadius: 116,
                                    dataKey: 'value',
                                    fill: BAR_COLOR,
                                    label: {
                                        value: 'States',
                                        position: 'center',
                                    },
                                    cell: stateChartData.map((d: any, index: any) => ({
                                        key: `cell-${index}`,
                                        fill: CHART_COLORS[index % CHART_COLORS.length],
                                    })),
                                },
                            ]}
                        />
                    </>
                ) : (
                    <p className="no-data-text text-center">No data available</p>
                )}
            </CardContent>
        </Card>
    );
};

export const StateLegend = ({ legendData = [], stateChartData, data }: any) => {
    return (
        <>
            <LegendTitle>{`Top ${legendData?.length} States`}</LegendTitle>
            <ul className="custom-legend">
                {legendData.map((entry: any, index: any) => {
                    const { name } = entry;
                    const state = humps.camelize(name);
                    return (
                        <li key={`item-${index}`} className="recharts-legend-item">
                            <span style={{ background: entry.colors, width: '14px', height: '10px', marginRight: '4px', borderRadius: '3px' }} />
                            &nbsp;&nbsp;&nbsp;
                            <div
                                data-for={state}
                                className="new-legend"
                                data-tip={
                                    _get(data, `loansSummary.consolidatedPoolInfo.stateDistrictMapping.${state}`, []) ||
                                    _get(data, `loansSummary.consolidatedPoolInfo.stateBranchMapping.${state}`, [])
                                        ? renderToolTip(
                                              _get(data, `loansSummary.consolidatedPoolInfo.stateDistrictMapping.${state}`, []),
                                              _get(data, `loansSummary.consolidatedPoolInfo.stateBranchMapping.${state}`, []),
                                              _get(data, `loansSummary.consolidatedPoolInfo.branchDistrictMapping`, []),
                                          )
                                        : null
                                }
                                data-html={true}
                            >
                                <span style={{ color: getColor('primary', DEFAULT_TEXT_COLOR), fontWeight: 600 }}>
                                    {_get(entry, 'value', 0).toFixed(2)}%
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: '#4A5567' }}>{humanize(state)}</span>
                            </div>
                            <ReactTooltip id={state} place="top" type="dark" effect="solid" html={true} /> <br />
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
const PoolOverview = ({ data, columnWise, showPoolVsPortFolio = true, tempFilter = false }: any): JSX.Element => {
    const stateChartData: Array<any> = (tempFilter
        ? _get(data, 'consolidatedPoolInfo.state') || []
        : _get(data, 'loansSummary.consolidatedPoolInfo.state') || []
    )
        .sort(getSortOrder('percent'))
        .slice(0, 5)
        .map((st: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: st.name,
            value: st.percent * 100,
        }));
    const districtChartData: Array<any> = (tempFilter
        ? _get(data, 'consolidatedPoolInfo.district') || []
        : _get(data, 'loansSummary.consolidatedPoolInfo.district') || []
    )
        .sort(getSortOrder('percent'))
        .slice(0, 5)
        .map((st: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: st.name,
            value: st.percent * 100,
        }));
    const branchChartData: Array<any> = (tempFilter
        ? _get(data, 'consolidatedPoolInfo.branch') || []
        : _get(data, 'loansSummary.consolidatedPoolInfo.branch') || []
    )
        .sort(getSortOrder('percent'))
        .slice(0, 5)
        .map((st: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: st.name,
            value: st.percent * 100,
        }));

    const posChartData: Array<any> = (_get(data, 'loansSummary.posSeasoningMapping') || [])
        .sort(sortRange('range'))
        .map((st: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: st.range,
            value: st.percent * 100,
        }));

    const renderPosLegend = (value: any, entry: any) => {
        const { value: v, payload } = entry;
        return (
            <div className="new-legend">
                <span style={{ color: getColor('primary', DEFAULT_TEXT_COLOR), fontWeight: 600 }}>
                    &nbsp;&nbsp;&nbsp;{(_get(payload, 'percent', 0) * 100).toFixed(2)}%
                </span>
                &nbsp;&nbsp;&nbsp;
                <span style={{ color: '#4A5567' }}>{v} Installments</span>
            </div>
        );
    };
    return (
        <POWrapper>
            {!!_size(posChartData) || !!_size(stateChartData) ? (
                <ChartsWrapper columnWise={columnWise}>
                    {!!_size(stateChartData) ? <StateChart data={data} /> : <div style={{ flex: 1 }}></div>}
                    {!!_size(posChartData) ? (
                        <Card style={{ overflow: 'auto' }}>
                            <CardHeader align="leftTop" heading="POS vs Seasoning"></CardHeader>
                            <CardContent className="card-content">
                                {posChartData.length > 0 ? (
                                    <>
                                        <Chart
                                            type={CHART_TYPE.PIE}
                                            data={posChartData}
                                            width={CHART_WIDTH}
                                            height={CHART_HEIGHT}
                                            tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                            legend={{
                                                layout: 'vertical',
                                                align: 'right',
                                                verticalAlign: 'middle',
                                                formatter: renderPosLegend,
                                            }}
                                            series={[
                                                {
                                                    innerRadius: 85,
                                                    outerRadius: 116,
                                                    dataKey: 'value',
                                                    fill: BAR_COLOR,
                                                    label: {
                                                        value: `${valuesToCrores(
                                                            _get(data, 'loansSummary.posSeasoningMapping', [])
                                                                .map((a: any) => a.principal)
                                                                .reduce((a: any, b: any) => {
                                                                    return a + b;
                                                                }),
                                                            2,
                                                        )} Crore(s)`,
                                                        position: 'center',
                                                    },
                                                    cell: posChartData.map((d: any, index: any) => ({
                                                        key: `cell-${index}`,
                                                        fill: CHART_COLORS[index % CHART_COLORS.length],
                                                    })),
                                                },
                                            ]}
                                        />
                                    </>
                                ) : (
                                    <p className="no-data-text text-center">No data available</p>
                                )}
                            </CardContent>
                        </Card>
                    ) : (
                        <></>
                    )}

                    <Card style={{ overflow: 'auto' }}>
                        <CardHeader align="leftTop" heading="Top District Concentration"></CardHeader>
                        <CardContent className="card-content">
                            {districtChartData.length > 0 ? (
                                <>
                                    <Chart
                                        type={CHART_TYPE.PIE}
                                        data={districtChartData}
                                        width={CHART_WIDTH}
                                        height={CHART_HEIGHT}
                                        tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                        legend={{
                                            layout: 'vertical',
                                            align: 'right',
                                            verticalAlign: 'middle',
                                            content: <StateLegend legendData={districtChartData} />,
                                        }}
                                        series={[
                                            {
                                                innerRadius: 85,
                                                outerRadius: 116,
                                                dataKey: 'value',
                                                fill: BAR_COLOR,
                                                label: {
                                                    value: 'Districts',
                                                    position: 'center',
                                                },
                                                cell: districtChartData.map((d: any, index: any) => ({
                                                    key: `cell-${index}`,
                                                    fill: CHART_COLORS[index % CHART_COLORS.length],
                                                })),
                                            },
                                        ]}
                                    />
                                </>
                            ) : (
                                <p className="no-data-text text-center">No data available</p>
                            )}
                        </CardContent>
                    </Card>
                    <Card style={{ overflow: 'auto' }}>
                        <CardHeader align="leftTop" heading="Top Branches Concentration"></CardHeader>
                        <CardContent className="card-content">
                            {branchChartData.length > 0 ? (
                                <>
                                    <Chart
                                        type={CHART_TYPE.PIE}
                                        data={branchChartData}
                                        width={CHART_WIDTH}
                                        height={CHART_HEIGHT}
                                        tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                        legend={{
                                            layout: 'vertical',
                                            align: 'right',
                                            verticalAlign: 'middle',
                                            content: <StateLegend legendData={branchChartData} />,
                                        }}
                                        series={[
                                            {
                                                innerRadius: 85,
                                                outerRadius: 116,
                                                dataKey: 'value',
                                                fill: BAR_COLOR,
                                                label: {
                                                    value: 'Branches',
                                                    position: 'center',
                                                },
                                                cell: branchChartData.map((d: any, index: any) => ({
                                                    key: `cell-${index}`,
                                                    fill: CHART_COLORS[index % CHART_COLORS.length],
                                                })),
                                            },
                                        ]}
                                    />
                                </>
                            ) : (
                                <p className="no-data-text text-center">No data available</p>
                            )}
                        </CardContent>
                    </Card>
                </ChartsWrapper>
            ) : (
                <></>
            )}
            {showPoolVsPortFolio && (
                <div>
                    <PoolVsPortfolio data={data} />
                </div>
            )}
        </POWrapper>
    );
};

export default PoolOverview;
