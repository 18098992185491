import React, { Component } from 'react';
import FallbackUI from 'app/components/ErrorBoundary/FallbackUI';
import Logger from 'app/utils/Logger';

interface ErrorBoundaryState {
    hasError: boolean;
    error: any;
}
interface ErrorBoundaryProps {
    from: string;
    FallbackComponent?: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: {} };
    }
    static getDerivedStateFromError(): { hasError: boolean } {
        return { hasError: true };
    }

    componentDidCatch(err: any, errInfo: any) {
        this.setState({ error: { message: err.message, file: errInfo.componentStack } });
        Logger.logException(err, { errInfo });
    }
    componentDidUpdate(prevProps: ErrorBoundaryProps) {
        if (prevProps.from !== this.props.from) {
            this.setState({ hasError: false });
        }
    }
    setErrorState = () => this.setState({ hasError: false });

    render() {
        const { hasError, error } = this.state;
        const { FallbackComponent, from } = this.props;
        if (hasError) {
            {
                return FallbackComponent ? (
                    <FallbackComponent error={error} changeErrorState={this.setErrorState} />
                ) : (
                    <FallbackUI error={error} changeErrorState={this.setErrorState} from={from} />
                );
            }
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
