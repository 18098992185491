import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    isFetching: false,
};

// actions
const createActionName = name => `app/cps/client/${name}`;

export const FETCHING_DATA = createActionName('FETCHING_DATA');

export const OVERVIEW_GET_SUCCESS = createActionName('OVERVIEW_UPDATE_SUCCESS');
export const KYC_DOC_GET_SUCCESS = createActionName('KYC_DOC_GET_SUCCESS');
export const FINANCIAL_RESULT_GET_SUCCESS = createActionName('FINANCIAL_RESULT_GET_SUCCESS');
export const PORTFOLIO_CUT_GET_SUCCESS = createActionName('PORTFOLIO_CUT_GET_SUCCESS');

// reducer
export default function customer(state = initialState, action) {
    switch (action.type) {
    case FETCHING_DATA:
        return {
            ...state,
            isFetching: action.status,
        };
    case OVERVIEW_GET_SUCCESS:
        return {
            ...state,
            overview: action.data,
        };
    case KYC_DOC_GET_SUCCESS:
        return {
            ...state,
            kycDocuments: action.data,
        };
    case FINANCIAL_RESULT_GET_SUCCESS:
        return {
            ...state,
            financialResults: action.data,
        };
    case PORTFOLIO_CUT_GET_SUCCESS:
        return {
            ...state,
            portfolioCuts: action.data,
        };
    default:
        return state;
    }
}

export function getOverview(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/overview`)
            .then(d => (d && d.overview && humps.camelizeKeys(d.overview)) || {})
            .then(d => dispatch({ type: OVERVIEW_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving overview', m);
                });
            });
}

export function getKycDocuments(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/kyc_document`)
            .then(d => (d && d.kyc_documents && humps.camelizeKeys(d.kyc_documents)) || {})
            .then(d => dispatch({ type: KYC_DOC_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving KYC Document', m);
                });
            });
}

export function getFinancialResult(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/financial_result`)
            .then(d => (d && d.financial_results && humps.camelizeKeys(d.financial_results)) || {})
            .then(d => dispatch({ type: FINANCIAL_RESULT_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving KYC Document', m);
                });
            });
}

export function getPortfolioCuts(customerId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/customers/${customerId}/portfolio_cut`)
            .then(d => (d && d.portfolio_cuts && humps.camelizeKeys(d.portfolio_cuts)) || {})
            .then(d => dispatch({ type: PORTFOLIO_CUT_GET_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving portfolio cut', m);
                });
            });
}
