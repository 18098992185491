import { push } from 'react-router-redux';
import humps from 'humps';
import _has from 'lodash/has';
import { logoutUser } from '../actions/AuthedActions';
import Auth from '../utils/Auth';
import Logger from './Logger';
import history from './myHistory';
import { MFAService } from '@yubi/yb-module-auth';
import { CREDAVENUE_DOMAIN, YUBI_DOMAIN } from 'app/constants/Constants';
import { getCurrentDomain } from './CommonUtils';
import { isDomainCorrect } from './graphql';

const BLACKLISTED_API = /auth-api.credavenue.com/g;

function getHeaders(multiPart, addParam = {}, removeContentSecurintyPolicy = false) {
    const roleHeaders = getGroupHeaders();
    const accessToken = Auth.getAccessToken();
    const mfaToken = MFAService.getMFAToken();
    const headers = {
        'Content-Security-Policy': `default-src 'self' *.${process.env.REACT_APP_MP_DOMAIN_NEW}`,
    };
    if (removeContentSecurintyPolicy) delete headers['Content-Security-Policy'];
    if (!multiPart) {
        headers.Accept = 'application/json';
        headers['Content-Type'] = 'application/json';
    }
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }
    if (mfaToken) {
        headers['Mfa-Token'] = mfaToken;
    }
    return {
        ...headers,
        ...roleHeaders,
        ...addParam,
    };
}

function getBody(body = {}, isJsonBody = true) {
    return isJsonBody ? JSON.stringify(body) : body;
}

export function getGroupHeaders() {
    const groupHeader = {};
    const getActiveGroup = Auth.getActiveUserGroup();
    if (_has(getActiveGroup, 'entity_id') && _has(getActiveGroup, 'group')) {
        groupHeader['Current-Entity-Id'] = getActiveGroup.entity_id;
        groupHeader['Current-Group'] = humps.decamelize(getActiveGroup.group, {
            separator: '_',
        });
    }
    return groupHeader;
}

export function requestPayload(method, bodyParam, additionalHeaders = {}, isJsonBody, isMultipart = false, removeContentSecurityPolicy = false) {
    const fetchParam = {};
    fetchParam.headers = getHeaders(isMultipart, additionalHeaders, removeContentSecurityPolicy);
    if (bodyParam) {
        fetchParam.body = getBody(bodyParam, isJsonBody);
    }
    fetchParam.method = method;
    return fetchParam;
}

export function checkStatus(response) {
    const { status, statusText } = response;
    if (status >= 200 && status < 300) return response;
    const error = new Error(statusText);
    error.response = response;
    if (status >= 500 || (status === 422 && !response?.url.match(BLACKLISTED_API))) {
        Logger.logException(error);
    }
    throw error;
}

export function parseJSON(response) {
    return response.json();
}

export function handleErrorV2(dispatch, ex, defaultMsg = 'Please try again...') {
    const status = ex && ex.response && ex.response.status;
    if (typeof dispatch === 'function') {
        if (status === 401) {
            history.push('/logout');
        } else if (status === 403) {
            history.push('/forbidden');
            return Promise.resolve("You don't seem to have access to this part of the app");
        } else if (status === 432) {
            history.push('/ip-address-blocked');
            return Promise.resolve("You don't seem to have access to this part of the app");
        }
    }
    if (!status) {
        return handleError(ex, 'Login Restricted! Please Whitelist *.go-yubi.com');
    }
    return handleError(ex, defaultMsg);
}

export function handleError(ex, defaultMsg = 'Please try again...') {
    return new Promise((resolve) => {
        const status = ex && ex.response && ex.response.status;
        if (!ex?.response) {
            resolve(defaultMsg);
        }
        if (status === 401) {
            history.push('/logout');
        }

        if (status === 403) {
            history.push('/forbidden');
            return resolve("You don't seem to have access to this part of the app");
        } else if (status === 432) {
            history.push('/ip-address-blocked');
            return Promise.resolve("You don't seem to have access to this part of the app");
        } else {
            ex.response
                .json()
                .then((data) => {
                    if (data.error) {
                        if (typeof data.error.message === 'string') {
                            resolve(data.error.message);
                        }
                    }
                    resolve(defaultMsg);
                })
                .catch((e) => {
                    Logger.logException(e);
                });
        }
    });
}

export function poll(fn, timeout, interval = 100) {
    const endTime = Number(new Date()) + (timeout || 2000);

    const checkCondition = (resolve, reject) => {
        // If the condition is met, we're done!
        const result = fn();

        result
            .then((r) => {
                if (r instanceof Error) {
                    reject(r);
                } else if (r) {
                    resolve(r);
                } else if (Number(new Date()) < endTime) {
                    // If the condition isn't met but the timeout hasn't elapsed, go again
                    setTimeout(checkCondition, interval, resolve, reject);
                } else {
                    // Didn't match and too much time, reject!
                    reject(new Error('timeout'));
                }
            })
            .catch((e) => {
                reject(e);
            });
    };

    return new Promise(checkCondition);
}

const checkTokenAndFetch = (url, payload, signal = null) =>
    new Promise((resolve, reject) => {
        try {
            resolve(
                fetch(getEnv(url), {
                    ...payload,
                    signal,
                }),
            );
        } catch (e) {
            reject(e);
        }
    });

export const getJSON = (url, signal, body, additionalHeaders) => {
    return checkTokenAndFetch(url, requestPayload('GET', body, additionalHeaders), signal)
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            if (!signal) throw error;
        });
};

export const putJSON = (url, body, additionalHeaders) =>
    checkTokenAndFetch(url, requestPayload('PUT', body, additionalHeaders))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const patchJSON = (url, body) =>
    checkTokenAndFetch(url, requestPayload('PATCH', body))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const postJSON = (url, body, additionalHeaders, jsonBody, isMultiPart, removeContentSecurityPolicy) =>
    checkTokenAndFetch(url, requestPayload('POST', body, additionalHeaders, jsonBody, isMultiPart, removeContentSecurityPolicy))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const postFormData = (url, body) =>
    checkTokenAndFetch(url, requestPayload('POST', body, {}, false, true))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const putFormData = (url, body) =>
    checkTokenAndFetch(url, requestPayload('PATCH', body, {}, false, true))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const deleteJSON = (url, body) =>
    checkTokenAndFetch(url, requestPayload('DELETE', body))
        .then(checkStatus)
        .then(parseJSON)
        .catch((ex) => {
            const error = { ...ex };
            throw error;
        });

export const getEnv = (endPoint, resolveDomain = true) => {
    if (endPoint?.startsWith('/')) return endPoint;
    if (resolveDomain && getCurrentDomain() === YUBI_DOMAIN) {
        return endPoint?.replace(CREDAVENUE_DOMAIN, YUBI_DOMAIN);
    }
    if (resolveDomain && getCurrentDomain() === CREDAVENUE_DOMAIN) {
        return endPoint?.replace(YUBI_DOMAIN, CREDAVENUE_DOMAIN);
    }
    return endPoint;
};
