import { toastr } from 'react-redux-toastr';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _chunk from 'lodash/chunk';
import _findIndex from 'lodash/findIndex';
import * as fetchUtils from 'app/utils/FetchUtils';
import { croresToValues, valuesToCrores } from 'app/utils/CommonUtils';
import { humanize } from 'app/utils/StringUtils';
import { createLoadingSelector } from 'app/reducers/loading';
import { actions } from 'app/reducers/ptcv1/transaction/index';
import { initStructuringValue, initAutoStrPrefillValue, initAutoStructurePref } from 'app/actors/admin/ducks/ptcv1/structuring';
import { GET_RATING_MODEL_SUCCESS } from './lossModelling';
import { mapAssignCounterparties, isCustomWaterfall, mapOptions } from 'app/utils/TransactionUtils';
import { normalizeCosting } from 'app/actors/admin/ducks/ptcv1/costing';
import { LTV_POOL_TYPES, PRODUCT_KEYS, TRANSACTION_STATE } from 'app/constants/Constants';
import { EventTypes } from 'app/utils/reduxAmplitude';
import { PRODUCT_RELEASE_CLICK } from 'app/constants/AmplitudeActions';
import { getDetails } from 'app/reducers/ptcv1/transaction/details';
import { mapValuesGlobal } from 'app/products/Shared/Tools/Pools/PoolStructuring/utils';
import { getActiveUser } from 'app/actions/AuthedActions';

const { isInvestor } = getActiveUser();

const initialState = {
    extraDocuments: [],
    pollAgain: true,
    structure: initStructuringValue,
    scrubbing: {
        loading: true,
        edit: true,
        selectedPoolState: [],
    },
    poolSelectionCriteria: {
        seasoning: true,
        concentrations: {
            branchName: 100,
            stateName: 100,
            districtName: 100,
        },
        loansToReject: [
            {
                ids: null,
                reason: null,
            },
        ],
        par: {
            parFilters: {
                par0: 100,
                par30: 100,
                par60: 100,
                par90: 100,
            },
            rangedParFilters: [
                {
                    from: null,
                    to: null,
                    cutoff: null,
                },
            ],
        },
    },
    lossEstimationSuggestions: [],
    lossEstimationPreferences: {},
};

const DISCRETE_PIVOT = 'discrete';
const CONTINUOUS_PIVOT = 'continuous';

export const baseURL = (id) => `${process.env.REACT_APP_MP_API_HOST}/ptcs/${id}`;

// actions
export const createActionName = (name) => `app/admin/ptcv1/${name}`;

export const TRANSACTION_GET = createActionName('TRANSACTION_GET');

export const TRANSACTION_GET_REQUEST = createActionName('TRANSACTION_GET_REQUEST');
export const TRANSACTION_GET_SUCCESS = createActionName('TRANSACTION_GET_SUCCESS');
export const TRANSACTION_GET_FAILURE = createActionName('TRANSACTION_GET_FAILURE');

export const TRANSACTION_POOLFILTER_REQUEST = createActionName('TRANSACTION_POOLFILTER_REQUEST');
export const TRANSACTION_POOLFILTER_SUCCESS = createActionName('TRANSACTION_POOLFILTER_SUCCESS');
export const TRANSACTION_POOLFILTER_FAILURE = createActionName('TRANSACTION_POOLFILTER_FAILURE');

export const TRANSACTION_RELEASE_REQUEST = createActionName('TRANSACTION_RELEASE_REQUEST');
export const TRANSACTION_RELEASE_SUCCESS = createActionName('TRANSACTION_RELEASE_SUCCESS');
export const TRANSACTION_RELEASE_FAILURE = createActionName('TRANSACTION_RELEASE_FAILURE');

export const WATERFALL_REUPLOAD_REQUEST = createActionName('WATERFALL_REUPLOAD_REQUEST');
export const WATERFALL_REUPLOAD_SUCCESS = createActionName('WATERFALL_REUPLOAD_SUCCESS');
export const WATERFALL_REUPLOAD_FAILURE = createActionName('WATERFALL_REUPLOAD_FAILURE');

export const DOCUMENT_UPLOAD_REQUEST = createActionName('DOCUMENT_UPLOAD_REQUEST');
export const DOCUMENT_UPLOAD_SUCCESS = createActionName('DOCUMENT_UPLOAD_SUCCESS');
export const DOCUMENT_UPLOAD_FAILURE = createActionName('DOCUMENT_UPLOAD_FAILURE');

export const DOCUMENTS_FETCH_REQUEST = createActionName('DOCUMENTS_FETCH_REQUEST');
export const DOCUMENTS_FETCH_SUCCESS = createActionName('DOCUMENTS_FETCH_SUCCESS');

export const ADHOC_CREATE_REQUEST = createActionName('ADHOC_CREATE_REQUEST');
export const ADHOC_CREATE_SUCCESS = createActionName('ADHOC_CREATE_SUCCESS');
export const ADHOC_CREATE_FAILURE = createActionName('ADHOC_CREATE_FAILURE');

export const ADHOC_UPDATE_REQUEST = createActionName('ADHOC_UPDATE_REQUEST');
export const ADHOC_UPDATE_SUCCESS = createActionName('ADHOC_UPDATE_SUCCESS');
export const ADHOC_UPDATE_FAILURE = createActionName('ADHOC_UPDATE_FAILURE');

export const PLACEHOLDER_REMOVE_REQUEST = createActionName('PLACEHOLDER_REMOVE_REQUEST');
export const PLACEHOLDER_REMOVE_SUCCESS = createActionName('PLACEHOLDER_REMOVE_SUCCESS');
export const PLACEHOLDER_REMOVE_FAILURE = createActionName('PLACEHOLDER_REMOVE_FAILURE');

export const FETCH_SCRUBB_REQUEST = createActionName('FETCH_SCRUBB_REQUEST');
export const FETCH_SCRUBB_SUCCESS = createActionName('FETCH_SCRUBB_SUCCESS');
export const FETCH_SCRUBB_FAILURE = createActionName('FETCH_SCRUBB_FAILURE');

export const LOAN_SCRUBB_REQUEST = createActionName('LOAN_SCRUBB_REQUEST');
export const LOAN_SCRUBB_SUCCESS = createActionName('LOAN_SCRUBB_SUCCESS');
export const LOAN_SCRUBB_FAILURE = createActionName('LOAN_SCRUBB_FAILURE');

export const TRANS_FINALIZE_REQUEST = createActionName('TRANS_FINALIZE_REQUEST');
export const TRANS_FINALIZE_SUCCESS = createActionName('TRANS_FINALIZE_SUCCESS');

export const TRANS_RELEASE_REQUEST = createActionName('TRANS_FINALIZE_REQUEST');
export const TRANS_RELEASE_SUCCESS = createActionName('TRANS_FINALIZE_SUCCESS');

export const ADD_ATTACHMENT_REQUEST = createActionName('ADD_ATTACHMENT_REQUEST');
export const ADD_ATTACHMENT_SUCCESS = createActionName('ADD_ATTACHMENT_SUCCESS');
export const ADD_ATTACHMENT_FAILURE = createActionName('ADD_ATTACHMENT_FAILURE');

export const DELETE_ATTACHMENT_REQUEST = createActionName('DELETE_ATTACHMENT_REQUEST');
export const DELETE_ATTACHMENT_SUCCESS = createActionName('DELETE_ATTACHMENT_SUCCESS');
export const DELETE_ATTACHMENT_FAILURE = createActionName('DELETE_ATTACHMENT_FAILURE');

export const LOAN_SCRUBB_EDIT = createActionName('LOAN_SCRUBB_EDIT');

export const GET_EXEC_DOCS_SUCCESS = createActionName('GET_EXEC_DOCS_SUCCESS');

export const scrubbingLoader = createLoadingSelector([createActionName('FETCH_SCRUBB')]);
export const isStrUpdating = createLoadingSelector([createActionName('STRUCTURING')]);
export const releaseLoading = createLoadingSelector([createActionName('TRANSACTION_RELEASE')]);
export const transLoading = createLoadingSelector([createActionName('TRANSACTION_GET')]);

export const FUNDING_STATUS_REQUEST = createActionName('FUNDING_STATUS_REQUEST');
export const FUNDING_STATUS_SUCCESS = createActionName('FUNDING_STATUS_SUCCESS');
export const FUNDING_STATUS_FAILURE = createActionName('FUNDING_STATUS_FAILURE');

export const STRUCTURING_REQUEST = createActionName('STRUCTURING_REQUEST');
export const STRUCTURING_SUCCESS = createActionName('STRUCTURING_SUCCESS');
export const STRUCTURING_FAILURE = createActionName('STRUCTURING_FAILURE');

function splitPivots(pivots, type) {
    if (!pivots && !_get(pivots, '[0]')) return [];
    return pivots.filter((f) => f.type === type).map((m) => ({ ...m, customName: humanize(m.name) }));
}

const normalizeStructuring = (structuring = {}, state) => {
    if (!structuring) return {};
    return {
        ...structuring,
        tranches: _get(structuring, 'tranches', []).map((t) => ({
            ...t,
            value: Number(_get(t, 'value', 0).toFixed(2)),
            ...(isInvestor && state?.isSelfCreated ? { trancheSeries: 'A1' } : {}),
        })),
        trancheCount: isInvestor && state?.isSelfCreated ? 1 : _get(structuring, 'tranches', []).length,
        schedule: {
            ..._get(structuring, 'schedule', {}),
            ...(_get(structuring, 'schedule.principalRepayment')
                ? { principalRepayment: structuring.schedule.principalRepayment }
                : _get(state, 'lossEstimationPreferences.principalExpectation')
                ? { principalRepayment: _get(state, 'lossEstimationPreferences.principalExpectation') }
                : { principalRepayment: 'expected' }),
        },
    };
};

export const splitLoanIds = (loansToReject = []) => {
    const result = [];
    loansToReject.forEach((loan) => {
        const ids = (_get(loan, 'ids') || '').split(',');

        if (_size(ids) < 1000) {
            result.push(loan);
        } else {
            const chunkedIds = _chunk(ids, 1000);
            chunkedIds.forEach((cIds) => {
                result.push({ ids: cIds.join(','), reason: _get(loan, 'reason') });
            });
        }
    });
    return result;
};

const getAutoStructuringPref = (data) => {
    const { lossEstimationPrefs = {}, ...preference } = data;
    let resultPref;
    let lossPref;
    if (_isEmpty(preference)) {
        resultPref = initAutoStructurePref.autoStructuringPreferences;
    } else {
        resultPref = {
            ...preference,
            tranche_prefs: initAutoStructurePref.autoStructuringPreferences.tranchePrefs.map((t, i) => {
                let tranche = {};
                if (_has(preference, `tranche_prefs[${i}].series`)) {
                    tranche = { ...preference.tranche_prefs[i], isEnabled: true };
                } else {
                    tranche = { ...t, isEnabled: false };
                }
                return tranche;
            }),
            isCe: !!_get(preference, 'total_ce_required'),
            guarantee: _has(preference, 'guarantees'),
            guarantees: _has(preference, 'guarantees') ? preference.guarantees : initAutoStructurePref.guarantees,
        };
        lossPref = {
            ...lossEstimationPrefs,
            ...(!_isEmpty(_get(lossEstimationPrefs, 'eis_trap'))
                ? {
                      eis_trap: humps.camelize(Object.keys(lossEstimationPrefs.eis_trap)[0]),
                  }
                : {}),
            ...(_get(lossEstimationPrefs, 'eis_trap.trigger') ? { trigger: lossEstimationPrefs.eis_trap.trigger } : {}),
        };
    }
    return { autoStructuringPreferences: resultPref, lossEstimationPreferences: lossPref };
};

const computeAutoStructuring = (appliedStructure) => {
    const prefillValues = { ...initAutoStrPrefillValue };
    const trancheYields = _get(appliedStructure, 'trancheYields', []).filter((d) => d.size > 0);
    const tranches = trancheYields.map((d, i) => {
        const tranche = {
            investors: [],
        };
        tranche.trancheSeries = _get(d, 'series', '').toUpperCase();
        tranche.size = Number(_get(d, 'size', null));
        tranche.value = Number(_get(d, 'size', null));
        tranche.rating = i === 0 ? _get(appliedStructure, 'rating', null) : '';
        tranche.investorUnit = 'percent';
        tranche.investors.push({
            value: 100,
            yield: Number(_get(d, 'yield', null)),
            interestMethod: _get(d, 'interestMethod', null),
            investorSeries: `${_get(d, 'series', '').toUpperCase()}a`,
        });
        return tranche;
    });
    prefillValues.trancheCount = tranches.length;
    prefillValues.tranches = tranches;
    prefillValues.creditEnhancement.cashCollateral.value = !_isNull(appliedStructure.cashCollateral)
        ? Number(_get(appliedStructure, 'cashCollateral', 0).toFixed(2))
        : null;
    prefillValues.creditEnhancement.slce.value = !_isNull(appliedStructure.guarantee)
        ? Number(_get(appliedStructure, 'guarantee', 0).toFixed(2))
        : null;
    prefillValues.creditEnhancement.slce.servicerFee = !_isNull(appliedStructure.gFee) ? Number(_get(appliedStructure, 'gFee', 0).toFixed(2)) : null;
    return prefillValues;
};

const getStructuring = (state, data, tempUpdate = false) => {
    let structureVal = { ...state.structure };
    let prefillStrForm = {};

    if (!_isEmpty(data.appliedStructure)) {
        prefillStrForm = computeAutoStructuring(data.appliedStructure);
    }

    if (tempUpdate) {
        structureVal = {
            ..._get(data, 'structuring', {}),
            ...prefillStrForm,
            creditEnhancement: { ..._get(data, 'structuring.creditEnhancement', {}), ...prefillStrForm.creditEnhancement },
        };
    } else if (_isEmpty(data.structuring) && !_isEmpty(data.appliedStructure)) {
        structureVal = {
            ...initStructuringValue,
            ...prefillStrForm,
            creditEnhancement: { ...initStructuringValue.creditEnhancement, ...prefillStrForm.creditEnhancement },
        };
    } else if (!_isEmpty(data.structuring)) {
        structureVal = { ...data.structuring };
    }

    return normalizeStructuring(structureVal, data);
};

export default function ptc(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_GET: {
            return {
                ...state,
                ...action.data,
                pollAgain: _get(action, 'data.pollingInProgress', false) ? false : action.data.filterProcessing,
                principal: action.data.poolPrincipal,
                documents: _get(action, 'data.documents'),
                poolFiles: _get(action, 'data.poolFiles'),
                filterSummary: null,
                structure: getStructuring(state, _get(action, 'data', {}), _get(action, 'tempUpdate', false)),
                poolSelectionCriteria: _get(action, 'data.poolSelectionCriteria')
                    ? {
                          ...state.poolSelectionCriteria,
                          ...action.data.poolSelectionCriteria,
                          soonToMature: _get(action, 'data.poolSelectionCriteria.soonToMature')
                              ? _get(action, `data.poolSelectionCriteria.soonToMature`)
                              : _get(action, 'data.poolSelectionCriteria.cutOffDate'),
                          ...(_get(action, 'data.poolSelectionCriteria.pivots')
                              ? {
                                    pivots: {
                                        discretePivots: splitPivots(action.data.poolSelectionCriteria.pivots, DISCRETE_PIVOT),
                                        continuousPivots: splitPivots(action.data.poolSelectionCriteria.pivots, CONTINUOUS_PIVOT),
                                    },
                                }
                              : {
                                    pivots: {
                                        discretePivots: splitPivots(action.data.possiblePivots, DISCRETE_PIVOT).filter((f) => f.default),
                                    },
                                }),
                          par: {
                              ...state.poolSelectionCriteria.par,
                              ...action.data.poolSelectionCriteria.par,
                              ...(_get(action, 'data.poolSelectionCriteria.par.year')
                                  ? { year: { label: action.data.poolSelectionCriteria.par.year, value: action.data.poolSelectionCriteria.par.year } }
                                  : {}),
                              ...(_get(action, 'data.poolSelectionCriteria.par.quarter')
                                  ? {
                                        quarter: {
                                            label: action.data.poolSelectionCriteria.par.quarter,
                                            value: action.data.poolSelectionCriteria.par.quarter,
                                        },
                                    }
                                  : {}),
                              ...(_get(action, 'data.poolSelectionCriteria.par.rangedParFilters')
                                  ? { rangedParFilters: action.data.poolSelectionCriteria.par.rangedParFilters }
                                  : { rangedParFilters: state.poolSelectionCriteria.par.rangedParFilters }),
                          },
                          loansToReject: splitLoanIds(
                              _get(action, 'data.poolSelectionCriteria.loansToReject', initialState.poolSelectionCriteria.loansToReject),
                          ),
                      }
                    : state.poolSelectionCriteria,
                ...(_get(action, 'data.possiblePivots[0]')
                    ? { possiblePivots: action.data.possiblePivots.map((m) => ({ ...m, customName: humanize(m.name) })) }
                    : {}),
            };
        }
        case TRANSACTION_POOLFILTER_SUCCESS: {
            const continuous = splitPivots(state.possiblePivots, CONTINUOUS_PIVOT);
            return {
                ...state,
                ...action.data,
                principal: action.data.poolPrincipal,
                structure: getStructuring(state, _get(action, 'data', {}), _get(action, 'tempUpdate', false)),
                poolSelectionCriteria: _get(action, 'data.poolSelectionCriteria')
                    ? {
                          ...state.poolSelectionCriteria,
                          par: {
                              ...state.poolSelectionCriteria.par,
                              ...(_get(state, 'poolSelectionCriteria.par.rangedParFilters')
                                  ? { rangedParFilters: state.poolSelectionCriteria.par.rangedParFilters }
                                  : { rangedParFilters: initialState.poolSelectionCriteria.par.rangedParFilters }),
                          },
                      }
                    : state.poolSelectionCriteria,
                ...(_get(state, 'possiblePivots[0]')
                    ? {
                          pivotOptions: {
                              discrete: splitPivots(state.possiblePivots, DISCRETE_PIVOT),
                              continuous: continuous.length > 0 ? continuous.map((m) => ({ ...m, range: { from: '', to: '' } })) : [],
                          },
                      }
                    : {}),
                filterSummary: action.data.filterSummary,
            };
        }
        case TRANSACTION_POOLFILTER_FAILURE: {
            return {
                ...state,
                pollAgain: true,
            };
        }
        case TRANSACTION_POOLFILTER_REQUEST: {
            return {
                ...state,
                poolFiles: [],
                pollAgain: false,
            };
        }
        case GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        case ADD_ATTACHMENT_SUCCESS: {
            const { data: newAttachment } = action;
            let cloneExtraDocs = _get(state, 'extraDocuments', []);
            const attachmentIndex = _findIndex(cloneExtraDocs, ['files[0].fileId', _get(newAttachment, 'fileId')]);

            if (attachmentIndex >= 0) {
                cloneExtraDocs[attachmentIndex] = { files: [{ ...newAttachment }] };
            } else {
                cloneExtraDocs = [{ files: [{ ...newAttachment }] }, ...cloneExtraDocs];
            }

            return {
                ...state,
                extraDocuments: cloneExtraDocs,
            };
        }
        case DELETE_ATTACHMENT_SUCCESS: {
            const { attachmentId } = action;
            const cloneExtraDocs = _get(state, 'extraDocuments', []);
            const deleteIndex = cloneExtraDocs.findIndex((item) => _get(item, 'files[0].fileId') === attachmentId);
            if (deleteIndex >= 0) cloneExtraDocs.splice(deleteIndex, 1);
            return {
                ...state,
                extraDocuments: cloneExtraDocs,
            };
        }
        case GET_RATING_MODEL_SUCCESS: {
            const { data: { lossEstimationSuggestions = [], lossEstimationPreferences = {} } = {} } = action;
            return {
                ...state,
                lossEstimationSuggestions,
                lossEstimationPreferences,
            };
        }
        case FETCH_SCRUBB_SUCCESS: {
            const poolState = action.data.poolStates ? action.data.poolStates : [];
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    loading: false,
                    selectedPoolState: poolState,
                    edit: poolState.length > 0,
                    status: action.data.status,
                },
            };
        }
        case FETCH_SCRUBB_FAILURE: {
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    selectedPoolState: [],
                    edit: false,
                    loading: false,
                },
            };
        }
        case LOAN_SCRUBB_REQUEST:
            return {
                ...state,
                scrubbing: {
                    edit: true,
                    selectedPoolState: [],
                    status: null,
                },
            };
        case LOAN_SCRUBB_SUCCESS:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, selectedPoolState: action.poolStates },
            };
        case LOAN_SCRUBB_EDIT:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, success: true },
            };
        default:
            return state;
    }
}

export const filterPreviewLoader = createLoadingSelector([createActionName('TRANSACTION_POOLFILTER')]);

function mapPTC(data) {
    const {
        seasoning,
        tenorStartsFrom,
        par,
        loansToReject,
        pivots,
        excludeTransactions = [],
        limitPoolSize,
        obligorFilterConfirmed,
        ...others
    } = data;
    const processedData = {
        ptcTransaction: {
            ...(_has(data, 'obligorFilterConfirmed') ? { obligorFilterConfirmed: obligorFilterConfirmed } : {}),
            poolSelectionCriteria: {
                ...others,
                seasoning,
                ...(seasoning && { tenorStartsFrom }),
                ...(_get(par, 'parFilters')
                    ? {
                          par: {
                              ...(_get(par, 'year') ? { year: _get(par, 'year.value') ? par.year.value : par.year } : {}),
                              ...(_get(par, 'quarter') ? { quarter: _get(par, 'quarter.value') ? par.quarter.value : par.quarter } : {}),
                              ...(_get(par, 'rangedParFilters.[0].from') ? { rangedParFilters: par.rangedParFilters } : {}),
                              ...(!_isEmpty(par.parFilters) && Object.values(par.parFilters).some((s) => s !== '')
                                  ? {
                                        parFilters: Object.keys(par.parFilters).map((m) => ({
                                            value: `par_${m.match(/\d+/g).map(Number)[0]}`,
                                            cutoff: par.parFilters[m],
                                        })),
                                    }
                                  : {}),
                          },
                      }
                    : {}),
                ...(_get(loansToReject, '[0].ids')
                    ? {
                          loansToReject: loansToReject
                              .filter((m) => m)
                              .map((m) => ({ ids: m.ids.split(/[\s,]+/).map((n) => n.trim()), reason: m.reason })),
                      }
                    : {}),
                ...(pivots
                    ? {
                          pivots: [
                              ...(_get(pivots, 'discretePivots') ? pivots.discretePivots.map((m) => ({ name: m.name, type: m.type })) : []),
                              ...(_get(pivots, 'continuousPivots')
                                  ? pivots.continuousPivots.map((n) => {
                                        const { customName, ...rest } = n;
                                        return rest;
                                    })
                                  : []),
                          ],
                      }
                    : {}),
                ...(_get(limitPoolSize, 'size', 0) > 0
                    ? {
                          limitPoolSize: {
                              ...limitPoolSize,
                              size: !_get(limitPoolSize, 'size') || limitPoolSize.size === 0 ? '' : croresToValues(limitPoolSize.size),
                          },
                      }
                    : {}),
                excludeTransactions,
                ...(_get(data, 'ltv')
                    ? {
                          ltv: {
                              maximumLtv: Number(_get(data, 'ltv.maximumLtv', 0)) || null,
                              minimumLtv:
                                  _get(data, 'ltv.minimumLtv') || _get(data, 'ltv.minimumLtv') === 0 ? Number(_get(data, 'ltv.minimumLtv')) : null,
                          },
                      }
                    : {}),
            },
        },
    };
    return humps.decamelizeKeys(processedData);
}

const unMapActors = (data) => {
    if (!data) return {};
    try {
        let ratingAgency = {};
        let lawFirms;
        let investorLawFirms;
        let originatorLawFirms;
        let trustees = {};
        let auditors;
        let investors = {};
        let slceProviders;
        let servicers;
        let trusteeBranch;
        let collateralManager;
        let acceptedInvestors;
        if (_get(data, 'rating_agencies[0]')) {
            ratingAgency = data.rating_agencies
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'law_firms[0]')) {
            lawFirms = data.law_firms
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'investor_law_firms[0]')) {
            investorLawFirms = data.investor_law_firms
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'originator_law_firms[0]')) {
            originatorLawFirms = data.originator_law_firms
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'trustees[0]')) {
            trustees = data.trustees
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'trustee_branch[0]')) {
            trusteeBranch = data.trustee_branch
                .map((m) => ({
                    value: m,
                    label: m,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'auditors[0]')) {
            auditors = data.auditors
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'investors[0]')) {
            investors = data.investors
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'slce_providers[0]')) {
            slceProviders = data.slce_providers
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'servicers[0]')) {
            servicers = data.servicers
                .map((m) => ({
                    value: m.id,
                    label: m.name,
                }))
                .reduce((r) => r);
        }
        if (_get(data, 'accepted_investors[0]')) {
            acceptedInvestors = data.accepted_investors.map((m) => ({
                value: m.id,
                label: m.name,
            }));
        }
        if (_get(data, 'collateral_managers[0]')) {
            collateralManager = data.collateral_managers.map((m) => ({
                value: m.id,
                label: m.name,
            }));
        }

        const processData = {
            ratingAgencyIds: ratingAgency.value ? ratingAgency : { value: 'N/A', label: 'N/A' },
            lawFirmIds: lawFirms,
            investorLawFirmIds: investorLawFirms,
            originatorLawFirmIds: originatorLawFirms,
            trusteeIds: trustees.value ? trustees : { value: 'N/A', label: 'N/A' },
            auditorIds: auditors,
            investorsIds: investors,
            slceProviderIds: slceProviders,
            servicerIds: servicers,
            trusteeBranch,
            acceptedInvestors,
            collateralManagerIds: collateralManager,
        };
        return humps.camelizeKeys(processData);
    } catch (e) {
        return {};
    }
};

export const getTranchePrincipal = (tranches = [], poolPrincipal = 0) => {
    return tranches.map((tranche) => (_get(tranche, 'value', 0) * poolPrincipal) / 100);
};

export const unMapPTC = (data) => {
    const continuous = splitPivots(data.possible_pivots, CONTINUOUS_PIVOT);
    const processedData = {
        ...data,
        ...unMapActors(data),
        pool_selection_criteria: {
            ...data.pool_selection_criteria,
            canTenorStartFromDisbursement: _get(data, 'can_tenor_start_from_disbursement', false),
            canTenorStartFromSecurityRegistrationDate: _get(data, 'can_tenor_start_from_security_registration_date', false),
            tenorStartsFrom: _get(data, 'pool_selection_criteria.tenor_starts_from', 'first_installment_date'),
            ...(_get(data, 'pool_selection_criteria.par')
                ? {
                      par: {
                          ...data.pool_selection_criteria.par,
                          ranged_par_filters: _get(data, 'pool_selection_criteria.par.ranged_par_filters')
                              ? data.pool_selection_criteria.par.ranged_par_filters
                              : initialState.poolSelectionCriteria.par.rangedParFilters,
                          par_filters: data.pool_selection_criteria.par.par_filters
                              .map((m) => ({ [m.value]: m.cutoff }))
                              .reduce((acc, val) => ({ ...acc, ...val }), {}),
                      },
                  }
                : {}),
            ...(_get(data, 'pool_selection_criteria.loans_to_reject')
                ? {
                      loans_to_reject: data.pool_selection_criteria.loans_to_reject.map((m) => ({
                          ...m,
                          ids: m.ids.join(','),
                      })),
                  }
                : {}),
            limitPoolSize:
                _has(data, 'pool_selection_criteria.limit_pool_size') && _size(data.pool_selection_criteria.limit_pool_size) > 0
                    ? {
                          ..._get(data, 'pool_selection_criteria.limit_pool_size', {}),
                          size:
                              _get(data, 'pool_selection_criteria.limit_pool_size.size') > 0
                                  ? valuesToCrores(_get(data, 'pool_selection_criteria.limit_pool_size.size', ''))
                                  : '',
                      }
                    : {
                          type: 'retain_branch_concentration',
                      },

            ...(_get(data, 'pool_selection_criteria.geography')
                ? {
                      geography: {
                          ..._get(data, 'pool_selection_criteria.geography'),
                          excludedStates: mapOptions(_get(data, 'pool_selection_criteria.geography.excluded_states', [])),
                          excludedDistricts: mapOptions(_get(data, 'pool_selection_criteria.geography.excluded_districts', [])),
                          excludedBranches: mapOptions(_get(data, 'pool_selection_criteria.geography.excluded_branches', [])),
                      },
                  }
                : {}),
            excludeTransactions: _get(data, 'pool_selection_criteria.exclude_transactions', []),
            ...(_get(data, 'asset_classes') && _get(data, 'asset_classes').some((asset) => LTV_POOL_TYPES.includes(asset))
                ? {
                      ltv: {
                          maximum_ltv: _get(data, 'pool_selection_criteria.cut_off_date')
                              ? `${_get(data, 'pool_selection_criteria.ltv.maximum_ltv')}`
                              : 100,
                          minimum_ltv: _get(data, 'pool_selection_criteria.cut_off_date')
                              ? `${_get(data, 'pool_selection_criteria.ltv.minimum_ltv')}`
                              : '0',
                      },
                  }
                : {}),
            customRules: { ...mapValuesGlobal(_get(data, 'pool_selection_criteria.custom_rules')) },
        },
        pivotOptions: {
            discrete: splitPivots(data.possible_pivots, DISCRETE_PIVOT),
            continuous: continuous.length > 0 ? continuous.map((m) => ({ ...m, range: { from: '', to: '', splitEvery: '' } })) : [],
        },
        auto_structuring_preferences: getAutoStructuringPref({
            ...data.auto_structuring_preferences,
            lossEstimationPrefs: data.loss_estimation_preferences,
        }),
        auto_structuring_suggestions:
            _get(data, 'auto_structuring_suggestions[0].label', '') === 'error' || _isNull(data.auto_structuring_suggestions)
                ? []
                : _get(data, 'auto_structuring_suggestions', []),
        costing: normalizeCosting(_get(data, 'costing', {}), {
            principal: _get(data, 'pool_principal', 0),
            totalFunding: _get(data, 'waterfall_summary.total_funding', 0),
            tranches: getTranchePrincipal(_get(data, 'structuring.tranches', []), _get(data, 'pool_principal', 0)),
        }),
        isWaterupload:
            _get(data, 'pool_files') && data.pool_files.some((f) => f.type === 'waterfall' && f.files.length > 0)
                ? false
                : isCustomWaterfall(humps.camelizeKeys(data.structuring)),
    };
    return humps.camelizeKeys(processedData);
};

function mapPTCStructuring(data) {
    const processedData = {
        ptcTransaction: {
            obligorFilterConfirmed: false,
            structuring: {
                ...data,
                creditEnhancement: {
                    ...(_get(data, 'creditEnhancement') || {}),
                    slce: {
                        ..._get(data, 'creditEnhancement.slce'),
                        value: _get(data, 'creditEnhancement.slce.value') || 0,
                        servicerFee: _get(data, 'creditEnhancement.slce.servicerFee') || 0,
                    },
                    bankGurantee: {
                        ..._get(data, 'creditEnhancement.bankGurantee'),
                        value: _get(data, 'creditEnhancement.bankGurantee.value') || 0,
                        servicerFee: _get(data, 'creditEnhancement.bankGurantee.servicerFee') || 0,
                    },
                    corporateGuarantee: {
                        ..._get(data, 'creditEnhancement.corporateGuarantee'),
                        value: _get(data, 'creditEnhancement.corporateGuarantee.value') || 0,
                    },
                    cashCollateral: {
                        ..._get(data, 'creditEnhancement.cashCollateral'),
                        value: _get(data, 'creditEnhancement.cashCollateral.value') || 0,
                    },
                },
            },
        },
    };
    return humps.decamelizeKeys(processedData);
}

export function getPTCInfo(id) {
    return (dispatch) => {
        dispatch({ type: TRANSACTION_GET_REQUEST });
        return fetchUtils
            .getJSON(baseURL(id))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id });
                dispatch({ type: TRANSACTION_GET_SUCCESS });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANSACTION_GET_FAILURE });
                    toastr.error('Error retrieving securitizations data', m);
                }),
            );
    };
}

export function updateDetails(id, data, callBack, isSelfServe, setError) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(baseURL(id), mapPTC(data))
            .then((d) => {
                if (!isSelfServe) {
                    toastr.success('Sucess!', 'Applying filters, Please wait for sometime.');
                }
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id });
                if (typeof callBack === 'function') callBack();
                return dispatch(pollForCashFlows(id, callBack, isSelfServe, setError));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Applying filters', m);
                }),
            );
}

export function preview(id, data) {
    return (dispatch) => {
        dispatch({ type: TRANSACTION_POOLFILTER_REQUEST, id });
        return fetchUtils
            .patchJSON(`${baseURL(id)}/filter_and_preview`, mapPTC(data))
            .then((d) => {
                if (preview && !d.filter_summary) {
                    return null;
                }
                dispatch({ type: TRANSACTION_POOLFILTER_SUCCESS, data: unMapPTC(d), id });
                return d;
            })
            .catch((ex) => ex);
    };
}

export function getCashflow(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${baseURL(id)}?is_poller=true`)
            .then((d) => {
                if (!d.filter_processing) {
                    return d;
                }
                dispatch({ type: TRANSACTION_GET, data: { ...unMapPTC(d), pollingInProgress: true }, id });
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANSACTION_POOLFILTER_FAILURE, id });
                    toastr.error('Error Applying filters', m);
                }),
            );
}

export function pollForPreview(id, data) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(preview(id, data)), 180000, 3000)
            .then(() => {
                toastr.success('Success!', 'Filter Preview is Ready.');
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANSACTION_POOLFILTER_FAILURE, id });
                    toastr.error('Error Applying filters', m);
                });
            });
}

export function pollForCashFlows(id, callBack, isSelfServe, setError) {
    return (dispatch) => {
        dispatch({ type: TRANSACTION_POOLFILTER_REQUEST, id });
        fetchUtils
            .poll(() => dispatch(getCashflow(id)), 1200000, 15000)
            .then(() => fetchUtils.getJSON(baseURL(id)))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id });
                if (isSelfServe) {
                    setError({ status: true, message: 'Pool filter applied', type: 'success' });
                } else {
                    toastr.success('Success!', 'Filter Applied.');
                }
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    if (typeof callBack === 'function') callBack();
                    dispatch({ type: TRANSACTION_POOLFILTER_FAILURE, id });
                });
            });
    };
}

export function loadWaterfall(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${baseURL(id)}?is_poller=true`)
            .then((d) => {
                if (_get(d, 'pool_files') && d.pool_files.some((f) => f.type === 'waterfall' && f.files.length > 0)) {
                    return d;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving waterfall summary', m);
                }),
            );
}

export function pollWaterfall(id, checkMrr, setStructuring, isSelfServe, setError) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(loadWaterfall(id)), 180000, 1000)
            .then(() => fetchUtils.getJSON(baseURL(id)))
            .then((d) => dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id }))
            .then(() => {
                if (isSelfServe) {
                    setError({ status: true, message: 'Waterfall updated successfully', type: 'success' });
                } else {
                    toastr.success('Success!', 'Waterfall updated successfully.');
                }
            })
            .then(() => {
                if (typeof checkMrr === 'function') {
                    checkMrr();
                }
                if (typeof setStructuring === 'function') {
                    setStructuring(true);
                }
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving waterfall summary', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving waterfall summary', errMsg);
                }
            });
}

export function updateStructuring(id, data, checkMrr, setStructuring, isSelfServe = false, setError) {
    return (dispatch) => {
        dispatch({ type: STRUCTURING_REQUEST });
        return fetchUtils
            .patchJSON(baseURL(id), mapPTCStructuring(data))
            .then((d) => dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id }))
            .then(() => {
                dispatch({ type: STRUCTURING_SUCCESS });
                if (isSelfServe) {
                    setError({ status: true, message: 'Initiated deal structuring. Please wait a few seconds.', type: 'success' });
                } else {
                    toastr.success('Success!', 'Initiated deal structuring. Please wait a few seconds.');
                }
                if (typeof checkMrr === 'function' && typeof setStructuring === 'function') {
                    dispatch(pollWaterfall(id, checkMrr, setStructuring, isSelfServe, setError));
                } else {
                    dispatch(pollWaterfall(id, undefined, undefined, isSelfServe, setError));
                }
            })
            .then(() => {
                dispatch(actions.pollLossModel(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: STRUCTURING_FAILURE });
                    toastr.error('Error retrieving securitizations data', m);
                }),
            );
    };
}

export const waterfallReuploadLoader = createLoadingSelector([createActionName('WATERFALL_REUPLOAD')]);

export function uploadWaterfall(fileId, file, transId) {
    return (dispatch) => {
        dispatch({ type: WATERFALL_REUPLOAD_REQUEST });
        const body = new FormData();
        body.append('deal_document[document]', file[0]);
        return fetchUtils
            .putFormData(`${baseURL(transId)}/deal_documents/${fileId}`, body)
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                if (d.file_id) {
                    toastr.success('Success!', 'Waterfall reupload successfully');
                    dispatch({ type: WATERFALL_REUPLOAD_SUCCESS });
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: WATERFALL_REUPLOAD_FAILURE });
                    toastr.error('Error uploading pool file', m);
                }),
            );
    };
}

export function reUploadWaterfall(fileId, file, transId, type) {
    return (dispatch) => {
        dispatch({ type: WATERFALL_REUPLOAD_REQUEST });
        const body = new FormData();
        body.append('document', file[0]);
        body.append('type', type);
        return fetchUtils
            .putFormData(`${baseURL(transId)}/replace_file`, body)
            .then((d) => {
                toastr.success('Success!', 'Waterfall reupload successfully');
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id: transId });
                dispatch({ type: WATERFALL_REUPLOAD_SUCCESS });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: WATERFALL_REUPLOAD_FAILURE });
                    toastr.error('Error uploading pool file', m);
                }),
            );
    };
}

// Document Actions

export function generateDocuments(transId) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${baseURL(transId)}/generate_documents `, {})
            .then((d) => humps.camelizeKeys(d))
            // .then((d) => {
            //     dispatch({ type: TRANSACTION_GET, data: d, id: transId });
            // })
            .then(() => {
                toastr.success('Success!', 'Documents generation initiated successfully.');
            })
            .then(() => dispatch(pollingPTCDocs(transId)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                }),
            );
}

export function pollingPTCDocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getPTCDocs(id)), 180000, 1000)
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: d, id });
                toastr.success('Success!', 'Documents generated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Generating Documents', errMsg);
                }
            });
}

function getPTCDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(baseURL(id))
            .then((d) => unMapPTC(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated' || item.files[0].state === 'uploaded');
                dispatch({ type: TRANSACTION_GET, data: d, id });
                if (isGenerated && docs.length > 0) return d;
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching Documents', m);
                }),
            );
}

export function pollDocuments(id) {
    return (dispatch) => {
        dispatch({ type: DOCUMENTS_FETCH_REQUEST, transId: id });

        return fetchUtils
            .poll(() => dispatch(getPTCDocs(id)), 20000, 1000)
            .catch((e) => {
                fetchUtils.handleError(e).then((m) => {
                    toastr.error('Error retrieving documents', m);
                });
            });
    };
}

export function uploadPTCDocs(id, file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('document', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${baseURL(id)}/deal_documents`, body)
            .then(() => {
                dispatch(getPTCDocs(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

export function destroyFile(transId, fileId) {
    return (dispatch) =>
        fetchUtils
            .deleteJSON(`${baseURL(transId)}/deal_documents/${fileId}`)
            .then(() => {
                toastr.success('File Destroyed');
                dispatch(getPTCInfo(transId));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error destroying transaction file', m);
                });
            });
}

const formatAdHocData = (values) => {
    const data = {
        document: values,
    };
    return humps.decamelizeKeys(data);
};

export function addAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_CREATE_REQUEST });
        return fetchUtils
            .patchJSON(`${baseURL(transId)}/add_document`, formatAdHocData(data))
            .then(() => {
                dispatch({ type: ADHOC_CREATE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Adhoc document has been created successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_CREATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding document', m);
                });
            });
    };
}

export function updateAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_UPDATE_REQUEST });
        return fetchUtils
            .patchJSON(`${baseURL(transId)}/modify_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: ADHOC_UPDATE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Document settings has been updated successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating settings', m);
                });
            });
    };
}

export function removeDocPlaceholder(transId, data) {
    return (dispatch) => {
        dispatch({ type: PLACEHOLDER_REMOVE_REQUEST });
        return fetchUtils
            .patchJSON(`${baseURL(transId)}/remove_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: PLACEHOLDER_REMOVE_SUCCESS });
                dispatch(getPTCDocs(transId));
                toastr.success('Success!', 'Document placeholder has been removed successfully');
            })
            .catch((ex) => {
                dispatch({ type: PLACEHOLDER_REMOVE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing placeholder', m);
                });
            });
    };
}

export const adhocDocLoading = createLoadingSelector([createActionName('ADHOC_CREATE')]);
export const adhocDocUpdating = createLoadingSelector([createActionName('ADHOC_UPDATE')]);
export const placeholderRemoving = createLoadingSelector([createActionName('PLACEHOLDER_REMOVE')]);

// Document Actions end

// Scrubbing

export function scrubbEdit(id) {
    return (dispatch) => {
        dispatch({
            type: LOAN_SCRUBB_EDIT,
            status: null,
            id,
        });
    };
}

export function getLoanScrubb(id, type) {
    return (dispatch) => {
        dispatch({ type: FETCH_SCRUBB_REQUEST });
        fetchUtils
            .getJSON(`${baseURL(id)}/loan_scrubb`)
            .then((d) => humps.camelizeKeys(d))
            .then((data) => {
                dispatch({ type: FETCH_SCRUBB_SUCCESS, data, id });
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FETCH_SCRUBB_FAILURE });
                    toastr.error('Error fetching scrub data', m);
                });
            });
    };
}

export function requestLoanScrubb(values, setSubmitting, formData) {
    const { poolStates = [], transactionId, paramKey } = formData;
    const formValues = { ...values };
    const { scrubType, selectedPoolState = [] } = formValues;
    formValues.poolStates = scrubType === 'full_pool' ? poolStates : selectedPoolState;
    delete formValues.selectedPoolState;
    delete formValues.type;
    const formVal = { [paramKey]: { ...formValues } };
    return (dispatch) => {
        dispatch({ type: LOAN_SCRUBB_REQUEST });
        fetchUtils
            .postJSON(`${baseURL(transactionId)}/loan_scrubb`, humps.decamelizeKeys(formVal))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                setSubmitting(false);
                dispatch({ type: LOAN_SCRUBB_SUCCESS, status: d.status, poolStates: d.poolStates, id: transactionId });
            })
            .catch((ex) => {
                setSubmitting(false);
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error initiating loan scrub', m);
                });
            });
    };
}

// Scrubbing Ends

// Release and Finalize
const mapFinalize = (data) => {
    const { tranches, ...rest } = data;
    const mData = {
        finalize: {
            tranches: tranches.map((t) => ({
                series: t.series,
                investors: t.investors.map((i) => ({
                    series: i.series,
                    investorId: i.investorId,
                })),
            })),
            ...rest,
        },
    };
    return humps.decamelizeKeys(mData);
};

export function releaseTrans(transId, values) {
    return (dispatch) => {
        dispatch({ type: TRANS_RELEASE_REQUEST });
        return fetchUtils
            .putJSON(`${baseURL(transId)}/release`, values)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: TRANS_RELEASE_SUCCESS, data: d, id: transId }))
            .then(() => {
                toastr.success('Success!', 'Transaction released successfully.');
                dispatch({
                    type: PRODUCT_RELEASE_CLICK,
                    meta: {
                        amplitude: [
                            {
                                eventType: EventTypes.track,
                                eventPayload: {
                                    eventName: PRODUCT_RELEASE_CLICK,
                                    transaction_type: PRODUCT_KEYS.ptc,
                                    transactionId: transId,
                                    ...values,
                                },
                            },
                        ],
                    },
                });
            })
            .then(() => {
                dispatch(getPTCInfo(transId));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error releasing', m);
                }),
            );
    };
}

export function finalizeTrans(transId, values) {
    return (dispatch) => {
        dispatch({ type: TRANS_FINALIZE_REQUEST });
        return fetchUtils
            .putJSON(`${baseURL(transId)}/finalize`, mapFinalize(values))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch({ type: TRANS_FINALIZE_SUCCESS, data: d, id: transId }))
            .then(() => {
                toastr.success('Success!', 'Transaction finalized successfully.');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error finalizing', m);
                }),
            );
    };
}

// Actors
const mapActors = (data) => {
    let prcessData = {};
    const {
        ratingAgencyIds,
        lawFirmIds,
        auditorIds,
        trusteeIds,
        slceProviderIds,
        servicerIds,
        trusteeBranch,
        collateralManagerIds,
        investorLawFirmIds,
        originatorLawFirmIds,
    } = data;
    prcessData = mapAssignCounterparties({
        ratingAgencyIds,
        lawFirmIds,
        auditorIds,
        trusteeIds,
        slceProviderIds,
        servicerIds,
        trusteeBranch,
        collateralManagerIds,
        investorLawFirmIds,
        originatorLawFirmIds,
    });
    return humps.decamelizeKeys(prcessData);
};

export function updateActors(id, actors) {
    return (dispatch) =>
        fetchUtils
            .putJSON(baseURL(id), {
                ptc_transaction: mapActors(actors),
            })
            .then((d) => unMapPTC(d))
            .then((d) => {
                toastr.success('Success!', 'Counterparties updated.');
                dispatch({ type: TRANSACTION_GET, data: d, id });
                dispatch(actions.getTasks(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating counterparties', m);
                }),
            );
}

// Release

export function releasePTC(id, data) {
    return (dispatch) => {
        dispatch({ type: TRANSACTION_RELEASE_REQUEST });
        const payload = humps.decamelizeKeys(data);
        return fetchUtils
            .putJSON(baseURL(id), {
                ptc_transaction: payload,
            })
            .then((d) => unMapPTC(d))
            .then(() => {
                toastr.success('Success!', 'Transaction state updated.');
                dispatch(getPTCInfo(id));
                dispatch(getDetails(id));
                dispatch({ type: TRANSACTION_RELEASE_SUCCESS, id });
                if (_get(data, 'investorIds')) {
                    dispatch({
                        type: PRODUCT_RELEASE_CLICK,
                        meta: {
                            amplitude: [
                                {
                                    eventType: EventTypes.track,
                                    eventPayload: {
                                        eventName: PRODUCT_RELEASE_CLICK,
                                        transaction_type: PRODUCT_KEYS.ptc,
                                        transactionId: id,
                                        ...data,
                                    },
                                },
                            ],
                        },
                    });
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating Transaction state.', m);
                    dispatch({ type: TRANSACTION_RELEASE_FAILURE });
                }),
            );
    };
}

export function editPTC(transId, values) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${baseURL(transId)}`, { ptc_transaction: { ...humps.decamelizeKeys(values), id: transId } })
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: d, id: transId });
                toastr.success('Success!', 'Deal Name updated successfully');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating deal name', m);
                }),
            );
}

export function getPTCState(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(baseURL(id))
            .then((d) => unMapPTC(d))
            .then((d) => {
                if ([TRANSACTION_STATE.SETTLED, TRANSACTION_STATE.MATURED].includes(d.transactionState)) {
                    dispatch({ type: TRANSACTION_GET, data: d, id });
                    return d;
                }

                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    return new Error(m);
                }),
            );
}

export function pollPTCState(id, callback) {
    return (dispatch) => {
        return fetchUtils
            .poll(() => dispatch(getPTCState(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'Transaction state updated successfully.');
                if (typeof callback === 'function') callback();
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving transaction state', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving transaction state', errMsg);
                }
                if (typeof callback === 'function') callback();
            });
    };
}

export function updatePTC(id, values, callback) {
    return (dispatch) => {
        dispatch({ type: FUNDING_STATUS_REQUEST });
        return fetchUtils
            .patchJSON(`${baseURL(id)}`, { ptc_transaction: { ...humps.decamelizeKeys(values), id } })
            .then((d) => humps.camelizeKeys(d))
            .then((d) => dispatch(pollPTCState(id, callback)))
            .then(() => {
                if (values.direct_settlement) {
                    dispatch({ type: FUNDING_STATUS_SUCCESS });
                    toastr.success('Success!', 'Transaction status updated.');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FUNDING_STATUS_FAILURE });
                    toastr.error('Error while updating', m);
                }),
            );
    };
}

// Executable Documents

export function getPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${id}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = d.documents.filter((doc) => doc.files.length > 0);
                const others = d.otherFiles;

                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (_get(d, 'errorMessage')) {
                    return _get(d, 'errorMessage');
                }
                if (!docs.length && others.length) {
                    dispatch({ type: GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return null;
                }
                if (isFailed) {
                    return docs;
                } else if (isGenerated) {
                    dispatch({ type: GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}

export function pollPTCExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getPTCExecDocs(id)), 180000, 3000)
            .then((d) => {
                if (!Array.isArray(d)) {
                    toastr.error('Error Finalizing Documents', d);
                } else if (!d.length) {
                    toastr.error('Error Finalizing Documents', 'Failed to generate executed documents');
                } else if (d.some((item) => item.files[0].state === 'failed')) {
                    toastr.error(
                        'Error Finalizing Documents',
                        `Please check the format of ${humps.pascalize(d.find((item) => item.files[0].state === 'failed').files[0].type)}`,
                    );
                } else {
                    toastr.success('Success!', 'Documents generated successfully.');
                }
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Polling Documents', errMsg);
                }
            });
}

export function generateExecDocs(transId, setSubmitting) {
    toastr.success('Success!', 'Documents generation Request initiated');
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}/executable_documents`)
            .then(() => dispatch(pollPTCExecDocs(transId)))
            .then(() => setSubmitting(false))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                }),
            );
}

export function revokeExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${id}/executable_documents/revoke`)
            .then((d) => {
                if (_get(d, 'success')) {
                    dispatch(getPTCExecDocs(id));
                } else {
                    toastr.error('Failed to revoke executable documents');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error revoking executable documents', m);
                }),
            );
}

export function updateMcd(transId, mcdFiles, action, closePopup) {
    return (dispatch) => {
        const params = {
            ptc_transaction: {
                mcd_data: mcdFiles.mcds.filter((f) => f.selected === true).map((m) => ({ id: m.id })),
            },
        };
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}`, humps.decamelizeKeys(params))
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                action.setSubmitting(false);
                closePopup(false);
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id: transId });
            })
            .catch((ex) => {
                action.setSubmitting(false);
                closePopup(false);
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating mcd', m);
                });
            });
    };
}

export function addAttachment(transId, data, attachmentId, callBack) {
    return (dispatch) => {
        dispatch({ type: ADD_ATTACHMENT_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}/extra_documents/${attachmentId}`, humps.decamelizeKeys(data))
            .then((attachment) => {
                dispatch({ type: ADD_ATTACHMENT_SUCCESS, data: humps.camelizeKeys(attachment), id: transId });
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                dispatch({ type: ADD_ATTACHMENT_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding attachment', m);
                    if (typeof callBack === 'function') callBack();
                });
            });
    };
}

export function deleteAttachment(transId, attachmentId, callBack) {
    return (dispatch) => {
        dispatch({ type: DELETE_ATTACHMENT_REQUEST });
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/ptcs/${transId}/extra_documents/${attachmentId}`)
            .then(() => {
                dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId, id: transId });
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                dispatch({ type: DELETE_ATTACHMENT_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing attachment', m);
                    if (typeof callBack === 'function') callBack();
                });
            });
    };
}
