import humps from 'humps';
import { toastr } from 'react-redux-toastr';

import { getJSON, postJSON, handleErrorV2 } from 'app/utils/FetchUtils';

import { daBaseUrl } from 'app/products/DA/Store/reducer';

export const FETCH_SCRUBB_REQUEST = 'FETCH_SCRUBB_REQUEST';
export const FETCH_SCRUBB_SUCCESS = 'FETCH_SCRUBB_SUCCESS';
export const FETCH_SCRUBB_FAILURE = 'FETCH_SCRUBB_FAILURE';

export const LOAN_SCRUBB_REQUEST = 'LOAN_SCRUBB_REQUEST';
export const LOAN_SCRUBB_SUCCESS = 'LOAN_SCRUBB_SUCCESS';
export const LOAN_SCRUBB_FAILURE = 'LOAN_SCRUBB_FAILURE';

export const LOAN_SCRUBB_EDIT = 'LOAN_SCRUBB_EDIT';

export const intialScrubbing = {
    loading: true,
    edit: true,
    selectedPoolState: [],
};

export function scrubbEdit(id: string, dispatch: any) {
    return dispatch({
        type: LOAN_SCRUBB_EDIT,
        status: null,
        id,
    });
}

export function getLoanScrubb(id: any, dispatch: any) {
    dispatch({ type: FETCH_SCRUBB_REQUEST });
    return getJSON(`${daBaseUrl(id)}/loan_scrubb`)
        .then((d) => humps.camelizeKeys(d))
        .then((data) => {
            dispatch({ type: FETCH_SCRUBB_SUCCESS, data, id });
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                dispatch({ type: FETCH_SCRUBB_FAILURE });
                toastr.error('Error fetching scrub data', m);
            });
        });
}

export function requestLoanScrubb(values: any, setSubmitting: any, formData: any, dispatch: any) {
    const { poolStates = [], transactionId, paramKey } = formData;
    const formValues = { ...values };
    const { scrubType, selectedPoolState = [] } = formValues;
    formValues.poolStates = scrubType === 'full_pool' ? poolStates : selectedPoolState;
    delete formValues.selectedPoolState;
    delete formValues.type;
    const formVal = { [paramKey]: { ...formValues } };
    dispatch({ type: LOAN_SCRUBB_REQUEST });
    return postJSON(`${daBaseUrl(transactionId)}/loan_scrubb`, humps.decamelizeKeys(formVal))
        .then((d: any) => humps.camelizeKeys(d))
        .then((d: any) => {
            setSubmitting(false);
            dispatch({ type: LOAN_SCRUBB_SUCCESS, status: d.status, poolStates: d.poolStates });
        })
        .catch((ex: any) => {
            setSubmitting(false);
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error initiating loan scrub', m);
            });
        });
}
