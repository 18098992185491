import { useLocation, useNavigate, useParams, Location, Params } from 'react-router-dom';
import history from 'app/utils/myHistory';

interface IUseRouterHelper {
    push: (path: string, state?: any) => void;
    replace: (path: string, state?: any) => void;
    goBack: () => void;
    goForward: () => void;
    params: Params;
    location: Location;
    openInNewTab: (path: string) => void;
    listenToRouteChange: (cb: () => void) => void;
    currentPathMatch: (path: string) => boolean;
}

export const useRouterHelper = (): IUseRouterHelper => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    return {
        push: (path: string, state = {}) => navigate(path, { state }),
        replace: (path: string, state = {}) => navigate(path, { state, replace: true }),
        goBack: () => navigate(-1),
        goForward: () => navigate(1),
        params,
        location: location,
        openInNewTab: (path: string) => window.open(path),
        listenToRouteChange: (cb: () => void) => history.listen(cb),
        currentPathMatch: (path: string) => location?.pathname?.includes(path),
    };
};
