import { ApolloClient, InMemoryCache, from, ApolloLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import history from 'app/utils/myHistory';
import Auth from 'app/utils/Auth';
import { setContext } from '@apollo/client/link/context';
import { MFAService } from '@yubi/yb-module-auth';
import AuthService from 'app/utils/Auth';
import { getEnv } from './FetchUtils';
import { isValidRedirect } from './CommonUtils';

const STATUS_CODES = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
};

export const ERROR_MESSAGES = {
    INVALID_MFA: 'Invalid MFA token',
    NOT_AUTHENTICATED: 'Not Authenticated',
    NOT_AUTHORIZED: 'user not authorized',
};

export const isDomainCorrect =
    window.location.origin === process.env.REACT_APP_POOL_HOST || window.location.origin === process.env.REACT_APP_POOL_OLD_HOST;
const errorLink = onError((error) => {
    const { networkError } = error;
    const { statusCode, result } = networkError || {};
    const { INVALID_MFA, NOT_AUTHENTICATED } = ERROR_MESSAGES;
    switch (statusCode) {
        case STATUS_CODES.UNAUTHORIZED: {
            switch (result.error.message) {
                case INVALID_MFA: {
                    MFAService.removeMFAToken();
                    if (isValidRedirect(window.location.origin)) {
                        window.location.href = window.location.origin;
                    }
                    break;
                }
                case NOT_AUTHENTICATED: {
                    if (isValidRedirect(window.location.origin)) {
                        window.location.href = window.location.origin;
                    }
                    AuthService.logout();
                    break;
                }
                default: {
                    console.error(result.error.message);
                }
            }
            break;
        }
        case STATUS_CODES.FORBIDDEN: {
            history.push('/forbidden');
            break;
        }
        default: {
            console.log('do nothing');
        }
    }
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${Auth.getAccessToken()}`,
            'Mfa-Token': MFAService.getMFAToken(),
            'Current-Entity-Id': Auth.getEntityId(),
            'Current-Group': Auth.getGroup(),
        },
    };
});

const uploadLink = createUploadLink({
    uri: getEnv(`${process.env.REACT_APP_MP_API_HOST}/dashboard/graphql`),
    headers: {
        Authorization: `Bearer ${Auth.getAccessToken()}`,
        'Mfa-Token': MFAService.getMFAToken(),
        'Current-Entity-Id': Auth.getEntityId(),
        'Current-Group': Auth.getGroup(),
    },
});

// Fix for multiple queries
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                payout: {
                    merge(existingData = [], incomingData) {
                        return { ...existingData, ...incomingData };
                    },
                    loanCollectionReport: {
                        merge(existingData = [], incomingData) {
                            return { ...existingData, ...incomingData };
                        },
                    },
                    poolCollectionReport: {
                        merge(existingData = [], incomingData) {
                            return { ...existingData, ...incomingData };
                        },
                    },
                },
            },
        },
    },
});

const uploadLinkNCD = createUploadLink({
    uri: getEnv(`${process.env.REACT_APP_MP_API_HOST}/graphql`),
    headers: {
        Authorization: `Bearer ${Auth.getAccessToken()}`,
        'Mfa-Token': MFAService.getMFAToken(),
        'Current-Entity-Id': Auth.getEntityId(),
        'Current-Group': Auth.getGroup(),
    },
    // useGETForQueries: true,
});

export const ncdClient = new ApolloClient({
    link: from([errorLink, ApolloLink.from([errorLink, authLink.concat(uploadLinkNCD)])]),
    cache,
});

export const client = new ApolloClient({
    link: from([errorLink, authLink.concat(uploadLink)]),
    cache,
});

export default client;
