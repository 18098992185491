import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import tools from 'app/reducers/tools';
import authed from '../reducers/authed';
import environment from '../reducers/environment';
import ptc from '../actors/client/reducers/ptc';
import investor from '../actors/investor/modules/index';
import client from '../actors/client/ducks/index';
import admin from '../actors/admin/ducks/index';
import auditor from '../actors/cps/modules/index';
import ratingAgency from '../actors/cps/modules/index';
import trustee from '../actors/cps/modules/index';
import lawFirm from '../actors/cps/modules/index';
import internalUser from '../actors/internal/modules/index';
import entities from './entities';
import entity from './entity/index';
import loading from './loading';
import * as types from '../constants/ActionTypes';

import bi from './bi';
import ptc2 from './ptc/index';
import ptcv1 from './ptcv1/index';
import da from './da/index';
import dav2 from './dav2/index';
import survey from './survey';
import tasks from './task';
import amplitude from './amplitude';
import transactions from './transactions';
import rsMapping from './rsMapping';
import search from './search.ts';
import assetClasses from './assetClasses';
import investorSignup from './investor_signup/index';
import dashboard from './dashboard';
import demandCollection from './demandCollection.ts';
import orders from './orders';

const appReducer = combineReducers({
    authed,
    environment,
    ptc,
    investor,
    admin,
    client,
    auditor,
    ratingAgency,
    trustee,
    lawFirm,
    loading,
    internalUser,
    transactions,
    search,
    bi,
    ptc2,
    ptcv1,
    da,
    dav2,
    survey,
    tasks,
    entities,
    entity,
    investorSignup,
    form: formReducer,
    router: routerReducer,
    toastr: toastrReducer,
    rsMapping,
    assetClasses,
    dashboard,
    demandCollection,
    tools,
    amplitude,
    orders,
});

const rootReducer = (state, action) => {
    if (action.type === types.RESET_AUTHED) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
