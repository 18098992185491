export const ACTORS = [
    { value: 'investor', label: 'Investor', type: 'external' },
    { value: 'customer', label: 'Customer', type: 'external' },
    { value: 'product', label: 'Product', type: 'internal' },
    { value: 'institutional_business', label: 'Institutional Business', type: 'internal' },
    { value: 'capital_markets', label: 'Digital Capital Markets', type: 'internal' },
    { value: 'auditor', label: 'Auditor', type: 'external' },
    { value: 'law_firm', label: 'Law Firm', type: 'external' },
    { value: 'trustee', label: 'Trustee', type: 'external' },
    { value: 'rating_agency', label: 'Rating Agency', type: 'external' },
    { value: 'operations', label: 'Operations', type: 'internal' },
    { value: 'risk', label: 'Risk', type: 'internal' },
    { value: 'treasury', label: 'Treasury', type: 'internal' },
    { value: 'credit', label: 'Credit', type: 'internal' },
    { value: 'client_acquisition_group', label: 'Client Aquisition Group', type: 'internal' },
    { value: 'client_service_group', label: 'Client Service Group', type: 'internal' },
    { value: 'guarantor', label: 'Guarantor', type: 'external' },
    { value: 'collateral_manager', label: 'Collateral Manager', type: 'external' },
];

export const INTERNAL_ACTORS = [
    { value: 'product', label: 'Product' },
    { value: 'institutional_business', label: 'Institutional Business' },
    { value: 'capital_markets', label: 'Digital Capital Markets' },
    { value: 'operations', label: 'Operations' },
    { value: 'risk', label: 'Risk' },
    { value: 'treasury', label: 'Treasury' },
    { value: 'credit', label: 'Credit' },
    { value: 'client_acquisition_group', label: 'Client Aquisition Group' },
    { value: 'client_service_group', label: 'Client Service Group' },
    { value: 'guarantor', label: 'Guarantor' },
];

export const EXTERNAL_ACTORS = [
    { value: 'investor', label: 'Investor' },
    { value: 'customer', label: 'Customer' },
    { value: 'auditor', label: 'Auditor' },
    { value: 'law_firm', label: 'Law Firm' },
    { value: 'trustee', label: 'Trustee' },
    { value: 'rating_agency', label: 'Rating Agency' },
    { value: 'collateral_manager', label: 'Collateral Manager' },
];

export const ROLES = [
    { value: 'admin', label: 'Admin', for: 'admin' },
    { value: 'analytics', label: 'Analytics', for: 'rating_agency' },
    { value: 'biz_development', label: 'Business Development', for: 'rating_agency' },
];

export const PTC_DOCS = {
    TERM_SHEET: 'termsheet',
    POWER_OF_ATTORNEY: 'power_of_attorney',
    SERVICING_AGREEMENT: 'servicing_agreement',
    ACCOUNTS_AGREEMENT: 'accounts_agreement',
    TRUST_DEED: 'trust_deed',
    ASSIGNMENT_AGREEMENT: 'assignment_agreement',
    SLCE_DEED: 'slce_deed',
    RBI_DISCLOSURE: 'rbi_disclosure',
    INFORMATION_MEMORANDUM: 'information_memorandum',
};

export const ENTITY_TYPES = [
    { value: 'NBFC', label: 'NBFC' },
    { value: 'SFB', label: 'SFB' },
];

export const NOTIFICATION_TYPES = [
    { value: 'customer_document_rejection_notification', label: 'Customer Document Rejection' },
    { value: 'customer_document_verification_notification', label: 'Customer Document Verification' },
    { value: 'customer_document_alert_reminder', label: 'Customer Document Alert' },
];
