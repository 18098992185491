import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';

const initialState = {
    nach: {
        loading: false,
        error: null,
        data: [],
        pageNumber: 1,
        totalPages: 1,
        filters: {
            fromDate: '',
            toDate: '',
        },
    },
};

const createActionName = (name: string) => `app/demandCollection/${name}`;

const nachBaseURL = `${process.env.REACT_APP_MP_API_HOST}/nach_reports`;

export const GET_NACH_REQUEST = createActionName('GET_NACH_REQUEST');
export const GET_NACH_SUCCESS = createActionName('GET_NACH_SUCCESS');
export const GET_NACH_FAILURE = createActionName('GET_NACH_FAILURE');

export const UPLOAD_NACH_REQUEST = createActionName('UPLOAD_NACH_REQUEST');
export const UPLOAD_NACH_SUCCESS = createActionName('UPLOAD_NACH_SUCCESS');
export const UPLOAD_NACH_FAILURE = createActionName('UPLOAD_NACH_FAILURE');

export const REMOVE_NACH_REQUEST = createActionName('REMOVE_NACH_REQUEST');
export const REMOVE_NACH_SUCCESS = createActionName('REMOVE_NACH_SUCCESS');
export const REMOVE_NACH_FAILURE = createActionName('REMOVE_NACH_FAILURE');

export const FILTER_NACH = createActionName('FILTER_NACH');
export const RESET_NACH = createActionName('RESET_NACH');

export default function demandCollection(state = initialState, action: any) {
    switch (action.type) {
        case GET_NACH_REQUEST: {
            return {
                ...state,
                nach: {
                    ...state.nach,
                    loading: true,
                    error: null,
                    filters: {
                        fromDate: action.filters.fromDate,
                        toDate: action.filters.toDate,
                    },
                },
            };
        }
        case GET_NACH_SUCCESS: {
            return {
                ...state,
                nach: {
                    ...state.nach,
                    data: action.payload,
                    loading: false,
                },
            };
        }
        case GET_NACH_FAILURE: {
            return {
                ...state,
                nach: {
                    ...state.nach,
                    loading: false,
                    error: action.message,
                },
            };
        }
        case FILTER_NACH: {
            return {
                ...state,
                nach: {
                    ...state.nach,
                    filters: {
                        ...state.nach.filters,
                        from: action.payload.filter.from,
                        to: action.payload.filter.to,
                    },
                },
            };
        }
        case RESET_NACH: {
            return {
                ...state,
                nach: {
                    ...state.nach,
                    filters: {},
                },
            };
        }
        default:
            return state;
    }
}

const INITIAL_START_DATE = moment()
    .subtract(1, 'month')
    .format('DD-MMM-YYYY');
const INITIAL_END_DATE = moment().format('DD-MMM-YYYY');

const sortData = (data: any) => {
    return data.sort(function compare(a: any, b: any) {
        const dateA: any = moment(a.date, 'YYYY-MM-DD').toDate();
        const dateB: any = moment(b.date, 'YYYY-MM-DD').toDate();
        return dateB - dateA;
    });
};

export const getNACHRepayments = ({ from = INITIAL_START_DATE, to = INITIAL_END_DATE }: { from?: string; to?: string }) => {
    return (dispatch: any) => {
        dispatch({
            type: GET_NACH_REQUEST,
            filters: {
                fromDate: from,
                toDate: to,
            },
        });
        return fetchUtils
            .getJSON(`${nachBaseURL}/?from_date=${from}&to_date=${to}`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({
                    type: GET_NACH_SUCCESS,
                    payload: sortData(d),
                });
            })
            .catch((err: any) =>
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    dispatch({ type: GET_NACH_FAILURE, message: m });
                }),
            );
    };
};

export const uploadNACHCollection = (nachId: string, files: any) => {
    const body = new FormData();
    body.append('document', files[0]);
    return fetchUtils
        .putFormData(`${nachBaseURL}/${nachId}`, body)
        .then((d) => {
            toastr.success('Success!', 'NACH Collection has been uploaded');
            return humps.camelizeKeys(d);
        })
        .catch((err: any) =>
            fetchUtils.handleErrorV2(err).then((m: string) => {
                toastr.error('An error occurred when uploading the document!', m);
                throw m;
            }),
        );
};

export const removeNACHCollection = (nachId: string) => {
    return (dispatch: any) => {
        dispatch({
            type: REMOVE_NACH_REQUEST,
        });
        return fetchUtils
            .deleteJSON(`${nachBaseURL}/${nachId}/collection_report`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                toastr.success('Success!', 'Collection report has been deleted.');
                return dispatch({
                    type: REMOVE_NACH_SUCCESS,
                    payload: d,
                });
            })
            .catch((err: any) =>
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    dispatch({ type: REMOVE_NACH_SUCCESS, message: m });
                }),
            );
    };
};

export const resetNACHRepayments = () => {
    return (dispatch: any) => {
        dispatch({ type: RESET_NACH });
    };
};
