import React, { useEffect } from 'react';

import { useAuth } from '@yubi/yb-module-auth';

const Logout = () => {
    const { onLogout: logout } = useAuth();

    useEffect(() => {
        logout({ returnTo: window.origin });
    }, []);

    return <></>;
};

export default Logout;
