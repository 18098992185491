import React from 'react';
import Logo from 'app/assets/images/yubi-logos/yubi-normal.svg';
import ParsedLink from 'app/components/ParsedLink';
import StyledFooterLinks from './styled';

const FooterLinks = () => {
    return (
        <StyledFooterLinks>
            <div className="container">
                <div className="row">
                    <div className="span5">
                        <div className="footer_logo">
                            <figure>
                                <img src={Logo} alt="Logo" />
                            </figure>
                        </div>
                    </div>
                    <ul className="list-inline social-links">
                        <li className="facebook">
                            <ParsedLink to="https://www.facebook.com/credavenue/" title="Yubi Facebook page" rel="noopener noreferrer" newTab>
                                <i className="icon-facebook" />
                            </ParsedLink>
                        </li>
                        <li className="twitter">
                            <ParsedLink to="https://twitter.com/Yubi_fin" title="Yubi Twitter page" rel="noopener noreferrer" newTab>
                                <i className="icon-twitter" />
                            </ParsedLink>
                        </li>
                        <li className="linkedin">
                            <ParsedLink to="https://www.linkedin.com/company/go-yubi/" title="Yubi Linkedin page" rel="noopener noreferrer" newTab>
                                <i className="icon-linkedin" />
                            </ParsedLink>
                        </li>
                    </ul>
                    <div className="span7">
                        <div className="footer_links">
                            <ul className="list-inline footer_links-list">
                                <li>
                                    <h4>About</h4>
                                    <ul className="list-unstyled">
                                        <li>
                                            <ParsedLink to={`${process.env.REACT_APP_NEW_HOST}/news-and-media`}>In The News</ParsedLink>
                                        </li>
                                        <li>
                                            <ParsedLink to="https://www.vivriticapital.com/" rel="noopener noreferrer">
                                                About Vivriti Capital
                                            </ParsedLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>Get Started</h4>
                                    <ul className="list-unstyled">
                                        <li>
                                            <ParsedLink to={`${process.env.REACT_APP_CS_HOST}/sign-up`} rel="noopener noreferrer">
                                                Signup as a Borrower
                                            </ParsedLink>
                                        </li>
                                        <li>
                                            <ParsedLink to="investor_signup" rel="noopener noreferrer">
                                                Signup as an Investor
                                            </ParsedLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooterLinks>
    );
};

export default FooterLinks;
