import { EventTypes } from './AmplitudeTypes';

export const CAEvents = {
    Authentication: {
        LoginVisited: {
            eventName: 'Login Visited',
            type: EventTypes.track,
            category: 'Authentication',
        },
        LoginInitiated: {
            eventName: 'Login Initiated',
            type: EventTypes.track,
            category: 'Authentication',
        },
        LoginCompleted: {
            eventName: 'Login Completed',
            type: EventTypes.track,
            category: 'Authentication',
        },
        LogoutCompleted: {
            eventName: 'Logout Completed',
            type: EventTypes.track,
            category: 'Authentication',
        },
        MFAVisited: {
            eventName: '2FA Visited',
            type: EventTypes.track,
            category: 'Authentication',
        },
        MFAInitiated: {
            eventName: '2FA Initiated',
            type: EventTypes.track,
            category: 'Authentication',
        },
        MFACompleted: {
            eventName: '2FA Completed',
            type: EventTypes.track,
            category: 'Authentication',
        },
        ForgotPasswordVisited: {
            eventName: 'Forgot Password Visited',
            type: EventTypes.track,
            category: 'Authentication',
        },
        ForgotPasswordEmailEntered: {
            eventName: 'Forgot Password Email Entered',
            type: EventTypes.track,
            category: 'Authentication',
        },
        PasswordSetupVisited: {
            eventName: 'Password Setup Visited',
            type: EventTypes.track,
            category: 'Authentication',
        },
        PasswordSetupEmailEntered: {
            eventName: 'Password Setup Email Entered',
            type: EventTypes.track,
            category: 'Authentication',
        },
        PasswordSetupCompleted: {
            eventName: 'Password Setup Completed',
            type: EventTypes.track,
            category: 'Authentication',
        },
        BactToLoginClicked: {
            eventName: 'Back to Login Clicked',
            type: EventTypes.track,
            category: 'Authentication',
        },
    },
    DEAL_LISTING: {
        DEAL_LIST_ITEM_CLICK: {
            eventName: 'DEAL_LIST_ITEM_CLICK',
            type: EventTypes.track,
            category: 'DEAL_LISTING',
        },
    },
    YUBI_LAUNCH: {
        YUBI_LAUNCH_NOTIFICATION_CLOSED: {
            eventName: 'YUBI_LAUNCH_NOTIFICATION_CLOSED',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH_NOTIFICATION',
        },
        YUBI_LAUNCH_NOTIFICATION_SHOWN: {
            eventName: 'YUBI_LAUNCH_NOTIFICATION_SHOWN',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH_NOTIFICATION',
        },
        YUBI_LAUNCH_NOTIFICATION_KNOW_MORE_CLICKED: {
            eventName: 'YUBI_LAUNCH_NOTIFICATION_KNOW_MORE_CLICKED',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH_NOTIFICATION',
        },
        YUBI_LAUNCH_NOTIFICATION_KNOW_MORE_CLOSED: {
            eventName: 'YUBI_LAUNCH_NOTIFICATION_KNOW_MORE_CLOSED',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH_NOTIFICATION',
        },
        YUBI_DOMAIN_RESOLVED_STATUS: {
            eventName: 'YUBI_DOMAIN_RESOLVED_STATUS',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH',
        },
        YUBI_DOMAIN_TP_COOKIE_NOT_SUPPORTED: {
            eventName: 'YUBI_DOMAIN_TP_COOKIE_NOT_SUPPORTED',
            type: EventTypes.track,
            category: 'YUBI_LAUNCH',
        },
    },
    WHITELISTED_ALERT: {
        WHITELISTED_NOTIFICATION_CLOSED: {
            eventName: 'WHITELISTED_NOTIFICATION_CLOSED',
            type: EventTypes.track,
            category: 'WHITELISTED_ALERT',
        },
        WHITELISTED_NOTIFICATION_SHOWN: {
            eventName: 'WHITELISTED_NOTIFICATION_SHOWN',
            type: EventTypes.track,
            category: 'WHITELISTED_ALERT',
        },
        WHITELISTED_NOTIFICATION_KNOW_MORE_CLICKED: {
            eventName: 'WHITELISTED_NOTIFICATION_KNOW_MORE_CLICKED',
            type: EventTypes.track,
            category: 'WHITELISTED_ALERT',
        },
        WHITELISTED_NOTIFICATION_KNOW_MORE_CLOSED: {
            eventName: 'WHITELISTED_NOTIFICATION_KNOW_MORE_CLOSED',
            type: EventTypes.track,
            category: 'WHITELISTED_ALERT',
        },
        ACCOUNT_URL_BLOCKED: {
            eventName: 'ACCOUNT_URL_BLOCKED',
            type: EventTypes.track,
            category: 'WHITELISTED_ALERT',
        },
    },
};
