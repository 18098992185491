export const initialColumnFrequency = {
    datePattern: 'loan_specific_schedule',
    recurringPattern: {
        type: '',
        value: [
            {
                range: '',
                repeatCount: '',
            },
        ],
    },
};

export const DATE_PATTERN = {
    loanSpecific: 'loan_specific_schedule',
    common: 'common_schedule',
};
