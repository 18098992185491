import styled from 'styled-components';
import loginPattern from '../../assets/images/login-pattern.png';
import sprite from '../../assets/images/credhome-sprite.png';

const primaryColor = '#293577';
const secondaryColor = '#00c7cc';
const tertiaryColor = '#f46058';

const StyledLogin = styled.section`
    position: relative;
    padding: 100px 0;
    overflow: hidden;
    background: #fff;
    @media (min-width: 992px) and (max-width: 1500px) {
        padding: 40px 0;
    }
    &:before {
        position: absolute;
        content: '';
        border-width: 1700px;
        border-right-width: 850px;
        border-style: solid;
        border-color: transparent;
        border-bottom: none;
        border-left: none;
        border-top-color: ${primaryColor};
        top: 0px;
        left: 0px;
    }
    &:after {
        position: absolute;
        content: '';
        background: url(${loginPattern}) no-repeat center center;
        width: 520px;
        height: 560px;
        background-size: cover;
        bottom: 0px;
        right: 0px;
        z-index: 0;
        pointer-events: none;
    }
    .row {
        max-width: 90%;
        margin: 0 auto;
        .content {
            color: #fff;
            max-width: 65%;
            h1 {
                color: #fff;
                font-weight: 700;
                font-size: 35px;
                text-transform: uppercase;
                margin-bottom: 20px;
                span {
                    color: ${secondaryColor};
                }
            }
            p {
                color: #fff;
                line-height: 20px;
                font-size: 12px;
                padding: 0;
            }
        }
        .login {
            max-width: 80%;
            height: 100%;
            background: none;
            min-height: 300px;
            &.forgot {
                form {
                    .form-group {
                        margin-bottom: 0;
                    }
                }
            }
            h4 {
                color: ${primaryColor};
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 40px;
            }
            form {
                position: relative;
                width: 60%;
                .frm-grp {
                    position: relative;
                    i {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        font-style: normal;
                        font-size: 16px;
                    }
                }
                .form-group {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    input {
                        background: transparent;
                        border-color: #b5b6b7;
                        color: #666;
                    }
                    input:-internal-autofill-selected {
                        opacity: 0.7;
                    }
                    label {
                        color: #333;
                        left: 10px;
                        font-size: 12px;
                        cursor: text;
                        transition: transform 0.2s ease-out, color 0.2s ease-out;
                        transform-origin: 0% 100%;
                        text-align: initial;
                    }
                    i {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        font-style: normal;
                        font-size: 16px;
                    }
                    input:hover {
                        border-color: ${primaryColor};
                    }
                    &.active,
                    &.focus {
                        font-size: 12px;
                        input {
                            border-color: ${primaryColor};
                        }
                        i {
                            color: ${primaryColor};
                        }
                    }
                    .error {
                        input {
                            border-color: ${tertiaryColor} !important;
                        }
                        i {
                            color: ${tertiaryColor};
                        }
                        label {
                            color: ${tertiaryColor} !important;
                        }
                        .error-text {
                            color: ${tertiaryColor} !important;
                        }
                    }
                    .error + i {
                        color: ${tertiaryColor} !important;
                        margin-top: -6px;
                    }
                }
                .actions {
                    display: flex;
                    justify-content: space-between;
                    margin: 10px 0 40px;
                    li {
                        a {
                            color: ${secondaryColor};
                            font-style: italic;
                            &:hover {
                                color: ${tertiaryColor};
                            }
                        }
                        button {
                            background: ${tertiaryColor};
                            border: 1px solid ${tertiaryColor};
                            color: #fff;
                            padding: 8px 20px;
                            text-transform: uppercase;
                            font-size: 13px;
                            font-weight: 600;
                            min-width: 120px;
                            border-radius: 0;
                            &:hover {
                                background: #fff;
                                color: ${tertiaryColor};
                            }
                        }
                    }
                }
                .new-user {
                    font-style: normal;
                    padding-bottom: 40px;
                    text-align: center;
                    a {
                        color: ${secondaryColor};
                        font-style: italic;
                        position: relative;
                        &:first-child {
                            margin-right: 12px;
                            &:after {
                                position: absolute;
                                content: '';
                                width: 1px;
                                height: 100%;
                                background: #000;
                                right: -9px;
                                top: 0px;
                            }
                        }
                        &:hover {
                            color: ${tertiaryColor};
                        }
                    }
                }
                .pass-prog {
                    position: absolute;
                    top: 31px;
                    right: 40px;
                    .progress {
                        width: 50px;
                        height: 10px;
                        border-radius: 50px;
                        background: #e4e3e3;
                        display: inline-block;
                        margin: 0;
                        .progress-bar {
                            height: 10px;
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                        }
                    }
                    &.weak .progress-bar {
                        width: 33.33%;
                        background: #b62b2f;
                    }
                    &.medium .progress-bar {
                        width: 66.66%;
                        background: #e1ac2e;
                    }
                    &.strong .progress-bar {
                        width: 100%;
                        background: #3ea245;
                        border-radius: 50px;
                    }
                }
            }
            .password-info {
                position: absolute;
                display: block;
                left: 450px;
                box-shadow: 0 0 5px #ccc;
                background: #fff;
                color: #807f7f;
                min-width: 280px;
                padding: 10px;
                top: -5px;
                right: 40px;
                width: 40%;
            }
            .password-info.open {
                display: block;
            }
            .password-info:before {
                position: absolute;
                content: '';
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid #ccc;
                top: 100px;
                margin-left: -20px;
            }
            .password-info:after {
                position: absolute;
                content: '';
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid #fff;
                top: 100px;
                margin-left: -20px;
                left: 13px;
            }
            .password-info .close {
                background: #8c8c8c;
                color: #fff;
                font-size: 12px;
                font-weight: 600;
                border: none;
                position: absolute;
                right: 10px;
                top: 10px;
                width: 15px;
                height: 15px;
                border-radius: 20px;
                text-align: center;
                padding: 0px;
                cursor: pointer;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .password-info .close i {
                vertical-align: middle;
            }
            .password-info p {
                margin-bottom: 5px;
                color: #333;
                font-size: 14px;
            }
            .password-info .password-info-detail li {
                font-size: 12px;
                padding: 2px 2px 2px 20px;
                position: relative;
            }
            .password-info .password-info-detail li:before {
                position: absolute;
                content: '\\e920';
                font-family: 'icomoon';
                font-size: 14px;
                vertical-align: middle;
                top: 3px;
                left: 0px;
            }
            .password-info .password-info-detail li.success {
                color: #3ea245;
            }
            .password-info .password-info-detail li.pending {
                color: #b62b2f;
            }
            .password-info .password-info-detail li.pending:before {
                content: '\\e924';
            }
            .password-info .password-progress {
                line-height: 0;
            }
            .password-info .password-progress.weak li:nth-child(1),
            .password-info .password-progress.weak li:nth-child(2) {
                background: #b62b2f;
            }
            .password-info .password-progress.medium li:nth-child(1),
            .password-info .password-progress.medium li:nth-child(2),
            .password-info .password-progress.medium li:nth-child(3),
            .password-info .password-progress.medium li:nth-child(4) {
                background: #e1ac2e;
            }
            .password-info .password-progress.strong li {
                background: #3ea245;
            }
            .password-info .password-progress ul {
                display: inline-block;
                margin-bottom: 20px;
                font-size: 0;
            }
            .password-info .password-progress ul li {
                width: 50px;
                height: 5px;
                border-radius: 20px;
                background: #ccc;
                display: inline-block;
                margin: 0 2px;
            }
            .password-info .password-progress span {
                margin-left: 10px;
                vertical-align: middle;
                display: none;
            }
            .password-info .password-progress.weak .password-weak {
                display: inline-block;
                color: #b62b2f;
            }
            .password-info .password-progress.medium .password-medium {
                display: inline-block;
                color: #e1ac2e;
            }
            .password-info .password-progress.strong .password-strong {
                display: inline-block;
                color: #3ea245;
            }
        }
    }
    .white-bg {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            background: #fff;
            width: 1200px;
            height: 400px;
            left: -40%;
            top: 100px;
            z-index: 0;
            pointer-events: none;
        }
    }
    .banner_overall {
        background: #fff;
        box-shadow: 0px 0px 10px #ccc;
        padding: 20px 0 20px 50px;
        position: relative;
        margin-bottom: 80px;
        max-width: 85%;
        margin: 0 auto;
        z-index: 9;
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
            li {
                padding: 20px 50px;
                position: relative;
                &:not(:last-child):after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    background: #eaeaef;
                    right: 0px;
                    top: 0px;
                }
                i {
                    width: 84px;
                    height: 78px;
                    display: block;
                    background: #fff url(${sprite}) no-repeat 0px 0px;
                    transform: scale(0.5);
                    transform-origin: 0 0;
                    &.credhome-investors {
                        background-position: 0px -308px;
                    }
                    &.credhome-borrowers {
                        background-position: 0px -400px;
                    }
                    &.credhome-live {
                        background-position: 0px -105px;
                    }
                    &.credhome-settled {
                        background-position: 0px -208px;
                    }
                    &.credhome-volume {
                        background-position: 0px -914px;
                    }
                }
                p {
                    color: #929497;
                    font-size: 13px;
                    font-weight: 300;
                    text-transform: inherit;
                    padding-bottom: 5px;
                }
                span {
                    color: ${primaryColor};
                    font-size: 23px;
                    font-weight: 700;
                    i {
                        vertical-align: middle;
                        width: auto;
                        height: auto;
                        display: inline-block;
                        background: no-repeat;
                        transform: none;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    @media (min-width: 2036px) {
        &:before {
            border-width: 2680px;
            border-right-width: 1050px;
        }
    }
    @media (max-width: 2035px) {
        &:before {
            border-width: 2180px;
            border-right-width: 1000px;
        }
    }
    @media (max-width: 1895px) {
        &:before {
            border-width: 1500px;
            border-right-width: 920px;
        }
        .row {
            max-width: 100%;
        }
    }
    @media (max-width: 1740px) {
        &:before {
            border-width: 1580px;
            border-right-width: 870px;
        }
    }
    @media (min-width: 1531px) and (max-width: 1640px) {
        &:before {
            border-width: 1580px;
            border-right-width: 790px;
        }
        &:after {
            width: 380px;
            height: 400px;
        }
    }
    @media (max-width: 1530px) {
        &:before {
            border-width: 1580px;
            border-right-width: 740px;
        }
        &:after {
            width: 380px;
            height: 400px;
        }
    }
    @media (max-width: 1380px) {
        &:before {
            border-width: 1500px;
            border-right-width: 670px;
        }
        &:after {
            width: 380px;
            height: 400px;
        }
        .row {
            .content {
                h1 {
                    font-size: 28px;
                }
            }
        }
    }
    @media (min-width: 1101px) and (max-width: 1250px) {
        &:before {
            border-width: 1240px;
            border-right-width: 600px;
        }
        &:after {
            width: 380px;
            height: 400px;
        }
    }
    @media (max-width: 1100px) {
        &:before {
            border-width: 1240px;
            border-right-width: 530px;
        }
        &:after {
            width: 380px;
            height: 400px;
        }
        .row {
            .content {
                h1 {
                    font-size: 24px;
                }
            }
        }
    }
    @media (max-width: 991px) {
        padding: 100px 0;
        margin-top: 60px;
        &:before {
            border-width: 550px;
            border-right-width: 520px;
        }
        &:after {
            width: 180px;
            height: 200px;
        }
        .banner_overall {
            padding: 0;
            max-width: 100%;
            margin-bottom: 60px;
            ul {
                display: block;
                li {
                    padding: 10px 0 20px;
                    text-align: center;
                    width: 50%;
                    &:not(:last-child):after {
                        width: 100%;
                        height: 1px;
                        bottom: 0px;
                        top: auto;
                    }
                    &:nth-child(3):after {
                        height: 0;
                    }
                    &:not(:last-child):before {
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 85%;
                        background: #eaeaef;
                        right: 0px;
                        top: 25px;
                    }
                    &:nth-child(3):before {
                        top: 0;
                    }
                    &:nth-child(2):before {
                        width: 0;
                    }
                    i {
                        transform-origin: 40px 40px;
                        margin: 0 auto;
                        /* transform: scale(.4); */
                    }
                    /* span {
                            font-size: 16px;
                        } */
                }
            }
            &:after {
                bottom: -50px;
                right: -20px;
            }
        }
        .row {
            max-width: 100%;
            .content {
                margin-bottom: 40px;
                h1 {
                    font-size: 16px;
                }
            }
            .login {
                max-width: 100%;
                h4 {
                    text-align: center;
                }
                form {
                    .actions {
                        li {
                            padding: 10px 0;
                            a {
                                display: block;
                            }
                            span {
                                a {
                                    &:last-child {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        padding: 20px 0;
        margin-top: 60px;
        &:before {
            border-width: 250px;
            border-right-width: 800px;
        }
        &:after {
            width: 180px;
            height: 200px;
        }
        .row {
            max-width: 100%;
            .content {
                margin-bottom: 70px;
                max-width: 100%;
                h1 {
                    font-size: 16px;
                }
            }
            .login {
                max-width: 100%;
                h4 {
                    text-align: center;
                }
                form {
                    .actions {
                        li {
                            padding: 10px 0;
                            a {
                                display: block;
                            }
                            span {
                                a {
                                    &:last-child {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 500px) and (max-width: 767px) {
        &:before {
            border-width: 250px;
            border-right-width: 950px;
        }
        .row {
            .content {
                max-width: 60%;
            }
        }
    }
`;

export default StyledLogin;
