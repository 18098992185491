import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import { createLoadingSelector } from '../../../reducers/loading';
import { getActiveEntityId } from '../../../actions/AuthedActions';
import * as fetchUtils from '../../../utils/FetchUtils';
import { tasksAllUpdate } from './tasks';

interface IInvoice {
    invoice: object;
    to: object;
    paymentBankAccountId: string;
    serviceDescriptions?: object;
}

const invoiceFormValues = {
    invoiceNumber: '',
    clientName: '',
    clientAddress: {
        line1: '',
    },
    clientGstin: '',
    state: '',
    code: '',
    supplyDate: '',
    supplyPlace: '',
    invoiceDate: '',
    serviceDescriptions: [
        {
            description: '',
            sac: '',
            taxableValue: '',
        },
    ],
    commonDescErrors: false,
};

const createActionName = (name: any) => `app/ptc/transaction/invoice/${name}`;
const baseURL = `${process.env.REACT_APP_MP_API_HOST}/securitizations`;

const initialState = {
    invoices: {
        invoices: [],
    },
    bankDetails: [],
    currentInvoice: invoiceFormValues,
    tasks: {
        id: '',
        taskFields: [],
    },
};

export const GET_INVOICE_LOADING = createLoadingSelector([createActionName('GET_INVOICE')]);
export const SIGNED_INVOICE_UPLOAD_LOADING = createLoadingSelector([createActionName('SIGNED_INVOICE_UPLOAD')]);
export const SIGNED_INVOICE_DELETE_LOADING = createLoadingSelector([createActionName('SIGNED_INVOICE_DELETE')]);
export const GET_INVOICE_DETAIL_LOADING = createLoadingSelector([createActionName('GET_INVOICE_DETAIL')]);

export const GET_INVOICE_REQUEST = createActionName('GET_INVOICE_REQUEST');
export const GET_INVOICE_SUCCESS = createActionName('GET_INVOICE_SUCCESS');
export const GET_INVOICE_FAILURE = createActionName('GET_INVOICE_FAILURE');

export const ADD_INVOICE_REQUEST = createActionName('ADD_INVOICE_REQUEST');
export const ADD_INVOICE_SUCCESS = createActionName('ADD_INVOICE_SUCCESS');
export const ADD_INVOICE_FAILURE = createActionName('ADD_INVOICE_FAILURE');

export const UPDATE_INVOICE_REQUEST = createActionName('UPDATE_INVOICE_REQUEST');
export const UPDATE_INVOICE_SUCCESS = createActionName('UPDATE_INVOICE_SUCCESS');
export const UPDATE_INVOICE_FAILURE = createActionName('UPDATE_INVOICE_FAILURE');

export const SIGNED_INVOICE_UPLOAD_REQUEST = createActionName('SIGNED_INVOICE_UPLOAD_REQUEST');
export const SIGNED_INVOICE_UPLOAD_SUCCESS = createActionName('SIGNED_INVOICE_UPLOAD_SUCCESS');
export const SIGNED_INVOICE_UPLOAD_FAILURE = createActionName('SIGNED_INVOICE_UPLOAD_FAILURE');

export const DELETE_INVOICE_REQUEST = createActionName('DELETE_INVOICE_REQUEST');
export const DELETE_INVOICE_SUCCESS = createActionName('DELETE_INVOICE_SUCCESS');
export const DELETE_INVOICE_FAILURE = createActionName('DELETE_INVOICE_FAILURE');

export const SIGNED_INVOICE_DELETE_REQUEST = createActionName('SIGNED_INVOICE_DELETE_REQUEST');
export const SIGNED_INVOICE_DELETE_SUCCESS = createActionName('SIGNED_INVOICE_DELETE_SUCCESS');
export const SIGNED_INVOICE_DELETE_FAILURE = createActionName('SIGNED_INVOICE_DELETE_FAILURE');

export const GET_BANK_REQUEST = createActionName('GET_BANK_REQUEST');
export const GET_BANK_SUCCESS = createActionName('GET_BANK_SUCCESS');
export const GET_BANK_FAILURE = createActionName('GET_BANK_FAILURE');

export const ADD_BANK_REQUEST = createActionName('ADD_BANK_REQUEST');
export const ADD_BANK_SUCCESS = createActionName('ADD_BANK_SUCCESS');
export const ADD_BANK_FAILURE = createActionName('ADD_BANK_FAILURE');

export const DELETE_BANK_REQUEST = createActionName('DELETE_BANK_REQUEST');
export const DELETE_BANK_SUCCESS = createActionName('DELETE_BANK_SUCCESS');
export const DELETE_BANK_FAILURE = createActionName('DELETE_BANK_FAILURE');

export const DRAFT_POLLING_REQUEST = createActionName('DRAFT_POLLING_REQUEST');
export const DRAFT_POLLING_SUCCESS = createActionName('DRAFT_POLLING_SUCCESS');
export const DRAFT_POLLING_FAILURE = createActionName('DRAFT_POLLING_FAILURE');

export const GET_INVOICE_DETAIL_REQUEST = createActionName('GET_INVOICE_DETAIL_REQUEST');
export const GET_INVOICE_DETAIL_SUCCESS = createActionName('GET_INVOICE_DETAIL_SUCCESS');
export const GET_INVOICE_DETAIL_FAILURE = createActionName('GET_INVOICE_DETAIL_FAILURE');

export const POST_TRANSACTION_TASK_REQUEST = createActionName('POST_TRANSACTION_TASK_REQUEST');
export const POST_TRANSACTION_TASK_SUCCESS = createActionName('POST_TRANSACTION_TASK_SUCCESS');
export const POST_TRANSACTION_TASK_FAILURE = createActionName('POST_TRANSACTION_TASK_FAILURE');

export default function invoice(state: any = initialState, action: any) {
    switch (action.type) {
        case GET_INVOICE_REQUEST:
            return {
                ...state,
                invoices:
                    _get(action, 'pageNumber', 1) === 1
                        ? {
                              invoices: [],
                          }
                        : { ..._get(state, 'invoices') },
            };
        case GET_INVOICE_SUCCESS:
            const { invoiceData } = action;
            return {
                ...state,
                invoices: {
                    invoices: [..._get(state, 'invoices.invoices', []), ..._get(invoiceData, 'invoices', [])],
                    currentPage: invoiceData.currentPage,
                    totalPages: invoiceData.totalPages,
                },
                currentInvoice: invoiceFormValues,
            };
        case ADD_INVOICE_SUCCESS:
            const newInvoice = _cloneDeep(state.invoices);
            const invoiceList = _get(newInvoice, 'invoices', []);
            const { data: newIn } = action;
            invoiceList.unshift(newIn);
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceList,
                },
                currentInvoice: {
                    ..._get(state, 'currentInvoice', invoiceFormValues),
                    ...newIn,
                },
            };
        case UPDATE_INVOICE_SUCCESS:
            const updateInvoice = _cloneDeep(state.invoices);
            const invoiceListUpdate = _get(updateInvoice, 'invoices', []);
            const { data: updateData } = action;
            const updateInvoiceIndex = invoiceListUpdate.findIndex((d: any) => d.id === updateData.id);
            invoiceListUpdate[updateInvoiceIndex] = updateData;
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListUpdate,
                },
                currentInvoice: updateData,
            };
        case DELETE_INVOICE_SUCCESS:
            const { invoiceId: invID } = action;
            const newDeleteInvoice = _cloneDeep(state.invoices);
            const invoiceListDelete = _get(newDeleteInvoice, 'invoices', []);
            const findInvId = invoiceListDelete.findIndex((d: any) => d.id === invID);
            invoiceListDelete.splice(findInvId, 1);
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListDelete,
                },
            };
        case GET_INVOICE_DETAIL_SUCCESS:
            const { data: currentInvoice } = action;
            return {
                ...state,
                currentInvoice,
            };
        case SIGNED_INVOICE_UPLOAD_REQUEST:
            const loadInvoices = _cloneDeep(state.invoices);
            const invoiceListSignedReq = _get(loadInvoices, 'invoices', []);
            const { invoiceId: loadInvoiceId } = action;
            const loadId = invoiceListSignedReq.findIndex((d: any) => d.id === loadInvoiceId);
            invoiceListSignedReq[loadId] = {
                ...invoiceListSignedReq[loadId],
                uploading: true,
            };
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListSignedReq,
                },
            };
        case SIGNED_INVOICE_UPLOAD_SUCCESS:
            const newInvSigned = _cloneDeep(state.invoices);
            const invoiceListSignedUp = _get(newInvSigned, 'invoices', []);
            const { invoiceId, signedUploadData } = action;
            const invoiceIndex = invoiceListSignedUp.findIndex((d: any) => d.id === invoiceId);
            invoiceListSignedUp[invoiceIndex] = { ...signedUploadData, uploading: false };
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListSignedUp,
                },
                currentInvoice: signedUploadData,
            };
        case SIGNED_INVOICE_DELETE_REQUEST:
            const deleteInvoices = _cloneDeep(state.invoices);
            const invoiceListSignedDelReq = _get(deleteInvoices, 'invoices', []);
            const { invoiceId: delInvoiceId } = action;
            const deleteId = invoiceListSignedDelReq.findIndex((d: any) => d.id === delInvoiceId);
            invoiceListSignedDelReq[deleteId] = {
                ...invoiceListSignedDelReq[deleteId],
                uploading: true,
            };
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListSignedDelReq,
                },
            };
        case SIGNED_INVOICE_DELETE_SUCCESS:
            const newInv = _cloneDeep(state.invoices);
            const invoiceListSignedDelUp = _get(newInv, 'invoices', []);
            const { invoiceId: deleteInvoiceId, signedDeleteData } = action;
            const d = invoiceListSignedDelUp.findIndex((d: any) => d.id === deleteInvoiceId);
            invoiceListSignedDelUp[d] = { ...signedDeleteData, uploading: false };
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    invoices: invoiceListSignedDelUp,
                },
                currentInvoice: signedDeleteData,
            };
        case DRAFT_POLLING_SUCCESS:
            const { data: draftInvoice } = action;
            return {
                ...state,
                currentInvoice: {
                    ..._get(state, 'currentInvoice', invoiceFormValues),
                    ...draftInvoice,
                },
            };
        case GET_BANK_SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case ADD_BANK_SUCCESS:
            const bankDetails = _cloneDeep(state.bankDetails);
            const { data } = action;
            bankDetails.push(data);
            return {
                ...state,
                bankDetails,
            };
        case DELETE_BANK_SUCCESS:
            const allBankDetails = _cloneDeep(state.bankDetails);
            const { data: deleteBankDetails } = action;
            const updateBankIndex = allBankDetails.findIndex((d: any) => d.id === deleteBankDetails.id);
            allBankDetails.splice(updateBankIndex, 1);
            return {
                ...state,
                bankDetails: allBankDetails,
            };
        default:
            return state;
    }
}

function mapInvoiceData(data: IInvoice) {
    const formData: any = {
        ..._omit(data, ['totalCgst', 'totalSgst', 'totalIgst', 'totalTaxableValue', 'grandTotal']),
    };
    return humps.decamelizeKeys(formData);
}

export function getInvoice(id: string, pageNumber: string) {
    return (dispatch: any) => {
        dispatch({ type: GET_INVOICE_REQUEST, id, pageNumber });
        return fetchUtils
            .getJSON(`${baseURL}/${id}/invoices?page=${pageNumber}&per_page=8&sort=desc`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({ type: GET_INVOICE_SUCCESS, invoiceData: data, id });
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: GET_INVOICE_FAILURE });
                    toastr.error('Error when retrieving invoices', m);
                });
            });
    };
}

export function addInvoice(payload: IInvoice, id: string, setSubmitting: any) {
    return (dispatch: any) => {
        dispatch({ type: ADD_INVOICE_REQUEST });
        return fetchUtils
            .postJSON(`${baseURL}/${id}/invoices`, mapInvoiceData(payload))
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                setSubmitting(false);
                dispatch({ type: ADD_INVOICE_SUCCESS, data, id });
                if (data.generatingDraftInvoice) {
                    dispatch(pollDraftDocument(id, data.id));
                } else {
                    toastr.success('Success!', 'Draft invoice generated successfully');
                }
                return data;
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error when adding invoice', m);
                });
                setSubmitting(false);
                dispatch({ type: ADD_INVOICE_FAILURE });
            });
    };
}

export function updateInvoice(payload: IInvoice, id: string, invoiceId: string, setSubmitting: any) {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_INVOICE_REQUEST });
        return fetchUtils
            .putJSON(`${baseURL}/${id}/invoices/${invoiceId}`, mapInvoiceData(payload))
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                setSubmitting(false);
                dispatch({ type: UPDATE_INVOICE_SUCCESS, data, id });
                if (data.generatingDraftInvoice) {
                    dispatch(pollDraftDocument(id, data.id));
                } else {
                    toastr.success('Success!', 'Draft invoice generated successfully');
                }
                return data;
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error when adding invoice', m);
                });
                setSubmitting(false);
                dispatch({ type: UPDATE_INVOICE_FAILURE });
            });
    };
}

export function deleteInvoice(transId: any, invoiceId: any) {
    return (dispatch: any) => {
        dispatch({ type: DELETE_INVOICE_REQUEST });
        return fetchUtils
            .deleteJSON(`${baseURL}/${transId}/invoices/${invoiceId}`)
            .then(() => {
                dispatch({ type: DELETE_INVOICE_SUCCESS, invoiceId, id: transId });
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error when deleting invoice', m);
                    dispatch({ type: DELETE_INVOICE_FAILURE });
                });
            });
    };
}

export function getInvoiceDetail(transId: string, invoiceId: string) {
    return (dispatch: any) => {
        dispatch({ type: GET_INVOICE_DETAIL_REQUEST });
        return fetchUtils
            .getJSON(`${baseURL}/${transId}/invoices/${invoiceId}`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({ type: GET_INVOICE_DETAIL_SUCCESS, id: transId, data });
                if (!data.generatingDraftInvoice) {
                    return data;
                }
                return null;
            })
            .catch((ex: any) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: GET_INVOICE_DETAIL_FAILURE });
                    toastr.error('Error when retrieving invoice detail', m);
                }),
            );
    };
}

export function signedInvoiceUpload(files: any, transId: any, invoiceId: any) {
    const body = new FormData();
    Array.from(files).forEach((file: any) => {
        body.append('document', file);
    });
    return (dispatch: any) => {
        dispatch({ type: SIGNED_INVOICE_UPLOAD_REQUEST, invoiceId, id: transId });
        return fetchUtils
            .postFormData(`${baseURL}/${transId}/invoices/${invoiceId}/signed_invoice_document`, body)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({
                    type: SIGNED_INVOICE_UPLOAD_SUCCESS,
                    id: transId,
                    invoiceId,
                    signedUploadData: data,
                });
                const taskDocument = {
                    ..._get(data, 'transactionTaskDocumentDetails'),
                    filename: _get(data, 'transactionTaskDocumentDetails.fileName', ''),
                };
                dispatch(postTransactionTasks(transId, data.transactionTaskDocumentId, taskDocument, true));
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error uploading signed invoice', m);
                    dispatch({ type: SIGNED_INVOICE_UPLOAD_FAILURE });
                });
            });
    };
}

export function deleteSignedInvoice(transId: any, invoiceId: any) {
    return (dispatch: any) => {
        dispatch({ type: SIGNED_INVOICE_DELETE_REQUEST, invoiceId, id: transId });
        return fetchUtils
            .deleteJSON(`${baseURL}/${transId}/invoices/${invoiceId}/signed_invoice_document`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({
                    type: SIGNED_INVOICE_DELETE_SUCCESS,
                    id: transId,
                    invoiceId,
                    signedDeleteData: data,
                });
                dispatch(postTransactionTasks(transId, data.transactionTaskDocumentId, null));
                return data;
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error when deleting signed invoice document', m);
                });
                dispatch({ type: SIGNED_INVOICE_DELETE_FAILURE });
            });
    };
}

export function pollDraftDocument(id: string, invoiceId: string) {
    return (dispatch: any) =>
        fetchUtils
            .poll(() => dispatch(getInvoiceDetail(id, invoiceId)), 60000, 1000)
            .then((d) => {
                dispatch({ type: DRAFT_POLLING_SUCCESS, data: d, id });
                toastr.success('Success!', 'Draft invoice generated successfully');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Generating Documents', errMsg);
                }
            });
}

export function getBankDetails(id: any) {
    return (dispatch: any) => {
        dispatch({ type: GET_BANK_REQUEST });
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${getActiveEntityId()}/bank_details`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({
                    type: GET_BANK_SUCCESS,
                    id,
                    data: {
                        bankDetails: data.bankDetails,
                        fromInvoiceDetails: data.fromInvoiceDetails,
                    },
                });
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error when retrieving bank information', m);
                    dispatch({ type: GET_BANK_FAILURE });
                });
            });
    };
}

export function addBank(bankData: object, transId: string, setSubmitting: any, setOpenModal: any) {
    return (dispatch: any) => {
        dispatch({ type: ADD_BANK_REQUEST });
        return fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${getActiveEntityId()}/bank_details`, humps.decamelizeKeys(bankData))
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({
                    type: ADD_BANK_SUCCESS,
                    id: transId,
                    data: data.bankDetails,
                });
                setSubmitting(false);
                setOpenModal(false);
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error adding bank information', m);
                    dispatch({ type: ADD_BANK_FAILURE });
                    setSubmitting(false);
                    setOpenModal(false);
                });
            });
    };
}

export function deleteBankDetails(bankId: string, transId: string) {
    return (dispatch: any) => {
        dispatch({ type: DELETE_BANK_REQUEST });
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${getActiveEntityId()}/bank_details/${bankId}`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((data: any) => {
                dispatch({
                    type: DELETE_BANK_SUCCESS,
                    id: transId,
                    data: data.bankDetails,
                });
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error deleting bank information', m);
                });
                dispatch({ type: DELETE_BANK_FAILURE });
            });
    };
}

export function postTransactionTasks(id: string, currentTaskId: string, tasksDetails: object | null = null, isAdd = false) {
    return (dispatch: any, getState: any) => {
        const taskFieldsAll = _get(getState(), `ptc2.transaction[${id}].tasks.all`, []);
        const tasksData: any = {
            id: '',
            taskFields: [],
        };
        const taskIndex = taskFieldsAll.findIndex((data: any) => data.name === 'treasury#upload_invoice');
        tasksData.id = taskFieldsAll[taskIndex].id;
        taskFieldsAll[taskIndex].taskFields.forEach((field: any) => {
            if (_get(field, 'value')) {
                field.value.forEach((d: any) => {
                    tasksData.taskFields.push(d.id);
                });
            }
        });
        if (isAdd) {
            tasksData.taskFields.push(currentTaskId);
            if (taskFieldsAll[taskIndex].taskFields[0].value) {
                taskFieldsAll[taskIndex].taskFields[0].value.push(tasksDetails);
            } else {
                taskFieldsAll[taskIndex].taskFields[0].value = Array.of(tasksDetails);
            }
        } else {
            const index = tasksData.taskFields.indexOf(currentTaskId);
            if (index > -1) {
                tasksData.taskFields.splice(index, 1);
            }
            const removeIndex = taskFieldsAll[taskIndex].taskFields[0].value.findIndex((d: any) => d, id === currentTaskId);
            taskFieldsAll[taskIndex].taskFields[0].value.splice(removeIndex, 1);
        }
        dispatch({ type: POST_TRANSACTION_TASK_REQUEST });
        return fetchUtils
            .putJSON(`${baseURL}/${id}/transaction_tasks/${tasksData.id}`, mapTasksField(tasksData.taskFields))
            .then((d: any) => humps.camelizeKeys(d))
            .then(() => {
                const taskArr = [];
                const taskAll = {
                    all: taskFieldsAll,
                    data: taskArr.push(taskFieldsAll[taskIndex]),
                };
                dispatch(tasksAllUpdate(taskAll));
            })
            .catch((ex: any) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: POST_TRANSACTION_TASK_FAILURE });
                    toastr.error('Error when retrieving transaction tasks', m);
                });
            });
    };
}

function mapTasksField(allTasks: Array<string>) {
    return {
        transaction_task: {
            task_fields: [
                {
                    name: 'invoice',
                    value: allTasks,
                },
            ],
        },
    };
}
