import React from 'react';
import styled from 'styled-components';
import { Color, Direction, Align } from '../Types/CommonTypes';

interface CardContentProps {
    children: any;
    bgColor?: Color;
    className?: string;
    color?: Color;
    direction?: Direction;
    align?: Align;
}

const StyledCardContent = styled.div<CardContentProps>`
    ${({ direction }) =>
        direction === 'horizontal' &&
        `
        display:flex;
        justify-content:center;
        align-items:center;
    `}
    ${({ align }) =>
        align === 'leftTop' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'leftCenter' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'leftBottom' &&
        `
            display:inline-flex;
            justify-content:flex-start;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'rightTop' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-start;
    `}
    ${({ align }) =>
        align === 'rightCenter' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
    `}
    ${({ align }) =>
        align === 'rightBottom' &&
        `
            display:inline-flex;
            justify-content:flex-end;
            align-items:flex-end;
    `}
    ${({ align }) =>
        align === 'center' &&
        `
            display:inline-flex;
            justify-content:center;
            align-items:center;
    `}
`;

const CardContent: React.FC<CardContentProps> = ({ children, bgColor, className, color, direction, ...rest }): JSX.Element => {
    return (
        <StyledCardContent bgColor={bgColor} className={className} color={color} direction={direction} {...rest}>
            {children}
        </StyledCardContent>
    );
};

export default CardContent;
