import humps from 'humps';
import { UPDATE_ORDERSCOUNT } from './types';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from 'app/utils/FetchUtils';

const initialState = {
    isFetching: false,
    orderData: {
        pendingOrderCount: 0,
    },
};

export default function orders(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ORDERSCOUNT:
            return { ...state, orderData: { pendingOrderCount: action?.data?.orderCount } };
        default:
            return state;
    }
}

export const fetchOrderCount = (entityId) => (dispatch) =>
    fetchUtils
        .getJSON(`${process.env.REACT_APP_CS_MP_API_HOST}/entities/${entityId}/orders/order_count`)
        .then((r) => humps.camelizeKeys(r))
        .then((data) => {
            dispatch({ type: UPDATE_ORDERSCOUNT, data });
            return data;
        })
        .catch((ex) =>
            fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                toastr.error('Error retrieving customer data', m);
            }),
        );
