export interface DAProps {
    id: string;
    dealName: string;
    createdAt: string;
    assetClasses: string[];
    filterProcessing: boolean;
    loansSummary?: LoansSummary;
    poolSelectionCriteria?: PoolSelectionCriteria;
}

export interface PoolConcentration {
    stateName: number;
    districtName: number;
    branchName: number;
}

export interface Par {
    parFilters: any;
    rangedParFilters: any;
}

// eslint-disable-next-line import/prefer-default-export
export enum PivotTypes {
    DISCRETE_PIVOT = 'discrete',
    CONTINUOUS_PIVOT = 'continuous',
}

export interface PivotRange {
    from: number;
    to: number;
    splitEvery: number;
}

export interface PivotProps {
    name: string;
    type: PivotTypes;
    range: PivotRange;
}
export interface Pivots {
    continuousPivots: any;
    disceretePivots: any;
}

export interface LoansToReject {
    ids: string;
    reason: string;
}

export interface PoolSelectionCriteria {
    cutOffDate: string;
    seasoning: boolean;
    soonToMature: string;
    tenorStartsFrom: string;
    magicKey?: string;
    concentrations: PoolConcentration;
    par: Par;
    excludeTransactions: any[];
    pivots: Pivots;
    canTenorStartFromDisbursement: boolean;
    canTenorStartFromSecurityRegistrationDate: boolean;
    loansToReject: LoansToReject[];
    limitPoolSize: any;
}

export interface LoansSummary {
    totalNumberOfContracts: number;
    totalPrincipalOutstanding: number;
    noOfAcceptedContracts: number;
    poolPrincipal: number;
    poolMaturityDate: string;
    poolTenor: string;
    rejectionSummary: RejectionSummary;
    consolidatedPoolInfo: ConsolidatedPoolInfo[];
    liveDuplicates: Duplicates;
    settledDuplicates: Duplicates;
    pivotsSummary: PivotsSummary;
}

export interface Duplicates {
    count: number;
    dealSplitup: any[];
}

export interface PivotsSummary {
    waSeasoning: number;
    minSeasoning: number;
    maxSeasoning: number;
    waTenor: number;
    minTenor: number;
    maxTenor: number;
    waLtv: number;
    avgTicketSize: number;
    waIrr: number;
}
export interface RejectionSummary {
    noOfRejectedContracts: number;
    rejectedPrincipal: number;
    rejectionDetails: RejectionDetails;
    maxConcentrationAchieved: any;
}

export interface RejectionDetails {
    rejectedReason: string;
    rejectedPrincipal: number;
    rejectedContracts: number;
}
export interface ConsolidatedPoolInfo {
    name: string;
    principal: number;
    percent: number;
}

// {

//     poolSelectionCriteria: {

//     },
//     structuring: null,
//     poolPrincipal: 30603,
//     possiblePivots: [
//       {
//         name: 'loan_number',
//         type: 'discrete'
//       },
//       {
//         name: 'disbursement_date',
//         type: 'discrete'
//       },
//       {
//         name: 'first_installment_date',
//         type: 'discrete'
//       },
//       {
//         name: 'repayment_frequency',
//         type: 'discrete'
//       },
//       {
//         name: 'branch_name',
//         type: 'discrete',
//         'default': true
//       },
//       {
//         name: 'total_number_of_repayments',
//         type: 'continuous'
//       }
//     ],
//     prompts: null,
//     transactionState: 'draft',
//     slugId: 'ASSIGNMENT00000000',
//     stampDuty: null,
//     filterProcessing: false,
//     clientId: '5e96bfce14ff193ebd725931',
//     clientName: 'SATIN CREDITCARE NETWORK LIMITED',
//     acceptedInvestors: [],
//     missedDeal: false,
//     clientPreferences: {
//       humanizedAmount: null
//     },
//     canTenorStartFromDisbursement: true,
//     customer: [
//       {
//         id: '5e96bfce14ff193ebd725931',
//         name: 'SATIN CREDITCARE NETWORK LIMITED'
//       }
//     ],
//     auditors: [],
//     trustees: [],
//     ratingAgencies: [],
//     lawFirms: [],
//     investors: [],
//     operation: [
//       {
//         id: '5e96bfc514ff193ebd72592a',
//         name: 'tsest2dsds5 - VIVRITI CAPITAL PRIVATE LIMITED'
//       }
//     ],
//     auditorContact: null,
//     preferredInvestors: {
//       type: 'all',
//       entities: null
//     },
//     mcds: [
//       {
//         id: '5e9ed6f99ccc733125610b9e',
//         name: 'satin (mcd) Apr-21-2020 (1)'
//       }
//     ],
//     documents: [
//       {
//         type: 'fee_letter',
//         documentType: 'legal_documents',
//         displayName: 'Fee Letter',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'term_sheet',
//         documentType: 'legal_documents',
//         displayName: 'Term Sheet',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'rbi_disclosure',
//         documentType: 'legal_documents',
//         displayName: 'RBI Disclosure',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'poa',
//         documentType: 'legal_documents',
//         displayName: 'Power of Attorney',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'servicing_agreement',
//         documentType: 'legal_documents',
//         displayName: 'Servicing Agreement',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'assignment_agreement',
//         documentType: 'legal_documents',
//         displayName: 'Assignment Agreement',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'assignee_representative_letter',
//         documentType: 'legal_documents',
//         displayName: 'Assignee Representative Letter',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'asset_audit_report',
//         documentType: 'legal_documents',
//         displayName: 'Asset Audit Report',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'kyc_report',
//         documentType: 'legal_documents',
//         displayName: 'KYC Report',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'psl_certificate',
//         documentType: 'legal_documents',
//         displayName: 'PSL Certificate',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'ca_certificate',
//         documentType: 'legal_documents',
//         displayName: 'CA Certificate',
//         locked: false,
//         readAccess: [
//           'customer'
//         ],
//         executable: true,
//         section: 'legal_documents',
//         standardDoc: true,
//         files: []
//       }
//     ],
//     extraDocuments: [],
//     poolFiles: [
//       {
//         type: 'rejected_loans',
//         documentType: 'pool_files',
//         displayName: 'Rejected Loans',
//         locked: false,
//         readAccess: [
//           'customer',
//           'investors'
//         ],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'selected_loans',
//         documentType: 'pool_files',
//         displayName: 'Selected loans',
//         locked: false,
//         readAccess: [
//           'customer',
//           'auditors',
//           'trustees',
//           'rating_agencies',
//           'investors'
//         ],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: [
//           {
//             fileId: '5ea819df9ccc73149f7a13dc',
//             type: 'selected_loans',
//             state: null,
//             fileName: 'Vivriti_Greig_04_2020_Selected_Loans',
//             fileSize: 8509,
//             contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//             editable: true,
//             newDocVersion: false,
//             lastUpdatedAt: '2020-04-28T11:56:15.410+00:00'
//           }
//         ]
//       },
//       {
//         type: 'selected_loans_rs',
//         documentType: 'pool_files',
//         displayName: 'Selected Loans Repayments',
//         locked: false,
//         readAccess: [],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: [
//           {
//             fileId: '5ea819df9ccc73149f7a13de',
//             type: 'selected_loans_rs',
//             state: null,
//             fileName: 'Vivriti_Greig_04_2020_Selected_Loans_Rs',
//             fileSize: 11791,
//             contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//             editable: true,
//             newDocVersion: false,
//             lastUpdatedAt: '2020-04-28T11:56:15.551+00:00'
//           }
//         ]
//       },
//       {
//         type: 'cashflows',
//         documentType: 'pool_files',
//         displayName: 'Cashflows',
//         locked: false,
//         readAccess: [
//           'customer',
//           'investors'
//         ],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: [
//           {
//             fileId: '5ea819df9ccc73149f7a13e0',
//             type: 'cashflows',
//             state: null,
//             fileName: 'Vivriti_Greig_04_2020_Cashflows',
//             fileSize: 9140,
//             contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//             editable: true,
//             newDocVersion: false,
//             lastUpdatedAt: '2020-04-28T11:56:15.832+00:00'
//           }
//         ]
//       },
//       {
//         type: 'value_waterfall',
//         documentType: 'pool_files',
//         displayName: 'Waterfall',
//         locked: false,
//         readAccess: [
//           'customer',
//           'trustees',
//           'rating_agencies',
//           'product'
//         ],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: []
//       },
//       {
//         type: 'formula_waterfall',
//         documentType: 'pool_files',
//         displayName: 'Formula Waterfall',
//         locked: false,
//         readAccess: [
//           'product',
//           'investors'
//         ],
//         executable: null,
//         section: 'pool_file',
//         standardDoc: true,
//         files: []
//       }
//     ],
//     pivotOptions: {
//       discrete: [
//         {
//           name: 'loan_number',
//           type: 'discrete',
//           customName: 'Loan number'
//         },
//         {
//           name: 'disbursement_date',
//           type: 'discrete',
//           customName: 'Disbursement date'
//         },
//         {
//           name: 'first_installment_date',
//           type: 'discrete',
//           customName: 'First installment date'
//         },
//         {
//           name: 'repayment_frequency',
//           type: 'discrete',
//           customName: 'Repayment frequency'
//         },
//         {
//           name: 'branch_name',
//           type: 'discrete',
//           'default': true,
//           customName: 'Branch name'
//         }
//       ],
//       continuous: [
//         {
//           name: 'total_number_of_repayments',
//           type: 'continuous',
//           customName: 'Total number of repayments',
//           range: {
//             from: '',
//             to: '',
//             splitEvery: ''
//           }
//         }
//       ]
//     }
//   }
