import React, { Fragment } from 'react';

const EnvironmentAlert = ({ title, desc }) => (
    <Fragment>
        <div className="no-inter">
            <div className="noicn" />
            <p>
                <span>{title}</span> {desc}
            </p>
            <span className="close">×</span>
        </div>
        <div style={{ paddingTop: 34 }}>&nbsp;</div>
    </Fragment>
);

export default EnvironmentAlert;
