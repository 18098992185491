import humps from 'humps';

import moment from 'moment';

import _get from 'lodash/get';
import _size from 'lodash/size';
import omit from 'lodash/omit';

import Auth, { setProfile } from 'app/utils/Auth';

import flattenDeep from 'lodash/flattenDeep';

import { toastr } from 'react-redux-toastr';

import * as fetchUtils from 'app/utils/FetchUtils';
import { objectToFormData } from 'app/utils/CommonUtils';
import { getActiveEntityId, getActiveUser, getActiveUserId } from 'app/actions/AuthedActions';
import {
    CRA_RBAC_USERS,
    NOTIFICATION_CHANNELS,
    PRODUCT_ID,
    PRODUCT_ID_PLATFORMS,
    PRODUCT_ID_CREDIT,
    RBAC_USERS,
    USER_MANAGEMENT_ACCESS_DISABLED_ACTORS,
    USER_MANAGEMENT_ACCESS_ENABLED_GROUPS,
    PRODUCT_ID_LOANS,
    PRODUCT_ID_COLLECTIONS,
} from 'app/constants/Constants';

const initialState = {
    isFetching: false,
    switchUsersList: null,
    actorsAndRoles: [],
    credpoolRoles: [],
    userPlatformRoles: [],
    loanRoles: [],
    collectionRoles: [],
};

// actions
const createActionName = (name) => `app/entities/${name}`;
export const FETCHING_DATA = createActionName('FETCHING_DATA');
const TAG = 'SpoctoRoles';

export const ENTITY_LIST_SUCCESS = createActionName('ENTITY_LIST_SUCCESS');
export const SWITCH_LIST_SUCCESS = createActionName('SWITCH_LIST_SUCCESS');
export const ENTITY_FETCH_SUCCESS = createActionName('ENTITY_FETCH_SUCCESS');
export const ENTITY_ADD_SUCCESS = createActionName('ENTITY_ADD_SUCCESS');
export const ENTITY_EDIT_SUCCESS = createActionName('ENTITY_EDIT_SUCCESS');
export const ENTITY_USER_ADD_SUCCESS = createActionName('ENTITY_USER_ADD_SUCCESS');
export const ENTITY_USER_EDIT_SUCCESS = createActionName('ENTITY_USER_EDIT_SUCCESS');
export const GET_ENTITY_USERS_SUCCESS = createActionName('GET_ENTITY_USERS_SUCCESS');
export const DELETE_ENTITY_USERS_SUCCESS = createActionName('DELETE_ENTITY_USERS_SUCCESS');
export const DELETE_ENTITY_USERS_FAILURE = createActionName('DELETE_ENTITY_USERS_FAILURE');
export const DELETE_ENTITY_USERS_REQUEST = createActionName('DELETE_ENTITY_USERS_REQUEST');
export const ENTITY_SEARCH_SUCCESS = createActionName('ENTITY_SEARCH_SUCCESS');
export const GET_ENTITY_USERS_REQUEST = createActionName('GET_ENTITY_USERS_REQUEST');
export const USER_SEARCH_SUCCESS = createActionName('USER_SEARCH_SUCCESS');
export const FETCH_ROLES_SUCCESS = createActionName('FETCH_ROLES_SUCCESS');

export default function transaction(state = initialState, action) {
    let updatedEntity;
    let index;
    let entityList;

    switch (action.type) {
        case FETCHING_DATA:
            return {
                ...state,
                isFetching: action.status,
            };
        case SWITCH_LIST_SUCCESS:
            return {
                ...state,
                switchUsersList: action.data,
            };
        case ENTITY_LIST_SUCCESS:
            return {
                ...state,
                list: action.currentPage > 1 ? state.list.concat(action.data) : action.data,
                entityUsers: null,
                totalPages: action.totalPages,
                totalCount: action.totalCount,
            };
        case ENTITY_SEARCH_SUCCESS:
            return {
                ...state,
                list: action.currentPage > 1 ? state.list.concat(action.data) : action.data,
                entityUsers: null,
                totalPages: action.totalPages,
                totalCount: action.totalCount,
            };
        case ENTITY_FETCH_SUCCESS:
            return {
                ...state,
                entity: action.data,
                actorsAndRoles: action.data.actorsAndRoles,
                credpoolRoles: action.data.credpoolRoles,
                collectionRoles: action.data.collectionRoles,
            };
        case FETCH_ROLES_SUCCESS:
            return {
                ...state,
                creditActorsAndRoles: action.data.roles,
                creditActors: action.data.actors,
                credpoolRoles: action.data.credpoolRoles,
                collectionRoles: action.data.collectionRoles,
                userPlatformRoles: action.data.platformRoles,
                loanRoles: action.data.loanRoles,
                entity: {
                    ...state.entity,
                    loanActors: action.data.loanActors,
                    loanRoles: action.data.loanRoles,
                    collectionRoles: action.data.collectionRoles,
                    credpoolActors: action.data.credpoolActors,
                    userPlatformActors: action.data.platformActors,
                    collectionActors: action.data.collectionActors,
                },
            };
        case ENTITY_ADD_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.data],
                entity: action.data,
            };
        case ENTITY_EDIT_SUCCESS:
            // replace updated entity with current entity list
            updatedEntity = action.data;
            entityList = state.list;
            index = entityList.findIndex((e) => e.id === updatedEntity.id);

            entityList.splice(index, 1, updatedEntity);

            return {
                ...state,
                list: entityList,
                entity: updatedEntity,
            };
        case GET_ENTITY_USERS_SUCCESS: {
            return {
                ...state,
                entityUsers: action.currentPage > 1 && state.entityUsers ? [...state.entityUsers, ...action.data] : action.data,
                totalPages: action.totalPages,
                totalCount: action.totalCount,
            };
        }
        case USER_SEARCH_SUCCESS:
            return {
                ...state,
                entityUsers: action.currentPage > 1 ? state.entityUsers.concat(action.data) : action.data,
                totalPages: action.totalPages,
                totalCount: action.totalCount,
            };

        case ENTITY_USER_ADD_SUCCESS:
            return {
                ...state,
                entityUsers: [...state.entityUsers, action.data],
            };
        case ENTITY_USER_EDIT_SUCCESS:
            // replace updated entity with current entity list
            updatedEntity = action.data;
            entityList = state.entityUsers;
            index = entityList.findIndex((e) => e.caUserId === updatedEntity.caUserId);
            if (index < 0) {
                entityList = [...entityList, action.data];
            } else {
                const newEntity = [...entityList];
                newEntity.splice(index, 1, action.data);
                entityList = [...newEntity];
            }

            return {
                ...state,
                entityUsers: entityList,
            };
        case DELETE_ENTITY_USERS_REQUEST:
            return {
                ...state,
                deleting: true,
            };
        case DELETE_ENTITY_USERS_SUCCESS:
            entityList = state.entityUsers.filter((item) => {
                if (item.id !== action.res.id) return item;
            });
            return {
                ...state,
                entityUsers: entityList,
                deleting: false,
            };
        case DELETE_ENTITY_USERS_FAILURE:
            return {
                ...state,
                deleting: false,
            };
        default:
            return state;
    }
}

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function fetchingEntityData() {
    return {
        type: GET_ENTITY_USERS_REQUEST,
    };
}

export const actorRoleNormalizer = (data, group) => {
    const camelizedData = humps.camelizeKeys(data);
    const result = camelizedData.map((m) => {
        const grp = Object.keys(m)[0];
        const sourceGroup = group ? Object.keys(group) : [];
        const sourceValues = group ? Object.values(group) : [];
        let selectedRoles = '';
        const roleObj = {};
        m[grp].forEach((n) => {
            const modifiedKey = n.toLowerCase();
            const modifiedGroup = grp;

            roleObj[modifiedKey] =
                sourceGroup.includes(modifiedGroup) && (!!_size(sourceValues) ? [_get(group, modifiedGroup)] : sourceValues).includes(modifiedKey);
            if (sourceGroup.includes(modifiedGroup) && (!!_size(sourceValues) ? [_get(group, modifiedGroup)] : sourceValues).includes(modifiedKey)) {
                selectedRoles = modifiedKey;
            }
        });
        if (!selectedRoles) selectedRoles = 'admin';
        return {
            group: { [grp]: sourceGroup.includes(grp) },
            roles: roleObj,
            selectedRoles,
        };
    });
    return result;
};

export const credPoolRoleNormalizer = (data, group) => {
    const camelizedData = humps.camelizeKeys(data);
    return camelizedData.map((m) => {
        const grp = Object.keys(m)[0];
        const sourceGroup = group ? Object.keys(group) : [];
        let selectedRoles = '';
        const roleObj = {};
        m[grp].forEach((n) => {
            const sourceValues = group ? [_get(group, grp)] : [];
            roleObj[n] = sourceGroup.includes(grp) && sourceValues.includes(n);
            if (sourceGroup.includes(grp) && sourceValues.includes(n)) {
                selectedRoles = n;
            }
        });
        if (!selectedRoles) selectedRoles = 'admin';
        return {
            group: { [grp]: sourceGroup.includes(grp) },
            roles: roleObj,
            selectedRoles,
        };
    });
};

const actorRoleDenormalizer = (data, key = 'actorRoles') => {
    const groups = {};
    data?.[key]?.forEach((m, i) => {
        const a = Object.keys(m.group)[0];
        if (m.group[a] && m.selectedRoles.length > 0) {
            groups[a] = m.selectedRoles;
        }
    });
    return groups;
};

/** FIXME: All users are of admin role by default */
const mapEntityUser = (data) => {
    const d = {
        ...data,
        groups: actorRoleDenormalizer(data),
        creditRoles: actorRoleDenormalizer(data, 'creditRoles'),
        notification_channels:
            (data.notificationChannelsVal && Object.keys(data.notificationChannelsVal).filter((c) => data.notificationChannelsVal[c])) || [],
        credpoolRoles: actorRoleDenormalizer(data, 'credpoolRoles'),
        loanRoles: actorRoleDenormalizer(data, 'loanRoles'),
        userPlatformRoles: actorRoleDenormalizer(data, 'userPlatformRoles'),
        collectionRoles: actorRoleDenormalizer(data, 'collectionRoles'),
    };
    if (!_get(d, 'notification_channels', []).includes(NOTIFICATION_CHANNELS.EMAIL)) {
        d.notification_channels = _get(d, 'notification_channels', []).filter((n) => n !== NOTIFICATION_CHANNELS.TRANSACTION_RELATED);
    } else {
        if (!_get(d, 'notification_channels', []).includes(NOTIFICATION_CHANNELS.TRANSACTION_RELATED)) {
            d.notification_channels.push(NOTIFICATION_CHANNELS.TRANSACTION_RELATED);
        }
    }
    delete d.actorRoles;
    delete d.notificationChannelsVal;
    return {
        user: humps.decamelizeKeys(d),
    };
};

const unMapEntityUser = (data) => humps.camelizeKeys(data);

const unMapEntities = (entities) =>
    entities.map((e) => {
        const { CIN, actors } = e;
        const d = humps.camelizeKeys(omit(e, ['CIN', 'actors']));
        return { ...d, CIN, actors };
    });

const mapEntity = (entity) => {
    const dcEntity = humps.decamelizeKeys(omit(entity, ['logo', 'CIN']));
    const { logo = null, CIN } = entity;
    let formData = {
        ...dcEntity,
    };
    if (_get(dcEntity, 'address.line1')) {
        formData = {
            ...formData,
            address: {
                ...dcEntity.address,
                line_1: dcEntity.address.line1,
            },
        };
    }
    return logo ? objectToFormData({ entity: { ...formData, logo, CIN } }) : objectToFormData({ entity: { ...formData, CIN } });
};

const unMapEntity = (entity) => {
    const d = humps.camelizeKeys(omit(entity, ['logo', 'CIN', 'actors']));
    const { CIN, actors, credpool_roles } = entity;
    return {
        ...d,
        CIN,
        createdAt: moment(d.createdAt).format('DD MMM, YYYY'),
        actors: actors,
        credpoolActors: credpool_roles,
        actorsAndRoles: humps.camelizeKeys(actorRoleNormalizer(actors)),
    };
};

export function addEntity(entity) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/entities`, mapEntity(entity))
            .then((d) => {
                dispatch(fetchingData(false));
                toastr.success('Success!', 'Entity added successfully');
                return dispatch({ type: ENTITY_ADD_SUCCESS, data: unMapEntity(d) });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding entity', m);
                });
            });
    };
}

export function editEntity(entity) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .putFormData(`${process.env.REACT_APP_MP_API_HOST}/entities/${entity.id}`, mapEntity(entity))
            .then((d) => {
                dispatch(fetchingData(false));
                toastr.success('Success!', 'Entity updated successfully');
                return dispatch({ type: ENTITY_EDIT_SUCCESS, data: unMapEntity(d) });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding entity', m);
                });
            });
    };
}

export function searchUsers(entityId, user) {
    const userObj = { ...user };
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/search?email=${user.email}`)
            .then((d) => {
                if (d.users.length > 0) {
                    userObj.id = d.users[0].caUserId;
                    dispatch(editEntityUser(entityId, userObj));
                } else {
                    dispatch(addEntityUser(entityId, userObj));
                }
            })
            .catch((ex) => {
                console.error(ex);
                toastr.error('Error fetching user information', '');
            });
}

export const fetchEntityUser = (entityId, id) => (dispatch) => {
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users/${id}?product_id=${PRODUCT_ID}`)
        .then((r) => humps.camelizeKeys(r))
        .catch((ex) => {
            dispatch(fetchingData(false));
            fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                toastr.error('Error fetching user', m);
            });
            return {};
        });
};

export function addEntityUser(entityId, user) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .postJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users?product_id=${PRODUCT_ID}`, mapEntityUser(user))
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch(getAllUserEntities());
                toastr.success('Success!', 'User is added successfully');
                return dispatch({ type: ENTITY_USER_ADD_SUCCESS, data: unMapEntityUser(d) });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding user', m);
                });
            });
    };
}

export function editEntityUser(entityId, user) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .patchJSON(
                `${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users/${user.caUserId}?product_id=${PRODUCT_ID}`,
                mapEntityUser(user),
            )
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch(getAllUserEntities());
                toastr.success('Success!', 'User is updated successfully');
                return dispatch({ type: ENTITY_USER_EDIT_SUCCESS, data: unMapEntityUser(d) });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding user', m);
                });
            });
    };
}

export function manageEmailNotifications(entityId, userId, userData, callBack) {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${entityId}/users/${userId}`, humps.decamelizeKeys(userData))
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch(getAllUserEntities(callBack));
                toastr.success('Success!', 'Email notifications updated successfully');
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating Email notifications', m);
                });
            });
    };
}

export function deleteEntityUser(entityId, userId) {
    return (dispatch) => {
        dispatch({ type: DELETE_ENTITY_USERS_REQUEST });
        fetchUtils
            .deleteJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users/${userId}?product_id=${PRODUCT_ID}`)
            .then((res) => {
                toastr.success('Success!', 'User deleted successfully');
                return dispatch({ type: DELETE_ENTITY_USERS_SUCCESS, res });
            })
            .catch((ex) => {
                dispatch({ type: DELETE_ENTITY_USERS_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Deleting user', m);
                });
            });
    };
}

export function loadEntities(page = 1, search = '') {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities?page=${page}${search ? `&search=${search}` : ''}`)
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch({
                    type: ENTITY_LIST_SUCCESS,
                    data: unMapEntities(d.entities),
                    totalPages: d.total_pages,
                    totalCount: d.total_count,
                    currentPage: page,
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error loading entities', m);
                });
            });
    };
}

const getActorRoles = (id, data, entity) => {
    const groupRoles =
        _get(
            (data || []).find((g) => _get(g, 'code') === id),
            'groups',
        ) || {};
    const actors = Object.keys(groupRoles) || [];
    const entityActors = [];
    entity.forEach((actor) => {
        entityActors.push(_get(Object.keys(actor) || [], '[0]'));
    });
    const actorRoles = actors.filter((k) => entityActors.includes(k.toLowerCase())).map((k) => ({ [k]: groupRoles[k] }));
    return actorRoles;
};

const getPlatformActors = (data) => {
    let platformActors = [];
    const { group } = Auth.getActiveUserGroup();
    if (USER_MANAGEMENT_ACCESS_ENABLED_GROUPS.includes(group)) {
        const groupRoles =
            _get(
                (data || []).find((g) => _get(g, 'code') === PRODUCT_ID_PLATFORMS),
                'groups',
            ) || {};
        const actors = Object.keys(groupRoles) || [];
        platformActors = actors.filter((k) => !USER_MANAGEMENT_ACCESS_DISABLED_ACTORS.includes(k.toLowerCase())).map((k) => ({ [k]: groupRoles[k] }));
    }
    return platformActors;
};

export function fetchRoles(entity) {
    const productId = getActiveEntityId() === process.env.REACT_APP_CA_ENTITY_ID ? PRODUCT_ID_PLATFORMS : PRODUCT_ID;
    return (dispatch) =>
        fetchUtils
            .getJSON(
                `${process.env.REACT_APP_AUTH_API_HOST}/platforms/groups_and_roles_for_entity?entity_id=${_get(
                    entity,
                    'data.id',
                )}&product_id=${productId}`,
            )
            .then((d) => {
                return dispatch({
                    type: FETCH_ROLES_SUCCESS,
                    data: {
                        roles: humps
                            .camelizeKeys(actorRoleNormalizer(getActorRoles(PRODUCT_ID_CREDIT, d, _get(entity, 'data.actors', []))))
                            .filter((role) => CRA_RBAC_USERS.includes(Object.keys(_get(role, 'group', {}))[0])),
                        actors: getActorRoles(PRODUCT_ID_CREDIT, d, _get(entity, 'data.actors', [])).filter((role) =>
                            CRA_RBAC_USERS.includes((Object.keys(role || {})[0] || '').toLowerCase()),
                        ),
                        credpoolActors: getActorRoles(PRODUCT_ID, d, _get(entity, 'data.actors', [])).filter((role) =>
                            RBAC_USERS.includes((Object.keys(role || {})[0] || '').toLowerCase()),
                        ),
                        credpoolRoles: humps
                            .camelizeKeys(actorRoleNormalizer(getActorRoles(PRODUCT_ID, d, _get(entity, 'data.actors', []))))
                            .filter((role) => RBAC_USERS.includes(Object.keys(_get(role, 'group', {}))[0])),
                        loanRoles: humps
                            .camelizeKeys(actorRoleNormalizer(getActorRoles(PRODUCT_ID_LOANS, d, _get(entity, 'data.actors', []))))
                            .filter((role) => RBAC_USERS.includes(Object.keys(_get(role, 'group', {}))[0])),

                        loanActors: getActorRoles(PRODUCT_ID_LOANS, d, _get(entity, 'data.actors', [])).filter((role) =>
                            RBAC_USERS.includes((Object.keys(role || {})[0] || '').toLowerCase()),
                        ),
                        collectionRoles: humps
                            .camelizeKeys(actorRoleNormalizer(getActorRoles(PRODUCT_ID_COLLECTIONS, d, _get(entity, 'data.actors', []))))
                            .filter((role) => {
                                const mGroup = _get(role, 'group', {});
                                const groupKey = Object.keys(mGroup)[0];
                                const groupKeyDeCamelized = humps.decamelize(Object.keys(mGroup)[0]);
                                return RBAC_USERS.includes(groupKey) || RBAC_USERS.includes(groupKeyDeCamelized);
                            }),

                        collectionActors: getActorRoles(PRODUCT_ID_COLLECTIONS, d, _get(entity, 'data.actors', [])).filter((role) => {
                            const groupKey = (Object.keys(role || {})[0] || '').toLowerCase();
                            return RBAC_USERS.includes(groupKey);
                        }),
                        platformRoles: actorRoleNormalizer(getPlatformActors(humps.camelizeKeys(d))),
                        platformActors: getPlatformActors(d),
                    },
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching entity', m);
                });
            });
}

export function fetchEntity(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities/${id}`)
            .then((d) => dispatch({ type: ENTITY_FETCH_SUCCESS, data: unMapEntity(d) }))
            .then((d) => dispatch(fetchRoles(d)))
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching entity', m);
                });
            });
}

export function getEntityUsers(id, page = 1, search = '') {
    return (dispatch) => {
        dispatch(fetchingData());
        dispatch(fetchingEntityData());
        return fetchUtils
            .getJSON(
                `${process.env.REACT_APP_AUTH_API_HOST}/entities/${id}/users?page=${page}${
                    search ? `&search=${search}` : ''
                }&product_id=${PRODUCT_ID}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch({
                    type: GET_ENTITY_USERS_SUCCESS,
                    data: unMapEntities(d.users),
                    totalPages: d.pages,
                    totalCount: d.count,
                    currentPage: page,
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching entity', m);
                });
            });
    };
}

export function searchEntity(page = 1, search = '') {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/entities?page=${page}${search ? `&search=${search}` : ''}`)
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch({
                    type: ENTITY_SEARCH_SUCCESS,
                    data: unMapEntities(d.entities),
                    totalPages: d.total_pages,
                    totalCount: d.total_count,
                    currentPage: page,
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error loading entities', m);
                });
            });
    };
}

function mapSwitchUsersList(data) {
    if (data && (!data.allEntities || data.allEntities.length < 1 || !Array.isArray(data.allEntities))) return [];
    const result = data.allEntities.map((m) =>
        Object.keys(m.groups).map((n) => ({
            name: m.companyName,
            entity_id: m.entityId,
            group: n,
            role: m.groups[n],
            credpoolRole: m.credpoolRoles[n],
            collectionRole: m.collectionRoles?.[n],
        })),
    );
    return flattenDeep(result);
}

export function getAllUserEntities(callBack) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${getActiveEntityId()}/users/${getActiveUserId()}?product_id=${PRODUCT_ID}`)
            .then((d) => mapSwitchUsersList(humps.camelizeKeys(d)))
            .then((d) => {
                const activeGroup = Auth.getActiveUserGroup();
                const activeEntity = d.find((entity) => {
                    if (activeGroup) {
                        return entity.entity_id === activeGroup.entity_id && entity.group === humps.camelize(activeGroup.group);
                    }
                    return {};
                });
                Auth.setActiveUserGroup(activeEntity);
                dispatch({ type: SWITCH_LIST_SUCCESS, data: d });
                if (typeof callBack === 'function') callBack();
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Retrieving Users', m);
                    if (typeof callBack === 'function') callBack();
                });
            });
}
export function searchUser(id, page = 1, search = '') {
    return (dispatch) => {
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(
                `${process.env.REACT_APP_AUTH_API_HOST}/entities/${id}/users?page=${page}${
                    search ? `&search=${search}` : ''
                }&product_id=${PRODUCT_ID}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                dispatch({
                    type: USER_SEARCH_SUCCESS,
                    data: unMapEntities(d.users),
                    totalPages: d.pages,
                    totalCount: d.count,
                    currentPage: page,
                });
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error loading users', m);
                });
            });
    };
}

const getUser = (entityId, id) => {
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users/${id}?product_id=${PRODUCT_ID}`)
        .then((d) => humps.camelizeKeys(d))
        .catch(() => {
            toastr.error('Sorry!', 'Error fetching permissions!');
        });
};

export function fetchUser(entityId, id) {
    return fetchUtils.getJSON(`${process.env.REACT_APP_AUTH_API_HOST}/entities/${entityId}/users/${id}?product_id=${PRODUCT_ID}`).then((r) => {
        const profile = humps.camelizeKeys(r);
        setProfile({ ...profile, id: profile.caUserId });
        return profile;
    });
}

const getPermissions = (entityId, id) => {
    const { group } = Auth.getActiveUserGroup() || {};
    return fetchUtils
        .getJSON(
            `${process.env.REACT_APP_AUTH_API_HOST}/users/${id}/all_permissions_web?product_id=${PRODUCT_ID}&entity_id=${entityId}&group_id=${group}`,
        )
        .then((d) => humps.camelizeKeys(d))
        .catch(() => {
            toastr.error('Sorry!', 'Error fetching user!');
        });
};

const getCRAPermissions = (entityId, id, group) => {
    return fetchUtils
        .getJSON(
            `${process.env.REACT_APP_AUTH_API_HOST}/users/${id}/all_permissions_web?product_id=${PRODUCT_ID_CREDIT}&entity_id=${entityId}&group_id=${group}`,
        )
        .then((d) => humps.camelizeKeys(d))
        .catch(() => {
            toastr.error('Sorry!', 'Error fetching user!');
        });
};

export function fetchUserWithpermissions(entityId, id) {
    const { isInvestor, isProduct, isClient } = getActiveUser();
    const { group } = Auth.getActiveUserGroup() || {};

    const canFetchCRA = (isInvestor || isProduct || isClient) && group;

    return Promise.all([getUser(entityId, id), getPermissions(entityId, id), ...(canFetchCRA ? [getCRAPermissions(entityId, id, group)] : [])]).then(
        (d) => {
            const profile = {
                ..._get(d, '[0]', {}),
                permissions: _get(d, '[1].permissions', {}),
                craPermissions: canFetchCRA ? _get(d, '[2].permissions', {}) : {},
            };
            setProfile({ ...profile, id: profile.caUserId });
            return profile;
        },
    );
}

export function resendActivationLink(id, callback) {
    return (dispatch) => {
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/${id}/resend_activation_link?product_id=${PRODUCT_ID}`)
            .then((d) => {
                toastr.success('Success!', 'Reactivation mail sent successfully');
                if (typeof callback === 'function') callback();
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error sending re-activation mail', m);
                    if (typeof callback === 'function') callback();
                });
            });
    };
}

export function sendOtp(id, phone_number, callback) {
    return (dispatch) => {
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/${id}/soft_validate_phone_number?product_id=CRDPL`, {
                phone_number,
            })
            .then((d) => {
                const camelizedData = humps.camelizeKeys(d);
                toastr.success('', 'OTP sent successfully');
                if (typeof callback === 'function') callback(true, camelizedData);
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error', m);
                    if (typeof callback === 'function') callback(false);
                });
            });
    };
}

export function verifyOtp(id, phone_number, otp, mobile_uuid, callback) {
    return (dispatch) => {
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_AUTH_API_HOST}/users/${id}/soft_verify_mobile_otp?product_id=CRDPL`, {
                phone_number,
                otp,
                mobile_uuid,
            })
            .then((d) => {
                d?.success && toastr.success('', 'Phone number verified successfully');
                if (typeof callback === 'function') callback(d);
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error verifying OTP', m);
                    if (typeof callback === 'function') callback(m);
                });
            });
    };
}
