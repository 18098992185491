import styled from 'styled-components';
import onboardimg from '../../assets/images/invonboard-image.jpg';
import '../../assets/css/nucleo-icons.css';
import { getColor } from 'app/products/UIComponents/Utils/colorSets';

export const Container = styled.div`
    position: relative;
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
    min-height: 100%;
`;
export const Body = styled.div`
    background: transparent;
    min-height: 100%;
    .invonboard-layout {
        display: flex;
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
`;
export const InvOnboardFormInner = styled.div`
    background: #fff;
    width: 50%;
    padding-top: 149px;
    padding-left: 117px;
    @media (max-width: 767px) {
        padding-top: 20px;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding-top: 30px;
    }
    h1 {
        padding-left: 10px;
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }
    p {
        padding-left: 10px;
    }
    .signup_btn {
        position: absolute;
        top: 35px;
        right: 50px;
        font-size: 0.8125rem;
        font-weight: 600;
        color: ${getColor('grey', '900')};
        background: none;
        border: 1px solid ${getColor('grey', '500')};
        border-radius: 8px;
        padding: 8px 24px;
        @media (max-width: 992px) {
            top: 15px;
            right: 10px;
            font-size: 11px;
        }
        @media (min-width: 768px) and (max-width: 992px) {
            top: 25px;
            right: 15px;
        }
    }
    > form {
        width: 375px;
        margin-top: 28px;
        @media (max-width: 767px) {
            margin-top: 10px;
            width: 100%;
        }
        @media (min-width: 768px) and (max-width: 992px) {
            width: 600px;
        }
        input[type='text'],
        input[type='number'] {
            border: 1px solid #d0ced9;
            border-radius: 8px !important;
            @media (max-width: 767px) {
                height: 34px;
            }
        }
        .error {
            .Select-control {
                border-color: #c80029 !important;
            }
        }
        .Select-control {
            border-color: #d0cdd9 !important;
            border-radius: 8px !important;
            @media (max-width: 767px) {
                height: 34px !important;
            }
            .Select-placeholder {
                @media (max-width: 767px) {
                    line-height: 34px !important;
                }
            }
        }
        .span3 {
            &.phone-code {
                padding-right: 0px;
                @media (max-width: 767px) {
                    width: 100%;
                    padding-right: 10px;
                }
            }
        }
        .form-group {
            width: 312px;
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
        .form-group-code {
            margin-bottom: 20px;
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
        .form-group-phone {
            width: 219px;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
    }
    [class^='span'] {
        > .form-group {
            &.error {
                .error-text {
                    text-align: left;
                    margin-top: 4px;
                }
            }
        }
        > h1 {
            font-size: 28px;
            font-style: italic;
            line-height: 16px;
            text-transform: uppercase;
            font-weight: 800;
            color: #434966;
        }
        > p {
            color: rgba(67, 73, 102, 0.71);
            position: relative;
            padding-bottom: 0;
            margin-bottom: 9px;
            font-size: 14px;
            line-height: 24px;
            &:before {
                content: '';
                position: absolute;
                width: 95px;
                height: 4px;
                background: #00c7cc;
                bottom: -8px;
                left: 100px;
            }
        }
    }
`;
export const OnBoardForm = styled.form`
    width: 375px;
    margin-top: 28px;
    .span3 {
        padding-right: 0px;
    }
`;
export const FloatFormGroup = styled.div`
    input,
    select {
        border-radius: 3px !important;
        height: 52px;
        padding: 13px 15px 0px !important;
        font-size: 15px !important;
        color: #293446;
        font-weight: 500;
        border-color: #cfceda !important;
        &:focus {
            + label {
                top: 5px;
                font-size: 11px;
                background: none;
                left: 15px;
            }
        }
        &:not([value='']):valid ~ label {
            top: 0px;
            font-size: 11px;
            background: none;
        }
    }
    label {
        position: absolute;
        color: rgba(41, 52, 70, 0.6);
        font-size: 14px;
        left: 15px;
        top: 17px;
        text-transform: uppercase;
        pointer-events: none;
    }
    i {
        position: absolute;
        right: 7px;
        color: rgba(0, 0, 0, 0.62);
        font-size: 18px;
        top: 17px;
    }
`;
export const BtnBlock = styled.button`
    width: 100%;
    background: #1e76d0;
    margin-top: 1.5em;
    border-radius: 3px;
    color: #fff;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &.red-colored-btn {
        width: 312px;
        padding: 8px 0;
        background: ${getColor('primary', '500')};
        text-transform: capitalize;
        font-weight: 500;
        font-size: 16px;
        border-radius: 8px;
        @media (max-width: 767px) {
            height: 35px;
            max-width: 130px;
            width: 130px;
            margin: 20px auto 20px;
            font-size: 14px;
        }
        @media (min-width: 768px) and (max-width: 992px) {
            height: 40px;
            max-width: 150px;
            width: 150px;
            margin: 20px auto 30px;
            font-size: 14px;
        }
    }
`;

export const InvOnboardSuccessWrap = styled.div`
    background: #fff;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
`;
export const InvOnboardSuccessMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
    text-align: center;
    span {
        i {
            color: #2ba563;
            font-size: 47px;
        }
    }
    h1 {
        color: #434966;
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 800;
        font-style: italic;
        margin-top: 18px;
    }
    p {
        font-size: 14px;
        color: rgba(41, 43, 53, 0.76);
    }
`;

export const InvOnboardBannerInner = styled.div`
    padding: 20px 50px;
    padding-left: 103px;
    padding-right: 117px;
    background: #F2F4F7;
    width: 50%;
    /* background: url(${onboardimg}) -480px -410px no-repeat; */
    @media (max-width: 992px) {
        width: 100%;
        padding:10px;
    }
    @media (min-width: 768px) and (max-width: 992px) {
        padding:15px 20px;
    }
    @media (min-width: 992px) and (max-width: 1250px) {
        padding: 20px 25px;
    }
    @media (min-width: 1251px) and (max-width: 1350px) {
        padding:20px 30px;
    }
`;
export const Logo = styled.div`
    position: relative;
    float: left;
    margin: 20px 0;
    @media (max-width: 767px) {
        margin: 10px 0;
    }
    a {
        display: block;
        figure {
            max-width: 250px;
            @media (max-width: 992px) {
                max-width: 140px;
            }
            @media (min-width: 993px) and (max-width: 1250px) {
                max-width: 170px;
            }
            img {
                backface-visibility: initial;
            }
        }
    }
`;
export const WelcomeNote = styled.div`
    padding: 112px 0;
    @media (max-width: 992px) {
        padding: 60px 10px 0px;
    }
    @media (min-width: 767px) and (max-width: 992px) {
        padding: 75px 0px 0px;
    }
    @media (min-width: 993px) and (max-width: 1250px) {
        padding: 90px 20px;
    }
    > h1 {
        font-size: 24px;
        color: ${getColor('grey', '900')};
        line-height: 17px;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
        }
        @media (min-width: 767px) and (max-width: 992px) {
            font-size: 20px;
        }
        @media (min-width: 993px) and (max-width: 1250px) {
            font-size: 20px;
        }
    }
    > p {
        font-size: 16px;
        color: ${getColor('grey', '500')};
        line-height: 22px;
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 20px;
            max-width: 500px;
        }
        @media (min-width: 767px) and (max-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 993px) and (max-width: 1250px) {
            font-size: 14px;
        }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        > h1 {
            line-height: 26px;
        }
    }
    @media (min-width: 993px) and (max-width: 1250px) {
        > h1 {
            line-height: 26px;
        }
    }
`;
export const FirmNumbers = styled.ul`
    display: inline-flex;
    padding: 10px 10px 10px 0;
    margin-top: 32px;
    max-width: 100%;
    @media (max-width: 520px) {
        flex-direction: column;
        width: 100%;
    }
    .left-space {
        padding-left: 32px;
    }
    li {
        min-width: 145px;
        padding-left: 15px;
        border-right: solid 1px ${getColor('grey', '900')};
        @media (max-width: 520px) {
            min-width: 100px;
            padding: 5px;
            border-right: none;
        }
        &:last-child {
            border-right: none;
        }
        span {
            color: ${getColor('grey', '900')};
            font-size: 18px;
            font-style: italic;
            font-weight: bold;
            @media (max-width: 767px) {
                font-size: 18px;
            }
            @media (min-width: 767px) and (max-width: 992px) {
                font-size: 20px;
            }
            @media (min-width: 993px) and (max-width: 1250px) {
                font-size: 20px;
            }
            @media (min-width: 993px) and (max-width: 1080px) {
                font-size: 16px !important;
            }
            @media (min-width: 1251px) and (max-width: 1350px) {
                font-size: 20px;
            }
            i {
                font-size: 20px;
                vertical-align: middle;
                @media (min-width: 993px) and (max-width: 1080px) {
                    font-size: 16px;
                }
            }
        }
        p {
            font-size: 14px;
            color: ${getColor('grey', '500')};
            padding-bottom: 0;
            @media (max-width: 767px) {
                font-size: 13px;
            }
            @media (min-width: 767px) and (max-width: 992px) {
                font-size: 14px;
            }
            @media (min-width: 993px) and (max-width: 1250px) {
                font-size: 14px;
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        li {
            min-width: 130px;
            padding: 5px;
            span {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
    }
`;
export const FirmFeatures = styled.div`
    margin-top: 50px;
    @media (max-width: 767px) {
        margin-top: 20px;
    }
    @media (min-width: 767px) and (max-width: 992px) {
        margin-top: 40px;
    }
    @media (min-width: 993px) and (max-width: 1199px) {
        margin-top: 60px;
    }
    h1 {
        position: relative;
        font-size: 22px;
        line-height: 26px;
        color: ${getColor('grey', '900')};
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
        }
        @media (min-width: 767px) and (max-width: 992px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
`;

export const FirmFeaturesList = styled.ul`
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        margin-top: 25px;
        padding-left: 10px;
    }
    li {
        display: inline-flex;
        align-items: center;
        margin-bottom: 25px;
        .features-summary {
            margin-left: 10px;
            span {
                font-size: 14px;
                line-height: 26px;
                color: ${getColor('grey', '900')};
                @media (max-width: 767px) {
                    font-size: 13px;
                    line-height: 16px;
                    display: block;
                }
            }
            p {
                font-size: 12px;
                line-height: 16px;
                padding-bottom: 0;
                color: ${getColor('grey', '500')};
            }
        }
    }
`;
export const DescriptiveIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
    border: solid 1px #667085;
    background: #667085;
    border-radius: 100%;
    text-align: center;
    i {
        color: #fff;
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
`;
