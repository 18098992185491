import _isEmpty from 'lodash/isEmpty';
import _flattenDeep from 'lodash/flattenDeep';

const serializeQuery = (params: any, prefix = ''): string => {
    const query = Object.keys(params).map((key: any) => {
        let keyParam = key;
        const value = params[key];
        if (params.constructor === Array) {
            keyParam = `${prefix}[]`;
        } else if (params.constructor === Object) {
            keyParam = prefix ? `${prefix}[${keyParam}]` : keyParam;
        }
        if (typeof value === 'object') {
            return serializeQuery(value, keyParam);
        }
        return `${keyParam}=${encodeURIComponent(value)}`;
    });

    return [...query].join('&');
};

/*
Example Usage:

const newParam = {
    page: 1,
    items_per_page: 20,
    actor: "product",
    tab: "live",
    a: {x: 9, y: 10},
    b: [1, 2, 3],
    filter_params: [{ field: "_type" }, { value: 'Sec'}]
}

serializeQuery(params)

Output: 

page=1&items_per_page=20&actor=product&tab=live&a[x]=9&a[y]=10&b[]=1&b[]=2&b[]=3&filter_params[][field]=_type&filter_params[][value]=Securitization

*/

/*
QUERY constructor
FORMAT:
    const data = [{ field: 'demo', operator: 'in', value: 'demo_val' }];
    */
export const constructQuery = (queryData: any) => {
    const processedQuery: any = [];
    const params = ['filter_params[][field]', 'filter_params[][value][]', 'filter_params[][operator]'];

    function query(input: any) {
        const output: any = Object.keys(input).map((m) => {
            const a = `${params[0]}=${input.field}`;
            const b = `${params[2]}=${input.operator}`;
            const c = `${params[1]}=${input.value}`;
            return [a, b, c];
        });
        const flatten: any = new Set(_flattenDeep(output));
        return [...flatten].join('&');
    }
    queryData.forEach((ele: any, index: number) => {
        if (!_isEmpty(queryData)) {
            processedQuery.push(query(ele));
        }
    });
    return processedQuery.join('&');
};

export default serializeQuery;
