import useSwr from 'swr';
import { YUBI_TEST_DOMAIN } from 'app/constants/Constants';

const useYubiDomainResolver = ({ canFetch = true }) => {
    const { data, error } = useSwr(YUBI_TEST_DOMAIN, canFetch ? fetch : null, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    return {
        status: data?.status,
    };
};

export default useYubiDomainResolver;
