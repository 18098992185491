import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import * as fetchUtils from 'app/utils/FetchUtils';
import * as RouterUtils from 'app/utils/RouterUtils';
import { validateObj } from 'app/utils/CommonUtils';
import uniqBy from 'lodash/uniqBy';
import { ADMIN_TRANS_DA, ADMIN_TRANS_DA_RSUPLOAD } from 'app/actors/admin/constants/Routes';
import { createLoadingSelector } from 'app/reducers/loading';
import { addDownloadDetails } from 'app/utils/TransactionUtils.tsx';
import { PRODUCT_KEYS, TRANSACTION_STATE } from 'app/constants/Constants';
import { PRODUCT_RELEASE_CLICK } from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';

const initialState = {
    mcdFile: {},
    rsFile: {},
    actualHeaders: [],
    expectedHeaders: {},
    poolFiles: [],
    details: {},
    poolSummary: {},
    clientPref: {},
    isFetching: false,
    errMessage: '',
    skipExtractHeaders: false,
    shouldSkipPoolSummary: false,
    progress: false,
    structuring: false,
    isGenLoanFilesLoading: false,
    isFileHeadersLoading: false,
};

// actions
export const createActionName = (name) => `app/da/transaction/details/${name}`;

export const TRANSACTION_GET_DA = createActionName('TRANSACTION_GET_DA');
export const TRANS_DA_POOL_FILE_HEADERS_REQUEST = createActionName('TRANS_DA_POOL_FILE_HEADERS_REQUEST');
export const TRANS_DA_POOL_FILE_HEADERS_SUCCESS = createActionName('TRANS_DA_POOL_FILE_HEADERS_SUCCESS');
export const TRANS_DA_POOL_FILE_DESTROY = createActionName('TRANS_DA_POOL_FILE_DESTROY');
export const TRANS_DA_POOL_FILE_HEADERS_ERROR = createActionName('TRANS_DA_POOL_FILE_HEADERS_ERROR');
export const TRANS_DA_POOL_FILE_UPLOAD = createActionName('TRANS_DA_POOL_FILE_UPLOAD');
export const TRANS_DA_CREATE_REQUEST = createActionName('TRANS_DA_CREATE_REQUEST');
export const TRANS_DA_CREATE_SUCCESS = createActionName('TRANS_DA_CREATE_SUCCESS');
export const TRANS_DA_POOL_FILE_422_ERROR = createActionName('TRANS_DA_POOL_FILE_422_ERROR');
export const TRANS_DA_CREATE_ERROR = createActionName('TRANS_DA_CREATE_ERROR');
export const WATERFALL_SUMMARY_REQUEST = createActionName('WATERFALL_SUMMARY_REQUEST');
export const WATERFALL_SUMMARY_TIMEOUT = createActionName('WATERFALL_SUMMARY_TIMEOUT');
export const GEN_CASHFLOW_REQUEST = createActionName('GEN_CASHFLOW_REQUESTdeta');
export const GEN_LOAN_FILES_REQUEST = createActionName('GEN_LOAN_FILES_REQUEST');
export const GEN_LOAN_FILES_SUCCESS = createActionName('GEN_LOAN_FILES_SUCCESS');
export const DA_GET_EXEC_DOCS_SUCCESS = createActionName('DA_GET_EXEC_DOCS_SUCCESS');

export const ADHOC_CREATE_REQUEST = createActionName('ADHOC_CREATE_REQUEST');
export const ADHOC_CREATE_SUCCESS = createActionName('ADHOC_CREATE_SUCCESS');
export const ADHOC_CREATE_FAILURE = createActionName('ADHOC_CREATE_FAILURE');

export const ADHOC_UPDATE_REQUEST = createActionName('ADHOC_UPDATE_REQUEST');
export const ADHOC_UPDATE_SUCCESS = createActionName('ADHOC_UPDATE_SUCCESS');
export const ADHOC_UPDATE_FAILURE = createActionName('ADHOC_UPDATE_FAILURE');

export const PLACEHOLDER_REMOVE_REQUEST = createActionName('PLACEHOLDER_REMOVE_REQUEST');
export const PLACEHOLDER_REMOVE_SUCCESS = createActionName('PLACEHOLDER_REMOVE_SUCCESS');
export const PLACEHOLDER_REMOVE_FAILURE = createActionName('PLACEHOLDER_REMOVE_FAILURE');

export const FUNDING_STATUS_REQUEST = createActionName('FUNDING_STATUS_REQUEST');
export const FUNDING_STATUS_SUCCESS = createActionName('FUNDING_STATUS_SUCCESS');
export const FUNDING_STATUS_FAILURE = createActionName('FUNDING_STATUS_FAILURE');

export default function details(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_GET_DA: {
            const files = action.data.poolFiles.map((f) => {
                if (f.type === 'mcd_file') {
                    return {
                        id: f.fileId,
                        document_type: 'mcd_file',
                        file_name: f.fileName,
                    };
                }
                if (f.type === 'rs_file') {
                    return {
                        id: f.fileId,
                        document_type: 'rs_file',
                        file_name: f.fileName,
                    };
                }
                return {
                    id: f.fileId,
                    document_type: f.displayName,
                    file_name: f.fileName,
                };
            });
            const mcd = action.data.poolFiles.filter((f) => f.type === 'mcd_file').reduce((r) => r);
            const rs = action.data.poolFiles.filter((f) => f.type === 'rs_file').reduce((r) => r);
            return {
                ...state,
                ...action.data,
                mcdFile: { file_name: mcd.fileName, id: mcd.fileId },
                rsFile: { file_name: rs.fileName, id: rs.fileId },
                poolFiles: files,
                errMessage: '',
                documents: addDownloadDetails(_get(action, 'data')),
            };
        }
        case TRANS_DA_POOL_FILE_HEADERS_REQUEST:
            return {
                ...state,
                errMessage: '',
                isFileHeadersLoading: true,
                // progress: true,
            };
        case TRANS_DA_POOL_FILE_HEADERS_SUCCESS: {
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: '',
                poolFiles: state.poolFiles.map((f) => (action.data && action.fileId && f.id === action.fileId ? { ...f, ...action.data } : { ...f })),
            };
        }
        case TRANS_DA_POOL_FILE_HEADERS_ERROR:
            return {
                ...state,
                errMessage: action.errorMsg,
                isFileHeadersLoading: false,
            };
        case TRANS_DA_POOL_FILE_UPLOAD:
            return {
                ...state,
                poolFiles: uniqBy([action.data, ...state.poolFiles], 'document_type'),
            };

        case TRANS_DA_POOL_FILE_DESTROY:
            return {
                ...state,
                mcdFile: {},
                poolFiles: [],
                errMessage: null,
            };
        case TRANS_DA_POOL_FILE_422_ERROR:
            return {
                ...state,
                skipExtractHeaders: true,
            };
        case TRANS_DA_CREATE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case TRANS_DA_CREATE_SUCCESS:
            return {
                ...state,
                ...action.data,
                progress: false,
            };
        case TRANS_DA_CREATE_ERROR:
            return {
                ...state,
                errMessage: action.message,
                progress: false,
            };
        case WATERFALL_SUMMARY_REQUEST:
            return {
                ...state,
                structuring: true,
            };
        case GEN_CASHFLOW_REQUEST:
            return {
                ...state,
                poolData: null,
            };
        case GEN_LOAN_FILES_REQUEST:
            return {
                ...state,
                isGenLoanFilesLoading: true,
            };
        case GEN_LOAN_FILES_SUCCESS:
            return {
                ...state,
                isGenLoanFilesLoading: false,
            };
        case DA_GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        default:
            return state;
    }
}

const daBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}`;

const unMapDA = (data) => {
    let ratingAgency = {};
    let lawFirms = {};
    let trustees = {};
    let auditors = {};
    let investors = {};
    let acceptedInvestors;
    if (data.rating_agencies.length > 0) {
        ratingAgency = data.rating_agencies
            .map((m) => ({
                value: m.id,
                label: m.name,
            }))
            .reduce((r) => r);
    }
    if (data.law_firms.length > 0) {
        lawFirms = data.law_firms
            .map((m) => ({
                value: m.id,
                label: m.name,
            }))
            .reduce((r) => r);
    }
    if (data.trustees.length > 0) {
        trustees = data.trustees
            .map((m) => ({
                value: m.id,
                label: m.name,
            }))
            .reduce((r) => r);
    }
    if (data.auditors.length > 0) {
        auditors = data.auditors
            .map((m) => ({
                value: m.id,
                label: m.name,
            }))
            .reduce((r) => r);
    }
    if (data.investors.length > 0) {
        investors = data.investors
            .map((m) => ({
                value: m.id,
                label: m.name,
            }))
            .reduce((r) => r);
    }
    if (data.accepted_investors.length > 0) {
        acceptedInvestors = data.accepted_investors.map((m) => ({
            value: m.id,
            label: m.name,
        }));
    }

    const processData = {
        ...data,
        ratingAgencyIds: ratingAgency.value ? ratingAgency : { value: 'N/A', label: 'N/A' },
        lawFirmIds: lawFirms,
        trusteeIds: trustees.value ? trustees : { value: 'N/A', label: 'N/A' },
        auditorIds: auditors,
        investorsIds: investors,
        acceptedInvestors,
    };
    return humps.camelizeKeys(processData);
};

const mapDA = (data) => {
    const processData = {
        waterfallGenCriteria: {
            servicerFeeUnit: validateObj(data, ['waterfallGenCriteria', 'servicerFeeUnit']) ? data.waterfallGenCriteria.servicerFeeUnit : null,
            servicerFee: validateObj(data, ['waterfallGenCriteria', 'servicerFee']) ? data.waterfallGenCriteria.servicerFee : null,
            servicerIncentive: validateObj(data, ['waterfallGenCriteria', 'servicerIncentive']) ? data.waterfallGenCriteria.servicerIncentive : 0,
            sharing: validateObj(data, ['waterfallGenCriteria', 'sharing']) ? data.waterfallGenCriteria.sharing : null,
            schedule: validateObj(data, ['waterfallGenCriteria', 'schedule']) ? data.waterfallGenCriteria.schedule : null,
        },
        settlementDate: data.settlementDate,
    };
    return humps.decamelizeKeys(processData);
};

const mapDAActors = (data) => {
    let prcessData = {};
    const trusteeIds = [];
    const ratingAgencyIds = [];
    const lawFirmIds = [];
    const auditorIds = [];

    if (data.ratingAgencyIds !== 'N/A') ratingAgencyIds.push(data.ratingAgencyIds);
    if (data.lawFirmIds) lawFirmIds.push(data.lawFirmIds);
    if (data.auditorIds) auditorIds.push(data.auditorIds);
    if (data.trusteeIds !== 'N/A') trusteeIds.push(data.trusteeIds);
    prcessData = {
        ratingAgencyIds,
        lawFirmIds,
        auditorIds,
        trusteeIds,
    };

    return humps.decamelizeKeys(prcessData);
};

function mapPoolSelection(data) {
    const mapData = {
        ...data,
        cutoffDate: moment(data.cutoffDate).format('YYYY-MM-DD'),
    };
    return humps.decamelizeKeys(mapData);
}

export function getDetails(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(daBaseUrl(id))
            .then((d) => unMapDA(d))
            .then((data) => {
                dispatch({ type: TRANSACTION_GET_DA, data, id });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving DA data', m);
                }),
            );
}

export function getWaterfall(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(daBaseUrl(id))
            .then((d) => unMapDA(d))
            .then((d) => {
                if (d.poolFiles.some((data) => data.displayName === 'Waterfall' && data.fileId)) {
                    dispatch({ type: TRANSACTION_GET_DA, data: d, id });
                    return d;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving DA data', m);
                }),
            );
}

export function updateDA(id, data) {
    return (dispatch) => {
        const payload = mapDA(data);
        return fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: payload,
            })
            .then((d) => unMapDA(d))
            .then(() => {
                toastr.success('Success!', 'DA data updated.');
                dispatch(getDetails(id));
            })
            .then(() => {
                if (_get(data, 'transaction_state') === TRANSACTION_STATE.RELEASED) {
                    dispatch({
                        type: PRODUCT_RELEASE_CLICK,
                        meta: {
                            amplitude: [
                                {
                                    eventType: EventTypes.track,
                                    eventPayload: {
                                        eventName: PRODUCT_RELEASE_CLICK,
                                        transaction_type: PRODUCT_KEYS.direct_assignment,
                                        transactionId: id,
                                        ...data,
                                    },
                                },
                            ],
                        },
                    });
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA data', m);
                }),
            );
    };
}

export function updateDAStructuring(id, data) {
    return (dispatch) => {
        const payload = mapDA(data);
        return fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: payload,
            })
            .then((d) => unMapDA(d))
            .then(() => {
                toastr.success('Success!', 'Structuring initiated, Please wait for few seconds.');
                dispatch(getDetails(id));
            })
            .then(() => dispatch(generateWaterfall(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA data', m);
                }),
            );
    };
}

export function generateWaterfall(id) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${daBaseUrl(id)}/generate_waterfall`)
            .then((d) => unMapDA(d))
            .then(() => dispatch(pollWaterfall(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA data', m);
                }),
            );
}

export function pollWaterfall(id) {
    return (dispatch) => {
        dispatch({ type: WATERFALL_SUMMARY_REQUEST, id });

        return fetchUtils
            .poll(() => dispatch(getWaterfall(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'Waterfall updated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving waterfall summary', 'Please try again after sometime.');
                    dispatch({ type: WATERFALL_SUMMARY_TIMEOUT, transId: id });
                } else {
                    toastr.error('Error retrieving waterfall summary', errMsg);
                }
            });
    };
}

export function generateLoanFiles(id, data) {
    return (dispatch) => {
        dispatch({ type: GEN_LOAN_FILES_REQUEST, id });
        return fetchUtils
            .patchJSON(`${daBaseUrl(id)}/generate_loan_files`, {
                direct_assignment_transaction: data,
            })
            .then(() => {
                toastr.success('Success!', 'Loan files generation request has been successful.');
                dispatch(pollLoanFiles(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA state', m);
                }),
            );
    };
}

export function pollLoanFiles(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getLoanFiles(id)), 180000, 1000)
            .then(() => {
                dispatch({ type: GEN_LOAN_FILES_SUCCESS, id });
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving Loan Files', 'Please try again after sometime.');
                    dispatch({ type: WATERFALL_SUMMARY_TIMEOUT, transId: id });
                } else {
                    toastr.error('Error retrieving Loan Files', errMsg);
                }
            });
}

export function getLoanFiles(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(daBaseUrl(id))
            .then((d) => unMapDA(d))
            .then((d) => {
                if (!d.poolFiles.some((s) => s.type === 'selected_loans' && s.fileId)) {
                    return null;
                }
                toastr.success('Success!', 'Loan files generated.');
                dispatch({ type: TRANSACTION_GET_DA, data: d, id });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Loan Files', m);
                }),
            );
}

//   Apply pool selection starts here
export function updateDAPool(id, data) {
    return (dispatch) =>
        fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: {
                    pool_selection_criteria: mapPoolSelection(data),
                },
            })
            .then((d) => unMapDA(d))
            .then(() => dispatch(generateCashFlow(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA pool data', m);
                }),
            );
}

//   Generate Cash flow
export function generateCashFlow(id) {
    return (dispatch) => {
        dispatch({ type: GEN_CASHFLOW_REQUEST, id });
        return fetchUtils
            .patchJSON(`${daBaseUrl(id)}/generate_cashflow`, {
                direct_assignment_transaction: {},
            })
            .then(() => dispatch(pollCashFlow(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Cashflow', m);
                }),
            );
    };
}

//   Keep polling for pool summary
export function pollCashFlow(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getCashFlow(id)), 180000, 1000)
            .then((d) => {
                if (validateObj(d, ['poolData', 'error'])) {
                    toastr.warning('Warning!', d.poolData.error);
                } else {
                    toastr.success('Success!', 'Pool selection criteria updated.');
                    toastr.success('Success!', 'Cashflow and Pool Summary Generated.');
                }
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving waterfall summary', 'Please try again after sometime.');
                    dispatch({ type: WATERFALL_SUMMARY_TIMEOUT, transId: id });
                } else {
                    toastr.error('Error retrieving waterfall summary', errMsg);
                }
            });
}

export function getCashFlow(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(daBaseUrl(id))
            .then((d) => unMapDA(d))
            .then((data) => {
                if (!data.poolData) {
                    return null;
                }
                dispatch({ type: TRANSACTION_GET_DA, data, id });
                return data;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving DA data', m);
                }),
            );
}
//   Apply pool selection ends here

export function updateDAActors(id, actors) {
    return (dispatch) =>
        fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: mapDAActors(actors),
            })
            .then((d) => unMapDA(d))
            .then((d) => {
                toastr.success('Success!', 'DA counterparties updated.');
                dispatch({ type: TRANSACTION_GET_DA, data: d, id });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA counterparties', m);
                }),
            );
}

export function updateDAState(id, data, callback) {
    return (dispatch) =>
        fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: data,
            })
            .then((d) => unMapDA(d))
            .then(() => {
                toastr.success('Success!', 'Action Completed.');
                dispatch(getDetails(id));
                if (typeof callback === 'function') callback();
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA state', m);
                    if (typeof callback === 'function') callback();
                }),
            );
}

export const selectorGenLoanFilesLoading = createLoadingSelector([createActionName('GEN_LOAN_FILES')]);
export const adhocDocLoading = createLoadingSelector([createActionName('ADHOC_CREATE')]);
export const adhocDocUpdating = createLoadingSelector([createActionName('ADHOC_UPDATE')]);

export function generatePoolFiles(id, data) {
    return (dispatch) =>
        fetchUtils
            .putJSON(daBaseUrl(id), {
                direct_assignment_transaction: humps.decamelizeKeys(data),
            })
            .then((d) => unMapDA(d))
            .then(() => {
                toastr.success('Success!', 'DA state updated.');
                dispatch(getDetails(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating DA state', m);
                }),
            );
}

export function uploadDADocuments(id, file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('document', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/deal_documents`, body)
            .then(() => {
                dispatch(getDetails(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

export function destroyFile(fileId, transId) {
    return (dispatch) =>
        fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/deal_documents/${fileId}`)
            .then(() => {
                toastr.success('File Destroyed');
                dispatch(getDetails(transId));
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error destroying transaction file', m);
                });
            });
}

export function destroyPTCPool(fileId, transId) {
    return (dispatch) => {
        if (transId) {
            return Promise.resolve(
                dispatch({
                    type: TRANS_DA_POOL_FILE_DESTROY,
                    data: {},
                    id: transId,
                }),
            );
        }
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}`)
            .then(() => {
                dispatch({
                    type: TRANS_DA_POOL_FILE_DESTROY,
                    id: fileId,
                });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing pool file', m);
                }),
            );
    };
}

export function uploadPTCPoolV2(fileId, files, fileType, poolType, skipExtractHeaders = false, transId) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/${fileType}s/${fileId}`, {
                [fileType]: {
                    document_file_name: files[0].name,
                    document_content_type: files[0].type,
                    document_file_size: files[0].size,
                    transaction_id: transId,
                },
                transaction_id: transId,
            })
            .then((d) => {
                dispatch(updatePoolFile(d, transId));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(pollFileHeaders(d.id, poolType, transId));
                }
            })
            .then((d) => {
                if (d) {
                    dispatch({
                        type: TRANS_DA_POOL_FILE_HEADERS_SUCCESS,
                        data: d,
                        fileId,
                        id: transId,
                    });
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_DA_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
}

export function uploadPTCPool(file, fileType, poolType, skipExtractHeaders = false) {
    return (dispatch) => {
        const body = new FormData();
        Object.keys(file).forEach((k) => {
            body.append('transaction_file[document]', file[k]);
        });
        if (fileType === 'mcd_file') {
            body.append('transaction_file[pool_type]', poolType);
            body.append('transaction_file[skip_extract_headers]', skipExtractHeaders);
        }
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(getFileHeaders(d.id, poolType));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_DA_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

export function pollFileHeaders(fileId, poolType, id) {
    return (dispatch) => {
        dispatch({ type: TRANS_DA_POOL_FILE_HEADERS_REQUEST, id });
        return fetchUtils
            .poll(
                () =>
                    fetchUtils
                        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/mcd_files/${fileId}/file_headers?pool_type=${poolType}`)
                        .catch(() => null),
                1000 * 60 * 3,
                1000,
            )
            .then((d) => {
                if (!d) {
                    return null;
                }
                dispatch({
                    type: TRANS_DA_POOL_FILE_HEADERS_SUCCESS,
                    data: d,
                    fileId,
                    id,
                });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) =>
                    dispatch({
                        type: TRANS_DA_POOL_FILE_HEADERS_ERROR,
                        errorMsg: m,
                        id,
                    }),
                ),
            );
    };
}

export function getFileHeaders(fileId, poolType, id = null) {
    return (dispatch) => dispatch(pollFileHeaders(fileId, poolType, id));
}

function updatePoolFile(d, id = null) {
    return { type: TRANS_DA_POOL_FILE_UPLOAD, data: d, id };
}

function mapTransfileColumns(poolType, fileType, columnMapping) {
    const obj = {};
    const colMappingObj = {};
    Object.keys(columnMapping).forEach((c) => {
        if (columnMapping[c]) {
            colMappingObj[c] = columnMapping[c];
        }
    });
    obj[fileType] = {
        column_mapping: colMappingObj,
        pool_type: poolType,
    };
    return obj;
}

function postTransFileColMap(poolType, fileId, fileType, columnMapping, id) {
    return (dispatch) => {
        if (fileId && fileType && columnMapping) {
            return fetchUtils
                .putJSON(
                    `${process.env.REACT_APP_MP_API_HOST}/mcd_files/${fileId}/map_columns`,
                    mapTransfileColumns(poolType, fileType, columnMapping),
                )
                .then((d) => dispatch(updatePoolFile(d, id)));
        }
        return Promise.resolve();
    };
}

function mapPTCInitData(data) {
    const { poolFiles, poolType, type } = data;

    const mcdFile = poolFiles.filter((el) => el.document_type === 'mcd_file');
    const rsFile = poolFiles.filter((el) => el.document_type === 'rs_file');

    return {
        direct_assignment_transaction: {
            documents: [
                {
                    id: type === 'rs' ? rsFile[0].id : mcdFile[0].id,
                    type: type === 'rs' ? 'rs_file' : 'mcd_file',
                },
            ],
            pool_type: poolType,
        },
    };
}

function updateDATrans(transId, data) {
    return fetchUtils.putJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}`, mapPTCInitData(data));
}

export function createDATrans(transId, data, type) {
    return (dispatch) => {
        const { poolFiles, columnMappingFileId, columnMappingFileType, columnMapping, poolType } = data;
        if (!poolFiles.some((f) => f.document_type === 'rs_file') && type === 'rs') {
            return dispatch(
                RouterUtils.pushRoute({
                    path: ADMIN_TRANS_DA,
                    keys: { transId },
                }),
            );
        }
        dispatch({ type: TRANS_DA_CREATE_REQUEST, id: transId });
        return dispatch(postTransFileColMap(poolType, columnMappingFileId, columnMappingFileType, columnMapping, transId))
            .then(() => updateDATrans(transId, { poolFiles, poolType, type }))
            .then((d) => {
                dispatch({
                    type: TRANS_DA_CREATE_SUCCESS,
                    data: d,
                    id: transId,
                });
                return d;
            })
            .then((d) => {
                if (type === 'mcd') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: ADMIN_TRANS_DA_RSUPLOAD,
                            keys: { transId: d.id },
                        }),
                    );
                }
                if (type === 'rs') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: ADMIN_TRANS_DA,
                            keys: { transId: d.id },
                        }),
                    );
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({
                        type: TRANS_DA_CREATE_ERROR,
                        message: m,
                        id: transId,
                    });
                    toastr.error('Error while creating DA transaction', m);
                }),
            );
    };
}

export function editDealName(transId, values) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${daBaseUrl(transId)}`, {
                direct_assignment_transaction: {
                    ...humps.decamelizeKeys(values),
                    id: transId,
                },
            })
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET_DA, data: d, id: transId });
                toastr.success('Success!', 'Deal Name updated successfully');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating deal name', m);
                }),
            );
}

export function generateDocs(transId) {
    toastr.success('Success!', 'Documents generation Request initiated');
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/generate_documents`, {
                direct_assignment_transaction: {},
            })
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET_DA, data: d, id: transId });
            })
            .then(() => dispatch(pollingDADocs(transId)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                }),
            );
}

export function pollingDADocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getDADocs(id)), 180000, 1000)
            .then((d) => {
                dispatch({ type: TRANSACTION_GET_DA, data: d, id });
                toastr.success('Success!', 'Documents generated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Generating Documents', errMsg);
                }
            });
}

function getDADocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}`)
            .then((d) => unMapDA(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated' || item.files[0].state === 'uploaded');
                if (isGenerated) return d;
                dispatch({ type: TRANSACTION_GET_DA, data: d, id });
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating deal name', m);
                }),
            );
}

const formatAdHocData = (values) => {
    const data = {
        document: values,
    };
    return humps.decamelizeKeys(data);
};

export function addAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_CREATE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/add_document`, formatAdHocData(data))
            .then(() => {
                dispatch({ type: ADHOC_CREATE_SUCCESS });
                dispatch(getDetails(transId));
                toastr.success('Success!', 'Adhoc document has been created successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_CREATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error adding document', m);
                });
            });
    };
}

export function updateAdhocDocument(transId, data) {
    return (dispatch) => {
        dispatch({ type: ADHOC_UPDATE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/modify_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: ADHOC_UPDATE_SUCCESS });
                dispatch(getDetails(transId));
                toastr.success('Success!', 'Document settings has been updated successfully');
            })
            .catch((ex) => {
                dispatch({ type: ADHOC_UPDATE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating settings', m);
                });
            });
    };
}

export function removeDocPlaceholder(transId, data) {
    return (dispatch) => {
        dispatch({ type: PLACEHOLDER_REMOVE_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/remove_document`, humps.decamelizeKeys(data))
            .then(() => {
                dispatch({ type: PLACEHOLDER_REMOVE_SUCCESS });
                dispatch(getDetails(transId));
                toastr.success('Success!', 'Document placeholder has been removed successfully');
            })
            .catch((ex) => {
                dispatch({ type: PLACEHOLDER_REMOVE_FAILURE });
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing placeholder', m);
                });
            });
    };
}

export function getDAExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = _get(d, 'documents', []).filter((doc) => doc.files.length > 0);
                const others = d.otherFiles;
                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (_get(d, 'errorMessage')) {
                    return _get(d, 'errorMessage');
                }
                if (!docs.length && others.length) {
                    dispatch({ type: DA_GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return null;
                }
                if (isFailed) {
                    return docs;
                } else if (isGenerated) {
                    dispatch({ type: DA_GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}

export function pollDAExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(getDAExecDocs(id)), 180000, 3000)
            .then((d) => {
                if (!Array.isArray(d)) {
                    toastr.error('Error Finalizing Documents', d);
                } else if (!d.length) {
                    toastr.error('Error Finalizing Documents', 'Failed to generate executed documents');
                } else if (d.some((item) => item.files[0].state === 'failed')) {
                    toastr.error(
                        'Error Finalizing Documents',
                        `Please check the format of ${humps.pascalize(d.find((item) => item.files[0].state === 'failed').files[0].type)}`,
                    );
                } else {
                    toastr.success('Success!', 'Documents generated successfully.');
                }
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Polling Documents', errMsg);
                }
            });
}

export function generateExecDocs(transId, setSubmitting) {
    toastr.success('Success!', 'Documents generation Request initiated');
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}/executable_documents`)
            .then(() => dispatch(pollDAExecDocs(transId)))
            .then(() => setSubmitting(false))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Generating Documents', m);
                }),
            );
}

export function revokeExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/executable_documents/revoke`)
            .then((d) => {
                if (_get(d, 'success')) {
                    dispatch(getDAExecDocs(id));
                } else {
                    toastr.error('Failed to revoke executable documents', '');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error revoking executable documents', m);
                }),
            );
}

export function getDAState(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(daBaseUrl(id))
            .then((d) => unMapDA(d))
            .then((d) => {
                if (d.transactionState === TRANSACTION_STATE.SETTLED) {
                    dispatch({ type: TRANSACTION_GET_DA, data: d, id });
                    return d;
                }

                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    return new Error(m);
                }),
            );
}

export function pollDAState(id) {
    return (dispatch) => {
        return fetchUtils
            .poll(() => dispatch(getDAState(id)), 180000, 1000)
            .then(() => {
                toastr.success('Success!', 'Transaction state updated successfully.');
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving transaction state', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving transaction state', errMsg);
                }
            });
    };
}

export function updateDAStatus(id, values) {
    return (dispatch) => {
        dispatch({ type: FUNDING_STATUS_REQUEST });
        return fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/ncds/${id}`, { direct_assignment_transaction: { ...humps.decamelizeKeys(values), id } })
            .then((d) => humps.camelizeKeys(d))
            .then(() => dispatch(pollDAState(id)))
            .then(() => {
                if (values.direct_settlement) {
                    dispatch({ type: FUNDING_STATUS_SUCCESS });
                    toastr.success('Success!', 'Transaction status updated.');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FUNDING_STATUS_FAILURE });
                    toastr.error('Error while updating', m);
                }),
            );
    };
}
