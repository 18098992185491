import { PROPORTION_UNITS, RATING } from 'app/constants/Constants';
import {
    FREQUENCY,
    INTEREST_RATE_METHOD,
    INTEREST_RATE_SHARING_TYPE,
    INTEREST_RATE_TYPE,
    PRINCIPAL_REPAYMENT,
} from 'app/constants/TransactionConstants';
import { getDayOptions } from 'app/utils/CommonUtils';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _size from 'lodash/size';
import { getOptionLabel } from 'app/utils/StringUtils';
import _isEmpty from 'lodash/isEmpty';
import { floatFormatINR } from 'app/utils/StringUtils';
import { formatDate, isValidDate } from 'app/utils/DateUtils';
import { humanize } from 'app/utils/StringUtils';
import {
    DATE_OPERATIONS,
    EMPTY_OPERATORS,
    NUMBER_OPERATIONS,
    NUMBER_OPERATORS,
    OPERATIONS,
} from 'app/products/Shared/Admin/PoolFilter/AdvancedFilter/constants';
export const MAX_TRANCHES = 4;

export const TRANCH_COUNT_OPTIONS = [...Array(MAX_TRANCHES)].map((k, i) => i + 1).map((i) => ({ value: i, label: i }));

export const getOption = (fieldName: string): any => {
    switch (fieldName) {
        case 'servicerFeeUnit':
        case 'schedule.servicerFeeUnit':
        case 'trancheUnit':
        case 'tranches.investorUnit':
            return PROPORTION_UNITS;
        case 'sharing.interestRateSharingType':
            return INTEREST_RATE_SHARING_TYPE;
        case 'sharing.interestRateMethod':
        case 'tranches.investors.interestMethod':
            return INTEREST_RATE_METHOD;
        case 'sharing.interestRateType.type':
            return INTEREST_RATE_TYPE;
        case 'sharing.interestRateType.floatingInterestRate.benchmarkResetFrequency':
        case 'sharing.interestRateType.floatingInterestRate.spreadResetFrequency':
            return FREQUENCY;
        case 'schedule.payinDate':
        case 'schedule.payoutDate':
            return getDayOptions(28);
        case 'schedule.firstCollectionOffset':
            return [...Array(4)].map((m, i) => ({ label: i, value: `${i}` }));
        case 'schedule.principalRepayment':
            return [...Object.keys(PRINCIPAL_REPAYMENT).map((pr: string) => _get(PRINCIPAL_REPAYMENT, pr))];
        case 'trancheCount':
            return TRANCH_COUNT_OPTIONS;
        case 'tranches.rating':
            return RATING;
    }
};

export const getOptionValueAndLabel = (fieldName: string, value: any): string => {
    const options = getOption(fieldName) || [];
    const option =
        value?.label || value?.label === 0
            ? value
            : (options && (value || value === 0) && Array.isArray(options) && options.find((p: any) => p.value === value)) || {
                  value,
                  label: value,
              };
    return option || value;
};

export const waterfallTypeHandler = (val: number) => {
    let waterfallType = '';
    if (Number(val) === 0) {
        waterfallType = 'PAR';
    } else if (Number(val) > 0 && Number(val) < 100) {
        waterfallType = 'PARTIAL TURBO';
    } else if (Number(val) === 100) {
        waterfallType = 'TURBO';
    }
    return waterfallType;
};

export const getOptionValue = (fieldName: string, value: string): string => {
    const options = getOption(fieldName) || [];
    return getOptionLabel(options, value);
};

export const validate = (values: any, poolPrincipal: number) => {
    const errors: any = {};

    const validateSharing = (sharingVals: any) => {
        const sharingErrors: any = {};

        if (!sharingVals.assigneeShare) {
            sharingErrors.assigneeShare = 'Required';
        }
        if (!sharingVals.assigneeAmount) {
            sharingErrors.assigneeAmount = 'Required';
        }
        if (!sharingVals.assignorShare) {
            sharingErrors.assignorShare = 'Required';
        }
        if (!sharingVals.assignorAmount) {
            sharingErrors.assignorAmount = 'Required';
        }
        if (sharingVals.assigneeAmount > poolPrincipal) {
            sharingErrors.assigneeAmount = 'Value canot be greater than Pool Principal';
        }
        if (sharingVals.assignorAmount > poolPrincipal) {
            sharingErrors.assignorAmount = 'Value canot be greater than Pool Principal';
        }
        if (!sharingVals.interestRateMethod) {
            sharingErrors.interestRateMethod = 'Required';
        }
        if (!_get(sharingVals, 'interestRateType.type')) {
            sharingErrors.interestRateType = { type: 'Required' };
        }
        if (!sharingVals.interestRateSharingType) {
            sharingErrors.interestRateSharingType = 'Required';
        }

        return sharingErrors;
    };

    const validateSchedule = (scheduleVals: any) => {
        const scheduleErrors: any = {};

        if (!scheduleVals.payinDate) {
            scheduleErrors.payinDate = 'Required';
        }

        if (!scheduleVals.payoutDate) {
            scheduleErrors.payoutDate = 'Required';
        }
        if (!scheduleVals.firstCollectionOffset && scheduleVals.firstCollectionOffset !== 0) {
            scheduleErrors.firstCollectionOffset = 'Required';
        }

        return scheduleErrors;
    };

    if (!values.settlementDate) {
        errors.settlementDate = 'Required';
    }
    if (!values.servicerFeeUnit) {
        errors.servicerFeeUnit = 'Required';
    }
    if (!values.servicerFee) {
        errors.servicerFee = 'Required';
    }

    if (!_isEmpty(validateSharing(values.sharing || {}))) {
        errors.sharing = validateSharing(values.sharing || {});
    }
    if (!_isEmpty(validateSchedule(values.schedule || {}))) {
        errors.schedule = validateSchedule(values.schedule || {});
    }

    return errors;
};

export const unmapValues = (val = {}): any => {
    const unmappedVal: any = { ...val };
    Object.keys(val).forEach((key) => {
        const currentValue: any = _get(val, key);

        unmappedVal[key] = Array.isArray(currentValue)
            ? currentValue.map(
                  (val: any) =>
                      (!['tranches', 'investors'].includes(key) && _get(val, 'value')) ||
                      (_get(val, 'constructor.name') === 'Object' && unmapValues(val)) ||
                      val,
              )
            : _get(currentValue, 'value') || (_get(currentValue, 'constructor.name') === 'Object' && unmapValues(currentValue)) || currentValue;
    });
    return unmappedVal;
};

export const unmapValuesGlobal = (val: any): any => {
    if (_has(val, 'rules')) {
        val.rules = _get(val, 'rules', []).filter((rule: any) =>
            _has(rule || {}, 'op')
                ? _get(rule || {}, 'oper') &&
                  (_get(rule || {}, 'value') || [EMPTY_OPERATORS.IS_NULL, EMPTY_OPERATORS.IS_NOT_NULL].includes(_get(rule, 'oper.value') || ''))
                : !!_size(Object.keys(rule || {})),
        );
        if ([0, 1].includes(_size(_get(val, 'rules', []))) && _has(val, 'join')) {
            if (_size(_get(val, 'rules', [])) === 1) {
                val = { ...val, ...{ ..._get(val, 'rules[0]', {}) } };
            }
            delete val?.rules;
            delete val?.join;
        }
    }

    const unmappedVal: any = { ...(val || {}) };

    Object.keys(val || {}).forEach((key) => {
        const currentValue: any = _get(val, key);
        unmappedVal[key] =
            key === 'value' && [EMPTY_OPERATORS.IS_NULL, EMPTY_OPERATORS.IS_NOT_NULL].includes(_get(val, 'oper.value'))
                ? ''
                : Array.isArray(currentValue)
                ? currentValue.map(
                      (val: any) =>
                          (_get(val, 'label') && _get(val, 'value')) || (_get(val, 'constructor.name') === 'Object' && unmapValuesGlobal(val)) || val,
                  )
                : currentValue?.value === 0
                ? currentValue?.value
                : (_get(currentValue, 'label') && _get(currentValue, 'value')) ||
                  (_get(currentValue, 'constructor.name') === 'Object' && unmapValuesGlobal(currentValue)) ||
                  currentValue;
    });
    if (_has(unmappedVal, 'rules')) {
        return _get(unmappedVal, 'rules', []).length && _size(unmappedVal?.rules?.[0]) ? unmappedVal : {};
    }
    return unmappedVal;
};
export const mapValuesGlobal = (val = {}, alreadyARule = false): any => {
    const mapOption = (k: any, isNumber = false, currentDataType = '') => {
        if (typeof k === 'string' && NUMBER_OPERATORS.includes(k) && currentDataType === 'date')
            return {
                label: _get(DATE_OPERATIONS.get(k), 'label'),
                value: k,
            };
        return {
            label:
                typeof k === 'string'
                    ? NUMBER_OPERATORS.includes(k)
                        ? _get((isNumber ? NUMBER_OPERATIONS : OPERATIONS).get(k), 'label')
                        : k.includes('-') && isValidDate(k)
                        ? formatDate(k, 'DD MMM, YYYY')
                        : humanize(k)
                    : k,
            value: k,
        };
    };
    const valueKeys = Object.keys(val);

    const structuredVal = valueKeys.includes('rules') && _size(valueKeys) === 1 ? { ..._get(val, 'rules[0]', {}) } : { ...val };
    let unmappedVal: any = { ...structuredVal };
    Object.keys(structuredVal).forEach((key) => {
        const currentValue: any = _get(structuredVal, key);
        const currentDataType = _get(structuredVal, 'dataType');
        unmappedVal[key] = Array.isArray(currentValue)
            ? currentValue.map((i: any) => {
                  if (_get(i, 'constructor.name') === 'Object') {
                      return mapValuesGlobal(i, valueKeys.includes('rules')) || i;
                  } else {
                      return mapOption(i, key === 'oper' && typeof _get(i, 'value') === 'number');
                  }
              })
            : _get(currentValue, 'constructor.name') === 'Object'
            ? mapValuesGlobal(currentValue, valueKeys.includes('rules')) || currentValue
            : mapOption(currentValue, key === 'oper', currentDataType);
    });
    if (_has(unmappedVal, 'op') && !alreadyARule) {
        unmappedVal = { rules: [{ ...unmappedVal }] };
    }
    return unmappedVal;
};

export const getFormattedvalue = (fieldName: string, values: any) => {
    switch (fieldName) {
        case 'settlementDate':
        case 'schedule.settlementDate':
            return formatDate(_get(values, fieldName), 'DD MMM, YYYY');
        case 'servicerFeeUnit':
        case 'schedule.servicerFeeUnit':
        case 'sharing.interestRateSharingType':
        case 'sharing.interestRateMethod':
        case 'sharing.interestRateType.type':
        case 'sharing.interestRateType.floatingInterestRate.benchmarkResetFrequency':
        case 'sharing.interestRateType.floatingInterestRate.spreadResetFrequency':
        case 'schedule.principalRepayment':
            return getOptionValue(fieldName, _get(values, `${fieldName}.value`) || _get(values, fieldName));
        case 'sharing.assignorAmount':
        case 'sharing.assigneeAmount':
            return floatFormatINR(_get(values, `${fieldName}`));
        default:
            return _get(values, `${fieldName}.value`) || _get(values, fieldName);
    }
};

export const numeric = (value: any) => value && parseFloat(value);
export const roundFloat = (value: any) => value && parseFloat(value).toFixed(2);
export const parseInteger = (value: any) => {
    const parsed = parseInt(value, 10);
    if (Number.isNaN(parsed)) {
        return 0;
    }
    return parsed;
};

// PTC Form validation

const validateInvestors = (investors: any) => {
    const errors: any = [];
    if (_get(investors, '[0]')) {
        investors.forEach((m: any, i: number) => {
            if (!m.value) {
                errors[i] = { ...errors[i], value: 'required' };
            }
            if (!m.yield) {
                errors[i] = { ...errors[i], yield: 'required' };
            }
            if (!m.interestMethod) {
                errors[i] = { ...errors[i], interestMethod: 'required' };
            }
        });
    }
    return errors;
};

const validateTranches = (tranches: any) => {
    const errors: any = [];
    if (_get(tranches, '[0]')) {
        tranches.forEach((m: any, i: number) => {
            if (!m.value) {
                errors[i] = { ...errors[i], value: 'required' };
            }
            if (!m.rating) {
                errors[i] = { ...errors[i], rating: 'required' };
            }
            if (!m.investorUnit) {
                errors[i] = { ...errors[i], investorUnit: 'required' };
            }
            errors[i] = { ...errors[i], investors: validateInvestors(m.investors) };
            if (!_size(Object.keys(_get(errors, `[${i}].investors[0]`, {})))) {
                delete errors[i].investors;
            }
        });
    }
    return errors;
};

export const ptcValidate = (values: any) => {
    const errors: any = {};
    if (!_get(values, 'schedule.settlementDate')) {
        errors.schedule = { ..._get(errors, 'schedule', {}), settlementDate: 'Required' };
    }
    if (!_get(values, 'schedule.payinDate')) {
        errors.schedule = { ..._get(errors, 'schedule', {}), payinDate: 'Required' };
    }
    if (!_get(values, 'schedule.payoutDate')) {
        errors.schedule = { ..._get(errors, 'schedule', {}), payoutDate: 'Required' };
    }
    if (!_get(values, 'schedule.firstCollectionOffset') && _get(values, 'schedule.firstCollectionOffset') !== 0) {
        errors.schedule = { ..._get(errors, 'schedule', {}), firstCollectionOffset: 'Required' };
    }
    if (!_get(values, 'schedule.servicerFee')) {
        errors.schedule = { ..._get(errors, 'schedule', {}), servicerFee: 'Required' };
    }
    if (!_get(values, 'schedule.servicerFeeUnit')) {
        errors.schedule = { ..._get(errors, 'schedule', {}), servicerFeeUnit: 'Required' };
    }
    if (!_get(values, 'eisTrappingPercentage') && _get(values, 'eisTrappingPercentage') !== 0) {
        errors.eisTrappingPercentage = 'Required';
    } else if (Number(_get(values, 'eisTrappingPercentage')) > 100) {
        errors.eisTrappingPercentage = 'Should Not Be Greater Than 100';
    }
    errors.tranches = validateTranches(values.tranches);
    if (!_size(Object.keys(_get(errors, 'tranches[0]', {})))) {
        delete errors.tranches;
    }
    return errors;
};

export const emptyState = {
    concentrations: {
        branchName: 100,
        stateName: 100,
        districtName: 100,
    },
    loansToReject: [
        {
            ids: '',
        },
    ],
    par: {
        parFilters: {
            par0: 100,
            par30: 100,
            par60: 100,
            par90: 100,
        },
    },
    limitPoolSize: {
        type: 'retain_branch_concentration',
    },
};
