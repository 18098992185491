import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../../reducers/loading';
import { createActionName, baseURL } from './bid';
import { constructQuery } from 'app/utils/URLQuery';

export const COMMODITY_GET = createActionName('COMMODITY_GET_REQUEST');
export const COMMODITY_GET_SUCCESS = createActionName('COMMODITY_GET_SUCCESS');
export const COMMODITY_GET_FAILURE = createActionName('COMMODITY_GET_FAILURE');

export const UPDATE_COMMODITY_REQUEST = createActionName('UPDATE_COMMODITY_REQUEST');
export const UPDATE_COMMODITY_SUCCESS = createActionName('UPDATE_COMMODITY_SUCCESS');
export const UPDATE_COMMODITY_FAILURE = createActionName('UPDATE_COMMODITY_FAILURE');

export const FILTER_COMMODITY_REQUEST = createActionName('FILTER_COMMODITY_REQUEST');
export const FILTER_COMMODITY_SUCCESS = createActionName('FILTER_COMMODITY_SUCCESS');
export const FILTER_COMMODITY_FAILURE = createActionName('FILTER_COMMODITY_FAILURE');

export const commodityFetching = createLoadingSelector([createActionName('COMMODITY_GET')]);
export const commodityFiltering = createLoadingSelector([createActionName('FILTER_COMMODITY')]);

const initialState: any = { filteredCommodity: [], commodities: [] };

export const historicalPriceInitialValue: any = { name: '', location: '', fromDate: '', toDate: '' };

export default function commodity(state: any = initialState, action: any) {
    switch (action.type) {
        case COMMODITY_GET_SUCCESS: {
            return { ...state, commodities: action.data };
        }

        case FILTER_COMMODITY_SUCCESS: {
            return { ...state, filteredCommodity: action.data };
        }

        default: {
            return state;
        }
    }
}

export const computeInitialValue = (data: any) => {
    if (data.length) {
        const values = data.map((d: any, ind: number) => {
            return {
                id: d.id,
                mandiPrice: '',
                ncdexPrice: '',
            };
        });
        return values;
    }
};
export const getCommodity = (id: string) => {
    return (dispatch: any) => {
        dispatch({ type: COMMODITY_GET, id });
        fetchUtils
            .getJSON(`${baseURL(id)}/commodities`)
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: COMMODITY_GET_SUCCESS, data: d, id });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    toastr.error('Error fetching Commodities information', m);
                }),
            );
    };
};

export const updateBulkCommodities = (id: string, values: object) => {
    return (dispatch: any) => {
        dispatch({ type: UPDATE_COMMODITY_REQUEST, id });
        fetchUtils
            .patchJSON(`${baseURL(id)}/commodities/bulk_update`, humps.decamelizeKeys(values))
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                dispatch({ type: UPDATE_COMMODITY_SUCCESS, id });
                toastr.success('Success', ' Commodities Updated Successfully');
                return dispatch(getCommodity(id));
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: UPDATE_COMMODITY_FAILURE, id });
                    toastr.error('Error Updating Commodities information', m);
                }),
            );
    };
};

export const filterCommodities = (id: string, query: any) => {
    return (dispatch: any) => {
        dispatch({ type: FILTER_COMMODITY_REQUEST, id });
        fetchUtils
            .getJSON(`${baseURL(id)}/commodities?${constructQuery(query)}`)
            .then((d: any) => d && humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({ type: FILTER_COMMODITY_SUCCESS, data: d, id });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m: any) => {
                    dispatch({ type: FILTER_COMMODITY_FAILURE, id });
                    toastr.error('Error Filtering Commodities ', m);
                }),
            );
    };
};
