import { useEffect, useState } from 'react';
import cookieCheck from '3rd-cookie-check';
import { YUBI_COOKIE_CHECK_URL } from 'app/constants/Constants';

export const TP_COOKIE_SETTINGS = 'yb_tp_cookie_settings';

function useTPCookieCheck({ canFetch }: any) {
    const [supported, setSupported] = useState<any>(null);

    function handleStatusChange(status: any) {
        setSupported(status.supported);
    }

    function handleCookieFailure() {
        setSupported('failed');
    }

    useEffect(() => {
        if (canFetch && supported === null)
            cookieCheck({ iframeSrc: YUBI_COOKIE_CHECK_URL })
                .then(handleStatusChange)
                .catch(handleCookieFailure);
    }, [canFetch, supported]);

    return {
        supported,
    };
}

export default useTPCookieCheck;
