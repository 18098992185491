import React, { useEffect } from 'react';
import queryString from 'qs';
import Banner from 'app/components/Banner';
import FooterLinks from 'app/components/FooterLinks';
import StyledLogin from './styled';
import ChangePasswordForm from './ChangePasswordForm';
import SideBar from '../SideBar';
import Spinner from '../Spinner';

const ChangePassword = (props) => {
    const { isLoggedIn, handleAuth, push, location: { search, hash, state } = null, errorMsg } = props;

    useEffect(() => {
        const { nexturl } = search ? queryString.parse(search, { ignoreQueryPrefix: true }) : {};
        const { from } = state || { from: { pathname: nexturl || '/' } };
        if (isLoggedIn && !nexturl) {
            push(from);
        }
        if (hash) {
            handleAuth(hash);
        }
    }, []);

    if (hash && !errorMsg) {
        return <Spinner />;
    }

    return (
        <>
            <StyledLogin>
                <div className="container">
                    <div className="row">
                        <div className="span6">
                            <SideBar />
                        </div>
                        <div className="span6">
                            <ChangePasswordForm {...props} />
                        </div>
                    </div>
                    <div className="white-bg" />
                    <Banner />
                </div>
            </StyledLogin>
            <FooterLinks />
        </>
    );
};
export default ChangePassword;
