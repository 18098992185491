import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from './Spinner';
import { logoutFromAllDevices, LOGOUT_ALL_LOADING } from '../actions/AuthedActions';

interface ILogoutFromAllDevices {
    logoutAll: () => void;
    isLogoutLoading: boolean;
}

const LogoutFromAllDevices: React.FC<ILogoutFromAllDevices> = (props) => {
    const { logoutAll, isLogoutLoading } = props;

    useEffect(() => {
        logoutAll();
    }, []);

    if (isLogoutLoading) return <Spinner />;

    return <></>;
};

const mapDispatchToProps = (dispatch: any) => ({
    logoutAll: () => dispatch(logoutFromAllDevices()),
});

const mapStateToProps = (state: any) => ({
    isLogoutLoading: LOGOUT_ALL_LOADING(state),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default reduxConnector(LogoutFromAllDevices);
