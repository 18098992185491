import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const ModalWrapper = styled.div`
    &.custom-model {
        height: 100%;
        > .close-btn {
            position: absolute;
            left: -46px;
            top: 6px;
            border: none;
            border-radius: 50%;
            padding: 12px;
            display: flex;
            background: #fff;
            > i {
                font-size: 16px;
            }
        }
        .title {
            color: #292929;
            font-size: 18px;
            font-weight: 800;
            font-style: italic;
            padding: 15px 20px;
        }
        .modal-body {
            height: 100%;
            padding: 0px;
            > .custom-scroll {
                padding: 15px;
                > p {
                    font-size: 13px;
                    font-weight: 500;
                    color: #292b35;
                    padding-bottom: 15px;
                }
                @media (max-width: 1199px) {
                    overflow-y: scroll;
                    max-height: 300px;
                }
            }
            &.manage-roles {
                > .custom-scroll {
                    display: flex;
                    justify-content: space-around;
                    padding: 20px 15px;
                }
            }
            > .action-btns {
                position: absolute;
                bottom: 50px;
                width: 100%;
                margin-top: 0px;
                background: #fff;
            }
        }
    }
`;

const ModalPopup = ({
    open,
    children,
    title,
    onAfterOpen,
    onAfterClose,
    onRequestClose,
    customStyle,
    contentLabel,
    showActionsToolbar,
    onPositiveCb,
    onNegativeCb,
    poisitiveLabel,
    negativeLabel,
    modalClass,
    floating = 'center',
    modalBodyClass = 'modal-body',
    customActionToolbar,
    customToolbar,
    disableOuterClick = false,
}) => {
    Modal.setAppElement('#root');
    return (
        <Modal
            isOpen={open}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            onRequestClose={onRequestClose}
            style={customStyle}
            contentLabel={contentLabel}
            className={classNames('custom-react-modal promoter-modal', {
                [modalClass]: true,
                [floating]: true,
            })}
            shouldCloseOnEsc={!disableOuterClick}
            shouldCloseOnOverlayClick={!disableOuterClick}
        >
            <ModalWrapper className="custom-model">
                {floating !== 'center' ? (
                    <>
                        <button className="close-btn" onClick={() => onRequestClose()}>
                            <i className="icon-close" />
                        </button>
                        <p className="title">{title}</p>
                    </>
                ) : (
                    <>
                        {(onRequestClose || title) && (
                            <div className="modal-header">
                                {onRequestClose && (
                                    <button type="button" className="close" onClick={onRequestClose}>
                                        <i className="icon-close" />
                                    </button>
                                )}
                                {title && <h4 className="modal-title">{title}</h4>}
                            </div>
                        )}
                    </>
                )}
                <div className={modalBodyClass}>
                    <div className="custom-scroll">{children}</div>
                    {showActionsToolbar && (
                        <ul className="list-inline action-btns">
                            {onNegativeCb && (
                                <li>
                                    <button onClick={onNegativeCb} className="cancel-btn">
                                        {negativeLabel || 'Cancel'}
                                    </button>
                                </li>
                            )}
                            <li>
                                <button onClick={onPositiveCb} className="save-btn">
                                    {poisitiveLabel || 'Save'}
                                </button>
                            </li>
                        </ul>
                    )}
                    {customActionToolbar && <>{customToolbar}</>}
                </div>
            </ModalWrapper>
        </Modal>
    );
};
ModalPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onAfterOpen: PropTypes.func,
    onAfterClose: PropTypes.func,
    onRequestClose: PropTypes.func,
    customStyle: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
    contentLabel: PropTypes.string,
    showActionsToolbar: PropTypes.bool,
    onPositiveCb: PropTypes.func,
    onNegativeCb: PropTypes.func,
    negativeLabel: PropTypes.string,
    modalClass: PropTypes.string,
    poisitiveLabel: PropTypes.string,
    customToolbar: PropTypes.element,
    customActionToolbar: PropTypes.bool,
    onModelOpen: PropTypes.func,
};

ModalPopup.defaultProps = {
    title: '',
    onAfterOpen: null,
    onAfterClose: null,
    onRequestClose: null,
    customStyle: {},
    contentLabel: '',
    showActionsToolbar: false,
    onPositiveCb: null,
    onNegativeCb: null,
    poisitiveLabel: '',
    negativeLabel: '',
    modalClass: '',
    customToolbar: null,
    customActionToolbar: false,
    onModelOpen: null,
};

export default ModalPopup;
