import humps from 'humps';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    isFetching: false,
};

// actions
const createActionName = name => `app/investor/ptc/${name}`;

export const TRANSACTION_GET = createActionName('TRANSACTION_GET');

export const SUMMARY_FETCH_SUCCESS = createActionName('SUMMARY_FETCH_SUCCESS');

export const DOCUMENTS_FETCH_SUCCESS = createActionName('DOCUMENTS_FETCH_SUCCESS');
export const DOCUMENTS_FETCH_FAILED = createActionName('DOCUMENTS_FETCH_FAILED');

export const BID_POST_SUCCESS = createActionName('BID_POST_SUCCESS');
export const BID_EDIT_SUCCESS = createActionName('BID_EDIT_SUCCESS');

export const BID_GET_SUCCESS = createActionName('BID_GET_SUCCESS');
export const BID_GET_FAILED = createActionName('BID_GET_FAILED');

export const PTC_FILES_SUCCESS = createActionName('PTC_FILES_SUCCESS');

export const RESET_DATA = createActionName('RESET_DATA');
export const FETCHING_DATA = createActionName('FETCHING_DATA');

export default function transaction(state = initialState, action) {
    switch (action.type) {
    case TRANSACTION_GET:
        return {
            ...state,
            details: action.data,
        };
    case SUMMARY_FETCH_SUCCESS:
        return {
            ...state,
            summary: action.data,
        };
    case DOCUMENTS_FETCH_SUCCESS:
        return {
            ...state,
            documents: action.data.legal_documents,
        };
    case DOCUMENTS_FETCH_FAILED:
        return {
            ...state,
            documents: {},
        };
    case PTC_FILES_SUCCESS:
        return {
            ...state,
            ptcFiles: action.data,
        };
    case BID_POST_SUCCESS:
        return {
            ...state,
            bid: action.data,
        };
    case BID_EDIT_SUCCESS:
        return {
            ...state,
            bid: action.data,
            isBidUpdated: true,
        };
    case BID_GET_SUCCESS:
        return {
            ...state,
            bid: action.data,
        };
    case BID_GET_FAILED:
        return {
            ...state,
            bid: {},
        };
    case FETCHING_DATA:
        return {
            ...state,
            isFetching: action.status,
        };
    case RESET_DATA:
        return {
            ...initialState,
        };
    default:
        return state;
    }
}

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function transactionGet(data) {
    return {
        type: TRANSACTION_GET,
        data,
    };
}

export function resetData() {
    return {
        type: RESET_DATA,
    };
}

const unMapPTC = (data) => {
    const cdata = humps.camelizeKeys(data);
    return {
        ...cdata,
        poolSelectionCriteria: {
            ...cdata.poolSelectionCriteria,
            cutoffDate: moment(cdata.poolSelectionCriteria.cutoffDate).format('DD MMM, YYYY'),
        },
    };
};

export function getPTC(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`)
            .then(d => unMapPTC(d))
            .then(d => dispatch(transactionGet(d)))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }));
}

export function getPTCSummary(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/transaction_summary`)
            .then(d =>
                (d && d.transaction_summary && humps.camelizeKeys(d.transaction_summary)) || {})
            .then(d => dispatch({ type: SUMMARY_FETCH_SUCCESS, data: d }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving securitizations data', m);
                }));
}

export function loadDocuments(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/legal_documents`)
            .then((d) => {
                dispatch({ type: DOCUMENTS_FETCH_SUCCESS, data: d, transId: id });
                return d;
            })
            .catch(() => dispatch({ type: DOCUMENTS_FETCH_FAILED }));
}

const mapBid = (bid) => {
    const mbid = {
        ...bid,
        expirationDate: moment(bid.expirationDate).format('YYYY-MM-DD'),
        preferredSettlementDate: moment(bid.preferredSettlementDate).format('YYYY-MM-DD'),
    };
    return { securitization_transaction_bid: humps.decamelizeKeys(mbid) };
};

const unmapBid = (bid) => {
    const umap = humps.camelizeKeys(bid);
    return {
        ...umap,
        expirationDate: moment(umap.expirationDate).format('DD MMM, YYYY'),
        preferredSettlementDate: moment(umap.preferredSettlementDate).format('DD MMM, YYYY'),
    };
};

export function postBid(id, bid) {
    return dispatch =>
        fetchUtils
            .postJSON(
                `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/bids`,
                mapBid(bid),
            )
            .then(d => unmapBid(d))
            .then(d => dispatch({ type: BID_POST_SUCCESS, data: d }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error posting bid', m);
                }));
}

export function editBid(id, bid) {
    return dispatch =>
        fetchUtils
            .putJSON(
                `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/bids/${bid.id}`,
                mapBid(bid),
            )
            .then(d => unmapBid(d))
            .then(d => dispatch({ type: BID_EDIT_SUCCESS, data: d }))
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error posting bid', m);
                }));
}

export function fetchBid(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/bids?actor=investor`)
            .then((d) => {
                if (d.length > 0) {
                    return unmapBid(d[0]);
                }
                throw new Error("Couldn't able to load bid");
            })
            .then(d => dispatch({ type: BID_GET_SUCCESS, data: d }))
            .catch(() => dispatch({ type: BID_GET_FAILED }));
}

export function getBid(transId, bidId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${
                process.env.REACT_APP_MP_API_HOST
            }/securitizations/${transId}/bids/${bidId}?actor=investor`)
            .then(d => dispatch({ type: BID_GET_SUCCESS, data: unmapBid(d) }))
            .catch(() => dispatch({ type: BID_GET_FAILED }));
}

export function getPTCFiles(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/securitization_files`)
            .then(d => humps.camelizeKeys(d))
            .then(d =>
                dispatch({
                    type: PTC_FILES_SUCCESS,
                    data: d.securitizationFiles,
                }))
            .catch(ex =>
                fetchUtils.handleError(ex).then((m) => {
                    toastr.error('Error retrieving securitization files', m);
                }));
}
