import humps from 'humps';

import { toastr } from 'react-redux-toastr';

import * as ampTypes from 'app/constants/AmplitudeActions';

import { createLoadingSelector } from '../reducers/loading';
import * as fetchUtils from '../utils/FetchUtils';
import * as StringUtils from '../utils/StringUtils';
import { EventTypes } from '../utils/reduxAmplitude';

const initialState = {
    isFetching: false,
};

// actions
const createActionName = (name) => `app/task/${name}`;

export const FETCHING_DATA = createActionName('FETCHING_DATA');

export const FILE_UPLOAD_SUCCESS = createActionName('FILE_UPLOAD_SUCCESS');
export const FILE_DESTROY = createActionName('FILE_DESTROY');

export const TASK_FETCH_SUCCESS = createActionName('TASK_FETCH_SUCCESS');
export const TASK_SUBMIT_SUCCESS = createActionName('TASK_SUBMIT_SUCCESS');

export const DEPENDENT_TASKS_FETCH_REQUEST = createActionName('DEPENDENT_TASKS_FETCH_REQUEST');
export const DEPENDENT_TASKS_FETCH_SUCCESS = createActionName('DEPENDENT_TASKS_FETCH_SUCCESS');

export const TASK_REPORTER_FETCH_SUCCESS = createActionName('TASK_REPORTER_FETCH_SUCCESS');

const getTaskGroup = (tasks) => {
    const group = [];
    if (tasks) {
        tasks.forEach((tsk) => {
            const tg = group.find((g) => g.name === tsk.group);
            if (!tg && tsk.group) {
                const openTask = tasks.find((g) => g.group === tsk.group && g.status === 'open');
                const avlTask = tasks.find((g) => g.group === tsk.group && g.isAvailable === true);
                const status = openTask ? 'open' : 'completed';
                const isAvailable = !!avlTask;
                const isEnabled = tasks.filter((g) => g.group === tsk.group).every((t) => Array.isArray(t.taskFields));

                group.push({
                    name: tsk.group,
                    alt: StringUtils.replaceSpecialChar(tsk.group),
                    status,
                    isAvailable,
                    isEnabled,
                });
            }
        });
    }
    return group;
};

export default function task(state = initialState, action) {
    switch (action.type) {
        case FETCHING_DATA:
            return {
                ...state,
                isFetching: action.status,
            };
        case TASK_FETCH_SUCCESS:
            return {
                ...state,
                tasks: action.data,
                groups: getTaskGroup(action.data),
            };
        case TASK_SUBMIT_SUCCESS: {
            const tsks = (state.tasks && state.tasks.map((obj) => [action.data].find((o) => o.id === obj.id) || obj)) || [];
            return {
                ...state,
                tasks: tsks,
                groups: getTaskGroup(tsks),
            };
        }
        case DEPENDENT_TASKS_FETCH_REQUEST:
            return {
                ...state,
                dependentData: null,
            };
        case DEPENDENT_TASKS_FETCH_SUCCESS:
            return {
                ...state,
                dependentData: action.data,
            };
        case TASK_REPORTER_FETCH_SUCCESS:
            return {
                ...state,
                tasksReport: action.data.tasksReport,
            };
        default:
            return state;
    }
}

// selectors
export const selectorDependentTasksLoading = createLoadingSelector([createActionName('DEPENDENT_TASKS_FETCH')]);

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function destroyTaskFile(fileId) {
    return (dispatch) =>
        fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}`)
            .then(() => dispatch({ type: FILE_DESTROY, id: fileId }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing pool file', m);
                });
            });
}

export function uploadTaskFile(file, fileType) {
    return (dispatch) => {
        const body = new FormData();
        body.append('transaction_file[document]', file);
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch({ type: FILE_UPLOAD_SUCCESS, data: d });
                return d;
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                });
            });
    };
}

const unMapTask = (data) => humps.camelizeKeys(data);

const unMapTasks = (data) => {
    const cdata = humps.camelizeKeys(data);
    return cdata.map((d) => unMapTask(d));
};

const mapTask = (data) => {
    const fieldValues = [];
    Object.keys(data).forEach((f) => {
        // FIXME: hack to support file type task field value between set and get
        const val = Array.isArray(data[f]) ? data[f].map((v) => v.id) : data[f];
        //
        fieldValues.push({
            name: f,
            value: val,
        });
    });
    const payload = {
        task: {
            task_fields: fieldValues,
        },
    };
    return humps.decamelizeKeys(payload);
};

export function getTasks(id, cp) {
    return (dispatch) => {
        const decamActor = cp && humps.decamelize(humps.camelize(cp));
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/tasks${cp ? `?counter_party_type=${decamActor}` : ''}`)
            .then((d) => unMapTasks(d))
            .then((d) => dispatch({ type: TASK_FETCH_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving task information', m);
                });
            });
    };
}

export function submitTask(id, tid, data) {
    return (dispatch) =>
        fetchUtils
            .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/tasks/${tid}`, mapTask(data))
            .then((d) => unMapTask(d))
            .then((d) => {
                toastr.success('Success!', `${d.label} submitted successfully.`);
                return dispatch({ type: TASK_SUBMIT_SUCCESS, data: d });
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error submitting task', m);
                });
                return Promise.reject(ex);
            });
}

export function getDependentTasks(id, tids) {
    return (dispatch) => {
        dispatch({ type: DEPENDENT_TASKS_FETCH_REQUEST });
        return fetchUtils
            .getJSON(
                `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/tasks/dependent_data?tasks[]=${(tids && tids.join('&tasks[]=')) || ''}`,
            )
            .then((d) => humps.camelizeKeys(d))
            .then((d) => d.dependentData || [])
            .then((d) => dispatch({ type: DEPENDENT_TASKS_FETCH_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving dependent tasks', m);
                });
            });
    };
}

// Get Task Reporter
export function getTaskReporter(id, cp) {
    return (dispatch) => {
        const decamActor = cp && humps.decamelize(humps.camelize(cp));
        return fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/tasks_report${cp ? `?counter_party_type=${decamActor}` : ''}`)
            .then((d) => unMapTask(d))
            .then((d) => dispatch({ type: TASK_REPORTER_FETCH_SUCCESS, data: d }))
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving task information', m);
                });
            });
    };
}
