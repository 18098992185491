import React from 'react';
import { Formik, Form, Field } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { findAttribValue } from 'app/utils/CommonUtils';
import { renderTextFieldV1, SelectField, renderNumberFieldV1 } from '../../utils/FormikUtils';
import { INVESTOR_TYPES, INVESTOR_INVESTMENT_SURPLUS, BLACK_LISTED_EMAIL_SUBDOMAINS } from '../../constants/Constants';
import { InvOnboardFormInner, BtnBlock } from './styled';
import ParsedLink from 'app/components/ParsedLink';
import { CLIENT_SIGNUP } from 'app/constants/Routes';

const SITE_KEY = `${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
const EMAIL_REGEXP = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; //eslint-disable-line

interface IinvOnboardForm {
    onSubmit: any;
}

const validateEmail = (email: string) => {
    if (EMAIL_REGEXP.test(email)) return true;
    return false;
};

const validateCode = (code: string) => {
    if (/^(\+?\d{1,3}|\d{1,4})$/.test(code)) return true;
    return false;
};

const validateString = (str: string) => {
    const strRegex = new RegExp(/^[a-zA-Z\s]*$/);
    return strRegex.test(str);
};

const validateStringOrAlpha = (companyName: string) => {
    const alphanumeric = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9\s]+$/);
    if (validateString(companyName) || alphanumeric.test(companyName)) {
        return true;
    }
    return false;
};

const validation = (values: any) => {
    const errors: any = {};
    if (!values.investorType) {
        errors.investorType = 'Required';
    }
    if (values.investorType !== findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value')) {
        if (!values.contactName) {
            errors.contactName = 'Required';
        } else if (!validateString(values.contactName)) {
            errors.contactName = 'Invalid name, name can only contain alphabets';
        }
        if (!values.jobTitle) {
            errors.jobTitle = 'Required';
        } else if (!validateStringOrAlpha(values.jobTitle)) {
            errors.jobTitle = 'Invalid job title, job title can only be alphabetic or alphanumeric';
        }
        if (values.contactName && values.contactName.length > 80) {
            errors.contactName = 'Contact Name cannot be greater than 80 characters';
        }
        if (values.jobTitle && values.jobTitle.length > 80) {
            errors.jobTitle = 'Job title cannot be greater than 80 characters';
        }
    }
    if (values.investorType === findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value')) {
        if (!values.investmentSurplus) {
            errors.investmentSurplus = 'Required';
        }
    }
    if (!values.name) {
        errors.name = 'Required';
    }
    if (values.name) {
        if (values.investorType === findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value') && !validateString(values.name)) {
            errors.name = 'Invalid name, name can only contain alphabets';
        } else if (!validateStringOrAlpha(values.name)) {
            errors.name = 'Invalid company name, company name can only be alphabetic or alphanumeric';
        }

        if (values.name.length > 80) {
            errors.name = 'Name cant be greater than 80 characters';
        }
    }
    if (!values.email) {
        errors.email = 'Required';
    }
    if (values.email && !validateEmail(values.email)) {
        errors.email = 'Invalid Email';
    }
    if (values.email && values.investorType !== findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value')) {
        const { email } = values;
        const invalidEmail = BLACK_LISTED_EMAIL_SUBDOMAINS.some(
            (d: string) => email.match(new RegExp(d)) || email.match(new RegExp(d.toUpperCase())),
        );
        if (invalidEmail) errors.email = 'Please enter valid business email. (Note: Public domains are not accepted)';
    }
    if (!values.code) {
        errors.code = 'Required';
    }
    if (values.code && !validateCode(values.code)) {
        errors.code = 'Invalid Code';
    }
    if (!values.phone) {
        errors.phone = 'Required';
    }
    if (values.code && values.code.length > 3) {
        errors.code = 'Code cant be greater than 3';
    }
    if (values.phone && (values.phone.toString().length < 8 || values.phone.toString().length > 10)) {
        errors.phone = 'Invalid Phone Number';
    }
    if (!(process.env.REACT_APP_ENV === 'qa' || process.env.REACT_APP_ENV === 'staging') && !values.recaptcha) {
        errors.recaptcha = 'Submit Captcha to prove you are not a robot ';
    }
    return errors;
};

const InvOnboardForm: React.FC<IinvOnboardForm> = (props) => {
    const { onSubmit } = props;

    return (
        <InvOnboardFormInner className="adjacent-wrapper">
            <ParsedLink className="signup_btn" to={CLIENT_SIGNUP} newTab>
                Signup as a borrower
            </ParsedLink>

            <h1>Start Investing</h1>
            <p>Fill the form & our team will get back to you</p>
            <Formik
                initialValues={{
                    investorType: '',
                    contactName: '',
                    jobTitle: '',
                    name: '',
                    email: '',
                    code: '',
                    phone: '',
                    recaptcha: '',
                    investmentSurplus: '',
                }}
                enableReinitialize
                validate={validation}
                onSubmit={(values: any, { setSubmitting }) => {
                    onSubmit(values, setSubmitting);
                }}
            >
                {({ values, isSubmitting, errors, touched, setFieldValue }: any) => (
                    <Form>
                        <div className="span12">
                            <Field
                                name="investorType"
                                component={SelectField}
                                options={INVESTOR_TYPES}
                                containerClassName="form-group"
                                label="Type of Investor"
                                selectOnlyValue
                            />
                        </div>
                        {values.investorType !== findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value') && (
                            <React.Fragment>
                                <div className="span12">
                                    <Field
                                        name="contactName"
                                        component={renderTextFieldV1}
                                        label="Contact Name"
                                        containerClassName="form-group"
                                        fieldClass="form-group"
                                    />
                                </div>
                                <div className="span12">
                                    <Field
                                        name="jobTitle"
                                        component={renderTextFieldV1}
                                        label="Job Title"
                                        inputClass="form-control"
                                        fieldClass="form-group"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="span12">
                            <Field
                                name="name"
                                component={renderTextFieldV1}
                                label={
                                    values.investorType === findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value') ? 'Name' : 'Company Name'
                                }
                                inputClass="form-control"
                                fieldClass="form-group"
                            />
                        </div>
                        <div className="span12">
                            <Field
                                name="email"
                                component={renderTextFieldV1}
                                label={
                                    values.investorType === findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value') ? 'Email' : 'Company Email'
                                }
                                inputClass="form-control"
                                fieldClass="form-group"
                            />
                        </div>
                        {values.investorType === findAttribValue(INVESTOR_TYPES, 'key', 'individual', 'value') && (
                            <div className="span12">
                                <Field
                                    name="investmentSurplus"
                                    component={SelectField}
                                    options={INVESTOR_INVESTMENT_SURPLUS}
                                    containerClassName="form-group"
                                    label="Investment Surplus"
                                    selectOnlyValue
                                />
                            </div>
                        )}

                        <div className="span3 phone-code">
                            <Field
                                name="code"
                                component={renderTextFieldV1}
                                placeholder="+91"
                                label="Code"
                                inputClass="form-control"
                                fieldClass="form-group-code"
                            />
                        </div>
                        <div className="span9">
                            <Field
                                name="phone"
                                component={renderNumberFieldV1}
                                placeholder="Phone"
                                label="Phone"
                                inputClass="form-control"
                                fieldClass="form-group-phone"
                            />
                        </div>
                        <div className="span10">
                            <ReCAPTCHA
                                sitekey={SITE_KEY}
                                onChange={(response: any) => {
                                    setFieldValue('recaptcha', response);
                                }}
                            />
                            {errors.recaptcha && touched.recaptcha && (
                                <div className="form-group error">
                                    <span className="error-text">{errors.recaptcha}</span>
                                </div>
                            )}
                        </div>
                        <div className="span12">
                            <BtnBlock type="submit" className="red-colored-btn">
                                {isSubmitting ? 'Submitting' : 'Submit'}
                            </BtnBlock>
                        </div>
                    </Form>
                )}
            </Formik>
        </InvOnboardFormInner>
    );
};

export default InvOnboardForm;
