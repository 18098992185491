import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'app/assets/css/toastr.css';
import 'app/index.css';
import 'app/assets/css/sign-in.css';
import 'app/assets/css/font-icons.css';
import { ApolloProvider } from '@apollo/client';
import client from 'app/utils/graphql';
import amplitude from 'amplitude-js';
import App from 'app/containers/App';
import loadThirdPartyServices from 'app/thirdpartyservices'; //eslint-disable-line
import configureStore from 'app/store/configureStore';
import history from 'app/utils/myHistory';
import NotificationProvider from 'app/components/Notifications/NotificationProvider';
import AmplitudeProvider from 'app/components/Amplitude/AmplitudeProvider';
import { getActiveUserId } from 'app/actions/AuthedActions';
import PublicRoutesWrapper from './PublicRoutesWrapper';
import YubiDomainResolver from 'app/components/YubiDomainResolver';
import { getEnv } from 'app/utils/FetchUtils';
import '@yubi/yb-module-auth/package/module-assets/icons/style.css';
import Auth, { getProfile } from 'app/utils/Auth';
import StyleProvider from '@yubi/yb-style-provider';
import WebEngageProvider from '@yubi/yb-module-webengage-provider';
import CustomRouter from 'app/components/Router/CustomRouter';
const user = getProfile();
const entityDetails = user?.allEntities?.find((entityData) => entityData?.entityId === user?.entityId);

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_V2_API_KEY;
const store = configureStore(history);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <NotificationProvider>
            <ApolloProvider client={client}>
                <WebEngageProvider
                    userProperties={{
                        entityId: user?.entityId,
                        entityName: entityDetails?.companyName,
                        currentGroup: Auth.getActiveActor(),
                        currentRole: Auth.getActiveUserGroup()?.role,
                        currentSubGroup: Auth.getActiveSubGroup(),
                        userEmail: user?.email,
                        userId: user?.id,
                        userName: user?.name,
                    }}
                >
                    <AmplitudeProvider
                        amplitudeInstance={amplitude.getInstance()}
                        apiKey={AMPLITUDE_KEY}
                        userId={getActiveUserId()}
                        config={{
                            apiEndpoint: getEnv(process.env.REACT_APP_AMPLITUDE_API_ENDPOINT),
                            saveEvents: true,
                            includeUtm: true,
                            includeReferrer: true,
                        }}
                    >
                        <CustomRouter history={history}>
                            <YubiDomainResolver>
                            <PublicRoutesWrapper history={history}>
                                <StyleProvider baseUrl={process.env.REACT_APP_PHOENIX_TOKENS_API_BASE_URL}>
                                    <App />
                                </StyleProvider>
                            </PublicRoutesWrapper>
                            <ReduxToastr timeOut={4000} newestOnTop={false} preventDuplicates position="top-right" progressBar={false} />
                            </YubiDomainResolver>
                        </CustomRouter>
                    </AmplitudeProvider>
                </WebEngageProvider>
            </ApolloProvider>
        </NotificationProvider>
    </Provider>,
);
