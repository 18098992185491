import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { CSSTransition } from 'react-transition-group';
import { renderField } from 'app/utils/ReduxFormUtils';
import { deriveErrorMsg } from 'app/utils/CommonUtils';
import Alert from '../Alert';
import Auth from 'app/utils/Auth';
import PasswordErrorStateComponent, { PasswordErrorProgressComponent } from '../PasswordStrenghtIndicator';

export const validate = (values) => {
    const errors = {};
    if (!values.oldPassword) {
        errors.oldPassword = 'Required';
    }
    if (!values.password) {
        errors.password = 'Required';
    } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
        errors.password = "Password and its confirmation doesn't match";
    }
    return errors;
};

const ChangePasswordForm = (props) => {
    const { handleSubmit, submitting, errorMsg, passwordChanged } = props;
    const [errors, setErrors] = useState({
        isPasswordLength: 0,
        isLowercase: 0,
        isUpperCase: 0,
        isDigit: 0,
        isSpecialChar: 0,
    });
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [errPopup, setErrPopup] = useState(false);

    const updatePasswordStrength = (err) => {
        const pwdStrength = Object.values(err).filter((v) => v === 2).length;
        const strengthError = pwdStrength < 5;
        setPasswordStrength(pwdStrength);
        setErrPopup(strengthError);
    };

    const handlePasswordChange = (password) => {
        const error = {
            isPasswordLength: password.length < 8 ? 1 : 2,
            isLowercase: !/[a-z]/g.test(password) ? 1 : 2,
            isUpperCase: !/[A-Z]/g.test(password) ? 1 : 2,
            isDigit: !/[0-9]/g.test(password) ? 1 : 2,
            isSpecialChar: !/[!@#$%^&*]+/.test(password) ? 1 : 2,
        };
        setErrors(error);
        updatePasswordStrength(error);
    };

    const derivedErrorMsg = useMemo(() => {
        return deriveErrorMsg(errorMsg);
    }, [errorMsg]);

    return (
        <div className="login">
            <h4>Change Password</h4>
            {typeof passwordChanged === 'undefined' ? (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                        <Field
                            onFocus={() => setErrPopup(false)}
                            name="oldPassword"
                            type="password"
                            className="form-control"
                            component={renderField}
                            label="Current Password"
                            containerClassName="frm-grp"
                        />
                        <i className="icon-lock" />
                    </div>
                    <div className="form-group">
                        <Field
                            onChangeAction={handlePasswordChange}
                            onFocus={() => setErrPopup(true)}
                            name="password"
                            type="password"
                            className="form-control"
                            component={renderField}
                            label="New Password"
                            containerClassName="frm-grp"
                        />
                        <i className="icon-lock" />
                    </div>
                    <div className="form-group">
                        <Field
                            name="confirmPassword"
                            type="password"
                            className="form-control"
                            component={renderField}
                            label="Confirm New Password"
                            containerClassName="frm-grp"
                        />
                        <i className="icon-lock" />
                    </div>
                    {errorMsg && (
                        <p className="text-center">
                            <Alert type="danger" title="Alert!" msg={derivedErrorMsg} />
                        </p>
                    )}
                    <ul className="actions">
                        <li className="actions-submit">
                            <button className="btn blu-nw pull-right violet-nw" disabled={submitting} type="submit">
                                {submitting ? ' Submitting' : 'Submit'}
                            </button>
                        </li>
                    </ul>
                    <PasswordErrorProgressComponent passwordStrength={passwordStrength} />
                </form>
            ) : (
                <div>
                    <p className="text-center">
                        <Alert type="info" title="New Password!" msg="Your new password has been updated successfully" />
                    </p>
                    <div>
                        <Link to={window.location.origin}>
                            <button onClick={() => Auth.logout()} className="btn blu-nw pull-right violet-nw">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            )}

            {errPopup && (
                <CSSTransition classNames="password-info" timeout={{ exit: 300, enter: 500 }}>
                    <PasswordErrorStateComponent errors={errors} onErrPopupClose={() => setErrPopup(false)} />
                </CSSTransition>
            )}
        </div>
    );
};

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const reduxFormDecorator = reduxForm({
    form: 'ChangePasswordForm',
    validate,
});

const mapStateToProps = (state) => ({
    isLoggedIn: state.authed.accessToken,
    errorMsg: state.authed.authErrMsg,
    passwordChanged: state.authed.passwordChanged,
});

const reduxConnector = connect(mapStateToProps);

export default reduxConnector(reduxFormDecorator(ChangePasswordForm));
