import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import Tabs from 'app/components/Tabs';
import Tab from 'app/components/Tab';
import Table from 'app/components/Table/Table';
import { Row, Span6, Span12, Span5, Span7 } from 'app/styled/CommonStyles';
import Spinner from 'app/components/Spinner';
import { BarChart, LineBarChart, StepsBarChart, StackedBarChart } from 'app/components/Charts';
import { Styled } from './styled';
import Chart from 'app/products/UIComponents/Chart';
import { CHART_TYPE, BAR_COLOR } from 'app/products/UIComponents/Chart/Constants';
import { CHART_WIDTH, CHART_HEIGHT } from 'app/products/Dashboards/Constants';
import { CHART_COLORS } from 'app/products/UIComponents/DealDetails/PoolOverview';
import { getSortOrder } from 'app/products/Dashboards/Utils';

export interface StateProps {
    data: any;
    loading: boolean;
    error: string;
}

export interface DispatchProps {
    getDealPerf: (id: string, type: string) => any;
}

export interface ReceivedProps {
    transId: string;
    transType: string;
}

export enum PAR_TYPE {
    par0 = 'par0',
    par30 = 'par30',
    par90 = 'par90',
}

export const PAR_OPTIONS: {
    label: string;
    shortLabel: string;
    value: PAR_TYPE;
}[] = [
    {
        label: 'Par0/CE%',
        shortLabel: 'Par 0',
        value: PAR_TYPE.par0,
    },
    {
        label: 'Par30/CE%',
        shortLabel: 'Par 30',
        value: PAR_TYPE.par30,
    },
    {
        label: 'Par90/CE%',
        shortLabel: 'Par 90',
        value: PAR_TYPE.par90,
    },
];

type AllProps = StateProps & DispatchProps & ReceivedProps;

const ParCell = (props: any) => {
    return (
        <td>
            <p>
                <span>
                    {_get(props, 'label') === 0 ? '-' : _get(props, 'label', '')}
                    {_get(props, 'index') !== 0 && _get(props, 'label') !== 0 && `%`}
                </span>
                {_get(props, 'status') === 'gain' && <i className="icon-caret-arrow-up" />}
                {_get(props, 'status') === 'loss' && <i className="icon-sort-down" />}
            </p>
        </td>
    );
};

const MatrixCell = (props: any) => {
    const { label, index } = props;
    return (
        <td>
            <p>{index === 0 ? label : label > 0 ? <b>{label}%</b> : `${label}%`}</p>
        </td>
    );
};

const Summary: React.FC<{ data: any }> = ({ data }) => {
    return (
        <Styled.Summary>
            <div>
                <ul>
                    <li>
                        <span>{_get(data, 'initialPoolPrincipal')}</span>
                        <p>Initial Pool Principal</p>
                    </li>
                    <li>
                        <span>{_get(data, 'osPoolPrincipal')}</span>
                        <p>O/s Pool Principal</p>
                    </li>
                    {_get(data, 'trusteeReportMonth') !== '-' && (
                        <li>
                            <span>{_get(data, 'trusteeReportMonth')}</span>
                            <p>Payout Date</p>
                        </li>
                    )}
                    <li>
                        <span>{_get(data, 'cumulativeCe')}</span>
                        <p>Cumulative Collection Efficiency</p>
                    </li>
                    <li>
                        <span>{_get(data, 'periodwiseCe')}</span>
                        <p>Period Wise Collection Efficiency</p>
                    </li>
                    <li>
                        <span>{_get(data, 'threeMonthPeriodCe')}</span>
                        <p>3 Month Period Wise Collection Efficiency</p>
                    </li>
                    <li>
                        <span>{_get(data, 'breakevenCe')}</span>
                        <p>Breakeven Collection Efficiency</p>
                    </li>
                    <li>
                        <span>{_get(data, 'amortization')}</span>
                        <p>Amortization</p>
                    </li>
                </ul>
            </div>
        </Styled.Summary>
    );
};

const DealPerf: React.FC<AllProps> = ({ data = {}, loading = false, error, getDealPerf, transId, transType }) => {
    const [par, setPar] = useState<PAR_TYPE>(PAR_TYPE.par0);
    useEffect(() => {
        getDealPerf(transId, transType).catch((err: any) => console.log(err));
    }, []);
    if (_isEmpty(data) && loading) {
        return (
            <Styled.DefaultMessage>
                <Spinner />
                <p>Fetching deal performance...</p>
            </Styled.DefaultMessage>
        );
    }
    if (_isEmpty(data) && !loading && error) {
        return (
            <Styled.DefaultMessage>
                <h1>Oops, some error occurred!</h1>
                <p>{error}</p>
            </Styled.DefaultMessage>
        );
    }
    if (_isEmpty(data) && !loading) {
        return (
            <Styled.DefaultMessage>
                <h1>Sorry!</h1>
                <p>No deal performance data available!</p>
            </Styled.DefaultMessage>
        );
    }
    const stateData: Array<any> = (_get(data, 'geographyWiseCollectionsReport.stateName') || [])
        ?.sort(getSortOrder('percentage'))
        ?.slice(0, 5)
        ?.map((state: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: state.value,
            value: state.percentage,
        }));
    const districtData: Array<any> = (_get(data, 'geographyWiseCollectionsReport.districtName') || [])
        ?.sort(getSortOrder('percentage'))
        ?.slice(0, 5)
        ?.map((district: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: district.value,
            value: district.percentage,
        }));
    const branchData: Array<any> = (_get(data, 'geographyWiseCollectionsReport.branchName') || [])
        ?.sort(getSortOrder('percentage'))
        ?.slice(0, 5)
        ?.map((branch: any, index: number) => ({
            colors: CHART_COLORS[index % CHART_COLORS.length],
            name: branch.value,
            value: branch.percentage,
        }));

    return (
        <Styled.Container>
            <Row>
                <Span12>
                    <Styled.Title>Deal Collection Efficiency</Styled.Title>
                    <Summary data={_get(data, 'dealPerformanceSummary', {})} />
                </Span12>
            </Row>
            <Row>
                <Span12>
                    <Styled.Title>Payout Details - Pool</Styled.Title>
                    <Styled.Table>
                        <Table columns={_get(data, 'payoutTableData.columns', [])} data={_get(data, 'payoutTableData.data', [])} />
                    </Styled.Table>
                </Span12>
            </Row>
            <Row>
                <Span6>
                    <Styled.ChartWrapper>
                        <Styled.Title>Collection Efficiency Trends</Styled.Title>
                        <Tabs defaultIndex={1}>
                            <Tab label={<i className="icon-table" />}>
                                <Styled.Table>
                                    <Table
                                        columns={_get(data, 'collEffTrendsTableData.columns', [])}
                                        data={_get(data, 'collEffTrendsTableData.data', [])}
                                    />
                                </Styled.Table>
                            </Tab>
                            <Tab label={<i className="icon-chart" />}>
                                <div className="chart-wrap-box coll-eff-graph">
                                    <LineBarChart
                                        data={_get(data, 'collectionEfficiencyTrends', [])}
                                        leftYAxisLabel="Cumulative Collection Efficiency"
                                        leftYAxisUnit="%"
                                        xName="CE (%)"
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </Styled.ChartWrapper>
                </Span6>
                <Span6>
                    <Styled.ChartWrapper>
                        <Styled.Title>Prepayment Trends</Styled.Title>
                        <Tabs defaultIndex={1}>
                            <Tab label={<i className="icon-table" />}>
                                <Styled.Table>
                                    <Table
                                        columns={_get(data, 'prepaymentTrendsTableData.columns', [])}
                                        data={_get(data, 'prepaymentTrendsTableData.data', [])}
                                    />
                                </Styled.Table>
                            </Tab>
                            <Tab label={<i className="icon-chart" />}>
                                <div className="chart-wrap-box">
                                    <LineBarChart
                                        data={_get(data, 'prepaymentTrends', [])}
                                        leftYAxisLabel="Prepayment Amount"
                                        rightYAxisLabel="CPR"
                                        leftYAxisUnit="Cr(s)"
                                        rightYAxisUnit="%"
                                        xName="Prepayment Amount (Crs)"
                                        yName="CPR (%)"
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </Styled.ChartWrapper>
                </Span6>
            </Row>
            <Row>
                <Span7>
                    <Styled.Title>Delinquency Vs Loss Protection</Styled.Title>
                    <Row className="delinquency-loss-inner">
                        <Span6>
                            <div className="chart-pos-mover del-los-graph-mover">
                                <Styled.ChartWrapper>
                                    <select onChange={(e: any) => setPar(e.target.value)}>
                                        {PAR_OPTIONS.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                    <BarChart
                                        data={_get(data, `${par}`, [])}
                                        xName={`${_get(_find(PAR_OPTIONS, { value: par }), 'shortLabel', 'Par 0')} by CE`}
                                        yName={_get(_find(PAR_OPTIONS, { value: par }), 'shortLabel', 'Par 0')}
                                        width={350}
                                        margin={{
                                            top: 36,
                                            right: 0,
                                            left: 5,
                                            bottom: 5,
                                        }}
                                        yAxisUnit="%"
                                    />
                                </Styled.ChartWrapper>
                            </div>
                        </Span6>
                        <Span6>
                            <Styled.Table>
                                <Table
                                    columns={_get(data, 'parTableData.columns', [])}
                                    data={_get(data, 'parTableData.data', [])}
                                    customCell={<ParCell />}
                                    containerClass="table-container del-loss-table"
                                />
                            </Styled.Table>
                        </Span6>
                    </Row>
                </Span7>
                <Span5>
                    <Styled.Title>Rating Upgrade Calendar (Expected)</Styled.Title>
                    <div className="chart-pos-mover rating-upgrade-chart">
                        <StepsBarChart
                            data={_get(data, 'ratingUpgrade', [])}
                            margin={{ top: 30, bottom: 5 }}
                            xName="Rating"
                            yName="CE"
                            tick={false}
                            tickLine={false}
                            yAxisLabel="Credit Enhancement %"
                        />
                    </div>
                </Span5>
            </Row>
            <Row>
                <Span6>
                    <Styled.ChartWrapper>
                        <Styled.Title>Loss Protection in Pool-Credit Enhancement Build Up</Styled.Title>
                        {_get(data, 'creditEnhancementTableData.data', []).length > 0 ? (
                            <Tabs defaultIndex={1}>
                                <Tab label={<i className="icon-table" />}>
                                    <Styled.Table>
                                        <Table
                                            containerClass="ce-table"
                                            columns={_get(data, 'creditEnhancementTableData.columns', [])}
                                            data={_get(data, 'creditEnhancementTableData.data', [])}
                                        />
                                    </Styled.Table>
                                </Tab>
                                <Tab label={<i className="icon-chart" />}>
                                    <div className="chart-wrap-box coll-eff-graph">
                                        <StackedBarChart
                                            data={_get(data, 'creditEnhancement', [])}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: -40,
                                                bottom: 5,
                                            }}
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        ) : (
                            <div className="no-data">
                                <p>Graph/Report not available</p>
                            </div>
                        )}
                    </Styled.ChartWrapper>
                </Span6>
                <Span6>
                    <Styled.Title className="ce-title">Transition matrix(Roll forward/Roll backward rates)</Styled.Title>
                    <Styled.Table>
                        <Table
                            columns={_get(data, 'transitionMatrixTableData.columns', [])}
                            data={_get(data, 'transitionMatrixTableData.data', [])}
                            customCell={<MatrixCell />}
                            containerClass="table-container trans-matrix-table"
                        />
                    </Styled.Table>
                </Span6>
            </Row>
            <Row>
                {stateData.length > 0 && (
                    <Span6>
                        <Styled.ChartWrapper>
                            <Styled.Title> Top 5 States Collection</Styled.Title>
                            <Chart
                                type={CHART_TYPE.PIE}
                                data={stateData}
                                width={CHART_WIDTH}
                                height={CHART_HEIGHT}
                                tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                legend={{
                                    layout: 'vertical',
                                    align: 'right',
                                    verticalAlign: 'middle',
                                    content: '',
                                }}
                                series={[
                                    {
                                        innerRadius: 85,
                                        outerRadius: 116,
                                        dataKey: 'value',
                                        fill: BAR_COLOR,
                                        label: {
                                            value: 'States',
                                            position: 'center',
                                        },
                                        cell: stateData.map((d: any, index: any) => ({
                                            key: `cell-${index}`,
                                            fill: CHART_COLORS[index % CHART_COLORS.length],
                                        })),
                                    },
                                ]}
                            />
                        </Styled.ChartWrapper>
                    </Span6>
                )}
                {districtData.length > 0 && (
                    <Span6>
                        <Styled.ChartWrapper>
                            <Styled.Title> Top 5 Districts Collection</Styled.Title>
                            <Chart
                                type={CHART_TYPE.PIE}
                                data={districtData}
                                width={CHART_WIDTH}
                                height={CHART_HEIGHT}
                                tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                legend={{
                                    layout: 'vertical',
                                    align: 'right',
                                    verticalAlign: 'middle',
                                    content: '',
                                }}
                                series={[
                                    {
                                        innerRadius: 85,
                                        outerRadius: 116,
                                        dataKey: 'value',
                                        fill: BAR_COLOR,
                                        label: {
                                            value: 'Districts',
                                            position: 'center',
                                        },
                                        cell: districtData.map((d: any, index: any) => ({
                                            key: `cell-${index}`,
                                            fill: CHART_COLORS[index % CHART_COLORS.length],
                                        })),
                                    },
                                ]}
                            />
                        </Styled.ChartWrapper>
                    </Span6>
                )}
                {branchData.length > 0 && (
                    <Span6>
                        <Styled.ChartWrapper>
                            <Styled.Title> Top 5 Branches Collection</Styled.Title>
                            <Chart
                                type={CHART_TYPE.PIE}
                                data={branchData}
                                width={CHART_WIDTH}
                                height={CHART_HEIGHT}
                                tooltip={{ formatter: (label: any) => [`${(label || 0).toFixed(2)}%`] }}
                                legend={{
                                    layout: 'vertical',
                                    align: 'right',
                                    verticalAlign: 'middle',
                                    content: '',
                                }}
                                series={[
                                    {
                                        innerRadius: 85,
                                        outerRadius: 116,
                                        dataKey: 'value',
                                        fill: BAR_COLOR,
                                        label: {
                                            value: 'Branches',
                                            position: 'center',
                                        },
                                        cell: branchData.map((d: any, index: any) => ({
                                            key: `cell-${index}`,
                                            fill: CHART_COLORS[index % CHART_COLORS.length],
                                        })),
                                    },
                                ]}
                            />
                        </Styled.ChartWrapper>
                    </Span6>
                )}
            </Row>
        </Styled.Container>
    );
};

export default DealPerf;
