import React from 'react';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import { toastr } from 'react-redux-toastr';
import { PRODUCT_API_ROUTES, TIMEOUT } from 'app/constants/Constants';
import { FILE_STATUS } from 'app/constants/Constants';

import { postJSON, getJSON, postFormData, patchJSON, putJSON, deleteJSON, poll, handleErrorV2, handleError } from 'app/utils/FetchUtils';
import { humanize } from 'app/utils/StringUtils';
import { croresToValues, valuesToCrores } from 'app/utils/CommonUtils';

import { PivotTypes, DAProps } from 'app/products/DA/Constants/da.types';
import { GET_INT_INVESTORS_SUCCESS, intialIntInvestor } from 'app/products/DA/Store/interestedInvestors';
import {
    intialScrubbing,
    LOAN_SCRUBB_EDIT,
    FETCH_SCRUBB_SUCCESS,
    FETCH_SCRUBB_FAILURE,
    LOAN_SCRUBB_REQUEST,
    LOAN_SCRUBB_SUCCESS,
} from 'app/products/DA/Store/scrubbing';
import { getTasks } from 'app/reducers/dav2/transaction/tasks';

import { mapData } from 'app/reducers/ptc/transaction/dealPerformance';
import { INTEREST_TYPE, mapOptions } from 'app/utils/TransactionUtils';
import { getActiveUser } from 'app/actions/AuthedActions';
import {
    TRANSACTION_STATE,
    RELEASE,
    COUNTERPARTY,
    LTV_POOL_TYPES,
    PRODUCT_KEYS,
    NETWORK_CALL_ABORTED,
    PRODUCT_API_UPDATE_KEYS,
} from 'app/constants/Constants';
import { logEvent } from 'app/utils/reduxAmplitude';
import { PRODUCT_RELEASE_CLICK } from 'app/constants/AmplitudeActions';
import { mapValuesGlobal } from 'app/products/Shared/Tools/Pools/PoolStructuring/utils';
import { splitLoanIds } from 'app/actors/admin/ducks/ptcv1/transaction';
import { DAYS_CONVENTION } from 'app/constants/TransactionConstants';
import { PoolProductTypes } from 'app/commonTypes';
const { isProduct, isClient } = getActiveUser();

export const DAContext: any = React.createContext({});

export const initialState: any = {
    structuring: {
        settlementDate: null,
        servicerFee: 500000,
        servicerFeeUnit: 'amount',
        servicerIncentive: 1,
        sharing: {
            assigneeShare: null,
            assignorShare: null,
            assigneeYield: null,
            assigneeAmount: null,
            assignorAmount: null,
            interestRateSharingType: null,
            interestRateMethod: null,
            interestRateType: null,
            isPenalInterestApplicable: null,
            penalInterestRate: null,
            penalInterestAppliedOn: null,
            daysConvention: DAYS_CONVENTION[0].value,
        },
        schedule: {
            payinDate: null,
            payoutDate: null,
            firstCollectionOffset: null,
            principalRepayment: 'expected',
        },
    },
    poolSelectionCriteria: {
        seasoning: true,
        concentrations: {
            branchName: 100,
            stateName: 100,
            districtName: 100,
        },
        loansToReject: [
            {
                ids: null,
                reason: null,
            },
        ],
        par: {
            parFilters: {
                par0: 100,
                par30: 100,
                par60: 100,
                par90: 100,
            },
            rangedParFilters: [
                {
                    from: null,
                    to: null,
                    cutoff: null,
                },
            ],
        },
    },
    scrubbing: intialScrubbing,
    interestedInvestor: intialIntInvestor,
    loading: false,
    autoStructuringSuggestions: {},
    disableSelectionAndStructuring: false,
    cifMessage: null,
};

/**
 * Reducer
 */

const WATERFALL = 'waterfall';
const DUPLICATE_CONTRACTS = 'duplicate_contracts';
const WARNINGS = 'warnings';

export const GET_DA_TRANS = 'GET_DA_TRANS';
const GET_DA_BIDS = 'GET_DA_BIDS';
const UPDATE_DA_BIDS = 'UPDATE_DA_BIDS';
const GET_DA_EXEC_DOCS = 'GET_DA_EXEC_DOCS';
const LOADING_ACTION = 'LOADING_ACTION';
const WATERFALL_UPLOADED = 'WATERFALL_UPLOADED';

export const DEAL_PERF_GET_REQUEST = 'DEAL_PERF_GET_REQUEST';
export const DEAL_PERF_GET_SUCCESS = 'DEAL_PERF_GET_SUCCESS';
export const DEAL_PERF_GET_FAILURE = 'DEAL_PERF_GET_FAILURE';

export const GET_RATING_MODEL_REQUEST = 'GET_RATING_MODEL_REQUEST';
export const GET_RATING_MODEL_SUCCESS = 'GET_RATING_MODEL_SUCCESS';
export const GET_RATING_MODEL_FAILURE = 'GET_RATING_MODEL_FAILURE';

export const POLL_RATING_MODEL_REQUEST = 'POLL_RATING_MODEL_REQUEST';
export const POLL_RATING_MODEL_SUCCESS = 'POLL_RATING_MODEL_SUCCESS';
export const POLL_RATING_MODEL_FAILURE = 'POLL_RATING_MODEL_FAILURE';

export const ADD_ATTACHMENT_REQUEST = 'ADD_ATTACHMENT_REQUEST';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';
export const ADD_ATTACHMENT_FAILURE = 'ADD_ATTACHMENT_FAILURE';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE';
export const CAN_SETTLE_DEAL = 'CAN_SETTLE_DEAL';

export const DISABLE_POOL_SELECTION_AND_STRUCTURING = 'DISABLE_POOL_SELECTION_AND_STRUCTURING';
export const SET_EGBT_CIF_LAN_STATUS = 'SET_EGBT_CIF_LAN_STATUS';
export const SET_BOI_API_FLAGS = 'SET_BOI_API_FLAGS';

export const CAN_ENABLE_CIF = 'CAN_ENABLE_CIF';
export function daReducer(state = initialState, action: any): any {
    switch (action.type) {
        case GET_DA_TRANS: {
            return {
                ...state,
                ...action.data,
                generatedFiles:
                    _size(action.data.poolFiles) > 0
                        ? action.data.poolFiles.filter((f: any) => _size(f.files) > 0 && ![WATERFALL, DUPLICATE_CONTRACTS, WARNINGS].includes(f.type))
                        : [],
                dedupLoans: action.data.poolFiles
                    .filter((f: any) => f.type === 'duplicate_contracts')
                    .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
                warningFile: action.data.poolFiles.filter((f: any) => f.type === 'warnings').reduce((a: any, b: any) => ({ ...a, ...b }), {}),
                pollAgain: _get(action, 'data.pollingInProgress', false) ? false : action.data.filterProcessing,
                extraDocuments: _get(action, 'data.extraDocuments'),
                poolSelectionCriteria: _get(action, 'data.poolSelectionCriteria')
                    ? {
                          ...state.poolSelectionCriteria,
                          ...action.data.poolSelectionCriteria,
                          loansToReject: splitLoanIds(
                              _get(action, 'data.poolSelectionCriteria.loansToReject', initialState.poolSelectionCriteria.loansToReject),
                          ),
                      }
                    : state.poolSelectionCriteria,
            };
        }
        case GET_DA_BIDS: {
            return {
                ...state,
                bids: action.data,
            };
        }
        case UPDATE_DA_BIDS: {
            const bids = state.bids.map((f: any) => {
                if (f.id === action.data.id) {
                    return action.data;
                }
                return f;
            });

            return {
                ...state,
                bids,
            };
        }
        case GET_DA_EXEC_DOCS: {
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        }
        case GET_INT_INVESTORS_SUCCESS: {
            return {
                ...state,
                interestedInvestor: action.data,
            };
        }
        case LOADING_ACTION: {
            return {
                ...state,
                loading: action.loading,
            };
        }
        case WATERFALL_UPLOADED: {
            const poolFilesCopy = [...state.poolFiles];
            const waterfallIndex = poolFilesCopy.findIndex((f: any) => f.type === WATERFALL);
            poolFilesCopy[waterfallIndex].files = [action.data];
            return {
                ...state,
                poolFiles: poolFilesCopy,
                waterfall: state.poolFiles
                    .filter((f: any) => f.type === WATERFALL)
                    .map((m: any) => {
                        return {
                            ...m,
                            files: [action.data],
                        };
                    })
                    .reduce((a: any, b: any) => ({ ...a, ...b }), {}),
            };
        }
        case FETCH_SCRUBB_SUCCESS: {
            const poolState = action.data.poolStates ? action.data.poolStates : [];
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    loading: false,
                    selectedPoolState: poolState,
                    edit: poolState.length > 0,
                    status: action.data.status,
                },
            };
        }
        case FETCH_SCRUBB_FAILURE: {
            return {
                ...state,
                scrubbing: {
                    ...state.scrubbing,
                    selectedPoolState: [],
                    edit: false,
                    loading: false,
                },
            };
        }
        case LOAN_SCRUBB_REQUEST:
            return {
                ...state,
                scrubbing: {
                    edit: true,
                    selectedPoolState: [],
                    status: null,
                },
            };
        case LOAN_SCRUBB_SUCCESS:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, selectedPoolState: action.poolStates },
            };
        case LOAN_SCRUBB_EDIT:
            return {
                ...state,
                scrubbing: { ...state.scrubbing, status: action.status, success: true },
            };
        case DEAL_PERF_GET_SUCCESS: {
            return {
                ...state,
                dealPerformance: { data: action.payload },
            };
        }
        case DEAL_PERF_GET_FAILURE:
            return {
                ...state,
                dealPerformance: { error: action.message },
            };
        case GET_RATING_MODEL_SUCCESS: {
            const { data: { lossEstimationSuggestions = [], lossEstimationPreferences = {} } = {} } = action;
            return {
                ...state,
                lossEstimationSuggestions,
                lossEstimationPreferences,
            };
        }
        case ADD_ATTACHMENT_SUCCESS: {
            const { data: newAttachment } = action;
            let cloneExtraDocs = [..._get(state, 'extraDocuments')];
            const attachmentIndex = cloneExtraDocs.findIndex((d: any) => _get(d, 'files[0].fileId') === _get(newAttachment, 'fileId'));
            if (attachmentIndex >= 0) {
                cloneExtraDocs[attachmentIndex] = { files: [{ ...newAttachment }] };
            } else {
                cloneExtraDocs = [{ files: [{ ...newAttachment }] }, ...cloneExtraDocs];
            }
            return { ...state, extraDocuments: cloneExtraDocs };
        }
        case DELETE_ATTACHMENT_SUCCESS: {
            const { attachmentId } = action;
            const cloneExtraDocs: any = [..._get(state, 'extraDocuments')];
            const attachmentIndex = cloneExtraDocs.findIndex((d: any) => _get(d, 'files[0].fileId') === attachmentId);
            if (attachmentIndex >= 0) cloneExtraDocs.splice(attachmentIndex, 1);
            return { ...state, extraDocuments: cloneExtraDocs };
        }

        case CAN_SETTLE_DEAL: {
            const { check } = action.data;
            return {
                ...state,
                canSettleDeal: check,
                stages: {
                    account_setup_dbs: action?.data?.account_setup_dbs,
                    account_setup_originator: action?.data?.account_setup_originator,
                    cif_creation: action?.data?.cif_creation,
                    cif_creation_rejection: action?.data?.cif_creation_rejection,
                    eligibility_check: action?.data?.eligibility_check,
                    eligibility_check_rejection: action?.data?.eligibility_check_rejection,
                    pool_selection: action?.data?.pool_selection,
                    structuring: action?.data?.structuring,
                },
            };
        }
        case CAN_ENABLE_CIF: {
            const { check } = action.data;
            return {
                ...state,
                canEnableCif: check,
                cifMessage: humps.camelize(action?.data?.message || ''),
            };
        }
        case DISABLE_POOL_SELECTION_AND_STRUCTURING: {
            return {
                ...state,
                disableSelectionAndStructuring: true,
            };
        }
        case SET_EGBT_CIF_LAN_STATUS: {
            return {
                ...state,
                poolSelectionCriteria: {
                    ...state?.poolSelectionCriteria,
                    [action?.data?.key]: action?.data?.value,
                },
            };
        }
        case SET_BOI_API_FLAGS: {
            return {
                ...state,
                boiApiFlags: {
                    ...state?.boiApiFlags,
                    ...action?.value,
                },
            };
        }
        default: {
            return state;
        }
    }
}

/**
 * Actions
 */
export const daBaseUrl = (id: string, type = 'dav2') => `${process.env.REACT_APP_MP_API_HOST}/${PRODUCT_API_ROUTES[type as PoolProductTypes]}/${id}`;

export const DISCRETE_PIVOT = 'discrete';
export const CONTINUOUS_PIVOT = 'continuous';

export function splitPivots(pivots: any, type: PivotTypes): any {
    if (!pivots && !_get(pivots, '[0]')) return [];
    return pivots.filter((f: any) => f.type === type).map((m: any) => ({ ...m, customName: humanize(m.name) }));
}

export const normalizeDirectAssignment = (data: any): DAProps => {
    const continuous = splitPivots(data.possiblePivots, PivotTypes.CONTINUOUS_PIVOT);
    const discrete = splitPivots(data.possiblePivots, PivotTypes.DISCRETE_PIVOT);
    const actorNormalizer = (actor: any[]): any => (_size(actor) > 0 ? actor.reduce((a: any, b: any) => ({ ...a, ...b }), {}) : '');
    const processedData: any = {
        ...data,
        // waterfall
        waterfall: _size(data.poolFiles) > 0 ? data.poolFiles.find((f: any) => f.type === 'waterfall') : null,
        // Counter Parties
        actorInitialValues: {
            auditorIds: actorNormalizer(data.auditors),
            trusteeIds: actorNormalizer(data.trustees),
            ratingAgencyIds: actorNormalizer(data.ratingAgencies),
            lawFirmIds: actorNormalizer(data.lawFirms),
            investorLawFirmIds: actorNormalizer(data.investorLawFirms),
            originatorLawFirmIds: actorNormalizer(data.originatorLawFirms),
        },
        actors: {
            auditor: actorNormalizer(data.auditors),
            trustee: actorNormalizer(data.trustees),
            ratingAgency: actorNormalizer(data.ratingAgencies),
            lawFirm: actorNormalizer(data.lawFirms),
        },
        investorIds: actorNormalizer(data.investors),
        structuring: _get(data, 'structuring') ? data.structuring : initialState.structuring,
        // Pool Selection
        pivotOptions: {
            discrete,
            continuous: continuous.length > 0 ? continuous.map((m: any) => ({ ...m, range: { from: '', to: '', splitEvery: '' } })) : [],
        },
        poolSelectionCriteria: {
            ...data.poolSelectionCriteria,
            canTenorStartFromDisbursement: _get(data, 'canTenorStartFromDisbursement', false),
            canTenorStartFromSecurityRegistrationDate: _get(data, 'canTenorStartFromSecurityRegistrationDate', false),
            tenorStartsFrom: _get(data, 'poolSelectionCriteria.tenorStartsFrom', 'first_installment_date'),
            concentrations: _get(data, 'poolSelectionCriteria.concentrations')
                ? data.poolSelectionCriteria.concentrations
                : initialState.poolSelectionCriteria.concentrations,
            soonToMature: _get(data, 'poolSelectionCriteria.soonToMature')
                ? _get(data, `poolSelectionCriteria.soonToMature`)
                : _get(data, 'poolSelectionCriteria.cutOffDate'),
            ...(_get(data, 'poolSelectionCriteria.par')
                ? {
                      par: {
                          ...data.poolSelectionCriteria.par,
                          ...(_get(data, 'poolSelectionCriteria.par.year')
                              ? { year: { label: data.poolSelectionCriteria.par.year, value: data.poolSelectionCriteria.par.year } }
                              : {}),
                          ...(_get(data, 'poolSelectionCriteria.par.quarter')
                              ? {
                                    quarter: {
                                        label: data.poolSelectionCriteria.par.quarter,
                                        value: data.poolSelectionCriteria.par.quarter,
                                    },
                                }
                              : {}),
                          rangedParFilters: _get(data, 'poolSelectionCriteria.par.rangedParFilters')
                              ? data.poolSelectionCriteria.par.rangedParFilters
                              : initialState.poolSelectionCriteria.par.rangedParFilters,
                          parFilters: _get(data, 'poolSelectionCriteria.par.parFilters')
                              ? data.poolSelectionCriteria.par.parFilters
                                    .map((m: any) => ({ [humps.camelize(m.value)]: m.cutoff }))
                                    .reduce((acc: any, val: any) => ({ ...acc, ...val }), {})
                              : initialState.poolSelectionCriteria.par.parFilters,
                      },
                  }
                : {
                      par: initialState.poolSelectionCriteria.par,
                  }),
            ...(_get(data, 'poolSelectionCriteria.loansToReject')
                ? {
                      loansToReject: data.poolSelectionCriteria.loansToReject.map((m: any) => ({
                          ...m,
                          ids: m.ids.join(','),
                      })),
                  }
                : { loansToReject: initialState.poolSelectionCriteria.loansToReject }),
            limitPoolSize:
                _has(data, 'poolSelectionCriteria.limitPoolSize') && _size(data.poolSelectionCriteria.limitPoolSize) > 0
                    ? {
                          ..._get(data, 'poolSelectionCriteria.limitPoolSize', {}),
                          size:
                              _get(data, 'poolSelectionCriteria.limitPoolSize.size') > 0
                                  ? valuesToCrores(_get(data, 'poolSelectionCriteria.limitPoolSize.size', ''))
                                  : '',
                      }
                    : {
                          type: 'retain_branch_concentration',
                      },
            ...(_get(data, 'poolSelectionCriteria.pivots[0]')
                ? {
                      pivots: {
                          discretePivots: splitPivots(data.poolSelectionCriteria.pivots, PivotTypes.DISCRETE_PIVOT),
                          continuousPivots: splitPivots(data.poolSelectionCriteria.pivots, PivotTypes.CONTINUOUS_PIVOT),
                      },
                  }
                : {
                      pivots: {
                          discretePivots: splitPivots(data.possiblePivots, PivotTypes.DISCRETE_PIVOT).filter((f: any) => f.default),
                      },
                  }),
            ...(_get(data, 'poolSelectionCriteria.geography')
                ? {
                      geography: {
                          ..._get(data, 'poolSelectionCriteria.geography'),
                          excludedStates: mapOptions(_get(data, 'poolSelectionCriteria.geography.excludedStates', [])),
                          excludedDistricts: mapOptions(_get(data, 'poolSelectionCriteria.geography.excludedDistricts', [])),
                          excludedBranches: mapOptions(_get(data, 'poolSelectionCriteria.geography.excludedBranches', [])),
                      },
                  }
                : {}),
            excludeTransactions: _get(data, 'poolSelectionCriteria.excludeTransactions', []),
            ...((_get(data, 'assetClasses') || []).some((asset: string) => LTV_POOL_TYPES.includes(asset))
                ? {
                      ltv: {
                          maximumLtv: _get(data, 'poolSelectionCriteria.cutOffDate') ? `${_get(data, 'poolSelectionCriteria.ltv.maximumLtv')}` : 100,
                          minimumLtv: _get(data, 'poolSelectionCriteria.cutOffDate') ? `${_get(data, 'poolSelectionCriteria.ltv.minimumLtv')}` : '0',
                      },
                  }
                : {}),

            customRules: { ...mapValuesGlobal(_get(data, 'poolSelectionCriteria.customRules')) },
        },
        preferredInvestors: !_get(data, 'preferredInvestors.entities') ? { ...data.preferredInvestors, entities: [] } : data.preferredInvestors,
        autoStructuringSuggestions:
            _get(data, 'autoStructuringSuggestions[0].label', '') === 'error' || _isNull(data.autoStructuringSuggestions)
                ? []
                : _get(data, 'autoStructuringSuggestions', []),
        recommendedInvestors: _get(data, 'recommendedInvestorInfo', []),
        poolFiles: [...data.poolFiles, ...(_size(data?.assetVerificationFile?.files) === 0 ? [] : [data?.assetVerificationFile])],
    };
    return processedData;
};

function denormalizeDirectAssignment(data: any): any {
    const { seasoning, tenorStartsFrom, par, loansToReject, pivots, excludeTransactions = [], limitPoolSize, ...others } = data;
    const processedData = {
        assignmentTransaction: {
            poolSelectionCriteria: {
                ...others,
                seasoning,
                ...(seasoning && { tenorStartsFrom }),
                ...(_get(par, 'parFilters')
                    ? {
                          par: {
                              ...(_get(par, 'year') ? { year: _get(par, 'year.value') ? par.year.value : par.year } : {}),
                              ...(_get(par, 'quarter') ? { quarter: _get(par, 'quarter.value') ? par.quarter.value : par.quarter } : {}),
                              ...(_get(par, 'rangedParFilters.[0].from') ? { rangedParFilters: par.rangedParFilters } : {}),
                              ...(!_isEmpty(par.parFilters) && Object.values(par.parFilters).some((s) => s !== '')
                                  ? {
                                        parFilters: Object.keys(par.parFilters).map((m: any) => ({
                                            value: `par_${m.match(/\d+/g).map(Number)[0]}`,
                                            cutoff: par.parFilters[m],
                                        })),
                                    }
                                  : {}),
                          },
                      }
                    : {}),
                ...(_get(loansToReject, '[0].ids')
                    ? {
                          loansToReject: loansToReject
                              .filter((m: any) => m)
                              .map((m: any) => ({ ids: m.ids.split(/[\s,]+/).map((n: any) => n.trim()), reason: m.reason })),
                      }
                    : {}),
                ...(pivots
                    ? {
                          pivots: [
                              ...(_get(pivots, 'discretePivots') ? pivots.discretePivots.map((m: any) => ({ name: m.name, type: m.type })) : []),
                              ...(_get(pivots, 'continuousPivots')
                                  ? pivots.continuousPivots.map((n: any) => {
                                        const { customName, ...rest } = n;
                                        return rest;
                                    })
                                  : []),
                          ],
                      }
                    : {}),
                ...(_get(limitPoolSize, 'size', 0) > 0
                    ? {
                          limitPoolSize: {
                              ...limitPoolSize,
                              size: !_get(limitPoolSize, 'size') || limitPoolSize.size === 0 ? '' : croresToValues(limitPoolSize.size),
                          },
                      }
                    : {}),
                ...(_get(data, 'ltv')
                    ? {
                          ltv: {
                              maximumLtv: Number(_get(data, 'ltv.maximumLtv')) || null,
                              minimumLtv:
                                  _get(data, 'ltv.minimumLtv') || _get(data, 'ltv.minimumLtv') === 0 ? Number(_get(data, 'ltv.minimumLtv')) : null,
                          },
                      }
                    : {}),
                excludeTransactions,
                egbtRejectedLoansCheck: true,
                cifRejectedLoansCheck: true,
            },
        },
    };
    return humps.decamelizeKeys(processedData);
}

export function getDAdetails(transId: string, dispatch: React.Dispatch<{ type: string; data: DAProps }>): Promise<DAProps> {
    return getJSON(daBaseUrl(transId))
        .then((data) => humps.camelizeKeys(data))
        .then((data) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(data) });
            return data;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                toastr.error('Error retrieving DA data', m);
            }),
        );
}

export function getDABids(transId: string, dispatch: React.Dispatch<{ type: string; data: DAProps }>): Promise<any> {
    return getJSON(`${daBaseUrl(transId)}/bids`)
        .then((data) => humps.camelizeKeys(data))
        .then((data: any) => {
            dispatch({ type: GET_DA_BIDS, data });
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                toastr.error('Error retrieving DA data', m);
            }),
        );
}

export function bidAction(transId: string, bidId: string, action: string, dispatch: React.Dispatch<any>): Promise<any> {
    return putJSON(`${daBaseUrl(transId)}/bids/${bidId}/${action}`, {})
        .then((data) => humps.camelizeKeys(data))
        .then((data) => {
            toastr.success('Success', 'Action Completed');
            dispatch({ type: UPDATE_DA_BIDS, data });
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                toastr.error('Error', m);
            }),
        );
}

export function getPoolFiles(id: string, dispatch: any): Promise<DAProps> {
    return getJSON(`${daBaseUrl(id)}?is_poller=true`)
        .then((d) => humps.camelizeKeys(d))
        .then((d: any) => {
            if (!d.filterProcessing) {
                return d;
            }
            dispatch({ type: GET_DA_TRANS, data: { ...normalizeDirectAssignment(d), pollingInProgress: true } });
            return null;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                // dispatch({ type: TRANSACTION_POOLFILTER_FAILURE, id });
                toastr.error('Error Applying filters', m);
            }),
        );
}

export function pollForPoolFiles(id: string, dispatch: any, isSelfServe?: boolean, setError?: any): Promise<any> {
    return poll(() => getPoolFiles(id, dispatch), 1200000, 15000)
        .then(() => getJSON(daBaseUrl(id)))
        .then((d: any) => humps.camelizeKeys(d))
        .then((d: any) => {
            dispatch({ type: GET_DA_TRANS, data: { ...normalizeDirectAssignment(d), pollingInProgress: true } });
            if (isSelfServe) {
                setError({ status: true, message: 'Pool filter applied', type: 'success' });
            } else {
                toastr.success('Success!', 'Filter Applied.');
            }
        })
        .catch((ex: any) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                console.log(ex);
                // dispatch({ type: TRANSACTION_POOLFILTER_FAILURE, id });
            });
        });
}

export function applyPoolFilter(transId: string, poolFilterValues: any, dispatch: any, isSelfServe?: boolean, setError?: any): Promise<any> {
    return patchJSON(daBaseUrl(transId), {
        ...denormalizeDirectAssignment(poolFilterValues),
    })
        .then((data) => humps.camelizeKeys(data))
        .then((data) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(data) });
            if (!isSelfServe) {
                toastr.success('Success!', 'Applying filters, Please wait for sometime.');
            }
            return dispatch(pollForPoolFiles(transId, dispatch, isSelfServe, setError));
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                toastr.error('Error retrieving DA data', m);
            }),
        );
}
export function loadWaterfall(id: string, dispatch: any): any {
    return getJSON(`${daBaseUrl(id)}?is_poller=true`)
        .then((d) => {
            if (_get(d, 'pool_files') && d.pool_files.some((f: any) => f.type === 'waterfall' && f.files.length > 0)) {
                return d;
            }
            return null;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error retrieving waterfall summary', m);
            }),
        );
}

export function pollWaterfall(id: string, dispatch: any, isSelfServe?: boolean, setError?: any): any {
    return poll(() => loadWaterfall(id, dispatch), 180000, 1000)
        .then(() => getJSON(daBaseUrl(id)))
        .then((data) => humps.camelizeKeys(data))
        .then((d) => dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d), id }))
        .then(() => {
            if (isSelfServe) {
                setError({ status: true, message: 'Waterfall file has been generated successfully.', type: 'success' });
            } else {
                toastr.success('Success!', 'Waterfall updated successfully.');
            }
        })
        .catch((e) => {
            const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
            if (errMsg === 'timeout') {
                toastr.error('Timeout retrieving waterfall summary', 'Please try again after sometime.');
            } else {
                toastr.error('Error retrieving waterfall summary', errMsg);
            }
        });
}

export function applyStructuring(
    transId: string,
    structuringValues: any,
    dispatch: React.Dispatch<any>,
    setSubmitting: any,
    isFulfillment?: boolean,
    setError?: any,
    isSelfServe?: boolean,
): Promise<any> {
    return patchJSON(daBaseUrl(transId), {
        assignment_transaction: {
            is_settled_deal: isFulfillment,
            structuring: {
                ...humps.decamelizeKeys(structuringValues),
            },
        },
    })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            setSubmitting(false);
            if (!isFulfillment) dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            else return d;
        })
        .then((d) => {
            if (isSelfServe) {
                setError({ status: true, message: 'Initiated deal structuring. Please wait a few seconds.', type: 'success' });
            }
            if (!isSelfServe) toastr.success('Success!', 'Initiated deal structuring. Please wait a few seconds.');
            if (isFulfillment) return d;
        })
        .then((d) => {
            if (!isFulfillment) dispatch(pollWaterfall(transId, dispatch, isSelfServe, setError));
            else return d;
        })
        .then((d) => {
            if (isFulfillment) return d;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                setSubmitting(false);
                if (isSelfServe) {
                    setError({ status: true, message: m });
                } else {
                    toastr.error('Error Applying structuring data', m);
                }
            }),
        );
}

export function applyReportConfig(transId: string, configValues: any, dispatch: React.Dispatch<any>, setSubmitting: any): Promise<any> {
    return patchJSON(daBaseUrl(transId), {
        assignment_transaction: {
            report_configuration: {
                ...humps.decamelizeKeys(configValues),
            },
        },
    })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: string) => {
                setSubmitting(false);
                toastr.error('Error Applying structuring data', m);
            }),
        );
}

export function updateAssignmentData(
    state: any,
    values: any,
    dispatch: React.Dispatch<any>,
    setSubmitting: any,
    type = '',
    errorCb = () => {},
): Promise<any> {
    return patchJSON(daBaseUrl(state.id), {
        assignment_transaction: {
            ...humps.decamelizeKeys(values),
        },
    })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            if (setSubmitting) setSubmitting(false);
            getDAdetails(state.id, dispatch);
            const normalizeData: any = normalizeDirectAssignment(d);
            if (type === COUNTERPARTY) {
                toastr.success('Success!', 'Counterparties updated');
            } else if (state.transactionState !== normalizeData.transactionState && _has(state, 'transactionState')) {
                toastr.success('Success!', 'Transaction state updated');
            } else if (type === RELEASE) {
                toastr.success('Success!', 'Transaction state updated');
                logEvent(PRODUCT_RELEASE_CLICK);
            } else {
                toastr.success('Success!', 'Action Completed');
            }
            dispatch({ type: GET_DA_TRANS, data: normalizeData });
        })
        .then(() => {
            if (!_get(state, 'dmsEnabled', true)) return getTasks(state.id);
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: string) => {
                if (typeof setSubmitting === 'function') {
                    setSubmitting(true);
                    if (!isProduct && !isClient) {
                        toastr.error('Error updating counter parties', m);
                    }
                }
            });
            errorCb();
        });
}

function getDADocs(id: string, dispatch: React.Dispatch<any>): Promise<any> {
    return getJSON(daBaseUrl(id))
        .then((d) => humps.camelizeKeys(d))
        .then((d: any) => {
            const docs = _get(d, 'documents', []).filter((doc: any) => doc.files.length > 0);
            const isGenerated = docs.every((item: any) => item.files[0].state === 'generated' || item.files[0].state === 'uploaded');
            if (isGenerated && _size(docs) > 0) return d;
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            return null;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error updating deal name', m);
            }),
        );
}

export function pollDADocuments(id: string, dispatch: React.Dispatch<any>): Promise<any> {
    return poll(() => getDADocs(id, dispatch), 180000, 3000)
        .then((data) => humps.camelizeKeys(data))
        .then((d) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            toastr.success('Success!', 'Documents generated successfully.');
        })
        .then(() => dispatch({ type: LOADING_ACTION, loading: false }))
        .catch((e) => {
            dispatch({ type: LOADING_ACTION, loading: false });
            handleErrorV2(dispatch, e).then((m: any) => {
                const errMsg = m || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
                } else {
                    toastr.error('Error Generating Documents', errMsg);
                }
            });
        });
}

export function generateDADocuments(id: string, dispatch: React.Dispatch<any>, setSubmitting: any): Promise<any> {
    setSubmitting(true);
    dispatch({ type: LOADING_ACTION, loading: true });
    return patchJSON(`${daBaseUrl(id)}/generate_documents`)
        .then((data) => humps.camelizeKeys(data))
        .then((d) => {
            setSubmitting(false);
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            toastr.success('Success!', 'Document generation initiated successfully.');
        })
        .then(() => dispatch(pollDADocuments(id, dispatch)))
        .catch((e) => {
            setSubmitting(false);
            handleErrorV2(dispatch, e).then((m: any) => {
                const errMsg = m || 'Something went wrong, please try again after sometime';
                toastr.error('Error Generating Documents', errMsg);
            });
        });
}

export function uploadDADocuments(id: string, file: any, fileType: any, dispatch: React.Dispatch<any>): Promise<any> {
    const body = new FormData();
    body.append('document', file);
    body.append('document_type', fileType);
    return postFormData(`${daBaseUrl(id)}/deal_documents`, body)
        .then(() => {
            toastr.success('Success!', 'Document Uploaded');
            dispatch(getDAdetails(id, dispatch));
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error Uploading Document', m);
            });
        });
}

export function destroyDocument(fileId: string, transId: string, dispatch: React.Dispatch<any>): Promise<any> {
    return deleteJSON(`${daBaseUrl(transId)}/deal_documents/${fileId}`)
        .then(() => {
            toastr.success('Success', 'Document destroyed');
            dispatch(getDAdetails(transId, dispatch));
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error removing document', m);
            });
        });
}

export function addAdhocDocument(transId: string, data: any, dispatch: React.Dispatch<any>): Promise<any> {
    return patchJSON(`${daBaseUrl(transId)}/add_document`, {
        document: humps.decamelizeKeys(data),
    })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            toastr.success('Success!', 'Adhoc document has been created successfully');
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error adding document', m);
            });
        });
}

export function updateAdhocDocument(transId: string, data: any, dispatch: React.Dispatch<any>): Promise<any> {
    return patchJSON(`${daBaseUrl(transId)}/modify_document`, humps.decamelizeKeys(data))
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            toastr.success('Success!', 'Adhoc document modified');
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error Uploading Document', m);
            });
        });
}

export function removeDocPlaceholder(transId: string, data: any, dispatch: React.Dispatch<any>): Promise<any> {
    return patchJSON(`${daBaseUrl(transId)}/remove_document`, humps.decamelizeKeys(data))
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            toastr.success('Success!', 'Document placeholder has been removed successfully');
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error removing placeholder', m);
            });
        });
}

export function getDAExecDocs(transId: string, dispatch: React.Dispatch<any>): Promise<any> {
    return getJSON(`${daBaseUrl(transId)}/executable_documents`)
        .then((d) => humps.camelizeKeys(d))
        .then((d: any) => {
            const docs = _get(d, 'documents', []).filter((doc: any) => doc.files.length > 0);
            const others = d.otherFiles;
            const isGenerated = docs.every((item: any) => item.files[0].state === 'generated');
            const isFailed = docs.some((item: any) => item.files[0].state === 'failed');
            if (_get(d, 'errorMessage')) {
                return _get(d, 'errorMessage');
            }
            if (!docs.length && others.length) {
                dispatch({ type: GET_DA_EXEC_DOCS, data: { execDocs: docs, others: d.otherFiles } });
                return null;
            }
            if (isFailed) {
                return docs;
            } else if (isGenerated) {
                dispatch({ type: GET_DA_EXEC_DOCS, data: { execDocs: docs, others: d.otherFiles } });
                return docs;
            }
            return null;
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error removing placeholder', m);
            });
        });
}

export function pollDAExecDocs(id: string, dispatch: React.Dispatch<any>): Promise<any> {
    return poll(() => getDAExecDocs(id, dispatch), 180000, 3000)
        .then((d) => {
            if (!Array.isArray(d)) {
                toastr.error('Error Finalizing Documents', d);
            } else if (!d.length) {
                toastr.error('Error Finalizing Documents', 'Failed to generate executed documents');
            } else if (d.some((item) => item.files[0].state === 'failed')) {
                toastr.error(
                    'Error Finalizing Documents',
                    `Please check the format of ${humps.pascalize(d.find((item) => item.files[0].state === 'failed').files[0].type)}`,
                );
            } else {
                toastr.success('Success!', 'Documents generated successfully.');
            }
        })
        .catch((e) => {
            const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
            if (errMsg === 'timeout') {
                toastr.error('Timeout Generating Documents', 'Please try again after sometime.');
            } else {
                toastr.error('Error Polling Documents', errMsg);
            }
        });
}

export function generateExecDocs(transId: string, dispatch: React.Dispatch<any>): Promise<any> {
    toastr.success('Success!', 'Documents generation Request initiated');
    return postJSON(`${daBaseUrl(transId)}/executable_documents`)
        .then(() => dispatch(pollDAExecDocs(transId, dispatch)))
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error Generating Documents', m);
            }),
        );
}

export function revokeExecDocs(id: string, dispatch: React.Dispatch<any>): Promise<any> {
    return postJSON(`${daBaseUrl(id)}/executable_documents/revoke`)
        .then((d) => {
            if (_get(d, 'success')) {
                dispatch(getDAExecDocs(id, dispatch));
            } else {
                toastr.error('Failed to revoke executable documents', '');
            }
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error revoking executable documents', m);
            }),
        );
}

export function reUploadWaterfall(fileId: string, transId: string, params: any, dispatch: React.Dispatch<any>) {
    return patchJSON(`${daBaseUrl(transId)}/deal_documents/${fileId}`, params)
        .then((d: any) => {
            toastr.success('Success!', 'Document Uploaded');
            dispatch({ type: WATERFALL_UPLOADED, data: humps.camelizeKeys(d) });
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error Uploading Document', m);
            });
        });
}

export function getDAState(id: string, dispatch: React.Dispatch<any>) {
    return getJSON(daBaseUrl(id))
        .then((data) => humps.camelizeKeys(data))
        .then((data: any) => {
            if ([TRANSACTION_STATE.SETTLED, TRANSACTION_STATE.MATURED].includes(data.transactionState)) {
                dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(data) });
                return data;
            }

            return null;
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                return new Error(m);
            }),
        );
}

export function pollDAState(id: string, dispatch: React.Dispatch<any>, callback: any) {
    return poll(() => getDAState(id, dispatch), 180000, 1000)
        .then(() => {
            toastr.success('Success!', 'Transaction state updated');
            if (typeof callback === 'function') callback();
        })
        .catch((e) => {
            const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
            if (errMsg === 'timeout') {
                toastr.error('Timeout retrieving transaction state', 'Please try again after sometime.');
            } else {
                toastr.error('Error retrieving transaction state', errMsg);
            }
            if (typeof callback === 'function') callback();
        });
}

export function directSettlement(id: string, values: any, dispatch: React.Dispatch<any>, callback?: any) {
    dispatch({ type: LOADING_ACTION, loading: true });
    return patchJSON(`${daBaseUrl(id)}`, { assignment_transaction: { ...values } })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            if (isProduct) {
                dispatch(pollDAState(id, dispatch, callback));
            }
            return dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
        })
        .then((d) => {
            if (values.direct_settlement) {
                dispatch({ type: LOADING_ACTION, loading: false });
            }
        })
        .catch((ex) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                dispatch({ type: LOADING_ACTION, loading: false });
                toastr.error('Error while updating', m);
            }),
        );
}

export function editDealName(transId: any, values: any, dispatch: any, type = PRODUCT_KEYS.assignment): Promise<any> {
    return putJSON(`${daBaseUrl(transId, type)}`, {
        [humps.decamelize(PRODUCT_API_UPDATE_KEYS[type as PoolProductTypes])]: {
            ...humps.decamelizeKeys(values),
            id: transId,
        },
    })
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d) });
            toastr.success('Success!', 'Deal Name updated successfully');
        })
        .catch((ex: any) =>
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error updating deal name', m);
            }),
        );
}

export function updateMcd(transId: string, mcdFiles: any, action: any, closePopup: any, dispatch: any) {
    const params = {
        assignmentTransaction: {
            mcdData: mcdFiles.mcds.filter((f: any) => f.selected === true).map((m: any) => ({ id: m.id })),
        },
    };
    return patchJSON(daBaseUrl(transId), humps.decamelizeKeys(params))
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            action.setSubmitting(false);
            closePopup(false);
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d), id: transId });
        })
        .catch((ex) => {
            action.setSubmitting(false);
            closePopup(false);
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error updating mcd', m);
            });
        });
}

export function getDealPerformance(id: any, dispatch: React.Dispatch<any>): Promise<any> {
    dispatch({ type: DEAL_PERF_GET_REQUEST, id });
    return getJSON(`${daBaseUrl(id)}/deal_performance`)
        .then((d: any) => mapData(humps.camelizeKeys(d), PRODUCT_KEYS.assignment))
        .then((payload: any) => {
            return dispatch({
                type: DEAL_PERF_GET_SUCCESS,
                payload,
                id,
            });
        })
        .catch((err: any) => {
            return handleErrorV2(dispatch, err).then((m: any) => {
                toastr.error('Error fetching deal performance', m);
                return dispatch({ type: DEAL_PERF_GET_FAILURE, message: m, id });
            });
        });
}

export function checkLossEstimationSuggestion(id: any, dispatch: any) {
    return getJSON(`${daBaseUrl(id)}`)
        .then((d: any) => humps.camelizeKeys(d))
        .then((d: any) => {
            if (!d.autoStructuringGenerationFlag) {
                return d;
            }
            return null;
        })
        .catch((ex) => {
            handleErrorV2(dispatch, ex).then((m: any) => {
                toastr.error('Error retrieving Loss estimation Suggestion', m);
            });
            return { error: true };
        });
}

export function pollLossEstimationSuggestion(id: any, dispatch: any, setSubmitting: any) {
    dispatch({ type: POLL_RATING_MODEL_REQUEST });
    return poll(() => checkLossEstimationSuggestion(id, dispatch), 180000, 1000)
        .then((d: any) => humps.camelizeKeys(d))
        .then((d: any) => {
            if (!_has(d, 'error')) {
                const cloneData: any = { ...d };
                if (_get(d, 'autoStructuringSuggestions[0].label', '') === 'error') {
                    cloneData.autoStructuringSuggestions = [];
                    toastr.error('Error', _get(d, 'autoStructuringSuggestions[0].type'), {
                        timeOut: 7000,
                    });
                } else {
                    toastr.success('Success!', 'Loss estimation suggestion generated.');
                }
                dispatch({ type: GET_RATING_MODEL_SUCCESS, data: d, id });
                dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(d), id });
            }
            dispatch({ type: POLL_RATING_MODEL_SUCCESS });
            setSubmitting(false);
        })
        .catch((e: any) => {
            const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
            dispatch({ type: POLL_RATING_MODEL_FAILURE });
            if (errMsg === 'timeout') {
                toastr.error('Timeout retrieving Loss estimation Suggestion', 'Please try again after sometime.');
            } else {
                toastr.error('Error retrieving Loss estimation Suggestion', errMsg);
            }
            setSubmitting(false);
        });
}

export function generateLossEstimation(id: string, payloadData: any, dispatch: React.Dispatch<any>, setSubmitting: any) {
    dispatch({ type: GET_RATING_MODEL_REQUEST });
    const payload: any = {
        assignment_transaction: humps.decamelizeKeys(payloadData),
    };
    return patchJSON(`${daBaseUrl(id)}`, payload)
        .then((d) => humps.camelizeKeys(d))
        .then((d) => {
            dispatch({ type: GET_RATING_MODEL_SUCCESS, data: d, id });
            dispatch(pollLossEstimationSuggestion(id, dispatch, setSubmitting));
            toastr.success('Success!', 'Initiated Loss modelling. Please wait a few seconds.');
        })
        .catch((ex) => {
            return handleErrorV2(dispatch, ex).then((m: any) => {
                dispatch({ type: GET_RATING_MODEL_FAILURE });
                toastr.error('Error retrieving Loss estimation Suggestion', m);
            });
        });
}

export function addAttachment(id: string, data: any, attachmentId: string, callBack: () => void, dispatch: React.Dispatch<any>) {
    dispatch({ type: ADD_ATTACHMENT_REQUEST });
    return patchJSON(`${daBaseUrl(id)}/extra_documents/${attachmentId}`, humps.decamelizeKeys(data))
        .then((d: any) => {
            dispatch({ type: ADD_ATTACHMENT_SUCCESS, data: humps.camelizeKeys(d), id });
            if (typeof callBack === 'function') callBack();
        })
        .catch((ex) => {
            return handleErrorV2(dispatch, ex).then((m: any) => {
                dispatch({ type: ADD_ATTACHMENT_FAILURE });
                toastr.error('Error adding attachment', m);
                if (typeof callBack === 'function') callBack();
            });
        });
}

export function deleteAttachment(id: string, attachmentId: string, callBack: () => void, dispatch: React.Dispatch<any>) {
    dispatch({ type: DELETE_ATTACHMENT_REQUEST });
    return deleteJSON(`${daBaseUrl(id)}/extra_documents/${attachmentId}`)
        .then((d: any) => {
            dispatch({ type: DELETE_ATTACHMENT_SUCCESS, attachmentId, id });
            if (typeof callBack === 'function') callBack();
        })
        .catch((ex) => {
            return handleErrorV2(dispatch, ex).then((m: any) => {
                dispatch({ type: DELETE_ATTACHMENT_FAILURE });
                toastr.error('Error deleting attachment', m);
                if (typeof callBack === 'function') callBack();
            });
        });
}

//genereic Patch call
export const updateDADetails = (dispatch: React.Dispatch<any>, transId: string, values: any, callBack: () => void): Promise<any> => {
    return patchJSON(daBaseUrl(transId), { assignment_transaction: humps.decamelizeKeys(values) })
        .then((data) => humps.camelizeKeys(data))
        .then((data) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(data) });
            if (typeof callBack === 'function') callBack();
        })
        .catch((ex) => {
            return handleErrorV2(dispatch, ex).then((m: any) => {
                if (typeof callBack === 'function') callBack();
                return m;
            });
        });
};

export const canSettleDeal = (dispatch: React.Dispatch<any>, transId: string) => {
    return getJSON(`${daBaseUrl(transId)}/can_settle_deal`).then((data) => {
        dispatch({ type: CAN_SETTLE_DEAL, data: data });
    });
};

export const initiateDealDisbursement = (
    transId: string,
    values: { disbursementAccountNumber: string },
    retryFlow = false,
    generateFiles = false,
): Promise<any> => {
    return postJSON(`${daBaseUrl(transId)}/initiate_disbursement?retry_flow=${retryFlow}&generate_files=${generateFiles}`, {
        assignment_transaction: humps.decamelizeKeys(values),
    })
        .then((data) => humps.camelizeKeys(data) as any)
        .catch((ex) => {
            return handleError(ex).then((m: string) => {
                toastr.error('Error initiating disbursment', m);
            });
        });
};

export const getDisbursementStatus = (transId: string, polling = false, signal?: any): Promise<any> => {
    return getJSON(`${daBaseUrl(transId)}/disbursement_status`, signal)
        .then((data) => humps.camelizeKeys(data) as any)
        .then((data) => {
            if (polling && data?.status === FILE_STATUS.INPROGRESS) {
                return null;
            }
            return data;
        })
        .catch((ex) => {
            return handleError(ex).then((m: string) => {
                toastr.error('Error in fetching disbursment status', m);
            });
        });
};

export const pollForDisbursementStatus = (transId: string, signal: any): Promise<any> =>
    poll(() => getDisbursementStatus(transId, true, signal), 180000, 5000)
        .then((data: any) => humps.camelizeKeys(data) as any)
        .catch((e: any) => {
            handleError(e, TIMEOUT).then((errMsg: any) => {
                if (errMsg !== TIMEOUT) {
                    toastr.error('Error retrieving Eligibility check results', errMsg);
                }
            });
            return null;
        });
export const canEnableCif = (dispatch: React.Dispatch<any>, transId: string) => {
    return getJSON(`${daBaseUrl(transId)}/can_enable_cif`).then((data) => {
        dispatch({ type: CAN_ENABLE_CIF, data: data });
    });
};

export const reInitiateDisbursement = (dispatch: React.Dispatch<any>, transId: string, callback?: () => void) => {
    return getJSON(`${daBaseUrl(transId)}/trigger_disbursement`)
        .then((data) => {
            dispatch({ type: GET_DA_TRANS, data: normalizeDirectAssignment(data), id: transId });
        })
        .catch((ex) => {
            return handleError(ex, NETWORK_CALL_ABORTED).then((m: any) => {
                if (m !== NETWORK_CALL_ABORTED) toastr.error('Error!', m);
                if (typeof callback === 'function') callback();
            });
        });
};
