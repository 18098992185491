import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ca-logo {
        margin-top: 72px;
    }

    .ip-img {
        margin-top: 165px;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 500px;
`;
