import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import queryString from 'qs';
import { push } from 'react-router-redux';
import { CSSTransition } from 'react-transition-group';
import Banner from 'app/components/Banner';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { isActivated } from 'app/actions/AuthedActions';
import { renderField, renderCheckField } from 'app/utils/ReduxFormUtils';
import PasswordErrorStateComponent, { PasswordErrorProgressComponent } from 'app/components/PasswordStrenghtIndicator';
import FooterLinks from 'app/components/FooterLinks';
import { deriveErrorMsg } from 'app/utils/CommonUtils';

import SideBar from 'app/components/SideBar';
import Alert from 'app/components/Alert';
import StyledLogin from './styled';

export const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Required';
    } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
        errors.password = "Password and its confirmation doesn't match";
    }
    return errors;
};

class SetPassword extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                isPasswordLength: 0,
                isLowercase: 0,
                isUpperCase: 0,
                isDigit: 0,
                isSpecialChar: 0,
            },
            passwordStrength: 0,
            errPopup: false,
            entityId: null,
            token: null,
            userId: null,
            termsAndConditionsAccepted: null,
            isForgotPasswordLink: null,
        };
    }

    componentDidMount() {
        const { location, redirectToHome, checkActivatedUser } = this.props;
        const qs = location && location.search && queryString.parse(location.search, { ignoreQueryPrefix: true });

        const entityId = _get(qs, 'e') || _get(qs, 'entity_id');
        const token = _get(qs, 't') || _get(qs, 'token');
        const userId = _get(qs, 'u') || _get(qs, 'user_id');
        const termsAndConditionsAccepted = _get(qs, 'terms_and_conditions_accepted');
        const isForgotPasswordLink = _get(qs, 'forgot_password');

        this.setQueryParams(entityId, userId, token, termsAndConditionsAccepted, isForgotPasswordLink);
        checkActivatedUser(userId);

        if (!entityId || !token || !userId) {
            redirectToHome();
        }
    }

    onErrPopupClose = () => {
        this.setState({
            errPopup: false,
        });
    };

    onErrPopupOpen = () => {
        this.setState({
            errPopup: true,
        });
    };

    setQueryParams = (entityId, userId, token, termsAndConditionsAccepted, isForgotPasswordLink) => {
        this.setState({
            entityId,
            token,
            userId,
            termsAndConditionsAccepted,
            isForgotPasswordLink,
        });
    };

    handlePasswordChange = (password) => {
        const errors = {
            isPasswordLength: password.length < 8 ? 1 : 2,
            isLowercase: !/[a-z]/g.test(password) ? 1 : 2,
            isUpperCase: !/[A-Z]/g.test(password) ? 1 : 2,
            isDigit: !/[0-9]/g.test(password) ? 1 : 2,
            isSpecialChar: !/[!@#$%^&*]+/.test(password) ? 1 : 2,
        };

        this.setState({
            errors,
        });

        this.updatePasswordStrength(errors);
    };

    updatePasswordStrength = (errors) => {
        const passwordStrength = Object.values(errors).filter((v) => v === 2).length;
        const errPopup = passwordStrength < 5;

        this.setState({
            passwordStrength,
            errPopup,
        });
    };

    render() {
        const { handleSubmit, onSubmit, submitting, errorMsg, terms } = this.props;
        const { entityId, token, userId, termsAndConditionsAccepted, isForgotPasswordLink } = this.state;

        const { errors, passwordStrength, errPopup } = this.state;

        const derivedErrorMsg = deriveErrorMsg(errorMsg);

        return (
            <>
                <StyledLogin>
                    <div className="container">
                        <div className="row">
                            <div className="span6">
                                <SideBar />
                            </div>
                            <div className="span6">
                                <div className="login">
                                    <h4>Set your password</h4>
                                    <form
                                        onSubmit={handleSubmit((data) => onSubmit({ entityId, userId, token, isForgotPasswordLink }, data))}
                                        autoComplete="off"
                                    >
                                        <div className="form-group">
                                            <Field
                                                onChangeAction={this.handlePasswordChange}
                                                onFocus={this.onErrPopupOpen}
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                component={renderField}
                                                label="Password"
                                            />
                                            <i className="icon-lock-fill" />
                                        </div>
                                        <div className="form-group">
                                            <Field
                                                name="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                component={renderField}
                                                label="Confirm Password"
                                            />
                                            <i className="icon-lock-fill" />
                                        </div>
                                        {errorMsg && (
                                            <p className="text-center">
                                                <Alert type="danger" title="Alert!" msg={derivedErrorMsg} />
                                            </p>
                                        )}
                                        {!termsAndConditionsAccepted && (
                                            <div className="acceptance-box">
                                                <Field
                                                    name="termsAndConditionsAccepted"
                                                    component={renderCheckField}
                                                    label={
                                                        <>
                                                            I accept{' '}
                                                            <a href="https://www.credavenue.com/tnc" target="_blank" rel="noopener noreferrer">
                                                                Terms and Conditions
                                                            </a>
                                                        </>
                                                    }
                                                />
                                            </div>
                                        )}
                                        <ul className="actions">
                                            <li />
                                            <li>
                                                <button
                                                    className="btn blu-nw pull-right violet-nw"
                                                    type="submit"
                                                    disabled={(!termsAndConditionsAccepted && !terms) || submitting}
                                                >
                                                    {submitting ? 'Submitting...' : 'Submit'}
                                                </button>
                                            </li>
                                        </ul>

                                        <PasswordErrorProgressComponent passwordStrength={passwordStrength} />
                                    </form>
                                    {errPopup && (
                                        <CSSTransition classNames="password-info" timeout={{ exit: 300, enter: 500 }}>
                                            <PasswordErrorStateComponent errors={errors} onErrPopupClose={this.onErrPopupClose} />
                                        </CSSTransition>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="white-bg" />
                        <Banner />
                    </div>
                </StyledLogin>
                <FooterLinks />
            </>
        );
    }
}

SetPassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const reduxFormDecorator = reduxForm({
    form: 'setPassword',
    validate,
});

const selector = formValueSelector('setPassword');

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authed.accessToken,
        done: state.authed.done,
        errorMsg: state.authed.authErrMsg,
        terms: selector(state, 'termsAndConditionsAccepted'),
    };
};

const mapDispatchToProps = (dispatch) => ({
    redirectToHome: () => dispatch(push('/')),
    checkActivatedUser: (userId) => dispatch(isActivated(userId)),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default reduxConnector(reduxFormDecorator(SetPassword));
