import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import InvOnboardBanner from './Banner';
import InvOnboardForm from './Form';
import { Container, Body } from './styled';
import { postInvestorSignup } from '../../reducers/investor_signup/index';
import InvestorSuccess from './Success';

interface IinvestorSignup {
    handleSubmit: (values: object, setSubmitting: boolean) => void;
    signupStatus: boolean;
}

const InvestorSignup: React.FC<IinvestorSignup> = ({ handleSubmit, signupStatus }) => {
    return (
        <Body>
            <Container>
                <div className="invonboard-layout">
                    <InvOnboardBanner />
                    {!signupStatus ? (
                        <InvOnboardForm onSubmit={(values: object, setSubmitting: boolean) => handleSubmit(values, setSubmitting)} />
                    ) : (
                        <InvestorSuccess />
                    )}
                </div>
            </Container>
        </Body>
    );
};

const mapStateToProps = (state: object) => {
    return {
        signupStatus: _get(state, 'investorSignup.signupStatus', false),
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    handleSubmit: (values: object, setSubmitting: boolean) => dispatch(postInvestorSignup(values, setSubmitting)),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default reduxConnector(InvestorSignup);
