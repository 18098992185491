import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = null;

const createActionName = name => `app/ptc/transaction/actor/${name}`;

export const ACTORS_GET_SUCCESS = createActionName('ACTORS_GET_SUCCESS');
export const ACTORS_UPDATE_SUCCESS = createActionName('ACTORS_UPDATE_SUCCESS');

export default function ptc(state = initialState, action) {
    switch (action.type) {
    case ACTORS_GET_SUCCESS:
    case ACTORS_UPDATE_SUCCESS:
        return {
            ...action.data,
        };
    default:
        return state;
    }
}

const ptcBaseUrl = id => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

export function actorsUpdate(transId, data) {
    return {
        type: ACTORS_UPDATE_SUCCESS,
        data,
        transId,
    };
}

function unMapActors(data) {
    const { actors } = data;
    const unMapData = humps.camelizeKeys(actors);
    return {
        ...unMapData,
        md: {
            slceProvider:
                (unMapData.slceProvider && {
                    value: unMapData.slceProvider.id,
                    label: unMapData.slceProvider.name || 'N/A',
                }) ||
                {},
            servicer:
                (unMapData.servicer && {
                    value: unMapData.servicer.id,
                    label: unMapData.servicer.name || '',
                }) ||
                {},
            auditor:
                (unMapData.auditor && {
                    value: unMapData.auditor.id,
                    label: unMapData.auditor.name || '',
                }) ||
                {},
            trustee:
                (unMapData.trustee && {
                    value: unMapData.trustee.id,
                    label: unMapData.trustee.name || '',
                }) ||
                {},
            trusteeBranch:
                (unMapData.trustee && unMapData.trustee.branch && {
                    value: unMapData.trustee.branch,
                    label: unMapData.trustee.branch || '',
                }) ||
                {},
            ratingAgency:
                (unMapData.ratingAgency && {
                    value: unMapData.ratingAgency.id,
                    label: unMapData.ratingAgency.name || '',
                }) ||
                {},
            lawFirm:
                (unMapData.lawFirm && {
                    value: unMapData.lawFirm.id,
                    label: unMapData.lawFirm.name || '',
                }) ||
                {},
            lawFirmBranch:
                (unMapData.lawFirm && unMapData.lawFirm.branch && {
                    value: unMapData.lawFirm.branch,
                    label: unMapData.lawFirm.branch || '',
                }) ||
                {},
        },
    };
}

function mapActors(data) {
    const mapData = {
        slce_provider: {
            id: data.slceProvider && data.slceProvider.value,
            name: data.slceProvider && data.slceProvider.label,
        },
        servicer: {
            id: data.servicer && data.servicer.value,
            name: data.servicer && data.servicer.label,
        },
        auditor: {
            id: data.auditor && data.auditor.value,
            name: data.auditor && data.auditor.label,
        },
        trustee: {
            id: data.trustee && data.trustee.value,
            name: data.trustee && data.trustee.label,
            branch: (data.trusteeBranch && data.trusteeBranch.value) || '',
        },
        ratingAgency: {
            id: data.ratingAgency && data.ratingAgency.value,
            name: data.ratingAgency && data.ratingAgency.label,
        },
        lawFirm: {
            id: data.lawFirm && data.lawFirm.value,
            name: data.lawFirm && data.lawFirm.label,
        },
    };
    return { actors: humps.decamelizeKeys(mapData) };
}

export function updateActors(id, actors) {
    return dispatch =>
        fetchUtils
            .putJSON(`${ptcBaseUrl(id)}/actors`, mapActors(actors))
            .then(d => dispatch({ type: ACTORS_UPDATE_SUCCESS, data: mapActors(d), id }))
            .then(() => {
                toastr.success('Success!', 'Counterparties changes updated successfully.');
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error updating actors data', m);
                });
            });
}

export function getActors(id) {
    return dispatch =>
        fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/actors`)
            .then((d) => {
                dispatch({ type: ACTORS_GET_SUCCESS, data: unMapActors(d), id });
                return d;
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving actors data', m);
                }));
}
