import * as React from 'react';
import { Editor, EditorOptions, EditorPlugin, UndoService } from 'roosterjs-editor-core';
import { HtmlSanitizer } from 'roosterjs-html-sanitizer';
import { ContentEdit, HyperLink, Paste } from 'roosterjs-editor-plugins';
import { DefaultFormat } from 'roosterjs-editor-types';
import { EditorViewState } from 'roosterjs-react';
import { ContentChangedPlugin } from 'roosterjs-react-common';

export interface ReactEditorProps {
    content?: string;
    viewState: EditorViewState;
    className?: string;
    plugins?: EditorPlugin[];
    updateViewState?: (viewState: EditorViewState, content: string, isInitializing: boolean) => void;
    undo?: UndoService;
    isRtl?: boolean;
    hyperlinkToolTipCallback?: (href: string) => string;
    defaultFormat?: DefaultFormat;
    onBlur?: (ev: React.FocusEvent<HTMLDivElement>) => void;
    onChange?: (newValue: string) => void;
}

export default class RTEditor extends React.PureComponent<ReactEditorProps, {}> {
    private contentDiv!: HTMLDivElement;
    private editor?: Editor;
    private updateViewStateWhenUnmount!: boolean;

    render() {
        const { className, isRtl } = this.props;
        return <div dir={isRtl ? 'rtl' : 'ltr'} className={className} onBlur={this.onBlur} ref={this.onContentDivRef} />;
    }

    componentDidMount() {
        this.editor = new Editor(this.contentDiv, this.getEditorOptions());
        this.updateViewStateWhenUnmount = true;
        this.updateContentToViewState(true);
    }

    componentWillUnmount() {
        if (this.updateViewStateWhenUnmount) {
            this.updateContentToViewState(false);
            this.updateViewStateWhenUnmount = false;
        }
        if (this.editor) {
            this.editor.dispose();
            // this.editor = null;
        }
    }

    setContent(newValue: string) {
        if (this.editor) {
            this.editor.setContent(newValue);
        }
    }

    updateContentToViewState(isInitializing: boolean) {
        if (this.editor) {
            const updateViewState = this.props.updateViewState || this.updateViewState;
            updateViewState(this.props.viewState, this.editor.getContent(), isInitializing);
        }
    }

    setUpdateViewStateWhenUnmount(updateViewStateWhenUnmount: boolean) {
        this.updateViewStateWhenUnmount = updateViewStateWhenUnmount;
    }

    onChange = (newValue: string) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(newValue);
        }
    };

    private getEditorOptions(): EditorOptions {
        const { plugins, viewState, undo, hyperlinkToolTipCallback, defaultFormat } = this.props;
        let allPlugins: EditorPlugin[] = [
            new ContentEdit(),
            new HyperLink(hyperlinkToolTipCallback),
            new Paste(true /* useDirectPaste */),
            new ContentChangedPlugin(this.onChange),
        ];

        if (plugins) {
            allPlugins = allPlugins.concat(plugins);
        }

        const initialContent = HtmlSanitizer.convertInlineCss(viewState.content);
        const options: EditorOptions = {
            plugins: allPlugins,
            defaultFormat,
            undo,
            initialContent,
        };

        return options;
    }

    private updateViewState(viewState: EditorViewState, content: string, isInitializing: boolean) {
        if (viewState.content !== content) {
            viewState.content = content;
            if (!isInitializing) {
                viewState.isDirty = true;
            }
        }
    }

    private onBlur = (ev: React.FocusEvent<HTMLDivElement>) => {
        this.updateContentToViewState(false);
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
    };

    private onContentDivRef = (ref: HTMLDivElement) => {
        this.contentDiv = ref;
    };
}
