import React from 'react';

const LoginSidebar = () => (
    <div className="banner_overall">
        <ul className="list-inline">
            <li>
                <i className="credhome-investors" />
                <p>Number of Investors</p>
                <span>500+</span>
            </li>
            <li>
                <i className="credhome-borrowers" />
                <p>Number of Borrowers</p>
                <span>1000+</span>
            </li>
            <li>
                <i className="credhome-volume" />
                <p>Debt Raise Volumes</p>
                <span>
                    <i className="icon-rupee-indian" />
                    65,000+ Cr
                </span>
            </li>
            <li>
                <i className="credhome-settled" />
                <p>Settled Deals</p>
                <span>1500+</span>
            </li>
        </ul>
    </div>
);

export default LoginSidebar;
