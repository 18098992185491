import humps from 'humps';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import { toastr } from 'react-redux-toastr';
import { formatDate, isValidDate, formatDateV1 } from 'app/utils/DateUtils';
import { amountInCr, formatINR } from 'app/utils/StringUtils';
import { PAR_OPTIONS } from 'app/components/TransactionDetail/PTC/DealPerfV2/Container';
import { LONG_TERM_RATING, PRODUCT_API_ROUTES, PRODUCT_KEYS } from 'app/constants/Constants';
import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../loading';

const createActionName = (name: string) => `app/ptc/transaction/dealPerf/${name}`;
const ptcBaseUrl = (id: string, type: string) => `${process.env.REACT_APP_MP_API_HOST}/${type}/${id}`;
const initialState = {
    error: '',
    data: [],
};

const ratingOrder = LONG_TERM_RATING.map((item: any) => item.value);

export const DEAL_PERFORMANCE_LOADING = createLoadingSelector([createActionName('DEAL_PERF_GET')]);

export const DEAL_PERF_GET_REQUEST = createActionName('DEAL_PERF_GET_REQUEST');
export const DEAL_PERF_GET_SUCCESS = createActionName('DEAL_PERF_GET_SUCCESS');
export const DEAL_PERF_GET_FAILURE = createActionName('DEAL_PERF_GET_FAILURE');

export enum PAR_STATUS {
    loss = 'loss',
    gain = 'gain',
}
export const emptyHeaderItem = {
    key: '',
    label: '',
};
const payoutHeaders = [
    {
        key: ['payoutDate'],
        label: 'Payout Date',
    },
    {
        key: ['expectedPrincipal'],
        label: 'Expected Principal',
    },
    {
        key: ['principalCollected'],
        label: 'Collected Principal',
    },
    {
        key: ['expectedInterest'],
        label: 'Expected Interest',
    },
    {
        key: ['interestCollected'],
        label: 'Collected Interest',
    },
];

const cEHeaders = [
    {
        key: ['payoutDate'],
        label: 'Payout Date',
    },
    {
        key: ['cashCollateral'],
        label: 'Cash Collateral',
    },
    {
        key: ['principalSubordinate'],
        label: 'Principal Subordinate',
    },
    {
        key: ['overCollateral'],
        label: 'Over Collateral',
    },
    {
        key: ['eis'],
        label: 'EIS',
    },
    {
        key: ['creditEnhancement'],
        label: 'Total CE',
    },
];

const investorCashflowHeaders = [
    {
        key: ['payoutDate'],
        label: 'Payout Date',
    },
    {
        key: ['expectedPrincipal'],
        label: 'Expected Principal',
    },
    { key: ['totalPrincipalCollected'], label: 'Total Principal Collection' },
    { key: ['closingPosWithOd'], label: 'Closing POS Including O.D' },
    {
        key: ['expectedInterest'],
        label: 'Expected Interest',
    },
    { key: ['totalInterestCollected'], label: 'Total Interest Collection' },
    { key: ['closingInterestOd'], label: 'Closing Interest Overdue' },
];

export default function dealPerformance(state = initialState, action: any) {
    switch (action.type) {
        case DEAL_PERF_GET_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case DEAL_PERF_GET_FAILURE:
            return {
                ...state,
                error: action.message,
            };
        default:
            return state;
    }
}
export const calculateFixed = (value: number, mult = 1): number => Number((value * mult).toFixed(2));

export const mapData = (d: any, transactionType: string) => {
    const delinquencyData = (_get(d, 'delinquencyVsLossProtection') || [])?.slice(-3);
    const transitionMatrixHeaders = Array(_get(d, 'transitionMatrix[0].length', 0))
        ?.fill(1)
        .map((m, i) => m * i);
    const sortedRatingUpgradeData = _reverse(_sortBy(_get(d, 'ratingUpgrade', []), (item) => ratingOrder.indexOf(item.rating)));
    if (!d) {
        return null;
    }
    const formatDateMMMYY = (date: any): any => formatDate(date, 'MMM YY');
    const calculateFixed = (value: number, mult = 1): number => Number((value * mult)?.toFixed(2));
    const isDATransaction = [PRODUCT_KEYS.direct_assignment, PRODUCT_KEYS.assignment].includes(transactionType);
    return {
        ...d,
        dealPerformanceSummary: {
            ...d.dealPerformanceSummary,
            initialPoolPrincipal: `${amountInCr(Number(_get(d, 'dealPerformanceSummary.initialPoolPrincipal', 0))?.toFixed(2))} Cr(s)`,
            osPoolPrincipal: `${amountInCr(Number(_get(d, 'dealPerformanceSummary.osPoolPrincipal', 0))?.toFixed(2))} Cr(s)`,
            osPoolPrincipalInvestorShare: d?.dealPerformanceSummary?.osPoolPrincipalInvestorShare
                ? `${amountInCr(Number(_get(d, 'dealPerformanceSummary.osPoolPrincipalInvestorShare', 0))?.toFixed(2))} Cr(s)`
                : '',
            cumulativeCe: `${calculateFixed(_get(d, 'dealPerformanceSummary.cumulativeCe', 0))} %`,
            periodwiseCe: `${calculateFixed(_get(d, 'dealPerformanceSummary.periodwiseCe', 0))} %`,
            threeMonthPeriodCe: `${calculateFixed(_get(d, 'dealPerformanceSummary.threeMonthPeriodCe', 0))} %`,
            trusteeReportMonth: isValidDate(_get(d, 'dealPerformanceSummary.trusteeReportMonth', ''))
                ? formatDate(_get(d, 'dealPerformanceSummary.trusteeReportMonth'), 'DD MMM, YYYY')
                : '-',
            breakevenCe: `${calculateFixed(_get(d, 'dealPerformanceSummary.breakevenCe', 0), 100)} %`,
            amortization: `${calculateFixed(_get(d, 'dealPerformanceSummary.amortization', 0), 100)} %`,
            npaLoansOutstandingPos: d?.dealPerformanceSummary?.npaLoansOutstandingPos
                ? `${amountInCr(Number(_get(d, 'dealPerformanceSummary.npaLoansOutstandingPos', 0))?.toFixed(2))} Cr(s)`
                : 0,
            overdueAmount:
                d?.dealPerformanceSummary?.overdueAmount > 0
                    ? `${amountInCr(Number(_get(d, 'dealPerformanceSummary.overdueAmount', 0))?.toFixed(2))} Cr(s)`
                    : 0,
            npaPosPercent: d?.dealPerformanceSummary?.npaPosPercent ? `${Number(d?.dealPerformanceSummary?.npaPosPercent)?.toFixed(2)} %` : '0%',
        },
        collectionEfficiencyTrends: (_get(d, 'ceAndPrepaymentTrends', []) || []).map((ceData: any) => ({
            name: formatDateMMMYY(ceData.payoutDate),
            pv: calculateFixed(_get(ceData, 'cumulativeCollectionEfficiency', 0)),
        })),
        prepaymentTrends: (_get(d, 'ceAndPrepaymentTrends', []) || []).map((pData: any) => ({
            name: formatDateMMMYY(pData.payoutDate),
            uv: calculateFixed(_get(pData, 'cpr', 0)),
            pv: Number(amountInCr(pData.prepayments)),
        })),
        cashflows: (_get(d, 'cashflows', []) || []).map((cfData: any) => ({
            ...cfData,
            payoutDate: formatDate(cfData.payoutDate, 'DD-MM-YYYY'),
            expectedPrincipal: cfData.expectedPrincipal ? formatINR(cfData.expectedPrincipal) : '',
            principalCollected: cfData.principalCollected ? formatINR(cfData.principalCollected) : '',
            expectedInterest: cfData.expectedInterest ? formatINR(cfData.expectedInterest) : '',
            interestCollected: cfData.interestCollected ? formatINR(cfData.interestCollected) : '',
            shortfallFundedByEis: isDATransaction ? '' : formatINR(cfData.shortfallFundedByEis),
        })),
        payoutTableData: {
            columns: payoutHeaders,
            data: (_get(d, 'cashflows', []) || []).reverse().map((cfData: any, index: number) => {
                return {
                    index: _get(d, 'cashflows', []).length - index - 1,
                    className: '',
                    data: payoutHeaders.map((col: any, i: number) => ({
                        key: i,
                        label:
                            i === 0
                                ? formatDate(_get(cfData, `${col.key[0]}`), 'DD-MM-YYYY')
                                : _get(cfData, `${col.key[0]}`)
                                ? formatINR(Number(_get(cfData, `${col.key[0]}`)))
                                : '',
                    })),
                };
            }),
        },
        transitionMatrix: isDATransaction
            ? []
            : (_get(d, 'transitionMatrix', []) || []).map((tmData: any) => (tmData || []).map((item: number) => calculateFixed(item, 100))),
        creditEnhancement: (_get(d, 'lossProtection', []) || []).map((lpData: any) => ({
            payOutDate: formatDateMMMYY(lpData.payoutDate),
            cashCollatPer: calculateFixed(_get(lpData, 'cashCollateral', 0), 100),
            eisPer: calculateFixed(_get(lpData, 'eis', 0), 100),
            overCollatPer: calculateFixed(_get(lpData, 'overCollateral', 0), 100),
        })),
        creditEnhancementTableData: {
            columns: cEHeaders,
            data: (_get(d, 'lossProtection', []) || []).map((cfData: any, index: number) => ({
                index,
                className: '',
                data: cEHeaders.map((col: any, i: number) => ({
                    key: i,
                    label:
                        i === 0
                            ? formatDate(_get(cfData, `${col.key[0]}`), 'DD-MM-YYYY')
                            : `${calculateFixed(_get(cfData, `${col.key[0]}`, 0), 100)} %`,
                })),
            })),
        },
        transitionMatrixTableData: isDATransaction
            ? {}
            : {
                  columns: [
                      {
                          key: '',
                          label: 'STATE',
                      },
                      ...transitionMatrixHeaders.map((tData: any) => ({
                          key: tData,
                          label: tData,
                      })),
                  ],
                  data: (_get(d, 'transitionMatrix', []) || [])
                      .filter((item: any, index: number) => index < 7)
                      .map((item: any, index: number) => ({
                          index,
                          className: '',
                          data: [
                              {
                                  key: index,
                                  label: index,
                                  index: 0,
                              },
                              ...item
                                  .filter((item: any, index: number) => index < 7)
                                  .map((tData: any, i: number) => ({
                                      key: i + 1,
                                      index: i + 1,
                                      label: calculateFixed(tData, 100),
                                  })),
                          ],
                      })),
              },
        collEffTrendsTableData: {
            columns: [
                emptyHeaderItem,
                ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                    key: dData.payoutDate,
                    label: formatDate(dData.payoutDate, 'YYYY-MM-DD'),
                })),
            ],
            data: [
                {
                    data: [
                        {
                            key: 'demand',
                            label: 'Demand',
                        },
                        ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                            key: dData.cumulativeDemand,
                            label: formatINR(dData.cumulativeDemand),
                        })),
                    ],
                },
                {
                    data: [
                        {
                            key: 'oD',
                            label: 'OD',
                        },
                        ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                            key: dData.cumulativeOd,
                            label: formatINR(dData.cumulativeOd),
                        })),
                    ],
                },
                {
                    data: [
                        {
                            key: 'cE',
                            label: 'CE',
                        },
                        ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                            key: dData.cumulativeCollectionEfficiency,
                            label: `${calculateFixed(_get(dData, 'cumulativeCollectionEfficiency', 0))} %`,
                        })),
                    ],
                },
            ],
        },
        prepaymentTrendsTableData: {
            columns: [
                emptyHeaderItem,
                ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                    key: dData.payoutDate,
                    label: formatDate(dData.payoutDate, 'YYYY-MM-DD'),
                })),
            ],
            data: [
                {
                    data: [
                        {
                            key: 'prepayments',
                            label: 'Prepayments',
                        },
                        ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                            key: dData.prepayments,
                            label: formatINR(dData.prepayments),
                        })),
                    ],
                },
                {
                    data: [
                        {
                            key: 'cpr',
                            label: 'CPR',
                        },
                        ...(_get(d, 'ceAndPrepaymentTrends', []) || []).map((dData: any) => ({
                            key: dData.cpr,
                            label: `${calculateFixed(_get(dData, 'cpr', 0))} %`,
                        })),
                    ],
                },
            ],
        },
        par0: delinquencyData?.map((pData: any) => ({
            name: formatDateMMMYY(pData.payoutDate),
            uv: calculateFixed(_get(pData, 'par0', 0), 100),
            pv: calculateFixed(_get(pData, 'par0ByCe', 0), 100),
        })),
        par30: delinquencyData?.map((pData: any) => ({
            name: formatDateMMMYY(pData.payoutDate),
            uv: calculateFixed(_get(pData, 'par30', 0), 100),
            pv: calculateFixed(_get(pData, 'par30ByCe', 0), 100),
        })),
        par90: delinquencyData?.map((pData: any) => ({
            name: formatDateMMMYY(pData.payoutDate),
            uv: calculateFixed(_get(pData, 'par90', 0), 100),
            pv: calculateFixed(_get(pData, 'par90ByCe', 0), 100),
        })),
        parTableData: {
            columns: [
                emptyHeaderItem,
                ...delinquencyData?.map((dData: any) => ({
                    key: dData.payoutDate,
                    label: formatDate(dData.payoutDate, 'MMM-YY'),
                })),
            ],
            data: PAR_OPTIONS.map((item: any, index: number) => ({
                index,
                className: '',
                data: [
                    {
                        key: item.value,
                        label: item.shortLabel,
                        index: 0,
                    },
                    ...delinquencyData?.map((pData: any, i: number) => ({
                        key: i + 1,
                        index: i + 1,
                        label: calculateFixed(_get(pData, `${item.value}`, 0), 100),
                        status:
                            _get(pData, `${item.value}`, 0) > _get(delinquencyData, `[${i - 1}].${item.value}`, 0)
                                ? PAR_STATUS.loss
                                : _get(pData, `${item.value}`, 0) < _get(delinquencyData, `[${i - 1}].${item.value}`, 0)
                                ? PAR_STATUS.gain
                                : '',
                    })),
                ],
            })),
        },
        npaBucketing:
            isDATransaction && d?.npaBucketing
                ? {
                      columns: [
                          { key: 'dpdDays', label: 'DPD Days' },
                          { key: 'pos', label: 'POS' },
                          { key: 'percent', label: 'Percent on latest POS' },
                      ],
                      data: Object.keys(d?.npaBucketing).map((bucket, index) => {
                          return {
                              index,
                              className: '',
                              data: [
                                  { key: index, index, label: d?.npaBucketing?.[bucket]?.label },
                                  { key: index + 1, index: index + 1, label: formatINR(d?.npaBucketing?.[bucket]?.outstandingPos) },
                                  { key: index + 2, index: index + 1, label: `${d?.npaBucketing?.[bucket]?.latestPosPercent}%` },
                              ],
                          };
                      }),
                  }
                : {},
        ratingUpgrade:
            sortedRatingUpgradeData &&
            sortedRatingUpgradeData.map((rData: any, index: number) => {
                const currentRating = _get(rData, 'cePercent', 0);
                const lastRating = _get(sortedRatingUpgradeData, `[${index - 1}].cePercent`, 0);
                return {
                    name: rData.rating,
                    label: `${rData.payoutMonth} | ${rData.cePercent}`,
                    uv: index === 0 ? currentRating : currentRating - lastRating,
                    pv: index === 0 ? 0 : currentRating - (currentRating - lastRating),
                };
            }),
        cashflowsInvestorShare:
            isDATransaction && d?.cashflowsInvestorShare
                ? {
                      columns: investorCashflowHeaders,
                      data: d?.cashflowsInvestorShare?.reverse()?.map((cfData: any, index: number) => ({
                          index: d?.cashflowsInvestorShare?.length - index - 1,
                          className: '',
                          data: investorCashflowHeaders.map((col: any, i: number) => ({
                              key: i,
                              label:
                                  i === 0
                                      ? _get(cfData, `${col.key[0]}`)
                                          ? formatDateV1(_get(cfData, `${col.key[0]}`), 'DD-MM-YYYY')
                                          : ''
                                      : _get(cfData, `${col.key[0]}`) && _get(cfData, `${col.key[0]}`) > 0
                                      ? formatINR(Number(_get(cfData, `${col.key[0]}`)))
                                      : 0,
                          })),
                      })),
                  }
                : {},
    };
};

export function getDealPerformance(id: any, type: string) {
    return (dispatch: any) => {
        dispatch({ type: DEAL_PERF_GET_REQUEST, id });
        return fetchUtils
            .getJSON(`${ptcBaseUrl(id, type)}/deal_performance`)
            .then((d: any) => humps.camelizeKeys(
                d
                           ))
                           .then((d: any) => {
                const transactionType = type === PRODUCT_API_ROUTES.ptcv1 ? PRODUCT_KEYS.ptc : PRODUCT_KEYS.securitization;
                const payload = mapData(d, transactionType);
                return dispatch({
                    type: DEAL_PERF_GET_SUCCESS,
                    payload,
                    id,
                });
            })
            .catch((err: any) => {
                return fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                    toastr.error('Error fetching deal performance', m);
                    return dispatch({ type: DEAL_PERF_GET_FAILURE, message: m, id });
                });
            });
    };
}
