import styled from 'styled-components';

const primaryColor = '#293577';
const secondaryColor = '#00c7cc';

const StyledFooterLinks = styled.footer`
    background: #eae9ee;
    padding: 50px 0;
    font-family: 'Sofia Pro', sans-serif;
    .container {
        .footer_logo {
            figure {
                max-width: 300px;
                img {
                    backface-visibility: initial;
                }
            }
        }
        .social-links {
            display: none;
        }
        .footer_links {
            ul.footer_links-list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                li {
                    h4 {
                        color: ${primaryColor};
                        font-weight: 700;
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                    li {
                        margin: 10px 0;
                        a {
                            color: ${primaryColor};
                            font-size: 13px;
                            font-weight: 500;
                            &:hover {
                                color: ${secondaryColor};
                            }
                        }
                    }
                }
            }
        }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .container {
            .footer_links {
                ul.footer_links-list {
                    > li {
                        width: 25%;
                        vertical-align: top;
                    }
                }
            }
        }
    }
    @media (max-width: 1365px) {
        .container {
            max-width: 100%;
            padding: 0 20px;
        }
    }
    @media (max-width: 1199px) {
        .container {
            max-width: 100%;
            padding: 0 20px;
        }
        .span5 {
            width: 30%;
        }
        .span7 {
            width: 70%;
        }
    }
    @media (max-width: 991px) {
        .container {
            max-width: 100%;
            padding: 0 20px;
            .span5 {
                width: 30%;
            }
            .span7 {
                width: 70%;
            }
            .footer_links {
                ul.footer_links-list {
                    li {
                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .container {
            max-width: 100%;
            padding: 0 20px;
            [class*='span'] {
                width: 100%;
            }
            .footer_logo {
                figure {
                    margin-bottom: 20px;
                    max-width: 160px;
                }
            }
            .social-links {
                display: block;
                position: absolute;
                top: 0;
                right: 20px;
                li {
                    a {
                        color: ${primaryColor};
                        &:hover {
                            color: ${secondaryColor};
                        }
                        i {
                            vertical-align: middle;
                        }
                    }
                }
            }
            .footer_links {
                ul.footer_links-list {
                    display: block;
                    li {
                        display: block;
                        margin-bottom: 20px;
                        padding: 0;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        h4 {
                            margin-bottom: 5px;
                            font-size: 16px;
                        }
                        li {
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
    }
`;

export default StyledFooterLinks;
