import styled from 'styled-components';
import ReactToolTip from 'react-tooltip';
import { getColor } from 'app/products/UIComponents/Utils/colorSets';

export const Row = styled.div`
    box-sizing: border-box;
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
`;
export const ClearFix = styled.div`
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
`;
export const PullLeft = styled.div`
    float: left;
`;
export const PullRight = styled.div`
    float: right;
`;
export const Span1 = styled.div`
    width: 8.33333333%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span2 = styled.div`
    width: 16.66666667%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span3 = styled.div`
    width: 25%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span4 = styled.div`
    width: 33.33333333%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span5 = styled.div`
    width: 41.66666667%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span6p5 = styled.div`
    width: 54.16665%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span6 = styled.div`
    width: 50%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span7 = styled.div`
    width: 58.33333333%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span8 = styled.div`
    width: 66.66666667%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span9 = styled.div`
    width: 75%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span10 = styled.div`
    width: 83.33333333%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span11 = styled.div`
    width: 91.66666667%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const Span12 = styled.div`
    width: 100%;
    position: relative;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
`;
export const CustomScroll = styled.div`
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
`;
export const Media = styled.div`
    display: table;
    width: 100%;
    @media (max-width: 767px) {
        align-items: center;
    }
`;
export const MediaBody = styled.div`
    display: table-cell;
    vertical-align: middle;
`;

export const UploadPool = styled.div`
    text-align: center;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    > a {
        color: #009e0f;
        border: 1px solid #009e0f;
        background: #fff;
        border-radius: 3px;
        display: inline-block;
        font-size: 12px;
        padding: 6px 16px;
        text-transform: uppercase;
        min-width: 150px;
        text-align: center;
        font-weight: 600;
        transition: 1s all ease;
        &:hover {
            background: #009e0f;
            color: #fff;
        }
    }
`;

export const SelectFieldWrapper = styled.div`
    label {
        color: ${getColor('grey', '600')};
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    .select-primary {
        .error-text {
            margin-left: 12px;
        }
        .Select-control {
            border: 1px solid #9aa5b8 !important;
            border-radius: 12px !important;
        }
        .Select-menu-outer {
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
            border-radius: 6px 6px 12px 12px !important;
            margin-top: 2px;
            .Select-menu {
                .Select-option {
                    margin: 2px 4px;
                    border-radius: 12px !important;
                    background: none !important;
                }
                .is-focused {
                    border-radius: 12px;
                    color: ${getColor('primary', 'primary')};
                    background: ${getColor('primary', '50')} !important;
                }
            }
        }
    }
    .is-disabled > .Select-control {
        background: #f2f5f9 !important;
        opacity: 0.8 !important;
        border: none !important;
    }
    .Select-placeholder {
        color: ${getColor('grey', '400')};
    }
    .Select-arrow {
        border-width: none;
        color: ${getColor('grey', '400')};
        &:hover {
            color: ${getColor('grey', '400')};
        }
    }
    .error {
        .Select-control {
            color: #9aa5b8 !important;
            border: 1.5px solid #ee4444 !important;
            background: #fdf1f1;
        }
        .error-text {
            margin-left: 4px;
        }
    }
    .is-focused {
        .Select-control {
            border: 1.5px solid ${getColor('primary', 'primary')} !important;
            box-shadow: 0px 0px 8px rgba(59, 130, 246, 0.3) !important;
        }
    }
`;

export const ToolTip = styled(ReactToolTip)`
    &.__react_component_tooltip.type-dark.place-top {
        background: ${getColor('secondary', '700')};
        border-radius: 12px;
        padding: 12px 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        &:after {
            border-top-color: ${getColor('secondary', '700')};
        }
    }
    &.__react_component_tooltip.type-dark.place-right {
        background: ${getColor('secondary', '700')};
        border-radius: 12px;
        padding: 12px 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        &:after {
            border-right-color: ${getColor('secondary', '700')};
        }
    }
    &.__react_component_tooltip.type-dark.place-left {
        background: ${getColor('secondary', '700')};
        border-radius: 12px;
        padding: 12px 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        &:after {
            border-left-color: ${getColor('secondary', '700')};
        }
    }
    &.__react_component_tooltip.type-dark.place-bottom {
        background: ${getColor('secondary', '700')};
        border-radius: 12px;
        padding: 12px 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        &:after {
            border-bottom-color: ${getColor('secondary', '700')};
        }
    }
`;

export const MonthPickerWrapper = styled.div`
    > div {
        padding: 0;
    }
    .ym-calendar {
        > button {
            color: ${getColor('primaryV1', '500')} !important;
            > i {
                color: ${getColor('primaryV1', '500')} !important;
            }
        }
        .react-calendar {
            border-radius: 8px !important;
            left: 0 !important;
            top: 25px !important;
            &:before,
            &:after {
                display: none;
            }
            .react-calendar__year-view__months {
                .react-calendar__tile--active {
                    background: ${getColor('primaryV1', '500')} !important;
                }
                button {
                    &:hover {
                        background: ${getColor('primaryV1', '500')}!important;
                    }
                }
            }
        }
    }
`;
