import humps from 'humps';

import { toastr } from 'react-redux-toastr';

import * as fetchUtils from 'app/utils/FetchUtils';
import { assetClassStructure } from 'app/utils/TransactionUtils.tsx';

const INITIAL_STATE = [];

// actions
const createActionName = name => `app/assetClasses/${name}`;

export const CUSTOMER_GET = createActionName('CUSTOMER_GET');
export const GET_ASSET_CLASSES = createActionName('GET_ASSET_CLASSES');

/**
 * @param {Array} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Array} New state
 */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case GET_ASSET_CLASSES:
        return [...state, ...action.data];
    default:
        return state;
    }
};

export function getAssetClasses() {
    return dispatch =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_CS_API_HOST}/customer/asset_class_list`)
            .then(d => humps.camelizeKeys(d))
            .then((data) => {
                dispatch({ type: GET_ASSET_CLASSES, data: assetClassStructure(data.assetClassList) });
                return data;
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving asset class data', m);
                }));
}
