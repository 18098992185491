export type AmplitudeEvent = {
    eventName: string;
    type?: string;
    category?: string;
};

export const EventTypes = {
    track: 'amplitude.eventTypes.track',
    identify: 'amplitude.eventTypes.identify',
};

export enum AmplitudeResourceTypes {
    Deal = 'deal',
    Profile = 'profile',
}

export enum AuthType {
    Web = 'web',
    Microsoft = 'microsoft',
}

export enum NetworkStatusCode {
    OK = 200,
    UnAuthenticated = 403,
    UnAuthorized = 401,
    SystemFailed = 500,
}

export enum UserGroups {
    Admin = 'admin',
    Product = 'product',
    Operations = 'operations',
    Client = 'client',
    Customer = 'customer',
    Internal = 'internal',
    CPS = 'cps',
    Investor = 'investor',
    Auditor = 'auditor',
    Treasury = 'treasury',
    Trustee = 'trustee',
    LawFirm = 'law_firm',
    RatingAgency = 'rating_agency',
    ClientAquisitionGroup = 'client_acquisition_group',
    ClientServiceGroup = 'client_service_group',
    Credit = 'credit',
    InstitutionalBusiness = 'institutional_business',
    CapitalMarkets = 'capital_markets',
    Risk = 'risk',
    Guarantor = 'guarantor',
    CollateralManager = 'collateral_manager',
    InternalAuditor = 'internal_auditor',
    Arranger = 'arranger',
}

export enum UserRoles {
    Admin = 'admin',
    Associate = 'associate',
}

export enum CAModules {
    Pools = 'pools',
    Loans = 'loans',
    SupplyChainFinance = 'tf',
    CoLend = 'colend',
    Plutus = 'plutus',
    Enity = 'entity',
    Credit = 'credit',
    MarketPlace = 'marketplace',
    CommonPlatforms = 'auth',
    CredAvenueWeb = 'credavenue_web',
}

export interface EventUserProps {
    userid: string;
    userEmail: string;
    entityName: string;
    entityId: string;
    eventDescription?: string;
    resourceID?: string;
    resourceType?: AmplitudeResourceTypes;
    group: UserGroups;
    subGroup?: any;
    role: UserRoles;
    module: CAModules;
}

export type AmplitudeCategory = Record<string, AmplitudeEvent>;
