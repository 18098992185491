import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import { SURVEY_SUCCESS } from '../constants/Routes';
import * as RouterUtils from '../utils/RouterUtils';
import * as fetchUtils from '../utils/FetchUtils';

const initialState = null;

// actions
const createActionName = name => `app/survey/${name}`;

export const SURVEY_GET = createActionName('SURVEY_GET');

export default function details(state = initialState, action) {
    switch (action.type) {
    case SURVEY_GET:
        return {
            ...state,
            [action.surveyId]: {
                ...action.data,
            },
        };
    default:
        return state;
    }
}

const surveyBaseUrl = surveyId => `${process.env.REACT_APP_MP_API_HOST}/surveys/${surveyId}`;

const mapDetails = (data) => {
    const cpData = data.counterParties.map((k) => {
        const { type, qualityAttributes } = k;
        return { type, qualityAttributes };
    });
    return { survey: { counter_parties: humps.decamelizeKeys(cpData) } };
};

const unMapDetails = (data) => {
    const cdata = humps.camelizeKeys(data);
    return { ...cdata };
};

export function getSurvey(surveyId, userId) {
    return dispatch =>
        fetchUtils
            .getJSON(`${surveyBaseUrl(surveyId)}?user_id=${userId}`)
            .then(d => unMapDetails(d))
            .then((data) => {
                dispatch({ type: SURVEY_GET, data, surveyId });
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Survey', m);
                }));
}

export function updateSurvey(surveyId, data, userId) {
    return dispatch =>
        fetchUtils
            .putJSON(`${surveyBaseUrl(surveyId)}?user_id=${userId}`, mapDetails(data))
            .then(d => unMapDetails(d))
            .then((d) => {
                dispatch({ type: SURVEY_GET, data, surveyId });
                return d;
            })
            .then(() => {
                dispatch(RouterUtils.pushRoute({
                    to: SURVEY_SUCCESS,
                }));
            })
            .catch(ex =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error submitting survey', m);
                }));
}
