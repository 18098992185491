import { toastr } from 'react-redux-toastr';
import uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import _has from 'lodash/has';
import humps from 'humps';
import moment from 'moment';
import * as fetchUtils from 'app/utils/FetchUtils';
import * as ampTypes from 'app/constants/AmplitudeActions';
import { humanize } from 'app/utils/StringUtils';
import { getTenor, getYieldPercent, getAssetClass, ratingToString, getProductType } from 'app/utils/TransactionUtils';
import { DATES, TRANSACTION_STATE, PRODUCT_KEYS, PRODUCT_API_ROUTES } from 'app/constants/Constants';
import { TRANSACTION_LIST_FIELDS, SORTING_DATES } from 'app/constants/TransactionList';
import { queryConstructor, valuesToAmount } from 'app/utils/CommonUtils';
import { EventTypes } from '../utils/reduxAmplitude';
import { getJSON, handleError } from 'app/utils/FetchUtils';
import { getActiveEntityId, getActiveUser } from 'app/actions/AuthedActions';
const { isClient } = getActiveUser();

const initialState = {
    transactionList: { count: 0, list: [] },
    transactionListPage: 1,
    transactionFilter: [{ name: 'tab', value: '' }],
    queryParams: '',
    isFetching: false,
    isFiltered: false,
    filters: { sort_by: _get(SORTING_DATES, '[0].value') },
    layout: 'list',
};

// actions
const createActionName = (name) => `app/transaction/${name}`;
export const TRANSACTION_LIST = createActionName('TRANSACTION_LIST');
export const TRANSACTION_LIST_VIEW_MORE = createActionName('TRANSACTION_LIST_VIEW_MORE');
export const TRANSACTION_LIST_FILTER = createActionName('TRANSACTION_LIST_FILTER');
export const TRANSACTION_LIST_FILTER_PARAMS = createActionName('TRANSACTION_LIST_FILTER_PARAMS');
export const TRANSACTION_LIST_LAYOUT_TOGGLE = createActionName('TRANSACTION_LIST_LAYOUT_TOGGLE');

export const FETCHING_DATA = createActionName('FETCHING_DATA');

export default function transactions(state = initialState, action) {
    const defaultSortBy = [TRANSACTION_STATE.SETTLED, TRANSACTION_STATE.MATURED].includes(_get(action, 'data.value'))
        ? _get(SORTING_DATES, '[1].value')
        : _get(SORTING_DATES, '[0].value');
    switch (action.type) {
        case TRANSACTION_LIST: {
            return Object.assign({}, state, {
                transactionList: {
                    count: action.data.totalCount,
                    list:
                        parseInt(action.page, 10) > 1
                            ? uniqBy([...state.transactionList.list, ...action.data.transactions], 'transId')
                            : action.data.transactions,
                },
                isFetching: false,
                isFiltered: action.isFiltered,
                transactionListPage: action.page,
            });
        }
        case TRANSACTION_LIST_VIEW_MORE:
            return Object.assign({}, state, {
                transactionListPage: state.transactionListPage + 1,
            });
        case TRANSACTION_LIST_FILTER:
            return {
                ...state,
                transactionFilter: uniqBy([action.data, ...state.transactionFilter], (o) => o.name),
                transactionList: { count: 0, list: [] },
                transactionListPage: 1,
                queryParams: queryConstructor({
                    ...state.filters,
                    ...(_has(state, 'filters.investor.value') ? { investor: _get(state, 'filters.investor.value') } : {}),
                    sort_by: defaultSortBy,
                }),
                filters: {
                    ...state.filters,
                    sort_by: defaultSortBy,
                },
            };
        case TRANSACTION_LIST_FILTER_PARAMS:
            return {
                ...state,
                queryParams: action.data.searchTerm,
                filters: action.data.filtersData,
                // Filter is active only when fields other than sory_by has value.
                isFilterActive: Object.keys(action.data.filtersData).filter((m) => m !== 'sort_by' && action.data.filtersData[m] !== '').length !== 0,
                transactionListPage: 1,
            };
        case TRANSACTION_LIST_LAYOUT_TOGGLE:
            return {
                ...state,
                layout: action.data.layout,
            };
        case FETCHING_DATA:
            return Object.assign({}, state, {
                isFetching: action.status,
            });
        default:
            return state;
    }
}

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function transactionList(data, page, isFiltered) {
    return {
        type: TRANSACTION_LIST,
        data,
        page,
        isFiltered,
    };
}

export function transactionListViewMore() {
    return {
        type: TRANSACTION_LIST_VIEW_MORE,
    };
}

export function transactionFilterUpdate(data) {
    return {
        type: TRANSACTION_LIST_FILTER,
        data,
    };
}

export function transactionFilterParams(data) {
    return {
        type: TRANSACTION_LIST_FILTER_PARAMS,
        data,
    };
}

export function transactionLayoutUpdate(data) {
    return {
        type: TRANSACTION_LIST_LAYOUT_TOGGLE,
        data,
    };
}

export const getFields = (data, productType) => {
    const fieldKeys = TRANSACTION_LIST_FIELDS[`${productType}`];
    const assetClass = getAssetClass(data[`${fieldKeys.assetClass}`]);
    const tenorValue = data[fieldKeys.tenor]
        ? data[fieldKeys.tenor]
        : _get(data, 'leanTransaction', false)
        ? _get(data, 'poolPreference.poolTenor')
        : '';
    const amountValue = data[fieldKeys.amount]
        ? data[fieldKeys.amount]
        : _get(data, 'leanTransaction', false)
        ? `${valuesToAmount(_get(data, 'poolPreference.poolPrincipal'), 10000000)} CRORES`
        : '';

    return {
        transId: data[`${fieldKeys.transId}`],
        status: data[`${fieldKeys.status}`],
        clientName: data[`${fieldKeys.clientName}`],
        dealName: data[`${fieldKeys.dealName}`],
        product: productType,
        assetClass: assetClass ? assetClass : _get(data, 'leanTransaction', false) ? humanize(_get(data, 'poolPreference.assetClass')) : '-',
        rating: ratingToString(data[`${fieldKeys.rating}`]).replace(/\s*,\s*|\s+,/g, ' | '),
        amount: productType === PRODUCT_KEYS.pooled_loan_issuance && amountValue ? `${data[`${fieldKeys.amount}`]} CRORES` : amountValue,
        pricing: _get(data, 'leanTransaction', false)
            ? _get(data, 'poolPreference.indicativeCoupon')
                ? `${_get(data, 'poolPreference.indicativeCoupon')}%`
                : getYieldPercent(data[`${fieldKeys.pricing}`], productType, true)
            : getYieldPercent(data[`${fieldKeys.pricing}`], productType, true),

        tenor: getTenor(tenorValue, productType),
        createdOn:
            data[`${fieldKeys.createdOn}`] && moment(data[`${fieldKeys.createdOn}`]).isValid()
                ? moment(data[`${fieldKeys.createdOn}`]).format(DATES.formatThree)
                : '-',
        executionDate:
            moment(data[`${fieldKeys.executionDate}`]).isValid() && data[`${fieldKeys.executionDate}`]
                ? moment(data[`${fieldKeys.executionDate}`]).format(DATES.formatThree)
                : '-',
        customers: data[`${fieldKeys.customers}`],
        recommendedScore: data[`${fieldKeys.recommendedScore}`],
        isHlfTransaction: data[`${fieldKeys.isHlfTransaction}`],
        bidsCount: data[`${fieldKeys.bidsCount}`],
        creditEnhancement: data[`${fieldKeys.creditEnhancement}`],
        isPslDeal: data[`${fieldKeys.isPslDeal}`],
        collectionEfficiencyMetrics: data[`${fieldKeys.collectionEfficiencyMetrics}`],
        investorInterestsCount: data[`${fieldKeys.investorInterestsCount}`],
        isNotLean: 'isNotLean' in fieldKeys ? data[`${fieldKeys.isNotLean}`] : true,
        trendingScore: data[`${fieldKeys.trendingScore}`],
        stcCompliant: data[`${fieldKeys.stcCompliant}`],
        isSelfCreated: data[`${fieldKeys.isSelfCreated}`],
    };
};

const mapTransactions = (data) => {
    return {
        ...data,
        transactions: data.transactions.map((t) => {
            const transType = _get(t, 'transactionType') === 'discount' ? 'discounting' : _get(t, 'transactionType', '');
            const productType = getProductType(transType);
            return getFields(t, productType);
        }),
    };
};

export function searchTransactions(page, filter, actor, searchTerm, itemsPerPage = 20) {
    return (dispatch) => {
        const tab = filter && filter.find((f) => f.name === 'tab');
        const tabValue = (tab && tab.value) || '';
        const isFiltered = true;
        const decamActor = humps.decamelize(humps.camelize(actor));
        dispatch(fetchingData());
        const { isInvestor } = getActiveUser();
        const url = encodeURI(
            `${
                process.env.REACT_APP_MP_API_HOST
            }/transactions?page=${page}&per_page=${itemsPerPage}&actor=${decamActor}&tab=${tabValue}&${searchTerm}${
                !isInvestor ? '&is_self_serve=false' : ''
            }`,
        );
        return fetchUtils
            .getJSON(url.replace('+', '%2B'))
            .then((d) => {
                dispatch(fetchingData(false));
                const data = humps.camelizeKeys(d);
                return dispatch(transactionList(mapTransactions(data), page, isFiltered));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function getClientIndustry(cid) {
    const url = `${process.env.REACT_APP_CS_OB_API_HOST}/entities/${cid}/customer`;

    try {
        return getJSON(url).then((d) => humps.camelizeKeys(d));
    } catch (e) {
        return handleError(e).then((err) => err);
    }
}

export function viewMoreTransactionList() {
    return (dispatch) => dispatch(transactionListViewMore());
}

export function updateTransactionFilter(data) {
    return (dispatch) => dispatch(transactionFilterUpdate(data));
}

export function updateFilterParams(data) {
    return (dispatch) => dispatch(transactionFilterParams(data));
}

export function transDetailsClick(id, productType, payload) {
    return (dispatch) =>
        dispatch({
            type: ampTypes.TRANS_DETAILS_CLICK,
            meta: {
                amplitude: [
                    {
                        eventType: EventTypes.track,
                        eventPayload: {
                            eventName: ampTypes.TRANS_DETAILS_CLICK,
                            transId: id,
                            type: productType,
                            ...payload,
                        },
                    },
                ],
            },
        });
}

function checkTransactionState(url, state = 'transactionState') {
    return (dispatch) =>
        fetchUtils
            .getJSON(url)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                if (d[state] === TRANSACTION_STATE.SETTLED) {
                    return d;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                }),
            );
}

export function pollTransactionState(id, url, state, getTrans, product) {
    return (dispatch) =>
        fetchUtils
            .poll(() => dispatch(checkTransactionState(url, state)), 180000, 1000)
            .then((d) => {
                if (product === PRODUCT_API_ROUTES.dav2) {
                    getTrans();
                } else {
                    dispatch(getTrans(id));
                }
                return d;
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                console.error(errMsg);
            });
}

export function getDraftTransactionsCount() {
    return fetchUtils
        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/transactions/draft_transactions_count`)
        .then((d) => humps.camelizeKeys(d))
        .catch((ex) =>
            fetchUtils.handleError(ex).then((m) => {
                toastr.error('Error retrieving draft transactions', m);
            }),
        );
}
