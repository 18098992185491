import humps from 'humps';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = null;

// actions
const createActionName = (name) => `app/ptc/transaction/summary/${name}`;

export const SUMMARY_FETCH_SUCCESS = createActionName('SUMMARY_FETCH_SUCCESS');

export default function summary(state = initialState, action) {
    switch (action.type) {
        case SUMMARY_FETCH_SUCCESS:
            return {
                ...action.data,
            };
        default:
            return state;
    }
}

const ptcBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}`;

export function getSummary(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${ptcBaseUrl(id)}/transaction_summary`)
            .then((d) => (d && d.transaction_summary && humps.camelizeKeys(d.transaction_summary)) || {})
            .then((data) => dispatch({ type: SUMMARY_FETCH_SUCCESS, data, id }))
            .catch(() => '');
}
