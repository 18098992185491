import { combineReducers } from 'redux';
import stats from './stats';
import portfolio from './portfolio';

const dashboardReducer = combineReducers({
    stats,
    portfolio,
});

export default dashboardReducer;
