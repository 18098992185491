import { toastr } from 'react-redux-toastr';
import uniqBy from 'lodash/uniqBy';
import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';
import * as RouterUtils from 'app/utils/RouterUtils';
import {
    CLIENT_INIT_TRANSACTION_DA_MCDUPLOAD,
    CLIENT_INIT_TRANSACTION_DA_RSUPLOAD,
    CLIENT_EDIT_TRANSACTION_DA_RSUPLOAD,
    CLIENT_INIT_TRANSACTION_DA_SUCCESS,
    CLIENT_DA_TRANS,
} from 'app/actors/client/constants/Routes';
import { CUSTOMER_INITIATE_TRANSACTION } from 'app/constants/AmplitudeActions';
import { EventTypes } from 'app/utils/reduxAmplitude';
import { getActiveUser } from 'app/actions/AuthedActions';
import { PRODUCT_KEYS } from 'app/constants/Constants';

const { isClient } = getActiveUser();

const createActionName = (name) => `app/client/da/${name}`;

export const CREATE_DA_TRANSACTION_SUCCESS = createActionName('CREATE_DA_TRANSACTION_SUCCESS');
export const TRANS_DA_POOL_FILE_HEADERS_REQUEST = createActionName('TRANS_DA_POOL_FILE_HEADERS_REQUEST');
export const TRANS_DA_POOL_FILE_HEADERS_SUCCESS = createActionName('TRANS_DA_POOL_FILE_HEADERS_SUCCESS');
export const TRANS_DA_POOL_FILE_DESTROY = createActionName('TRANS_DA_POOL_FILE_DESTROY');
export const TRANS_DA_POOL_FILE_HEADERS_ERROR = createActionName('TRANS_DA_POOL_FILE_HEADERS_ERROR');
export const TRANS_DA_POOL_FILE_UPLOAD = createActionName('TRANS_DA_POOL_FILE_UPLOAD');
export const TRANS_PTC_CREATE_REQUEST = createActionName('TRANS_PTC_CREATE_REQUEST');
export const TRANS_DA_CREATE_REQUEST = createActionName('TRANS_DA_CREATE_REQUEST');
export const TRANS_DA_CREATE_SUCCESS = createActionName('TRANS_DA_CREATE_SUCCESS');
export const TRANS_DA_POOL_FILE_422_ERROR = createActionName('TRANS_DA_POOL_FILE_422_ERROR');
export const TRANS_DA_CREATE_ERROR = createActionName('TRANS_DA_CREATE_ERROR');
export const TRANS_DA_GET = createActionName('TRANS_DA_GET');

const initialState = {
    initializedDA: null,
    poolFiles: [],
    details: {},
    poolSummary: {},
    clientPref: {},
    isFetching: false,
    errMessage: '',
    skipExtractHeaders: false,
    shouldSkipPoolSummary: false,
    actualHeaders: [],
    expectedHeaders: {},
    progress: false,
};

export default function transaction(state = initialState, action) {
    switch (action.type) {
        case CREATE_DA_TRANSACTION_SUCCESS:
            return {
                ...state,
                initializedDA: action.data,
            };
        case TRANS_DA_GET: {
            const files = [];

            const mcd = action.data.pool_files.filter((f) => f.type === 'mcd_file').reduce((r) => r);
            const rs = action.data.pool_files.filter((f) => f.type === 'rs_file').reduce((r) => r);
            if (rs && rs.file_id) {
                files.push({
                    id: rs.file_id,
                    document_type: 'rs_file',
                    file_name: rs.file_name,
                });
            }
            if (mcd && mcd.file_id) {
                files.push({
                    id: mcd.file_id,
                    document_type: 'mcd_file',
                    file_name: mcd.file_name,
                });
            }

            return {
                ...state,
                poolFiles: files,
                details: action.data,
            };
        }
        case TRANS_DA_POOL_FILE_HEADERS_REQUEST:
            return {
                ...state,
                errMessage: '',
                isFileHeadersLoading: true,
            };
        case TRANS_DA_POOL_FILE_HEADERS_SUCCESS:
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: '',
                poolFiles: state.poolFiles.map((f) => (action.data && action.fileId && f.id === action.fileId ? { ...f, ...action.data } : { ...f })),
            };
        case TRANS_DA_POOL_FILE_HEADERS_ERROR:
            return {
                ...state,
                errMessage: action.errorMsg,
                isFileHeadersLoading: false,
            };
        case TRANS_DA_POOL_FILE_DESTROY:
            return {
                ...state,
                poolFiles: state.poolFiles.filter((el) => el.document_type !== action.fileType),
                errMessage: null,
            };
        case TRANS_DA_POOL_FILE_UPLOAD:
            return {
                ...state,
                poolFiles: uniqBy([action.data, ...state.poolFiles], 'document_type'),
            };
        case TRANS_DA_POOL_FILE_422_ERROR:
            return {
                ...state,
                skipExtractHeaders: true,
            };
        case TRANS_DA_CREATE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case TRANS_DA_CREATE_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case TRANS_DA_CREATE_ERROR:
            return {
                ...state,
                errMessage: action.message,
                progress: false,
            };
        default:
            return state;
    }
}

function processDAData(data) {
    let prcessData = {};
    const trusteeIds = [];
    const ratingAgencyIds = [];
    const lawFirmIds = [];
    const auditorIds = [];

    if (data.ratingAgencyIds) ratingAgencyIds.push(data.ratingAgencyIds);
    if (data.lawFirmIds) lawFirmIds.push(data.lawFirmIds);
    if (data.auditorIds) auditorIds.push(data.auditorIds);
    if (data.trusteeIds) trusteeIds.push(data.trusteeIds);

    prcessData = {
        ...data,
        amount: data.amount * 10000000,
        ratingAgencyIds,
        lawFirmIds,
        auditorIds,
        trusteeIds,
        preferencePriority: [data.priority1, data.priority2, data.priority3],
    };

    return humps.decamelizeKeys(prcessData);
}

function mapCP(data) {
    return humps.camelizeKeys(data);
}

export function createDA(data) {
    return (dispatch) =>
        fetchUtils
            .postJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments`, {
                direct_assignment_transaction: processDAData(data),
            })
            .then((d) => {
                const processedD = mapCP(d);
                dispatch({
                    type: CREATE_DA_TRANSACTION_SUCCESS,
                    data: processedD,
                });
                return processedD;
            })
            .then((d) =>
                dispatch(
                    RouterUtils.pushRoute({
                        path: CLIENT_INIT_TRANSACTION_DA_MCDUPLOAD,
                        keys: { transId: d.id },
                    }),
                ),
            )
            .then(() => {
                dispatch({
                    type: CUSTOMER_INITIATE_TRANSACTION,
                    meta: {
                        amplitude: [
                            {
                                eventType: EventTypes.track,
                                eventPayload: {
                                    eventName: CUSTOMER_INITIATE_TRANSACTION,
                                    transaction_type: PRODUCT_KEYS.direct_assignment,
                                    ...data,
                                },
                            },
                        ],
                    },
                });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error Initiating DA', m);
                }),
            );
}

export function getDATrans(transId) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}`)
            .then((d) => {
                dispatch({ type: TRANS_DA_GET, data: d });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                }),
            );
}

export function destroyPTCPool(fileId, transId, fileType) {
    return (dispatch) => {
        if (transId) {
            return Promise.resolve(
                dispatch({
                    type: TRANS_DA_POOL_FILE_DESTROY,
                    fileType,
                    id: transId,
                }),
            );
        }
        return fetchUtils
            .deleteJSON(`${process.env.REACT_APP_MP_API_HOST}/transaction_files/${fileId}`)
            .then(() => {
                dispatch({
                    type: TRANS_DA_POOL_FILE_DESTROY,
                    id: fileId,
                });
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error removing pool file', m);
                }),
            );
    };
}

export function uploadPTCPoolV2(fileId, files, fileType, poolType, skipExtractHeaders = false, transId) {
    return (dispatch) =>
        fetchUtils
            .patchJSON(`${process.env.REACT_APP_MP_API_HOST}/${fileType}s/${fileId}`, {
                [fileType]: {
                    document_file_name: files[0].name,
                    document_content_type: files[0].type,
                    document_file_size: files[0].size,
                    transaction_id: transId,
                },
                transaction_id: transId,
            })
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(pollFileHeaders(fileId, poolType, transId));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_DA_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
}

export function uploadPTCPool(file, fileType, poolType, skipExtractHeaders = false) {
    return (dispatch) => {
        const body = new FormData();
        Object.keys(file).forEach((k) => {
            body.append('transaction_file[document]', file[k]);
        });
        if (fileType === 'mcd_file') {
            body.append('transaction_file[pool_type]', poolType);
            body.append('transaction_file[skip_extract_headers]', skipExtractHeaders);
        }
        body.append('document_type', fileType);
        return fetchUtils
            .postFormData(`${process.env.REACT_APP_MP_API_HOST}/transaction_files`, body)
            .then((d) => {
                dispatch(updatePoolFile(d));
                return d;
            })
            .then((d) => {
                if (d && d.id && poolType && fileType === 'mcd_file') {
                    dispatch(getFileHeaders(d.id, poolType));
                }
            })
            .catch((ex) => {
                const { status } = ex.response;
                if (status === 422 && fileType === 'mcd_file') {
                    dispatch({ type: TRANS_DA_POOL_FILE_422_ERROR });
                }
                throw ex;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error uploading pool file', m);
                    throw new Error(m);
                }),
            );
    };
}

export function pollFileHeaders(fileId, poolType, id) {
    return (dispatch) => {
        dispatch({ type: TRANS_DA_POOL_FILE_HEADERS_REQUEST, id });
        return fetchUtils
            .poll(
                () =>
                    fetchUtils
                        .getJSON(`${process.env.REACT_APP_MP_API_HOST}/mcd_files/${fileId}/file_headers?pool_type=${poolType}`)
                        .catch(() => null),
                1000 * 60 * 3,
                1000,
            )
            .then((d) => {
                if (!d) {
                    return null;
                }
                dispatch({
                    type: TRANS_DA_POOL_FILE_HEADERS_SUCCESS,
                    data: d,
                    fileId,
                    id,
                });
                return d;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) =>
                    dispatch({
                        type: TRANS_DA_POOL_FILE_HEADERS_ERROR,
                        errorMsg: m,
                        id,
                    }),
                ),
            );
    };
}

export function getFileHeaders(fileId, poolType, id = null) {
    return (dispatch) => dispatch(pollFileHeaders(fileId, poolType, id));
}

function updatePoolFile(d) {
    return { type: TRANS_DA_POOL_FILE_UPLOAD, data: d };
}

function mapTransfileColumns(poolType, fileType, columnMapping) {
    const obj = {};
    const colMappingObj = {};
    Object.keys(columnMapping).forEach((c) => {
        if (columnMapping[c]) {
            colMappingObj[c] = columnMapping[c];
        }
    });
    obj[fileType] = {
        column_mapping: colMappingObj,
        pool_type: poolType,
    };
    return obj;
}

function postTransFileColMap(poolType, fileId, fileType, columnMapping) {
    return (dispatch) => {
        if (fileId && fileType && columnMapping) {
            return fetchUtils
                .putJSON(
                    `${process.env.REACT_APP_MP_API_HOST}/mcd_files/${fileId}/map_columns`,
                    mapTransfileColumns(poolType, fileType, columnMapping),
                )
                .then((d) => dispatch(updatePoolFile(d)));
        }
        return Promise.resolve();
    };
}

function mapPTCInitData(data) {
    const { poolFiles, poolType, type } = data;

    const mcdFile = poolFiles.filter((el) => el.document_type === 'mcd_file');
    const rsFile = poolFiles.filter((el) => el.document_type === 'rs_file');

    return {
        direct_assignment_transaction: {
            documents: [
                {
                    id: type === 'rs' ? rsFile[0].id : mcdFile[0].id,
                    type: type === 'rs' ? 'rs_file' : 'mcd_file',
                },
            ],
            pool_type: poolType,
        },
    };
}

function updateDATrans(transId, data) {
    return fetchUtils.putJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${transId}`, mapPTCInitData(data));
}

export function createDATrans(transId, data, type) {
    return (dispatch) => {
        const { poolFiles, columnMappingFileId, columnMappingFileType, columnMapping, poolType } = data;
        if (!poolFiles.some((f) => f.document_type === 'rs_file') && type === 'rs') {
            return dispatch(
                RouterUtils.pushRoute({
                    path: CLIENT_INIT_TRANSACTION_DA_SUCCESS,
                    keys: { transId },
                }),
            );
        }
        dispatch({ type: TRANS_DA_CREATE_REQUEST });
        return dispatch(postTransFileColMap(poolType, columnMappingFileId, columnMappingFileType, columnMapping))
            .then(() => updateDATrans(transId, { poolFiles, poolType, type }))
            .then((d) => {
                dispatch({ type: TRANS_DA_CREATE_SUCCESS, data: d });
                return d;
            })
            .then((d) => {
                if (type === 'mcd') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: CLIENT_INIT_TRANSACTION_DA_RSUPLOAD,
                            keys: { transId: d.id },
                        }),
                    );
                }
                if (type === 'rs') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: CLIENT_INIT_TRANSACTION_DA_SUCCESS,
                            keys: { transId: d.id },
                        }),
                    );
                }
            })
            .then(() => {
                if (isClient) {
                    dispatch({
                        type: CUSTOMER_INITIATE_TRANSACTION,
                        meta: {
                            amplitude: [
                                {
                                    eventType: EventTypes.track,
                                    eventPayload: {
                                        eventName: CUSTOMER_INITIATE_TRANSACTION,
                                        transaction_type: PRODUCT_KEYS.direct_assignment,
                                        ...data,
                                    },
                                },
                            ],
                        },
                    });
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANS_DA_CREATE_ERROR, message: m });
                    toastr.error('Error while creating DA transaction', m);
                }),
            );
    };
}

export function updateDAFiles(transId, data, type) {
    return (dispatch) => {
        const { poolFiles, columnMappingFileId, columnMappingFileType, columnMapping, poolType } = data;
        if (!poolFiles.some((f) => f.document_type === 'rs_file') && type === 'rs') {
            return dispatch(
                RouterUtils.pushRoute({
                    path: CLIENT_DA_TRANS,
                    keys: { transId },
                }),
            );
        }
        dispatch({ type: TRANS_DA_CREATE_REQUEST });
        return dispatch(postTransFileColMap(poolType, columnMappingFileId, columnMappingFileType, columnMapping))
            .then(() => updateDATrans(transId, { poolFiles, poolType, type }))
            .then((d) => {
                dispatch({ type: TRANS_DA_CREATE_SUCCESS, data: d });
                return d;
            })
            .then((d) => {
                if (type === 'mcd') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: CLIENT_EDIT_TRANSACTION_DA_RSUPLOAD,
                            keys: { transId: d.id },
                        }),
                    );
                }
                if (type === 'rs') {
                    return dispatch(
                        RouterUtils.pushRoute({
                            path: CLIENT_DA_TRANS,
                            keys: { transId: d.id },
                        }),
                    );
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: TRANS_DA_CREATE_ERROR, message: m });
                    toastr.error('Error while creating DA transaction', m);
                }),
            );
    };
}

// export function loadPoolSummary(id) {
//     return dispatch =>
//         fetch(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${id}/pool_summary`, fetchUtils.setGetHeaders())
//             .then((resp) => {
//                 const { status, statusText } = resp;

//                 if ((status >= 200 && status < 300) || status === 404) return resp;

//                 const error = new Error(statusText);
//                 error.response = resp;
//                 throw error;
//             })
//             .then(fetchUtils.parseJSON)
//             .then((d) => {
//                 if (d && d.error) {
//                     return null;
//                 }
//                 dispatch({ type: TRANS_PTC_SUMMARY_SUCCESS, summary: d });
//                 return d;
//             })
//             .catch(ex =>
//                 fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
//                     dispatch({
//                         type: TRANS_PTC_SUMMARY_ERROR,
//                         message: 'We are unable to process the file at the moment. ',
//                         messageAction: 'The Vivriti team will get back to you as soon as the problem is fixed',
//                     });
//                     return new Error(m);
//                 }));
// }

// export function pollPoolSummary(id) {
//     return (dispatch) => {
//         dispatch({ type: TRANS_PTC_SUMMARY_REQUEST });

//         return fetchUtils.poll(() => dispatch(loadPoolSummary(id)), 20000, 1000).catch((e) => {
//             const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
//             if (errMsg === 'timeout') {
//                 dispatch({
//                     type: TRANS_PTC_SUMMARY_ERROR,
//                     message: 'File is taking too long to process.',
//                     messageAction: 'Please check back in about 5 to 10 minutes by accessing the transaction from the Transactions Screen',
//                 });
//             }
//         });
//     };
// }

// export function skipPoolSummary() {
//     return dispatch => dispatch({ type: TRANS_PTC_SUMMARY_SKIP });
// }

// export function updateTransClientPref(transId, pref) {
//     return dispatch =>
//         fetchUtils
//             .putJSON(`${process.env.REACT_APP_MP_API_HOST}/securitizations/${transId}/client_preferences`, mapClientPref(pref))
//             .then(d => dispatch({ type: TRANS_PTC_CLIENT_PREF_SUCCESS, data: d }))
//             .then(() =>
//                 dispatch(RouterUtils.pushRoute({
//                     path: CLIENT_INIT_TRANSACTION_PTC_SUCCESS,
//                     keys: { transId },
//                 })))
//             .catch(ex =>
//                 fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
//                     toastr.error('Error updating preferences', m);
//                 }));
// }
