export const CHART_COLORS = ['#f86646', '#fcd53d', '#6bd09b', '#0063a0', '#00b7cb', '#0e70fe', '#b837c4', '#e94873', '#ff9e52', '#1a4755'];

export const PORTFOLIO_COLORS = [
    { name: 'green', value: '#3ad682' },
    { name: 'dark-blue', value: '#0e70fe' },
    { name: 'yellow', value: '#ebc92e' },
    { name: 'red', value: '#ff544f' },
    { name: 'sky-blue', value: '#37bbc4' },
    { name: 'purple', value: '#b837c4' },
    { name: 'violet', value: '#652ed2' },
    { name: 'orange', value: '#ff9d64' },
    { name: 'dark-green', value: '#8ba582' },
];

export const PRODUCT_COLORS = [
    { name: 'ptc', value: 'Securitization', color: '#0A984C' },
    { name: 'ncd', value: 'Ncd::Transaction', color: '#5A4FD1' },
    { name: 'cp', value: 'CommercialPaper::Transaction', color: '#F67D2B' },
    { name: 'tl', value: 'TermLoan::Transaction', color: '#D9A007' },
    { name: 'ps', value: 'PrefShares::Transaction', color: '#51ACF9' },
    { name: 'da', value: 'DirectAssignment::Transaction', color: '#AE5EB4' },
    { name: 'wcdl', value: 'WorkingCapitalDemandLoan::Transaction', color: '#E464A6' },
    { name: 'default', value: 'default', color: '#0A984C' },
];

