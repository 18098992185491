import React, { useEffect, useState } from 'react';
import ModalPopup from 'app/components/ModalPopup';
import Cookie from 'js-cookie';
import { getDomainName } from 'app/utils/CommonUtils';

const SHOWN = 'shown';
const TEASER = 'teaser';
const TRAILER = 'trailer';

const TODAY = new Date();
const TEASER_END_DATE = new Date('2022-06-22T23:59:59');
const TRAILER_START_DATE = new Date('2022-06-23T00:00:00');
const TRAILER_END_DATE = new Date('2022-07-10T23:59:59');

const TEASER_VIDEO_URL = 'https://assets.credavenue.com/yubi/marketing/Cred_Teaser_Music.mp4';
const TRAILER_VIDEO_URL = 'https://assets.credavenue.com/yubi/marketing/Yubi_Launch_AV_Final_Out.mp4';

const COOKIE_CONFIG = {
    domain: getDomainName().domain,
    path: '/',
};

const isDateInRange = (date: any, startDate: any, endDate: any) => date >= startDate && date <= endDate;

const isVideoNotShown = (key: any) => localStorage.getItem(key) !== SHOWN;

const setVideoShown = (key: any) => localStorage.setItem(key, SHOWN);

const setVideoShownInCookie = (key: any) => Cookie.set(key, SHOWN, COOKIE_CONFIG);

const isVideoNotShownFromCookie = (key: any) => Cookie.get(key) !== SHOWN;

const YubiLaunch: React.FC = () => {
    const [video, setVideo] = useState<any>(null);

    useEffect(() => {
        if (isDateInRange(TODAY, TODAY, TEASER_END_DATE)) {
            if (isVideoNotShownFromCookie(TEASER)) {
                if (isVideoNotShown(TEASER)) {
                    setVideo(TEASER_VIDEO_URL);
                    setVideoShown(TEASER);
                }
                setVideoShownInCookie(TEASER);
            } else {
                setVideoShown(TEASER);
            }
        } else if (isDateInRange(TODAY, TRAILER_START_DATE, TRAILER_END_DATE)) {
            if (isVideoNotShownFromCookie(TRAILER)) {
                if (isVideoNotShown(TRAILER)) {
                    setVideo(TRAILER_VIDEO_URL);
                    setVideoShown(TRAILER);
                }
                setVideoShownInCookie(TRAILER);
            } else {
                setVideoShown(TRAILER);
            }
        }
    }, []);

    const handleClose = () => {
        setVideo(null);
    };

    if (video) {
        return (
            <ModalPopup
                open
                modalClass="brand-launch-modal modal-md"
                onRequestClose={handleClose}
                disableOuterClick
                customStyle={{ overlay: { maxWidth: '75%', margin: window?.screen?.width < 1550 ? '21px auto' : '108px auto', background: 'none' } }}
            >
                <video controls width="100%" height="100%" disablePictureInPicture controlsList="nodownload noplaybackrate" autoPlay muted>
                    <source src={video} type="video/mp4" />
                </video>
            </ModalPopup>
        );
    }

    return <></>;
};

export default YubiLaunch;
