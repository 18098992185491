export const ADMIN_HOME = '/admin';
export const ADMIN_TRANS_LIST = '/:type/transactions';
export const ADMIN_TRANS_DEFAULT = '/:product(securitization|ptc|ncd|cp|ps|tl|da)/:transId/tools/pool-selection';
export const ADMIN_TRANS_OLD = '/admin/:product(securitization|ptc|ncd|cp|ps|tl|da)/:transId/:any(tools|details-view)/:x';

export const ADMIN_TRANS_PTC = '/ptc/:transId';
export const ADMIN_TRANS_PTC_DETAILS_VIEW = '/ptc/:transId/details-view';
export const ADMIN_TRANS_PTC_ATTACHMENTS = '/ptc/:transId/attachments';
export const ADMIN_TRANS_PTC_ADMIN_VIEW = '/ptc/:transId/tools';
export const ADMIN_PTC_MARKET_RESEARCH = '/ptc/:transId/market-research';
export const ADMIN_TRANS_PTC_POOL_SELECTION = '/ptc/:transId/tools/pool-selection';
export const ADMIN_TRANS_PTC_STRUCTURING = '/ptc/:transId/tools/structuring';
export const ADMIN_TRANS_PTC_SCRUBBING = '/ptc/:transId/tools/scrubbing';
export const ADMIN_TRANS_PTC_COSTING = '/ptc/:transId/tools/costing';
export const ADMIN_TRANS_PTC_ACTORS = '/ptc/:transId/tools/counterparties';
export const ADMIN_TRANS_PTC_DOCUMENTS = '/ptc/:transId/tools/documents';
export const ADMIN_TRANS_PTC_RELEASE = '/ptc/:transId/tools/release';
export const ADMIN_TRANS_PTC_BIDS = '/ptc/:transId/tools/bids';
export const ADMIN_TRANS_PTC_FINALISE = '/ptc/:transId/tools/finalise';
export const ADMIN_TRANS_PTC_TASKS = '/ptc/:transId/tools/task-status';
export const ADMIN_TRANS_PTC_TASKS_REPORTER = '/ptc/:transId/tools/task-report';
export const ADMIN_TRANS_PTC_POOLFILES = '/ptc/:transId/tools/pool-files';
export const ADMIN_TRANS_PTC_SERVICER_REPORT = '/ptc/:transId/tools/servicer-report';
export const ADMIN_TRANS_PTC_INVESTOR_INTERESTS = '/ptc/:transId/tools/interested-investors';
export const ADMIN_TRANS_DEAL_PERFORMANCE = '/ptc/:transId/deal-performance';
export const ADMIN_TRANS_SIGNING_INSTRUCTIONS = '/ptc/:transId/tools/signing-instructions';
export const ADMIN_TRANS_INVESTMENT_NOTE = '/ptc/:transId/investment-note';
export const ADMIN_PTC_REGULATORY_REPORTS = '/ptc/:transId/transaction-reports';

export const ADMIN_PTC = '/ptcv1/:transId';
export const ADMIN_PTC_DETAILS_VIEW = '/ptcv1/:transId/details-view';
export const ADMIN_PTC_ATTACHMENTS = '/ptcv1/:transId/attachments';
export const ADMIN_PTC_ADMIN_VIEW = '/ptcv1/:transId/tools';
export const ADMIN_PTC_V1_MARKET_RESEARCH = '/ptcv1/:transId/market-research';
export const ADMIN_PTC_V1_TOOLS = '/ptcv1/:transId/ekyc-tools';
export const ADMIN_PTC_V1_DOCUMENTS = '/ptcv1/:transId/documents';
export const ADMIN_PTC_V1_DOCUMENTS_VIEW = '/ptcv1/:transId/documents-view';
export const ADMIN_PTC_FILTER = '/ptcv1/:transId/tools/pool-filter';
export const ADMIN_PTC_STRUCTURING = '/ptcv1/:transId/tools/structuring';
export const ADMIN_PTC_LOSS_ESTIMATION = '/ptcv1/:transId/tools/loss-estimation';
export const ADMIN_PTC_SCRUBBING = '/ptcv1/:transId/tools/scrubbing';
export const ADMIN_PTC_ACTORS = '/ptcv1/:transId/tools/counterparties';
export const ADMIN_PTC_DOCUMENTS = '/ptcv1/:transId/tools/generate-documents';
export const ADMIN_PTC_RELEASE = '/ptcv1/:transId/tools/release';
export const ADMIN_PTC_BIDS = '/ptcv1/:transId/tools/bids';
export const ADMIN_PTC_FINALISE = '/ptcv1/:transId/tools/finalise';
export const ADMIN_PTC_COSTING = '/ptcv1/:transId/tools/costing';
export const ADMIN_PTC_SERVICER_REPORT = '/ptcv1/:transId/tools/servicer-report';
export const ADMIN_PTC_TASKS = '/ptcv1/:transId/tools/task-status';
export const ADMIN_PTC_INVESTOR_INTERESTS = '/ptcv1/:transId/tools/interested-investors';
export const ADMIN_PTC_SIGNING_INSTRUCTIONS = '/ptcv1/:transId/tools/signing-instructions';
export const ADMIN_PTC_DEAL_PERFORMANCE = '/ptcv1/:transId/deal-performance';
export const ADMIN_PTC_INVESTMENT_NOTE = '/ptcv1/:transId/investment-note';
export const ADMIN_PTC_UPDATE_COMMODITIES = '/ptcv1/:transId/tools/update-commodities';
export const ADMIN_PTC_SCRUB_APPROVAL = '/ptcv1/:transId/tools/scrub-approval';
export const ADMIN_PTC_CIF_LAN = '/ptcv1/:transId/tools/cif-lan';

export const ADMIN_POOL_LIST = '/pools';
export const ADMIN_POOL_DETAILS_MCD = '/pool-details-mcd/:poolId/';
export const ADMIN_POOL_DETAILS_RS = '/pool-details-rs/:poolId/';

export const ADMIN_TRANS_DA = '/da/:transId';
export const ADMIN_TRANS_DA_POOL_SELECTION = '/da/:transId/tools/pool-selection';
export const ADMIN_TRANS_DA_DETAILS_VIEW = '/da/:transId/details-view';
export const ADMIN_TRANS_DA_DOCUMENTS_VIEW = '/da/:transId/documents-view';
export const ADMIN_TRANS_DA_ATTACHMENTS = '/da/:transId/attachments';
export const ADMIN_TRANS_DA_ADMIN_VIEW = '/da/:transId/tools';
export const ADMIN_TRANS_DA_STRUCTURING = '/da/:transId/tools/structuring';
export const ADMIN_TRANS_DA_SCRUBBING = '/da/:transId/tools/scrubbing';
export const ADMIN_TRANS_DA_COSTING = '/da/:transId/tools/costing';
export const ADMIN_TRANS_DA_ACTORS = '/da/:transId/tools/counterparties';
export const ADMIN_TRANS_DA_DOCUMENTS = '/da/:transId/tools/documents';
export const ADMIN_TRANS_DA_RELEASE = '/da/:transId/tools/release';
export const ADMIN_TRANS_DA_BIDS = '/da/:transId/tools/bids';
export const ADMIN_TRANS_DA_FINALISE = '/da/:transId/tools/finalise';
export const ADMIN_TRANS_DA_CIF_LAN = '/da/:transId/tools/cif-lan';
export const ADMIN_TRANS_DA_TASKS = '/da/:transId/tools/task-status';
export const ADMIN_TRANS_DA_TASKS_REPORTER = '/da/:transId/tools/task-report';
export const ADMIN_TRANS_DA_POOLFILES = '/da/:transId/tools/pool-files';
export const ADMIN_TRANS_DA_MCDUPLOAD = '/da/:transId/tools/mcd-upload';
export const ADMIN_TRANS_DA_RSUPLOAD = '/da/:transId/tools/rs-upload';
export const ADMIN_TRANS_DA_STAMPDUTY = '/da/:transId/tools/stamp-duty';
export const ADMIN_TRANS_DA_SERVICER_REPORT = '/da/:transId/tools/servicer-report';
export const ADMIN_TRANS_DA_INVESTOR_INTERESTS = '/da/:transId/tools/interested-investors';
export const ADMIN_TRANS_DA_DEAL_PERFORMANCE = '/da/:transId/deal-performance';
export const ADMIN_DA_REGULATORY_REPORTS = '/da/:transId/transaction-reports';

export const ADMIN_DOCUMENT_COLLABORATOR_DA = '/da/:transId/documents/:docId/editor';
export const ADMIN_DOCUMENT_COLLABORATOR_PTC = '/ptc/:transId/documents/:docId/editor';
export const ADMIN_DOCUMENT_COLLABORATOR_PTC_V1 = '/ptcv1/:transId/documents/:docId/editor';

export const ADMIN_REPORTS_LIST = '/reports';
export const ADMIN_REPORTS_DETAILS = '/reports/:reportType/details';
export const ADMIN_MANAGE_ROLE = '/entities/:entityId/:actor/manage-roles';

export const ADMIN_TRANS_ALL_REPORTS = '/:type/:transId/all-reports';
export const ADMIN_TRANS_MONTHLY_REPORTS = `${ADMIN_TRANS_ALL_REPORTS}/monthly`;
export const ADMIN_TRANS_DAILY_REPORTS = `${ADMIN_TRANS_ALL_REPORTS}/daily`;
export const ADMIN_TRANS_DYNAMIC_REPORTS = `${ADMIN_TRANS_ALL_REPORTS}/dynamic`;

export const ADMIN_TRANS_INPUT = '/:type/:transId/input';
export const ADMIN_TRANS_INPUT_PAYOUT_REPORT = `${ADMIN_TRANS_INPUT}/payout-report`;
export const ADMIN_TRANS_INPUT_COLLECTION_FILE = `${ADMIN_TRANS_INPUT}/collection-file`;

export const REPORT = '/report';
