import React from 'react';

import BrowserImg from '../assets/images/browser-nw.png';
import '../assets/css/cookie.css';

const HelpCookies = () => (
    <div className="container">
        <div className="en-cookie">
            <div className="cookie-head">
                <figure>
                    <img src={BrowserImg} alt="Browsers" />
                </figure>
                <h1>How to Enable Third-Party Cookies</h1>
                <p>
                    To sign in or register, your browser must be configured to accept third-party cookies. Please follow the browser-specific
                    instructions below to enable third-party cookies.
                </p>
            </div>
            <div className="cookie-body">
                <ul className="clearfix">
                    <li>
                        <a href="#google" className="lo-google">
                            Google Chrome
                        </a>
                    </li>
                    <li>
                        <a href="#firefox" className="lo-firefox">
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a href="#safari" className="lo-safari">
                            Safari
                        </a>
                    </li>
                    <li>
                        <a href="#ie" className="lo-ie">
                            Microsoft Internet Explorer
                        </a>
                    </li>
                </ul>
                <div className="br-cookie" id="google">
                    <h2>Google Chrome</h2>
                    <h4>To enable cookies in Google Chrome (Mac):</h4>
                    <ol start="1" type="1">
                        <li>Open Chrome preferences click on Settings, then Show Advanced Settings.</li>
                        <li>Under Privacy, click on Content Settings.</li>
                        <li>Make sure "Block third-party cookies and site data" is not checked</li>
                    </ol>
                    <a className="cookie-top" href="#">
                        Top
                    </a>
                </div>
                <div className="br-cookie" id="firefox">
                    <h2>Mozilla Firefox</h2>
                    <h4>To enable cookies in Mozilla Firefox 3.x (PC):</h4>
                    <ol>
                        <li>
                            Click <b>Tools {'>'} Options.</b>
                        </li>
                        <li>
                            Click <b>Privacy</b> in the top panel.
                        </li>
                        <li>Select the checkbox labeled 'Accept cookies from sites.'</li>
                        <li>Select the checkbox labeled 'Accept third-party cookies.'</li>
                        <li>
                            Click <b>OK.</b>
                        </li>
                    </ol>
                    <h4>To enable cookies in Mozilla Firefox 2.x (PC):</h4>
                    <ol>
                        <li>
                            Click <b>Tools {'>'} Options.</b>
                        </li>
                        <li>
                            Click <b>Privacy</b> in the top panel.
                        </li>
                        <li>Select the checkbox labeled 'Accept cookies from sites.'</li>
                        <li>
                            Click <b>OK.</b>
                        </li>
                    </ol>
                    <h4>To enable cookies in Mozilla Firefox 1.x (PC):</h4>
                    <ol>
                        <li>
                            Click <b>Tools {'>'} Options.</b>
                        </li>
                        <li>
                            Click <b>Privacy</b> in the top panel.
                        </li>
                        <li>
                            Click the <b>Cookies</b> tab.
                        </li>
                        <li>Select the checkbox labeled 'Allow sites to set cookies.'</li>
                        <li>
                            Click <b>OK.</b>
                        </li>
                    </ol>
                    <h4>To enable cookies in Mozilla Firefox (Mac):</h4>
                    <ol>
                        <li>
                            Go to the <b>Firefox</b> drop-down menu.
                        </li>
                        <li>
                            Select <b>Preferences.</b>
                        </li>
                        <li>
                            Click <b>Privacy.</b>
                        </li>
                        <li>Under Cookies, select the option 'Accept cookies from sites.'</li>
                    </ol>
                    <a className="cookie-top" href="#">
                        Top
                    </a>
                </div>
                <div className="br-cookie" id="safari">
                    <h2>Safari</h2>
                    <h4>To enable cookies in Safari:</h4>
                    <ol>
                        <li>Go to the Safari drop-down menu.</li>
                        <li>Select Preferences.</li>
                        <li>Click Privacy in the top panel.</li>
                        <li>Under 'Block cookies' select the option 'Never.'</li>
                        <li>For increased security, once you have finished using the site, please change the Privacy setting back to Always.</li>
                    </ol>
                    <a className="cookie-top" href="#">
                        Top
                    </a>
                </div>
                <div className="br-cookie" id="ie">
                    <h2>Microsoft Internet Explorer</h2>
                    <h4>To enable cookies in IE 7:</h4>
                    <ol>
                        <li>
                            Click <b>Start {'>'} Control Panel.</b> (Note: with Windows XP Classic View,
                            click the Windows <b>Start</b> button{' '}
                            <b>{'>'} Settings {'>'} Control Panel).</b>
                        </li>
                        <li>
                            Double-click the <b>Internet Options</b> icon.
                        </li>
                        <li>
                            Click the <b>Privacy</b> tab.
                        </li>
                        <li>
                            Click the <b>Advanced</b> button.
                        </li>
                        <li>
                            Select the option 'Override automatic cookie handling' under the Cookies section in the Advanced Privacy Settings window.
                        </li>
                        <li>Select the 'Accept' or 'Prompt' option under 'First-party Cookies.'</li>
                        <li>
                            Select the 'Accept' or 'Prompt' option under 'Third-party Cookies.' (Note: if you select the 'Prompt' option, you'll be
                            prompted to click <b>OK</b> every time a website attempts to send you a cookie.)
                        </li>
                        <li>
                            In the Internet Options window, click <b>OK</b> to exit.
                        </li>
                    </ol>
                    <a className="cookie-top" href="#">
                        Top
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default HelpCookies;
