import React from 'react';
import '../assets/css/no-internet.css';

class NoConnection extends React.PureComponent {
    componentDidMount() {
        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    }

    handleOnline = () => {
        console.log('I am online...');
        const { onOnline } = this.props;
        if (onOnline) {
            onOnline();
        }
    };
    handleOffline = () => {
        console.log('I am offline...');
        const { onOffline } = this.props;
        if (onOffline) {
            onOffline();
        }
    };

    render() {
        return null;
    }
}

export default NoConnection;
