import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import history from 'app/utils/myHistory';
import { ENVIRONMENTS } from 'app/constants/Constants';

export default function loadSentry() {
    if (ENVIRONMENTS.PRODUCTION === process.env.REACT_APP_ENV) {
        Sentry.init({
            dsn: 'https://2882cf1b33e44ae7b811855f667a24f4@o144424.ingest.sentry.io/5471899',
            integrations: [
                new TracingIntegrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
                }),
            ],
            tracesSampleRate: 1.0,
            environment: process.env.REACT_APP_ENV,
            ignoreErrors: [
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
                'Illegal invocation',
                'privateSpecialRepair is not defined',
                'getReadModeConfig',
                'getReadModeExtract',
                'getReadModeRender',
                'Invalid token specified',
            ],
        });
    }
}
