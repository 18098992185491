/* eslint-disable no-param-reassign */
import dayjs, { QUnitType } from 'dayjs';
import moment, { Moment, unitOfTime } from 'moment';

import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

const DATE_FORMAT = 'DD-MMM-YYYY';
const DATE_FORMAT1 = 'YYYY-MM-DD';

export const isValidDate = (date: any) => {
    if (!date) return false;
    return moment(date).isValid();
};

export const isValidDateV1 = (date: any) => {
    if (!date) return false;
    return dayjs(date).isValid();
};

export const formatDate = (date: any, format = DATE_FORMAT) => {
    if (!date) return '';
    return moment(date).isValid() ? moment(date).format(format) : null;
};

// Using Day js **Please depricate using Momentjs**
export const formatDateV1 = (date: any, format = DATE_FORMAT) => {
    if (!date) return '';
    return dayjs(date).format(format);
};

export const getYearMonth = (date: any) => {
    let d = date;
    if (!d) {
        d = new Date();
    }

    return {
        year: Number(dayjs(d).year()),
        month: Number(dayjs(d).month()),
    };
};

export const getMonthName = (d: any, format = 'MMMM'): string => {
    const d1 = typeof d === 'string' ? d : String(d);

    return dayjs(String(d1)).format('MMM');
};

export const newDate = (date?: any, format?: any) => {
    return format ? formatDate(moment(date), format) : moment(date).format();
};

export const checkPastAndCurrentMonth = (date: string) => {
    if (moment().isSameOrAfter(date, 'month')) return true;
    return false;
};

export const checkSameOrBeforeDay = (date: string) => {
    return moment().isSameOrBefore(date, 'day');
};

export const subtractDays = (date: any, days = 0) => moment(date).subtract('days', days);

export const subtractDaysV1 = (date: any, days = 0, type = 'days' as dayjs.ManipulateType) => dayjs(date).subtract(days, type);

export const addDays = (days = 0, date?: any) => moment(date).add(days, 'days');

export const addMonths = (months = 0, date?: any) => moment(date).add(months, 'months');

export const dateTimeFormat = (date: any) => moment(date).format('MMMM D, YYYY, h:mm A');

export function dateIsBefore(date1: any, date2?: any): boolean {
    if (!date1) return false;
    if (!date2) date2 = Date.now();
    date1 = moment(date1).format(DATE_FORMAT1) || date1;
    date2 = moment(date2).format(DATE_FORMAT1) || date2;
    return moment(date1).isBefore(date2);
}

export const parseDate = (date: any, format = DATE_FORMAT) => {
    if (!date || !format) return null;
    return moment(date, format);
};

export const fromNow = (date: string) => {
    if (!date) return null;
    return moment(date).fromNow();
};

export const checkSameDate = (date1: string, date2?: string) => (date2 ? moment(date1).isSame(date2, 'date') : moment().isSame(date1, 'date'));

export const currentDate = (format: string) => moment().format(format);
export const toIso8601 = (date: any): any => moment(date).toISOString(true);

export const dateDiff = (startDate: string, endDate: string, unitOfTime?: unitOfTime.Diff): any =>
    moment(endDate).diff(moment(startDate), unitOfTime || 'months');

export const isDateToday = (dateStr: string) => {
    return moment(new Date(dateStr)).isSame(new Date(), 'day');
};

export const isDateYesterday = (dateStr: string) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return moment(new Date(dateStr)).isSame(yesterday, 'day');
};

export const humanizeDataTime = (dataTime: string, format = 'DD MMM YYYY') => {
    if (isDateToday(dataTime)) return 'Today';
    if (isDateYesterday(dataTime)) return 'Yesterday';
    return formatDate(dataTime, format);
};
export const getTimeDiffFromNow = (dateStr: string) => dayjs().to(dayjs(dateStr));

export const formatTimeWithAMPM = (dateStr: string) => {
    const givenDate = new Date(dateStr);
    let hours: number = givenDate.getHours();
    let minutes: number | string = givenDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
};

export const getDaysInMonth = (dateStr: string | Moment) => {
    return dayjs(dateStr.toString()).daysInMonth();
};

export const getEndOfMonth = (dateStr: any) => {
    const lastDay = getDaysInMonth(dateStr);
    return dayjs(dateStr)
        .date(lastDay)
        .format(DATE_FORMAT);
};

export const getYearOrMonth = (type: any, previous: any = 0): number => dayjs().get(type) - previous;

export const getCurrentDate = (format: string = DATE_FORMAT) => dayjs().format(format);

export const getDateDiff = (date1: string, date2: string, unit = 'day' as QUnitType) => dayjs(date1).diff(dayjs(date2), unit);

export const getDateDiffFromNow = (toDate: any) => {
    const diff = Math.abs(toDate - Date.now());
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
};
