import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import * as types from '../constants/ActionTypes';

const initialState = {
    poolFiles: [],
    transaction: {},
    poolSummary: {},
    clientPref: {},
    isFetching: false,
    errMessage: '',
    warnMessage: '',
    skipExtractHeaders: false,
    shouldSkipPoolSummary: false,
};

export default function ptc(state = initialState, action) {
    switch (action.type) {
        case types.TRANS_PTC_POOL_FILE_DESTROY:
            return {
                ...state,
                poolFiles: filter(state.poolFiles, (f) => f.id !== action.id),
            };
        case types.TRANS_PTC_POOL_FILE_UPLOAD:
            return {
                ...state,
                poolFiles: uniqBy([action.data, ...state.poolFiles], 'document_type'),
            };
        case types.TRANS_PTC_POOL_FILE_422_ERROR:
            return {
                ...state,
                skipExtractHeaders: true,
            };
        case types.TRANS_PTC_POOL_FILE_HEADERS_REQUEST:
            return {
                ...state,
                errMessage: '',
                warnMessage: '',
                isFileHeadersLoading: true,
            };
        case types.TRANS_PTC_POOL_FILE_HEADERS_SUCCESS:
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: '',
                warnMessage: '',
                poolFiles: state.poolFiles.map((f) => (action.data && action.fileId && f.id === action.fileId ? { ...f, ...action.data } : { ...f })),
            };
        case types.TRANS_PTC_POOL_FILE_HEADERS_ERROR:
            return {
                ...state,
                isFileHeadersLoading: false,
                errMessage: action.errorMsg,
                warnMessage: action.warnMessage,
            };
        case types.TRANS_PTC_CREATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                errMessage: '',
                warnMessage: '',
            };
        case types.TRANS_PTC_CREATE_SUCCESS:
            return {
                ...state,
                transaction: action.data,
                isFetching: false,
            };
        case types.TRANS_PTC_CREATE_ERROR:
            return {
                ...state,
                errMessage: action.message,
                warnMessage: '',
                isFetching: false,
            };
        case types.TRANS_PTC_SUMMARY_SKIP:
            return {
                ...state,
                errMessage: '',
                warnMessage: '',
                shouldSkipPoolSummary: true,
            };
        case types.TRANS_PTC_SUMMARY_REQUEST:
            return {
                ...state,
                isFetching: true,
                shouldSkipPoolSummary: false,
                errMessage: '',
                warnMessage: '',
            };
        case types.TRANS_PTC_SUMMARY_SUCCESS:
            return {
                ...state,
                poolSummary: action.summary.pool_summary,
                isFetching: false,
            };
        case types.TRANS_PTC_SUMMARY_ERROR:
            return {
                ...state,
                errMessage: action.message,
                warnMessage: '',
                errMessageAction: action.messageAction,
                isFetching: false,
            };
        case types.TRANS_PTC_CLIENT_PREF_SUCCESS:
            return {
                ...state,
                clientPref: action.data,
            };
        case types.TRANS_PTC_INIT:
            return {
                ...initialState,
            };
        case types.TRANS_PTC_GET: {
            const files = [];
            if (action.data.rs_file_id) {
                files.push({
                    id: action.data.rs_file_id,
                    document_type: 'rs_file',
                    file_name: 'RS file',
                });
            }
            if (action.data.mcd_file_id) {
                files.push({
                    id: action.data.mcd_file_id,
                    document_type: 'mcd_file',
                    file_name: 'MCD File',
                });
            }

            return {
                ...state,
                poolFiles: files,
                transaction: action.data,
            };
        }
        default:
            return state;
    }
}
