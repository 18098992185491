import humps from 'humps';
import * as fetchUtils from 'app/utils/FetchUtils';

const initialState = null;

// actions
const createActionName = (name) => `app/ptc/transaction/clients/${name}`;

export const CLIENTS_GET = createActionName('CLIENTS_GET');

export default function client(state = initialState, action) {
    switch (action.type) {
        case CLIENTS_GET:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
}

export function getClient(id, cid) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_CS_OB_API_HOST}/entities/${cid}/customer`)
            .then((d) => humps.camelizeKeys(d))
            .then((data) => {
                dispatch({ type: CLIENTS_GET, data, id });
                return data;
            })
            .catch(() => {});
}
