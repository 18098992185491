import loadAmplitude from './amplitude';
import loadHotjar from './hotjar';
import loadSentry from './sentry';
import loadGtm from './gtm';
import { ENVIRONMENTS } from 'app/constants/Constants';

export default (function loadThirdPartyServices() {
    loadSentry();
    loadGtm();
    if (process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION) {
        loadHotjar();
        loadAmplitude();
    }
})();
