import React from 'react';
import _get from 'lodash/get';
import _size from 'lodash/size';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import fire from 'app/assets/images/fire.svg';
import flash from 'app/assets/images/flash.svg';
import { ImageWrapper, LabelValueContainer, PoolText } from './PoolDetailsCard';
import { humanizeNumber } from 'app/utils/CommonUtils';
import { amountInCr, capitalize } from 'app/utils/StringUtils';
import { useEffect } from 'react';
import { getColor } from '../Utils/colorSets';
import { DEFAULT_TEXT_COLOR } from '../Constants';

export const CarouselWrapper = styled.div`
    .carousel-slider {
        padding-right: 15px;
    }
`;
export const PVPWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    background: linear-gradient(99.47deg, rgba(253, 242, 248, 0) 0%, rgba(253, 242, 248, 0) 0.01%, #fdf2f8 100%);
    border: 1px solid #f9a8d3;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 0px 12px;
    margin-left: 0;
    min-height: 209px;
    height: 209px;
    overflow-x: scroll;
`;

export const Heading = styled.h1`
    background: #f473b6;
    border-radius: 6px;
    font-size: 10px;
    line-height: 12px;
    padding: 6px 10px 6px 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
`;

export const LabelValueWithSpace = styled(LabelValueContainer)`
    width: 90%;
    font-family: Sofia Pro;
`;

export const HighlightsWrapper = styled(PVPWrapper)`
    background: linear-gradient(99.47deg, rgba(236, 254, 255, 0) 0%, ${getColor('secondary', '50')} 100%);
    border: 1px solid ${getColor('secondary', '300')};
`;

export const HighlightsHeading = styled(Heading)`
    background: ${getColor('secondary', '400')};
`;

export const ProductMixWrapper = styled(PVPWrapper)`
    background: linear-gradient(99.47deg, rgba(245, 245, 255, 0) 0%, ${getColor('primary', '200')} 100%);
    border: 1px solid ${getColor('primary', '400')};
    font-family: Sofia Pro;
`;

export const NextButton = styled.button`
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgb(27 32 45 / 8%), 0px 1px 2px rgb(27 32 45 / 16%);
    border-radius: 50%;
    width: 39px;
    height: 39px;
    position: absolute;
    top: 40%;
    opacity: 1;
    right: 0;
    color: #677489;
    display: flex;
    align-items: center;
    font-size: 24px;
    &.blue-button {
        color: ${getColor('primary', 'primary')};
    }
`;

export const PreviousButton = styled(NextButton)`
    right: unset;
    left: 0;
    z-index: 2;
`;

export const ProductMixContainer = styled(LabelValueContainer)`
    padding: 0;
`;

export const ProductMixData = styled(LabelValueContainer)`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    > span {
        line-height: 16px;
    }
    > label {
        color: #878d96;
    }
`;

export const NoData = styled.p`
    padding: 9%;
    color: #677489;
    width: 100%;
`;
export const TextWrap = styled.div`
    display: flex;
    color: ${getColor('primary', DEFAULT_TEXT_COLOR)};
    font-family: inherit;
    font-weight: 400;
    font-size: 14px;
    align-items: flex-start;
    width: 100%;
    text-align: left;
`;
const PoolVsPortfolio = ({ data }: any): JSX.Element => {
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [slideTwo, setSlideTwo] = useState<boolean>(false);
    useEffect(() => {
        if (
            _get(data, 'collectionEfficiencyMetrics.totalCollectionsCount', 0) %
                _get(data, 'collectionEfficiencyMetrics.efficientCollectionsCount', 0) <=
            2
        ) {
            setSlideTwo(true);
        }
    });
    return (
        <CarouselWrapper>
            <Carousel
                axis="horizontal"
                showThumbs={false}
                showIndicators={false}
                centerMode={true}
                centerSlidePercentage={45}
                showStatus={false}
                selectedItem={selectedItem}
                renderArrowNext={(clickHandler: () => void, hasNext: boolean) => {
                    return hasNext && _size(_get(data, 'productMixInfo', [])) ? (
                        <NextButton
                            onClick={() => {
                                setSelectedItem(2);
                            }}
                        >
                            <i className="icon-chevron-right" />
                        </NextButton>
                    ) : (
                        <></>
                    );
                }}
                renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) => {
                    return hasPrev ? (
                        <PreviousButton
                            onClick={() => {
                                setSelectedItem(0);
                            }}
                        >
                            <i className="icon-chevron-left1" />
                        </PreviousButton>
                    ) : (
                        <></>
                    );
                }}
            >
                <PVPWrapper>
                    <Heading>🤝 Pool vs Portfolio Cuts</Heading>
                    {
                        <LabelValueWithSpace>
                            <span>
                                <ImageWrapper className="tick">
                                    <i className="icon-tick2" />
                                </ImageWrapper>
                                {`${
                                    _get(data, 'previousTransactionsInfo.poolAvgTicketSize') === 0
                                        ? 'N/A'
                                        : `₹${humanizeNumber(_get(data, 'previousTransactionsInfo.poolAvgTicketSize'))}`
                                } |${
                                    _get(data, 'previousTransactionsInfo.portfolioAvgTicketSize') === 0
                                        ? 'N/A'
                                        : `₹${humanizeNumber(_get(data, 'previousTransactionsInfo.portfolioAvgTicketSize'))}`
                                }`}
                            </span>
                            <PoolText>
                                {' '}
                                <label>Average Ticket Size</label>
                            </PoolText>
                        </LabelValueWithSpace>
                    }
                    <LabelValueWithSpace>
                        <span>
                            <ImageWrapper className="fire">
                                <img src={fire} alt="Average IRR" />
                            </ImageWrapper>
                            {_get(data, 'previousTransactionsInfo.poolAvgIrr', 0) || _get(data, 'previousTransactionsInfo.portfolioAvgIrr', 0)
                                ? `${
                                      _get(data, 'previousTransactionsInfo.poolAvgIrr') == 0
                                          ? 'N/A'
                                          : _get(data, 'previousTransactionsInfo.poolAvgIrr') + '%'
                                  }| ${
                                      _get(data, 'previousTransactionsInfo.portfolioAvgIrr') == 0
                                          ? 'N/A'
                                          : _get(data, 'previousTransactionsInfo.portfolioAvgIrr') + '%'
                                  }`
                                : 'N/A'}
                        </span>
                        <PoolText>
                            {' '}
                            <label>Average IRR</label>
                        </PoolText>
                    </LabelValueWithSpace>
                    <LabelValueWithSpace>
                        <span>
                            <ImageWrapper className="flash">
                                <img src={flash} alt="Average LTV" />
                            </ImageWrapper>
                            {_get(data, 'previousTransactionsInfo.poolAvgLtv', 0) || _get(data, 'previousTransactionsInfo.portfolioAvgLtv', 0)
                                ? `${
                                      _get(data, 'previousTransactionsInfo.poolAvgLtv') == 0
                                          ? 'N/A'
                                          : _get(data, 'previousTransactionsInfo.poolAvgLtv') + `%`
                                  } | ${
                                      _get(data, 'previousTransactionsInfo.portfolioAvgLtv', 0) == 0
                                          ? 'N/A'
                                          : _get(data, 'previousTransactionsInfo.portfolioAvgLtv', 0) + `%`
                                  }`
                                : 'N/A'}
                        </span>
                        <PoolText>
                            <label>Average LTV</label>
                        </PoolText>
                    </LabelValueWithSpace>
                    <LabelValueWithSpace>
                        <span>
                            <ImageWrapper className="flash">
                                <img src={flash} alt="collectionEfficiency" />
                            </ImageWrapper>
                            {_get(data, 'previousTransactionsInfo.collectionEfficiency', '') !== 'N/A' &&
                            _size(_get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')) > 1 &&
                            _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[0] &&
                            _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[1]
                                ? _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[0] == 0 &&
                                  _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[1] == 0
                                    ? 'N/A'
                                    : `${
                                          _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[0] == 0
                                              ? 'N/A'
                                              : _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[0] + `%`
                                      } | ${
                                          _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[1] == 0
                                              ? 'N/A'
                                              : _get(data, 'previousTransactionsInfo.collectionEfficiency', '').split('/')[1] + `%`
                                      }`
                                : 'N/A'}
                        </span>
                        <PoolText>
                            {' '}
                            <label>NPA (in last 10 transactions) Among top 5% in this asset class</label>
                        </PoolText>
                    </LabelValueWithSpace>
                </PVPWrapper>
                {slideTwo ? (
                    <HighlightsWrapper>
                        <HighlightsHeading>🤝 Highlights</HighlightsHeading>
                        <LabelValueWithSpace>
                            <TextWrap>
                                <ImageWrapper className="tick">
                                    <i className="icon-tick2" />
                                </ImageWrapper>
                                <p>
                                    <b>
                                        {_get(data, 'collectionEfficiencyMetrics.totalCollectionsCount', 0) %
                                            _get(data, 'collectionEfficiencyMetrics.efficientCollectionsCount', 0) <=
                                        2
                                            ? `${_get(data, 'collectionEfficiencyMetrics.efficientCollectionsCount')} out of 
                                ${_get(data, 'collectionEfficiencyMetrics.totalCollecitonsCount', 0)}`
                                            : '0 out of 0'}
                                        &nbsp;Transactions{' '}
                                    </b>
                                    <label>
                                        {' '}
                                        with Collection Efficiency &gt;{' '}
                                        {_get(data, 'collectionEfficiencyMetrics.collectionEfficiencyThreshold', 0) >= 80
                                            ? _get(data, 'collectionEfficiencyMetrics.collectionEfficiencyThreshold')
                                            : '95'}
                                        % Among top 5% in this asset class
                                    </label>
                                </p>
                            </TextWrap>
                        </LabelValueWithSpace>
                    </HighlightsWrapper>
                ) : _size(_get(data, 'productMixInfo', [])) ? (
                    <ProductMixWrapper>
                        <HighlightsHeading>🤝 Product Mix</HighlightsHeading>
                        {!!_size(_get(data, 'productMixInfo', [])) ? (
                            _get(data, 'productMixInfo', []).map((pm: any) => (
                                <span key={pm.assetClass}>
                                    <ProductMixContainer>
                                        <span>
                                            <ImageWrapper className="flash">
                                                <img src={flash} alt="collectionEfficiency" />
                                            </ImageWrapper>
                                            {capitalize(_get(pm, 'assetClass', '').replace(/_/g, ' '))}
                                        </span>
                                    </ProductMixContainer>
                                    <ProductMixData style={{ alignItems: 'flex-start' }}>
                                        <ImageWrapper></ImageWrapper>
                                        <span>
                                            &nbsp;&nbsp;{_get(pm, 'avgTicketSize') == 0 ? 'N/A' : amountInCr(_get(pm, 'avgTicketSize')) + 'Cr'}
                                            &nbsp;&nbsp;
                                        </span>
                                        <label>Average Ticket Size&nbsp;&nbsp;</label>
                                        {
                                            <>
                                                {' '}
                                                &#8226;&nbsp;&nbsp;
                                                <span>&nbsp;&nbsp;{_get(pm, 'avgIrr') == 0 ? 'N/A' : _get(pm, 'avgIrr') + '%'}&nbsp;&nbsp;</span>
                                                <label>Average IRR</label>
                                            </>
                                        }
                                    </ProductMixData>
                                </span>
                            ))
                        ) : (
                            <NoData>No data available</NoData>
                        )}
                    </ProductMixWrapper>
                ) : (
                    <></>
                )}
                {_size(_get(data, 'productMixInfo', [])) && slideTwo ? (
                    <ProductMixWrapper>
                        <HighlightsHeading>🤝 Product Mix</HighlightsHeading>
                        {!!_size(_get(data, 'productMixInfo', [])) ? (
                            _get(data, 'productMixInfo', []).map((pm: any) => (
                                <span key={pm.assetClass}>
                                    <ProductMixContainer>
                                        <span>
                                            <ImageWrapper className="flash">
                                                <img src={flash} alt="collectionEfficiency" />
                                            </ImageWrapper>
                                            {capitalize(_get(pm, 'assetClass', '').replace(/_/g, ' '))}
                                        </span>
                                    </ProductMixContainer>
                                    <ProductMixData style={{ alignItems: 'flex-start' }}>
                                        <ImageWrapper></ImageWrapper>
                                        <span>
                                            &nbsp;&nbsp;{_get(pm, 'avgTicketSize') == 0 ? 'N/A' : amountInCr(_get(pm, 'avgTicketSize')) + 'Cr'}
                                            &nbsp;&nbsp;
                                        </span>
                                        <label>Average Ticket Size&nbsp;&nbsp;</label>
                                        {
                                            <>
                                                {' '}
                                                &#8226;&nbsp;&nbsp;
                                                <span>&nbsp;&nbsp;{_get(pm, 'avgIrr') == 0 ? 'N/A' : _get(pm, 'avgIrr') + '%'}&nbsp;&nbsp;</span>
                                                <label>Average IRR</label>
                                            </>
                                        }
                                    </ProductMixData>
                                </span>
                            ))
                        ) : (
                            <NoData>No data available</NoData>
                        )}
                    </ProductMixWrapper>
                ) : (
                    <></>
                )}
            </Carousel>
        </CarouselWrapper>
    );
};

export default PoolVsPortfolio;
