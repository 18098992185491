import humps from 'humps';
import _get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../loading';

const createActionName = (name: string) => `app/da/transaction/lossModel/${name}`;
const daBaseUrl = (id: string, type: string) => `${process.env.REACT_APP_MP_API_HOST}/${type}/${id}`;
const initialState = {
    loading: false,
    errorMessage: '',
};

export const lossModelLoading = createLoadingSelector([createActionName('LOSS_MODEL_GET')]);

export const LOSS_MODEL_GET_REQUEST = createActionName('LOSS_MODEL_GET_REQUEST');
export const LOSS_MODEL_GET_SUCCESS = createActionName('LOSS_MODEL_GET_SUCCESS');
export const LOSS_MODEL_GET_FAILURE = createActionName('LOSS_MODEL_GET_FAILURE');

export default function lossModel(state = initialState, action: any) {
    switch (action.type) {
        case LOSS_MODEL_GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOSS_MODEL_GET_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };

        case LOSS_MODEL_GET_FAILURE:
            return {
                ...state,
                errorMessage: action.message,
            };
        default:
            return state;
    }
}

const mapLossModel = (lossModelData: any) => ({
    ...lossModelData,
    data: {
        ...lossModelData.data,
        ratingCeWithStress: _get(lossModelData, 'data.ratingCeWithStress', []).map((item: any) => ({
            ...item,
            cePercentElPlusUl: Number((item.cePercentElPlusUl * 100).toFixed(2)),
        })),
        betaGhhi: _get(lossModelData, 'data.betaGhhi', []).map((item: any) => ({
            ...item,
            betaEntity: Number(item.betaEntity).toFixed(2),
            betaPool: Number(item.betaPool).toFixed(2),
            ghhiEntityR: Number(item.ghhiEntityR).toFixed(2),
            ghhiPoolR: Number(item.ghhiPoolR).toFixed(2),
        })),
        ceBuildUpA1: _get(lossModelData, 'data.ceBuildUpA1', [])
            .slice(0, -1)
            .map((item: any) => ({
                ...item,
                cashCollatPer: Number(item.cashCollatPer.slice(0, -2)),
                eisPer: Number(item.eisPer.slice(0, -2)),
                overCollatPer: Number(item.overCollatPer.slice(0, -2)),
                payOutDate: item.payOutDate,
                total: Number(item.total.slice(0, -2)),
            })),
        ceBuildUpA2: _get(lossModelData, 'data.ceBuildUpA2', [])
            .slice(0, -1)
            .map((item: any) => ({
                ...item,
                cashCollatPer: Number(item.cashCollatPer.slice(0, -2)),
                eisPer: Number(item.eisPer.slice(0, -2)),
                overCollatPer: Number(item.overCollatPer.slice(0, -2)),
                payOutDate: item.payOutDate,
                total: Number(item.total.slice(0, -2)),
            })),
    },
});

export function getLossModel(id: any, type: string) {
    return (dispatch: any) => {
        dispatch({ type: LOSS_MODEL_GET_REQUEST, id });
        return fetchUtils
            .getJSON(`${daBaseUrl(id, type)}/investment_notes`)
            .then((d: any) => humps.camelizeKeys(d))
            .then((d: any) => {
                const payload = mapLossModel(d);
                if (['processing', 'inprogress'].includes(_get(payload, 'status'))) return null;
                return dispatch({
                    type: LOSS_MODEL_GET_SUCCESS,
                    payload,
                    id,
                });
            })
            .catch((err: any) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                    toastr.error('Error Initiating Loss Modelling', m);
                });
                return dispatch({ type: LOSS_MODEL_GET_FAILURE, errorMessage: err.message, id });
            });
    };
}

export function pollLossModel(id: any, type: string) {
    return (dispatch: any) => {
        return fetchUtils
            .poll(() => dispatch(getLossModel(id, type)), 180000, 5000)
            .then(() => {})
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                if (errMsg === 'timeout') {
                    const msg = 'Timeout retrieving investment note';
                    toastr.error(msg, 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving investment note', errMsg);
                }
            });
    };
}
