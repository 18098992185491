import React from 'react';

import Image404 from '../assets/images/404.png';

const NotFound = () => (
    <div className="container" style={{ marginTop: 20 }}>
        <div className="pa-ntf">
            <div className="align-center" style={{ marginTop: 42 }}>
                <img alt="NotFound" src={Image404} width="153" height="163" style={{ display: 'unset' }} />
            </div>

            <div className="panfo">Page not found</div>
            <p className="align-center">We are sorry but the page you are looking for does exist.</p>
            <div className="align-center" style={{ width: '16%', margin: '0 auto' }}>
                <button className=" btn primary fd-vsm1 " type="submit ">
                    <a href="/" tabIndex="0 ">
                        Back to Homepage
                    </a>
                </button>
            </div>
        </div>
    </div>
);

export default NotFound;
