import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import humps from 'humps';
import * as fetchUtils from '../utils/FetchUtils';
import { createLoadingSelector } from './loading';

const initialState = {
    keyword: '',
    results: null,
};

const createActionName = (name: string) => `app/search/${name}`;
const transactionBaseURL = (searchTerm: string) =>
    `${process.env.REACT_APP_MP_API_HOST}/transactions?page=1&per_page=3&tab=all&search=${searchTerm}&is_self_serve=${false}`;
const clientsBaseURL = (searchTerm: string) => `${process.env.REACT_APP_CS_OB_API_HOST}/customers?page=1&page_size=3&search=${searchTerm}`;

export const searchLoading = createLoadingSelector([createActionName('SEARCH')]);
export const SEARCH_REQUEST = createActionName('SEARCH_REQUEST');
export const SEARCH_SUCCESS = createActionName('SEARCH_SUCCESS');
export const SEARCH_FAILURE = createActionName('SEARCH_FAILURE');

export const SEARCH_ONCHANGE = createActionName('SEARCH_ONCHANGE');
export const SEARCH_RESET = createActionName('SEARCH_RESET');

export default function transactions(state = initialState, action: any) {
    switch (action.type) {
        case SEARCH_ONCHANGE: {
            return {
                ...state,
                keyword: action.payload,
            };
        }
        case SEARCH_SUCCESS: {
            return {
                ...state,
                results: action.payload,
            };
        }
        case SEARCH_RESET: {
            return {
                ...state,
                keyword: '',
                results: null,
            };
        }
        default:
            return state;
    }
}

const getTransactions = (searchTerm: string) => {
    return fetchUtils
        .getJSON(`${transactionBaseURL(searchTerm)}`)
        .then((d: any) => humps.camelizeKeys(d))
        .catch(() => {
            toastr.error('Sorry!', 'Error fetching transactions!');
        });
};

const getClients = (searchTerm: string) => {
    return fetchUtils
        .getJSON(`${clientsBaseURL(searchTerm)}`)
        .then((d: any) => humps.camelizeKeys(d))
        .catch(() => {
            toastr.error('Sorry!', 'Error fetching clients!');
        });
};

export const getSearchResults = (searchTerm: string, canViewTransactions: boolean) => {
    return (dispatch: any) => {
        if (searchTerm.length >= 3) {
            dispatch({ type: SEARCH_REQUEST });
            return canViewTransactions
                ? Promise.all([getTransactions(searchTerm), getClients(searchTerm)])
                      .then((d: any) => {
                          return dispatch({
                              type: SEARCH_SUCCESS,
                              payload: {
                                  transactions: _get(d, '[0].transactions', []),
                                  clients: _get(d, '[1].customers', []).slice(0, 3),
                              },
                          });
                      })
                      .catch((err: any) => {
                          fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                              toastr.error('Error getting search results!', m);
                          });
                          return dispatch({ type: SEARCH_FAILURE, errorMessage: err.message });
                      })
                : getClients(searchTerm)
                      .then((d: any) => {
                          return dispatch({
                              type: SEARCH_SUCCESS,
                              payload: {
                                  transactions: [],
                                  clients: _get(d, 'customers', []).slice(0, 3),
                              },
                          });
                      })
                      .catch((err: any) => {
                          fetchUtils.handleErrorV2(dispatch, err).then((m: any) => {
                              toastr.error('Error getting search results!', m);
                          });
                          return dispatch({ type: SEARCH_FAILURE, errorMessage: err.message });
                      });
        }
    };
};

export const searchOnChange = (keyword: string) => {
    return (dispatch: any) => {
        dispatch({ type: SEARCH_ONCHANGE, payload: keyword });
    };
};

export const resetSearchResults = () => {
    return (dispatch: any) => {
        dispatch({ type: SEARCH_RESET });
    };
};
