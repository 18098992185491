import humps from 'humps';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import { addDownloadDetails } from 'app/utils/TransactionUtils.tsx';
import * as fetchUtils from 'app/utils/FetchUtils';
import { createLoadingSelector } from 'app/reducers/loading';

const initialState = null;

// actions
const createActionName = (name) => `app/da/transaction/details/${name}`;

export const TRANSACTION_GET = createActionName('TRANSACTION_GET');
export const GET_EXEC_DOCS_SUCCESS = createActionName('GET_EXEC_DOCS_SUCCESS');
export const FUNDING_STATUS_REQUEST = createActionName('FUNDING_STATUS_REQUEST');
export const FUNDING_STATUS_SUCCESS = createActionName('FUNDING_STATUS_SUCCESS');
export const FUNDING_STATUS_FAILURE = createActionName('FUNDING_STATUS_FAILURE');

export default function details(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_GET:
            return {
                ...state,
                ...action.data,
                documents: addDownloadDetails(_get(action, 'data')),
            };
        case GET_EXEC_DOCS_SUCCESS:
            return {
                ...state,
                execDocs: action.data.execDocs,
                otherDocs: action.data.others,
            };
        default:
            return state;
    }
}

const daBaseUrl = (id) => `${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}`;

const unMapDetails = (data) => humps.camelizeKeys(data);

export function getDetails(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${daBaseUrl(id)}`)
            .then((d) => unMapDetails(d))
            .then((data) => dispatch({ type: TRANSACTION_GET, data, id }))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Direct Assignments data', m);
                }),
            );
}

export function getDAExecDocs(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(`${process.env.REACT_APP_MP_API_HOST}/direct_assignments/${id}/executable_documents`)
            .then((d) => humps.camelizeKeys(d))
            .then((d) => {
                const docs = d.documents.filter((doc) => doc.files.length > 0);
                const isGenerated = docs.every((item) => item.files[0].state === 'generated');
                const isFailed = docs.some((item) => item.files[0].state === 'failed');
                if (isFailed) {
                    return null;
                } else if (isGenerated) {
                    dispatch({ type: GET_EXEC_DOCS_SUCCESS, data: { execDocs: docs, others: d.otherFiles }, id });
                    return docs;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error fetching documents', m);
                }),
            );
}

const params = (data) => {
    return {
        direct_assignment_transaction: {
            ...data,
        },
    };
};

export const fundingStatusLoader = createLoadingSelector([createActionName('FUNDING_STATUS')]);

export function updateDA(id, values) {
    return (dispatch) => {
        dispatch({ type: FUNDING_STATUS_REQUEST });
        return fetchUtils
            .patchJSON(`${daBaseUrl(id)}`, params(values))
            .then((d) => unMapDetails(d))
            .then((d) => dispatch({ type: TRANSACTION_GET, data: d, id }))
            .then(() => {
                if (values.direct_settlement) {
                    dispatch({ type: FUNDING_STATUS_SUCCESS });
                    toastr.success('Success!', 'Transaction status updated.');
                }
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: FUNDING_STATUS_FAILURE });
                    toastr.error('Error while updating', m);
                }),
            );
    };
}
