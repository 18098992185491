import * as fetchUtils from 'app/utils/FetchUtils';
import humps from 'humps';
import _has from 'lodash/has';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { toastr } from 'react-redux-toastr';
import { createLoadingSelector } from 'app/reducers/loading';
import { EIS_TRAP } from 'app/constants/TransactionConstants';
import { unMapPTC, baseURL, TRANSACTION_GET } from './transaction';

export const createActionName = (name) => `app/admin/ptcv1/${name}`;

export const POLL_OPTIMUM_STRUCTURE_REQUEST = createActionName('POLL_OPTIMUM_STRUCTURE_REQUEST');
export const POLL_OPTIMUM_STRUCTURE_SUCCESS = createActionName('POLL_OPTIMUM_STRUCTURE_SUCCESS');
export const POLL_OPTIMUM_STRUCTURE_FAILURE = createActionName('POLL_OPTIMUM_STRUCTURE_FAILURE');

export const GENERATE_OPTIMUM_STRUCTURE_REQUEST = createActionName('GENERATE_OPTIMUM_STRUCTURE_REQUEST');
export const GENERATE_OPTIMUM_STRUCTURE_SUCCESS = createActionName('GENERATE_OPTIMUM_STRUCTURE_SUCCESS');
export const GENERATE_OPTIMUM_STRUCTURE_FAILURE = createActionName('GENERATE_OPTIMUM_STRUCTURE_FAILURE');

export const APPLYING_OPTIMUM_STRUCTURE_REQUEST = createActionName('APPLYING_OPTIMUM_STRUCTURE_REQUEST');
export const APPLYING_OPTIMUM_STRUCTURE_SUCCESS = createActionName('APPLYING_OPTIMUM_STRUCTURE_SUCCESS');
export const APPLYING_OPTIMUM_STRUCTURE_FAILURE = createActionName('APPLYING_OPTIMUM_STRUCTURE_FAILURE');

export const AUTO_COMPUTING_CE_REQUEST = createActionName('AUTO_COMPUTING_CE_REQUEST');
export const AUTO_COMPUTING_CE_SUCCESS = createActionName('AUTO_COMPUTING_CE_SUCCESS');
export const AUTO_COMPUTING_CE_FAILURE = createActionName('AUTO_COMPUTING_CE_FAILURE');

export const isOptimumStrLoading = createLoadingSelector([createActionName('POLL_OPTIMUM_STRUCTURE')]);
export const isGenerateLoading = createLoadingSelector([createActionName('GENERATE_OPTIMUM_STRUCTURE')]);
export const isApplyingLoading = createLoadingSelector([createActionName('APPLYING_OPTIMUM_STRUCTURE')]);
export const isCEAutoComputing = createLoadingSelector([createActionName('AUTO_COMPUTING_CE')]);

export const initStructuringValue = {
    trancheCount: 2,
    trancheUnit: 'percent',
    schedule: {
        settlementDate: null,
        payinDate: null,
        payoutDate: null,
        firstCollectionOffset: null,
        servicerFee: 500000,
        servicerFeeUnit: 'amount',
    },
    creditEnhancement: {
        slce: {
            value: 0,
            unit: 'percent',
            servicerFee: 5,
        },
        bankGurantee: {
            value: 0,
            unit: 'percent',
            servicerFee: 5,
        },
        corporateGuarantee: {
            value: 0,
            unit: 'percent',
        },
        cashCollateral: {
            value: 0,
            unit: 'percent',
        },
    },
    tranches: [],
};

export const initAutoStrPrefillValue = {
    trancheCount: initStructuringValue.trancheCount,
    trancheUnit: initStructuringValue.trancheUnit,
    creditEnhancement: {
        ..._omit(initStructuringValue.creditEnhancement, ['corporateGuarantee', 'bankGurantee']),
    },
    tranches: initStructuringValue.tranches,
};

export const initAutoStructurePref = {
    autoStructuringPreferences: {
        tranchePrefs: [
            {
                series: 'a1',
                yield: null,
                interestMethod: null,
                isEnabled: true,
            },
            {
                series: 'a2',
                yield: null,
                interestMethod: 'XIRR',
                isEnabled: false,
                min: null,
            },
        ],
        cashCollateral: {
            min: null,
            max: null,
        },
        totalCeRequired: null,
        guarantee: false,
        guarantees: [
            {
                type: 'SLCE',
                minGuarantee: null,
                gfee: null,
            },
        ],
    },
};

export function checkOptimumStructure(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(baseURL(id))
            .then((d) => {
                if (!d.auto_structuring_generation_flag) {
                    return d;
                }
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving Optimum Suggestion', m);
                }),
            );
}

export function pollOptimumStructure(id) {
    return (dispatch) => {
        dispatch({ type: POLL_OPTIMUM_STRUCTURE_REQUEST });
        return fetchUtils
            .poll(() => dispatch(checkOptimumStructure(id)), 180000, 1000)
            .then((d) => {
                const cloneData = { ...d };
                if (_get(d, 'auto_structuring_suggestions[0].label', '') === 'error') {
                    cloneData.auto_structuring_suggestions = [];
                    toastr.error('Error', _get(d, 'auto_structuring_suggestions[0].type'));
                } else {
                    toastr.success('Success!', 'Optimum structure updated.');
                }
                if (_get(d, 'structuring')) {
                    cloneData.structuring = {
                        ..._get(d, 'structuring'),
                        schedule: {
                            ..._get(d, 'structuring.schedule'),
                            principal_repayment: _get(d, 'loss_estimation_preferences.principal_expectation'),
                        },
                    };
                }
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(cloneData), id });
                dispatch({ type: POLL_OPTIMUM_STRUCTURE_SUCCESS });
            })
            .catch((e) => {
                const errMsg = (e && e.message) || 'Something went wrong, please try again after sometime';
                dispatch({ type: POLL_OPTIMUM_STRUCTURE_FAILURE });
                if (errMsg === 'timeout') {
                    toastr.error('Timeout retrieving Optimum Suggestion', 'Please try again after sometime.');
                } else {
                    toastr.error('Error retrieving Optimum Suggestion', errMsg);
                }
            });
    };
}

function mapGnOptimumStructuring(data) {
    const {
        autoStructuringPreferences: { tranchePrefs, guarantee, guarantees, totalCeRequired, ...formData },
        lossEstimationPreferences = {},
    } = data;
    const autoStructurePref = {
        tranchePrefs: tranchePrefs.reduce((result, d) => {
            const resultCopy = [...result];
            resultCopy.push({ interestMethod: d.interestMethod, yield: d.yield, series: d.series, ...(_has(d, 'min') ? { min: d.min } : {}) });
            return resultCopy;
        }, []),
        totalCeRequired: _get(lossEstimationPreferences, 'ratingAgencyName', '') !== '' ? null : totalCeRequired,
        ...(guarantee && { guarantees }),
        ...formData,
    };
    const { EIS_LEAKING, TRIGGER, TURBO_PAR } = EIS_TRAP;
    const lossEstPref = {
        ...(_get(lossEstimationPreferences, 'principalExpectation') ? { principalExpectation: lossEstimationPreferences.principalExpectation } : {}),
        ...(_get(lossEstimationPreferences, 'ratingAgencyName') ? { ratingAgencyName: lossEstimationPreferences.ratingAgencyName } : {}),
        ...(_get(lossEstimationPreferences, 'ratingAgencyId') ? { ratingAgencyId: lossEstimationPreferences.ratingAgencyId } : {}),
        ...(_get(lossEstimationPreferences, 'targetRating') ? { targetRating: lossEstimationPreferences.targetRating } : {}),
        eisTrap: {
            ...(_get(lossEstimationPreferences, 'eisTrap') === TURBO_PAR.value ? { turbo_par: true } : {}),
            ...(_get(lossEstimationPreferences, 'eisTrap') === EIS_LEAKING.value ? { eis_leaking: true } : {}),
            ...(_get(lossEstimationPreferences, 'eisTrap') === TRIGGER.value ? { trigger: lossEstimationPreferences.trigger } : {}),
        },
    };
    const processedData = {
        ptcTransaction: {
            lossEstimationPreferences: { ...lossEstPref },
            autoStructuringPreferences: {
                ...autoStructurePref,
            },
        },
    };
    return humps.decamelizeKeys(processedData);
}

export function generateOptimumStructuring(id, data, setSubmitting, setOpenPopup) {
    return (dispatch) => {
        dispatch({ type: GENERATE_OPTIMUM_STRUCTURE_REQUEST });
        fetchUtils
            .patchJSON(baseURL(id), mapGnOptimumStructuring(data))
            .then((d) => dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id }))
            .then(() => {
                dispatch({ type: GENERATE_OPTIMUM_STRUCTURE_SUCCESS });
                setSubmitting(false);
                setOpenPopup(false);
                toastr.success('Success!', 'Initiated optimum structuring. Please wait a few seconds.');
            })
            .then(() => dispatch(pollOptimumStructure(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: GENERATE_OPTIMUM_STRUCTURE_FAILURE });
                    toastr.error('Error updating optimum structure', m);
                }),
            );
    };
}

export function applyOptimumStructure(id, data) {
    const processedData = {
        ptcTransaction: {
            appliedStructure: {
                ...data,
            },
        },
    };
    return (dispatch) => {
        dispatch({ type: APPLYING_OPTIMUM_STRUCTURE_REQUEST });
        fetchUtils
            .patchJSON(baseURL(id), humps.decamelizeKeys(processedData))
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id, tempUpdate: true });
            })
            .then(() => {
                dispatch({ type: APPLYING_OPTIMUM_STRUCTURE_SUCCESS });
                toastr.success('Success!', 'Applied optimum structuring. Please wait a few seconds.');
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: APPLYING_OPTIMUM_STRUCTURE_FAILURE });
                    toastr.error('Error applying optimum structure', m);
                }),
            );
    };
}

export function getCreditEnhancement(id) {
    return (dispatch) =>
        fetchUtils
            .getJSON(baseURL(id))
            .then((d) => {
                if (!d.ce_flag) {
                    return d;
                }
                dispatch({ type: TRANSACTION_GET, data: { ...unMapPTC(d), pollingInProgress: true }, id });
                return null;
            })
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: AUTO_COMPUTING_CE_FAILURE, id });
                    toastr.error('Error Auto computing CE', m);
                }),
            );
}

export function pollForCE(id) {
    return (dispatch) => {
        fetchUtils
            .poll(() => dispatch(getCreditEnhancement(id)), 1200000, 15000)
            .then((d) => {
                dispatch({ type: TRANSACTION_GET, data: unMapPTC(d), id });
                dispatch({ type: AUTO_COMPUTING_CE_SUCCESS });
                toastr.success('Success!', 'CE Auto Computed');
            })
            .catch((ex) => {
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: AUTO_COMPUTING_CE_FAILURE, id });
                });
            });
    };
}

export function autoComputeCE(id) {
    return (dispatch) => {
        dispatch({ type: AUTO_COMPUTING_CE_REQUEST, id });
        fetchUtils
            .patchJSON(`${baseURL(id)}/predicted_credit_enhancement`, { ce_flag: true })
            .then(() => dispatch(pollForCE(id)))
            .catch((ex) =>
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    dispatch({ type: AUTO_COMPUTING_CE_FAILURE });
                    toastr.error('Error updating optimum structure', m);
                }),
            );
    };
}

export default generateOptimumStructuring;
