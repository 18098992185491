export const BROWSER_LIST = {
    IE: 'ie',
    Opera: 'opera',
    Edge: 'edge',
    Firefox: 'firefox',
    Chrome: 'chrome',
    Safari: 'safari',
    Blink: 'blink',
    GoogleBot: 'googlebot',
    Android: 'android',
};

export const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;
export const isGoogleBot = navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1;
export const isIE = false || !!document.documentMode;
export const isEdge = !isIE && !!window.StyleMedia;
export const isFirefox = typeof InstallTrigger !== 'undefined';
export const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
export const isChrome =
    !isGoogleBot &&
    !isEdge &&
    !isOpera &&
    !!window.chrome &&
    (!!window.chrome.webstore || navigator.vendor.toLowerCase().indexOf('google inc.') !== -1);
export const isSafari = !isChrome && navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
export const isBlink = (isChrome || isOpera) && !!window.CSS;

const getBrowserName = () => {
    let browser = '';
    if (isIE) {
        browser = BROWSER_LIST.IE;
    } else if (isEdge) {
        browser = BROWSER_LIST.Edge;
    } else if (isFirefox) {
        browser = BROWSER_LIST.Firefox;
    } else if (isOpera) {
        browser = BROWSER_LIST.Opera;
    } else if (isChrome) {
        browser = BROWSER_LIST.Chrome;
    } else if (isSafari) {
        browser = BROWSER_LIST.Safari;
    } else if (isBlink) {
        browser = BROWSER_LIST.Blink;
    } else if (isGoogleBot) {
        browser = BROWSER_LIST.GoogleBot;
    }
    return browser;
};

export default getBrowserName;
