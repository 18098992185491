import _has from 'lodash/has';
import _get from 'lodash/get';

const normalizeColumn = (data: any, price: any, columnData: any, index: number) => {
    const tableVal: any = { className: '', label: '', key: '' };
    let key = data.key.find((d: string) => {
        return price[d];
    });
    if (!key) {
        key = _get(data, `key[0]`, '');
    }
    if (key) {
        const filteredVal = _has(columnData, key) ? columnData[key](price, key, index) : columnData.default(price, key, index);
        tableVal.key = key;
        tableVal.label = typeof filteredVal === 'object' ? _get(filteredVal, 'label', '') : filteredVal;
        tableVal.className = _get(filteredVal, 'className', '');
    }
    return tableVal;
};

const normalizeHeader = (header: Array<object>, price: object, columnData: object, index: number): Array<object> => {
    return header.map((data: object) => normalizeColumn(data, price, columnData, index));
};

const normalizeRow = (headers: Array<object>, price: object, columnData: object, index: number, rowClassName: string): object => {
    const headerData = normalizeHeader(headers, price, columnData, index);
    return { data: [...headerData], className: rowClassName, index };
};

const normalizeTable = (payload: any): Array<object> => {
    const {
        response = [],
        headers = [],
        columnData = {
            default: (price: any, key: string): string => {
                return price[key];
            },
        },
        rowClassName = '',
    } = payload;

    return response.length > 0 && response.map((price: object, index: number) => normalizeRow(headers, price, columnData, index, rowClassName));
};

export const calculateNewPosition = (pos: any): any => {
    if(pos.left < 10) {
        pos.left = 10;
    }
    return pos;
};

export default normalizeTable;
