import React from 'react';
// import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { downloadFile } from 'app/reducers/amplitude';

import * as RouterUtils from '../utils/RouterUtils';
import { getEnv } from 'app/utils/FetchUtils';
// Fixme: Add props to prop-validation
const ParsedLink = ({
    path,
    keys = {},
    to,
    children,
    downloadParams,
    downloadFileDispatch,
    newTab = false,
    exact = true,
    onClick,
    className = '',
    activeClassName = 'active',
    ...rest
}) => {
    const toParsed = RouterUtils.parsedRoute({ path, keys, to });

    return /^https?:\/\//.test(toParsed) ? (
        <a
            href={getEnv(toParsed)}
            rel="noreferrer"
            target={newTab ? '_blank' : null}
            {...rest}
            className={className}
            onClick={() => {
                if (downloadParams) {
                    downloadFileDispatch(downloadParams);
                }
                if (typeof onClick === 'function') onClick();
            }}
        >
            {children}
        </a>
    ) : (
        <NavLink
            target={newTab ? '_blank' : null}
            {...rest}
            className={({ isActive }) => (isActive ? `${className} ${activeClassName}` : className)}
            onClick={onClick}
            to={toParsed}
            exact={exact}
        >
            {children}
        </NavLink>
    );
};

// ParsedLink.propTypes = {
//     children: PropTypes.node.isRequired,
//     to: PropTypes.string,
//     path: PropTypes.string,
//     keys: PropTypes.object, // eslint-disable-line react/forbid-prop-types
//     newTab: PropTypes.bool,
//     exact: PropTypes.bool,
// };

// ParsedLink.defaultProps = {
//     path: null,
//     keys: null,
//     to: null,
//     newTab: false,
//     exact: true,
// };

const mapDispatchToProps = (dispatch) => ({
    downloadFileDispatch: (p) => dispatch(downloadFile(p)),
});

export default connect(null, mapDispatchToProps)(ParsedLink);
