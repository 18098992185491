import React from 'react';
import Image403 from '../assets/images/403.png';
import Button from 'app/products/UIComponentsV1/Button';
import Auth from 'app/utils/Auth';
import { useAuth0 } from '@auth0/auth0-react';

const Forbidden = () => {
    const { logout } = useAuth0();
    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="pa-ntf">
                <div className="align-center" style={{ marginTop: 42 }}>
                    <img alt="Forbidden" src={Image403} width="153" height="163" style={{ display: 'unset' }} />
                </div>

                <div className="panfo">Access Denied</div>
                <p className="align-center">Sorry, but you don't have permission to access this page.</p>
                <div className="align-center" style={{ width: '16%', margin: '0 auto' }}>
                    <button className=" btn primary fd-vsm1 " type="submit ">
                        <a href="/" tabIndex="0 ">
                            Back to Homepage
                        </a>
                    </button>
                    <div style={{ margin: '10px auto' }}>
                        <Button
                            type="button"
                            textButton
                            style={{
                                margin: 'auto',
                                color: '#1c90d8',
                                fontWeight: 400,
                                borderBottom: 'none',
                            }}
                            onClick={() => {
                                Auth.logout();
                                logout({ returnTo: window.origin });
                            }}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forbidden;
