import { AmplitudeEvent, EventUserProps } from './AmplitudeTypes';
import { APP_AMPLITUDE_MODULE, MODULE_DOMAIN_MAP } from './AmplitudeConstants';
import { getNextUrl } from 'app/utils/Auth';
export default class AmplitudeService {
    static userProps: EventUserProps;

    constructor(userProps: EventUserProps) {
        AmplitudeService.userProps = userProps;
    }

    static logEvent(event: AmplitudeEvent, eventProperties: Record<any, any>, callback = null): void {
        const webEngage = (window as any)?.webengage;

        window?.amplitudeInstance?.logEvent(
            event?.eventName,
            {
                category: event?.category ?? '',
                ...eventProperties,
                module: APP_AMPLITUDE_MODULE,
            },
            callback,
        );
        webEngage?.track(event?.eventName, {
            category: event?.category ?? '',
            ...eventProperties,
            module: APP_AMPLITUDE_MODULE,
        });
    }

    static getModuleFromURL(url: any = getNextUrl()): string {
        return MODULE_DOMAIN_MAP?.find((d) => url?.includes(d?.domain))?.module ?? 'pool';
    }
}
