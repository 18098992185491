import React from 'react';
import ParsedLink from 'app/components/ParsedLink';
import { InvOnboardBannerInner, Logo, WelcomeNote, FirmFeatures, FirmNumbers, FirmFeaturesList, DescriptiveIcon } from './styled';
import LogoImg from 'app/assets/images/yubi-logos/yubi-big.svg';

const InvOnboardBanner = () => (
    <InvOnboardBannerInner className="adjacent-wrapper">
        <Logo>
            <ParsedLink to={process.env.REACT_APP_NEW_HOST} title="Yubi">
                <figure>
                    <img src={LogoImg} alt="Logo" />
                </figure>
            </ParsedLink>
        </Logo>
        <WelcomeNote>
            <h1>Expand your Horizon with Yubi</h1>
            <p>
                Get access to wider opportunities across different debt instruments enabled by hassle-free online execution and post settlement
                support!
            </p>
            <FirmNumbers>
                <li>
                    <span>1000+</span>
                    <p>Clients</p>
                </li>
                <li className="left-space">
                    <span>1500+</span>
                    <p>Transactions</p>
                </li>
                <li className="left-space">
                    <span>
                        <i className="icon-rupee-indian" />
                        65,000+ Cr
                    </span>
                    <p>Debt Facilitated</p>
                </li>
            </FirmNumbers>
            <FirmFeatures>
                <h1>Why Yubi?</h1>
                <FirmFeaturesList>
                    <li>
                        <DescriptiveIcon>
                            <i className="icon nucleoinvest-multiple-11-2" />
                        </DescriptiveIcon>
                        <div className="features-summary">
                            <span>Access to a Wide range of Clients</span>
                            <p>1000+ clients with a wide range of asset class</p>
                        </div>
                    </li>
                    <li>
                        <DescriptiveIcon>
                            <i className="icon nucleoinvest-a-check" />
                        </DescriptiveIcon>
                        <div className="features-summary">
                            <span>Passionate & Competent Relationship Managers</span>
                            <p>Who will strive to understand your business</p>
                        </div>
                    </li>
                    <li>
                        <DescriptiveIcon>
                            <i className="icon nucleoinvest-crown" />
                        </DescriptiveIcon>
                        <div className="features-summary">
                            <span>Market Leader</span>
                            <p>In Structured Finance products</p>
                        </div>
                    </li>
                    <li>
                        <DescriptiveIcon>
                            <i className="icon nucleoinvest-like" />
                        </DescriptiveIcon>
                        <div className="features-summary">
                            <span>Hasle Free Execution</span>
                            <p>Perform all activities in one place efficiently</p>
                        </div>
                    </li>
                    <li>
                        <DescriptiveIcon>
                            <i className="icon nucleoinvest-parking-sensors" />
                        </DescriptiveIcon>
                        <div className="features-summary">
                            <span>Transparency</span>
                            <p>Access to data to guide your decision making</p>
                        </div>
                    </li>
                </FirmFeaturesList>
            </FirmFeatures>
        </WelcomeNote>
    </InvOnboardBannerInner>
);
export default InvOnboardBanner;
