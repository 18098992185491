import humps from 'humps';
import uniqBy from 'lodash/uniqBy';
import { toastr } from 'react-redux-toastr';
import * as fetchUtils from '../../../utils/FetchUtils';

const initialState = {
    transactionList: { count: 0, list: [] },
    transactionListPage: 1,
    transactionFilter: [{ name: 'tab', value: 'live' }],
    isFetching: false,
};

// actions
const createActionName = (name) => `app/cps/transaction/${name}`;
export const TRANSACTION_LIST = createActionName('TRANSACTION_LIST');
export const TRANSACTION_LIST_VIEW_MORE = createActionName('TRANSACTION_LIST_VIEW_MORE');
export const TRANSACTION_LIST_FILTER = createActionName('TRANSACTION_LIST_FILTER');

export const FETCHING_DATA = createActionName('FETCHING_DATA');

export default function transaction(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_LIST: {
            return Object.assign({}, state, {
                transactionList: {
                    count: action.data.total_count,
                    list:
                        parseInt(action.page, 10) > 1
                            ? uniqBy([...state.transactionList.list, ...action.data.transactions], 'id')
                            : action.data.transactions,
                },
                isFetching: false,
            });
        }
        case TRANSACTION_LIST_VIEW_MORE:
            return Object.assign({}, state, {
                transactionListPage: state.transactionListPage + 1,
            });
        case TRANSACTION_LIST_FILTER:
            return {
                ...state,
                transactionFilter: uniqBy([action.data, ...state.transactionFilter], (o) => o.name),
                transactionList: { count: 0, list: [] },
                transactionListPage: 1,
            };
        case FETCHING_DATA:
            return Object.assign({}, state, {
                isFetching: action.status,
            });
        default:
            return state;
    }
}

export function fetchingData(status = true) {
    return {
        type: FETCHING_DATA,
        status,
    };
}

export function transactionList(data, page) {
    return {
        type: TRANSACTION_LIST,
        data,
        page,
    };
}

export function transactionListViewMore() {
    return {
        type: TRANSACTION_LIST_VIEW_MORE,
    };
}

export function transactionFilterUpdate(data) {
    return {
        type: TRANSACTION_LIST_FILTER,
        data,
    };
}

export function getTransactionList(page = 1, filter, actor) {
    return (dispatch) => {
        const tab = filter && filter.find((f) => f.name === 'tab');
        const tabValue = (tab && tab.value) || '';
        const decamActor = humps.decamelize(humps.camelize(actor));
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${page}&per_page=20&actor=${decamActor}&tab=${tabValue}&is_self_serve=${false}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(transactionList(d, page));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function searchTransactions(page = 1, filter, actor, searchTerm) {
    return (dispatch) => {
        const tab = filter && filter.find((f) => f.name === 'tab');
        const tabValue = (tab && tab.value) || '';
        const decamActor = humps.decamelize(humps.camelize(actor));
        dispatch(fetchingData());
        return fetchUtils
            .getJSON(
                `${
                    process.env.REACT_APP_MP_API_HOST
                }/transactions?page=${page}&items_per_page=20&actor=${decamActor}&tab=${tabValue}&search=${searchTerm}&is_self_serve=${false}`,
            )
            .then((d) => {
                dispatch(fetchingData(false));
                return dispatch(transactionList(d, page));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                fetchUtils.handleErrorV2(dispatch, ex).then((m) => {
                    toastr.error('Error retrieving transaction', m);
                });
            });
    };
}

export function viewMoreTransactionList() {
    return (dispatch) => dispatch(transactionListViewMore());
}

export function updateTransactionFilter(data) {
    return (dispatch) => dispatch(transactionFilterUpdate(data));
}
