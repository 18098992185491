import styled from 'styled-components';

export const Foot = styled.footer`
    padding: 15px 0;
    color: #fff;
    text-align: center;
    background: #58595b;
    > .container {
        max-width: inherit;
    }
    span {
        font-size: 12px;
        a {
            color: #fff;
            transition: all 0.2s ease;
            &:hover {
                color: #00c7cc;
            }
        }
    }
    .social-links {
        float: right;
        text-align: right;
        li {
            vertical-align: middle;
            font-size: 12px;
            &:first-child {
                position: relative;
                padding-right: 20px;
                &:after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    top: 0;
                    right: 8px;
                    background: #fff;
                }
            }
            a {
                color: #fff;
                transition: all 0.2s ease;
                i {
                    font-size: 16px;
                    transition: all 0.2s ease;
                    vertical-align: middle;
                    display: inline-block;
                }
            }
            &:nth-child(2) {
                padding-left: 0;
            }
        }
    }
    ul {
        display: inline-block;
        float: left;
        text-align: left;
        li {
            padding: 0 5px;
            a {
                font-size: 12px;
                position: relative;
                color: #fff;
                i {
                    vertical-align: middle;
                    font-size: 14px;
                    margin-right: 2px;
                }
                &:hover {
                    color: #00c7cc;
                }
                figure {
                    display: inline-block;
                    vertical-align: sub;
                    margin-left: 5px;
                    img {
                        min-width: 80px;
                        max-width: 80px;
                    }
                }
            }
            &:first-child {
                position: relative;
                padding-right: 20px;
            }
        }
    }
    @media (max-width: 991px) {
        span {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
        ul {
            display: block;
            float: none;
            margin-bottom: 10px;
            text-align: center;
            li {
                &:first-child {
                    padding-right: 5px;
                    &:after {
                        right: -2px;
                    }
                }
                a {
                    figure {
                        img {
                            min-width: 80px;
                        }
                    }
                }
            }
        }
        .social-links {
            margin-bottom: 0;
            float: none;
            text-align: center;
            li {
                padding: 0;
                &:first-child {
                    padding-right: 5px;
                    &:after {
                        width: 0px;
                    }
                }
                &:not(:first-child) {
                    display: none;
                }
            }
        }
    }
`;
