export const MAP_TYPES = {
    COUNTRY: 0,
    STATE: 1,
};

export const UNASSIGNED_STATE_CODE = 'UN';

export const MAPS_DIR = '/projected_maps';

export const MAP_META = {
    AP: {
        geoDataFile: `${MAPS_DIR}/andhrapradesh.json`,
        mapType: MAP_TYPES.STATE,
    },
    AR: {
        geoDataFile: `${MAPS_DIR}/arunachalpradesh.json`,
        mapType: MAP_TYPES.STATE,
    },
    AS: {
        geoDataFile: `${MAPS_DIR}/assam.json`,
        mapType: MAP_TYPES.STATE,
    },
    BR: {
        geoDataFile: `${MAPS_DIR}/bihar.json`,
        mapType: MAP_TYPES.STATE,
    },
    CT: {
        geoDataFile: `${MAPS_DIR}/chhattisgarh.json`,
        mapType: MAP_TYPES.STATE,
    },
    GA: {
        geoDataFile: `${MAPS_DIR}/goa.json`,
        mapType: MAP_TYPES.STATE,
    },
    GJ: {
        geoDataFile: `${MAPS_DIR}/gujarat.json`,
        mapType: MAP_TYPES.STATE,
    },
    HR: {
        geoDataFile: `${MAPS_DIR}/haryana.json`,
        mapType: MAP_TYPES.STATE,
    },
    HP: {
        geoDataFile: `${MAPS_DIR}/himachalpradesh.json`,
        mapType: MAP_TYPES.STATE,
    },
    JK: {
        geoDataFile: `${MAPS_DIR}/jammukashmir.json`,
        mapType: MAP_TYPES.STATE,
    },
    JH: {
        geoDataFile: `${MAPS_DIR}/jharkhand.json`,
        mapType: MAP_TYPES.STATE,
    },
    KA: {
        geoDataFile: `${MAPS_DIR}/karnataka.json`,
        mapType: MAP_TYPES.STATE,
    },
    KL: {
        geoDataFile: `${MAPS_DIR}/kerala.json`,
        mapType: MAP_TYPES.STATE,
    },
    MP: {
        geoDataFile: `${MAPS_DIR}/madhyapradesh.json`,
        mapType: MAP_TYPES.STATE,
    },
    MH: {
        geoDataFile: `${MAPS_DIR}/maharashtra.json`,
        mapType: MAP_TYPES.STATE,
    },
    MN: {
        geoDataFile: `${MAPS_DIR}/manipur.json`,
        mapType: MAP_TYPES.STATE,
    },
    ML: {
        geoDataFile: `${MAPS_DIR}/meghalaya.json`,
        mapType: MAP_TYPES.STATE,
    },
    MZ: {
        geoDataFile: `${MAPS_DIR}/mizoram.json`,
        mapType: MAP_TYPES.STATE,
    },
    NL: {
        geoDataFile: `${MAPS_DIR}/nagaland.json`,
        mapType: MAP_TYPES.STATE,
    },
    OR: {
        geoDataFile: `${MAPS_DIR}/odisha.json`,
        mapType: MAP_TYPES.STATE,
    },
    PB: {
        geoDataFile: `${MAPS_DIR}/punjab.json`,
        mapType: MAP_TYPES.STATE,
    },
    RJ: {
        geoDataFile: `${MAPS_DIR}/rajasthan.json`,
        mapType: MAP_TYPES.STATE,
    },
    SK: {
        geoDataFile: `${MAPS_DIR}/sikkim.json`,
        mapType: MAP_TYPES.STATE,
    },
    TN: {
        geoDataFile: `${MAPS_DIR}/tamilnadu.json`,
        mapType: MAP_TYPES.STATE,
    },
    TG: {
        geoDataFile: `${MAPS_DIR}/telangana.json`,
        mapType: MAP_TYPES.STATE,
    },
    TR: {
        geoDataFile: `${MAPS_DIR}/tripura.json`,
        mapType: MAP_TYPES.STATE,
    },
    UT: {
        geoDataFile: `${MAPS_DIR}/uttarakhand.json`,
        mapType: MAP_TYPES.STATE,
    },
    UP: {
        geoDataFile: `${MAPS_DIR}/uttarpradesh.json`,
        mapType: MAP_TYPES.STATE,
    },
    WB: {
        geoDataFile: `${MAPS_DIR}/westbengal.json`,
        mapType: MAP_TYPES.STATE,
    },
    AN: {
        geoDataFile: `${MAPS_DIR}/andamannicobarislands.json`,
        mapType: MAP_TYPES.STATE,
    },
    CH: {
        geoDataFile: `${MAPS_DIR}/chandigarh.json`,
        mapType: MAP_TYPES.STATE,
    },
    DN: {
        geoDataFile: `${MAPS_DIR}/dnh-and-dd.json`,
        mapType: MAP_TYPES.STATE,
    },
    DL: {
        geoDataFile: `${MAPS_DIR}/delhi.json`,
        mapType: MAP_TYPES.STATE,
    },
    LA: {
        geoDataFile: `${MAPS_DIR}/ladakh.json`,
        mapType: MAP_TYPES.STATE,
    },
    LD: {
        geoDataFile: `${MAPS_DIR}/lakshadweep.json`,
        mapType: MAP_TYPES.STATE,
    },
    PY: {
        geoDataFile: `${MAPS_DIR}/puducherry.json`,
        mapType: MAP_TYPES.STATE,
    },
    TT: {
        geoDataFile: `${MAPS_DIR}/india.json`,
        mapType: MAP_TYPES.COUNTRY,
    },
};

export const MAP_LEGEND_HEIGHT = 50;

export const STATE_NAMES: any = {
    AP: 'Andhra Pradesh',
    AR: 'Arunachal Pradesh',
    AS: 'Assam',
    BR: 'Bihar',
    CT: 'Chhattisgarh',
    GA: 'Goa',
    GJ: 'Gujarat',
    HR: 'Haryana',
    HP: 'Himachal Pradesh',
    JH: 'Jharkhand',
    KA: 'Karnataka',
    KL: 'Kerala',
    MP: 'Madhya Pradesh',
    MH: 'Maharashtra',
    MN: 'Manipur',
    ML: 'Meghalaya',
    MZ: 'Mizoram',
    NL: 'Nagaland',
    OR: 'Odisha',
    PB: 'Punjab',
    RJ: 'Rajasthan',
    SK: 'Sikkim',
    TN: 'Tamil Nadu',
    TG: 'Telangana',
    TR: 'Tripura',
    UT: 'Uttarakhand',
    UP: 'Uttar Pradesh',
    WB: 'West Bengal',
    AN: 'Andaman and Nicobar Islands',
    CH: 'Chandigarh',
    DN: 'Dadra and Nagar Haveli and Daman and Diu',
    DL: 'Delhi',
    JK: 'Jammu and Kashmir',
    LA: 'Ladakh',
    LD: 'Lakshadweep',
    PY: 'Puducherry',
    TT: 'India',
    [UNASSIGNED_STATE_CODE]: 'Unassigned',
};

const stateCodes: any = [];
const stateCodesMap: any = {};

Object.keys(STATE_NAMES).map((key: any, index: any) => {
    stateCodesMap[STATE_NAMES[key]] = key;
    stateCodes.push({ code: key, name: STATE_NAMES[key] });
    return null;
});
export const STATE_CODES: any = stateCodesMap;
export const STATE_CODES_ARRAY: any = stateCodes;
