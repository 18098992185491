import * as React from 'react';
import { createEditorViewState, EmojiPlugin } from 'roosterjs-react';
import { Ribbon, RibbonPlugin } from 'roosterjs-react-ribbon';

import RTEditor from './RTEditor.tsx';
import ribbonButtonRenderer from './ribbonButtonRenderer.tsx';

export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.viewState = createEditorViewState('');
        this.ribbonPlugin = new RibbonPlugin();
        this.emojiPlugin = new EmojiPlugin();
        this.emojiButton = { name: 'btnEmoji', onClick: () => this.emojiPlugin.startEmoji() };
        this.ribbonButtons = [
            'emoji',
            'bold',
            'italic',
            'underline',
            'font',
            'size',
            'bkcolor',
            'color',
            'bullet',
            'number',
            'indent',
            'outdent',
            'quote',
            'left',
            'center',
            'right',
            'link',
            'unlink',
            'sub',
            'super',
            'strike',
            'alttext',
            'ltr',
            'rtl',
            'undo',
            'redo',
            'unformat',
        ];

        this.editor = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('resize', () => this.ribbonPlugin.resize());
    }

    onChange = (value) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
    };

    setContent = (newValue) => {
        this.editor.current.setContent(newValue);
    };

    render() {
        return (
            <div>
                <Ribbon
                    ribbonPlugin={this.ribbonPlugin}
                    className="myRibbon"
                    buttonRenderer={ribbonButtonRenderer}
                    buttonNames={this.ribbonButtons}
                    additionalButtons={{ emoji: this.emojiButton }}
                />
                <RTEditor
                    className="clearfix editable-area"
                    ref={this.editor}
                    viewState={this.viewState}
                    onChange={this.onChange}
                    plugins={[this.ribbonPlugin, this.emojiPlugin]}
                />
            </div>
        );
    }
}
