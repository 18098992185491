import { Dispatch } from 'redux';
import humps from 'humps';
import _has from 'lodash/has';
import _get from 'lodash/get';
import { getRatingRange, getLongTermRating } from 'app/utils/TransactionUtils';
import { humanize } from 'app/utils/StringUtils';
import { toastr } from 'react-redux-toastr';
import Header from 'app/components/TransactionDetail/Common/PriceComparison/constants';
import serializeQuery from 'app/utils/URLQuery';
import { formatDate } from 'app/utils/DateUtils';
import normalizeTable from 'app/utils/TableUtils';
import * as fetchUtils from '../../../utils/FetchUtils';
import { createLoadingSelector } from '../../loading';

interface Iaction {
    type: string;
    sameEntityData: Array<object>;
    sameSectorData: Array<object>;
    otherSectorData: Array<object>;
    clientInvestorData: Array<object>;
}
interface IapiKey {
    marketSecuritizations: Array<object>;
}
const createActionName = (name: string): string => `app/ptc/transaction/pricing/${name}`;
const baseUrl = (): string => `${process.env.REACT_APP_MP_API_HOST}/market_securitizations`;

const initialState = {
    sameEntity: [],
    sameSector: [],
    otherSector: [],
    clientInvestor: [],
};

export const sameEntityLoading = createLoadingSelector([createActionName('GET_SAME_ENTITY')]);
export const sameSectorLoading = createLoadingSelector([createActionName('SAME_SECTOR')]);
export const otherSectorLoading = createLoadingSelector([createActionName('OTHER_SECTOR')]);
export const clientInvestorLoading = createLoadingSelector([createActionName('CLIENT_INVESTOR')]);

export const GET_SAME_ENTITY_REQUEST = createActionName('GET_SAME_ENTITY_REQUEST');
export const GET_SAME_ENTITY_SUCCESS = createActionName('GET_SAME_ENTITY_SUCCESS');
export const GET_SAME_ENTITY_FAILURE = createActionName('GET_SAME_ENTITY_FAILURE');

export const SAME_SECTOR_REQUEST = createActionName('SAME_SECTOR_REQUEST');
export const SAME_SECTOR_SUCCESS = createActionName('SAME_SECTOR_SUCCESS');
export const SAME_SECTOR_FAILURE = createActionName('SAME_SECTOR_FAILURE');

export const OTHER_SECTOR_REQUEST = createActionName('OTHER_SECTOR_REQUEST');
export const OTHER_SECTOR_SUCCESS = createActionName('OTHER_SECTOR_SUCCESS');
export const OTHER_SECTOR_FAILURE = createActionName('OTHER_SECTOR_FAILURE');

export const CLIENT_INVESTOR_REQUEST = createActionName('CLIENT_INVESTOR_REQUEST');
export const CLIENT_INVESTOR_SUCCESS = createActionName('CLIENT_INVESTOR_SUCCESS');
export const CLIENT_INVESTOR_FAILURE = createActionName('CLIENT_INVESTOR_FAILURE');

export default function pricing(state = initialState, action: Iaction): object {
    switch (action.type) {
        case GET_SAME_ENTITY_SUCCESS: {
            const { sameEntityData } = action;
            return {
                ...state,
                sameEntity: sameEntityData,
            };
        }
        case SAME_SECTOR_SUCCESS: {
            const { sameSectorData } = action;
            return {
                ...state,
                sameSector: sameSectorData,
            };
        }
        case OTHER_SECTOR_SUCCESS: {
            const { otherSectorData } = action;
            return {
                ...state,
                otherSector: otherSectorData,
            };
        }
        case CLIENT_INVESTOR_SUCCESS: {
            const { clientInvestorData } = action;
            return {
                ...state,
                clientInvestor: clientInvestorData,
            };
        }
        default:
            return state;
    }
}

const filters = {
    sno: (price: object, key: string, index: number) => {
        return { label: index + 1 };
    },
    assetClass: (price: any, key: string) => {
        const humanizedAssetClass = price[key].map((d: string) => humanize(d));
        return { label: humanizedAssetClass.join() };
    },
    settlementDate: (price: any, key: string) => {
        return { label: formatDate(price[key]), className: 'settle' };
    },
    rating: (price: any) => {
        const seniorInvestor = price.investors.find((d: any) => d.type === 'senior_investor');
        const rating = _get(seniorInvestor, 'rating.[0].value', '');
        const ratingAgency = _has(price, 'ratingAgency') ? `${_get(price, 'ratingAgency')} - ` : '';
        return { label: ratingAgency + rating };
    },
    coupon: (price: any) => {
        const seniorInvestor = price.investors.find((d: any) => d.type === 'senior_investor');
        return { label: _get(seniorInvestor, 'pricing', '') };
    },
    cashCollateral: (price: any, key: string) => {
        return { label: (price[key] * 100).toFixed(2) };
    },
    default: (price: any, key: string) => {
        return { label: price[key] };
    },
};

export function sameEntity(transId: string, customerId: string) {
    return (dispatch: Dispatch<string>): Promise<object> => {
        dispatch({ type: GET_SAME_ENTITY_REQUEST });
        const params = {
            page: 1,
            include: {
                customerIds: customerId,
            },
        };
        return fetchUtils
            .getJSON(`${baseUrl()}?${serializeQuery(humps.decamelizeKeys(params))}`)
            .then((d: object) => humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({
                    type: GET_SAME_ENTITY_SUCCESS,
                    sameEntityData: normalizeTable({ response: d.marketSecuritizations, headers: Header, columnData: filters }),
                    id: transId,
                });
            })
            .catch((err: object) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    toastr.error('Error retrieving data', m);
                });
                return dispatch({ type: GET_SAME_ENTITY_FAILURE });
            });
    };
}

export function sameSector(transId: string, assetClass: Array<string>, rating: string, customerId: string) {
    return (dispatch: Dispatch<string>): Promise<object> => {
        dispatch({ type: SAME_SECTOR_REQUEST });
        const ratingIndex = getLongTermRating(rating);
        const params = {
            page: 1,
            include: {
                tranchRating: getRatingRange(ratingIndex, 1, 1).toString(),
                assetClass: assetClass.toString(),
            },
            exclude: {
                customerIds: customerId,
            },
        };
        return fetchUtils
            .getJSON(`${baseUrl()}?${serializeQuery(humps.decamelizeKeys(params))}`)
            .then((d: object) => humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({
                    type: SAME_SECTOR_SUCCESS,
                    sameSectorData: normalizeTable({ response: d.marketSecuritizations, headers: Header, columnData: filters }),
                    id: transId,
                });
            })
            .catch((err: object) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    toastr.error('Error retrieving data', m);
                });
                return dispatch({ type: SAME_SECTOR_FAILURE });
            });
    };
}

export function otherSector(transId: string, assetClass: string, rating: string) {
    return (dispatch: Dispatch<string>): Promise<object> => {
        dispatch({ type: OTHER_SECTOR_REQUEST });
        const ratingIndex = getLongTermRating(rating);
        const params = {
            page: 1,
            include: {
                tranchRating: getRatingRange(ratingIndex, 1, 1).toString(),
            },
            exclude: {
                assetClass: assetClass.toString(),
            },
        };
        return fetchUtils
            .getJSON(`${baseUrl()}?${serializeQuery(humps.decamelizeKeys(params))}`)
            .then((d: object) => humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({
                    type: OTHER_SECTOR_SUCCESS,
                    otherSectorData: normalizeTable({ response: d.marketSecuritizations, headers: Header, columnData: filters }),
                    id: transId,
                });
            })
            .catch((err: object) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    toastr.error('Error retrieving data', m);
                });
                return dispatch({ type: OTHER_SECTOR_FAILURE });
            });
    };
}

export function clientInvestor(transId: string, customerId: string) {
    return (dispatch: Dispatch<string>): Promise<object> => {
        dispatch({ type: CLIENT_INVESTOR_REQUEST });
        return fetchUtils
            .getJSON(`${baseUrl()}?page=1&include[customer_ids]=${customerId}`)
            .then((d: object) => humps.camelizeKeys(d))
            .then((d: any) => {
                return dispatch({
                    type: CLIENT_INVESTOR_SUCCESS,
                    clientInvestorData: d.marketSecuritizations,
                    id: transId,
                });
            })
            .catch((err: object) => {
                fetchUtils.handleErrorV2(dispatch, err).then((m: string) => {
                    toastr.error('Error retrieving data', m);
                });
                return dispatch({ type: CLIENT_INVESTOR_FAILURE });
            });
    };
}
